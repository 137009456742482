<template>
  <TableRegular
    :loading="loading"
    :downloading="downloading"
    :rows="rows"
    :columns="columns"
    :sort="sort"
    :page="page"
    :per-page="perPage"
    :page-size="pageSize"
    :total-page="totalPage"
    :line-numbers="lineNumbers"
    :downloadable="downloadable && checkPermission('finance.income.download')"
    :custom-filters="customFilters"
    @onFilter="$emit('onFilter', $event)"
    @onPageChange="$emit('onPageChange', $event)"
    @onPerPageChange="$emit('onPerPageChange', $event)"
    @onSortChange="$emit('onSortChange', $event)"
    @onDownload="$emit('onDownload')"
    @onChangeCustomFilter="$emit('onChangeCustomFilter', $event)"
    @onSuccessFilter="$emit('onSuccessFilter', $event)"
  >
    <template slot="table-row" slot-scope="props">
      <p v-if="props.column.field === 'text'" class="w-36">
        {{ props.row.text }}
      </p>
      <div v-else-if="props.column.field === 'number'" class="font-medium whitespace-nowrap">
        <p v-if="props.row.apartment">{{ props.row.apartment.number }}</p>
        <p>{{ props.row.ownerName }}</p>
        <p>{{ props.row.phone }}</p>
      </div>
      <div v-else-if="props.column.field === 'status'" class="whitespace-nowrap">
        <div :class="['status', `is-${props.row.status ? 'sent' : 'not-sent'}`]">
          {{ props.row.status ? $t('statusSmsLog.sent') : $t('statusSmsLog.notSent') }}
        </div>
      </div>
    </template>
  </TableRegular>
</template>

<script>
import TableRegular from '@/components/ui/table/TableRegular';
import tableMixin from '@/mixins/tableMixin';

export default {
  mixins: [tableMixin],

  props: { footerItems: Object },

  components: { TableRegular },
};
</script>
