import Index from '@/views/AppVersions/Index';
import AppVersions from '@/views/AppVersions/AppVersions';
import AppVersionForm from '@/views/AppVersions/AppVersionForm';

export default [
  {
    path: '/app-versions',
    component: Index,
    meta: { group: 'app_versions' },
    children: [
      {
        path: '',
        component: AppVersions,
        name: 'AppVersions',
      },
      {
        path: 'create',
        name: 'AppVersionCreate',
        component: AppVersionForm,
      },
      {
        path: 'update/:id',
        name: 'AppVersionUpdate',
        component: AppVersionForm,
      },
    ],
  },
];
