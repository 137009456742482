<template>
  <div
    class="dark-mode-switcher cursor-pointer shadow-md fixed bottom-0 right-0 box dark:bg-dark-2 border rounded-full w-40 h-12 flex items-center justify-center z-50 mb-10 mr-10"
    @click="switchMode"
  >
    <div class="mr-4 text-gray-700 dark:text-gray-300">Dark Mode</div>
    <div
      :class="{ 'dark-mode-switcher__toggle--active': darkMode }"
      class="dark-mode-switcher__toggle border"
    ></div>
  </div>
</template>

<script>
import { DARK_SWITCH_REQUEST } from '@/store/actions/darkSwitcher';

export default {
  computed: {
    darkMode() {
      return this.$store.getters.darkMode;
    },
  },
  mounted() {
    // if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
    //   this.$store.dispatch(DARK_SWITCH_REQUEST, true);
    // }
    this.setDarkModeClass();
  },
  methods: {
    async switchMode() {
      await this.$store.dispatch(DARK_SWITCH_REQUEST, !this.darkMode);
      this.setDarkModeClass();
    },
    setDarkModeClass() {
      if (this.darkMode) {
        document.querySelector('html').classList.add('dark');
      } else {
        document.querySelector('html').classList.remove('dark');
      }
    },
  },
};
</script>
