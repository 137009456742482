<template>
  <TableRegular
    :loading="loading"
    :downloading="downloading"
    :rows="rows"
    :columns="columns"
    :sort="sort"
    :line-numbers="lineNumbers"
    :page="page"
    :per-page="perPage"
    :page-size="pageSize"
    :total-page="totalPage"
    :custom-filters="customFilters"
    @onFilter="$emit('onFilter', $event)"
    @onPageChange="$emit('onPageChange', $event)"
    @onPerPageChange="$emit('onPerPageChange', $event)"
    @onSortChange="$emit('onSortChange', $event)"
    @onDownload="$emit('onDownload')"
    @onChangeCustomFilter="$emit('onChangeCustomFilter', $event)"
  >
    <template slot="table-row" slot-scope="props">
      <p v-if="props.column.field === 'action'" class="flex justify-end items-center">
        <a
          href="javascript:"
          @click="onTransaction(props.row)"
          class="flex items-center mr-3 whitespace-nowrap"
        >
          <CheckSquareIcon class="w-4 h-4 mr-1" />
          {{ $t('table.relateTransactions') }}
        </a>
        <a
          href="javascript:"
          @click="$emit('delete', props.row.id)"
          class="flex items-center text-theme-6"
        >
          <Trash2Icon class="w-4 h-4 mr-1" />
          {{ $t('table.delete') }}
        </a>
      </p>
      <p v-else-if="props.column.field === 'invoiceDate'" class="whitespace-nowrap">
        {{ props.row.invoiceDate }} {{ props.row.cag }}
      </p>
      <p v-else-if="props.column.field === 'invoiceDescription'" class="text-right">
        {{ props.row.invoiceDescription }}
      </p>
      <p v-else-if="props.column.field === 'accountId'" class="flex justify-end items-center">
        {{ props.row.accountId }}
      </p>
      <p v-else-if="props.column.field === 'dtAmt'" class="flex justify-end items-center">
        {{ props.row.dtAmt | currencyNormal }}
      </p>
    </template>
    <template #footer>
      <tr v-if="!loading && rows.length > 0">
        <th v-if="lineNumbers"></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
    </template>
  </TableRegular>
</template>

<script>
import TableRegular from '@/components/ui/table/TableRegular';
import tableMixin from '@/mixins/tableMixin';

export default {
  mixins: [tableMixin],

  methods: {
    onTransaction(payload) {
      this.$emit(
        'transaction',
        payload.id,
        payload.dtAmt,
        payload.invoiceDescription,
        payload.paymentType
      );
    },
  },

  components: { TableRegular },
};
</script>
