<template>
  <div class="field">
    <label v-if="label" class="flex flex-col sm:flex-row">
      {{ label }}
      <span v-if="helpText" class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600">
        {{ helpText }}
      </span>
    </label>

    <input
      type="text"
      :class="[
        'input w-full border block',
        inputClass,
        { 'border-theme-6': hasError },
        { 'mt-2': label },
      ]"
      v-bind:value="value"
      :placeholder="placeholder"
      @input="onChange"
      @focusout="onFocusout"
      @keydown.enter="onKeydownEnter"
      :disabled="disabled"
      maxlength="10"
    />

    <HasError v-if="hasError" :vuelidate="vuelidate" :invalidFeedback="invalidFeedback" />
  </div>
</template>

<script>
import baseInputMixin from '@/mixins/baseInputMixin';
import HasError from '@/components/ui/input/HasError';

export default {
  mixins: [baseInputMixin],

  components: { HasError },

  methods: {
    onChange($event) {
      this.$emit('input', $event.target.value.toUpperCase());
      const start = $event.target.selectionStart;
      this.$nextTick(() => {
        $event.target.selectionEnd = start;
        $event.target.selectionStart = start;
      });
    },
  },
};
</script>
