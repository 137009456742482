<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="intro-y col-span-12 md:col-span-6 lg:col-span-4">
      <div
        :class="{ activeMeeting: isActive == meeting.id, box: 'box', mb5: 'mb5' }"
        v-for="(meeting, index) in meetings"
        :key="index"
        @click="meetingDetail(meeting.id)"
      >
        <MeetingListItem :meeting="meeting" />
      </div>
    </div>
    <div class="intro-y col-span-12 md:col-span-6 lg:col-span-8" v-if="isMeetingDetial">
      <div class="box">
        <MeetingDetail
          :meeting="meeting"
          :loading="loading"
          :businessId="businessId"
          @close="onDetailClosed"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MeetingListItem from '@/components/meeting/MeetingListItem';
import MeetingDetail from '@/components/meeting/MeetingDetail';
import { MEETING_REQUEST } from '@/store/actions/meeting';

export default {
  props: {
    loading: Boolean,
    meetings: Array,
    businessId: Number,
  },
  components: { MeetingListItem, MeetingDetail },
  data() {
    return {
      isMeetingDetial: false,
      isActive: 0,
    };
  },
  computed: {
    ...mapGetters(['meeting', 'meetingStatus']),
    isLoading() {
      return this.meetingStatus === 'loading';
    },
  },
  methods: {
    onDelete(value) {
      this.$emit('delete', value);
    },
    async onDetailClosed() {
      this.isMeetingDetial = false;
      // await this.$store.dispatch(MEETING_LIST_REQUEST, {
      //   businessId: this.businessId,
      // });
    },
    async meetingDetail(meetingId) {
      this.isActive = meetingId;
      const payload = {
        businessId: this.businessId,
        id: meetingId,
      };
      await this.$store.dispatch(MEETING_REQUEST, payload);
      if (this.meetingStatus === 'success') {
        this.isMeetingDetial = true;
      }
    },
  },
};
</script>
<style>
.activeMeeting {
  border: 2px solid #1a389f;
}
.mb5 {
  margin-bottom: 20px;
}
</style>
