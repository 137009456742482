import { findIndex } from 'lodash';
import {
  ACTIVITY_REPORT_LIST_REQUEST,
  ACTIVITY_REPORT_LIST_REQUEST_ERROR,
  ACTIVITY_REPORT_LIST_REQUEST_SUCCESS,
  ACTIVITY_REPORT_CREATE,
  ACTIVITY_REPORT_CREATE_ERROR,
  ACTIVITY_REPORT_CREATE_SUCCESS,
  ACTIVITY_REPORT_SHOW,
  ACTIVITY_REPORT_SHOW_SUCCESS,
  ACTIVITY_REPORT_SHOW_ERROR,
  ACTIVITY_REPORT_UPDATE,
  ACTIVITY_REPORT_UPDATE_SUCCESS,
  ACTIVITY_REPORT_UPDATE_ERROR,
  ACTIVITY_REPORT_DELETE,
  ACTIVITY_REPORT_DELETE_SUCCESS,
  ACTIVITY_REPORT_DELETE_ERROR,
} from '@/store/actions/activityReport';
import { handleRequestError } from '@/utils/error';

import Repository, { ACTIVITY_REPORTS } from '@/repositories/RepositoryFactory';

const ActivityReportsRepository = Repository.get(ACTIVITY_REPORTS);

const state = {
  activityReport: {},
  activityReportStatus: '',
  activityReportList: [],
  activityReportListMeta: '',
  activityReportListStatus: '',
};

const getters = {
  activityReport: state => state.activityReport,
  activityReportStatus: state => state.activityReportStatus,
  activityReportList: state => state.activityReportList,
  activityReportListMeta: state => state.activityReportListMeta,
  activityReportListStatus: state => state.activityReportListStatus,
};

const actions = {
  [ACTIVITY_REPORT_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(ACTIVITY_REPORT_LIST_REQUEST);
    try {
      const response = await ActivityReportsRepository.get(payload.businessId, payload.data);
      commit(ACTIVITY_REPORT_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(ACTIVITY_REPORT_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [ACTIVITY_REPORT_CREATE]: async ({ commit }, payload) => {
    commit(ACTIVITY_REPORT_CREATE);
    try {
      const response = await ActivityReportsRepository.create(payload.businessId, payload.data);
      commit(ACTIVITY_REPORT_CREATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(ACTIVITY_REPORT_CREATE_ERROR);
      handleRequestError(error);
    }
  },
  [ACTIVITY_REPORT_SHOW]: async ({ commit }, payload) => {
    commit(ACTIVITY_REPORT_SHOW);
    try {
      const response = await ActivityReportsRepository.show(payload.businessId, payload.id);
      commit(ACTIVITY_REPORT_SHOW_SUCCESS, response.data);
    } catch (error) {
      commit(ACTIVITY_REPORT_SHOW_ERROR);
      handleRequestError(error);
    }
  },
  [ACTIVITY_REPORT_UPDATE]: async ({ commit }, payload) => {
    commit(ACTIVITY_REPORT_UPDATE);
    try {
      const response = await ActivityReportsRepository.update(
        payload.businessId,
        payload.id,
        payload.data
      );
      commit(ACTIVITY_REPORT_UPDATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(ACTIVITY_REPORT_UPDATE_ERROR);
      handleRequestError(error);
    }
  },
  [ACTIVITY_REPORT_DELETE]: async ({ commit }, payload) => {
    commit(ACTIVITY_REPORT_DELETE);
    try {
      await ActivityReportsRepository.delete(payload.businessId, payload.id);
      commit(ACTIVITY_REPORT_DELETE_SUCCESS, payload.id);
    } catch (error) {
      commit(ACTIVITY_REPORT_DELETE_ERROR);
      handleRequestError(error);
    }
  },
};

const mutations = {
  [ACTIVITY_REPORT_LIST_REQUEST]: state => {
    state.activityReportListStatus = 'loading';
  },
  [ACTIVITY_REPORT_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.activityReportListStatus = 'success';
    state.activityReportList = payload.data;
    if (payload.meta) {
      state.activityReportListMeta = payload.meta;
    }
  },
  [ACTIVITY_REPORT_LIST_REQUEST_ERROR]: state => {
    state.activityReportListStatus = 'error';
  },
  [ACTIVITY_REPORT_CREATE]: state => {
    state.activityReportStatus = 'loading';
  },
  [ACTIVITY_REPORT_CREATE_SUCCESS]: (state, payload) => {
    state.activityReportStatus = 'success';
    state.activityReportList.unshift(payload);
  },
  [ACTIVITY_REPORT_CREATE_ERROR]: state => {
    state.activityReportStatus = 'error';
  },
  [ACTIVITY_REPORT_SHOW]: state => {
    state.activityReportStatus = 'loading';
  },
  [ACTIVITY_REPORT_SHOW_SUCCESS]: (state, payload) => {
    state.activityReportStatus = 'success';
    state.activityReport = payload.data;
  },
  [ACTIVITY_REPORT_SHOW_ERROR]: state => {
    state.activityReportStatus = 'error';
  },
  [ACTIVITY_REPORT_UPDATE]: state => {
    state.activityReportStatus = 'loading';
  },
  [ACTIVITY_REPORT_UPDATE_SUCCESS]: (state, payload) => {
    state.activityReportStatus = 'success';
    state.activityReportList = state.activityReportList.map(item => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },
  [ACTIVITY_REPORT_UPDATE_ERROR]: state => {
    state.activityReportStatus = 'error';
  },
  [ACTIVITY_REPORT_DELETE]: state => {
    state.activityReportStatus = 'deleting';
  },
  [ACTIVITY_REPORT_DELETE_SUCCESS]: (state, payload) => {
    state.activityReportStatus = 'deleted';
    const index = findIndex(state.activityReportList, { id: payload });
    state.activityReportList.splice(index, 1);
  },
  [ACTIVITY_REPORT_DELETE_ERROR]: state => {
    state.activityReportStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
