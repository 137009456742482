<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <PageHeader class="text-lg font-medium mr-auto" :title="title" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <Button
          type="button"
          :to="{ name: 'Services' }"
          class="box text-gray-700 dark:text-gray-300 mr-2 flex"
        >
          {{ $t('text.back') }}
        </Button>
        <Button
          type="button"
          @click="onSubmit"
          :loading="isLoading"
          class="w-24 bg-theme-1 text-white"
        >
          {{ $t('text.save') }}
        </Button>
      </div>
    </div>
    <div class="intro-y mt-5">
      <form @submit.prevent="onSubmit" enctype="multipart/form-data">
        <div class="grid grid-cols-12 gap-6">
          <div class="intro-y col-span-12 sm:col-span-12 lg:col-span-5 xl:col-span-5">
            <div class="intro-y box">
              <div class="py-4 px-5 relative">
                <div class="grid grid-cols-12">
                  <div class="intro-y col-span-12 sm:col-span-12">
                    <InputSelect
                      :label="$t('text.type')"
                      v-model.trim="$v.form.type.$model"
                      :options="servicesTypes"
                      value-key="id"
                      :vuelidate="$v.form.type"
                      input-class="input-service-type"
                    />
                    <InputString
                      :label="$t('text.name')"
                      v-model.trim="$v.form.name.$model"
                      :vuelidate="$v.form.name"
                      input-class="input-service-name"
                    />
                    <InputMoney
                      :label="$t('text.price')"
                      v-model.trim="$v.form.price.$model"
                      :vuelidate="$v.form.price"
                      input-class="input-service-price"
                    />
                    <InputMoney
                      :label="$t('text.sale')"
                      v-model.trim="$v.form.sale.$model"
                      :vuelidate="$v.form.sale"
                      input-class="input-service-sale"
                    />
                    <InputSelect
                      :label="$t('text.importers')"
                      v-model.number="$v.form.importerId.$model"
                      :options="importers"
                      value-key="id"
                      :vuelidate="$v.form.importerId"
                      input-class="input-service-importer"
                    />
                    <InputString
                      :label="$t('text.description')"
                      v-model.trim="$v.form.description.$model"
                      :vuelidate="$v.form.description"
                      input-class="input-service-description"
                      is-textarea
                    />
                    <InputMoney
                      v-if="$v.form.type.$model == 'PRODUCT'"
                      :label="$t('text.deliveryCost')"
                      v-model.trim="$v.form.deliveryCost.$model"
                      :vuelidate="$v.form.deliveryCost"
                      input-class="input-service-money"
                    />
                    <InputImage
                      v-if="$v.form.type.$model == 'PRODUCT'"
                      :label="$t('text.attachImages')"
                      v-model.trim="$v.form.photo_0"
                      :vuelidate="$v.form.photo_0"
                      input-class="input-service-image"
                      @upload="setFile"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="$v.form.type.$model == 'SERVICE'"
            class="intro-y col-span-12 sm:col-span-12 lg:col-span-7 xl:col-span-7"
          >
            <div class="intro-y box">
              <div class="p-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class="font-medium text-base">
                  {{ $t('text.serviceSchedule') }}
                </h2>
              </div>
              <div class="py-4 px-5 relative">
                <div class="grid grid-cols-12 gap-6">
                  <div
                    v-for="(day, index) in days"
                    :key="index"
                    class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y"
                  >
                    <ServiceDay :data="day"></ServiceDay>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { assign } from 'lodash';
import { IMPORTERS_LIST_REQUEST } from '@/store/actions/importer';
import { SERVICE_REQUEST, SERVICE_ADD, SERVICE_UPDATE } from '@/store/actions/service';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import Button from '@/components/ui/button/Button';
import PageHeader from '@/components/ui/PageHeader';
import InputMoney from '@/components/ui/input/InputMoney';
import InputString from '@/components/ui/input/InputString';
import InputSelect from '@/components/ui/input/InputSelect';
import InputImage from '@/components/ui/input/InputImage';
import { SERVICE_TYPES } from '@/utils/constants';
import ServiceDay from '@/components/services/ServiceDay';

export default {
  mixins: [validationMixin],

  components: {
    Button,
    PageHeader,
    InputString,
    InputMoney,
    InputSelect,
    ServiceDay,
    InputImage,
  },

  props: {
    business: Object,
  },

  validations: {
    form: {
      name: {
        required,
      },
      type: {
        required,
      },
      price: {
        required,
      },
      sale: {
        required,
      },
      description: {
        required,
      },
      importerId: {
        required,
      },
      deliveryCost: {
        required,
      },
    },
  },

  data() {
    return {
      form: {
        name: null,
        type: null,
        price: null,
        sale: null,
        deliveryCost: null,
        description: '',
        importerId: '',
        photo_0: { path: '', url: '' },
        schedule: [],
      },
      images: '',
      servicesTypes: SERVICE_TYPES,
      importers: [],
      defaultTableData: {
        filters: {},
        sort: {
          field: 'id',
          type: 'desc',
        },
        page: 1,
        perPage: 100,
      },
      days: [
        { name: 'Даваа', date: 1, selected: true, start: '08.00', end: '18.00' },
        { name: 'Мягмар', date: 2, selected: true, start: '08.00', end: '18.00' },
        { name: 'Лхагва', date: 3, selected: true, start: '08.00', end: '18.00' },
        { name: 'Пүрэв', date: 4, selected: true, start: '08.00', end: '18.00' },
        { name: 'Баасан', date: 5, selected: true, start: '08.00', end: '18.00' },
        { name: 'Бямба', date: 6, selected: false, start: '08.00', end: '18.00' },
        { name: 'Ням', date: 7, selected: false, start: '08.00', end: '18.00' },
      ],
    };
  },

  computed: {
    ...mapGetters([
      'serviceListStatus',
      'service',
      'importerList',
      'importerListMeta',
      'importerListStatus',
    ]),
    isLoading() {
      return this.serviceListStatus === 'loading';
    },
    title() {
      return this.$route.name === 'ServiceCreate'
        ? this.$t(`nav.serviceCreate`)
        : this.$t(`nav.serviceUpdate`);
    },
  },

  async created() {
    await this.fetchData();
  },

  methods: {
    async fetchData() {
      if (this.$route.params.serviceId) {
        const payload = { id: this.$route.params.serviceId };
        await this.$store.dispatch(SERVICE_REQUEST, payload);
        if (this.service) {
          const { name, type, price, sale, description, importerId, deliveryCost } = this.service;

          this.form = {
            name,
            type,
            price,
            sale,
            description,
            importerId,
            deliveryCost,
          };
        }
      }
      await this.fetchImportList();
    },

    async fetchImportList() {
      const payload = {
        data: this.defaultTableData,
      };

      await this.$store.dispatch(IMPORTERS_LIST_REQUEST, payload);
      if (this.importerListStatus === 'success') {
        this.importers = this.importerList.map(item => item);
      }
    },

    setFile(value) {
      this.form.photo_0 = value;
    },

    async onSubmit() {
      if (this.$v) {
        this.$v.$touch();

        if (this.$v.$anyError) {
          if (this.$v.owner.$anyError) {
            this.$toasted.show(this.$t('toast.requiredOwner'), { type: 'error' });
            return;
          }

          this.$toasted.show(this.$t('toast.failedForm'), { type: 'error' });
          return;
        }
      }

      try {
        if (this.form.type === 'SERVICE') {
          this.form.schedule = this.days.filter(item => item.selected === true);
        }
        const payload = {
          data: {
            ...this.form,
          },
        };
        if (this.$route.params.serviceId) {
          assign(payload, { id: this.service.id });
          await this.$store.dispatch(SERVICE_UPDATE, payload);
        } else {
          await this.$store.dispatch(SERVICE_ADD, payload);
        }

        if (this.serviceListStatus === 'success') {
          this.$router.push({ name: 'Services' });
          this.$toasted.show(this.$t('toast.success'), { type: 'success' });
        }
      } catch (error) {
        this.$toasted.show(error.message, { type: 'error' });
      }
    },
  },
};
</script>
