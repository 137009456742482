import Client from '@/clients/apiClient';
import ClientFile from '@/clients/fileClient';
import humps from 'humps';
import { stringify } from 'query-string';

const resource = 'business';
const resources = 'businesses';
const bankRes = 'bank-account';
const getBank = 'banks';
export default {
  get(payload) {
    return Client.get(
      `${resources}?page=${payload.page}&limit=${payload.perPage}&sort[field]=${humps.decamelize(
        payload.sort.field
      )}&sort[type]=${payload.sort.type}&${stringify(humps.decamelizeKeys(payload.filters))}`
    );
  },
  update(id, payload) {
    return Client.put(`${resources}/${id}`, payload);
  },
  getBank() {
    return Client.get(`${getBank}`);
  },
  bankCreate(businessId, payload) {
    return Client.post(`${resource}/${businessId}/${bankRes}`, payload);
  },
  bankUpdate(businessId, id, payload) {
    return Client.put(`${resource}/${businessId}/${bankRes}/${id}`, payload);
  },
  excelImport(businessId, payload) {
    return ClientFile.post(`${resource}/${businessId}/excel-import`, payload);
  },
  sendMailToStateBank(businessId, payload) {
    return Client.post(`${resource}/${businessId}/send-email-to-state-bank`, payload);
  },
  sendInvoiceToStateBank(businessId, payload) {
    return Client.post(`${resource}/${businessId}/send-invoice-email-to-state-bank`, payload);
  },
};
