<template>
  <div class="intro-y box mt-5">
    <div class="relative flex items-center p-5">
      <div class="mr-auto">
        <div class="font-medium text-base">{{ $t('text.subscribeSmsUnits') }}</div>
      </div>
      <Button @click="onSubscribe" class="w-24 bg-theme-14 text-theme-10">
        {{ $t('text.subscribe') }}
      </Button>
    </div>
    <div class="p-5 border-t border-gray-200 dark:border-dark-5">
      <p class="font-bold">
        {{ `${$t('table.balance')}: ${business.smsCount}` }}
      </p>
    </div>
    <BusinessSmsPackageModal :modal-id="modalId" :businessId="business.id" />
  </div>
</template>

<script>
import Button from '@/components/ui/button/Button';
import BusinessSmsPackageModal from '@/components/business/sms/BusinessSmsPackageModal';

export default {
  props: {
    business: Object,
  },

  components: {
    Button,
    BusinessSmsPackageModal,
  },
  data() {
    return {
      modalId: 'sms-package-modal',
    };
  },
  methods: {
    onSubscribe() {
      cash(`#${this.modalId}`).modal('show');
    },
  },
};
</script>
