<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-5">
      <PageHeader class="text-lg font-medium mr-auto" />
    </div>
    <div class="intro-y mt-5 overflow-auto lg:overflow-visible">
      <OrderTable
        :loading="isLoading"
        :rows="orderList"
        :columns="columns"
        :line-numbers="true"
        :sort="sort"
        :page="page"
        :per-page="perPage"
        :page-size="pageSize"
        :total-page="totalPage"
        :footerItems="footerItems"
        :custom-filters="customFilters"
        @delete="confirmRemoval"
        @show="onShowModal($event)"
        @pay="onShowModal($event, 'pay')"
        @onFilter="onFilter"
        @onPageChange="onPageChange"
        @onPerPageChange="onPerPageChange"
        @onSortChange="onSortChange"
        @onDownload="onDownload"
        @onChangeCustomFilter="onChangeCustomFilter"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ORDER_LIST_REQUEST } from '@/store/actions/order';
import { BUILDING_LIST_REQUEST } from '@/store/actions/building';
import dataListMixin from '@/mixins/dataListMixin';
import PageHeader from '@/components/ui/PageHeader';
import OrderTable from '@/components/order/OrderTable';

export default {
  mixins: [dataListMixin],
  components: {
    PageHeader,
    OrderTable,
  },
  data() {
    return {
      storeActionListRequest: ORDER_LIST_REQUEST,
      columns: [
        { label: this.$t('table.orderNo'), field: 'id', filterable: 'true' },
        { label: this.$t('table.date'), field: 'createdAt' },
        { label: this.$t('table.type'), field: 'serviceType' },
        { label: this.$t('table.name'), field: 'serviceName', filterable: 'true' },
        { label: this.$t('table.quantity'), field: 'quantity' },
        { label: this.$t('table.totalPrice'), field: 'total' },
        { label: this.$t('table.userId'), field: 'userId' },
        { label: this.$t('table.userId'), field: 'userName', filterable: 'true', disabled: true },
        { label: this.$t('table.apartmentId'), field: 'apartmentId' },
        { label: this.$t('table.doorNumber'), field: 'doorNumber' },
        { label: this.$t('table.importerId'), field: 'importerId' },
        {
          label: this.$t('table.importerId'),
          field: 'importerName',
          filterable: 'true',
          disabled: true,
        },
        { label: this.$t('table.status'), field: 'status' },
      ],
      customFilters: [
        {
          key: 'buildingId',
          value: null,
          label: this.$t('text.selectBuilding'),
          type: 'select',
          options: [],
        },
        {
          key: 'serviceType',
          value: null,
          label: this.$t('text.type'),
          type: 'select',
          options: [
            {
              id: 'PRODUCT',
              name: this.$t('text.product'),
            },
            {
              id: 'SERVICE',
              name: this.$t('text.service'),
            },
          ],
        },
        {
          key: 'status',
          value: null,
          label: this.$t('text.status'),
          type: 'select',
          options: [
            {
              id: 'NORMAL',
              name: this.$t('statusOrder.normal'),
            },
            {
              id: 'PAID',
              name: this.$t('statusOrder.paid'),
            },
            {
              id: 'CANCELLED',
              name: this.$t('statusOrder.cancelled'),
            },
          ],
        },
        {
          key: 'startDate',
          value: null,
          label: this.$t('text.selectStartDate'),
          type: 'day',
        },
        {
          key: 'endDate',
          value: null,
          label: this.$t('text.selectEndDate'),
          type: 'day',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['orderList', 'orderMeta', 'orderStatus', 'buildingStatus', 'buildingList']),
    isLoading() {
      return this.orderStatus === 'loading';
    },
    footerItems() {
      return this.orderMeta
        ? {
            totalAmount: this.orderMeta.totalAmount,
            total: this.orderMeta.total,
          }
        : {};
    },
  },
  async created() {
    await this.fetchBuildingList();
    this.fetchData();
  },
  methods: {
    async fetchBuildingList() {
      const payload = {
        businessId: this.business.id,
        data: {
          filters: {},
          sort: {
            field: 'id',
            type: 'desc',
          },
          page: 1,
          perPage: 100,
        },
      };

      await this.$store.dispatch(BUILDING_LIST_REQUEST, payload);
      if (this.buildingStatus === 'success') {
        this.customFilters[0].options = this.buildingList.map(item => item);
      }
    },
    onChangeCustomFilter(object) {
      const index = this.customFilters.findIndex(item => item.key === object.key);
      if (index > -1) {
        const { value } = object;
        this.customFilters[index].value = value || null;
        this.fetchData();
      }
    },
    setFilterValues(data) {
      this.customFilters.forEach(item => {
        data.filters[`filter[${item.key}]`] = item.value;
      });
    },
    setPaginationValues() {
      this.totalRecords = this.orderMeta ? this.orderMeta.total : 1;
      this.page = this.orderMeta.currentPage;
      this.totalPage = this.orderMeta.lastPage;
    },
  },
};
</script>
