<template>
  <PieChart :width="width" :height="height" :chart-data="data" />
</template>

<script>
import PieChart from '@/components/ui/chart/PieChart';

export default {
  components: { PieChart },
  props: {
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
    statistics: Array,
    headers: Array,
  },
  computed: {
    data() {
      return {
        labels: this.headers,
        datasets: [
          {
            data: this.statistics,
            backgroundColor: ['#07ab43', '#FFA500'],
            hoverBackgroundColor: ['#07ab43', '#FFA500'],
            borderWidth: 1,
            borderColor: '#fff',
          },
        ],
      };
    },
  },
};
</script>
