<template>
  <div class="field">
    <label v-if="label" class="flex flex-col sm:flex-row">
      {{ label }}
      <span v-if="helpText" class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600">
        {{ helpText }}
      </span>
    </label>

    <div class="mt-2">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import baseInputMixin from '@/mixins/baseInputMixin';

export default {
  mixins: [baseInputMixin],
};
</script>
