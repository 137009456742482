<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <PageHeader class="text-lg font-medium mr-auto" :title="$t('businessSetup.title')" />
    </div>

    <div class="intro-y box mt-5">
      <div class="grid grid-cols-12 gap-0">
        <div class="col-span-12 lg:col-span-4 p-5 sm:p-10">
          <div
            v-for="(step, stepIndex) in steps"
            :key="stepIndex"
            :class="['intro-x flex items-center', { 'mt-5': stepIndex > 0 }]"
          >
            <Button
              :class="[
                'w-10 h-10 flex-shrink-0 rounded-full',
                step.value === currentStep
                  ? 'text-white bg-theme-1'
                  : 'text-gray-600 bg-gray-200 dark:bg-dark-1',
              ]"
              @click="onChangeTab(step.value)"
            >
              {{ stepIndex + 1 }}
            </Button>
            <div
              @click="onChangeTab(step.value)"
              class="font-medium text-base ml-3 leading-5"
              role="button"
            >
              {{ step.name }}
            </div>
          </div>
        </div>
        <div
          class="col-span-12 lg:col-span-8 border-t lg:border-t-0 lg:border-l border-gray-200 dark:border-dark-5"
        >
          <div class="py-4 px-5 sm:px-10 flex items-center justify-between">
            <p class="text-base font-medium">{{ currentStepName }}</p>
            <Button
              @click="downloadTemplate"
              type="button"
              class="border dark:border-dark-5 text-gray-700 dark:text-gray-300 mr-1"
            >
              {{ $t('businessSetup.downloadTemplate') }}
            </Button>
          </div>
          <div class="py-4 px-5 sm:px-10 border-t border-gray-200 dark:border-dark-5">
            <form @submit.prevent="onSubmit">
              <div v-if="currentStep === 'OWNER'" class="intro-y mb-4">
                <InputSelect
                  :label="$t('text.buildingNameLong')"
                  v-model.number="$v.form.buildingId.$model"
                  :vuelidate="$v.form.buildingId"
                  :options="buildingList"
                  value-key="id"
                  :disabled="buildingStatus === 'loading'"
                  input-class="input-business-setup-building"
                />
              </div>
              <div v-if="currentStep === 'BALANCE'" class="intro-y mb-4">
                <InputCheckbox
                  v-model="isStatementOfStateBank"
                  is-boolean
                  :label="$t('file.statementOfStateBank')"
                  input-class="input-business-setup-is-statement"
                />
              </div>
              <div v-if="hasMonthInput" class="intro-y mb-4">
                <InputMonth
                  :label="$t('text.selectMonth')"
                  v-model="$v.form.date.$model"
                  :vuelidate="$v.form.date"
                  input-class="input-business-month"
                />
              </div>
              <div class="intro-y">
                <InputFile
                  ref="inputFile"
                  :label="$t('text.chooseFile')"
                  :value="$v.form.file.$model"
                  accept-files=".xls, .xlsx, .csv"
                  :vuelidate="$v.form.file"
                  class="input-business-file"
                  @upload="setFile"
                />
              </div>
              <div class="intro-y mt-4">
                <Button
                  @click="resetFile"
                  type="button"
                  class="w-24 border dark:border-dark-5 text-gray-700 dark:text-gray-300 mr-1"
                >
                  {{ $t('text.clear') }}
                </Button>
                <Button type="submit" :loading="isLoading" class="w-24 bg-theme-1 text-white">
                  {{ $t('text.save') }}
                </Button>
              </div>
            </form>
          </div>
          <div
            v-if="showResults"
            class="py-4 px-5 sm:px-10 border-t border-gray-200 dark:border-dark-5"
          >
            <div class="font-medium mb-2">
              {{ $t('businessSetup.calculatedResults') }}
            </div>
            <p v-if="businessExcelImportSuccessRowCount > 0">
              {{
                $t('businessSetup.successWithTotalRow', {
                  number: businessExcelImportSuccessRowCount,
                })
              }}
            </p>
            <template v-if="serverErrors">
              <p v-for="(item, index) in serverErrors" :key="index" class="status is-danger">
                {{ item.error[0] }} - {{ item.row }}-р мөр
              </p>
            </template>
            <DownloadExcel
              :label="$t('businessSetup.downloadInvalidRows')"
              :list="businessExcelImportFailures"
              :file-name="currentStep.toLowerCase()"
              :sheet-name="currentStep.toLowerCase()"
              class="mt-3 bg-theme-12 dark:text-gray-800"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { VALIDATION_ERROR_CODE } from '@/utils/error';
import { BUILDING_LIST_REQUEST } from '@/store/actions/building';
import { BUSINESS_EXCEL_IMPORT } from '@/store/actions/business';
import PageHeader from '@/components/ui/PageHeader';
import Button from '@/components/ui/button/Button';
import InputSelect from '@/components/ui/input/InputSelect';
import InputFile from '@/components/ui/input/InputFile';
import InputCheckbox from '@/components/ui/input/InputCheckbox';
import InputMonth from '@/components/ui/input/InputMonth';
import DownloadExcel from '@/components/businessSetup/DownloadExcel';

const defaultValuesForForm = () => ({
  file: '',
  type: 'BUILDING',
  buildingId: null,
  date: '',
});

export default {
  mixins: [validationMixin],

  props: {
    business: Object,
  },

  data() {
    return {
      steps: [
        {
          name: this.$t('businessSetup.building'),
          value: 'BUILDING',
        },
        {
          name: this.$t('businessSetup.ownerApartmentParking'),
          value: 'OWNER',
        },
        {
          name: this.$t('businessSetup.balance'),
          value: 'BALANCE',
        },
        {
          name: this.$t('businessSetup.service'),
          value: 'SERVICE',
        },
        {
          name: this.$t('businessSetup.invoice'),
          value: 'INVOICE',
        },
        {
          name: this.$t('businessSetup.expense'),
          value: 'EXPENSE',
        },
      ],
      currentStep: 'BUILDING',
      form: defaultValuesForForm(),
      serverErrors: [],
      showResults: false,
      isStatementOfStateBank: false,
    };
  },

  validations() {
    if (this.currentStep === 'OWNER') {
      return {
        form: {
          buildingId: {
            required,
          },
          file: {
            required,
          },
        },
      };
    }
    if (
      this.currentStep === 'INVOICE' ||
      (this.currentStep === 'BALANCE' && !this.isStatementOfStateBank)
    ) {
      return {
        form: {
          date: {
            required,
          },
          file: {
            required,
          },
        },
      };
    }
    return {
      form: {
        file: {
          required,
        },
      },
    };
  },

  computed: {
    ...mapGetters([
      'businessStatus',
      'businessExcelImportErrors',
      'businessExcelImportFailures',
      'businessExcelImportSuccessRowCount',
      'buildingList',
      'buildingStatus',
    ]),
    isLoading() {
      return this.businessStatus === 'loading';
    },
    currentStepName() {
      const step = this.steps.find(item => item.value === this.currentStep);
      return step.name;
    },
    hasMonthInput() {
      return (
        this.currentStep === 'INVOICE' ||
        (this.currentStep === 'BALANCE' && !this.isStatementOfStateBank)
      );
    },
  },

  created() {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const type = url.searchParams.get('type');
    const buildingId = url.searchParams.get('building_id');
    if (type) {
      this.currentStep = type;
    }
    if (buildingId) {
      this.form.buildingId = buildingId;
    }
  },

  methods: {
    fetchBuildings() {
      const data = {
        filters: {},
        page: 1,
        perPage: 100,
        sort: {
          field: 'id',
          type: 'desc',
        },
      };
      const payload = { businessId: this.business ? this.business.id : 0, data };
      this.$store.dispatch(BUILDING_LIST_REQUEST, payload);
    },
    downloadTemplate() {
      let link = `${process.env.VUE_APP_API_URL}/business/excel-template-download?type=${this.currentStep}`;
      if (this.currentStep === 'INVOICE' || this.currentStep === 'BALANCE') {
        link += `&business_id=${this.business.id}`;
      }
      window.open(link, '_blank');
    },
    async onChangeTab(value) {
      this.form.type = value;
      if (value === 'OWNER') {
        this.fetchBuildings();
      }
      this.currentStep = value;
      await this.resetFile();
      this.serverErrors = [];
    },
    async resetForm() {
      this.form = defaultValuesForForm();
      this.serverErrors = [];
      this.resetFile();
    },
    resetFile() {
      this.$refs.inputFile.resetField();
      this.$v.form.file.$model = '';
      this.form.file = '';
      this.showResults = false;
      this.$v.$reset();
    },
    setFile(value) {
      this.$v.form.file.$model = value;
      this.form.file = value;
      this.$v.form.file.$touch();
    },
    async onSubmit() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          this.$toasted.show(this.$t('toast.failedForm'), { type: 'error' });
          return;
        }
      }

      try {
        let { type } = this.form;
        if (this.form.type === 'BALANCE' && this.isStatementOfStateBank) {
          type = 'BALANCE_STATEBANK';
        }
        const formData = new FormData();
        formData.append('file', this.form.file);
        formData.append('type', type);
        if (this.currentStep === 'OWNER') {
          formData.append('building_id', this.form.buildingId);
        }
        if (this.currentStep === 'INVOICE' || this.currentStep === 'BALANCE') {
          formData.append('date', this.form.date);
        }
        const payload = {
          businessId: this.business.id,
          data: formData,
        };
        await this.$store.dispatch(BUSINESS_EXCEL_IMPORT, payload);
        if (this.businessStatus === 'success') {
          this.$toasted.show(this.$t('toast.success'), { type: 'success' });
          this.resetForm();
        } else {
          this.serverErrors = this.businessExcelImportErrors;
          this.showResults = true;
          this.$refs.inputFile.resetField();
          this.$v.$reset();
        }
      } catch (error) {
        if (error.response.status === VALIDATION_ERROR_CODE) {
          this.serverErrors = error.response.data.errors;
        }
        this.$toasted.show(error.message, { type: 'error' });
      }
    },
  },

  components: {
    PageHeader,
    Button,
    InputSelect,
    InputFile,
    InputCheckbox,
    InputMonth,
    DownloadExcel,
  },
};
</script>
