<template>
  <div>
    <p class="text-xl text-center xl:text-left font-medium text-gray-600 mb-4">{{ title }}</p>
    <form @submit.prevent="onSubmit">
      <div class="intro-x">
        <InputString
          :placeholder="$t('auth.companyName')"
          v-model.trim="$v.formData.name.$model"
          :vuelidate="$v.formData.name"
          input-class="input-register-name login__input input--lg"
        />

        <InputString
          :placeholder="$t('auth.companyRegistrationNumber')"
          :value="$v.formData.registrationNumber.$model"
          :vuelidate="$v.formData.registrationNumber"
          :max-length="7"
          :invalidFeedback="registrationNumberInvalidFeedback"
          input-class="input-register-registration-number login__input input--lg"
          @focusout="setRegistrationNumber($event.target.value)"
        />

        <InputSelect
          v-model.number="$v.formData.cityId.$model"
          :options="cities"
          :first-item-label="$t('auth.city')"
          value-key="id"
          :vuelidate="$v.formData.cityId"
          input-class="input-register-city login__input input--lg"
          @input="onChangeCity()"
        />

        <InputSelect
          v-model.number="$v.formData.districtId.$model"
          :options="districts"
          :first-item-label="$t('auth.district')"
          value-key="id"
          :vuelidate="$v.formData.districtId"
          input-class="input-register-district login__input input--lg"
          @input="onChangeDistrict()"
        />

        <InputSelect
          v-model.number="$v.formData.horooId.$model"
          :options="horoos"
          :first-item-label="$t('auth.khoroo')"
          value-key="id"
          :vuelidate="$v.formData.horooId"
          input-class="input-select-register-khoroo login__input input--lg"
        />

        <InputString
          isTextarea
          :placeholder="$t('auth.address')"
          v-model.trim="$v.formData.address.$model"
          :vuelidate="$v.formData.address"
          input-class="input-select-register-address login__input input--lg"
        />
      </div>
      <div class="intro-x mt-5 xl:mt-8">
        <Button type="submit" class="button--lg w-full text-white bg-theme-1" :loading="isLoading">
          <span>{{ $t('auth.continue') }}</span>
        </Button>
      </div>
      <div class="intro-x mt-3 xl:mt-5">
        <Button
          :to="{ name: 'UserLogin' }"
          class="button--lg w-full xl:w-32 text-gray-700 border border-gray-300 dark:border-dark-5 dark:text-gray-300"
        >
          <span>{{ $t('auth.login') }}</span>
        </Button>
      </div>
    </form>
  </div>
</template>

<script>
import InputString from '@/components/ui/input/InputString';
import InputSelect from '@/components/ui/input/InputSelect';
import Button from '@/components/ui/button/Button';
import { validationMixin } from 'vuelidate';
import { required, minLength, numeric, maxLength } from 'vuelidate/lib/validators';
import { USER_VALIDATE } from '@/store/actions/user';
import stepMixin from '@/mixins/stepMixin';

export default {
  components: { Button, InputString, InputSelect },

  mixins: [validationMixin, stepMixin],

  validations: {
    formData: {
      name: {
        required,
        minLength: minLength(2),
      },
      registrationNumber: {
        required,
        numeric,
        max: maxLength(7),
        async isUnique(value) {
          if (value === '') return true;
          try {
            const data = { registrationNumber: this.formData.registrationNumber };
            await this.$store.dispatch(USER_VALIDATE, data);
            return true;
          } catch (e) {
            if (e.response.status === 422) {
              const firstError = { index: 0 };
              if (e.response.data.errors.registrationNumber) {
                [firstError.index] = e.response.data.errors.registrationNumber;
                this.registrationNumberInvalidFeedback = firstError.index;
              } else {
                return true;
              }
            }
            return false;
          }
        },
      },
      cityId: {
        required,
      },
      districtId: {
        required,
      },
      horooId: {
        required,
      },
      address: {
        required,
        minLength: minLength(6),
      },
    },
  },

  data() {
    return {
      districts: [],
      horoos: [],
      registrationNumberInvalidFeedback: '',
      max: 7,
    };
  },

  methods: {
    setRegistrationNumber(value) {
      this.$v.formData.registrationNumber.$model = value;
      this.$v.formData.registrationNumber.$touch();
    },
    onChangeCity() {
      this.formData.districtId = null;
      this.formData.horooId = null;
      const index = this.cities.findIndex(item => item.id === this.formData.cityId);
      if (index > -1) {
        this.districts = this.cities[index].districts;
      } else {
        this.districts = [];
      }
    },
    onChangeDistrict() {
      this.formData.horooId = null;
      const index = this.districts.findIndex(item => item.id === this.formData.districtId);
      if (index > -1) {
        this.horoos = this.districts[index].horoos;
      } else {
        this.horoos = [];
      }
    },
  },
};
</script>
