import Client from '@/clients/apiClient';
import humps from 'humps';
import { stringify } from 'query-string';

const resource = 'sms-templates';

export default {
  get(payload) {
    return Client.get(
      `${resource}?page=${payload.page}&limit=${payload.perPage}&sort[field]=${humps.decamelize(
        payload.sort.field
      )}&sort[type]=${payload.sort.type}&${stringify(humps.decamelizeKeys(payload.filters))}`
    );
  },
  show(id) {
    return Client.get(`${resource}/${id}`);
  },
  update(id, payload) {
    return Client.put(`${resource}/${id}`, payload);
  },
};
