import DropDownButtonList from '@/components/ui/DropDownButtonList';
import ConfirmDialog from '@/components/ui/ConfirmDialog';
import { assign } from 'lodash';
import permissionMixin from '@/mixins/permissionMixin';

const defaultSortObject = {
  field: 'id',
  type: 'desc',
};

export default {
  mixins: [permissionMixin],

  props: {
    business: Object,
  },

  components: { ConfirmDialog, DropDownButtonList },

  data() {
    return {
      storeActionListRequest: null,
      storeActionRemove: null,
      storeActionConfirm: null,
      storeActionDownload: null,
      storeMakeTransaction: null,
      uploadModalId: 'upload-modal',
      uploadRefName: 'uploadModal',
      dropdownId: 'dropdown-items',
      deleteModalId: 'delete-confirm-dialog',
      deleteSelectedItemsModalId: 'delete-selected-items-confirm-dialog',
      approveModalId: 'approve-confirm-dialog',
      transactionModalId: 'transaction-dialog',
      itemIdToRemove: null,
      itemIdToConfirm: null,
      itemIdToChart: null,
      sort: defaultSortObject,
      totalRecords: 0,
      page: 1,
      perPage: 20,
      pageSize: [10, 20, 30, 50, 100, 200],
      totalPage: 1,
      filter: null,
      isDeleting: false,
    };
  },

  methods: {
    async onUpload() {
      await this.$refs[this.uploadRefName].resetForm();
      await cash(`#${this.uploadModalId}`).modal('show');
      cash(`#${this.dropdownId}`).dropdown('hide');
    },

    confirmRemoval(id) {
      this.itemIdToRemove = id;
      cash(`#${this.deleteModalId}`).modal('show');
    },

    confirmApprove(id) {
      this.itemIdToConfirm = id;
      cash(`#${this.approveModalId}`).modal('show');
    },

    async onDelete() {
      try {
        this.isDeleting = true;
        const payload = {
          businessId: this.business ? this.business.id : 0,
          id: this.itemIdToRemove,
        };
        await this.$store.dispatch(this.storeActionRemove, payload);
      } catch (error) {
        this.$toasted.show(error.message, { type: 'error' });
      } finally {
        cash(`#${this.deleteModalId}`).modal('hide');
        this.isDeleting = false;
      }
      this.itemIdToRemove = null;
    },

    async onDeleteOwner() {
      cash(`#${this.deleteModalId}`).modal('hide');
      try {
        const payload = {
          businessId: this.business ? this.business.id : 0,
          user_apartment_id: this.itemIdToRemove,
        };
        await this.$store.dispatch(this.storeActionRemove, payload);
      } catch (error) {
        this.$toasted.show(error.message, { type: 'error' });
      }
      this.itemIdToRemove = null;
    },

    async toApprove() {
      cash(`#${this.approveModalId}`).modal('hide');
      try {
        const payload = {
          businessId: this.business ? this.business.id : 0,
          userApartmentId: this.itemIdToConfirm,
        };
        await this.$store.dispatch(this.storeActionConfirm, payload);
      } catch (error) {
        this.$toasted.show(error.message, { type: 'error' });
      }
      this.itemIdToRemove = null;
    },

    async makeTransaction() {
      cash(`#${this.approveModalId}`).modal('hide');
      try {
        const payload = {
          businessId: this.business ? this.business.id : 0,
          userApartmentId: this.itemIdToConfirm,
        };
        await this.$store.dispatch(this.storeMakeTransaction, payload);
      } catch (error) {
        this.$toasted.show(error.message, { type: 'error' });
      }
      this.itemIdToRemove = null;
    },

    onSortChange(params) {
      this.sort = params;
      this.onPageChange(1);
    },

    onPerPageChange(perPage) {
      this.perPage = perPage;
      this.onPageChange(1);
    },

    onPageChange(page) {
      this.page = page;
      this.fetchData();
    },

    onFilter(payload) {
      this.filter = payload;
      if (this.filter.value === '' && this.filter.field === '') {
        this.sort = defaultSortObject;
      }
      this.fetchData();
    },

    async onDownload() {
      const data = { filters: {} };

      if (this.sort) {
        assign(data, { sort: this.sort });
      }

      await this.setFilterValues(data);

      if (this.filter && this.filter.field) {
        data.filters[`filter[${this.filter.field}]`] = this.filter.value;
      }

      const payload = { businessId: this.business ? this.business.id : 0, data };

      await this.$store.dispatch(this.storeActionDownload, payload);
      this.setDownloads();
    },

    async fetchData() {
      const data = { filters: {}, page: this.page, perPage: this.perPage };

      if (this.sort) {
        assign(data, { sort: this.sort });
      }

      await this.setFilterValues(data);

      if (this.filter && this.filter.field) {
        data.filters[`filter[${this.filter.field}]`] = this.filter.value;
      }

      const payload = { businessId: this.business ? this.business.id : 0, data };

      await this.$store.dispatch(this.storeActionListRequest, payload);
      await this.setPaginationValues();
    },

    setFilterValues() {},

    setPaginationValues() {},

    setDownloads() {},
  },
};
