<template>
  <div class="field">
    <label v-if="label" class="flex flex-col sm:flex-row">
      {{ label }}
      <span v-if="helpText" class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600">
        {{ helpText }}
      </span>
    </label>

    <select
      :class="[
        'input w-full border block',
        inputClass,
        { 'border-theme-6': hasError },
        { 'mt-2': label },
      ]"
      @input="onChange"
      :disabled="disabled"
      :value="value"
    >
      <option
        v-if="firstItemLabel"
        :disabled="firstItemDisabled"
        :selected="firstItemDisabled"
        :value="firstItemValue ? firstItemValue : null"
        >{{ firstItemLabel }}</option
      >
      <option v-else :value="null">&nbsp;</option>
      <option
        v-for="(item, index) in options"
        :value="getValue(item)"
        :key="index"
        :selected="value === getValue(item)"
        >{{ item[labelKeyCustom] ? item[labelKeyCustom] : item[labelKey] }}</option
      >
    </select>

    <HasError v-if="hasError" :vuelidate="vuelidate" :invalidFeedback="invalidFeedback" />
  </div>
</template>

<script>
import baseInputMixin from '@/mixins/baseInputMixin';
import HasError from '@/components/ui/input/HasError';

export default {
  mixins: [baseInputMixin],

  components: { HasError },

  props: {
    options: Array,
    firstItemLabel: String,
    firstItemValue: [String, Number],
    firstItemDisabled: {
      type: Boolean,
      default: false,
    },
    labelKey: {
      type: String,
      default: 'name',
    },
    labelKeyCustom: {
      type: String,
      default: 'number',
    },

    valueKey: String,
  },

  methods: {
    getValue(item) {
      return this.valueKey ? item[this.valueKey] : item;
    },
  },
};
</script>
