<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">{{ title }}</h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 sm:col-span-6 lg:col-span-4">
        <div class="intro-y box p-5">
          <form @submit.prevent="onSubmit">
            <InputSelect
              v-model.number="$v.form.cityId.$model"
              :options="cityList"
              :label="$t('auth.city')"
              value-key="id"
              :vuelidate="$v.form.cityId"
              @input="onChangeCity()"
              input-class="sux-city"
              :disabled="isLoadingCities"
            />

            <InputSelect
              v-model.number="$v.form.districtId.$model"
              :options="districts"
              :label="$t('auth.district')"
              value-key="id"
              :vuelidate="$v.form.districtId"
              :disabled="districts.length < 1"
              input-class="sux-district"
              @input="onChangeDistrict()"
            />

            <InputSelect
              v-model.number="$v.form.horooId.$model"
              :options="horoos"
              :label="$t('auth.khoroo')"
              value-key="id"
              :vuelidate="$v.form.horooId"
              :disabled="horoos.length < 1"
              input-class="sux-khoroo"
              @input="onChangeHoroo()"
            />

            <InputSelect
              v-model.number="$v.form.townId.$model"
              :options="towns"
              :label="$t('text.town')"
              value-key="id"
              input-class="input-select-building-town"
              :vuelidate="$v.form.townId"
            />

            <InputString
              :label="$t('text.buildingName')"
              v-model.trim="$v.form.name.$model"
              input-class="sux-building-name"
              :vuelidate="$v.form.name"
            />

            <InputString
              :label="$t('text.stateBankBuildingName')"
              v-model="form.stateBankBuildingName"
              input-class="input-building-state-bank-name"
            />

            <InputString
              :label="$t('text.stateBankCorps')"
              input-class="input-building-state-bank-corps"
              v-model="form.corps"
            />

            <div class="text-right mt-5">
              <Button
                type="button"
                :to="{ name: 'Buildings' }"
                class="w-24 border dark:border-dark-5 text-gray-700 dark:text-gray-300 mr-1"
              >
                {{ $t('text.cancel') }}
              </Button>
              <Button type="submit" :loading="isLoading" class="w-24 bg-theme-1 text-white">
                {{ $t('text.save') }}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BUILDING_ADD, BUILDING_UPDATE, BUILDING_REQUEST } from '@/store/actions/building';
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import InputString from '@/components/ui/input/InputString';
import InputSelect from '@/components/ui/input/InputSelect';
import Button from '@/components/ui/button/Button';

export default {
  mixins: [validationMixin],

  components: { InputString, InputSelect, Button },

  props: {
    business: Object,
  },

  validations: {
    form: {
      name: {
        required,
        minLength: minLength(2),
      },
      cityId: {
        required,
      },
      districtId: {
        required,
      },
      horooId: {
        required,
      },
      townId: {},
    },
  },

  data() {
    return {
      form: {
        name: '',
        cityId: null,
        districtId: null,
        horooId: null,
        townId: null,
        stateBankBuildingName: '',
        corps: '',
      },
      districts: [],
      horoos: [],
      towns: [],
    };
  },

  computed: {
    ...mapGetters(['buildingStatus', 'building', 'cityStatus', 'cityList']),
    isLoading() {
      return this.buildingStatus === 'loading';
    },
    isLoadingCities() {
      return this.cityStatus === 'loading';
    },
    title() {
      return this.$route.name === 'BuildingCreate'
        ? this.$t('nav.buildingCreate')
        : this.$t('nav.buildingUpdate');
    },
  },

  async created() {
    await this.fetchData();
  },

  methods: {
    async fetchData() {
      if (this.$route.params.buildingId) {
        const payload = { businessId: this.business.id, id: this.$route.params.buildingId };
        await this.$store.dispatch(BUILDING_REQUEST, payload);
        if (this.building) {
          await this.fetchDataList(
            this.cityList,
            this.building.cityId,
            this.building.districtId,
            this.building.horooId
          );
          this.form = {
            name: this.building.name,
            cityId: this.building.cityId,
            districtId: this.building.districtId,
            horooId: this.building.horooId,
            townId: this.building.townId,
            stateBankBuildingName: this.building.stateBankBuildingName,
            corps: this.building.corps,
          };
        }
      }
    },

    async fetchDataList(cityList, cityId, districtId, horooId) {
      this.districts = await cityList.find(item => item.id === cityId).districts;
      if (this.districts.length > 0) {
        this.horoos = this.districts.find(item => item.id === districtId).horoos;
        if (this.horoos.length > 0) {
          this.towns = this.horoos.find(item => item.id === horooId).towns;
        }
      }
    },

    async onSubmit() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          this.$toasted.show(this.$t('toast.failedForm'), { type: 'error' });
          return;
        }
      }

      try {
        if (this.$route.params.buildingId) {
          const payload = {
            businessId: this.business.id,
            id: this.building.id,
            data: this.form,
          };
          await this.$store.dispatch(BUILDING_UPDATE, payload);
        } else {
          const payload = {
            businessId: this.business.id,
            data: this.form,
          };
          await this.$store.dispatch(BUILDING_ADD, payload);
        }

        if (this.buildingStatus === 'success') {
          this.$router.push({ name: 'Buildings' });
          this.$toasted.show(this.$t('toast.success'), { type: 'success' });
        }
      } catch (error) {
        this.$toasted.show(error.message, { type: 'error' });
      }
    },

    onChangeCity() {
      this.form.districtId = null;
      this.form.horooId = null;
      this.form.townId = null;
      const index = this.cityList.findIndex(item => item.id === this.form.cityId);
      if (index > -1) {
        this.districts = this.cityList[index].districts;
      } else {
        this.districts = [];
      }
    },

    onChangeDistrict() {
      this.form.horooId = null;
      this.form.townId = null;
      const index = this.districts.findIndex(item => item.id === this.form.districtId);
      if (index > -1) {
        this.horoos = this.districts[index].horoos;
      } else {
        this.horoos = [];
      }
    },

    onChangeHoroo() {
      this.form.townId = null;
      const index = this.horoos.findIndex(item => item.id === this.form.horooId);
      if (index > -1) {
        this.towns = this.horoos[index].towns;
      } else {
        this.towns = [];
      }
    },
  },
};
</script>
