<template>
  <ModalEdit
    :id="modalId"
    :title="$t('text.sentSMSLogs')"
    :loading="isLoading"
    size="xl"
    :save-button="false"
  >
    <BusinessSmsLogsTable
      :loading="isLoading"
      :rows="smsLogs"
      :columns="columns"
      :line-numbers="true"
      :sort="sort"
      :page="page"
      :per-page="perPage"
      :page-size="pageSize"
      :total-page="totalPage"
      class=" shadow-none rounded-none"
      @onPageChange="onPageChange"
      @onPerPageChange="onPerPageChange"
      @onSortChange="onSortChange"
    />
  </ModalEdit>
</template>

<script>
import { mapGetters } from 'vuex';
import { SMS_LOGS_REQUEST } from '@/store/actions/smsPackage';
import dataListMixin from '@/mixins/dataListMixin';
import ModalEdit from '@/components/ui/ModalEdit';
import baseModalMixin from '@/mixins/baseModalMixin';
import BusinessSmsLogsTable from '@/components/business/sms/BusinessSmsLogsTable';

export default {
  mixins: [baseModalMixin, dataListMixin],

  props: {
    business: Object,
  },

  data() {
    return {
      storeActionListRequest: SMS_LOGS_REQUEST,
      perPage: 10,
      smsBalanceId: null,
      columns: [
        { label: this.$t('table.text'), field: 'text' },
        { label: this.$t('table.buildingName'), field: 'buildingName' },
        { label: this.$t('table.name'), field: 'number' },
        { label: this.$t('table.status'), field: 'status' },
      ],
    };
  },

  computed: {
    ...mapGetters(['smsLogsStatus', 'smsLogs', 'smsLogsMeta']),
    isLoading() {
      return this.smsLogsStatus === 'loading';
    },
  },

  methods: {
    showModal(id) {
      this.smsBalanceId = id;
      cash(`#${this.modalId}`).modal('show');
      this.fetchData();
    },
    setFilterValues(data) {
      data.filters['filter[smsBalanceId]'] = this.smsBalanceId;
    },
    setPaginationValues() {
      this.totalRecords = this.smsLogsMeta ? this.smsLogsMeta.total : 1;
      this.page = this.smsLogsMeta.currentPage;
      this.totalPage = this.smsLogsMeta.lastPage;
    },
  },

  components: {
    ModalEdit,
    BusinessSmsLogsTable,
  },
};
</script>
