<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { FIREBASE_INIT_APP, FIREBASE_INIT_MESSAGING } from '@/store/actions/firebase';

export default {
  beforeMount() {
    if (process.env.NODE_ENV === 'production') {
      const d = this.$store.dispatch;
      d(FIREBASE_INIT_APP).then(() => d(FIREBASE_INIT_MESSAGING));
    }
  },
};
</script>
