export const BUILDING_LIST_REQUEST = 'BUILDING_LIST_REQUEST';
export const BUILDING_LIST_REQUEST_SUCCESS = 'BUILDING_LIST_REQUEST_SUCCESS';
export const BUILDING_LIST_REQUEST_ERROR = 'BUILDING_LIST_REQUEST_ERROR';

export const BUILDING_ADD = 'BUILDING_ADD';
export const BUILDING_ADD_SUCCESS = 'BUILDING_ADD_SUCCESS';
export const BUILDING_ADD_ERROR = 'BUILDING_ADD_ERROR';

export const BUILDING_UPDATE = 'BUILDING_UPDATE';
export const BUILDING_UPDATE_SUCCESS = 'BUILDING_UPDATE_SUCCESS';
export const BUILDING_UPDATE_ERROR = 'BUILDING_UPDATE_ERROR';

export const BUILDING_REMOVE = 'BUILDING_REMOVE';
export const BUILDING_REMOVE_SUCCESS = 'BUILDING_REMOVE_SUCCESS';
export const BUILDING_REMOVE_ERROR = 'BUILDING_REMOVE_ERROR';

export const BUILDING_REQUEST = 'BUILDING_REQUEST';
export const BUILDING_REQUEST_SUCCESS = 'BUILDING_REQUEST_SUCCESS';
export const BUILDING_REQUEST_ERROR = 'BUILDING_REQUEST_ERROR';

export const BUILDING_CHILD_LIST_IMPORT = 'BUILDING_CHILD_LIST_IMPORT';
export const BUILDING_CHILD_LIST_IMPORT_SUCCESS = 'BUILDING_CHILD_LIST_IMPORT_SUCCESS';
export const BUILDING_CHILD_LIST_IMPORT_ERROR = 'BUILDING_CHILD_LIST_IMPORT_ERROR';
