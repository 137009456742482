<template>
  <div class="box building-list-item">
    <div class="grid grid-cols-12 gap-6 p-5 xxl:items-center">
      <div class="col-span-12 xxl:col-span-7 flex flex-col lg:flex-row items-center">
        <div class="w-16 h-16 flex-none image-fit">
          <!-- <img :alt="building.name" src="@/assets/img/location.svg" class="rounded-md" /> -->
          <div
            class="flex items-center justify-center w-16 h-16 bg-gray-200 dark:bg-dark-5 rounded-md"
          >
            <ImageIcon />
          </div>
        </div>
        <BuildingFullname
          :item="item"
          class="lg:ml-3 text-center lg:text-left mt-3 lg:mt-0 overflow-hidden"
        />
      </div>
      <div class="col-span-12 xxl:col-span-5">
        <div class="text-gray-600 text-xs">
          {{ $t('text.totalShortApartments') }}:
          <span class="ml-1 font-medium">{{ item.apartmentsCount || 0 }}</span>
        </div>
        <div class="text-gray-600 text-xs">
          {{ $t('text.totalShortOwners') }}:
          <span class="ml-1 font-medium">{{ item.ownersCount || 0 }}</span>
        </div>
        <div class="text-gray-600 text-xs">
          {{ $t('text.totalShortParking') }}:
          <span class="ml-1 font-medium">{{ item.parkingCount || 0 }}</span>
        </div>
      </div>
    </div>
    <div class="border-t border-gray-200 dark:border-dark-5 flex items-center justify-between">
      <router-link
        v-if="checkPermission('registration.building.show')"
        :to="{ name: 'Apartments', params: { buildingId: item.id } }"
        class="flex flex-1 items-center justify-center py-4"
      >
        <EyeIcon class="w-4 h-4 mr-1 hidden sm:block" />
        {{ $t('table.read') }}
      </router-link>
      <router-link
        v-if="checkPermission('registration.building.edit')"
        :to="{ name: 'BuildingUpdate', params: { buildingId: item.id } }"
        class="flex flex-1 items-center justify-center py-4"
      >
        <CheckSquareIcon class="w-4 h-4 mr-1 hidden sm:block" />
        {{ $t('table.edit') }}
      </router-link>
      <a
        v-if="checkPermission('registration.building.delete')"
        href="javascript:;"
        @click="$emit('delete', item.id)"
        class="flex flex-1 items-center justify-center py-4 text-theme-6"
      >
        <Trash2Icon class="w-4 h-4 mr-1 hidden sm:block" />
        {{ $t('table.delete') }}
      </a>
      <!-- <a
        v-if="checkPermission('registration.building.download')"
        href="javascript:;"
        class="flex flex-1 items-center justify-center py-4"
      >
        <DownloadIcon class="w-4 h-4 mr-1 hidden sm:block" />
        {{ $t('table.download') }}
      </a> -->
    </div>
  </div>
</template>

<script>
import BuildingFullname from '@/components/building/BuildingFullname';
import permissionMixin from '@/mixins/permissionMixin';

export default {
  mixins: [permissionMixin],

  props: {
    item: Object,
  },

  components: { BuildingFullname },
};
</script>
