export const TICKET_LIST_REQUEST = 'TICKET_LIST_REQUEST';
export const TICKET_LIST_REQUEST_SUCCESS = 'TICKET_LIST_REQUEST_SUCCESS';
export const TICKET_LIST_REQUEST_ERROR = 'TICKET_LIST_REQUEST_ERROR';

export const TICKET_LIST_REQUEST_ASSIGNED = 'TICKET_LIST_REQUEST_ASSIGNED';
export const TICKET_LIST_REQUEST_CONFIRMED = 'TICKET_LIST_REQUEST_CONFIRMED';
export const TICKET_LIST_REQUEST_FINISHED = 'TICKET_LIST_REQUEST_FINISHED';

export const TICKET_SEARCH = 'TICKET_SEARCH';
export const TICKET_SEARCH_SUCCESS = 'TICKET_SEARCH_SUCCESS';
export const TICKET_SEARCH_ERROR = 'TICKET_SEARCH_ERROR';
export const TICKET_SEARCH_LIST_CLEAR = 'TICKET_SEARCH_LIST_CLEAR';

export const TICKET_ADD = 'TICKET_ADD';
export const TICKET_ADD_SUCCESS = 'TICKET_ADD_SUCCESS';
export const TICKET_ADD_ERROR = 'TICKET_ADD_ERROR';

export const TICKET_UPDATE = 'TICKET_UPDATE';
export const TICKET_UPDATE_SUCCESS = 'TICKET_UPDATE_SUCCESS';
export const TICKET_UPDATE_ERROR = 'TICKET_UPDATE_ERROR';

export const TICKET_REMOVE = 'TICKET_REMOVE';
export const TICKET_REMOVE_SUCCESS = 'TICKET_REMOVE_SUCCESS';
export const TICKET_REMOVE_ERROR = 'TICKET_REMOVE_ERROR';

export const TICKET_REQUEST = 'TICKET_REQUEST';
export const TICKET_REQUEST_SUCCESS = 'TICKET_REQUEST_SUCCESS';
export const TICKET_REQUEST_ERROR = 'TICKET_REQUEST_ERROR';

export const TICKET_CATEGORIES_REQUEST = 'TICKET_CATEGORIES_REQUEST';
export const TICKET_CATEGORIES_REQUEST_SUCCESS = 'TICKET_CATEGORIES_REQUEST_SUCCESS';
export const TICKET_CATEGORIES_REQUEST_ERROR = 'TICKET_CATEGORIES_REQUEST_ERROR';

export const TICKET_TYPES_REQUEST = 'TICKET_TYPES_REQUEST';
export const TICKET_TYPES_REQUEST_SUCCESS = 'TICKET_TYPES_REQUEST_SUCCESS';
export const TICKET_TYPES_REQUEST_ERROR = 'TICKET_TYPES_REQUEST_ERROR';

export const TICKET_STATUS_CHANGE = 'TICKET_STATUS_CHANGE';
export const TICKET_STATUS_CHANGE_SUCCESS = 'TICKET_STATUS_CHANGE_SUCCESS';
export const TICKET_STATUS_CHANGE_ERROR = 'TICKET_STATUS_CHANGE_ERROR';
