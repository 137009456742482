<template>
  <div class="building-fullname">
    <p class="font-medium truncate">{{ item.name }}</p>
    <p v-if="item.district" class="text-gray-600 text-xs truncate">
      {{ item.district.name }} {{ item.horoo.name }}
    </p>
    <p v-if="item.town" class="text-gray-600 text-xs truncate">
      {{ item.town.name }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.building-fullname {
  & + .building-fullname {
    @apply mt-2;
  }
}
</style>
