<template>
  <TableRegular
    :loading="loading"
    :downloading="downloading"
    :rows="rows"
    :columns="columns"
    :sort="sort"
    :page="page"
    :per-page="perPage"
    :page-size="pageSize"
    :total-page="totalPage"
    :line-numbers="lineNumbers"
    :downloadable="downloadable && checkPermission('finance.income.download')"
    :custom-filters="customFilters"
    @onFilter="$emit('onFilter', $event)"
    @onPageChange="$emit('onPageChange', $event)"
    @onPerPageChange="$emit('onPerPageChange', $event)"
    @onSortChange="$emit('onSortChange', $event)"
    @onDownload="$emit('onDownload')"
    @onChangeCustomFilter="$emit('onChangeCustomFilter', $event)"
    @onSuccessFilter="$emit('onSuccessFilter', $event)"
  >
    <template slot="table-row" slot-scope="props">
      <p v-if="props.column.field === 'business'" class="font-medium whitespace-nowrap">
        {{ props.row.business.name }}
      </p>
      <p v-else-if="props.column.field === 'amount'" class="font-medium whitespace-nowrap">
        {{ props.row.amount | currency }}
      </p>
      <p v-else-if="props.column.field === 'paidAmount'" class="font-medium whitespace-nowrap">
        {{ props.row.paidAmount | currency }}
      </p>
      <p v-else-if="props.column.field === 'startDate'" class="whitespace-nowrap">
        {{ props.row.startDate.slice(0, 7) }} {{ $t('table.month') }}
      </p>
      <p v-else-if="props.column.field === 'paidDate'" class="whitespace-nowrap">
        {{ props.row.paidDate | momentDate('YYYY-MM-DD HH:mm') }}
      </p>
      <p v-else-if="props.column.field === 'date'" class="whitespace-nowrap">
        {{ props.row.date | dateAndTimeToLocal('YYYY-MM-DD HH:mm') }}
      </p>
      <p v-else-if="props.column.field === 'smsPackage'" class="whitespace-nowrap">
        {{ props.row.smsPackage.name }}
      </p>
      <div v-else-if="props.column.field === 'status'" class="whitespace-nowrap">
        <div v-if="props.row.status" :class="['status', `is-${props.row.status.toLowerCase()}`]">
          {{ $t(`status.${props.row.status.toLowerCase()}`) }}
        </div>
      </div>
      <p
        v-else-if="props.column.field === 'paid' && props.row.status !== 'PAID'"
        class="whitespace-nowrap"
      >
        <select
          class="input w-full xxl:w-full sm:w-auto border"
          @change="
            onChange(props.row.total, $event, props.row.id, props.row.status, props.row.paidAmount)
          "
        >
          <option value="">--сонго--</option>
          <option v-for="(item, index) in paidAmountStatus" :key="index" :value="item.id">
            {{ item.name }}
          </option>
        </select>
      </p>
      <p v-else-if="props.column.field === 'action'" class="flex justify-center items-center">
        <a href="javascript:" @click="$emit('show', props.row.id)" class="flex items-center">
          <EyeIcon class="w-4 h-4 mr-1" />
          {{ $t('table.read') }}
        </a>
      </p>
    </template>
    <template #footer>
      <tr v-if="!loading && rows.length > 0 && footerItems">
        <th v-if="lineNumbers"></th>
        <th></th>
        <th></th>
        <th>{{ $t('text.totalAmount') }}:</th>
        <th>
          <template v-if="footerItems.hasOwnProperty('totalAmount')">
            {{ footerItems.totalAmount | currency }}
          </template>
        </th>
        <th>
          <template v-if="footerItems.hasOwnProperty('totalPaidAmount')">
            {{ footerItems.totalPaidAmount | currency }}
          </template>
        </th>
        <th>{{ $t('table.balance') }}:</th>
        <th>
          <template v-if="footerItems.hasOwnProperty('totalBalance')">
            {{ footerItems.totalBalance | currency }}
          </template>
        </th>
        <th></th>
        <th></th>
        <th>
          <template v-if="footerItems.hasOwnProperty('total')">
            {{ $t('text.totalPieces') }}: {{ footerItems.total }}
          </template>
        </th>
      </tr>
    </template>
  </TableRegular>
</template>

<script>
import TableRegular from '@/components/ui/table/TableRegular';
import tableMixin from '@/mixins/tableMixin';

export default {
  mixins: [tableMixin],
  props: { footerItems: Object },
  components: { TableRegular },
  data() {
    return {
      paidAmountStatus: [
        {
          id: 'PAID',
          name: this.$t('status.paid'),
        },
      ],
      paidStatus: {
        status: '',
        amount: '',
        id: '',
      },
    };
  },
  methods: {
    onChange(atotal, event, invoId, Astatus, paidAmount) {
      if (Astatus === 'UNDER_PAID') {
        this.paidStatus.amount = atotal - paidAmount;
        this.paidStatus.id = invoId;
        this.paidStatus.status = event.target.value;
      } else {
        this.paidStatus.amount = atotal;
        this.paidStatus.id = invoId;
        this.paidStatus.status = event.target.value;
      }
      this.$emit('onChangePaidStatus', this.paidStatus);
    },
  },
};
</script>
