<template>
  <div>
    <DarkModeSwitcher />
    <MobileMenu />
    <div class="flex">
      <Navigation />
      <Content />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DarkModeSwitcher from '@/components/ui/DarkModeSwitcher';
import MobileMenu from '@/views/layouts/MobileMenu';
import Navigation from '@/views/layouts/Navigation';
import Content from '@/views/layouts/Content';

export default {
  name: 'Main',
  computed: {
    ...mapGetters(['me']),
  },
  watch: {
    $route: {
      handler() {
        this.checkBusiness();
      },
    },
  },
  mounted() {
    this.checkBusiness();
    cash('body')
      .removeClass('login')
      .addClass('app');
  },
  methods: {
    checkBusiness() {
      if (this.me.business?.buildingsCount === 0 && this.$route.name !== 'BusinessSetup') {
        this.$router.push({ name: 'BusinessSetup' });
      }
    },
  },
  components: { DarkModeSwitcher, MobileMenu, Navigation, Content },
};
</script>
