import {
  ORDER_LIST_REQUEST,
  ORDER_LIST_REQUEST_ERROR,
  ORDER_LIST_REQUEST_SUCCESS,
} from '@/store/actions/order';
import { handleRequestError } from '@/utils/error';

import Repository, { ORDER } from '@/repositories/RepositoryFactory';

const OrderRepository = Repository.get(ORDER);

const state = {
  orderList: [],
  orderMeta: '',
  orderStatus: '',
};

const getters = {
  orderList: state => state.orderList,
  orderMeta: state => state.orderMeta,
  orderStatus: state => state.orderStatus,
};

const actions = {
  [ORDER_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(ORDER_LIST_REQUEST);
    try {
      const response = await OrderRepository.get(payload.data);
      commit(ORDER_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(ORDER_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
};

const mutations = {
  [ORDER_LIST_REQUEST]: state => {
    state.orderStatus = 'loading';
  },
  [ORDER_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.orderStatus = 'success';
    state.orderList = payload.data;
    state.orderMeta = payload.meta;
  },
  [ORDER_LIST_REQUEST_ERROR]: state => {
    state.orderStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
