<template>
  <div class="text-theme-6 mt-2">
    <p v-if="vuelidate.hasOwnProperty('required') && !vuelidate.required">
      {{ $t('validation.fieldIsRequired') }}
    </p>
    <p v-else-if="vuelidate.hasOwnProperty('minValue') && !vuelidate.minValue">
      {{ $t('validation.minValue', { number: vuelidate.$params.minValue.min }) }}
    </p>
    <p v-else-if="vuelidate.hasOwnProperty('minValueAmount') && !vuelidate.minValueAmount">
      {{
        $t('validation.minValueAmount', {
          number: $options.filters.currency(vuelidate.$params.minValueAmount.min),
        })
      }}
    </p>
    <p v-else-if="vuelidate.hasOwnProperty('minLength') && !vuelidate.minLength">
      {{ $t('validation.mustHaveAtLeast', { number: vuelidate.$params.minLength.min }) }}
    </p>
    <p v-else-if="vuelidate.hasOwnProperty('email') && !vuelidate.email">
      {{ $t('validation.pleaseEnterValidEmailAddress') }}
    </p>
    <p v-else-if="vuelidate.hasOwnProperty('integer') && !vuelidate.integer">
      {{ $t('validation.fieldIsIntegerOnly') }}
    </p>
    <p v-else-if="vuelidate.hasOwnProperty('maxLength') && !vuelidate.maxLength">
      {{ $t('validation.mustHaveAtMost', { number: vuelidate.$params.maxLength.max }) }}
    </p>
    <p v-else-if="vuelidate.hasOwnProperty('url') && !vuelidate.url">
      {{ $t('validation.fieldIsUrlFormatOnly') }}
    </p>
    <p v-else-if="vuelidate.hasOwnProperty('numeric') && !vuelidate.numeric">
      {{ $t('validation.fieldIsNumericOnly') }}
    </p>
    <p v-else-if="vuelidate.hasOwnProperty('decimal') && !vuelidate.decimal">
      {{ $t('validation.fieldIsDecimalOnly') }}
    </p>
    <p v-else-if="vuelidate.hasOwnProperty('phoneNumber') && !vuelidate.phoneNumber">
      {{ $t('validation.fieldIsPhoneNumber') }}
    </p>
    <p
      v-else-if="vuelidate.hasOwnProperty('companyRegistration') && !vuelidate.companyRegistration"
    >
      {{ $t('validation.fieldIsCompanyRegistrationNumber') }}
    </p>
    <p v-else-if="vuelidate.hasOwnProperty('sameAs') && !vuelidate.sameAs">
      {{ $t('validation.sameAs') }}
    </p>
    <p v-else-if="vuelidate.hasOwnProperty('registrationNumber') && !vuelidate.registrationNumber">
      {{ $t('validation.fieldIsRegistrationNumber') }}
    </p>
    <p v-else-if="vuelidate.hasOwnProperty('carNumber') && !vuelidate.carNumber">
      {{ $t('validation.fieldIsCarNumber') }}
    </p>
    <p v-else-if="invalidFeedback">
      {{ invalidFeedback }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    vuelidate: [Object, Array],
    invalidFeedback: String,
  },
};
</script>
