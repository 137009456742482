<template>
  <div v-if="loading" class="flex items-center justify-center flex-col py-3">
    <LoadingIcon icon="oval" class="w-10 h-10" />
    <p class="text-gray-500 mt-3">{{ $t('table.loading') }}</p>
  </div>
  <div v-else class="cursor-pointer border rounded-md border-blue-300">
    <div class="flex items-center px-5 pt-5 pb-5 border-b border-gray-200">
      <div class="w-full flex flex-col lg:flex-row items-center">
        <div class="flex-1">
          <h3 class="text-base font-medium text-blue-700">{{ meeting.name }}</h3>
          <span class="text-sm">хурлын сэдэв</span>
        </div>
        <div class="flex-3 w-18 h-10">
          <div class="flex gap-2 items-center">
            <router-link
              :to="{ name: 'MeetingUpdate', params: { id: meeting.id, businessId: businessId } }"
              class="border bg-white hover:bg-gray-100 rounded-full border-gray-400 px-3 py-3"
            >
              <CheckSquareIcon class="w-4 h-4" />
            </router-link>
            <button
              @click="clickTrash"
              class="border bg-white hover:bg-gray-100 rounded-full border-gray-400 px-3 py-3"
            >
              <Trash2Icon class="w-4 h-4" />
            </button>
          </div>
        </div>
        <div class="flex-2 w-40">
          <router-link
            :to="{ name: 'MeetingStart', params: { id: meeting.id, businessId: businessId } }"
            class="button text-white bg-theme-1 shadow-md mr-8 ml-8"
            target="_blank"
          >
            {{ $t('meeting.start') }}
          </router-link>
        </div>
      </div>
    </div>
    <div class="flex flex-row px-5 pb-5 pt-5">
      <div class="w-3/5">
        <div>
          <span class="text-sm">Болох огноо:</span>
          <span class="text-base float-right font-medium text-blue-700">
            {{ meeting.scheduledAt }}
          </span>
        </div>
        <div>
          <span class="text-sm">Үргэлжлэх хугацаа:</span>
          <span class="text-base float-right font-medium text-blue-700">{{ getHours }} цаг</span>
        </div>
      </div>
      <div class="w-2/5 text-right"></div>
    </div>
    <div class="flex items-center justify-between">
      <div class="w-full">
        <div class="w-full pb-3 px-5 text-base font-medium border-b border-gray-200">
          Хамрагдсан цар хүрээ:
        </div>
        <div class="w-full px-5">
          <div class="" v-for="(building, index) in meeting.buildings" :key="index">
            <span
              class="w-auto float-left mt-3 mb-3 border rounded-md border-gray-400 px-2 mr-2 text-xs text-blue-700"
            >
              {{ building.name }} -р байр
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-between">
      <div class="w-full">
        <div class="w-full pb-3 px-5 text-base font-medium border-b border-gray-200">
          Хурлын ирц:
        </div>
        <div class="w-full px-5">
          <div class="" v-for="(building, index) in meeting.buildings" :key="index">
            <span
              class="w-auto float-left mt-3 mb-3 border rounded-md border-gray-400 px-2 mr-2 text-xs text-blue-700"
            >
              {{ building.name }} -р байр
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-between">
      <div class="w-full">
        <div class="w-full pt-3 px-5 text-base font-medium">
          Санал асуулга:
        </div>
        <div class="w-full px-5">
          <div class="">
            <PollsTable
              :rows="meeting.polls"
              :columns="columns"
              :line-numbers="true"
              @delete="confirmRemoval"
              @show="onShowModal($event)"
            />
          </div>
        </div>
      </div>
    </div>
    <ConfirmDialog
      :modal-id="deleteModalId"
      :is-loading="isDeleting ? true : false"
      @ok="onDelete"
    />
    <ConfirmDialog
      :modal-id="deleteMeetingId"
      :is-loading="isDeleting ? true : false"
      @ok="onDeleteMeeting"
    />
  </div>
</template>

<script>
import PollsTable from '@/components/meeting/PollsTable';
import dataListMixin from '@/mixins/dataListMixin';
import { mapGetters } from 'vuex';
import { POLLS_DELETE, POLLS_SHOW } from '@/store/actions/polls';
import { MEETING_REMOVE } from '@/store/actions/meeting';

export default {
  mixins: [dataListMixin],
  props: {
    meeting: Object,
    loading: Boolean,
    businessId: Number,
  },
  data() {
    return {
      deleteMeetingId: `delete-meeting-modal-${Math.round(Math.random() * 1000)}`,
      storeActionRemove: POLLS_DELETE,
      showModalId: 'chart-show-modal',
      columns: [
        { label: this.$t('table.name'), field: 'title', filterable: 'true' },
        { label: this.$t('table.content'), field: 'description', filterable: 'true' },
        { label: this.$t('table.submitted'), field: 'totalNumber' },
        { label: this.$t('table.voted'), field: 'totalSubmit' },
        { label: this.$t('table.status'), field: 'status' },
        {
          label: this.$t('table.respondent'),
          field: 'assignedName',
          filterable: 'true',
          disabled: true,
        },
        { label: this.$t('table.action'), field: 'action' },
      ],
    };
  },
  computed: {
    ...mapGetters(['poll', 'pollStatus']),
    getHours() {
      return this.meeting.duration / 60;
    },
  },
  methods: {
    async onShowModal(pollId) {
      const payload = { id: pollId };
      await this.$store.dispatch(POLLS_SHOW, payload);
      if (this.pollStatus === 'success') {
        await cash(`#${this.showModalId}`).modal('show');
      }
    },
    async clickTrash() {
      cash(`#${this.deleteMeetingId}`).modal('show');
    },
    async onDeleteMeeting() {
      cash(`#${this.deleteMeetingId}`).modal('hide');
      const payload = {
        id: this.meeting.id,
        businessId: this.meeting.businessId,
      };
      await this.$store.dispatch(MEETING_REMOVE, payload);
      this.$emit('close');
    },
  },
  components: {
    PollsTable,
  },
};
</script>
