<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-5">
      <PageHeader class="text-lg font-medium mr-auto" />
    </div>
    <div class="intro-y mt-5 overflow-auto lg:overflow-visible">
      <OwnerRequestsTable
        :loading="isLoading"
        :rows="ownerRequestList"
        :columns="columns"
        :line-numbers="true"
        :sort="sort"
        :page="page"
        :per-page="perPage"
        :page-size="pageSize"
        :total-page="totalPage"
        :custom-filters="customFilters"
        @approve="confirmApprove"
        @delete="confirmRemoval"
        @onFilter="onFilter"
        @onPageChange="onPageChange"
        @onPerPageChange="onPerPageChange"
        @onSortChange="onSortChange"
        @onDownload="onDownload"
        @onChangeCustomFilter="onChangeCustomFilter"
      />
    </div>
    <ApproveDialog :modal-id="approveModalId" @ok="toApprove" />
    <ConfirmDialog
      :modal-id="deleteModalId"
      :is-loading="isDeleting ? true : false"
      @ok="onDeleteOwner"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  OWNER_REQUEST_LIST,
  OWNER_APPROVE_CONFIRM,
  OWNER_REQUEST_REMOVE,
} from '@/store/actions/owner';
import PageHeader from '@/components/ui/PageHeader';
import ApproveDialog from '@/components/ui/ApproveDialog';
import OwnerRequestsTable from '@/components/owner/OwnerRequestsTable';
import { isNaN } from 'lodash';
import dataListMixin from '@/mixins/dataListMixin';
import permissionMixin from '@/mixins/permissionMixin';
import ConfirmDialog from '@/components/ui/ConfirmDialog';

export default {
  mixins: [dataListMixin, permissionMixin],

  components: {
    PageHeader,
    OwnerRequestsTable,
    ApproveDialog,
    ConfirmDialog,
  },

  data() {
    return {
      storeActionListRequest: OWNER_REQUEST_LIST,
      storeActionConfirm: OWNER_APPROVE_CONFIRM,
      storeActionRemove: OWNER_REQUEST_REMOVE,
      customFilters: [
        {
          key: 'status',
          value: null,
          label: this.$t('text.status'),
          type: 'select',
          options: [
            { id: 'PENDING', name: this.$t('status.pending') },
            { id: 'CONFIRMED', name: this.$t('status.confirmed') },
          ],
        },
      ],
      deleteModalId: 'owner-car-delete-confirm-dialog',
    };
  },

  computed: {
    ...mapGetters(['ownerRequestList', 'ownerRequestListStatus', 'ownerRequestMeta']),
    isLoading() {
      return this.ownerRequestListStatus === 'loading';
    },
    columns() {
      let array = [];
      array = [
        { label: this.$t('table.appUser'), field: 'firstName', filterable: true },
        { label: this.$t('table.phone'), field: 'phone', filterable: true },
        { label: this.$t('table.buildingName'), field: 'building' },
        { label: this.$t('table.doorNumber'), field: 'number', filterable: true },
        { label: this.$t('table.status'), field: 'status' },
        { label: this.$t('table.date'), field: 'createdAt', sortable: true },
      ];

      if (this.checkPermission('userRequest.userRequest.confirm')) {
        array.push({ label: this.$t('table.action'), field: 'action' });
      }
      return array;
    },
  },

  async created() {
    this.fetchData();
  },

  methods: {
    onChangeCustomFilter(object) {
      const index = this.customFilters.findIndex(item => item.key === object.key);
      if (index > -1) {
        this.customFilters[index].value = isNaN(object.value) ? null : object.value;
        this.fetchData();
      }
    },

    setFilterValues(data) {
      this.customFilters.forEach(item => {
        data.filters[`filter[${item.key}]`] = item.value;
      });
    },

    setPaginationValues() {
      this.totalRecords = this.ownerRequestMeta ? this.ownerRequestMeta.total : 1;
      this.page = this.ownerRequestMeta.currentPage;
      this.totalPage = this.ownerRequestMeta.lastPage;
    },
  },
};
</script>
