export default {
  methods: {
    $importScript(path) {
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        const plugin = document.createElement('script');
        plugin.setAttribute('src', path);
        plugin.id = path;
        plugin.async = true;
        document.head.appendChild(plugin);

        plugin.addEventListener('load', () => resolve(null));
      });
    },
    $removeScript(path) {
      document.getElementById(path).remove();
    },
  },
};
