import Index from '@/views/SmsBalances/Index';
import SmsBalances from '@/views/SmsBalances/SmsBalances';
import SmsBalanceForm from '@/views/SmsBalances/SmsBalanceForm';

export default [
  {
    path: '/sms-balances',
    component: Index,
    meta: { group: 'sms_balances' },
    children: [
      {
        path: '',
        component: SmsBalances,
        name: 'SmsBalances',
      },
      {
        path: 'create',
        name: 'SmsBalanceCreate',
        component: SmsBalanceForm,
      },
      {
        path: 'update/:id',
        name: 'SmsBalanceUpdate',
        component: SmsBalanceForm,
      },
    ],
  },
];
