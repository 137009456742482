import Index from '@/views/Meeting/Index';
import Meeting from '@/views/Meeting/MeetingIndex';
import MeetingForm from '@/views/Meeting/MeetingCreate';
// import MeetingStart from '@/views/Meeting/MeetingStart';

export default [
  {
    path: '/meeting',
    component: Index,
    meta: { group: 'meeting' },
    children: [
      {
        path: '',
        component: Meeting,
        name: 'Meeting',
      },
      {
        path: 'create',
        name: 'MeetingCreate',
        component: MeetingForm,
      },
      {
        path: 'update/:id',
        name: 'MeetingUpdate',
        component: MeetingForm,
      },
      // {
      //   path: 'start/:id',
      //   name: 'MeetingStart',
      //   component: MeetingStart,
      // },
    ],
  },
];
