import BuildingIndex from '@/views/BuildingIndex';
import Buildings from '@/views/Buildings';
import BuildingForm from '@/views/BuildingForm';
import BuildingDetails from '@/views/BuildingDetails';
import Owners from '@/views/Owners';
import OwnerForm from '@/views/OwnerForm';
import Apartments from '@/views/Apartments';
import Parkings from '@/views/Parkings';
import ApartmentForm from '@/views/ApartmentForm';
import ParkingsForm from '@/views/ParkingsForm';

export default [
  {
    path: '/buildings',
    component: BuildingIndex,
    meta: { group: 'registration' },
    children: [
      {
        path: '',
        name: 'Buildings',
        component: Buildings,
      },
      {
        path: 'update/:buildingId',
        name: 'BuildingUpdate',
        component: BuildingForm,
      },
      {
        path: 'create',
        name: 'BuildingCreate',
        component: BuildingForm,
      },
      {
        path: ':buildingId',
        component: BuildingDetails,
        children: [
          {
            path: '',
            name: 'Apartments',
            component: Apartments,
          },
          {
            path: 'owners',
            name: 'Owners',
            component: Owners,
          },
          {
            path: 'parkings',
            name: 'Parkings',
            component: Parkings,
          },
        ],
      },
      {
        path: ':buildingId/owners/update/:ownerId',
        name: 'OwnerUpdate',
        component: OwnerForm,
      },
      {
        path: ':buildingId/owners/create',
        name: 'OwnerCreate',
        component: OwnerForm,
      },

      {
        path: ':buildingId/apartment/update/:apartmentId',
        name: 'ApartmentUpdate',
        component: ApartmentForm,
      },
      {
        path: ':buildingId/apartment/create',
        name: 'ApartmentCreate',
        component: ApartmentForm,
      },
      {
        path: ':buildingId/parking/update/:parkingId',
        name: 'ParkingUpdate',
        component: ParkingsForm,
      },
      {
        path: ':buildingId/parking/create',
        name: 'ParkingCreate',
        component: ParkingsForm,
      },
    ],
  },
];
