<template>
  <LineChart :width="width" :height="height" :chart-data="data" :options="options" />
</template>

<script>
import LineChart from '@/components/ui/chart/LineChart';
import { formatMoney } from 'accounting-js';

export default {
  components: { LineChart },
  props: {
    width: {
      type: Number,
      default: 150,
    },
    height: {
      type: Number,
      default: 150,
    },
    headers: {},
    statistics: {},
  },
  computed: {
    data() {
      return {
        labels: [
          '1 сар',
          '2 сар',
          '3 сар',
          '4 сар',
          '5 сар',
          '6 сар',
          '7 сар',
          '8 сар',
          '9 сар',
          '10 сар',
          '11 сар',
          '12 сар',
        ],
        datasets: [
          {
            label: this.headers.invoice,
            data: this.statistics.invoice,
            borderWidth: 2,
            borderColor: '#3160D8',
            backgroundColor: 'transparent',
            pointBorderColor: '#3160D8',
            pointBackgroundColor: '#ffffff',
          },
          {
            label: this.headers.income,
            data: this.statistics.income,
            borderWidth: 2,
            borderColor: '#07ab43',
            backgroundColor: 'transparent',
            pointBorderColor: '#07ab43',
            pointBackgroundColor: '#ffffff',
          },
          {
            label: this.headers.receivable,
            data: this.statistics.receivable,
            borderWidth: 2,
            borderColor: '#FFA500',
            backgroundColor: 'transparent',
            pointBorderColor: '#FFA500',
            pointBackgroundColor: '#ffffff',
          },
        ],
      };
    },
    options() {
      return {
        scales: {
          xAxes: [
            {
              ticks: {
                fontSize: '12',
                fontColor: '#777777',
              },
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                fontSize: '12',
                fontColor: '#777777',
                callback(value) {
                  return formatMoney(value / 100, { symbol: '₮', format: '%v%s' }, 2);
                },
              },
              gridLines: {
                color: '#D8D8D8',
                zeroLineColor: '#D8D8D8',
                borderDash: [2, 2],
                zeroLineBorderDash: [2, 2],
                drawBorder: false,
              },
            },
          ],
        },
      };
    },
  },
};
</script>
