import { find, findIndex } from 'lodash';
import {
  BUILDING_LIST_REQUEST,
  BUILDING_LIST_REQUEST_ERROR,
  BUILDING_LIST_REQUEST_SUCCESS,
  BUILDING_ADD,
  BUILDING_ADD_ERROR,
  BUILDING_ADD_SUCCESS,
  BUILDING_REQUEST,
  BUILDING_REQUEST_SUCCESS,
  BUILDING_REQUEST_ERROR,
  BUILDING_REMOVE,
  BUILDING_REMOVE_ERROR,
  BUILDING_REMOVE_SUCCESS,
  BUILDING_UPDATE,
  BUILDING_UPDATE_ERROR,
  BUILDING_UPDATE_SUCCESS,
  BUILDING_CHILD_LIST_IMPORT,
  BUILDING_CHILD_LIST_IMPORT_SUCCESS,
  BUILDING_CHILD_LIST_IMPORT_ERROR,
} from '@/store/actions/building';
import { handleRequestError } from '@/utils/error';

import Repository, { BUILDINGS, OWNERS, APARTMENTS } from '@/repositories/RepositoryFactory';

const BuildingsRepository = Repository.get(BUILDINGS);
const OwnersRepository = Repository.get(OWNERS);
const ApartmentsRepository = Repository.get(APARTMENTS);

const state = {
  building: '',
  buildingList: [],
  buildingMeta: '',
  buildingStatus: '',
  buildingChildImportStatus: '',
};

const getters = {
  getBuildingById: (state, getters) => payload => {
    const id = parseInt(payload, 10);
    if (getters.buildingList.length > 0) {
      return find(getters.buildingList, { id });
    }

    return state.building;
  },
  building: state => state.building,
  buildingList: state => state.buildingList,
  buildingMeta: state => state.buildingMeta,
  buildingStatus: state => state.buildingStatus,
  buildingChildImportStatus: state => state.buildingChildImportStatus,
};

const actions = {
  [BUILDING_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(BUILDING_LIST_REQUEST);
    try {
      const response = await BuildingsRepository.get(payload.businessId, payload.data);
      commit(BUILDING_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(BUILDING_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },

  [BUILDING_REQUEST]: async ({ commit }, payload) => {
    commit(BUILDING_REQUEST);
    try {
      const response = await BuildingsRepository.getSelf(payload.businessId, payload.id);
      const { data } = response;
      commit(BUILDING_REQUEST_SUCCESS, data);
    } catch (error) {
      commit(BUILDING_REQUEST_ERROR);
      handleRequestError(error);
    }
  },

  [BUILDING_ADD]: async ({ commit }, payload) => {
    commit(BUILDING_ADD);
    try {
      const response = await BuildingsRepository.create(payload.businessId, payload.data);
      commit(BUILDING_ADD_SUCCESS, response.data.data);
    } catch (error) {
      commit(BUILDING_ADD_ERROR);
      handleRequestError(error);
    }
  },

  [BUILDING_UPDATE]: async ({ commit }, payload) => {
    commit(BUILDING_UPDATE);
    try {
      const response = await BuildingsRepository.update(
        payload.businessId,
        payload.id,
        payload.data
      );
      commit(BUILDING_UPDATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(BUILDING_UPDATE_ERROR);
      handleRequestError(error);
    }
  },

  [BUILDING_REMOVE]: async ({ commit }, payload) => {
    commit(BUILDING_REMOVE);
    try {
      await BuildingsRepository.delete(payload.businessId, payload.id);
      commit(BUILDING_REMOVE_SUCCESS, payload.id);
    } catch (error) {
      commit(BUILDING_REMOVE_ERROR);
      handleRequestError(error);
    }
  },

  [BUILDING_CHILD_LIST_IMPORT]: async ({ commit }, payload) => {
    commit(BUILDING_CHILD_LIST_IMPORT);
    try {
      let response = '';
      if (payload.type === 'owners') {
        response = await OwnersRepository.import(payload.businessId, payload.data);
      } else {
        response = await ApartmentsRepository.import(payload.businessId, payload.data);
      }
      commit(BUILDING_CHILD_LIST_IMPORT_SUCCESS, response.data.data);
    } catch (error) {
      commit(BUILDING_CHILD_LIST_IMPORT_ERROR);
      handleRequestError(error);
    }
  },
};

const mutations = {
  [BUILDING_LIST_REQUEST]: state => {
    state.buildingStatus = 'loading';
  },
  [BUILDING_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.buildingStatus = 'success';
    state.buildingList = payload.data;
    state.buildingMeta = payload.meta;
  },
  [BUILDING_LIST_REQUEST_ERROR]: state => {
    state.buildingStatus = 'error';
  },

  [BUILDING_ADD]: state => {
    state.buildingStatus = 'loading';
  },
  [BUILDING_ADD_SUCCESS]: (state, payload) => {
    state.buildingStatus = 'success';
    state.buildingList.push(payload);
  },
  [BUILDING_ADD_ERROR]: state => {
    state.buildingStatus = 'error';
  },

  [BUILDING_REQUEST]: state => {
    state.buildingStatus = 'loading';
  },
  [BUILDING_REQUEST_SUCCESS]: (state, payload) => {
    state.buildingStatus = 'success';
    state.building = payload.data;
  },
  [BUILDING_REQUEST_ERROR]: state => {
    state.buildingStatus = 'error';
  },

  [BUILDING_UPDATE]: state => {
    state.buildingStatus = 'loading';
  },
  [BUILDING_UPDATE_SUCCESS]: (state, payload) => {
    state.buildingStatus = 'success';
    const index = findIndex(state.buildingList, { id: payload.id });
    state.buildingList[index] = payload;
  },
  [BUILDING_UPDATE_ERROR]: state => {
    state.buildingStatus = 'error';
  },

  [BUILDING_REMOVE]: state => {
    state.buildingStatus = 'deleting';
  },

  [BUILDING_REMOVE_SUCCESS]: (state, payload) => {
    const index = findIndex(state.buildingList, { id: payload });
    state.buildingList.splice(index, 1);
  },

  [BUILDING_REMOVE_ERROR]: state => {
    state.buildingStatus = 'error';
  },

  [BUILDING_CHILD_LIST_IMPORT]: state => {
    state.buildingChildImportStatus = 'loading';
  },
  [BUILDING_CHILD_LIST_IMPORT_SUCCESS]: state => {
    state.buildingChildImportStatus = 'success';
  },
  [BUILDING_CHILD_LIST_IMPORT_ERROR]: state => {
    state.buildingChildImportStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
