<template>
  <ModalEdit :id="modalId" :title="$t('nav.businessUpdate')" :loading="isLoading" @click="onSubmit">
    <div class="p-5">
      <InputString :label="'Name'" v-model.trim="form.name" class="input-name" />
      <InputString
        :label="$t('text.registrationNumber')"
        v-model.trim="form.registrationNumber"
        :max-length="7"
        input-class="input-registration-number"
      />
      <InputString
        :label="$t('text.businessPhoneNumber')"
        v-model.trim="form.phone"
        :max-length="11"
        input-class="input-phone-number"
      />
      <InputString
        :label="$t('text.address')"
        v-model.trim="form.address"
        input-class="input-address"
      />
      <InputString
        :label="$t('text.stateBankEmail')"
        v-model.trim="form.stateBankEmail"
        input-class="input-email"
      />
    </div>
  </ModalEdit>
</template>

<script>
import { mapGetters } from 'vuex';
import { BUSINESS_UPDATE } from '@/store/actions/business';
import { validationMixin } from 'vuelidate';
import ModalEdit from '@/components/ui/ModalEdit';
import InputString from '@/components/ui/input/InputString';
import baseModalMixin from '@/mixins/baseModalMixin';

export default {
  mixins: [validationMixin, baseModalMixin],

  components: { ModalEdit, InputString },
  props: {
    business: Object,
  },
  data() {
    return {
      form: {
        name: this.business.name,
        phone: this.business.phone,
        registrationNumber: this.business.registrationNumber,
        address: this.business.address,
        stateBankEmail: this.business.stateBankEmail,
      },
    };
  },

  computed: {
    ...mapGetters(['businessStatus', 'businessList']),
    isLoading() {
      return this.businessStatus === 'loading';
    },
    title() {
      return this.$t('nav.ownerCreate');
    },
  },
  methods: {
    async onSubmit() {
      try {
        const payload = {
          id: this.business.id,
          data: this.form,
        };
        await this.$store.dispatch(BUSINESS_UPDATE, payload);
        if (this.businessStatus === 'success') {
          this.$toasted.show(this.$t('toast.success'), { type: 'success' });
          window.location.reload();
        }
      } catch (error) {
        this.$toasted.show(error.message, { type: 'error' });
      }
    },
    hideEditModal() {
      cash(`#${this.modalId}`).modal('hide');
    },
  },
};
</script>
