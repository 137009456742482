<template>
  <div>
    <div class="p-5">
      <InputString
        :label="$t('text.lastName')"
        v-model.trim="form.lastName.$model"
        :vuelidate="form.lastName"
        input-class="input-owner-last-name"
      />

      <InputString
        :label="$t('text.firstName')"
        v-model.trim="form.firstName.$model"
        :vuelidate="form.firstName"
        input-class="input-owner-first-name"
      />

      <InputString
        :label="$t('text.phone')"
        v-model.trim="form.phone.$model"
        :vuelidate="form.phone"
        input-class="input-owner-phone"
        :max-length="8"
      />

      <InputString
        :label="$t('text.email')"
        v-model.trim="form.email.$model"
        :vuelidate="form.email"
        input-class="input-owner-email"
      />

      <InputRegistrationNumber
        :label="$t('text.registrationNumber')"
        v-model.trim="form.registrationNumber.$model"
        :vuelidate="form.registrationNumber"
        input-class="input-owner-registration-number"
      />

      <InputString
        type="number"
        :label="$t('text.familySize')"
        v-model.trim="form.familySize.$model"
        :vuelidate="form.familySize"
        input-class="input-owner-family-size"
      />

      <Loading v-if="petTypesLoading" />
      <template v-else>
        <InputCheckboxList
          :options="petTypes"
          v-model="selectedPets"
          :label="$t('text.ownerHasPet')"
          input-class="input-checkbox-owner-pet"
          @change="setPets"
        />
      </template>
    </div>

    <div class="p-5">
      <p class="mb-2">{{ $t('nav.cars') }}</p>
      <div class="border border-gray-200 dark:border-dark-5 rounded-md">
        <div
          v-if="cars.length < 1"
          class="py-4 px-5 border-gray-200 dark:border-dark-5 relative text-gray-500"
        >
          {{ $t('text.noItems') }}
        </div>
        <div
          v-for="(car, index) in cars"
          :key="index"
          class="py-4 px-5 border-gray-200 dark:border-dark-5 relative"
          :class="{ 'border-b': index + 1 < cars.length }"
        >
          <p class="font-medium">
            {{
              `${computedCar(car).make && computedCar(car).make.name} ${computedCar(car).model &&
                computedCar(car).model.name}`
            }}
          </p>
          <p class="text-gray-600 whitespace-nowrap">
            {{ $t('text.carNumber') }}: <span>{{ car.number }}</span>
          </p>
          <div
            @click="confirmRemoval(car)"
            class="w-5 h-5 cursor-pointer flex items-center justify-center absolute rounded-full text-white bg-theme-6 right-0 top-0 -mr-2 mt-2"
          >
            <xIcon class="w-4 h-4" />
          </div>
        </div>
      </div>
      <Button
        @click="onCarModalShow"
        class="mt-3 w-full bg-gray-300 dark:bg-dark-5 dark:text-gray-300 text-gray-600"
        >{{ $t('text.carAdd') }}</Button
      >
    </div>

    <OwnerCarModalForm
      :modal-id="modalId"
      :car-makes="carMakes"
      @onCarAdd="$emit('onCarAdd', $event)"
    />

    <ConfirmDialog :modal-id="deleteModalId" @ok="onDelete" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { PET_TYPES_REQUEST, CAR_MAKES_REQUEST } from '@/store/actions/localUnits';
import Button from '@/components/ui/button/Button';
import InputCheckboxList from '@/components/ui/input/InputCheckboxList';
import InputString from '@/components/ui/input/InputString';
import InputRegistrationNumber from '@/components/ui/input/InputRegistrationNumber';
import OwnerCarModalForm from '@/components/owner/OwnerCarModalForm';
import ConfirmDialog from '@/components/ui/ConfirmDialog';

export default {
  components: {
    Button,
    InputString,
    InputRegistrationNumber,
    InputCheckboxList,
    OwnerCarModalForm,
    ConfirmDialog,
  },

  props: {
    form: Object,
    selectedPets: Array,
    cars: Array,
  },

  data() {
    return {
      modalId: 'owner-car-modal-form',
      deleteModalId: 'owner-car-delete-confirm-dialog',
      carToRemove: null,
    };
  },

  computed: {
    ...mapGetters(['petTypesStatus', 'petTypes', 'carMakesStatus', 'carMakes']),
    petTypesLoading() {
      return this.petTypesStatus === 'loading';
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    computedCar(car) {
      const makes = this.carMakes.find(item => item.id === car.carMakeId);
      let data = {
        make: makes,
        model: null,
      };
      if (data.make) {
        data = { ...data, model: data.make.models.find(item => item.id === car.carModelId) };
      }
      return data;
    },

    setPets(value) {
      this.$emit('onPetsChange', value);
    },

    onCarModalShow() {
      cash(`#${this.modalId}`).modal('show');
    },

    async fetchData() {
      await this.$store.dispatch(PET_TYPES_REQUEST);
      await this.$store.dispatch(CAR_MAKES_REQUEST);
    },

    confirmRemoval(car) {
      this.carToRemove = car;
      cash(`#${this.deleteModalId}`).modal('show');
    },

    async onDelete() {
      cash(`#${this.deleteModalId}`).modal('hide');
      this.$emit('onCarDelete', this.carToRemove);
    },
  },
};
</script>
