<template>
  <TableRegular
    :loading="loading"
    :downloading="downloading"
    :rows="rows"
    :columns="columns"
    :custom-filters="customFilters"
    :sort="sort"
    :page="page"
    :per-page="perPage"
    :page-size="pageSize"
    :total-page="totalPage"
    :line-numbers="lineNumbers"
    :downloadable="downloadable && checkPermission('service.services.download')"
    @onFilter="$emit('onFilter', $event)"
    @onPageChange="$emit('onPageChange', $event)"
    @onPerPageChange="$emit('onPerPageChange', $event)"
    @onSortChange="$emit('onSortChange', $event)"
    @onDownload="$emit('onDownload')"
    @onChangeCustomFilter="$emit('onChangeCustomFilter', $event)"
  >
    <template slot="table-row" slot-scope="props">
      <p v-if="props.column.field === 'assignedUser'">
        <PeopleFullname v-if="props.row.assignedUser" :item="props.row.assignedUser" />
      </p>
      <p v-if="props.column.field === 'title'" v-tooltip.top-center="props.row.title">
        {{ props.row.title | textSubstr(30, '...') }}
      </p>
      <p v-if="props.column.field === 'description'" v-tooltip.top-center="props.row.description">
        {{ props.row.description | textSubstr(25, '...') }}
      </p>
      <p
        v-else-if="props.column.field === 'status'"
        :class="['status status-announcement', `is-${props.row.status.toLowerCase()}`]"
      >
        {{ $t(`statusAnnouncement.${props.row.status.toLowerCase()}`) }}
      </p>
      <p v-else-if="props.column.field === 'createdAt'" class="font-medium whitespace-nowrap">
        {{ props.row.createdAt | momentDate('YYYY-MM-DD HH:mm') }}
      </p>
      <p
        v-else-if="props.column.field === 'totalNumber'"
        class="font-medium text-theme-11 flex justify-center items-center"
      >
        {{ props.row.totalNumber }}
      </p>
      <p
        v-else-if="props.column.field === 'totalSubmit'"
        class="font-medium text-theme-9 flex justify-center items-center"
      >
        {{ props.row.totalSubmit }}
      </p>
      <p v-else-if="props.column.field === 'closingDate'" class="font-medium whitespace-nowrap">
        {{ props.row.closingDate | momentDate('YYYY-MM-DD HH:mm') }}
      </p>
      <p v-else-if="props.column.field === 'action'" class="flex justify-center items-center">
        <a href="javascript:" @click="$emit('show', props.row.id)" class="flex items-center mr-3">
          <EyeIcon class="w-4 h-4 mr-1" />
          {{ $t('table.read') }}
        </a>
        <router-link
          :to="{ name: 'PollUpdate', params: { id: props.row.id } }"
          class="flex items-center mr-3"
        >
          <CheckSquareIcon class="w-4 h-4 mr-1" />
          {{ $t('table.edit') }}
        </router-link>
        <a
          href="javascript:"
          @click="$emit('delete', props.row.id)"
          class="flex items-center text-theme-6"
        >
          <Trash2Icon class="w-4 h-4 mr-1" />
          {{ $t('table.delete') }}
        </a>
      </p>
    </template>
  </TableRegular>
</template>

<script>
import TableRegular from '@/components/ui/table/TableRegular';
import tableMixin from '@/mixins/tableMixin';
import PeopleFullname from '@/components/people/PeopleFullname';

export default {
  mixins: [tableMixin],
  components: { TableRegular, PeopleFullname },
};
</script>
