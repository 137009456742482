import {
  SMS_PACKAGE_LIST_REQUEST,
  SMS_PACKAGE_LIST_REQUEST_SUCCESS,
  SMS_PACKAGE_LIST_REQUEST_ERROR,
  SMS_PACKAGE_SUBSCRIBE,
  SMS_PACKAGE_SUBSCRIBE_SUCCESS,
  SMS_PACKAGE_SUBSCRIBE_ERROR,
  SMS_PACKAGE_INVOICE_LIST_REQUEST,
  SMS_PACKAGE_INVOICE_LIST_REQUEST_SUCCESS,
  SMS_PACKAGE_INVOICE_LIST_REQUEST_ERROR,
  SMS_LOGS_REQUEST,
  SMS_LOGS_REQUEST_SUCCESS,
  SMS_LOGS_REQUEST_ERROR,
} from '@/store/actions/smsPackage';
import { handleRequestError } from '@/utils/error';

import Repository, { SMS_PACKAGES } from '@/repositories/RepositoryFactory';

const SmsPackagesRepository = Repository.get(SMS_PACKAGES);

const state = {
  smsPackage: {},
  smsPackageList: [],
  smsPackageMeta: '',
  smsPackageStatus: '',
  smsPackageInvoiceList: [],
  smsPackageInvoiceListMeta: '',
  smsLogs: [],
  smsLogsStatus: '',
  smsLogsMeta: '',
};

const getters = {
  smsPackageList: state => state.smsPackageList,
  smsPackage: state => state.smsPackage,
  smsPackageStatus: state => state.smsPackageStatus,
  smsPackageInvoiceList: state => state.smsPackageInvoiceList,
  smsPackageInvoiceListMeta: state => state.smsPackageInvoiceListMeta,
  smsLogs: state => state.smsLogs,
  smsLogsStatus: state => state.smsLogsStatus,
  smsLogsMeta: state => state.smsLogsMeta,
};

const actions = {
  [SMS_PACKAGE_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(SMS_PACKAGE_LIST_REQUEST);
    try {
      const response = await SmsPackagesRepository.get(payload.data);
      commit(SMS_PACKAGE_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(SMS_PACKAGE_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [SMS_PACKAGE_SUBSCRIBE]: async ({ commit }, payload) => {
    commit(SMS_PACKAGE_SUBSCRIBE);
    try {
      const response = await SmsPackagesRepository.subscribe(payload.businessId, payload.data);
      commit(SMS_PACKAGE_SUBSCRIBE_SUCCESS, response.data);
    } catch (error) {
      commit(SMS_PACKAGE_SUBSCRIBE_ERROR);
      handleRequestError(error);
    }
  },
  [SMS_PACKAGE_INVOICE_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(SMS_PACKAGE_INVOICE_LIST_REQUEST);
    try {
      const response = await SmsPackagesRepository.getBusinessInvoice(
        payload.businessId,
        payload.data
      );
      commit(SMS_PACKAGE_INVOICE_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(SMS_PACKAGE_INVOICE_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [SMS_LOGS_REQUEST]: async ({ commit }, payload) => {
    commit(SMS_LOGS_REQUEST);
    try {
      const response = await SmsPackagesRepository.getSmsLogs(payload.businessId, payload.data);
      commit(SMS_LOGS_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(SMS_LOGS_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
};

const mutations = {
  [SMS_PACKAGE_LIST_REQUEST]: state => {
    state.smsPackageStatus = 'loading';
  },
  [SMS_PACKAGE_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.smsPackageStatus = 'success';
    state.smsPackageList = payload.data;
    state.smsPackageMeta = payload.meta;
  },
  [SMS_PACKAGE_LIST_REQUEST_ERROR]: state => {
    state.smsPackageStatus = 'error';
  },
  [SMS_PACKAGE_SUBSCRIBE]: state => {
    state.smsPackageStatus = 'loading';
  },
  [SMS_PACKAGE_SUBSCRIBE_SUCCESS]: (state, payload) => {
    state.smsPackageStatus = 'success';
    state.smsPackage = payload.data;
  },
  [SMS_PACKAGE_SUBSCRIBE_ERROR]: state => {
    state.smsPackageStatus = 'error';
  },
  [SMS_PACKAGE_INVOICE_LIST_REQUEST]: state => {
    state.smsPackageStatus = 'loading';
  },
  [SMS_PACKAGE_INVOICE_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.smsPackageStatus = 'success';
    state.smsPackageInvoiceList = payload.data;
    if (payload.meta) {
      state.smsPackageInvoiceListMeta = payload.meta;
    }
  },
  [SMS_PACKAGE_INVOICE_LIST_REQUEST_ERROR]: state => {
    state.smsPackageStatus = 'error';
  },
  [SMS_LOGS_REQUEST]: state => {
    state.smsLogsStatus = 'loading';
  },
  [SMS_LOGS_REQUEST_SUCCESS]: (state, payload) => {
    state.smsLogsStatus = 'success';
    state.smsLogs = payload.data;
    if (payload.meta) {
      state.smsLogsMeta = payload.meta;
    }
  },
  [SMS_LOGS_REQUEST_ERROR]: state => {
    state.smsLogsStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
