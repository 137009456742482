import { findIndex } from 'lodash';
import {
  POLLS_LIST_REQUEST,
  POLLS_LIST_REQUEST_ERROR,
  POLLS_LIST_REQUEST_SUCCESS,
  POLLS_ADD,
  POLLS_ADD_ERROR,
  POLLS_ADD_SUCCESS,
  POLLS_SHOW,
  POLLS_SHOW_SUCCESS,
  POLLS_SHOW_ERROR,
  POLLS_UPDATE,
  POLLS_UPDATE_SUCCESS,
  POLLS_UPDATE_ERROR,
  POLLS_DELETE,
  POLLS_DELETE_SUCCESS,
  POLLS_DELETE_ERROR,
  POLLS_ADD_RESPONSES,
  POLLS_ADD_RESPONSES_SUCCESS,
  POLLS_ADD_RESPONSES_ERROR,
} from '@/store/actions/polls';
import { handleRequestError } from '@/utils/error';

import Repository, { POLLS } from '@/repositories/RepositoryFactory';

const PollsRepository = Repository.get(POLLS);

const state = {
  poll: {},
  pollList: [],
  pollMeta: '',
  pollStatus: '',
  pollLastCreated: '',
};

const getters = {
  poll: state => state.poll,
  pollList: state => state.pollList,
  pollMeta: state => state.pollMeta,
  pollStatus: state => state.pollStatus,
  pollLastCreated: state => state.pollLastCreated,
};

const actions = {
  [POLLS_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(POLLS_LIST_REQUEST);
    try {
      const response = await PollsRepository.get(payload.businessId, payload.data);
      commit(POLLS_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(POLLS_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [POLLS_ADD]: async ({ commit }, payload) => {
    commit(POLLS_ADD);
    try {
      const response = await PollsRepository.create(payload.businessId, payload.data);
      commit(POLLS_ADD_SUCCESS, response.data.data);
    } catch (error) {
      commit(POLLS_ADD_ERROR);
      handleRequestError(error);
    }
  },
  [POLLS_SHOW]: async ({ commit }, payload) => {
    // commit(POLLS_SHOW);
    try {
      const response = await PollsRepository.show(payload.businessId, payload.id);
      const { data } = response;
      commit(POLLS_SHOW_SUCCESS, data);
    } catch (error) {
      commit(POLLS_SHOW_ERROR);
      handleRequestError(error);
    }
  },
  [POLLS_UPDATE]: async ({ commit }, payload) => {
    commit(POLLS_UPDATE);
    try {
      const response = await PollsRepository.update(payload.businessId, payload.id, payload.data);
      commit(POLLS_ADD_SUCCESS, response.data.data);
    } catch (error) {
      commit(POLLS_ADD_ERROR);
      handleRequestError(error);
    }
  },
  [POLLS_DELETE]: async ({ commit }, payload) => {
    commit(POLLS_DELETE);
    try {
      await PollsRepository.delete(payload.businessId, payload.id);
      commit(POLLS_DELETE_SUCCESS, payload.id);
    } catch (error) {
      commit(POLLS_DELETE_ERROR);
      handleRequestError(error);
    }
  },
  [POLLS_ADD_RESPONSES]: async ({ commit }, payload) => {
    commit(POLLS_ADD_RESPONSES);
    try {
      await PollsRepository.addResponses(payload.businessId, payload.id, payload.data);
      commit(POLLS_ADD_RESPONSES_SUCCESS);
    } catch (error) {
      commit(POLLS_ADD_RESPONSES_ERROR);
      handleRequestError(error);
    }
  },
};

const mutations = {
  [POLLS_LIST_REQUEST]: state => {
    state.pollStatus = 'loading';
  },
  [POLLS_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.pollStatus = 'success';
    state.pollList = payload.data;
    state.pollMeta = payload.meta;
  },
  [POLLS_LIST_REQUEST_ERROR]: state => {
    state.pollStatus = 'error';
  },
  [POLLS_ADD]: state => {
    state.pollStatus = 'loading';
    state.pollLastCreated = '';
  },
  [POLLS_ADD_SUCCESS]: (state, payload) => {
    state.pollStatus = 'success';
    state.pollLastCreated = payload;
    state.pollList.push(payload);
  },
  [POLLS_ADD_ERROR]: state => {
    state.pollStatus = 'error';
  },
  [POLLS_SHOW]: state => {
    state.pollStatus = 'loading';
  },
  [POLLS_SHOW_SUCCESS]: (state, payload) => {
    state.pollStatus = 'success';
    state.poll = payload.data;
  },
  [POLLS_SHOW_ERROR]: state => {
    state.pollStatus = 'error';
  },
  [POLLS_UPDATE]: state => {
    state.pollStatus = 'loading';
  },
  [POLLS_UPDATE_SUCCESS]: (state, payload) => {
    state.pollStatus = 'success';
    state.pollList = payload;
  },
  [POLLS_UPDATE_ERROR]: state => {
    state.pollStatus = 'error';
  },
  [POLLS_DELETE]: state => {
    state.pollStatus = 'deleting';
  },
  [POLLS_DELETE_SUCCESS]: (state, payload) => {
    const index = findIndex(state.pollList, { id: payload });
    state.pollList.splice(index, 1);
  },
  [POLLS_DELETE_ERROR]: state => {
    state.pollStatus = 'error';
  },
  [POLLS_ADD_RESPONSES]: state => {
    state.pollStatus = 'adding responses';
  },
  [POLLS_ADD_RESPONSES_SUCCESS]: state => {
    state.pollStatus = 'success';
  },
  [POLLS_ADD_RESPONSES_ERROR]: state => {
    state.pollStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
