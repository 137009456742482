<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center">
      <PageHeader class="text-lg font-medium mr-auto" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div>
          <InputSelect
            v-model.number="buildingId"
            :options="buildingList"
            :first-item-label="$t('text.selectBuilding')"
            value-key="id"
            :disabled="isLoadingBuildings"
            input-class="input-transaction-building"
            @input="onChangeBuilding()"
          />
        </div>
        <div class="ml-2">
          <InputSelect
            v-model.number="number"
            :options="apartmentList"
            :first-item-label="$t('text.selectApartment')"
            label-key="number"
            value-key="number"
            :disabled="isLoadingApartments || !buildingId"
            input-class="input-transaction-apartment"
            @input="onChangeApartment"
          />
        </div>
      </div>
    </div>
    <div class="intro-y mt-5 overflow-auto lg:overflow-visible">
      <TransactionTable
        :loading="isLoading"
        :rows="transactions"
        :columns="columns"
        :sort="sort"
        :page="page"
        :per-page="perPage"
        :page-size="pageSize"
        :total-page="totalPage"
        @onFilter="onFilter"
        @onPageChange="onPageChange"
        @onPerPageChange="onPerPageChange"
        @onSortChange="onSortChange"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { TRANSACTION_LIST_REQUEST } from '@/store/actions/transaction';
import { BUILDING_LIST_REQUEST } from '@/store/actions/building';
import { APARTMENT_LIST_REQUEST } from '@/store/actions/apartment';
import dataListMixin from '@/mixins/dataListMixin';
import PageHeader from '@/components/ui/PageHeader';
import InputSelect from '@/components/ui/input/InputSelect';
import TransactionTable from '@/components/transaction/TransactionTable';

export default {
  mixins: [dataListMixin],

  components: {
    PageHeader,
    InputSelect,
    TransactionTable,
  },

  data() {
    return {
      storeActionListRequest: TRANSACTION_LIST_REQUEST,
      columns: [
        { label: this.$t('table.date'), field: 'startDate' },
        { label: this.$t('table.firstBalance'), field: 'firstBalance' },
        { label: this.$t('table.invoicedAmount'), field: 'invoicedAmount' },
        { label: this.$t('table.paidAmount'), field: 'paidAmount' },
        { label: this.$t('table.lastBalance'), field: 'lastBalance' },
      ],
      buildingId: null,
      number: null,
      defaultTableData: {
        filters: {},
        sort: {
          field: 'id',
          type: 'desc',
        },
        page: 1,
        perPage: 1000,
      },
    };
  },

  computed: {
    ...mapGetters([
      'transactionList',
      'transactionListStatus',
      'buildingList',
      'buildingStatus',
      'apartmentList',
      'apartmentListStatus',
    ]),
    isLoading() {
      return this.transactionListStatus === 'loading';
    },
    isLoadingBuildings() {
      return this.buildingStatus === 'loading';
    },
    isLoadingApartments() {
      return this.apartmentListStatus === 'loading';
    },
    transactions() {
      if (this.buildingId && this.number) {
        let lastBalance = 0;
        const transactions = this.transactionList.map(transaction => {
          const transactionFirstBalance = lastBalance;
          const dtAmt = transaction.dtAmt * 100;
          if (transaction.amount) {
            lastBalance += transaction.amount;
          }
          if (transaction.dtAmt) {
            lastBalance -= dtAmt;
          }
          const object = {
            startDate: transaction.startDate,
            firstBalance: transactionFirstBalance,
            invoicedAmount: transaction.amount || 0,
            paidAmount: dtAmt || 0,
            lastBalance,
          };

          return object;
        });

        return transactions;
      }

      return [];
    },
  },

  created() {
    this.fetchBuildingList();
  },

  methods: {
    fetchBuildingList() {
      const payload = {
        businessId: this.business.id,
        data: this.defaultTableData,
      };
      this.$store.dispatch(BUILDING_LIST_REQUEST, payload);
    },
    setFilterValues(data) {
      data.filters[`filter[buildingId]`] = this.buildingId;
      data.filters[`filter[number]`] = this.number;
    },
    async onChangeBuilding() {
      this.number = null;
      await this.getApartmentList();
    },
    onChangeApartment() {
      this.fetchData();
    },
    async getApartmentList() {
      const data = this.defaultTableData;
      data.filters['filter[buildingId]'] = this.buildingId;
      data.filters['filter[isParking]'] = 0;
      const payload = {
        businessId: this.business.id,
        data,
      };
      await this.$store.dispatch(APARTMENT_LIST_REQUEST, payload);
    },
  },
};
</script>
