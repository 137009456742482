<template>
  <TableRegular
    :loading="loading"
    :downloading="downloading"
    :rows="rows"
    :columns="columns"
    :sort="sort"
    :page="page"
    :per-page="perPage"
    :page-size="pageSize"
    :total-page="totalPage"
    :line-numbers="lineNumbers"
    :downloadable="downloadable && checkPermission('finance.receivable.download')"
    :custom-filters="customFilters"
    @onFilter="$emit('onFilter', $event)"
    @onPageChange="$emit('onPageChange', $event)"
    @onPerPageChange="$emit('onPerPageChange', $event)"
    @onSortChange="$emit('onSortChange', $event)"
    @onDownload="$emit('onDownload')"
    @onChangeCustomFilter="$emit('onChangeCustomFilter', $event)"
  >
    <template slot="table-row" slot-scope="props">
      <p v-if="props.column.field === 'balance'" class="font-medium whitespace-nowrap">
        {{ props.row.balance ? props.row.balance : 0 | currency }}
      </p>
      <p v-else-if="props.column.field === 'owners'" class="font-medium whitespace-nowrap">
        <template v-if="props.row">
          <PeopleFullname v-for="(owner, index) in props.row.owners" :key="index" :item="owner" />
        </template>
      </p>
      <p v-else-if="props.column.field === 'total'" class="font-medium whitespace-nowrap">
        {{ props.row.total ? props.row.total : 0 | currency }}
      </p>
    </template>

    <template #footer>
      <tr v-if="!loading && rows.length > 0 && footerItems">
        <th v-if="lineNumbers"></th>
        <th colspan="2"></th>
        <th>
          <template v-if="footerItems.hasOwnProperty('balance')">
            {{ footerItems.balance | currency }}
          </template>
        </th>
        <th v-for="n in 12" :key="n">
          <template v-if="footerItems.hasOwnProperty(n.toString())">
            {{ footerItems[n] | currency }}
          </template>
        </th>
        <th>
          <template v-if="footerItems.hasOwnProperty('total')">
            {{ footerItems.total | currency }}
          </template>
        </th>
      </tr>
    </template>
  </TableRegular>
</template>

<script>
import TableRegular from '@/components/ui/table/TableRegular';
import tableMixin from '@/mixins/tableMixin';
import PeopleFullname from '@/components/people/PeopleFullname';

export default {
  mixins: [tableMixin],

  props: {
    footerItems: Object,
  },

  components: { TableRegular, PeopleFullname },
};
</script>
