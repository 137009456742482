export const MEETING_LIST_REQUEST = 'MEETING_LIST_REQUEST';
export const MEETING_LIST_REQUEST_SUCCESS = 'MEETING_LIST_REQUEST_SUCCESS';
export const MEETING_LIST_REQUEST_ERROR = 'MEETING_LIST_REQUEST_ERROR';

export const MEETING_ADD = 'MEETING_ADD';
export const MEETING_ADD_SUCCESS = 'MEETING_ADD_SUCCESS';
export const MEETING_ADD_ERROR = 'MEETING_ADD_ERROR';

export const MEETING_UPDATE = 'MEETING_UPDATE';
export const MEETING_UPDATE_SUCCESS = 'MEETING_UPDATE_SUCCESS';
export const MEETING_UPDATE_ERROR = 'MEETING_UPDATE_ERROR';

export const MEETING_REMOVE = 'MEETING_REMOVE';
export const MEETING_REMOVE_SUCCESS = 'MEETING_REMOVE_SUCCESS';
export const MEETING_REMOVE_ERROR = 'MEETING_REMOVE_ERROR';

export const MEETING_REQUEST = 'MEETING_REQUEST';
export const MEETING_REQUEST_SUCCESS = 'MEETING_REQUEST_SUCCESS';
export const MEETING_REQUEST_ERROR = 'MEETING_REQUEST_ERROR';
