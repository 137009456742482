import Client from '@/clients/apiClient';
import humps from 'humps';
import { stringify } from 'query-string';

const resourceBase = 'services';
const resource = 'order';

export default {
  get(payload) {
    return Client.get(
      `${resourceBase}/${resource}?page=${payload.page}&limit=${
        payload.perPage
      }&sort[field]=${humps.decamelize(payload.sort.field)}&sort[type]=${
        payload.sort.type
      }&${stringify(humps.decamelizeKeys(payload.filters))}`
    );
  },
};
