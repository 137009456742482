import Client from '@/clients/apiClient';
import ClientFile from '@/clients/fileClient';
import humps from 'humps';
import { stringify } from 'query-string';
import AuthTokenStorageService from '@/services/AuthTokenStorageService';

const resourceBase = 'business';
const resource = 'invoices';

export default {
  get(businessId, payload) {
    return Client.get(
      `${resourceBase}/${businessId}/${resource}?page=${payload.page}&limit=${
        payload.perPage
      }&sort[field]=${humps.decamelize(payload.sort.field)}&sort[type]=${
        payload.sort.type
      }&${stringify(humps.decamelizeKeys(payload.filters))}`
    );
  },
  getSelf(businessId, id) {
    return Client.get(`${resourceBase}/${businessId}/${resource}/${id}`);
  },
  create(businessId, payload) {
    return Client.post(`${resourceBase}/${businessId}/${resource}`, payload);
  },
  update(businessId, id, payload) {
    return Client.put(`${resourceBase}/${businessId}/${resource}/${id}`, payload);
  },
  delete(businessId, id) {
    return Client.delete(`${resourceBase}/${businessId}/${resource}/${id}`);
  },
  deleteChecked(businessId, payload) {
    return Client.post(`${resourceBase}/${businessId}/${resource}/delete-checked`, payload);
  },
  importInvoice(businessId, payload) {
    return ClientFile.post(`${resourceBase}/${businessId}/${resource}/import-invoice`, payload);
  },
  copyInvoice(businessId, payload) {
    return ClientFile.post(`${resourceBase}/${businessId}/${resource}/copy-invoice`, payload);
  },
  importStatement(businessId, payload) {
    const accessToken = AuthTokenStorageService.getAccessToken();
    return ClientFile.post(`${resourceBase}/${businessId}/${resource}/import`, payload, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
  export(businessId, payload) {
    return Client.post(
      `${resourceBase}/${businessId}/${resource}/export?sort[field]=${humps.decamelize(
        payload.sort.field
      )}&sort[type]=${payload.sort.type}&${stringify(humps.decamelizeKeys(payload.filters))}`
    );
  },
  makePay(businessId, id, payload) {
    return Client.post(`${resourceBase}/${businessId}/${resource}/${id}/pay`, payload);
  },
  getStatementJob(businessId, payload) {
    return Client.get(
      `${resourceBase}/${businessId}/unknown-transaction/get-unused-unknown-transactions?page=${
        payload.page
      }&limit=${payload.perPage}&sort[field]=${humps.decamelize(payload.sort.field)}&sort[type]=${
        payload.sort.type
      }&${stringify(humps.decamelizeKeys(payload.filters))}`
    );
  },
  deleteUnusedStatement(businessId) {
    return Client.delete(
      `${resourceBase}/${businessId}/unknown-transaction/delete-unused-unknown-transactions`
    );
  },
  deleteUnknownTransaction(businessId, id) {
    return Client.delete(`${resourceBase}/${businessId}/unknown-transaction/${id}`);
  },
  getJob(businessId, payload) {
    return Client.post(`${resourceBase}/${businessId}/ail-job/get-last-current-ail-job`, payload);
  },
  checkStatus(businessId, payload) {
    return Client.post(`${resourceBase}/${businessId}/invoices/check-invoice`, payload);
  },
  payFromStatement(businessId, payload) {
    return Client.post(`${resourceBase}/${businessId}/invoices/paidInvoice`, payload);
  },
  checkSms(businessId, type, payload) {
    return Client.post(`${resourceBase}/${businessId}/${resource}/sms-count?type=${type}`, payload);
  },
};
