import { findIndex } from 'lodash';
import {
  BUSINESS_ADMIN_LIST_REQUEST,
  BUSINESS_ADMIN_LIST_REQUEST_SUCCESS,
  BUSINESS_ADMIN_LIST_REQUEST_ERROR,
  BUSINESS_ADMIN_APPROVE_REQUEST,
  BUSINESS_ADMIN_APPROVE_REQUEST_SUCCESS,
  BUSINESS_ADMIN_APPROVE_REQUEST_ERROR,
  BUSINESS_ADMIN_DELETE_REQUEST,
  BUSINESS_ADMIN_DELETE_REQUEST_SUCCESS,
  BUSINESS_ADMIN_DELETE_REQUEST_ERROR,
} from '@/store/actions/admin';

import Repository, { ADMIN } from '@/repositories/RepositoryFactory';
import { handleRequestError } from '@/utils/error';

const AdminRepository = Repository.get(ADMIN);

const state = {
  businessApproveList: [],
  businessApproveListStatus: '',
  businessApproveListMeta: '',
  businessApproveRequestStatus: '',
  businessDeleteStatus: '',
};

const getters = {
  businessApproveList: state => state.businessApproveList,
  businessApproveListStatus: state => state.businessApproveListStatus,
  businessApproveListMeta: state => state.businessApproveListMeta,
  businessApproveRequestStatus: state => state.businessApproveRequestStatus,
  businessDeleteStatus: state => state.businessDeleteStatus,
};

const actions = {
  [BUSINESS_ADMIN_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(BUSINESS_ADMIN_LIST_REQUEST);
    try {
      const response = await AdminRepository.getBusinessAdmins(payload.data);
      commit(BUSINESS_ADMIN_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(BUSINESS_ADMIN_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [BUSINESS_ADMIN_APPROVE_REQUEST]: async ({ commit }, payload) => {
    commit(BUSINESS_ADMIN_APPROVE_REQUEST);
    try {
      await AdminRepository.approveBusinessAdmin(payload);
      commit(BUSINESS_ADMIN_APPROVE_REQUEST_SUCCESS);
    } catch (error) {
      commit(BUSINESS_ADMIN_APPROVE_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [BUSINESS_ADMIN_DELETE_REQUEST]: async ({ commit }, payload) => {
    commit(BUSINESS_ADMIN_DELETE_REQUEST);
    try {
      await AdminRepository.deleteBusinessAdmin(payload.id);
      commit(BUSINESS_ADMIN_DELETE_REQUEST_SUCCESS, payload.id);
    } catch (error) {
      commit(BUSINESS_ADMIN_DELETE_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
};

const mutations = {
  [BUSINESS_ADMIN_LIST_REQUEST]: state => {
    state.businessApproveListStatus = 'loading';
  },
  [BUSINESS_ADMIN_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.businessApproveListStatus = 'success';
    state.businessApproveList = payload.data;
    state.businessApproveListMeta = payload.meta;
  },
  [BUSINESS_ADMIN_LIST_REQUEST_ERROR]: state => {
    state.businessApproveListStatus = 'error';
  },
  [BUSINESS_ADMIN_APPROVE_REQUEST]: state => {
    state.businessApproveRequestStatus = 'loading';
  },
  [BUSINESS_ADMIN_APPROVE_REQUEST_SUCCESS]: state => {
    state.businessApproveRequestStatus = 'success';
  },
  [BUSINESS_ADMIN_APPROVE_REQUEST_ERROR]: state => {
    state.businessApproveRequestStatus = 'error';
  },
  [BUSINESS_ADMIN_DELETE_REQUEST]: state => {
    state.businessDeleteStatus = 'deleting';
  },
  [BUSINESS_ADMIN_DELETE_REQUEST_SUCCESS]: (state, payload) => {
    state.businessDeleteStatus = 'deleted';
    const index = findIndex(state.businessApproveList, { id: payload });
    state.businessApproveList.splice(index, 1);
  },
  [BUSINESS_ADMIN_DELETE_REQUEST_ERROR]: state => {
    state.businessDeleteStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
