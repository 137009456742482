<template>
  <div class="dropdown">
    <component
      :is="togglerTag"
      class="dropdown-toggle meeting-dropdown-toggle p-0 m-0 flex items-center justify-center"
    >
      <MoreHorizontalIcon class="w-5 h-5" />
    </component>
    <div :class="['dropdown-box', boxClass]">
      <div
        :class="[
          'dropdown-box__content dropdown-box__content-list box p-2 dark:bg-dark-6',
          contentClass,
        ]"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import dropDownMixin from '@/mixins/dropDownMixin';

export default {
  mixins: [dropDownMixin],
};
</script>
<style>
.meeting-dropdown-toggle {
  padding: 9px 30px;
}
</style>
