import Index from '@/views/Announcements/Index';
import Announcements from '@/views/Announcements/Announcements';
import AnnouncementForm from '@/views/Announcements/AnnouncementForm';

export default [
  {
    path: '/announcements',
    component: Index,
    meta: { group: 'announcements' },
    children: [
      {
        path: '',
        component: Announcements,
        name: 'Announcements',
      },
      {
        path: 'create',
        name: 'AnnouncementCreate',
        component: AnnouncementForm,
      },
      {
        path: 'update/:id',
        name: 'AnnouncementUpdate',
        component: AnnouncementForm,
      },
    ],
  },
];
