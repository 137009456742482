<template>
  <ModalPrint :id="modalId" :title="title">
    <div class="p-5">
      <swiper class="swiper" :options="swiperOption">
        <swiper-slide v-for="(item, index) in poll" :key="`poll-${item.id}-${index}`">
          <h1>{{ item.question }}</h1>
          <ApexChart :chart="item.answers"></ApexChart>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </div>
  </ModalPrint>
</template>

<script>
import ModalPrint from '@/components/ui/ModalPrint';
import baseModalMixin from '@/mixins/baseModalMixin';
import ApexChart from '@/components/ui/chart/ApexChart';
import { Swiper, SwiperSlide, Pagination, Navigation } from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.min.css';

Swiper.use([Pagination, Navigation]);
export default {
  mixins: [baseModalMixin],

  components: {
    ModalPrint,
    ApexChart,
    Swiper,
    SwiperSlide,
  },
  props: {
    title: {
      type: String,
    },
    poll: Array,
  },
  data() {
    return {
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          type: 'fraction',
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    };
  },
  methods: {
    onCancel() {
      this.hideEditModal();
    },
  },
};
</script>
<style>
.swiper {
  height: 400px;
  width: 100%;
}
.swiper-slide {
  display: block;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  background-color: white;
}
</style>
