<template>
  <div v-if="page" class="table-regular-footer">
    <span class="table-regular-paginator">
      <label>{{ $t('table.pageSize') }}</label>
      <select
        class="table-regular-page-size"
        :value="perPage"
        :aria-label="$t('table.pageSize')"
        :title="$t('table.pageSize')"
        @change="$emit('onPerPageChange', $event)"
      >
        <option v-for="(item, index) in pageSize" :key="index" :value="item">{{ item }}</option>
      </select>
      <button
        class="table-regular-page"
        type="button"
        role="button"
        :aria-label="$t('table.first')"
        :title="$t('table.first')"
        data-page="first"
        :disabled="page === 1"
        @click="$emit('onPageChange', 1)"
      >
        {{ $t('table.first') }}
      </button>
      <button
        class="table-regular-page"
        type="button"
        role="button"
        :aria-label="$t('table.prev')"
        :title="$t('table.prev')"
        data-page="prev"
        :disabled="page === 1"
        @click="$emit('onPageChange', page - 1)"
      >
        {{ $t('table.prev') }}
      </button>
      <span class="table-regular-pages">
        <button
          v-if="page > 1"
          class="table-regular-page"
          type="button"
          role="button"
          :aria-label="$t('table.showPage', { number: page - 1 })"
          :title="$t('table.showPage', { number: page - 1 })"
          :data-page="page - 1"
          @click="$emit('onPageChange', page - 1)"
        >
          {{ page - 1 }}
        </button>
        <button
          class="table-regular-page active"
          type="button"
          role="button"
          :aria-label="$t('table.showPage', { number: page })"
          :title="$t('table.showPage', { number: page })"
          data-page="1"
        >
          {{ page }}
        </button>
        <button
          v-if="totalPage > page"
          class="table-regular-page"
          type="button"
          role="button"
          :aria-label="$t('table.showPage', { number: page + 1 })"
          :title="$t('table.showPage', { number: page + 1 })"
          :data-page="page + 1"
          @click="$emit('onPageChange', page + 1)"
        >
          {{ page + 1 }}
        </button>
      </span>
      <button
        class="table-regular-page"
        type="button"
        role="button"
        :aria-label="$t('table.next')"
        :title="$t('table.next')"
        data-page="next"
        :disabled="totalPage === page"
        @click="$emit('onPageChange', page + 1)"
      >
        {{ $t('table.next') }}
      </button>
      <button
        class="table-regular-page"
        type="button"
        role="button"
        :aria-label="$t('table.last')"
        :title="$t('table.last')"
        data-page="last"
        :disabled="totalPage === page"
        @click="$emit('onPageChange', totalPage)"
      >
        {{ $t('table.last') }}
      </button>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    page: Number,
    perPage: Number,
    pageSize: Array,
    totalPage: Number,
  },
};
</script>
