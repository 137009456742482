import businessapprove from '@/views/admin/businessApprove';
import businessapproveIndex from '@/views/admin/Index';

export default [
  {
    path: '/businessapprove',
    component: businessapproveIndex,
    meta: { group: 'businessapprove' },
    children: [{ path: '', name: 'businessapprove', component: businessapprove }],
  },
];
