import { findIndex } from 'lodash';
import {
  APP_VERSION_LIST_REQUEST,
  APP_VERSION_LIST_REQUEST_ERROR,
  APP_VERSION_LIST_REQUEST_SUCCESS,
  APP_VERSION_SHOW,
  APP_VERSION_SHOW_SUCCESS,
  APP_VERSION_SHOW_ERROR,
  APP_VERSION_CREATE,
  APP_VERSION_CREATE_ERROR,
  APP_VERSION_CREATE_SUCCESS,
  APP_VERSION_UPDATE,
  APP_VERSION_UPDATE_SUCCESS,
  APP_VERSION_UPDATE_ERROR,
  APP_VERSION_DELETE,
  APP_VERSION_DELETE_SUCCESS,
  APP_VERSION_DELETE_ERROR,
} from '@/store/actions/appVersion';
import { handleRequestError } from '@/utils/error';

import Repository, { APP_VERSIONS } from '@/repositories/RepositoryFactory';

const AppVersionsRepository = Repository.get(APP_VERSIONS);

const state = {
  appVersion: {},
  appVersionStatus: '',
  appVersionList: [],
  appVersionListMeta: '',
  appVersionListStatus: '',
};

const getters = {
  appVersion: state => state.appVersion,
  appVersionStatus: state => state.appVersionStatus,
  appVersionList: state => state.appVersionList,
  appVersionListMeta: state => state.appVersionListMeta,
  appVersionListStatus: state => state.appVersionListStatus,
};

const actions = {
  [APP_VERSION_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(APP_VERSION_LIST_REQUEST);
    try {
      const response = await AppVersionsRepository.get(payload.data);
      commit(APP_VERSION_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(APP_VERSION_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [APP_VERSION_SHOW]: async ({ commit }, payload) => {
    commit(APP_VERSION_SHOW);
    try {
      const response = await AppVersionsRepository.show(payload.id);
      const { data } = response;
      commit(APP_VERSION_SHOW_SUCCESS, data);
    } catch (error) {
      commit(APP_VERSION_SHOW_ERROR);
      handleRequestError(error);
    }
  },
  [APP_VERSION_CREATE]: async ({ commit }, payload) => {
    commit(APP_VERSION_CREATE);
    try {
      const response = await AppVersionsRepository.create(payload.data);
      commit(APP_VERSION_CREATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(APP_VERSION_CREATE_ERROR);
      handleRequestError(error);
    }
  },
  [APP_VERSION_UPDATE]: async ({ commit }, payload) => {
    commit(APP_VERSION_UPDATE);
    try {
      const response = await AppVersionsRepository.update(payload.id, payload.data);
      commit(APP_VERSION_UPDATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(APP_VERSION_UPDATE_ERROR);
      handleRequestError(error);
    }
  },
  [APP_VERSION_DELETE]: async ({ commit }, payload) => {
    commit(APP_VERSION_DELETE);
    try {
      await AppVersionsRepository.delete(payload.id);
      commit(APP_VERSION_DELETE_SUCCESS, payload.id);
    } catch (error) {
      commit(APP_VERSION_DELETE_ERROR);
      handleRequestError(error);
    }
  },
};

const mutations = {
  [APP_VERSION_LIST_REQUEST]: state => {
    state.appVersionListStatus = 'loading';
  },
  [APP_VERSION_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.appVersionListStatus = 'success';
    state.appVersionList = payload.data;
    if (payload.meta) {
      state.appVersionListMeta = payload.meta;
    }
  },
  [APP_VERSION_LIST_REQUEST_ERROR]: state => {
    state.appVersionListStatus = 'error';
  },
  [APP_VERSION_SHOW]: state => {
    state.appVersionStatus = 'loading';
  },
  [APP_VERSION_SHOW_SUCCESS]: (state, payload) => {
    state.appVersionStatus = 'success';
    state.appVersion = payload.data;
  },
  [APP_VERSION_SHOW_ERROR]: state => {
    state.appVersionStatus = 'error';
  },
  [APP_VERSION_CREATE]: state => {
    state.appVersionStatus = 'loading';
  },
  [APP_VERSION_CREATE_SUCCESS]: (state, payload) => {
    state.appVersionStatus = 'success';
    state.appVersionList.unshift(payload);
  },
  [APP_VERSION_CREATE_ERROR]: state => {
    state.appVersionStatus = 'error';
  },
  [APP_VERSION_UPDATE]: state => {
    state.appVersionStatus = 'loading';
  },
  [APP_VERSION_UPDATE_SUCCESS]: (state, payload) => {
    state.appVersionStatus = 'success';
    state.appVersionList = state.appVersionList.map(item => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },
  [APP_VERSION_UPDATE_ERROR]: state => {
    state.appVersionStatus = 'error';
  },
  [APP_VERSION_DELETE]: state => {
    state.appVersionStatus = 'deleting';
  },
  [APP_VERSION_DELETE_SUCCESS]: (state, payload) => {
    state.appVersionStatus = 'deleted';
    const index = findIndex(state.appVersionList, { id: payload });
    state.appVersionList.splice(index, 1);
  },
  [APP_VERSION_DELETE_ERROR]: state => {
    state.appVersionStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
