<template>
  <div>
    <div class="intro-y w-full relative">
      <SearchIcon
        class="w-4 h-4 absolute my-auto inset-y-0 ml-3 left-0 z-10 text-gray-600 dark:text-gray-300"
      />
      <input
        type="text"
        v-model="search"
        @input="fetchResults"
        :placeholder="$t('text.ownerSearchByPhoneNumber')"
        class="pl-10 pr-24 input input-lg w-full border"
        input-class="input-owner-search"
      />
      <Button
        v-if="rows.length < 1 && search"
        @click="onOwnerModalShow"
        class="w-20 absolute my-1 mr-1 inset-y-0 right-0 z-10 bg-gray-300 dark:bg-dark-5 dark:text-gray-300 text-gray-600"
        >{{ $t('text.add') }}</Button
      >
    </div>

    <div
      v-if="isLoading"
      class="border border-t-0 dark:border-dark-4 rounded-b-md -mt-1 py-4 pt-5 px-5 flex items-center justify-center"
    >
      <LoadingIcon icon="oval" class="w-5 h-5" />
    </div>
    <template v-else>
      <div
        v-if="rows.length < 1 && search"
        class="border border-t-0 dark:border-dark-4 rounded-b-md -mt-1 py-4 pt-5 px-5 text-gray-500"
      >
        {{ $t('text.noResult') }}
      </div>
      <div
        v-if="rows.length > 0 && search"
        class="border border-t-0 dark:border-dark-4 rounded-b-md -mt-1"
      >
        <template v-for="(item, index) in rows">
          <div
            @click="onOwnerSelect(item)"
            :key="index"
            class="px-4 py-3 border-gray-300 dark:border-dark-4 cursor-pointer hover:bg-gray-200 dark:hover:bg-dark-2"
            :class="[{ 'border-t': index > 0 }, { 'pt-4': index < 1 }]"
          >
            <PeopleFullname :item="item" />
          </div>
        </template>
      </div>
    </template>

    <OwnerModalForm
      :modal-id="modalId"
      :business="business"
      :building-id="parseInt($route.params.buildingId, 10)"
      :search-value="search"
      @onOwnerAdd="onOwnerAdd"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Button from '@/components/ui/button/Button';
import OwnerModalForm from '@/components/owner/OwnerModalForm';
import { OWNER_SEARCH, OWNER_SEARCH_LIST_CLEAR } from '@/store/actions/owner';
import PeopleFullname from '@/components/people/PeopleFullname';

export default {
  components: { OwnerModalForm, Button, PeopleFullname },

  props: {
    business: Object,
    buildingId: Number,
    modalId: String,
  },

  data() {
    return {
      search: '',
      timeout: null,
      debounceMilliseconds: 250,
      rows: [],
    };
  },

  computed: {
    ...mapGetters(['ownerSearchStatus', 'ownerSearchList']),
    isLoading() {
      return this.ownerSearchStatus === 'loading';
    },
  },

  methods: {
    onOwnerModalShow() {
      cash(`#${this.modalId}`).modal('show');
    },

    onOwnerAdd(owner) {
      const data = { ...owner, new: true };
      this.$emit('onOwnerAdd', data);
      this.resetSearch();
    },

    onOwnerSelect(owner) {
      this.$emit('onOwnerAdd', owner);
      this.resetSearch();
    },

    async resetSearch() {
      await this.$store.dispatch(OWNER_SEARCH_LIST_CLEAR);
      this.search = '';
    },

    fetchResults() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.fetchData();
      }, this.debounceMilliseconds);
    },

    async fetchData() {
      const data = {
        filters: {},
        sort: {
          field: 'id',
          type: 'desc',
        },
        page: 1,
        perPage: 5,
      };

      data.filters['filter[buildingId]'] = this.$route.params.buildingId
        ? this.$route.params.buildingId
        : undefined;
      data.filters['filter[phone]'] = this.search;

      const payload = {
        businessId: this.business.id,
        query: this.search,
        data,
      };

      await this.$store.dispatch(OWNER_SEARCH, payload);
      this.rows = this.ownerSearchList;
    },
  },
};
</script>
