<template>
  <TableRegular
    :loading="loading"
    :downloading="downloading"
    :rows="rows"
    :columns="columns"
    :custom-filters="customFilters"
    :sort="sort"
    :page="page"
    :per-page="perPage"
    :page-size="pageSize"
    :total-page="totalPage"
    :downloadable="downloadable && checkPermission('service.services.download')"
    @onFilter="$emit('onFilter', $event)"
    @onPageChange="$emit('onPageChange', $event)"
    @onPerPageChange="$emit('onPerPageChange', $event)"
    @onSortChange="$emit('onSortChange', $event)"
    @onDownload="$emit('onDownload')"
    @onChangeCustomFilter="$emit('onChangeCustomFilter', $event)"
  >
    <template slot="table-row" slot-scope="props">
      <p v-if="props.column.field === 'key'" class="font-medium whitespace-nowrap text-sm">
        {{ props.row.key }}
      </p>
      <p v-else-if="props.column.field === 'text'">
        {{ props.row.text }}
      </p>
      <template v-else-if="props.column.field === 'isActive'">
        <div :class="['status', `is-${props.row.isActive ? 'active' : 'inactive'}`]">
          {{ $t(`text.${props.row.isActive ? 'active' : 'inactive'}`) }}
        </div>
      </template>
      <p v-else-if="props.column.field === 'businessName'" class="w-40">
        {{ props.row.business ? props.row.business.name : '' }}
      </p>
      <p v-else-if="props.column.field === 'createdAt'" class="font-medium whitespace-nowrap">
        {{ props.row.createdAt | momentDate('YYYY-MM-DD HH:mm') }}
      </p>
      <p v-else-if="props.column.field === 'action'" class="flex justify-center items-center">
        <router-link
          v-if="checkPermission('sms_template.sms_template.edit')"
          :to="{ name: 'SmsTemplateUpdate', params: { id: props.row.id } }"
          class="flex items-center mr-3"
        >
          <CheckSquareIcon class="w-4 h-4 mr-1" />
          {{ $t('table.edit') }}
        </router-link>
      </p>
    </template>
  </TableRegular>
</template>

<script>
import TableRegular from '@/components/ui/table/TableRegular';
import tableMixin from '@/mixins/tableMixin';

export default {
  mixins: [tableMixin],

  methods: {
    openModal(index) {
      cash(`#modal-see-more-apartments-${index}`).modal('show');
    },
  },

  components: {
    TableRegular,
  },
};
</script>
