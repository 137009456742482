export const IMPORTERS_LIST_REQUEST = 'IMPORTERS_LIST_REQUEST';
export const IMPORTERS_LIST_REQUEST_SUCCESS = 'IMPORTERS_LIST_REQUEST_SUCCESS';
export const IMPORTERS_LIST_REQUEST_ERROR = 'IMPORTERS_LIST_REQUEST_ERROR';

export const IMPORTERS_ADD = 'IMPORTERS_ADD';
export const IMPORTERS_ADD_SUCCESS = 'IMPORTERS_ADD_SUCCESS';
export const IMPORTERS_ADD_ERROR = 'IMPORTERS_ADD_ERROR';

export const IMPORTERS_SHOW = 'IMPORTERS_SHOW';
export const IMPORTERS_SHOW_SUCCESS = 'IMPORTERS_SHOW_SUCCESS';
export const IMPORTERS_SHOW_ERROR = 'IMPORTERS_SHOW_ERROR';

export const IMPORTERS_UPDATE = 'IMPORTERS_UPDATE';
export const IMPORTERS_UPDATE_SUCCESS = 'IMPORTERS_UPDATE_SUCCESS';
export const IMPORTERS_UPDATE_ERROR = 'IMPORTERS_UPDATE_ERROR';

export const IMPORTERS_DELETE = 'IMPORTERS_DELETE';
export const IMPORTERS_DELETE_SUCCESS = 'IMPORTERS_DELETE_SUCCESS';
export const IMPORTERS_DELETE_ERROR = 'IMPORTERS_DELETE_ERROR';
