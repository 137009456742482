var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"field"},[(_vm.label)?_c('label',{staticClass:"flex flex-col sm:flex-row"},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.helpText)?_c('span',{staticClass:"sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600"},[_vm._v(" "+_vm._s(_vm.helpText)+" ")]):_vm._e()]):_vm._e(),(_vm.isTextarea)?_c('textarea',{class:[
      'input w-full h-24 border block',
      _vm.inputClass,
      { 'border-theme-6': _vm.hasError },
      { 'mt-2': _vm.label } ],attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"maxlength":_vm.maxLength},domProps:{"value":_vm.value},on:{"input":_vm.onChange,"focusout":_vm.onFocusout,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onKeydownEnter.apply(null, arguments)}}}):_c('input',{ref:"ref",class:[
      'input w-full border block',
      _vm.inputClass,
      { 'border-theme-6': _vm.hasError },
      { 'mt-2': _vm.label } ],attrs:{"type":_vm.type,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"maxlength":_vm.maxLength},domProps:{"value":_vm.value},on:{"input":_vm.onChange,"focusout":_vm.onFocusout,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onKeydownEnter.apply(null, arguments)}}}),(_vm.hasError)?_c('HasError',{attrs:{"vuelidate":_vm.vuelidate,"invalidFeedback":_vm.invalidFeedback}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }