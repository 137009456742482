export const EXPENSE_LIST_REQUEST = 'EXPENSE_LIST_REQUEST';
export const EXPENSE_LIST_REQUEST_SUCCESS = 'EXPENSE_LIST_REQUEST_SUCCESS';
export const EXPENSE_LIST_REQUEST_ERROR = 'EXPENSE_LIST_REQUEST_ERROR';

export const EXPENSE_SEARCH = 'EXPENSE_SEARCH';
export const EXPENSE_SEARCH_SUCCESS = 'EXPENSE_SEARCH_SUCCESS';
export const EXPENSE_SEARCH_ERROR = 'EXPENSE_SEARCH_ERROR';
export const EXPENSE_SEARCH_LIST_CLEAR = 'EXPENSE_SEARCH_LIST_CLEAR';

export const EXPENSE_ADD = 'EXPENSE_ADD';
export const EXPENSE_ADD_SUCCESS = 'EXPENSE_ADD_SUCCESS';
export const EXPENSE_ADD_ERROR = 'EXPENSE_ADD_ERROR';

export const EXPENSE_UPDATE = 'EXPENSE_UPDATE';
export const EXPENSE_UPDATE_SUCCESS = 'EXPENSE_UPDATE_SUCCESS';
export const EXPENSE_UPDATE_ERROR = 'EXPENSE_UPDATE_ERROR';

export const EXPENSE_REMOVE = 'EXPENSE_REMOVE';
export const EXPENSE_REMOVE_SUCCESS = 'EXPENSE_REMOVE_SUCCESS';
export const EXPENSE_REMOVE_ERROR = 'EXPENSE_REMOVE_ERROR';

export const EXPENSE_REQUEST = 'EXPENSE_REQUEST';
export const EXPENSE_REQUEST_SUCCESS = 'EXPENSE_REQUEST_SUCCESS';
export const EXPENSE_REQUEST_ERROR = 'EXPENSE_REQUEST_ERROR';

export const EXPENSE_LIST_IMPORT = 'EXPENSE_LIST_IMPORT';
export const EXPENSE_LIST_IMPORT_SUCCESS = 'EXPENSE_LIST_IMPORT_SUCCESS';
export const EXPENSE_LIST_IMPORT_ERROR = 'EXPENSE_LIST_IMPORT_ERROR';

export const EXPENSE_LIST_EXPORT = 'EXPENSE_LIST_EXPORT';
export const EXPENSE_LIST_EXPORT_SUCCESS = 'EXPENSE_LIST_EXPORT_SUCCESS';
export const EXPENSE_LIST_EXPORT_ERROR = 'EXPENSE_LIST_EXPORT_ERROR';
