<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <PageHeader class="text-lg font-medium mr-auto" :title="title" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <Button
          type="button"
          :to="{ name: 'Tickets' }"
          class="box text-gray-700 dark:text-gray-300 mr-2 flex"
        >
          {{ $t('text.back') }}
        </Button>
        <Button
          type="button"
          @click="onSubmit"
          :loading="isLoading"
          class="w-24 bg-theme-1 text-white"
        >
          {{ $t('text.save') }}
        </Button>
      </div>
    </div>
    <div class="intro-y mt-5">
      <form @submit.prevent="onSubmit">
        <div class="grid grid-cols-12 gap-6">
          <div class="intro-y col-span-12 sm:col-span-12 lg:col-span-4 xl:col-span-4">
            <div class="grid grid-cols-12 gap-6">
              <div class="intro-y col-span-12 sm:col-span-6 lg:col-span-12 xl:col-span-12">
                <div class="box">
                  <div class="p-5 border-b border-gray-200 dark:border-dark-5">
                    <h2 class="font-medium text-base">{{ $t('nav.ownerShow') }}</h2>
                  </div>
                  <div v-if="owner" class="intro-y py-4 px-5 relative">
                    <PeopleFullname :item="owner" />
                    <div
                      @click="updateOwner(null)"
                      class="w-5 h-5 cursor-pointer flex items-center justify-center absolute rounded-full text-white bg-theme-6 right-0 top-1/2 transform translate-x-1/2 -translate-y-1/2"
                    >
                      <xIcon class="w-4 h-4" />
                    </div>
                  </div>
                  <OwnerSearch
                    v-else
                    class="p-5"
                    :business="business"
                    :building-id="parseInt($route.params.buildingId, 10)"
                    modal-id="apartment-owner-form-modal"
                    @onOwnerAdd="updateOwner"
                  />
                </div>
              </div>
              <div class="intro-y col-span-12 sm:col-span-6 lg:col-span-12 xl:col-span-12">
                <div class="box">
                  <div class="intro-y p-5 border-b border-gray-200 dark:border-dark-5">
                    <h2 class="font-medium text-base">{{ $t('nav.buildingShow') }}</h2>
                  </div>
                  <div class="intro-y py-4 px-5">
                    <InputSelect
                      v-model.number="$v.buildingId.$model"
                      :options="buildingList"
                      :label="$t('text.selectBuilding')"
                      value-key="id"
                      :vuelidate="$v.buildingId"
                      :disabled="isLoadingBuildings"
                      input-class="input-ticket-building"
                      @input="onChangeBuilding()"
                    />
                    <template v-if="buildingId">
                      <InputSelect
                        v-model.number="$v.apartmentId.$model"
                        :options="apartmentList"
                        :label="$t('text.selectApartment')"
                        label-key="number"
                        value-key="id"
                        :vuelidate="$v.apartmentId"
                        :disabled="isLoadingApartments"
                        @input="onChangeApartment"
                        class="intro-y"
                        input-class="input-ticket-apartment"
                      />
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="intro-y col-span-12 sm:col-span-12 lg:col-span-8 xl:col-span-8">
            <div class="intro-y box">
              <div class="p-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class="font-medium text-base">{{ $t('nav.ticketShow') }}</h2>
              </div>
              <div class="py-4 px-5 relative">
                <div class="grid grid-cols-12 gap-6">
                  <div class="intro-y col-span-12 sm:col-span-12 lg:col-span-6 xl:col-span-6">
                    <InputSelect
                      v-model.number="$v.form.ticketCategoryId.$model"
                      :options="ticketCategories"
                      :label="$t('text.category')"
                      value-key="id"
                      :vuelidate="$v.form.ticketCategoryId"
                      input-class="input-ticket-category"
                    />
                    <InputSelect
                      v-model.number="$v.form.ticketTypeId.$model"
                      :options="ticketTypes"
                      :label="$t('text.type')"
                      value-key="id"
                      :vuelidate="$v.form.ticketTypeId"
                      input-class="input-ticket-type"
                    />
                    <InputSelect
                      v-model.number="$v.form.priority.$model"
                      :options="priorityList"
                      :label="$t('text.priority')"
                      value-key="id"
                      :vuelidate="$v.form.priority"
                      input-class="input-ticket-priority"
                    />
                    <InputSelect
                      v-model="$v.form.channel.$model"
                      :options="channelList"
                      :label="$t('text.channel')"
                      value-key="id"
                      :vuelidate="$v.form.channel"
                      input-class="input-ticket-channel"
                    />
                    <InputDate
                      v-model="$v.form.ticketDate.$model"
                      :vuelidate="$v.form.ticketDate"
                      input-class="input-ticket-date"
                      :label="$t('text.date')"
                    />
                  </div>
                  <div class="intro-y col-span-12 sm:col-span-12 lg:col-span-6 xl:col-span-6">
                    <InputString
                      :label="$t('text.title')"
                      v-model.trim="$v.form.title.$model"
                      :vuelidate="$v.form.title"
                      input-class="input-ticket-title"
                    />
                    <InputString
                      :label="$t('text.content')"
                      v-model.trim="$v.form.description.$model"
                      :vuelidate="$v.form.description"
                      input-class="input-ticket-description"
                      is-textarea
                    />
                    <FieldWithLabel :label="$t('text.attachImages')">
                      <div class="grid grid-cols-12 gap-6">
                        <div class="intro-y col-span-6 sm:col-span-6 lg:col-span-6 xl:col-span-6">
                          <InputPhoto
                            :vuelidate="$v.form.files"
                            class="input-ticket-image-uploader"
                            @change="onChangeFile"
                          />
                        </div>
                        <div
                          v-for="(photo, photoIndex) in form.files"
                          :key="`photo-${photoIndex}`"
                          class="intro-y col-span-6 sm:col-span-6 lg:col-span-6 xl:col-span-6"
                        >
                          <InputPhoto
                            :photo="photo"
                            class="input-ticket-image-remover"
                            @remove="onDeleteFile(photoIndex)"
                          />
                        </div>
                      </div>
                    </FieldWithLabel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { assign } from 'lodash';
import { APARTMENT_LIST_REQUEST } from '@/store/actions/apartment';
import { BUILDING_LIST_REQUEST } from '@/store/actions/building';
import {
  TICKET_REQUEST,
  TICKET_ADD,
  TICKET_UPDATE,
  TICKET_CATEGORIES_REQUEST,
  TICKET_TYPES_REQUEST,
} from '@/store/actions/ticket';
import { OWNER_REMOVE } from '@/store/actions/owner';
import { FILE_UPLOAD } from '@/store/actions/file';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import Button from '@/components/ui/button/Button';
import PageHeader from '@/components/ui/PageHeader';
import OwnerSearch from '@/components/owner/OwnerSearch';
import PeopleFullname from '@/components/people/PeopleFullname';
import InputString from '@/components/ui/input/InputString';
import InputSelect from '@/components/ui/input/InputSelect';
import InputDate from '@/components/ui/input/InputDate';
import InputPhoto from '@/components/ui/input/InputPhoto';
import FieldWithLabel from '@/components/ui/input/FieldWithLabel';
import { PRIORITY_LEVELS, CHANNELS } from '@/utils/constants';
import moment from 'moment';

export default {
  mixins: [validationMixin],

  components: {
    Button,
    PageHeader,
    OwnerSearch,
    PeopleFullname,
    InputString,
    InputSelect,
    InputDate,
    InputPhoto,
    FieldWithLabel,
  },

  props: {
    business: Object,
  },

  validations: {
    form: {
      ticketCategoryId: {
        required,
      },
      ticketTypeId: {
        required,
      },
      priority: {
        required,
      },
      channel: {
        required,
      },
      ticketDate: {
        required,
      },
      title: {
        required,
      },
      description: {
        required,
      },
      files: {
        required,
      },
    },
    owner: {
      required,
    },
    buildingId: {
      required,
    },
    apartmentId: {
      required,
    },
  },

  data() {
    return {
      form: {
        ticketCategoryId: null,
        ticketTypeId: null,
        priority: null,
        channel: null,
        ticketDate: moment().format('YYYY-MM-DD'),
        title: '',
        description: '',
        files: [],
      },
      owner: null,
      buildingId: null,
      apartmentId: null,
      priorityList: PRIORITY_LEVELS,
      channelList: CHANNELS,
      defaultTableData: {
        filters: {},
        sort: {
          field: 'id',
          type: 'desc',
        },
        page: 1,
        perPage: 100,
      },
      isOwnerOfThisApartment: false,
    };
  },

  computed: {
    ...mapGetters([
      'ticketStatus',
      'ticket',
      'ticketCategories',
      'ticketTypes',
      'apartmentList',
      'apartmentListStatus',
      'buildingList',
      'buildingStatus',
      'getFileUpload',
    ]),
    isLoading() {
      return this.ticketStatus === 'loading';
    },
    isLoadingBuildings() {
      return this.buildingStatus === 'loading';
    },
    isLoadingApartments() {
      return this.apartmentListStatus === 'loading';
    },
    title() {
      return this.$route.name === 'TicketCreate'
        ? this.$t(`nav.ticketCreate`)
        : this.$t(`nav.ticketUpdate`);
    },
  },

  async created() {
    await this.fetchData();
  },

  methods: {
    async fetchData() {
      if (this.$route.params.id) {
        const payload = { businessId: this.business.id, id: this.$route.params.id };
        await this.$store.dispatch(TICKET_REQUEST, payload);
        if (this.ticket) {
          const {
            ticketCategoryId,
            ticketTypeId,
            priority,
            channel,
            ticketDate,
            title,
            description,
            owner,
            apartment,
            photos,
          } = this.ticket;

          this.form = {
            ticketCategoryId,
            ticketTypeId,
            priority,
            channel,
            ticketDate,
            title,
            description,
            files: photos.map(photo => {
              return photo.gallery;
            }),
          };

          this.owner = owner;
          this.buildingId = apartment ? apartment.buildingId : null;
          await this.getApartmentList();
          this.apartmentId = apartment ? apartment.id : null;
        }
      }

      await this.getBuildingList();
      await this.getTicketCategoriesAndTypes();
    },

    getTicketCategoriesAndTypes() {
      this.$store.dispatch(TICKET_CATEGORIES_REQUEST);
      this.$store.dispatch(TICKET_TYPES_REQUEST);
    },

    async getBuildingList() {
      const payload = {
        businessId: this.business.id,
        data: this.defaultTableData,
      };

      await this.$store.dispatch(BUILDING_LIST_REQUEST, payload);
    },

    async getApartmentList() {
      const data = this.defaultTableData;
      data.filters['filter[buildingId]'] = this.buildingId;
      data.filters['filter[isParking]'] = 0;

      const payload = {
        businessId: this.business.id,
        data,
      };

      await this.$store.dispatch(APARTMENT_LIST_REQUEST, payload);
    },

    async updateOwner(owner) {
      await this.checkAndDelete();
      this.owner = owner;
    },

    async checkAndDelete() {
      if (this.owner && this.owner.new) {
        try {
          const payload = {
            businessId: this.business.id,
            id: this.owner.id,
          };
          await this.$store.dispatch(OWNER_REMOVE, payload);
        } catch (error) {
          this.$toasted.show(error.message, { type: 'error' });
        }
      }
    },

    async onChangeBuilding() {
      this.apartmentId = null;
      await this.getApartmentList();
    },

    onChangeApartment(id) {
      const apartment = this.apartmentList.find(item => item.id === parseInt(id, 10));
      if (apartment) {
        const owner = apartment.owners.findIndex(item => item.id === this.owner.id);
        this.isOwnerOfThisApartment = owner > -1;
      }
    },
    async onChangeFile(e) {
      const file = e.target.files[0];
      await this.$store.dispatch(FILE_UPLOAD, file);
      if (this.getFileUpload.status === 'success') {
        this.form.files.push(this.getFileUpload.item);
      }
    },
    async onDeleteFile(index) {
      this.form.files.splice(index, 1);
    },
    async onSubmit() {
      if (this.$v) {
        this.$v.$touch();

        if (this.$v.$anyError) {
          if (this.$v.owner.$anyError) {
            this.$toasted.show(this.$t('toast.requiredOwner'), { type: 'error' });
            return;
          }
          this.$toasted.show(this.$t('toast.failedForm'), { type: 'error' });
          return;
        }
      }

      try {
        const payload = {
          businessId: this.business.id,
          data: {
            ...this.form,
            ownerId: this.owner.id,
            apartmentId: this.apartmentId,
          },
        };
        if (this.$route.params.id) {
          assign(payload, { id: this.ticket.id });
          await this.$store.dispatch(TICKET_UPDATE, payload);
        } else {
          await this.$store.dispatch(TICKET_ADD, payload);
        }
        if (this.ticketStatus === 'success') {
          this.$router.push({ name: 'Tickets' });
          this.$toasted.show(this.$t('toast.success'), { type: 'success' });
        }
      } catch (error) {
        this.$toasted.show(error.message, { type: 'error' });
      }
    },
  },
};
</script>
