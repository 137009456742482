<template>
  <div class="content-meeting">
    <div v-if="loading" class="flex items-center justify-center flex-col py-3">
      <LoadingIcon icon="oval" class="w-10 h-10" />
      <p class="text-gray-500 mt-3">{{ $t('table.loading') }}</p>
    </div>
    <div v-else class="intro-y flex flex-col sm:flex-row items-center mt-5">
      <div class="w-full xs:w-auto mt-4 sm:mt-0">
        <div>
          <div class="flex items-start px-5 pt-5 pb-5 border-b border-gray-200">
            <div class="w-full flex flex-col lg:flex-row items-center">
              <div class="flex-1">
                <h3 class="text-base font-medium text-black">{{ $t('nav.meeting') }}</h3>
              </div>
              <div class="flex-1 text-right">
                <Button
                  class="button inline-flex items-center justify-center w-30 bg-theme-14 text-theme-10 mr-3 text-sm"
                >
                  {{ $t('meeting.meetingLogout') }}
                </Button>
                <Button
                  class="button inline-flex items-center justify-center w-30 bg-theme-14 text-theme-10 mr-3"
                >
                  {{ $t('meeting.meetingClose') }}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="intro-y mt-5 overflow-auto lg:overflow-visible">
      <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12 md:col-span-3 lg:col-span-3">
          <div v-if="isMeetingAdmin" class="box mb-5">
            <MeetingApprove
              v-if="!loading"
              :users="users"
              :users-data="usersData"
              :meeting="meeting"
              :client="roomClient"
            />
          </div>
          <div class="box">
            <MeetingApartmentList v-if="!loading" :users="usersData" :meeting="meeting" />
          </div>
        </div>
        <div class="col-span-12 md:col-span-9 lg:col-span-9">
          <MeetingVideo
            @client="roomClient = $event"
            @users="users = $event"
            @users-data="usersData = $event"
            v-if="!loading"
            :meeting="meeting"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Client from '@/clients/apiClient';
import MeetingApprove from '@/components/meeting/video/MeetingApprove';
import MeetingVideo from '@/components/meeting/video/MeetingVideo';
import MeetingApartmentList from '@/components/meeting/video/MeetingApartmentList';
import { mapGetters } from 'vuex';
import { USER_REQUEST } from '@/store/actions/user';

export default {
  components: {
    MeetingApprove,
    MeetingApartmentList,
    MeetingVideo,
  },
  data() {
    return {
      businessId: this.$route.params.businessId,
      meetingId: this.$route.params.id,
      meeting: {},
      resourceBase: 'business',
      resource: 'meeting',
      loading: true,

      usersData: [],
      users: [],
      roomClient: null,
    };
  },
  computed: {
    ...mapGetters(['user', 'me']),
    isMeetingAdmin() {
      if (!this.me) return false;
      if (!this.me?.business) return false;

      return this.me?.business?.id === this.meeting?.business?.id;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      Promise.all([
        this.$store.dispatch(USER_REQUEST),
        Client.get(`business/${this.businessId}/meeting/${this.meetingId}`),
      ]).then(values => {
        this.meeting = values[1].data.data;
        if (values[1].status === 200) this.loading = false;
      });
    },
  },
};
</script>
<style lang="css">
.content-meeting {
  border-radius: 30px;
  min-height: 100vh;
  min-width: 0;
  flex: 1 1 0;
  --tw-bg-opacity: 1;
  padding: 0 22px 2.5rem;
  background-color: #fff;
}
</style>
