import Client from '@/clients/apiClient';
import { isEmpty } from 'lodash';
import humps from 'humps';
import stringify from 'postcss/lib/stringify';

const resource = '/users';

export default {
  get(payload) {
    return Client.get(`${resource}?${stringify(humps.decamelizeKeys(payload.filters))}`);
  },
  getAll(payload) {
    return Client.get(`${resource}?page=${payload.page}&limit=${payload.perPage}`);
  },
  getSelf() {
    return Client.get(`${resource}/me`);
  },
  register(payload) {
    return Client.post('/register', payload);
  },
  update(id, payload) {
    return Client.put(`${resource}/${id}`, payload);
  },
  validateIsUnique(payload) {
    return Client.post(`${resource}/validate`, payload);
  },
  getUserSales(id) {
    return Client.get(`users/${id}/sales`);
  },
  sendPasswordResetEmail(payload) {
    return Client.post(`password/email`, payload);
  },
  resetPassword(payload) {
    return Client.post(`reset-password-by-email`, payload);
  },
  async queryInSalon(salonId, payload) {
    const response = await Client.get(`/users?query=${encodeURIComponent(payload.query)}
    &filter[salon_id]=${salonId}`);
    return response.data.data;
  },

  async queryAll(payload) {
    const response = await Client.get(`/users?query=${encodeURIComponent(payload.query)}`);
    return response.data.data;
  },

  addClient(payload) {
    if (isEmpty(payload.email)) {
      delete payload.email;
    }
    if (isEmpty(payload.phone)) {
      delete payload.phone;
    }
    return Client.post(`clients`, payload);
  },

  getUserPoints(id) {
    return Client.get(`users/${id}/points`);
  },

  getUserFeatures() {
    return Client.get(`features`);
  },

  getUserPermissionList(id) {
    return Client.get(`permission/get-permissions-by-user?user_id=${id}`);
  },

  updateUserPermission(payload) {
    return Client.post(`permission/store`, payload);
  },

  fetchUsers(payload) {
    return Client.get(
      `business/${payload.businessId}/user?page=${payload.data.page}&limit=${payload.data.perPage}`
    );
  },

  fetchSingleUser(payload) {
    return Client.get(`business/${payload.businessId}/user/${payload.id}`);
  },

  createUser(payload) {
    return Client.post(`business/${payload.businessId}/user`, payload.data);
  },

  updateUser(payload) {
    return Client.put(`business/${payload.businessId}/user/${payload.id}`, payload.data);
  },
};
