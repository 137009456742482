<template>
  <AuthLayout>
    <form @submit.prevent="onSubmit">
      <h2 class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
        {{ $t('auth.login') }}
      </h2>
      <div class="intro-x mt-8">
        <InputString
          type="email"
          :placeholder="$t('auth.email')"
          v-model.trim="$v.form.email.$model"
          :vuelidate="$v.form.email"
          input-class="input-login-email login__input input--lg"
        />

        <InputString
          type="password"
          :placeholder="$t('auth.password')"
          v-model.trim="$v.form.password.$model"
          :vuelidate="$v.form.password"
          input-class="input-login-password login__input input--lg"
        />
      </div>
      <div class="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4">
        <div class="flex items-center mr-auto">
          <input
            id="remember-me"
            type="checkbox"
            class="input-login-remember-me input border mr-2"
          />
          <label class="cursor-pointer select-none" for="remember-me">
            {{ $t('auth.rememberMe') }}
          </label>
        </div>
        <Button :to="{ name: 'UserForgotPassword' }">
          <span>{{ $t('auth.forgotPassword') }}</span>
        </Button>
      </div>
      <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
        <Button
          type="submit"
          class="button--lg w-full xl:w-32 text-white bg-theme-1 xl:mr-3 align-top"
          :loading="isLoading"
        >
          <span>{{ $t('auth.login') }}</span>
        </Button>
        <Button
          :to="{ name: 'UserRegister' }"
          class="button--lg w-full xl:w-32 text-gray-700 border border-gray-300 dark:border-dark-5 dark:text-gray-300 mt-3 xl:mt-0 align-top"
        >
          <span>{{ $t('auth.register') }}</span>
        </Button>
      </div>
    </form>
  </AuthLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import { REDIRECT_URL_KEY } from '@/config';
import InputString from '@/components/ui/input/InputString';
import Button from '@/components/ui/button/Button';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import { AUTH_REQUEST } from '@/store/actions/auth';
import AuthLayout from '@/views/auth/AuthLayout';

export default {
  components: { Button, InputString, AuthLayout },

  mixins: [validationMixin],

  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },

  data() {
    return {
      loading: false,
      form: {
        email: '',
        password: '',
      },
      error: '',
    };
  },

  computed: {
    ...mapGetters(['authStatus']),
    isLoading() {
      return this.authStatus === 'loading';
    },
  },

  mounted() {
    document.querySelector('body').classList.add('login');
  },

  methods: {
    async onSubmit() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          this.$toasted.show(this.$t('toast.failedForm'), { type: 'error' });
          return;
        }
      }

      try {
        await this.$store.dispatch(AUTH_REQUEST, this.form);
        if (this.$store.getters.isAuthenticated) {
          this.$router.push(window.$cookies.get(REDIRECT_URL_KEY) || { name: 'Dashboard' });
        }
      } catch (error) {
        this.form.password = '';
        if (error.response.status === 422) {
          this.$v.$reset();
          this.$toasted.show(this.$t('toast.incorrectCredentials'), { type: 'error' });
        }
      }
    },
  },
};
</script>
