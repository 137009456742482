<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center">
      <PageHeader class="text-lg font-medium mr-auto" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div class="box flex flex-row items-stretch mr-5">
          <div class="flex items-center px-4 border-r border-gray-300">
            {{ $t('text.statementLast') }}
          </div>
          <div v-if="isLoadingJob" class="flex items-center px-2 w-60">
            <LoadingIcon icon="rings" />
            <span class="ml-2 text-gray-600 italic">
              {{ $t('status.processing') }}
            </span>
          </div>
          <div v-else class="flex items-center justify-around px-2 w-60">
            <span>
              {{ $t('text.stateBank') }}
            </span>
            <span>
              {{ jobDate | momentDate('YYYY/MM/DD hh:mm') }}
            </span>
          </div>
        </div>
        <Button @click="showConfirm" class="button text-white bg-theme-11 shadow-md mr-2">
          {{ $t('text.clearUnusedTransactionData') }}
        </Button>
        <DropDownButtonList
          v-if="checkPermission('finance.income.upload')"
          :id="dropdownId"
          class="ml-auto sm:ml-0"
          toggler-class="button px-2 box text-gray-700 dark:text-gray-300"
          box-class="w-60"
          content-class="dark:bg-dark-1"
        >
          <a @click="showUploadDialog('STATEBANK')"><UploadIcon /> {{ $t('file.StateBank') }}</a>
          <a @click="showUploadDialog('KHAANBANK')"><UploadIcon /> {{ $t('file.khaanBank') }}</a>
          <a @click="showUploadDialog('TDBANK')">
            <UploadIcon /> {{ $t('file.TradeDevelopmentBank') }}
          </a>
          <a @click="showUploadDialog('GOLOMTBANK')"><UploadIcon /> {{ $t('file.GolomtBank') }}</a>
          <!-- <a @click="onUpload()"><UploadIcon /> {{ $t('file.KhasBank') }}</a> -->
        </DropDownButtonList>
      </div>
    </div>
    <div class="intro-y mt-5 overflow-auto lg:overflow-visible">
      <StatementTable
        :loading="isLoading"
        :rows="jobStatementList"
        :columns="columns"
        :line-numbers="true"
        :page="page"
        :per-page="perPage"
        :page-size="pageSize"
        :total-page="totalPage"
        :sort="sort"
        :custom-filters="customFilters"
        @transaction="showTransaction"
        @delete="confirmRemoval"
        @onFilter="onFilter"
        @onPageChange="onPageChange"
        @onPerPageChange="onPerPageChange"
        @onSortChange="onSortChange"
        @onChangeCustomFilter="onChangeCustomFilter"
      />
    </div>

    <StatementModalUpload
      :ref="uploadRefName"
      :title="uploadModalTitle"
      :bank="bank"
      :business="business"
      :is-invoice="uploadModalTypeIsInvoice"
      :modal-id="uploadModalId"
      @onImportSuccess="onImportSuccess"
    />
    <TransactionDialog
      :modal-id="transactionModalId"
      :business-id="business.id"
      :transaction-data="transactionData"
      :building-list="buildingList"
      @onImportSuccess="onImportSuccess"
    />
    <ConfirmDialog
      :modal-id="deleteModalId"
      :is-loading="isDeleting ? true : false"
      @ok="onDelete"
    />
    <TransactionConfirmDialog :modal-id="transactionDeleteModalId" @ok="deleteTransaction" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BUILDING_LIST_REQUEST } from '@/store/actions/building';
import {
  JOB_REQUEST,
  JOB_STATEMENT_REQUEST,
  UNUSED_TRANSACTION_DELETE,
  UNKNOWN_TRANSACTION_DELETE,
} from '@/store/actions/invoice';
import { PAYMENT_TYPES } from '@/utils/constants';
import dataListMixin from '@/mixins/dataListMixin';
import Button from '@/components/ui/button/Button';
import PageHeader from '@/components/ui/PageHeader';
import StatementTable from '@/components/statement/StatementTable';
import StatementModalUpload from '@/components/statement/statementModalUpload';
import TransactionConfirmDialog from '@/components/statement/TransactionConfirmDialog';
import TransactionDialog from '@/components/transaction/TransactionDialog';

export default {
  mixins: [dataListMixin],
  components: {
    PageHeader,
    StatementTable,
    StatementModalUpload,
    TransactionDialog,
    TransactionConfirmDialog,
    Button,
  },
  data() {
    return {
      storeActionListRequest: JOB_STATEMENT_REQUEST,
      storeActionRemove: UNKNOWN_TRANSACTION_DELETE,
      jobDate: '',
      uploadRefName: 'statementModalUpload',
      transactionData: {},
      bank: '',
      uploadModalTitle: '',
      uploadModalTypeIsInvoice: false,
      transactionDeleteModalId: 'transaction-delete-modal',
      columns: [
        { label: this.$t('table.date'), field: 'invoiceDate' },
        { label: this.$t('table.transactionDescription'), field: 'invoiceDescription' },
        { label: this.$t('table.relatedAccount'), field: 'accountId' },
        { label: this.$t('table.income'), field: 'dtAmt' },
        { label: this.$t('table.paymentType'), field: 'paymentType' },
        { label: this.$t('table.action'), field: 'action' },
      ],
      customFilters: [
        {
          key: 'paymentType',
          value: null,
          label: this.$t('table.paymentType'),
          type: 'select',
          options: PAYMENT_TYPES,
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'buildingList',
      'jobStatementList',
      'jobStatementListStatus',
      'jobStatementMeta',
      'jobRequestStatus',
      'jobRequest',
      'transactionDeleteStatus',
    ]),
    isLoadingJob() {
      return this.jobRequestStatus === 'loading';
    },
    isFinishedJob() {
      return this.jobRequestStatus !== 'loading';
    },
    isLoading() {
      return this.jobStatementListStatus === 'loading';
    },
  },
  async created() {
    await this.fetchBuildingList();
    await this.getJobStatus();
  },
  methods: {
    async fetchBuildingList() {
      const payload = {
        businessId: this.business.id,
        data: {
          filters: {},
          sort: {
            field: 'id',
            type: 'desc',
          },
          page: 1,
          perPage: 100,
        },
      };
      await this.$store.dispatch(BUILDING_LIST_REQUEST, payload);
      if (this.buildingStatus === 'success') {
        this.customFilters[0].options = this.buildingList.map(item => item);
      }
    },
    onChangeCustomFilter(object) {
      const index = this.customFilters.findIndex(item => item.key === object.key);
      if (index > -1) {
        const { value } = object;
        this.customFilters[index].value = value || null;
        this.page = 1;
        this.fetchData();
      }
    },
    setFilterValues(data) {
      this.customFilters.forEach(item => {
        data.filters[`filter[${item.key}]`] = item.value;
      });
    },
    onImportSuccess() {
      this.getJobStatus();
    },
    async getJobStatus() {
      const payload = {
        businessId: this.business.id,
        type: 'account_statement',
      };
      await this.$store.dispatch(JOB_REQUEST, payload);
      if (this.jobRequestStatus === 'success') {
        if (this.jobRequest.data.status === 'done') {
          this.jobDate = this.jobRequest.data.updatedAt;
          this.fetchData();
        } else {
          setTimeout(() => {
            this.fetchData();
          }, 2000);
        }
      }
    },
    showTransaction(id, amount, desc, paymentType) {
      this.transactionData = {
        transactionId: id,
        transactionAmount: amount,
        transactionDesc: desc,
        transactionPaymentType: paymentType,
      };
      cash(`#${this.transactionModalId}`).modal('show');
    },
    async deleteTransaction() {
      const payload = {
        businessId: this.business.id,
      };
      await this.$store.dispatch(UNUSED_TRANSACTION_DELETE, payload);
      if (this.transactionDeleteStatus === 'success') {
        this.$toasted.show(this.$t('toast.success'), { type: 'success' });
        await this.fetchData();
      }
    },
    showConfirm() {
      cash(`#${this.transactionDeleteModalId}`).modal('show');
    },
    showUploadDialog(varBank) {
      this.bank = varBank;
      switch (varBank) {
        case 'STATEBANK':
          this.uploadModalTitle = this.$t('nav.statementOfStateBankBillUpload');
          break;
        case 'KHAANBANK':
          this.uploadModalTitle = this.$t('nav.statementOfKhaanBankBillUpload');
          break;
        case 'TDBANK':
          this.uploadModalTitle = this.$t('nav.statementOfTDBankBillUpload');
          break;
        case 'GOLOMTBANK':
          this.uploadModalTitle = this.$t('nav.statementOfGolomtBankBillUpload');
          break;
        default:
          break;
      }
      this.onUpload();
    },
    setPaginationValues() {
      this.totalRecords = this.jobStatementMeta ? this.jobStatementMeta.total : 1;
      this.page = this.jobStatementMeta.currentPage;
      this.totalPage = this.jobStatementMeta.lastPage;
    },
  },
};
</script>
