import Index from '@/views/Sms/Index';
import SendSms from '@/views/Sms/SendSms';

export default [
  {
    path: '/smsSend',
    component: Index,
    meta: { group: 'sms' },
    children: [
      {
        path: '',
        component: SendSms,
        name: 'SendSms',
      },
    ],
  },
];
