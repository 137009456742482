<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <PageHeader class="text-lg font-medium mr-auto" :title="title" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <Button
          type="button"
          :to="{ name: 'Importers' }"
          class="box text-gray-700 dark:text-gray-300 mr-2 flex"
        >
          {{ $t('text.back') }}
        </Button>
        <Button
          type="button"
          @click="onSubmit"
          :loading="isLoading"
          class="w-24 bg-theme-1 text-white"
        >
          {{ $t('text.save') }}
        </Button>
      </div>
    </div>
    <div class="intro-y mt-5">
      <form @submit.prevent="onSubmit">
        <div class="grid grid-cols-12 gap-6">
          <div class="intro-y col-span-12 sm:col-span-6 lg:col-span-12 xl:col-span-12">
            <div class="intro-y box">
              <div class="py-4 px-5 relative">
                <div class="grid grid-cols-12 gap-6">
                  <div class="intro-y col-span-12 sm:col-span-12 lg:col-span-6 xl:col-span-6">
                    <InputSelect
                      :label="$t('text.type')"
                      v-model.trim="$v.form.type.$model"
                      :options="importersTypes"
                      value-key="id"
                      :vuelidate="$v.form.type"
                      input-class="input-importer-type"
                    />
                    <InputString
                      :label="$t('text.name')"
                      v-model.trim="$v.form.name.$model"
                      :vuelidate="$v.form.name"
                      input-class="input-importer-name"
                    />
                    <InputString
                      :label="$t('text.registrationNumber')"
                      v-model.trim="$v.form.register.$model"
                      :vuelidate="$v.form.register"
                      input-class="input-importer-registration-number"
                      :max-length="10"
                    />
                    <InputString
                      :label="$t('text.phone')"
                      v-model.trim="$v.form.phone.$model"
                      :vuelidate="$v.form.phone"
                      input-class="input-importer-phone"
                      :max-length="12"
                    />
                    <InputString
                      :label="$t('text.email')"
                      v-model.trim="$v.form.email.$model"
                      :vuelidate="$v.form.email"
                      input-class="input-importer-email"
                    />
                    <InputSelect
                      :label="$t('text.bankName')"
                      v-model.number="$v.form.bankId.$model"
                      :options="banks"
                      value-key="id"
                      :vuelidate="$v.form.bankId"
                      input-class="input-importer-bank"
                    />
                    <InputString
                      :label="$t('text.bankAccountName')"
                      v-model.trim="$v.form.accountName.$model"
                      :vuelidate="$v.form.accountName"
                      input-class="input-importer-account-name"
                    />
                    <InputString
                      :label="$t('text.bankAccountNumber')"
                      v-model.trim="$v.form.accountNumber.$model"
                      :vuelidate="$v.form.accountNumber"
                      :max-length="20"
                      input-class="input-importer-account-number"
                    />
                    <InputString
                      :label="$t('text.description')"
                      v-model.trim="$v.form.description.$model"
                      :vuelidate="$v.form.description"
                      is-textarea
                      input-class="input-importer-description"
                    />
                    <FieldWithLabel :label="$t('text.attachImages')">
                      <div class="grid grid-cols-12 gap-6">
                        <div class="intro-y col-span-4 sm:col-span-4 lg:col-span-4 xl:col-span-4">
                          <InputPhoto
                            :photo="form.logo"
                            :vuelidate="$v.form.logo"
                            class="input-importer-logo"
                            @change="setLogo"
                            @remove="onDeleteLogo"
                          />
                        </div>
                      </div>
                    </FieldWithLabel>
                  </div>
                  <div class="intro-y col-span-12 sm:col-span-12 lg:col-span-6 xl:col-span-6">
                    <FieldWithLabel :label="$t('text.introduction')">
                      <div class="grid grid-cols-12 gap-6">
                        <div class="intro-y col-span-6 sm:col-span-6 lg:col-span-6 xl:col-span-6">
                          <InputPhoto
                            :vuelidate="$v.form.photos"
                            class="input-importer-photo-uploader"
                            @change="setFile"
                          />
                        </div>
                        <div
                          v-for="(photo, photoIndex) in form.photos"
                          :key="`photo-${photoIndex}`"
                          :class="[
                            `input-importer-photo-toggler-${photoIndex}`,
                            'intro-y col-span-6 sm:col-span-6 lg:col-span-6 xl:col-span-6',
                          ]"
                        >
                          <InputPhoto :photo="photo" @remove="onDeleteFile(photoIndex)" />
                        </div>
                      </div>
                    </FieldWithLabel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { assign } from 'lodash';
import { mapGetters } from 'vuex';
import { IMPORTERS_ADD, IMPORTERS_SHOW, IMPORTERS_UPDATE } from '@/store/actions/importer';
import { BANKS_LIST_REQUEST } from '@/store/actions/banks';
import { FILE_UPLOAD } from '@/store/actions/file';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import Button from '@/components/ui/button/Button';
import PageHeader from '@/components/ui/PageHeader';
import InputString from '@/components/ui/input/InputString';
import InputSelect from '@/components/ui/input/InputSelect';
import InputPhoto from '@/components/ui/input/InputPhoto';
import FieldWithLabel from '@/components/ui/input/FieldWithLabel';
import { IMPORTERS_TYPES } from '@/utils/constants';

export default {
  mixins: [validationMixin],

  components: {
    Button,
    PageHeader,
    InputString,
    InputSelect,
    InputPhoto,
    FieldWithLabel,
  },

  props: {
    business: Object,
  },

  validations: {
    form: {
      type: {
        required,
      },
      name: {
        required,
      },
      register: {
        required,
      },
      phone: {
        required,
      },
      email: {
        required,
      },
      bankId: {
        required,
      },
      accountName: {
        required,
      },
      accountNumber: {
        required,
      },
      description: {
        required,
      },
      logo: {
        required,
      },
      photos: {
        required,
      },
    },
  },

  data() {
    return {
      form: {
        type: null,
        name: null,
        register: null,
        phone: null,
        email: null,
        bankId: '',
        accountName: '',
        accountNumber: '',
        description: '',
        photos: [],
        logo: null,
      },
      importersTypes: IMPORTERS_TYPES,
      banks: [],
      defaultTableData: {
        filters: {},
        sort: {
          field: 'id',
          type: 'desc',
        },
        page: 1,
        perPage: 100,
      },
    };
  },

  computed: {
    ...mapGetters([
      'importer',
      'importerStatus',
      'banksList',
      'banksMeta',
      'banksStatus',
      'getFileUpload',
    ]),
    isLoading() {
      return this.importerStatus === 'loading';
    },
    title() {
      return this.$route.name === 'ServiceCreate'
        ? this.$t(`nav.importerCreate`)
        : this.$t(`nav.importerUpdate`);
    },
  },

  async created() {
    this.fetchData();
    this.fetchBankList();
  },

  methods: {
    async fetchData() {
      if (this.$route.params.importerId) {
        await this.$store.dispatch(IMPORTERS_SHOW, { id: this.$route.params.importerId });
        if (this.importer) {
          const {
            name,
            type,
            register,
            phone,
            email,
            bankId,
            accountName,
            accountNumber,
            description,
            photos,
            logo,
          } = this.importer;
          this.form = {
            name,
            type,
            register,
            phone,
            email,
            bankId,
            accountName,
            accountNumber,
            description,
            photos: photos.map(photo => photo),
            logo: { ...logo },
          };
        }
      }
    },
    async fetchBankList() {
      const payload = {
        data: this.defaultTableData,
      };

      await this.$store.dispatch(BANKS_LIST_REQUEST, payload);
      if (this.banksStatus === 'success') {
        this.banks = this.banksList.map(item => item);
      }
    },
    async setFile(e) {
      const file = e.target.files[0];
      await this.$store.dispatch(FILE_UPLOAD, file);
      if (this.getFileUpload.status === 'success') {
        this.form.photos.push(this.getFileUpload.item);
      }
    },
    async setLogo(e) {
      const file = e.target.files[0];
      await this.$store.dispatch(FILE_UPLOAD, file);
      if (this.getFileUpload.status === 'success') {
        this.form.logo = this.getFileUpload.item;
      }
    },
    onDeleteFile(index) {
      this.form.photos.splice(index, 1);
    },
    onDeleteLogo() {
      this.form.logo = null;
    },
    async onSubmit() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          this.$toasted.show(this.$t('toast.failedForm'), { type: 'error' });
          return;
        }
      }

      try {
        const payload = {
          data: this.form,
        };

        if (this.$route.params.importerId) {
          assign(payload, { id: this.importer.id });
          await this.$store.dispatch(IMPORTERS_UPDATE, payload);
        } else {
          await this.$store.dispatch(IMPORTERS_ADD, payload);
        }

        if (this.importerStatus === 'success') {
          this.$router.push({ name: 'Importers' });
          this.$toasted.show(this.$t('toast.success'), { type: 'success' });
        }
      } catch (error) {
        this.$toasted.show(error.message, { type: 'error' });
      }
    },
  },
};
</script>
