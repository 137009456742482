<template>
  <TableRegular
    :loading="loading"
    :downloading="downloading"
    :rows="rows"
    :columns="columns"
    :sort="sort"
    :page="page"
    :per-page="perPage"
    :page-size="pageSize"
    :total-page="totalPage"
    :line-numbers="lineNumbers"
    :custom-filters="customFilters"
    @onFilter="$emit('onFilter', $event)"
    @onPageChange="$emit('onPageChange', $event)"
    @onPerPageChange="$emit('onPerPageChange', $event)"
    @onSortChange="$emit('onSortChange', $event)"
    @onDownload="$emit('onDownload')"
    @onChangeCustomFilter="$emit('onChangeCustomFilter', $event)"
  >
    <template slot="table-row" slot-scope="props">
      <p v-if="props.column.field === 'id'" class="font-medium whitespace-nowrap">
        {{ props.row.id ? props.row.id : 0 }}
      </p>
      <p v-else-if="props.column.field === 'createdAt'" class="font-medium whitespace-nowrap">
        {{ props.row.createdAt | momentDate('YYYY-MM-DD HH:mm') }}
      </p>
      <div v-else-if="props.column.field === 'status'">
        <div v-if="props.row.status" :class="['status', `is-${props.row.status.toLowerCase()}`]">
          {{ $t(`status.${props.row.status.toLowerCase()}`) }}
        </div>
      </div>
      <p v-else-if="props.column.field === 'firstName'" class="font-medium whitespace-nowrap">
        <template v-if="props.row.user">
          {{ props.row.user | fullName }}
        </template>
      </p>
      <p v-else-if="props.column.field === 'phone'" class="font-medium whitespace-nowrap">
        <template v-if="props.row.user">
          {{ props.row.user.phone }}
        </template>
      </p>
      <p v-else-if="props.column.field === 'building'" class="font-medium whitespace-nowrap">
        <template v-if="props.row.apartment && props.row.apartment.building">
          <BuildingFullname :item="props.row.apartment.building" />
        </template>
      </p>
      <p v-else-if="props.column.field === 'number'" class="font-medium whitespace-nowrap">
        {{ props.row.apartment.number }}
      </p>
      <p v-else-if="props.column.field === 'action'" class="flex justify-center items-center">
        <a
          v-if="props.row.status == 'PENDING'"
          href="javascript:;"
          @click="$emit('approve', props.row.id)"
          class="flex items-center text-theme-6 mr-3"
        >
          <CheckSquareIcon class="w-4 h-4 mr-1" />
          {{ $t('table.approve') }}
        </a>
        <a
          href="javascript:"
          @click="$emit('delete', props.row.id)"
          class="flex items-center text-theme-6"
        >
          <Trash2Icon class="w-4 h-4 mr-1" />
          {{ $t('table.delete') }}
        </a>
      </p>
    </template>
  </TableRegular>
</template>

<script>
import TableRegular from '@/components/ui/table/TableRegular';
import BuildingFullname from '@/components/building/BuildingFullname';
import tableMixin from '@/mixins/tableMixin';

export default {
  mixins: [tableMixin],
  components: { TableRegular, BuildingFullname },
};
</script>
