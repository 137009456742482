<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <PageHeader class="text-lg font-medium mr-auto" :title="title" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <Button
          type="button"
          :to="{ name: 'SmsTemplates' }"
          class="box text-gray-700 dark:text-gray-300 mr-2 flex"
        >
          {{ $t('text.back') }}
        </Button>
        <Button
          type="button"
          @click="onSubmit"
          :loading="isLoading"
          class="w-24 bg-theme-1 text-white"
        >
          {{ $t('text.save') }}
        </Button>
      </div>
    </div>
    <div class="intro-y mt-5">
      <form @submit.prevent="onSubmit">
        <div class="grid grid-cols-12 gap-6">
          <div class="intro-y col-span-12 sm:col-span-12 lg:col-span-5 xl:col-span-5">
            <div class="intro-y box">
              <div class="py-4 px-5 relative">
                <div class="grid grid-cols-12">
                  <div class="intro-y col-span-12 sm:col-span-12">
                    <InputString
                      v-if="$route.params.id"
                      label="key"
                      :value="form.key"
                      disabled
                      input-class="input-sms-template-key"
                    />
                    <InputString
                      v-else
                      label="key"
                      v-model.trim="$v.form.key.$model"
                      :vuelidate="$v.form.key"
                      input-class="input-sms-template-key"
                    />

                    <InputString
                      :label="$t('text.text')"
                      v-model.trim="$v.form.text.$model"
                      :vuelidate="$v.form.text"
                      is-textarea
                      input-class="input-sms-template-text"
                    />
                    <InputSwitch v-model="form.isActive" input-class="input-sms-template-switch" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SMS_TEMPLATE_REQUEST, SMS_TEMPLATE_UPDATE_REQUEST } from '@/store/actions/smsTemplate';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import Button from '@/components/ui/button/Button';
import PageHeader from '@/components/ui/PageHeader';
import InputString from '@/components/ui/input/InputString';
import InputSwitch from '@/components/ui/input/InputSwitch';

export default {
  mixins: [validationMixin],

  components: {
    Button,
    PageHeader,
    InputString,
    InputSwitch,
  },

  props: {
    business: Object,
  },

  validations() {
    if (this.$route.params.id) {
      return {
        form: {
          text: {
            required,
          },
        },
      };
    }
    return {
      form: {
        key: {
          required,
        },
        text: {
          required,
        },
      },
    };
  },

  data() {
    return {
      form: {
        key: '',
        text: '',
        isActive: true,
      },
    };
  },

  computed: {
    ...mapGetters([
      'smsTemplate',
      'smsTemplateStatus',
      'smsTemplateList',
      'smsTemplateListStatus',
      'smsTemplateListMeta',
    ]),
    isLoading() {
      return this.smsTemplateListStatus === 'loading';
    },
    title() {
      return this.$t(`nav.smsTemplateUpdate`);
    },
  },

  async created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      if (this.$route.params.id) {
        const payload = { id: this.$route.params.id };
        await this.$store.dispatch(SMS_TEMPLATE_REQUEST, payload);
        if (this.smsTemplate && this.smsTemplateStatus === 'success') {
          const { key, text, isActive } = this.smsTemplate;
          this.form = {
            key,
            text,
            isActive,
          };
        }
      }
    },
    async onSubmit() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          this.$toasted.show(this.$t('toast.failedForm'), { type: 'error' });
          return;
        }
      }

      try {
        const payload = {
          id: this.smsTemplate.id,
          data: this.form,
        };
        await this.$store.dispatch(SMS_TEMPLATE_UPDATE_REQUEST, payload);
        if (this.smsTemplateStatus === 'success') {
          this.$router.push({ name: 'SmsTemplates' });
          this.$toasted.show(this.$t('toast.success'), { type: 'success' });
        }
      } catch (error) {
        this.$toasted.show(error.message, { type: 'error' });
      }
    },
  },
};
</script>
