<template>
  <div class="field">
    <label v-if="label" class="flex flex-col sm:flex-row">
      {{ label }}
      <span v-if="helpText" class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600">
        {{ helpText }}
      </span>
    </label>

    <div class="relative">
      <label
        :class="['input-image', inputClass, { 'border-theme-6': hasError }, { 'mt-2': label }]"
        :style="`background-image: url(${image});`"
      >
        <input
          ref="fileupload"
          class="hidden"
          type="file"
          name="image"
          :accept="acceptFiles"
          @change="onFileChange"
          :disabled="disabled"
        />
      </label>
      <div
        v-if="image"
        @click="removeImg(null)"
        class="w-5 h-5 cursor-pointer flex items-center justify-center absolute rounded-full text-white bg-theme-6 right-0 bottom-0 transform translate-x-1/2 translate-y-1/2"
      >
        <xIcon class="w-4 h-4" />
      </div>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex';
import baseInputMixin from '@/mixins/baseInputMixin';
// import { FILE_UPLOAD } from '@/store/actions/file';
// import { isNil } from 'lodash';

export default {
  mixins: [baseInputMixin],

  props: {
    value: String,
    acceptFiles: {
      type: String,
      default: 'image/*',
    },
  },

  data() {
    return {
      image: this.value,
    };
  },

  computed: {
    // ...mapGetters(['getFileUpload']),
  },

  methods: {
    async onFileChange(e) {
      const file = e.target.files[0];

      if (file) {
        if (file.size > 1024 * 1024 * 5) {
          e.preventDefault();
          this.$toasted.show(this.$t('toast.fileIsTooLarge'), { type: 'error' });
          return;
        }

        // await this.$store.dispatch(FILE_UPLOAD, file);
        this.image = URL.createObjectURL(file);
        // this.onChange(this.getFileUpload.item);
        this.$emit('upload', file);
      }
    },
    // onChange(value) {
    //   this.$emit('change', value);
    //   if (isNil(value)) {
    //     this.image = '';
    //     const input = this.$refs.fileupload;
    //     input.type = 'text';
    //     input.type = 'file';
    //   }
    // },
    removeImg() {
      this.image = '';
    },
  },
};
</script>
