import Client from '@/clients/apiClient';
import humps from 'humps';
import { stringify } from 'query-string';

const resourceBase = 'business';
const resource = 'tickets';

export default {
  get(businessId, payload) {
    return Client.get(
      `${resourceBase}/${businessId}/${resource}?page=${payload.page}&limit=${
        payload.perPage
      }&sort[field]=${humps.decamelize(payload.sort.field)}&sort[type]=${
        payload.sort.type
      }&${stringify(humps.decamelizeKeys(payload.filters))}`
    );
  },
  getAssigned(businessId, payload) {
    return Client.get(
      `${resourceBase}/${businessId}/${resource}?page=${payload.page}&limit=${
        payload.perPage
      }&sort[field]=${humps.decamelize(payload.sort.field)}&sort[type]=${
        payload.sort.type
      }&filter[status]=assigned`
    );
  },
  getConfirmed(businessId, payload) {
    return Client.get(
      `${resourceBase}/${businessId}/${resource}?page=${payload.page}&limit=${
        payload.perPage
      }&sort[field]=${humps.decamelize(payload.sort.field)}&sort[type]=${
        payload.sort.type
      }&filter[status]=confirmed`
    );
  },
  getFinished(businessId, payload) {
    return Client.get(
      `${resourceBase}/${businessId}/${resource}?page=${payload.page}&limit=${
        payload.perPage
      }&sort[field]=${humps.decamelize(payload.sort.field)}&sort[type]=${
        payload.sort.type
      }&filter[status]=finished`
    );
  },
  show(businessId, id) {
    return Client.get(`${resourceBase}/${businessId}/${resource}/${id}`);
  },
  create(businessId, payload) {
    return Client.post(`${resourceBase}/${businessId}/${resource}`, payload);
  },
  update(businessId, id, payload) {
    return Client.put(`${resourceBase}/${businessId}/${resource}/${id}`, payload);
  },
  delete(businessId, id) {
    return Client.delete(`${resourceBase}/${businessId}/${resource}/${id}`);
  },
  categories() {
    return Client.get('ticket-categories');
  },
  types() {
    return Client.get('ticket-types');
  },
  statusChange(businessId, id, payload) {
    return Client.post(
      `${resourceBase}/${businessId}/${resource}/${id}/request-status-change`,
      payload
    );
  },
};
