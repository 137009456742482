<template>
  <div class="report-box zoom-in">
    <div class="box p-5">
      <div class="text-sm text-center text-gray-600 mt-1">
        {{ data.name }}
      </div>
      <div
        @click="selectDay(data)"
        class="intro-x flex items-center justify-center pl-5 pt-4 router-link-active"
      >
        <img v-if="data.selected" src="@/assets/images/sunbed.svg" class="w-10" />
        <img v-else src="@/assets/images/trade.svg" class="w-10" />
      </div>
      <div v-if="data.selected" class="inline-flex items-center justify-center mt-6">
        <input type="time" v-model="data.start" class="input-service-start" />
        <input type="time" v-model="data.end" class="input-service-end" />
      </div>
      <div v-else class="text-xl text-center leading-8 mt-6">{{ $t('text.closed') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {},
    label: String,
  },
  methods: {
    selectDay(item) {
      item.selected = !item.selected;
    },
  },
};
</script>
