export const TICKET_CATEGORY = 'TICKET_CATEGORY';
export const TICKET_CATEGORY_SUCCESS = 'TICKET_CATEGORY_SUCCESS';
export const TICKET_CATEGORY_ERROR = 'TICKET_CATEGORY_ERROR';

export const TICKET_CATEGORY_ADD = 'TICKET_CATEGORY_ADD';
export const TICKET_CATEGORY_ADD_SUCCESS = 'TICKET_CATEGORY_ADD_SUCCESS';
export const TICKET_CATEGORY_ADD_ERROR = 'TICKET_CATEGORY_ADD_ERROR';

export const TICKET_CATEGORY_UPDATE = 'TICKET_CATEGORY_UPDATE';
export const TICKET_CATEGORY_UPDATE_SUCCESS = 'TICKET_CATEGORY_UPDATE_SUCCESS';
export const TICKET_CATEGORY_UPDATE_ERROR = 'TICKET_CATEGORY_UPDATE_ERROR';

export const TICKET_CATEGORY_REMOVE = 'TICKET_CATEGORY_REMOVE';
export const TICKET_CATEGORY_REMOVE_SUCCESS = 'TICKET_CATEGORY_REMOVE_SUCCESS';
export const TICKET_CATEGORY_REMOVE_ERROR = 'TICKET_CATEGORY_REMOVE_ERROR';

export const TICKET_CATEGORY_LIST_REQUEST = 'TICKET_CATEGORY_LIST_REQUEST';
export const TICKET_CATEGORY_LIST_REQUEST_SUCCESS = 'TICKET_CATEGORY_LIST_REQUEST_SUCCESS';
export const TICKET_CATEGORY_LIST_REQUEST_ERROR = 'TICKET_CATEGORY_LIST_REQUEST_ERR';
