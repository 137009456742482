<template>
  <div class="meeting-accordion text-theme-10">
    <ul>
      <li v-for="(item, index) in items" :key="index" @click="openItem(item)">
        <div class="meeting-arrow-box">
          <div class="arrow_box" :class="{ 'arrow_box--open': item.id }"></div>
          {{ item.name }}-р байр
        </div>
        <transition v-on:enter="enter" v-on:leave="leave">
          <div v-show="item.id" class="item">
            <div v-for="(user, idx) in item.users" :key="idx" class="flex border-b border-gray-200">
              <div class="w-1/6">
                <div class="meeting-user-avatar">
                  <p>X</p>
                </div>
              </div>
              <div class="w-5/6">
                <div class="flex">
                  <div class="w-2/3 mt-3 mb-3 text-xs">
                    <h4 class="text-md font-bold">
                      {{ user.first_name }} {{ user.last_name }} /{{ user.phone }}/
                      {{ item.isopen }}
                    </h4>
                    <span v-if="user.apartments.length === 1">
                      {{ user.apartments[0].number }} тоот
                    </span>
                    <template v-else>
                      <span :key="apartment.id" v-for="apartment in user.apartments">
                        {{ apartment.building.name }}-{{ apartment.number }} тоот
                      </span>
                    </template>
                  </div>
                  <div class="w-1/3">
                    <div class="meeting-setting">
                      <Button
                        class="button px-1 py-1 mr-1 inline-flex items-center justify-center w-30 bg-theme-14 text-green-300 text-xs-new"
                      >
                        <ThumbsUpIcon />
                      </Button>
                      <Button
                        class="button px-1 py-1 inline-flex items-center justify-center w-30 bg-theme-14 text-green-300 mr-1 text-xs-new"
                      >
                        <MicIcon />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </li>
    </ul>
  </div>
</template>

<script>
import Velocity from 'velocity-animate';

export default {
  props: {
    items: Array,
  },
  components: {},
  computed: {},
  data() {
    return {
      isopen: {
        isopen: false,
      },
    };
  },
  updated() {},
  created() {},
  methods: {
    openItem(item) {
      item.id = !item.id;
      this.isopen = !this.isopen;
    },

    setClass(item) {
      if (item.id) {
        return 'open';
      }
      return 'close';
    },

    enter(el, done) {
      Velocity(
        el,
        'slideDown',
        {
          duration: 400,
          easing: 'easeInBack',
        },
        {
          complete: done,
        }
      );
    },

    leave(el, done) {
      Velocity(
        el,
        'slideUp',
        {
          duration: 400,
          easing: 'easeInBack',
        },
        {
          complete: done,
        }
      );
    },
  },
};
</script>
<style>
.meeting-accordion ul li {
  background-color: #e6f3ff;
  padding: 5px 0 5px 0;
  margin-bottom: 2px;
  position: relative;
}
.meeting-arrow-box {
  padding-left: 35px;
}
.item {
  background-color: #fff;
}
.arrow_box {
  width: 10px;
  height: 10px;
  transition: all.3s;
  padding-bottom: 0px;
  position: absolute;
  margin: 4px 0px 0px -15px;
}
.arrow_box:after,
.arrow_box:before {
  border: solid transparent;
  content: '';
  position: absolute;
}

.arrow_box:after {
  border-width: 5px;
}
.arrow_box:before {
  border-left-color: #1c3faa;
  border-width: 5px;
}
.arrow_box--open {
  transform: rotateZ(90deg);
  transform-origin: 50% 50%;
}
.meeting-user-avatar {
  position: relative;
  width: 70%;
  height: auto;
  margin: 15%;
  background: #ccc;
  border-radius: 50%;
  align-items: center;
}
.meeting-user-avatar p {
  font-size: 1.5rem;
  text-align: center;
  padding: 10px 0;
  color: #fff;
}
.meeting-setting {
  margin: 11px 9px;
}
</style>
