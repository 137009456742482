import businessapproveIndex from '@/views/admin/Index';
import subTabs from '@/views/admin/subTabs';
import ticketType from '@/views/TicketType/TicketType';
import TicketTypeForm from '@/views/TicketType/TicketTypeForm';
import ticketCategory from '@/views/TicketCategory/TicketCategory';
import ticketCategoryForm from '@/views/TicketCategory/TicketCategoryForm';
import ExpenseTypeForm from '@/views/ExpenseType/ExpenseTypeForm';
import ExpenseType from '@/views/ExpenseType/ExpenseType';

export default [
  {
    path: '/reference',
    component: businessapproveIndex,
    meta: { group: 'reference' },
    children: [
      {
        path: '',
        component: subTabs,
        children: [
          { path: '', name: 'ticketType', component: ticketType },
          { path: 'ticket-category', name: 'ticketCategory', component: ticketCategory },
          { path: 'expense-type', name: 'expenseType', component: ExpenseType },
        ],
      },
      { path: 'ticket-type-create', name: 'TicketTypeCreate', component: TicketTypeForm },
      {
        path: 'ticket-type-update/:ticketTypeId',
        name: 'TicketTypeUpdate',
        component: TicketTypeForm,
      },
      {
        path: 'ticket-category-create',
        name: 'TicketCategoryCreate',
        component: ticketCategoryForm,
      },
      {
        path: 'ticket-category-update/:ticketCategoryId',
        name: 'TicketCategoryUpdate',
        component: ticketCategoryForm,
      },
      {
        path: 'expense-type-update/:expenseTypeId',
        name: 'expenseTypeUpdate',
        component: ExpenseTypeForm,
      },
      {
        path: 'expense-type-create',
        name: 'expenseTypeCreate',
        component: ExpenseTypeForm,
      },
    ],
  },
];
