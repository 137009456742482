<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-5">
      <PageHeader class="text-lg font-medium mr-auto" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <router-link
          v-if="checkPermission('registration.owner.create')"
          :to="{ name: 'OwnerCreate' }"
          class="button text-white bg-theme-1 shadow-md mr-2"
        >
          {{ $t('text.create') }}
        </router-link>
        <DropDownButtonList
          v-if="checkPermission('registration.apartment.upload')"
          :id="dropdownId"
          class="ml-auto sm:ml-0"
          toggler-class="button px-2 box text-gray-700 dark:text-gray-300"
          box-class="w-40"
          content-class="dark:bg-dark-1"
        >
          <!-- <a @click="onUpload"><UploadIcon /> {{ $t('file.excel') }}</a> -->
          <router-link
            :to="{
              name: 'BusinessSetup',
              query: { type: 'OWNER', building_id: $route.params.buildingId },
            }"
          >
            <UploadIcon /> {{ $t('file.excel') }}
          </router-link>
        </DropDownButtonList>
      </div>
    </div>
    <div class="intro-y mt-5 overflow-auto lg:overflow-visible">
      <OwnerTable
        :loading="isLoading"
        :rows="ownerList"
        :columns="columns"
        :line-numbers="true"
        :sort="sort"
        :page="page"
        :per-page="perPage"
        :page-size="pageSize"
        :total-page="totalPage"
        @delete="confirmRemoval"
        @onFilter="onFilter"
        @onPageChange="onPageChange"
        @onPerPageChange="onPerPageChange"
        @onSortChange="onSortChange"
      />
    </div>

    <BuildingApartmentAndOwnerUploadModal
      :ref="uploadRefName"
      :title="$t('nav.ownerUpload')"
      :business="business"
      :modal-id="uploadModalId"
      @onImportSuccess="fetchData"
    />

    <ConfirmDialog :modal-id="deleteModalId" @ok="onDelete" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { OWNER_LIST_REQUEST, OWNER_REMOVE } from '@/store/actions/owner';
import PageHeader from '@/components/ui/PageHeader';
import OwnerTable from '@/components/owner/OwnerTable';
import BuildingApartmentAndOwnerUploadModal from '@/components/building/BuildingApartmentAndOwnerUploadModal';
import dataListMixin from '@/mixins/dataListMixin';

export default {
  mixins: [dataListMixin],

  components: {
    PageHeader,
    OwnerTable,
    BuildingApartmentAndOwnerUploadModal,
  },

  data() {
    return {
      storeActionListRequest: OWNER_LIST_REQUEST,
      storeActionRemove: OWNER_REMOVE,
      uploadModalId: 'upload-modal-owners',
      uploadRefName: 'uploadModalOwners',
      dropdownId: 'dropdown-owner',
      columns: [
        {
          label: this.$t('table.firstName'),
          field: 'firstName',
          sortable: true,
          filterable: true,
        },
        {
          label: this.$t('table.phone'),
          field: 'phone',
          sortable: true,
          filterable: true,
        },
        {
          label: this.$t('table.registrationNumber'),
          field: 'registrationNumber',
          sortable: true,
          filterable: true,
        },
        {
          label: this.$t('table.email'),
          field: 'email',
          sortable: true,
          filterable: true,
        },
        {
          label: this.$t('table.familySize'),
          field: 'familySize',
          sortable: true,
          filterable: true,
        },
        {
          label: this.$t('table.apartAndPark'),
          field: 'apartments',
        },
        {
          label: this.$t('table.action'),
          field: 'action',
        },
      ],
      sort: {
        field: 'firstName',
        type: 'asc',
      },
    };
  },

  computed: {
    ...mapGetters(['ownerList', 'ownerMeta', 'ownerStatus']),
    isLoading() {
      return this.ownerStatus === 'loading';
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    setFilterValues(data) {
      data.filters['filter[buildingId]'] = this.$route.params.buildingId;
    },

    setPaginationValues() {
      this.totalRecords = this.ownerMeta ? this.ownerMeta.total : 1;
      this.page = this.ownerMeta.currentPage;
      this.totalPage = this.ownerMeta.lastPage;
    },
  },
};
</script>
