import {
  BUSINESS_LIST_REQUEST,
  BUSINESS_LIST_REQUEST_ERROR,
  BUSINESS_LIST_REQUEST_SUCCESS,
  BUSINESS_UPDATE,
  BUSINESS_UPDATE_ERROR,
  BUSINESS_UPDATE_SUCCESS,
  BUSINESS_BANK_CREATE,
  BUSINESS_BANK_CREATE_SUCCESS,
  BUSINESS_BANK_CREATE_ERROR,
  BUSINESS_BANK_UPDATE,
  BUSINESS_BANK_UPDATE_SUCCESS,
  BUSINESS_BANK_UPDATE_ERROR,
  BUSINESS_BANK_LIST,
  BUSINESS_BANK_LIST_SUCCESS,
  BUSINESS_BANK_LIST_ERROR,
  BUSINESS_EXCEL_IMPORT,
  BUSINESS_EXCEL_IMPORT_SUCCESS,
  BUSINESS_EXCEL_IMPORT_ERROR,
  BUSINESS_SEND_MAIL_TO_STATE_BANK,
  BUSINESS_SEND_MAIL_TO_STATE_BANK_SUCCESS,
  BUSINESS_SEND_MAIL_TO_STATE_BANK_ERROR,
  BUSINESS_SEND_INVOICE_TO_STATE_BANK,
  BUSINESS_SEND_INVOICE_TO_STATE_BANK_SUCCESS,
  BUSINESS_SEND_INVOICE_TO_STATE_BANK_ERROR,
} from '@/store/actions/business';
import { handleRequestError } from '@/utils/error';

import Repository, { BUSINESSES } from '@/repositories/RepositoryFactory';
// import { findIndex } from 'lodash';

const BusinessesRepository = Repository.get(BUSINESSES);

const state = {
  businessList: [],
  businessMeta: '',
  businessStatus: '',
  business: '',
  bankList: [],
  bankStatus: '',
  bank: '',
  businessExcelImportErrors: [],
  businessExcelImportFailures: [],
  businessExcelImportSuccessRowCount: 0,
  businessSendMailToStateBankStatus: '',
  businessSendInvoiceToStateBankStatus: '',
};

const getters = {
  business: state => state.business,
  businessStatus: state => state.businessStatus,
  businessList: state => state.businessList,
  businessMeta: state => state.businessMeta,
  bankList: state => state.bankList,
  bankStatus: state => state.bankStatus,
  bank: state => state.bank,
  businessExcelImportErrors: state => state.businessExcelImportErrors,
  businessExcelImportFailures: state => state.businessExcelImportFailures,
  businessExcelImportSuccessRowCount: state => state.businessExcelImportSuccessRowCount,
  businessSendMailToStateBankStatus: state => state.businessSendMailToStateBankStatus,
  businessSendInvoiceToStateBankStatus: state => state.businessSendInvoiceToStateBankStatus,
};

const actions = {
  [BUSINESS_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(BUSINESS_LIST_REQUEST);
    try {
      const response = await BusinessesRepository.get(payload.data);
      commit(BUSINESS_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(BUSINESS_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [BUSINESS_BANK_LIST]: async ({ commit }) => {
    commit(BUSINESS_BANK_LIST);
    try {
      const response = await BusinessesRepository.getBank();
      commit(BUSINESS_BANK_LIST_SUCCESS, response.data);
    } catch (error) {
      commit(BUSINESS_BANK_LIST_ERROR);
      handleRequestError(error);
    }
  },
  [BUSINESS_UPDATE]: async ({ commit }, payload) => {
    commit(BUSINESS_UPDATE);
    try {
      const response = await BusinessesRepository.update(payload.id, payload.data);
      commit(BUSINESS_UPDATE_SUCCESS, response.data);
    } catch (error) {
      commit(BUSINESS_UPDATE_ERROR);
      handleRequestError(error);
    }
  },
  [BUSINESS_BANK_CREATE]: async ({ commit }, payload) => {
    commit(BUSINESS_BANK_CREATE);
    try {
      const response = await BusinessesRepository.bankCreate(payload.businessId, payload.data);
      commit(BUSINESS_BANK_CREATE_SUCCESS, response.data);
    } catch (error) {
      commit(BUSINESS_BANK_CREATE_ERROR);
      handleRequestError(error);
    }
  },
  [BUSINESS_BANK_UPDATE]: async ({ commit }, payload) => {
    commit(BUSINESS_BANK_UPDATE);
    try {
      const response = await BusinessesRepository.bankUpdate(
        payload.businessId,
        payload.id,
        payload.data
      );
      commit(BUSINESS_BANK_UPDATE_SUCCESS, response.data);
    } catch (error) {
      commit(BUSINESS_BANK_UPDATE_ERROR);
      handleRequestError(error);
    }
  },
  [BUSINESS_EXCEL_IMPORT]: async ({ commit }, payload) => {
    commit(BUSINESS_EXCEL_IMPORT);
    try {
      const response = await BusinessesRepository.excelImport(payload.businessId, payload.data);
      commit(BUSINESS_EXCEL_IMPORT_SUCCESS, response.data.data);
    } catch (error) {
      commit(BUSINESS_EXCEL_IMPORT_ERROR);
      handleRequestError(error);
    }
  },
  [BUSINESS_SEND_MAIL_TO_STATE_BANK]: async ({ commit }, payload) => {
    commit(BUSINESS_SEND_MAIL_TO_STATE_BANK);
    try {
      await BusinessesRepository.sendMailToStateBank(payload.businessId, payload.data);
      commit(BUSINESS_SEND_MAIL_TO_STATE_BANK_SUCCESS);
    } catch (error) {
      commit(BUSINESS_SEND_MAIL_TO_STATE_BANK_ERROR);
      handleRequestError(error);
    }
  },
  [BUSINESS_SEND_INVOICE_TO_STATE_BANK]: async ({ commit }, payload) => {
    commit(BUSINESS_SEND_INVOICE_TO_STATE_BANK);
    try {
      await BusinessesRepository.sendInvoiceToStateBank(payload.businessId, payload.data);
      commit(BUSINESS_SEND_INVOICE_TO_STATE_BANK_SUCCESS);
    } catch (error) {
      commit(BUSINESS_SEND_INVOICE_TO_STATE_BANK_ERROR);
      handleRequestError(error);
    }
  },
};

const mutations = {
  [BUSINESS_LIST_REQUEST]: state => {
    state.businessStatus = 'loading';
  },
  [BUSINESS_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.businessStatus = 'success';
    state.businessList = payload.data;
    state.businessMeta = payload.meta;
  },
  [BUSINESS_LIST_REQUEST_ERROR]: state => {
    state.businessStatus = 'error';
  },
  [BUSINESS_BANK_LIST]: state => {
    state.bankStatus = 'loading';
  },
  [BUSINESS_BANK_LIST_SUCCESS]: (state, payload) => {
    state.bankStatus = 'success';
    state.bankList = payload.data;
  },
  [BUSINESS_BANK_LIST_ERROR]: state => {
    state.bankStatus = 'error';
  },
  [BUSINESS_UPDATE]: state => {
    state.businessStatus = 'loading';
  },
  [BUSINESS_UPDATE_SUCCESS]: state => {
    state.businessStatus = 'success';
    // const index = findIndex(state.businessList, { id: payload.id });
    // state.businessList = payload;
  },
  [BUSINESS_UPDATE_ERROR]: state => {
    state.businessStatus = 'error';
  },
  [BUSINESS_BANK_CREATE]: state => {
    state.businessStatus = 'loading';
  },
  [BUSINESS_BANK_CREATE_SUCCESS]: (state, payload) => {
    state.businessStatus = 'success';
    state.businessList = payload;
  },
  [BUSINESS_BANK_CREATE_ERROR]: state => {
    state.businessStatus = 'error';
  },
  [BUSINESS_BANK_UPDATE]: state => {
    state.businessStatus = 'loading';
  },
  [BUSINESS_BANK_UPDATE_SUCCESS]: state => {
    state.businessStatus = 'success';
    // const index = findIndex(state.businessList, { id: payload.id });
    // state.businessList = payload;
  },
  [BUSINESS_BANK_UPDATE_ERROR]: state => {
    state.businessStatus = 'error';
  },
  [BUSINESS_EXCEL_IMPORT]: state => {
    state.businessStatus = 'loading';
  },
  [BUSINESS_EXCEL_IMPORT_SUCCESS]: (state, payload) => {
    if (payload.errors && payload.errors.length > 0) {
      state.businessStatus = 'error';
    } else {
      state.businessStatus = 'success';
    }
    state.businessExcelImportErrors = payload.errors;
    state.businessExcelImportFailures = payload.failures;
    state.businessExcelImportSuccessRowCount = payload.successRowCount;
  },
  [BUSINESS_EXCEL_IMPORT_ERROR]: state => {
    state.businessStatus = 'error';
  },
  [BUSINESS_SEND_MAIL_TO_STATE_BANK]: state => {
    state.businessSendMailToStateBankStatus = 'loading';
  },
  [BUSINESS_SEND_MAIL_TO_STATE_BANK_SUCCESS]: state => {
    state.businessSendMailToStateBankStatus = 'success';
  },
  [BUSINESS_SEND_MAIL_TO_STATE_BANK_ERROR]: state => {
    state.businessSendMailToStateBankStatus = 'error';
  },
  [BUSINESS_SEND_INVOICE_TO_STATE_BANK]: state => {
    state.businessSendInvoiceToStateBankStatus = 'loading';
  },
  [BUSINESS_SEND_INVOICE_TO_STATE_BANK_SUCCESS]: state => {
    state.businessSendInvoiceToStateBankStatus = 'success';
  },
  [BUSINESS_SEND_INVOICE_TO_STATE_BANK_ERROR]: state => {
    state.businessSendInvoiceToStateBankStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
