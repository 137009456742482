import Client from '@/clients/apiClient';
import humps from 'humps';
import { stringify } from 'query-string';

const resourceBase = 'business';
const resource = 'reports';

export default {
  getReceivables(businessId, payload) {
    return Client.get(
      `${resourceBase}/${businessId}/${resource}/receivable?page=${payload.page}&limit=${
        payload.perPage
      }&${stringify(humps.decamelizeKeys(payload.filters))}`
    );
  },
  exportReceivables(businessId, payload) {
    return Client.post(
      `${resourceBase}/${businessId}/${resource}/receivable/export?${stringify(
        humps.decamelizeKeys(payload.filters)
      )}`
    );
  },
  sendReceivablesSms(businessId, payload) {
    return Client.post(`${resourceBase}/${businessId}/invoices/send-sms-invoice`, payload);
  },
};
