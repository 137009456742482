import { findIndex } from 'lodash';
import {
  TICKET_LIST_REQUEST,
  TICKET_LIST_REQUEST_ASSIGNED,
  TICKET_LIST_REQUEST_CONFIRMED,
  TICKET_LIST_REQUEST_FINISHED,
  TICKET_LIST_REQUEST_ERROR,
  TICKET_LIST_REQUEST_SUCCESS,
  TICKET_REQUEST,
  TICKET_REQUEST_ERROR,
  TICKET_REQUEST_SUCCESS,
  TICKET_ADD,
  TICKET_ADD_ERROR,
  TICKET_ADD_SUCCESS,
  TICKET_UPDATE,
  TICKET_UPDATE_ERROR,
  TICKET_UPDATE_SUCCESS,
  TICKET_REMOVE,
  TICKET_REMOVE_ERROR,
  TICKET_REMOVE_SUCCESS,
  TICKET_CATEGORIES_REQUEST,
  TICKET_CATEGORIES_REQUEST_ERROR,
  TICKET_CATEGORIES_REQUEST_SUCCESS,
  TICKET_TYPES_REQUEST,
  TICKET_TYPES_REQUEST_ERROR,
  TICKET_TYPES_REQUEST_SUCCESS,
  TICKET_STATUS_CHANGE,
  TICKET_STATUS_CHANGE_SUCCESS,
  TICKET_STATUS_CHANGE_ERROR,
} from '@/store/actions/ticket';
import { handleRequestError } from '@/utils/error';

import Repository, { TICKETS } from '@/repositories/RepositoryFactory';

const TicketsRepository = Repository.get(TICKETS);

const state = {
  ticket: {},
  ticketStatus: '',
  ticketList: [],
  ticketMeta: '',
  ticketListStatus: '',
  ticketLastCreated: '',
  ticketCategories: [],
  ticketCategoriesStatus: '',
  ticketTypes: [],
  ticketTypesStatus: '',
  ticketChangingStatus: '',
};

const getters = {
  ticket: state => state.ticket,
  ticketStatus: state => state.ticketStatus,
  ticketList: state => state.ticketList,
  ticketMeta: state => state.ticketMeta,
  ticketListStatus: state => state.ticketListStatus,
  ticketLastCreated: state => state.ticketLastCreated,
  ticketCategories: state => state.ticketCategories,
  ticketCategoriesStatus: state => state.ticketCategoriesStatus,
  ticketTypes: state => state.ticketTypes,
  ticketTypesStatus: state => state.ticketTypesStatus,
  ticketChangingStatus: state => state.ticketChangingStatus,
};

const actions = {
  [TICKET_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(TICKET_LIST_REQUEST);
    try {
      const response = await TicketsRepository.get(payload.businessId, payload.data);
      commit(TICKET_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(TICKET_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },

  [TICKET_LIST_REQUEST_ASSIGNED]: async ({ commit }, payload) => {
    commit(TICKET_LIST_REQUEST_ASSIGNED);
    try {
      const response = await TicketsRepository.getAssigned(payload.businessId, payload.data);
      commit(TICKET_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(TICKET_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },

  [TICKET_LIST_REQUEST_CONFIRMED]: async ({ commit }, payload) => {
    commit(TICKET_LIST_REQUEST_CONFIRMED);
    try {
      const response = await TicketsRepository.getConfirmed(payload.businessId, payload.data);
      commit(TICKET_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(TICKET_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },

  [TICKET_LIST_REQUEST_FINISHED]: async ({ commit }, payload) => {
    commit(TICKET_LIST_REQUEST_FINISHED);
    try {
      const response = await TicketsRepository.getFinished(payload.businessId, payload.data);
      commit(TICKET_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(TICKET_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },

  [TICKET_REQUEST]: async ({ commit }, payload) => {
    commit(TICKET_REQUEST);
    try {
      const response = await TicketsRepository.show(payload.businessId, payload.id);
      const { data } = response;
      commit(TICKET_REQUEST_SUCCESS, data);
    } catch (error) {
      commit(TICKET_REQUEST_ERROR);
      handleRequestError(error);
    }
  },

  [TICKET_ADD]: async ({ commit }, payload) => {
    commit(TICKET_ADD);
    try {
      const response = await TicketsRepository.create(payload.businessId, payload.data);
      commit(TICKET_ADD_SUCCESS, response.data.data);
    } catch (error) {
      commit(TICKET_ADD_ERROR);
      handleRequestError(error);
    }
  },

  [TICKET_UPDATE]: async ({ commit }, payload) => {
    commit(TICKET_UPDATE);
    try {
      const response = await TicketsRepository.update(payload.businessId, payload.id, payload.data);
      commit(TICKET_UPDATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(TICKET_UPDATE_ERROR);
      handleRequestError(error);
    }
  },

  [TICKET_REMOVE]: async ({ commit }, payload) => {
    commit(TICKET_REMOVE);
    try {
      await TicketsRepository.delete(payload.businessId, payload.id);
      commit(TICKET_REMOVE_SUCCESS, payload.id);
    } catch (error) {
      commit(TICKET_REMOVE_ERROR);
      handleRequestError(error);
    }
  },

  [TICKET_CATEGORIES_REQUEST]: async ({ commit }) => {
    commit(TICKET_CATEGORIES_REQUEST);
    try {
      const response = await TicketsRepository.categories();
      commit(TICKET_CATEGORIES_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(TICKET_CATEGORIES_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [TICKET_TYPES_REQUEST]: async ({ commit }) => {
    commit(TICKET_TYPES_REQUEST);
    try {
      const response = await TicketsRepository.types();
      commit(TICKET_TYPES_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(TICKET_TYPES_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [TICKET_STATUS_CHANGE]: async ({ commit }, payload) => {
    commit(TICKET_STATUS_CHANGE);
    try {
      await TicketsRepository.statusChange(payload.businessId, payload.id, payload.data);
      commit(TICKET_STATUS_CHANGE_SUCCESS);
    } catch (error) {
      commit(TICKET_STATUS_CHANGE_ERROR);
      handleRequestError(error);
    }
  },
};

const mutations = {
  [TICKET_LIST_REQUEST]: state => {
    state.ticketListStatus = 'loading';
  },
  [TICKET_LIST_REQUEST_ASSIGNED]: state => {
    state.ticketListStatus = 'loading';
  },
  [TICKET_LIST_REQUEST_CONFIRMED]: state => {
    state.ticketListStatus = 'loading';
  },
  [TICKET_LIST_REQUEST_FINISHED]: state => {
    state.ticketListStatus = 'loading';
  },
  [TICKET_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.ticketListStatus = 'success';
    state.ticketList = payload.data;
    state.ticketMeta = payload.meta;
  },
  [TICKET_LIST_REQUEST_ERROR]: state => {
    state.ticketListStatus = 'error';
  },

  [TICKET_REQUEST]: state => {
    state.ticketStatus = 'loading';
  },
  [TICKET_REQUEST_SUCCESS]: (state, payload) => {
    state.ticketStatus = 'success';
    state.ticket = payload.data;
  },
  [TICKET_REQUEST_ERROR]: state => {
    state.ticketStatus = 'error';
  },

  [TICKET_ADD]: state => {
    state.ticketStatus = 'loading';
    state.ticketLastCreated = '';
  },
  [TICKET_ADD_SUCCESS]: (state, payload) => {
    state.ticketStatus = 'success';
    state.ticketLastCreated = payload;
    state.ticketList.push(payload);
  },
  [TICKET_ADD_ERROR]: state => {
    state.ticketStatus = 'error';
  },

  [TICKET_UPDATE]: state => {
    state.ticketStatus = 'loading';
  },
  [TICKET_UPDATE_SUCCESS]: (state, payload) => {
    state.ticketStatus = 'success';
    state.ticketList = payload;
  },
  [TICKET_UPDATE_ERROR]: state => {
    state.ticketStatus = 'error';
  },

  [TICKET_REMOVE]: state => {
    state.ticketStatus = 'deleting';
  },
  [TICKET_REMOVE_SUCCESS]: (state, payload) => {
    const index = findIndex(state.ticketList, { id: payload });
    state.ticketList.splice(index, 1);
  },
  [TICKET_REMOVE_ERROR]: state => {
    state.ticketStatus = 'error';
  },

  [TICKET_CATEGORIES_REQUEST]: state => {
    state.ticketCategoriesStatus = 'loading';
  },
  [TICKET_CATEGORIES_REQUEST_SUCCESS]: (state, payload) => {
    state.ticketCategoriesStatus = 'success';
    state.ticketCategories = payload.data;
  },
  [TICKET_CATEGORIES_REQUEST_ERROR]: state => {
    state.ticketCategoriesStatus = 'error';
  },

  [TICKET_TYPES_REQUEST]: state => {
    state.ticketTypesStatus = 'loading';
  },
  [TICKET_TYPES_REQUEST_SUCCESS]: (state, payload) => {
    state.ticketTypesStatus = 'success';
    state.ticketTypes = payload.data;
  },
  [TICKET_TYPES_REQUEST_ERROR]: state => {
    state.ticketTypesStatus = 'error';
  },

  [TICKET_STATUS_CHANGE]: state => {
    state.ticketChangingStatus = 'loading';
  },
  [TICKET_STATUS_CHANGE_SUCCESS]: state => {
    state.ticketChangingStatus = 'success';
  },
  [TICKET_STATUS_CHANGE_ERROR]: state => {
    state.ticketChangingStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
