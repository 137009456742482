import { findIndex } from 'lodash';
import {
  EXPENSE_TYPE_LIST_REQUEST,
  EXPENSE_TYPE_LIST_REQUEST_SUCCESS,
  EXPENSE_TYPE_LIST_REQUEST_ERROR,
  EXPENSE_TYPE_ADD,
  EXPENSE_TYPE_ADD_SUCCESS,
  EXPENSE_TYPE_ADD_ERROR,
  EXPENSE_TYPE_UPDATE,
  EXPENSE_TYPE_UPDATE_SUCCESS,
  EXPENSE_TYPE_UPDATE_ERROR,
  EXPENSE_TYPE,
  EXPENSE_TYPE_SUCCESS,
  EXPENSE_TYPE_ERROR,
  EXPENSE_TYPE_REMOVE,
  EXPENSE_TYPE_REMOVE_SUCCESS,
  EXPENSE_TYPE_REMOVE_ERROR,
} from '@/store/actions/expenseType';

import Repository, { EXPENSE_TYPES } from '@/repositories/RepositoryFactory';
import { handleRequestError } from '@/utils/error';

const AdminRepository = Repository.get(EXPENSE_TYPES);

const state = {
  expenseTypeList: [],
  expenseTypeListStatus: '',
  expenseTypeListMeta: '',
  expenseTypeAddStatus: '',
  expenseTypeUpdateStatus: '',
  expenseType: '',
  expenseTypeStatus: '',
  expenseTypeRemoveStatus: '',
};

const getters = {
  expenseTypeList: state => state.expenseTypeList,
  expenseTypeListStatus: state => state.expenseTypeListStatus,
  expenseTypeListMeta: state => state.expenseTypeListMeta,
  expenseTypeAddStatus: state => state.expenseTypeAddStatus,
  expenseTypeUpdateStatus: state => state.expenseTypeUpdateStatus,
  expenseType: state => state.expenseType,
  expenseTypeStatus: state => state.expenseTypeStatus,
  expenseTypeRemoveStatus: state => state.expenseTypeRemoveStatus,
};

const actions = {
  [EXPENSE_TYPE_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(EXPENSE_TYPE_LIST_REQUEST);
    try {
      const response = await AdminRepository.get(payload);
      commit(EXPENSE_TYPE_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(EXPENSE_TYPE_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [EXPENSE_TYPE_ADD]: async ({ commit }, payload) => {
    commit(EXPENSE_TYPE_ADD);
    try {
      await AdminRepository.create(payload.data);
      commit(EXPENSE_TYPE_ADD_SUCCESS);
    } catch (error) {
      commit(EXPENSE_TYPE_ADD_ERROR);
      handleRequestError(error);
    }
  },
  [EXPENSE_TYPE_UPDATE]: async ({ commit }, payload) => {
    commit(EXPENSE_TYPE_UPDATE);
    try {
      AdminRepository.update(payload.id, payload.data);
      commit(EXPENSE_TYPE_UPDATE_SUCCESS);
    } catch (error) {
      commit(EXPENSE_TYPE_UPDATE_ERROR);
      handleRequestError(error);
    }
  },
  [EXPENSE_TYPE]: async ({ commit }, payload) => {
    commit(EXPENSE_TYPE);
    try {
      const response = await AdminRepository.show(payload.id);
      const { data } = response;
      commit(EXPENSE_TYPE_SUCCESS, data);
    } catch (error) {
      commit(EXPENSE_TYPE_ERROR);
      handleRequestError(error);
    }
  },
  [EXPENSE_TYPE_REMOVE]: async ({ commit }, payload) => {
    commit(EXPENSE_TYPE_REMOVE);
    try {
      await AdminRepository.delete(payload.id);
      commit(EXPENSE_TYPE_REMOVE_SUCCESS, payload.id);
    } catch (error) {
      commit(EXPENSE_TYPE_REMOVE_ERROR);
      handleRequestError(error);
    }
  },
};

const mutations = {
  [EXPENSE_TYPE_LIST_REQUEST]: state => {
    state.expenseTypeListStatus = 'loading';
  },
  [EXPENSE_TYPE_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.expenseTypeListStatus = 'success';
    state.expenseTypeList = payload.data;
    state.expenseTypeListMeta = payload.meta;
  },
  [EXPENSE_TYPE_LIST_REQUEST_ERROR]: state => {
    state.expenseTypeListStatus = 'error';
  },
  [EXPENSE_TYPE_ADD]: state => {
    state.expenseTypeAddStatus = 'loading';
  },
  [EXPENSE_TYPE_ADD_SUCCESS]: state => {
    state.expenseTypeAddStatus = 'success';
  },
  [EXPENSE_TYPE_ADD_ERROR]: state => {
    state.expenseTypeAddStatus = 'error';
  },
  [EXPENSE_TYPE_UPDATE]: state => {
    state.expenseTypeUpdateStatus = 'loading';
  },
  [EXPENSE_TYPE_UPDATE_SUCCESS]: state => {
    state.expenseTypeUpdateStatus = 'success';
  },
  [EXPENSE_TYPE_UPDATE_ERROR]: state => {
    state.expenseTypeUpdateStatus = 'error';
  },
  [EXPENSE_TYPE]: state => {
    state.expenseTypeStatus = 'loading';
  },
  [EXPENSE_TYPE_SUCCESS]: (state, payload) => {
    state.expenseTypeStatus = 'success';
    state.expenseType = payload.data.name;
  },
  [EXPENSE_TYPE_ERROR]: state => {
    state.expenseTypeStatus = 'error';
  },
  [EXPENSE_TYPE_REMOVE]: state => {
    state.expenseTypeRemoveStatus = 'loading';
  },
  [EXPENSE_TYPE_REMOVE_SUCCESS]: (state, payload) => {
    state.expenseTypeRemoveStatus = 'success';
    const index = findIndex(state.expenseTypeList, { id: payload });
    if (index > -1) {
      state.expenseTypeList.splice(index, 1);
    }
  },
  [EXPENSE_TYPE_REMOVE_ERROR]: state => {
    state.expenseTypeRemoveStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
