import { find, findIndex } from 'lodash';
import {
  APARTMENT_LIST_REQUEST,
  APARTMENT_LIST_REQUEST_ERROR,
  APARTMENT_LIST_REQUEST_SUCCESS,
  APARTMENT_SEARCH,
  APARTMENT_SEARCH_ERROR,
  APARTMENT_SEARCH_SUCCESS,
  APARTMENT_SEARCH_LIST_CLEAR,
  APARTMENT_ADD,
  APARTMENT_ADD_ERROR,
  APARTMENT_ADD_SUCCESS,
  APARTMENT_REQUEST,
  APARTMENT_REQUEST_SUCCESS,
  APARTMENT_REQUEST_ERROR,
  APARTMENT_REMOVE,
  APARTMENT_REMOVE_ERROR,
  APARTMENT_REMOVE_SUCCESS,
  APARTMENT_UPDATE,
  APARTMENT_UPDATE_ERROR,
  APARTMENT_UPDATE_SUCCESS,
  APARTMENT_LIST_IMPORT,
  APARTMENT_LIST_IMPORT_SUCCESS,
  APARTMENT_LIST_IMPORT_ERROR,
} from '@/store/actions/apartment';
import { handleRequestError } from '@/utils/error';

import Repository, { APARTMENTS } from '@/repositories/RepositoryFactory';

const ApartmentsRepository = Repository.get(APARTMENTS);

const state = {
  apartment: '',
  apartmentList: [],
  apartmentLastCreated: '',
  apartmentMeta: '',
  apartmentStatus: '',
  apartmentListStatus: '',
  apartmentSearchList: '',
  apartmentSearchStatus: '',
  apartmentSearchFieldId: '',
};

const getters = {
  getApartmentById: (state, getters) => id => {
    if (getters.apartmentList.length > 0) {
      return find(getters.apartmentList, { id });
    }

    return state.service;
  },
  apartment: state => state.apartment,
  apartmentList: state => state.apartmentList,
  apartmentLastCreated: state => state.apartmentLastCreated,
  apartmentMeta: state => state.apartmentMeta,
  apartmentStatus: state => state.apartmentStatus,
  apartmentListStatus: state => state.apartmentListStatus,
  apartmentSearchList: state => state.apartmentSearchList,
  apartmentSearchStatus: state => state.apartmentSearchStatus,
  apartmentSearchFieldId: state => state.apartmentSearchFieldId,
};

const actions = {
  [APARTMENT_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(APARTMENT_LIST_REQUEST);
    try {
      const response = await ApartmentsRepository.get(payload.businessId, payload.data);
      commit(APARTMENT_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(APARTMENT_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },

  [APARTMENT_SEARCH]: async ({ commit }, payload) => {
    commit(APARTMENT_SEARCH, payload.searchingFieldId);
    try {
      let items = [];
      if (payload.query) {
        const response = await ApartmentsRepository.get(payload.businessId, payload.data);
        items = response.data.data;
      }

      commit(APARTMENT_SEARCH_SUCCESS, items);
    } catch (error) {
      commit(APARTMENT_SEARCH_ERROR);
      handleRequestError(error);
    }
  },

  [APARTMENT_SEARCH_LIST_CLEAR]: async ({ commit }) => {
    commit(APARTMENT_SEARCH_LIST_CLEAR);
  },

  [APARTMENT_REQUEST]: async ({ commit }, payload) => {
    commit(APARTMENT_REQUEST);
    try {
      const response = await ApartmentsRepository.getSelf(payload.businessId, payload.id);
      const { data } = response;
      commit(APARTMENT_REQUEST_SUCCESS, data);
    } catch (error) {
      commit(APARTMENT_REQUEST_ERROR);
      handleRequestError(error);
    }
  },

  [APARTMENT_ADD]: async ({ commit }, payload) => {
    commit(APARTMENT_ADD);
    try {
      const response = await ApartmentsRepository.create(payload.businessId, payload.data);
      commit(APARTMENT_ADD_SUCCESS, response.data.data);
    } catch (error) {
      commit(APARTMENT_ADD_ERROR);
      handleRequestError(error);
    }
  },

  [APARTMENT_UPDATE]: async ({ commit }, payload) => {
    commit(APARTMENT_UPDATE);
    try {
      const response = await ApartmentsRepository.update(
        payload.businessId,
        payload.id,
        payload.data
      );
      commit(APARTMENT_UPDATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(APARTMENT_UPDATE_ERROR);
      handleRequestError(error);
    }
  },

  [APARTMENT_REMOVE]: async ({ commit }, payload) => {
    commit(APARTMENT_REMOVE);
    try {
      await ApartmentsRepository.delete(payload.businessId, payload.id);
      commit(APARTMENT_REMOVE_SUCCESS, payload.id);
    } catch (error) {
      commit(APARTMENT_REMOVE_ERROR);
      handleRequestError(error);
    }
  },

  [APARTMENT_LIST_IMPORT]: async ({ commit }, payload) => {
    commit(APARTMENT_LIST_IMPORT);
    try {
      const response = await ApartmentsRepository.import(payload.businessId, payload.data);
      commit(APARTMENT_LIST_IMPORT_SUCCESS, response.data.data);
    } catch (error) {
      commit(APARTMENT_LIST_IMPORT_ERROR);
      handleRequestError(error);
    }
  },
};

const mutations = {
  [APARTMENT_LIST_REQUEST]: state => {
    state.apartmentListStatus = 'loading';
  },
  [APARTMENT_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.apartmentListStatus = 'success';
    state.apartmentList = payload.data;
    state.apartmentMeta = payload.meta;
  },
  [APARTMENT_LIST_REQUEST_ERROR]: state => {
    state.apartmentListStatus = 'error';
  },

  [APARTMENT_SEARCH]: (state, payload) => {
    state.apartmentSearchFieldId = payload;
    state.apartmentSearchStatus = 'loading';
  },
  [APARTMENT_SEARCH_SUCCESS]: (state, payload) => {
    state.apartmentSearchStatus = 'success';
    state.apartmentSearchList = payload;
  },
  [APARTMENT_SEARCH_ERROR]: state => {
    state.apartmentSearchStatus = 'error';
  },

  [APARTMENT_SEARCH_LIST_CLEAR]: state => {
    state.apartmentSearchFieldId = '';
    state.apartmentSearchList = [];
  },

  [APARTMENT_ADD]: state => {
    state.apartmentStatus = 'loading';
    state.apartmentLastCreated = '';
  },
  [APARTMENT_ADD_SUCCESS]: (state, payload) => {
    state.apartmentStatus = 'success';
    state.apartmentLastCreated = payload;
    state.apartmentList.push(payload);
  },
  [APARTMENT_ADD_ERROR]: state => {
    state.apartmentStatus = 'error';
  },

  [APARTMENT_LIST_IMPORT]: state => {
    state.apartmentStatus = 'loading';
  },
  [APARTMENT_LIST_IMPORT_SUCCESS]: state => {
    state.apartmentStatus = 'success';
    // state.apartmentList.push(payload);
  },
  [APARTMENT_LIST_IMPORT_ERROR]: state => {
    state.apartmentStatus = 'error';
  },

  [APARTMENT_REQUEST]: state => {
    state.apartmentStatus = 'loading';
  },
  [APARTMENT_REQUEST_SUCCESS]: (state, payload) => {
    state.apartmentStatus = 'success';
    state.apartment = payload.data;
  },
  [APARTMENT_REQUEST_ERROR]: state => {
    state.apartmentStatus = 'error';
  },

  [APARTMENT_UPDATE]: state => {
    state.apartmentStatus = 'loading';
  },
  [APARTMENT_UPDATE_SUCCESS]: (state, payload) => {
    state.apartmentStatus = 'success';
    const index = findIndex(state.apartmentList, { id: payload.id });
    state.apartmentList[index] = payload;
  },
  [APARTMENT_UPDATE_ERROR]: state => {
    state.apartmentStatus = 'error';
  },

  [APARTMENT_REMOVE]: state => {
    state.apartmentStatus = 'deleting';
  },

  [APARTMENT_REMOVE_SUCCESS]: (state, payload) => {
    const index = findIndex(state.apartmentList, { id: payload });
    if (index > -1) {
      state.apartmentList.splice(index, 1);
    }
  },

  [APARTMENT_REMOVE_ERROR]: state => {
    state.apartmentStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
