<template>
  <div class="field">
    <label v-if="label" class="flex flex-col sm:flex-row">
      {{ label }}
      <span v-if="helpText" class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600">
        {{ helpText }}
      </span>
    </label>

    <div class="flex flex-col sm:flex-row">
      <div
        v-for="option in options"
        :key="option.id"
        class="flex items-center text-gray-700 dark:text-gray-500 mr-2 mt-2"
      >
        <input
          :value="option.id"
          :checked="value.includes(option.id)"
          @change="emitToForm"
          type="checkbox"
          class="input border mr-2"
          :id="option.name"
        />
        <label :for="option.name" class="cursor-pointer select-none">
          {{ option.name }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import baseInputMixin from '@/mixins/baseInputMixin';

export default {
  mixins: [baseInputMixin],
  props: {
    options: Array,
    checked: Boolean,
  },

  methods: {
    emitToForm(event) {
      const isChecked = event.target.checked;
      const item = parseInt(event.target.value, 10);
      const newValue = this.value;
      if (isChecked) {
        newValue.push(item);
      } else {
        newValue.splice(newValue.indexOf(item), 1);
      }
      this.$emit('change', newValue);
    },
  },
};
</script>
