<template>
  <div class="mt-5">
    <div class="intro-y">
      <router-view :business="business"></router-view>
    </div>
  </div>
</template>

<script>
export default {
  props: { business: Object },
};
</script>
