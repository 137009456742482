import { findIndex } from 'lodash';
import {
  TICKET_CATEGORY_LIST_REQUEST,
  TICKET_CATEGORY_LIST_REQUEST_SUCCESS,
  TICKET_CATEGORY_LIST_REQUEST_ERROR,
  TICKET_CATEGORY_ADD,
  TICKET_CATEGORY_ADD_SUCCESS,
  TICKET_CATEGORY_ADD_ERROR,
  TICKET_CATEGORY_UPDATE,
  TICKET_CATEGORY_UPDATE_SUCCESS,
  TICKET_CATEGORY_UPDATE_ERROR,
  TICKET_CATEGORY,
  TICKET_CATEGORY_SUCCESS,
  TICKET_CATEGORY_ERROR,
  TICKET_CATEGORY_REMOVE,
  TICKET_CATEGORY_REMOVE_SUCCESS,
  TICKET_CATEGORY_REMOVE_ERROR,
} from '@/store/actions/ticketCategory';

import Repository, { TICKET_CATEGORY_REPOSITORY } from '@/repositories/RepositoryFactory';
import { handleRequestError } from '@/utils/error';

const AdminRepository = Repository.get(TICKET_CATEGORY_REPOSITORY);

const state = {
  ticketCategoryList: [],
  ticketCategoryListStatus: '',
  ticketCategoryListMeta: '',
  ticketCategoryAddStatus: '',
  ticketCategoryUpdateStatus: '',
  ticketCategory: '',
  ticketCategoryStatus: '',
  ticketCategoryRemoveStatus: '',
};

const getters = {
  ticketCategoryList: state => state.ticketCategoryList,
  ticketCategoryListStatus: state => state.ticketCategoryListStatus,
  ticketCategoryListMeta: state => state.ticketCategoryListMeta,
  ticketCategoryAddStatus: state => state.ticketCategoryAddStatus,
  ticketCategoryUpdateStatus: state => state.ticketCategoryUpdateStatus,
  ticketCategory: state => state.ticketCategory,
  ticketCategoryStatus: state => state.ticketCategoryStatus,
  ticketCategoryRemoveStatus: state => state.ticketCategoryRemoveStatus,
};

const actions = {
  [TICKET_CATEGORY_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(TICKET_CATEGORY_LIST_REQUEST);
    try {
      const response = await AdminRepository.get(payload);
      commit(TICKET_CATEGORY_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(TICKET_CATEGORY_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [TICKET_CATEGORY_ADD]: async ({ commit }, payload) => {
    commit(TICKET_CATEGORY_ADD);
    try {
      await AdminRepository.create(payload.data);
      commit(TICKET_CATEGORY_ADD_SUCCESS);
    } catch (error) {
      commit(TICKET_CATEGORY_ADD_ERROR);
      handleRequestError(error);
    }
  },
  [TICKET_CATEGORY_UPDATE]: async ({ commit }, payload) => {
    commit(TICKET_CATEGORY_UPDATE);
    try {
      AdminRepository.update(payload.id, payload.data);
      commit(TICKET_CATEGORY_UPDATE_SUCCESS);
    } catch (error) {
      commit(TICKET_CATEGORY_UPDATE_ERROR);
      handleRequestError(error);
    }
  },
  [TICKET_CATEGORY]: async ({ commit }, payload) => {
    commit(TICKET_CATEGORY);
    try {
      const response = await AdminRepository.show(payload.id);
      const { data } = response;
      commit(TICKET_CATEGORY_SUCCESS, data);
    } catch (error) {
      commit(TICKET_CATEGORY_ERROR);
      handleRequestError(error);
    }
  },
  [TICKET_CATEGORY_REMOVE]: async ({ commit }, payload) => {
    commit(TICKET_CATEGORY_REMOVE);
    try {
      await AdminRepository.delete(payload.id);
      commit(TICKET_CATEGORY_REMOVE_SUCCESS, payload.id);
    } catch (error) {
      commit(TICKET_CATEGORY_REMOVE_ERROR);
      handleRequestError(error);
    }
  },
};

const mutations = {
  [TICKET_CATEGORY_LIST_REQUEST]: state => {
    state.ticketCategoryListStatus = 'loading';
  },
  [TICKET_CATEGORY_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.ticketCategoryListStatus = 'success';
    state.ticketCategoryList = payload.data;
    state.ticketCategoryListMeta = payload.meta;
  },
  [TICKET_CATEGORY_LIST_REQUEST_ERROR]: state => {
    state.ticketCategoryListStatus = 'error';
  },
  [TICKET_CATEGORY_ADD]: state => {
    state.ticketCategoryAddStatus = 'loading';
  },
  [TICKET_CATEGORY_ADD_SUCCESS]: state => {
    state.ticketCategoryAddStatus = 'success';
  },
  [TICKET_CATEGORY_ADD_ERROR]: state => {
    state.ticketCategoryAddStatus = 'error';
  },
  [TICKET_CATEGORY_UPDATE]: state => {
    state.ticketCategoryUpdateStatus = 'loading';
  },
  [TICKET_CATEGORY_UPDATE_SUCCESS]: state => {
    state.ticketCategoryUpdateStatus = 'success';
  },
  [TICKET_CATEGORY_UPDATE_ERROR]: state => {
    state.ticketCategoryUpdateStatus = 'error';
  },
  [TICKET_CATEGORY]: state => {
    state.ticketCategoryStatus = 'loading';
  },
  [TICKET_CATEGORY_SUCCESS]: (state, payload) => {
    state.ticketCategoryStatus = 'success';
    state.ticketCategory = payload.data.name;
  },
  [TICKET_CATEGORY_ERROR]: state => {
    state.ticketCategoryStatus = 'error';
  },
  [TICKET_CATEGORY_REMOVE]: state => {
    state.ticketCategoryRemoveStatus = 'loading';
  },
  [TICKET_CATEGORY_REMOVE_SUCCESS]: (state, payload) => {
    state.ticketCategoryRemoveStatus = 'success';
    const index = findIndex(state.ticketCategoryList, { id: payload });
    if (index > -1) {
      state.ticketCategoryList.splice(index, 1);
    }
  },
  [TICKET_CATEGORY_REMOVE_ERROR]: state => {
    state.ticketCategoryRemoveStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
