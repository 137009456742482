<template>
  <div class="cursor-pointer box">
    <div class="px-4 py-2 bg-default rounded-t-lg">
      <h3 class="text-base font-medium text-white">Зөвшөөрөл олгох</h3>
    </div>
    <div class="border-t border-gray-200 dark:border-dark-5 flex items-center justify-between">
      <div class="w-full flex-grow h-56 overflow-y-auto">
        <div v-for="(item, idx) in pendingUsers" :key="idx" class="flex border-b border-gray-200">
          <div class="w-2/5 w-25 mt-3 mb-3 ml-5 mr-1 text-xs">
            <h4 class="text-md font-bold">
              {{ _.get(item, 'profile_new.first_name', '-') }}
              {{ _.get(item, 'profile_new.last_name', '-') }}
            </h4>
            <span>
              {{ _.get(item, 'profile_new.apartments[0].building.name', '-') }} байр
              {{ _.get(item, 'profile_new.apartments[0].number', '-') }} тоот
            </span>
          </div>
          <div class="w-3/5">
            <div class="meeting-approve">
              <Button
                @click="decline(item)"
                class="button px-2 py-1 inline-flex items-center justify-center w-30 bg-theme-14 text-red-500 mr-1 text-xs-new"
              >
                Татгалзах
              </Button>
              <Button
                @click="approve(item)"
                class="button px-2 py-1 inline-flex items-center justify-center w-30 bg-theme-14 text-green-300 text-xs-new"
              >
                Зөвшөөрөх
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import permissionMixin from '@/mixins/permissionMixin';

export default {
  mixins: [permissionMixin],

  props: {
    meeting: Object,
    users: {
      type: Array,
      default: () => [],
    },
    usersData: {
      type: Array,
      default: () => [],
    },
    client: {
      type: Object,
      default: null,
    },
  },
  computed: {
    pendingUsers() {
      return this.usersData.filter(e => e.hand_raised);
      // return this.users.filter(e => e.meeting_state.status === 'PENDING');
    },
    getHours() {
      return this.meeting.duration / 60;
    },
  },
  methods: {
    approve(item) {
      if (this.client == null) return;
      this.client.approveSpeaker(item.profile_new.id);
    },
    decline(item) {
      if (this.client == null) return;
      this.client.declineSpeaker(item.profile_new.id);
    },
  },
};
</script>
<style>
.bg-default {
  background: #1c3faa;
}

.text-xs-new {
  font-size: 0.65rem;
}

.meeting-approve {
  margin: 15px 0;
}
</style>
