<template>
  <div class="field">
    <label v-if="label" class="flex flex-col sm:flex-row">
      {{ label }}
      <span v-if="helpText" class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600">
        {{ helpText }}
      </span>
    </label>

    <datepicker
      v-bind:value="valueToText"
      :input-class="[
        'input w-full border block',
        inputClass,
        { 'border-theme-6': hasError },
        { 'mt-2': label },
      ]"
      wrapper-class=""
      :minimumView="'year'"
      :maximumView="'year'"
      format="yyyy"
      :language="mn"
      :placeholder="placeholder"
      @selected="onSelectDate"
    ></datepicker>

    <HasError v-if="hasError" :vuelidate="vuelidate" :invalidFeedback="invalidFeedback" />
  </div>
</template>

<script>
import baseInputMixin from '@/mixins/baseInputMixin';
import HasError from '@/components/ui/input/HasError';
import Datepicker from 'vuejs-datepicker';
import { mn } from 'vuejs-datepicker/dist/locale';
import moment from 'moment';

export default {
  mixins: [baseInputMixin],

  components: { HasError, Datepicker },

  data() {
    return {
      mn,
    };
  },

  computed: {
    valueToText() {
      return this.value;
    },
  },

  methods: {
    onSelectDate(date) {
      this.$emit('input', moment(date).format('YYYY'));
    },
  },
};
</script>
