<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <PageHeader class="text-lg font-medium mr-auto" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <Button
          type="button"
          :to="{ name: 'user' }"
          class="box text-gray-700 dark:text-gray-300 mr-2 flex"
        >
          {{ $t('text.back') }}
        </Button>
        <Button
          type="button"
          @click="updatePermission(permissionForm)"
          :loading="isLoading"
          class="w-32 bg-theme-1 text-white"
        >
          {{ $t('permission.updatePermission') }}
        </Button>
      </div>
    </div>
    <div class="intro-y mt-5 overflow-auto lg:overflow-visible">
      <div class="table-regular" v-if="!isLoading">
        <div class="table-regular-holder">
          <table class="table mt-5">
            <thead>
              <tr>
                <template>
                  <th>
                    <div class="table-regular-col-title">
                      {{ $t('permission.table.menu') }}
                    </div>
                  </th>
                  <th>
                    <div class="table-regular-col-title">
                      {{ $t('permission.table.window') }}
                    </div>
                  </th>
                  <th>
                    <div class="table-regular-col-title">
                      {{ $t('permission.table.show') }}
                    </div>
                  </th>
                  <th>
                    <div class="table-regular-col-title">
                      {{ $t('permission.table.create') }}
                    </div>
                  </th>
                  <th>
                    <div class="table-regular-col-title">
                      {{ $t('permission.table.edit') }}
                    </div>
                  </th>
                  <th>
                    <div class="table-regular-col-title">
                      {{ $t('permission.table.delete') }}
                    </div>
                  </th>
                  <th>
                    <div class="table-regular-col-title">
                      {{ $t('permission.table.download') }}
                    </div>
                  </th>
                  <th>
                    <div class="table-regular-col-title">
                      {{ $t('permission.table.upload') }}
                    </div>
                  </th>
                  <th>
                    <div class="table-regular-col-title">
                      {{ $t('permission.table.confirm') }}
                    </div>
                  </th>
                  <th>
                    <div class="table-regular-col-title">
                      {{ $t('permission.table.changeStatus') }}
                    </div>
                  </th>
                </template>
              </tr>
            </thead>
            <tbody>
              <template v-for="permissionEach in permissionForm">
                <tr v-for="(items, i) in permissionEach.item" :key="items.name + i">
                  <td :rowspan="permissionEach.length">
                    <div class="flex items-center justify-left">
                      <p class=" text-gray-900 dark:text-gray-500 ml-3">
                        {{ $t(`permission.menu.${permissionEach.menu}`) }}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div class="flex items-center justify-left">
                      <p class=" text-gray-900 dark:text-gray-500 ml-3">
                        {{ $t(`permission.window.${items.name}`) }}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div class="flex items-center justify-center">
                      <input
                        type="checkbox"
                        v-model="items.permission.show"
                        v-if="typeof items.permission.show !== 'undefined'"
                        input-class="input-permission-show"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="flex items-center justify-center">
                      <input
                        type="checkbox"
                        v-model="items.permission.create"
                        v-if="typeof items.permission.create !== 'undefined'"
                        input-class="input-permission-create"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="flex items-center justify-center">
                      <input
                        type="checkbox"
                        v-model="items.permission.edit"
                        v-if="typeof items.permission.create !== 'undefined'"
                        input-class="input-permission-edit"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="flex items-center justify-center">
                      <input
                        type="checkbox"
                        v-model="items.permission.delete"
                        v-if="typeof items.permission.delete !== 'undefined'"
                        input-class="input-permission-delete"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="flex items-center justify-center">
                      <input
                        type="checkbox"
                        v-model="items.permission.download"
                        v-if="typeof items.permission.download !== 'undefined'"
                        input-class="input-permission-download"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="flex items-center justify-center">
                      <input
                        type="checkbox"
                        v-model="items.permission.upload"
                        v-if="typeof items.permission.upload !== 'undefined'"
                        input-class="input-permission-upload"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="flex items-center justify-center">
                      <input
                        type="checkbox"
                        v-model="items.permission.confirm"
                        v-if="typeof items.permission.confirm !== 'undefined'"
                        input-class="input-permission-confirm"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="flex items-center justify-center">
                      <input
                        type="checkbox"
                        v-model="items.permission.changeStatus"
                        v-if="typeof items.permission.changeStatus !== 'undefined'"
                        input-class="input-permission-change-status"
                      />
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <ApproveDialog :modal-id="approveModalId" @ok="toApprove" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { USER_PERMISSION_REQUEST, USER_PERMISSION_UPDATE } from '@/store/actions/user';
import ApproveDialog from '@/components/ui/ApproveDialog';
import dataListMixin from '@/mixins/dataListMixin';
import PageHeader from '@/components/ui/PageHeader';
import baseInputMixin from '@/mixins/baseInputMixin';
import Button from '@/components/ui/button/Button';

export default {
  mixins: [dataListMixin, baseInputMixin],

  components: {
    PageHeader,
    ApproveDialog,
    Button,
  },

  data() {
    return {
      permissionForm: [],
    };
  },

  computed: {
    ...mapGetters(['permissionList', 'permissionStatus', 'permissionUpdateStatus']),
    isLoading() {
      return this.permissionStatus === 'loading' || this.permissionUpdateStatus === 'loading';
    },
  },

  async created() {
    await this.fetchData();
  },

  methods: {
    async fetchData() {
      const payload = {
        id: this.$route.params.userId,
      };

      try {
        await this.$store.dispatch(USER_PERMISSION_REQUEST, payload);

        if (this.permissionList) {
          this.permissionForm = this.permissionList.map(permission => {
            return {
              item: permission.item.map(item => {
                return {
                  name: item.name,
                  permission: { ...item.permission },
                };
              }),
              menu: permission.menu,
            };
          });
        }
      } catch (error) {
        this.$toasted.show(this.$t('toast.failedRoute'), { type: 'error' });
        await this.$router.push({ name: 'user' });
      }
    },
    async updatePermission(permissionForm) {
      const array = [];
      const dot = '.';
      // const makes = this.permissionForm.find(permissions => permissions.item.find(items => items.permission.find(permissions => permissions === true)));
      for (let i = 0; i < permissionForm.length; i += 1) {
        for (let k = 0; k < permissionForm[i].item.length; k += 1) {
          Object.keys(permissionForm[i].item[k].permission).forEach(function a(permissions) {
            if (permissionForm[i].item[k].permission[permissions] === true) {
              array.push(
                permissionForm[i].menu + dot + permissionForm[i].item[k].name + dot + permissions
              );
            }
          });
        }
      }
      const payload = {
        user_id: this.$route.params.userId,
        permissions: array,
      };
      try {
        await this.$store.dispatch(USER_PERMISSION_UPDATE, payload);
        this.$toasted.show(this.$t('toast.success'), { type: 'success' });
        await this.fetchData();
      } catch (error) {
        this.$toasted.show(this.$t('toast.failedRoute'), { type: 'error' });
        await this.fetchData();
      }
    },
  },
};
</script>
