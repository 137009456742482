<template>
  <AuthLayout>
    <h2 class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
      {{ $t('auth.register') }}
    </h2>
    <component
      :is="currentStepComponent"
      :form-data="form"
      :cities="cityList"
      :title="title"
      @step-done="nextStep"
      class="intro-x mt-8"
    ></component>
  </AuthLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import AuthLayout from '@/views/auth/AuthLayout';
import { CITY_LIST_REQUEST } from '@/store/actions/localUnits';
import { USER_REGISTER } from '@/store/actions/user';
import Step1 from '@/components/register-company/Step1';
import Step2 from '@/components/register-company/Step2';
import Step3 from '@/components/register-company/Step3';

export default {
  components: { AuthLayout, Step1, Step2, Step3 },

  data() {
    return {
      title: this.$t('auth.organizationInfo'),
      steps: [
        { n: 1, title: this.$t('auth.organizationInfo') },
        { n: 2, title: this.$t('auth.staffInfo') },
        { n: 3, title: this.$t('auth.password') },
      ],
      currentStep: 1,
      currentStepComponent: 'Step1',
      form: {
        lastName: '',
        firstName: '',
        email: '',
        phone: '',
        password: '',
        passwordConfirmation: '',
        name: '',
        registrationNumber: '',
        cityId: null,
        districtId: null,
        horooId: null,
        logo: '',
        address: '',
      },
    };
  },

  created() {
    this.fetchData();
  },

  computed: {
    ...mapGetters(['cityStatus', 'cityList', 'userStatus']),
  },

  mounted() {
    document.querySelector('body').classList.add('login');
  },

  methods: {
    fetchData() {
      this.$store.dispatch(CITY_LIST_REQUEST);
    },
    nextStep() {
      if (this.currentStep < this.steps.length) {
        this.currentStep += 1;
        this.setTitle();
        return;
      }
      this.finish(this.form);
    },
    setTitle() {
      switch (this.currentStep) {
        case 1:
          this.title = this.$t('auth.organizationInfo');
          this.currentStepComponent = 'Step1';
          break;
        case 2:
          this.title = this.$t('auth.staffInfo');
          this.currentStepComponent = 'Step2';
          break;
        case 3:
          this.title = this.$t('auth.password');
          this.currentStepComponent = 'Step3';
          break;
        default:
          break;
      }
    },
    async finish(payload) {
      try {
        await this.$store.dispatch(USER_REGISTER, payload);
        this.$router.push({ name: 'UserLogin' });
      } catch (error) {
        this.$toasted.show(error.message, { type: 'error' });
      }
    },
  },
};
</script>
