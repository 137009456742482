<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-5">
      <PageHeader class="text-lg font-medium mr-auto" />
    </div>
    <div class="intro-y mt-5 overflow-auto lg:overflow-visible">
      <BusinessApproveTable
        :loading="isLoading"
        :rows="businessApproveList"
        :columns="columns"
        :line-numbers="true"
        :sort="sort"
        :page="page"
        :per-page="perPage"
        :page-size="pageSize"
        :total-page="totalPage"
        @approve="showApproveDialog"
        @delete="confirmRemoval"
        @onFilter="onFilter"
        @onPageChange="onPageChange"
        @onPerPageChange="onPerPageChange"
        @onSortChange="onSortChange"
        @onDownload="onDownload"
      />
      <BusinessApprove
        :modal-id="approveModalId"
        @ok="toApproveBusiness"
        :businessName="businessName"
      />
    </div>
    <ConfirmDialog
      :modal-id="deleteModalId"
      :is-loading="isDeleting ? true : false"
      @ok="onDelete"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  BUSINESS_ADMIN_LIST_REQUEST,
  BUSINESS_ADMIN_APPROVE_REQUEST,
  BUSINESS_ADMIN_DELETE_REQUEST,
} from '@/store/actions/admin';
import PageHeader from '@/components/ui/PageHeader';
import BusinessApproveTable from '@/components/admin/BusinessApproveTable';
import BusinessApprove from '@/components/admin/BusiinessApproveDialog';
import dataListMixin from '@/mixins/dataListMixin';
import permissionMixin from '@/mixins/permissionMixin';

export default {
  mixins: [dataListMixin, permissionMixin],

  data() {
    return {
      storeActionListRequest: BUSINESS_ADMIN_LIST_REQUEST,
      storeActionRemove: BUSINESS_ADMIN_DELETE_REQUEST,
      approveModalId: 'business-approve-dialog',
      filterAbleVal: '',
      businessAdminId: '',
      businessName: '',
      columns: [
        {
          label: this.$t('table.companyName'),
          field: 'name',
          sortable: true,
          filterable: true,
        },
        {
          label: this.$t('table.name'),
          field: 'firstName',
          sortable: true,
          filterable: true,
        },
        {
          label: this.$t('table.registrationNumber'),
          field: 'registrationNumber',
          sortable: true,
          filterable: true,
        },
        {
          label: this.$t('table.phone'),
          field: 'phone',
          sortable: true,
          filterable: true,
        },
        {
          label: this.$t('table.district'),
          field: 'district',
        },
        {
          label: this.$t('table.horoo'),
          field: 'horoo',
        },
        {
          label: this.$t('table.address'),
          field: 'address',
        },
        {
          label: this.$t('table.date'),
          field: 'createdAt',
        },
        {
          label: this.$t('table.action'),
          field: 'action',
        },
      ],
    };
  },

  computed: {
    ...mapGetters([
      'businessApproveList',
      'businessApproveListStatus',
      'businessApproveListMeta',
      'businessApproveRequestStatus',
    ]),
    isLoading() {
      return this.businessApproveListStatus === 'loading';
    },
  },

  async created() {
    await this.fetchData();
  },

  methods: {
    setPaginationValues() {
      this.totalRecords = this.businessApproveListMeta ? this.businessApproveListMeta.total : 1;
      this.page = this.businessApproveListMeta.currentPage;
      this.totalPage = this.businessApproveListMeta.lastPage;
    },
    showApproveDialog(id, name) {
      this.businessAdminId = id;
      this.businessName = name;
      cash(`#${this.approveModalId}`).modal('show');
    },
    async toApproveBusiness() {
      const payload = this.businessAdminId;
      await this.$store.dispatch(BUSINESS_ADMIN_APPROVE_REQUEST, payload);
      this.$toasted.show(this.$t('toast.success'), { type: 'success' });
      await this.fetchData();
    },
  },

  components: {
    PageHeader,
    BusinessApproveTable,
    BusinessApprove,
  },
};
</script>
