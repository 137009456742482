<template>
  <div :id="modalId" class="modal">
    <div class="modal__content">
      <div class="p-5 text-center">
        <MailIcon class="w-16 h-16 text-theme-9 mx-auto mt-3" />
        <div class="text-3xl mt-5">{{ $t('deleteConfirmDialog.title') }}</div>
      </div>
      <div class="px-5 pb-8 text-center">
        <Button
          type="button"
          @click="onCancel"
          class="w-24 border text-gray-700 dark:border-dark-5 dark:text-gray-300 mr-1"
        >
          {{ $t('text.cancel') }}
        </Button>
        <Button type="button" @click="submit" class="w-30 bg-theme-9 text-white">
          {{ $t('text.paySms') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/button/Button';

export default {
  components: { Button },

  props: {
    modalId: {
      type: String,
      default: 'subscribe-sms-confirm-dialog',
    },
  },
  methods: {
    submit() {
      cash(`#${this.modalId}`).modal('hide');
      this.$emit('onSubmit');
    },
    onCancel() {
      cash(`#${this.modalId}`).modal('hide');
    },
  },
};
</script>
