import SideMenuTooltip from '@/components/ui/SideMenuTooltip';
import Velocity from 'velocity-animate';

export default {
  components: { SideMenuTooltip },

  data() {
    return {
      formattedMenu: [],
    };
  },
  computed: {
    sideMenu() {
      const nested = this.nestedMenu(this.$store.state.sideMenu.menu).filter(menu => {
        if (typeof menu === 'object') {
          if (menu.group in this.$store.state.user.userPermissions) {
            return menu;
          }
          return false;
        }
        return menu;
      });
      return nested;
    },
  },
  watch: {
    $route() {
      this.formattedMenu = this.assign(this.sideMenu);
    },
  },
  mounted() {
    this.formattedMenu = this.assign(this.sideMenu);
  },
  methods: {
    nestedMenu(menu) {
      menu.forEach((item, key) => {
        if (typeof item !== 'string') {
          menu[key].active =
            item.pageName === this.$route.name ||
            (item.group &&
              this.$route.matched.length > 1 &&
              item.group === this.$route.matched[1].meta?.group) ||
            (this.issei(item.subMenu) && this.findActiveMenu(item.subMenu));
        }

        if (this.issei(item.subMenu)) {
          menu[key].activeDropdown = this.findActiveMenu(item.subMenu);
          menu[key] = {
            ...item,
            ...this.nestedMenu(item.subMenu),
          };
        }
      });

      return menu;
    },
    findActiveMenu(subMenu) {
      let match = false;
      subMenu.forEach(item => {
        if (item.pageName === this.$route.name) {
          match = true;
        } else if (!match && this.issei(item.subMenu)) {
          match = this.findActiveMenu(item.subMenu);
        }
      });
      return match;
    },
    linkTo(menu) {
      if (this.issei(menu.subMenu)) {
        menu.activeDropdown = !menu.activeDropdown;
      } else {
        this.$router.push({
          name: menu.pageName,
        });
      }
    },
    issei(obj) {
      if (obj !== null && obj !== undefined) {
        if (typeof obj === 'object' || Array.isArray(obj)) {
          return Object.keys(obj).length;
        }
        return obj.toString().length;
      }

      return false;
    },
    assign(obj) {
      return JSON.parse(JSON.stringify(obj));
    },

    enter(el, done) {
      Velocity(
        el,
        'slideDown',
        {
          duration: 300,
        },
        {
          complete: done,
        }
      );
    },
    leave(el, done) {
      Velocity(
        el,
        'slideUp',
        {
          duration: 300,
        },
        {
          complete: done,
        }
      );
    },
  },
};
