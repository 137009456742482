<template>
  <LoadingWithText v-if="isLoading" />
  <div v-else>
    <div class="intro-y mt-8 mb-5">
      <router-link
        :to="{ name: 'Buildings' }"
        class="button box inline-flex items-center text-gray-700 dark:text-gray-300"
      >
        <ArrowLeftIcon width="16" class="mr-2" />
        {{ $t('text.back') }}
      </router-link>
    </div>
    <div class="intro-y box px-5 pt-5">
      <div class="flex flex-col lg:flex-row border-b border-gray-200 dark:border-dark-5 pb-5 -mx-5">
        <div class="flex flex-1 px-5 items-center justify-center lg:justify-start">
          <div class="w-20 h-20 sm:w-24 sm:h-24 flex-none image-fit relative">
            <!-- <img src="@/assets/images/placeholder_building.svg" class="rounded-md" /> -->
            <div
              class="flex items-center justify-center w-20 h-20 sm:w-24 sm:h-24 bg-gray-200 dark:bg-dark-5 rounded-full"
            >
              <ImageIcon />
            </div>
            <!-- <div
              class="absolute mb-1 mr-1 flex items-center justify-center bottom-0 right-0 bg-theme-1 rounded-full p-2"
            >
              <CameraIcon class="w-4 h-4 text-white" />
            </div> -->
          </div>
          <div class="ml-5">
            <div class="w-24 sm:w-40 truncate sm:whitespace-normal font-medium text-lg">
              {{ building.name }}
            </div>
            <div class="text-gray-600 truncate">
              {{ building.district.name }} {{ building.horoo.name }}
            </div>
          </div>
        </div>
        <div
          class="flex mt-6 lg:mt-0 items-center lg:items-start flex-1 flex-col justify-center text-gray-600 dark:text-gray-300 px-5 border-l border-r border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0"
        >
          <div v-if="building.city" class="truncate sm:whitespace-normal flex items-center">
            {{ building.city.name }}
          </div>
          <div
            v-if="building.district"
            class="truncate sm:whitespace-normal flex items-center mt-1"
          >
            {{ building.district.name }}
          </div>
          <div v-if="building.horoo" class="truncate sm:whitespace-normal flex items-center mt-1">
            {{ $t('text.horooWithNumber', { number: building.horoo.name }) }}
          </div>
          <div v-if="building.town" class="truncate sm:whitespace-normal flex items-center mt-1">
            {{ building.town.name }}
          </div>
        </div>
        <div
          class="mt-6 lg:mt-0 flex-1 flex flex-col items-center lg:items-start justify-center px-5 border-t lg:border-0 border-gray-200 dark:border-dark-5 pt-5 lg:pt-0"
        >
          <div class="text-gray-600 dark:text-gray-300">
            {{ $t('text.totalApartments') }}:
            <span class="ml-2 font-medium">{{ building.apartmentsCount }}</span>
          </div>
          <div class="text-gray-600 dark:text-gray-300">
            {{ $t('text.totalOwners') }}:
            <span class="ml-2 font-medium">{{ building.ownersCount || 0 }}</span>
          </div>
          <div class="text-gray-600 dark:text-gray-300">
            {{ $t('text.totalParking') }}:
            <span class="ml-2 font-medium">{{ building.parkingCount }}</span>
          </div>
        </div>
      </div>
      <div class="nav-tabs flex flex-col sm:flex-row justify-center lg:justify-start">
        <router-link
          :to="{ name: 'Apartments' }"
          class="py-4 sm:mr-8"
          :class="{ active: $route.name === 'Apartments' }"
          >{{ $t('nav.apartments') }}</router-link
        >
        <router-link
          :to="{ name: 'Owners' }"
          class="py-4 sm:mr-8"
          :class="{ active: $route.name === 'Owners' }"
        >
          {{ $t('nav.owners') }}
        </router-link>
        <router-link
          :to="{ name: 'Parkings' }"
          class="py-4 sm:mr-8"
          :class="{ active: $route.name === 'Parkings' }"
          >{{ $t('nav.parkings') }}</router-link
        >
      </div>
    </div>
    <div class="intro-y">
      <router-view :business="business"></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BUILDING_REQUEST } from '@/store/actions/building';

export default {
  props: {
    business: Object,
  },

  computed: {
    ...mapGetters(['building', 'buildingStatus', 'buildingSingleMeta']),
    isLoading() {
      return this.buildingStatus === 'loading';
    },
  },

  watch: {
    '$route.params.buildingId': 'fetchData',
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      const payload = {
        businessId: this.business.id,
        id: this.$route.params.buildingId,
      };

      try {
        await this.$store.dispatch(BUILDING_REQUEST, payload);
      } catch (error) {
        this.$toasted.show(this.$t('toast.failedRoute'), { type: 'error' });
        this.$router.push({ name: 'Buildings' });
      }
    },
  },
};
</script>
