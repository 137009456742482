import Business from '@/views/Business/index';

export default [
  {
    path: '/business',
    name: 'Business',
    component: Business,
    meta: { group: 'business' },
    children: [],
  },
];
