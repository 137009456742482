<template>
  <TableRegular
    :loading="loading"
    :downloading="downloading"
    :rows="rows"
    :columns="columns"
    :sort="sort"
    :page="page"
    :per-page="perPage"
    :page-size="pageSize"
    :total-page="totalPage"
    :line-numbers="lineNumbers"
    :downloadable="downloadable && checkPermission('finance.income.download')"
    :custom-filters="customFilters"
    :selectable="selectable"
    :selected-rows="selectedRows"
    @onFilter="$emit('onFilter', $event)"
    @onPageChange="$emit('onPageChange', $event)"
    @onPerPageChange="$emit('onPerPageChange', $event)"
    @onSortChange="$emit('onSortChange', $event)"
    @onDownload="$emit('onDownload')"
    @onChangeCustomFilter="$emit('onChangeCustomFilter', $event)"
    @onSuccessFilter="$emit('onSuccessFilter', $event)"
    @check="$emit('check', $event)"
    @checkAll="$emit('checkAll')"
  >
    <template slot="table-row" slot-scope="props">
      <p v-if="props.column.field === 'building'" class="font-medium whitespace-nowrap">
        <template v-if="props.row.apartment && props.row.apartment.building">
          <BuildingFullname :item="props.row.apartment.building" />
        </template>
      </p>
      <p v-else-if="props.column.field === 'number'" class="font-medium whitespace-nowrap">
        {{ props.row.apartment && props.row.apartment.number }}
      </p>
      <p v-else-if="props.column.field === 'owners'" class="font-medium whitespace-nowrap">
        <template v-if="props.row.apartment">
          <PeopleFullname
            v-for="(owner, index) in props.row.apartment.owners"
            :key="index"
            :item="owner"
          />
        </template>
      </p>
      <div
        v-else-if="props.column.field === 'amount'"
        class="font-medium whitespace-nowrap flex items-center"
      >
        {{ props.row.total | currency }}
        <div
          v-if="props.row.note"
          class="inline-flex items-center ml-2 text-theme-11"
          v-tippy="{
            arrow: true,
            arrowType: 'round',
            boundary: 'viewport',
            placement: 'left',
            animateFill: false,
            animation: 'shift-away',
          }"
          :content="props.row.note"
        >
          <HelpCircleIcon class="w-5 h-5 mr-1 text" />
        </div>
      </div>
      <p v-else-if="props.column.field === 'paidAmount'" class="font-medium whitespace-nowrap">
        {{ props.row.paidAmount | currency }}
      </p>
      <p v-else-if="props.column.field === 'startDate'" class="whitespace-nowrap">
        {{ props.row.startDate.slice(0, 7) }} {{ $t('table.month') }}
      </p>
      <p v-else-if="props.column.field === 'paidDate'" class="whitespace-nowrap">
        {{ props.row.paidDate }}
      </p>
      <div v-else-if="props.column.field === 'status'" class="whitespace-nowrap">
        <div v-if="props.row.status" :class="['status', `is-${props.row.status.toLowerCase()}`]">
          {{ $t(`status.${props.row.status.toLowerCase()}`) }}
        </div>
      </div>
      <template v-else-if="props.column.field === 'payments'">
        <template v-if="props.row.payments && props.row.payments.length > 0">
          <p
            v-for="(payment, paymentIndex) in props.row.payments"
            :key="`payment-${payment.id}${paymentIndex}`"
            class="whitespace-nowrap flex items-center justify-between"
          >
            <span>{{ getPaymentType(payment) }}</span>
            <span class="block ml-2">{{ payment.paidAmount | currency }}</span>
          </p>
        </template>
        <template v-else>
          -
        </template>
      </template>
      <p v-else-if="props.column.field === 'morePayment'" class="whitespace-nowrap">
        {{ props.row.morePayment | currency }}
      </p>
      <p
        v-else-if="
          checkPermission('finance.income.edit') &&
            props.column.field === 'paid' &&
            props.row.status !== 'PAID'
        "
        class="whitespace-nowrap"
      >
        <select
          class="input w-full xxl:w-full sm:w-auto border"
          @change="
            onChange(props.row.total, $event, props.row.id, props.row.status, props.row.paidAmount)
          "
        >
          <option value="">--сонго--</option>
          <option v-for="(item, index) in paidAmountStatus" :key="index" :value="item.id">
            {{ item.name }}
          </option>
        </select>
      </p>
      <p v-else-if="props.column.field === 'action'" class="flex justify-end items-center">
        <a
          v-if="props.row.status !== 'PAID' && checkPermission('finance.income.changeStatus')"
          href="javascript:"
          @click="$emit('pay', props.row.id)"
          class="flex items-center mr-3"
        >
          <!-- <PrinterIcon class="w-4 h-4 mr-1" /> -->
          {{ $t('table.doPay') }}
        </a>
        <a
          v-if="checkPermission('finance.income.show')"
          href="javascript:"
          @click="$emit('show', props.row.id)"
          class="flex items-center mr-3"
        >
          <EyeIcon class="w-4 h-4 mr-1" />
          {{ $t('table.read') }}
        </a>
        <router-link
          v-if="checkPermission('finance.income.edit') && props.row.status !== 'PAID'"
          :to="{ name: 'InvoiceUpdate', params: { invoiceId: props.row.id } }"
          class="flex items-center mr-3"
        >
          <CheckSquareIcon class="w-4 h-4 mr-1" />
          {{ $t('table.edit') }}
        </router-link>
        <a
          v-if="checkPermission('finance.income.delete')"
          href="javascript:"
          @click="$emit('delete', props.row.id)"
          class="flex items-center text-theme-6"
        >
          <Trash2Icon class="w-4 h-4 mr-1" />
          {{ $t('table.delete') }}
        </a>
      </p>
    </template>
    <template #footer>
      <tr v-if="!loading && rows.length > 0 && footerItems">
        <th v-if="selectable"></th>
        <th v-if="lineNumbers"></th>
        <th></th>
        <th></th>
        <th>{{ $t('text.totalAmount') }}:</th>
        <th>
          <template v-if="footerItems.hasOwnProperty('totalAmount')">
            {{ footerItems.totalAmount | currency }}
          </template>
        </th>
        <th>
          <template v-if="footerItems.hasOwnProperty('totalPaidAmount')">
            {{ footerItems.totalPaidAmount | currency }}
          </template>
        </th>
        <th>{{ $t('table.balance') }}:</th>
        <th>
          <template v-if="footerItems.hasOwnProperty('totalBalance')">
            {{ footerItems.totalBalance | currency }}
          </template>
        </th>
        <th></th>
        <th></th>
        <th>
          <template v-if="footerItems.hasOwnProperty('total')">
            {{ $t('text.totalPieces') }}: {{ footerItems.total }}
          </template>
        </th>
      </tr>
    </template>
  </TableRegular>
</template>

<script>
import TableRegular from '@/components/ui/table/TableRegular';
import PeopleFullname from '@/components/people/PeopleFullname';
import BuildingFullname from '@/components/building/BuildingFullname';
import tableMixin from '@/mixins/tableMixin';
import { PAYMENT_TYPES } from '@/utils/constants';
import humps from 'humps';

export default {
  mixins: [tableMixin],

  props: { footerItems: Object },

  data() {
    return {
      paidAmountStatus: [
        {
          id: 'PAID',
          name: this.$t('status.paid'),
        },
      ],
      paidStatus: {
        status: '',
        amount: '',
        id: '',
      },
      paymentTypes: PAYMENT_TYPES,
    };
  },

  methods: {
    getPaymentType(payment) {
      const name = payment.paymentType.replace(' ', '_').toLowerCase();
      const type = humps.camelize(name);
      return type ? this.$t(`paymentTypes.${type}`) : '';
    },
    onChange(atotal, event, invoId, Astatus, paidAmount) {
      if (Astatus === 'UNDER_PAID') {
        this.paidStatus.amount = atotal - paidAmount;
        this.paidStatus.id = invoId;
        this.paidStatus.status = event.target.value;
      } else {
        this.paidStatus.amount = atotal;
        this.paidStatus.id = invoId;
        this.paidStatus.status = event.target.value;
      }
      this.$emit('onChangePaidStatus', this.paidStatus);
    },
  },

  components: { TableRegular, PeopleFullname, BuildingFullname },
};
</script>
