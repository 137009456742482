import { findIndex } from 'lodash';
import {
  TICKET_TYPE_LIST_REQUEST,
  TICKET_TYPE_LIST_REQUEST_SUCCESS,
  TICKET_TYPE_LIST_REQUEST_ERROR,
  TICKET_TYPE_ADD,
  TICKET_TYPE_ADD_SUCCESS,
  TICKET_TYPE_ADD_ERROR,
  TICKET_TYPE_UPDATE,
  TICKET_TYPE_UPDATE_SUCCESS,
  TICKET_TYPE_UPDATE_ERROR,
  TICKET_TYPE,
  TICKET_TYPE_SUCCESS,
  TICKET_TYPE_ERROR,
  TICKET_TYPE_REMOVE,
  TICKET_TYPE_REMOVE_SUCCESS,
  TICKET_TYPE_REMOVE_ERROR,
} from '@/store/actions/ticketType';

import Repository, { TICKET_TYPES } from '@/repositories/RepositoryFactory';
import { handleRequestError } from '@/utils/error';

const ticketTypeRepository = Repository.get(TICKET_TYPES);

const state = {
  ticketTypeList: [],
  ticketTypeListStatus: '',
  ticketTypeListMeta: '',
  ticketTypeAddStatus: '',
  ticketTypeUpdateStatus: '',
  ticketType: '',
  ticketTypeStatus: '',
  ticketRemoveStatus: '',
};

const getters = {
  ticketTypeList: state => state.ticketTypeList,
  ticketTypeListStatus: state => state.ticketTypeListStatus,
  ticketTypeListMeta: state => state.ticketTypeListMeta,
  ticketTypeAddStatus: state => state.ticketTypeAddStatus,
  ticketTypeUpdateStatus: state => state.ticketTypeUpdateStatus,
  ticketType: state => state.ticketType,
  ticketTypeStatus: state => state.ticketTypeStatus,
  ticketRemoveStatus: state => state.ticketRemoveStatus,
};

const actions = {
  [TICKET_TYPE_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(TICKET_TYPE_LIST_REQUEST);
    try {
      const response = await ticketTypeRepository.get(payload);
      commit(TICKET_TYPE_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(TICKET_TYPE_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [TICKET_TYPE_ADD]: async ({ commit }, payload) => {
    commit(TICKET_TYPE_ADD);
    try {
      await ticketTypeRepository.create(payload.data);
      commit(TICKET_TYPE_ADD_SUCCESS);
    } catch (error) {
      commit(TICKET_TYPE_ADD_ERROR);
      handleRequestError(error);
    }
  },
  [TICKET_TYPE_UPDATE]: async ({ commit }, payload) => {
    commit(TICKET_TYPE_UPDATE);
    try {
      ticketTypeRepository.update(payload.id, payload.data);
      commit(TICKET_TYPE_UPDATE_SUCCESS);
    } catch (error) {
      commit(TICKET_TYPE_UPDATE_ERROR);
      handleRequestError(error);
    }
  },
  [TICKET_TYPE]: async ({ commit }, payload) => {
    commit(TICKET_TYPE);
    try {
      const response = await ticketTypeRepository.show(payload.id);
      commit(TICKET_TYPE_SUCCESS, response.data);
    } catch (error) {
      commit(TICKET_TYPE_ERROR);
      handleRequestError(error);
    }
  },
  [TICKET_TYPE_REMOVE]: async ({ commit }, payload) => {
    commit(TICKET_TYPE_REMOVE);
    try {
      await ticketTypeRepository.delete(payload.id);
      commit(TICKET_TYPE_REMOVE_SUCCESS, payload.id);
    } catch (error) {
      commit(TICKET_TYPE_REMOVE_ERROR);
      handleRequestError(error);
    }
  },
};

const mutations = {
  [TICKET_TYPE_LIST_REQUEST]: state => {
    state.ticketTypeListStatus = 'loading';
  },
  [TICKET_TYPE_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.ticketTypeListStatus = 'success';
    state.ticketTypeList = payload.data;
    state.ticketTypeListMeta = payload.meta;
  },
  [TICKET_TYPE_LIST_REQUEST_ERROR]: state => {
    state.ticketTypeListStatus = 'error';
  },
  [TICKET_TYPE_ADD]: state => {
    state.ticketTypeAddStatus = 'loading';
  },
  [TICKET_TYPE_ADD_SUCCESS]: state => {
    state.ticketTypeAddStatus = 'success';
  },
  [TICKET_TYPE_ADD_ERROR]: state => {
    state.ticketTypeAddStatus = 'error';
  },
  [TICKET_TYPE_UPDATE]: state => {
    state.ticketTypeUpdateStatus = 'loading';
  },
  [TICKET_TYPE_UPDATE_SUCCESS]: state => {
    state.ticketTypeUpdateStatus = 'success';
  },
  [TICKET_TYPE_UPDATE_ERROR]: state => {
    state.ticketTypeUpdateStatus = 'error';
  },
  [TICKET_TYPE]: state => {
    state.ticketTypeStatus = 'loading';
  },
  [TICKET_TYPE_SUCCESS]: (state, payload) => {
    state.ticketTypeStatus = 'success';
    state.ticketType = payload.data.name;
  },
  [TICKET_TYPE_ERROR]: state => {
    state.ticketTypeStatus = 'error';
  },
  [TICKET_TYPE_LIST_REQUEST_ERROR]: state => {
    state.ticketTypeStatus = 'error';
  },
  [TICKET_TYPE_REMOVE]: state => {
    state.ticketRemoveStatus = 'loading';
  },
  [TICKET_TYPE_REMOVE_SUCCESS]: (state, payload) => {
    state.ticketRemoveStatus = 'success';
    const index = findIndex(state.ticketTypeList, { id: payload });
    if (index > -1) {
      state.ticketTypeList.splice(index, 1);
    }
  },
  [TICKET_TYPE_REMOVE_ERROR]: state => {
    state.ticketRemoveStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
