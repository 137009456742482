<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <PageHeader class="text-lg font-medium mr-auto" :title="title" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <Button
          type="button"
          :to="{ name: 'AppVersions' }"
          class="box text-gray-700 dark:text-gray-300 mr-2 flex"
        >
          {{ $t('text.back') }}
        </Button>
        <Button
          type="button"
          @click="onSubmit"
          :loading="isLoading"
          class="w-24 bg-theme-1 text-white"
        >
          {{ $t('text.save') }}
        </Button>
      </div>
    </div>
    <div class="intro-y mt-5">
      <form @submit.prevent="onSubmit">
        <div class="intro-y box">
          <div class="p-5 border-b border-gray-200 dark:border-dark-5">
            <h2 class="font-medium text-base">{{ $t('nav.appVersionShow') }}</h2>
          </div>
          <div class="py-4 px-5 relative">
            <div class="grid grid-cols-12 gap-6">
              <div class="col-span-12 sm:col-span-12 lg:col-span-6">
                <InputSelect
                  :label="$t('text.os')"
                  v-model="$v.form.os.$model"
                  :options="osOptions"
                  label-key="name"
                  value-key="id"
                  :vuelidate="$v.form.os"
                  input-class="input-app-version-os"
                />
                <InputSelect
                  :label="$t('text.type')"
                  v-model="$v.form.type.$model"
                  :options="typeOptions"
                  label-key="name"
                  value-key="id"
                  :vuelidate="$v.form.type"
                  input-class="input-app-version-type"
                />
                <InputString
                  :label="$t('text.version')"
                  v-model.trim="$v.form.latestVersion.$model"
                  :vuelidate="$v.form.latestVersion"
                  input-class="input-app-version-latest-version"
                />
                <InputCheckbox
                  v-model="form.forceUpgrade"
                  is-boolean
                  label="Force upgrade"
                  input-class="input-app-version-force-upgrade"
                />
              </div>
              <div class="col-span-12 sm:col-span-12 lg:col-span-6">
                <InputString
                  :label="$t('text.text')"
                  v-model.trim="$v.form.message.$model"
                  :vuelidate="$v.form.message"
                  input-class="input-app-version-message"
                  is-textarea
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { assign } from 'lodash';
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import {
  APP_VERSION_SHOW,
  APP_VERSION_CREATE,
  APP_VERSION_UPDATE,
} from '@/store/actions/appVersion';
import Button from '@/components/ui/button/Button';
import PageHeader from '@/components/ui/PageHeader';
import InputString from '@/components/ui/input/InputString';
import InputSelect from '@/components/ui/input/InputSelect';
import InputCheckbox from '@/components/ui/input/InputCheckbox';

export default {
  mixins: [validationMixin],

  validations() {
    return {
      form: {
        os: {
          required,
        },
        latestVersion: {
          required,
        },
        message: {
          required,
        },
        type: {
          required,
        },
      },
    };
  },

  data() {
    return {
      form: {
        os: '',
        latestVersion: '',
        forceUpgrade: false,
        message: '',
        type: '',
      },
      osOptions: [
        {
          id: 'ios',
          name: 'iOS',
        },
        {
          id: 'android',
          name: 'Android',
        },
      ],
      typeOptions: [
        {
          id: 'main',
          name: 'Үндсэн',
        },
        {
          id: 'darkhan',
          name: 'Дархан',
        },
      ],
    };
  },

  computed: {
    ...mapGetters(['appVersionStatus', 'appVersionStatus', 'appVersion']),
    isLoading() {
      return this.appVersionStatus === 'loading';
    },
    title() {
      return this.$route.name === 'AppVersionCreate'
        ? this.$t(`nav.appVersionCreate`)
        : this.$t(`nav.appVersionUpdate`);
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      if (this.$route.params.id) {
        await this.$store.dispatch(APP_VERSION_SHOW, { id: this.$route.params.id });
        if (this.appVersion) {
          const { os, latestVersion, forceUpgrade, message, type } = this.appVersion;
          this.form = {
            os,
            latestVersion,
            forceUpgrade,
            message,
            type,
          };
        }
      }
    },
    async onSubmit() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          this.$toasted.show(this.$t('toast.failedForm'), { type: 'error' });
          return;
        }
      }

      try {
        const payload = {
          data: { ...this.form },
        };

        if (this.$route.params.id) {
          assign(payload, { id: this.appVersion.id });
          await this.$store.dispatch(APP_VERSION_UPDATE, payload);
        } else {
          await this.$store.dispatch(APP_VERSION_CREATE, payload);
        }
        if (this.appVersionStatus === 'success') {
          this.$router.push({ name: 'AppVersions' });
          this.$toasted.show(this.$t('toast.success'), { type: 'success' });
        }
      } catch (error) {
        this.$toasted.show(error.message, { type: 'error' });
      }
    },
  },

  components: {
    Button,
    PageHeader,
    InputString,
    InputSelect,
    InputCheckbox,
  },
};
</script>
