import axios from 'axios';
import humps from 'humps';
import AuthTokenStorageService from '@/services/AuthTokenStorageService';

// Client.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
const headers = {};

const accessToken = AuthTokenStorageService.getAccessToken();
if (accessToken) {
  headers.Authorization = `Bearer ${accessToken}`;
}

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers,

  transformRequest: [data => humps.decamelizeKeys(data), ...axios.defaults.transformRequest],

  transformResponse: [...axios.defaults.transformResponse, data => humps.camelizeKeys(data)],
});

export default apiClient;
