<template>
  <nav class="side-nav" :class="simpleNav ? simpleNav : ''">
    <a
      href="#"
      class="intro-x flex items-center pl-0 pt-4 router-link-active"
      @click="sidebarClick"
    >
      <img
        v-if="isUB"
        :alt="$t('project.name')"
        src="@/assets/images/aillogoshine.png"
        class="w-24 -ml-8"
      />
      <img v-else :alt="$t('project.name')" src="@/assets/images/logo_darkhan.png" class="w-12" />
      <span v-if="isSimpleNav" class="hidden xl:block text-white text-lg ml-3">
        {{ $t('project.name') }}
      </span>
    </a>
    <div class="side-nav__divider my-6"></div>
    <ul>
      <template v-for="(menu, menuKey) in formattedMenu">
        <li v-if="menu === 'divider'" :key="menuKey" class="side-nav__divider my-6"></li>
        <li v-else :key="menuKey">
          <SideMenuTooltip
            tag="a"
            :content="menu.title"
            href="javascript:;"
            class="side-menu"
            :class="{
              'side-menu--active': menu.active,
              'side-menu--open': menu.activeDropdown,
            }"
            @click.native="linkTo(menu)"
          >
            <div class="side-menu__icon">
              <component :is="menu.icon" />
            </div>
            <div class="side-menu__title">
              {{ menu.title }}
              <ChevronDownIcon
                v-if="issei(menu.subMenu)"
                class="side-menu__sub-icon"
                :class="{ 'transform rotate-180': menu.activeDropdown }"
              />
            </div>
          </SideMenuTooltip>
          <transition @enter="enter" @leave="leave">
            <ul v-if="issei(menu.subMenu) && menu.activeDropdown">
              <li v-for="(subMenu, subMenuKey) in menu.subMenu" :key="subMenuKey">
                <SideMenuTooltip
                  tag="a"
                  :content="subMenu.title"
                  href="javascript:;"
                  class="side-menu"
                  :class="{ 'side-menu--active': subMenu.active }"
                  @click.native="linkTo(subMenu)"
                >
                  <div class="side-menu__icon">
                    <component :is="subMenu.icon" />
                  </div>
                  <div class="side-menu__title">
                    {{ subMenu.title }}
                    <ChevronDownIcon
                      v-if="issei(subMenu.subMenu)"
                      class="side-menu__sub-icon"
                      :class="{
                        'transform rotate-180': subMenu.activeDropdown,
                      }"
                    />
                  </div>
                </SideMenuTooltip>
                <transition @enter="enter" @leave="leave">
                  <ul v-if="issei(subMenu.subMenu) && subMenu.activeDropdown">
                    <li
                      v-for="(lastSubMenu, lastSubMenuKey) in subMenu.subMenu"
                      :key="lastSubMenuKey"
                    >
                      <SideMenuTooltip
                        tag="a"
                        :content="lastSubMenu.title"
                        href="javascript:;"
                        class="side-menu"
                        :class="{ 'side-menu--active': lastSubMenu.active }"
                        @click.native="linkTo(lastSubMenu)"
                      >
                        <div class="side-menu__icon">
                          <component :is="lastSubMenu.icon" />
                        </div>
                        <div class="side-menu__title">
                          {{ lastSubMenu.title }}
                        </div>
                      </SideMenuTooltip>
                    </li>
                  </ul>
                </transition>
              </li>
            </ul>
          </transition>
        </li>
      </template>
    </ul>
  </nav>
</template>

<script>
import navigationMixin from '@/mixins/navigationMixin';

export default {
  mixins: [navigationMixin],
  data() {
    return {
      simpleNav: '',
      isSimpleNav: true,
      isToggle: false,
      isUB: process.env.VUE_APP_LOCATION !== 'da',
    };
  },
  methods: {
    sidebarClick() {
      if (this.isToggle) {
        this.isSimpleNav = true;
        this.simpleNav = '';
        this.isToggle = false;
      } else {
        this.isSimpleNav = false;
        this.simpleNav = 'side-nav--simple';
        this.isToggle = true;
      }
    },
  },
};
</script>
