<template>
  <ModalEdit :id="modalId" :title="title" :loading="isLoading" @click="onSubmit">
    <FormOwner
      :form="$v.form"
      :selected-pets="form.pets"
      :cars="form.cars"
      @onPetsChange="onPetsChange"
      @onCarAdd="onCarAdd"
      @onCarDelete="onCarDelete"
    />
  </ModalEdit>
</template>

<script>
import { mapGetters } from 'vuex';
import { OWNER_ADD } from '@/store/actions/owner';
import { validationMixin } from 'vuelidate';
import { required, minLength, helpers, email, minValue } from 'vuelidate/lib/validators';
import ModalEdit from '@/components/ui/ModalEdit';
import baseModalMixin from '@/mixins/baseModalMixin';
import FormOwner from '@/components/form/FormOwner';
import phone from '@/utils/phone';
import registrationNumber from '@/utils/registrationNumber';

export default {
  mixins: [validationMixin, baseModalMixin],

  components: {
    ModalEdit,
    FormOwner,
  },

  props: {
    business: Object,
    apartment: Object,
    buildingId: Number,
    searchValue: String,
  },

  validations: {
    form: {
      lastName: {
        minLength: minLength(1),
      },
      firstName: {
        required,
        minLength: minLength(1),
      },
      phone: {
        required,
        phoneNumber: helpers.regex('phoneNumber', phone),
      },
      email: {
        email,
      },
      registrationNumber: {
        registrationNumber: helpers.regex('registrationNumber', registrationNumber),
      },
      familySize: {
        minValue: minValue(0),
      },
    },
  },

  data() {
    return {
      form: {
        lastName: '',
        firstName: '',
        phone: '',
        email: '',
        registrationNumber: '',
        familySize: 1,
        avatar: '',
        buildingId: this.buildingId,
        apartmentList: [],
        cars: [],
        pets: [],
      },
    };
  },

  watch: {
    searchValue: {
      handler(payload) {
        this.form.phone = payload;
      },
    },
  },

  computed: {
    ...mapGetters(['ownerStatus', 'ownerLastCreated']),
    isLoading() {
      return this.ownerStatus === 'loading';
    },
    title() {
      return this.$t('nav.ownerCreate');
    },
  },

  methods: {
    resetForm() {
      this.form = {
        lastName: '',
        firstName: '',
        phone: '',
        email: '',
        registrationNumber: '',
        familySize: 1,
        avatar: '',
        buildingId: this.buildingId,
        apartmentList: [],
        cars: [],
        pets: [],
      };
      this.$v.$reset();
    },

    onPetsChange(value) {
      this.form.pets = value;
    },

    onCarAdd(car) {
      this.form.cars.push(car);
    },

    onCarDelete(car) {
      const index = this.form.cars.findIndex(
        item => item.number === car.number && item.carMakeId === car.carMakeId
      );
      if (index > -1) {
        this.form.cars.splice(index, 1);
      }
    },

    async onSubmit() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          this.$toasted.show(this.$t('toast.failedForm'), { type: 'error' });
          return;
        }
      }

      try {
        this.form.pets = this.form.pets.map(item => {
          return { petTypeId: item };
        });

        const payload = {
          businessId: this.business.id,
          data: this.form,
        };
        await this.$store.dispatch(OWNER_ADD, payload);

        if (this.ownerStatus === 'success') {
          this.$toasted.show(this.$t('toast.success'), { type: 'success' });
          this.$emit('onOwnerAdd', this.ownerLastCreated);
          this.hideEditModal();
          this.resetForm();
        }
      } catch (error) {
        this.$toasted.show(error.message, { type: 'error' });
      }
    },
  },
};
</script>
