<template>
  <TableRegular
    :loading="loading"
    :downloading="downloading"
    :rows="rows"
    :columns="columns"
    :sort="sort"
    :page="page"
    :per-page="perPage"
    :page-size="pageSize"
    :total-page="totalPage"
    :line-numbers="lineNumbers"
    :downloadable="downloadable && checkPermission('finance.income.download')"
    :custom-filters="customFilters"
    @onFilter="$emit('onFilter', $event)"
    @onPageChange="$emit('onPageChange', $event)"
    @onPerPageChange="$emit('onPerPageChange', $event)"
    @onSortChange="$emit('onSortChange', $event)"
    @onDownload="$emit('onDownload')"
    @onChangeCustomFilter="$emit('onChangeCustomFilter', $event)"
    @onSuccessFilter="$emit('onSuccessFilter', $event)"
  >
    <template slot="table-row" slot-scope="props">
      <p v-if="props.column.field === 'building'" class="font-medium whitespace-nowrap">
        <template v-if="props.row.apartment && props.row.apartment.building">
          <BuildingFullname :item="props.row.apartment.building" />
        </template>
      </p>
      <p v-else-if="props.column.field === 'number'" class="font-medium whitespace-nowrap">
        {{ props.row.apartment && props.row.apartment.number }}
      </p>
      <p v-else-if="props.column.field === 'owners'" class="font-medium whitespace-nowrap">
        <template v-if="props.row.apartment">
          <PeopleFullname
            v-for="(owner, index) in props.row.apartment.owners"
            :key="index"
            :item="owner"
          />
        </template>
      </p>
      <p v-else-if="props.column.field === 'amount'" class="font-medium whitespace-nowrap">
        {{ props.row.total | currency }}
      </p>
      <p v-else-if="props.column.field === 'paidAmount'" class="font-medium whitespace-nowrap">
        {{ props.row.paidAmount | currency }}
      </p>
      <p v-else-if="props.column.field === 'balance'" class="font-medium whitespace-nowrap">
        {{ $options.filters.currency(props.row.total - props.row.paidAmount) }}
      </p>
      <p v-else-if="props.column.field === 'startDate'" class="whitespace-nowrap">
        {{ props.row.startDate.slice(0, 7) }} {{ $t('table.month') }}
      </p>
      <p v-else-if="props.column.field === 'paidDate'" class="whitespace-nowrap">
        {{ props.row.paidDate }}
      </p>
      <div v-else-if="props.column.field === 'status'" class="whitespace-nowrap">
        <div v-if="props.row.status" :class="['status', `is-${props.row.status.toLowerCase()}`]">
          {{ $t(`status.${props.row.status.toLowerCase()}`) }}
        </div>
      </div>
    </template>
    <template #footer>
      <tr v-if="!loading && rows.length > 0 && footerItems">
        <th v-if="lineNumbers"></th>
        <th></th>
        <th></th>
        <th></th>
        <th>{{ $t('text.totalAmount') }}:</th>
        <th>
          <template v-if="footerItems.hasOwnProperty('totalAmount')">
            {{ footerItems.totalAmount | currency }}
          </template>
        </th>
        <th>
          <template v-if="footerItems.hasOwnProperty('totalPaidAmount')">
            {{ footerItems.totalPaidAmount | currency }}
          </template>
        </th>
        <th>
          <template v-if="footerItems.hasOwnProperty('totalBalance')">
            {{ footerItems.totalBalance | currency }}
          </template>
        </th>
      </tr>
    </template>
  </TableRegular>
</template>

<script>
import TableRegular from '@/components/ui/table/TableRegular';
import PeopleFullname from '@/components/people/PeopleFullname';
import BuildingFullname from '@/components/building/BuildingFullname';
import tableMixin from '@/mixins/tableMixin';

export default {
  mixins: [tableMixin],
  props: { footerItems: Object },
  components: { TableRegular, PeopleFullname, BuildingFullname },
  data() {
    return {
      paidAmountStatus: [
        {
          id: 'PAID',
          name: this.$t('status.paid'),
        },
      ],
      paidStatus: {
        status: '',
        amount: '',
        id: '',
      },
    };
  },
};
</script>
