import {
  BANKS_LIST_REQUEST,
  BANKS_LIST_REQUEST_ERROR,
  BANKS_LIST_REQUEST_SUCCESS,
} from '@/store/actions/banks';
import { handleRequestError } from '@/utils/error';

import Repository, { BANKS } from '@/repositories/RepositoryFactory';

const BanksRepository = Repository.get(BANKS);

const state = {
  banksList: [],
  banksMeta: '',
  banksStatus: '',
};

const getters = {
  banksList: state => state.banksList,
  banksMeta: state => state.banksMeta,
  banksStatus: state => state.banksStatus,
};

const actions = {
  [BANKS_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(BANKS_LIST_REQUEST);
    try {
      const response = await BanksRepository.get(payload.data);
      commit(BANKS_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(BANKS_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
};

const mutations = {
  [BANKS_LIST_REQUEST]: state => {
    state.banksStatus = 'loading';
  },
  [BANKS_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.banksStatus = 'success';
    state.banksList = payload.data;
    state.banksMeta = payload.meta;
  },
  [BANKS_LIST_REQUEST_ERROR]: state => {
    state.banksStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
