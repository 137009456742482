import { isNil } from 'lodash';

export default {
  props: {
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: [Object, String, Number, Array, Boolean, Date],
    errors: Array,
    vuelidate: Object,
    invalidFeedback: String,
    helpText: {
      type: String,
      default: '',
    },
    isTextarea: {
      type: Boolean,
      default: false,
    },
    inputClass: String,
    maxLength: Number,
  },

  data() {
    return {};
  },

  computed: {
    hasError() {
      return isNil(this.vuelidate) ? false : this.vuelidate.$error;
    },
  },

  methods: {
    onChange($event) {
      this.$emit('input', $event.target.value);
    },
    onKeydownEnter($event) {
      this.$emit('keydownenter', $event);
    },
    onFocusout($event) {
      this.$emit('focusout', $event);
    },
  },
};
