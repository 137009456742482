<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center">
      <PageHeader class="text-lg font-medium mr-auto" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <router-link
          :to="{ name: 'ServiceCreate' }"
          class="button text-white bg-theme-1 shadow-md mr-2"
        >
          {{ $t('text.create') }}
        </router-link>
      </div>
    </div>
    <div class="intro-y mt-5 overflow-auto lg:overflow-visible">
      <ServicesTable
        :loading="isLoading"
        :rows="serviceList"
        :columns="columns"
        :line-numbers="true"
        :sort="sort"
        :page="page"
        :per-page="perPage"
        :page-size="pageSize"
        :total-page="totalPage"
        :footerItems="footerItems"
        :custom-filters="customFilters"
        @delete="confirmRemoval"
        @show="onShowModal($event)"
        @pay="onShowModal($event, 'pay')"
        @onFilter="onFilter"
        @onPageChange="onPageChange"
        @onPerPageChange="onPerPageChange"
        @onSortChange="onSortChange"
        @onDownload="onDownload"
        @onChangeCustomFilter="onChangeCustomFilter"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SERVICE_LIST_REQUEST } from '@/store/actions/service';
import dataListMixin from '@/mixins/dataListMixin';
import PageHeader from '@/components/ui/PageHeader';
import ServicesTable from '@/components/services/ServicesTable';

export default {
  mixins: [dataListMixin],

  components: {
    PageHeader,
    ServicesTable,
  },

  data() {
    return {
      storeActionListRequest: SERVICE_LIST_REQUEST,
      columns: [
        { label: this.$t('table.name'), field: 'name', filterable: 'true' },
        { label: this.$t('table.type'), field: 'type', filterable: 'true' },
        { label: this.$t('table.price'), field: 'price' },
        { label: this.$t('table.importerId'), field: 'importerId', filterable: 'true' },
        { label: this.$t('table.action'), field: 'action' },
      ],
      customFilters: [
        {
          key: 'type',
          value: null,
          label: this.$t('text.type'),
          type: 'select',
          options: [
            {
              id: 'PRODUCT',
              name: this.$t('text.product'),
            },
            {
              id: 'SERVICE',
              name: this.$t('text.service'),
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['serviceList', 'serviceListMeta', 'serviceListStatus']),
    isLoading() {
      return this.serviceListStatus === 'loading';
    },
    footerItems() {
      return this.serviceListMeta
        ? {
            totalAmount: this.serviceListMeta.totalAmount,
            total: this.serviceListMeta.total,
          }
        : {};
    },
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    onChangeCustomFilter(object) {
      const index = this.customFilters.findIndex(item => item.key === object.key);
      if (index > -1) {
        const { value } = object;
        this.customFilters[index].value = value || null;
        this.fetchData();
      }
    },
    setFilterValues(data) {
      this.customFilters.forEach(item => {
        data.filters[`filter[${item.key}]`] = item.value;
      });
    },
    setPaginationValues() {
      this.totalRecords = this.serviceListMeta ? this.serviceListMeta.total : 1;
      this.page = this.serviceListMeta.currentPage;
      this.totalPage = this.serviceListMeta.lastPage;
    },
  },
};
</script>
