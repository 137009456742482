<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-5">
      <PageHeader class="text-lg font-medium mr-auto" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <router-link
          v-if="checkPermission('registration.apartment.create')"
          :to="{
            name: 'ApartmentCreate',
          }"
          class="button text-white bg-theme-1 shadow-md mr-2"
        >
          {{ $t('text.create') }}
        </router-link>
        <DropDownButtonList
          v-if="checkPermission('registration.apartment.upload')"
          :id="dropdownId"
          class="ml-auto sm:ml-0"
          toggler-class="button px-2 box text-gray-700 dark:text-gray-300"
          box-class="w-40"
          content-class="dark:bg-dark-1"
        >
          <!-- <a @click="onUpload"><UploadIcon /> {{ $t('file.excel') }}</a> -->
          <router-link
            :to="{
              name: 'BusinessSetup',
              query: { type: 'OWNER', building_id: $route.params.buildingId },
            }"
          >
            <UploadIcon /> {{ $t('file.excel') }}
          </router-link>
        </DropDownButtonList>
      </div>
    </div>
    <div class="intro-y mt-5 overflow-auto lg:overflow-visible">
      <ApartmentTable
        :loading="isLoading"
        :rows="apartmentList"
        :columns="columns"
        :line-numbers="true"
        :sort="sort"
        :page="page"
        :per-page="perPage"
        :page-size="pageSize"
        :total-page="totalPage"
        @delete="confirmRemoval"
        @onFilter="onFilter"
        @onPageChange="onPageChange"
        @onPerPageChange="onPerPageChange"
        @onSortChange="onSortChange"
      />
    </div>

    <BuildingApartmentAndOwnerUploadModal
      :ref="uploadRefName"
      :title="$route.name === 'Parkings' ? $t('nav.parkingUpload') : $t('nav.apartmentUpload')"
      :business="business"
      :modal-id="uploadModalId"
      @onImportSuccess="fetchData"
    />

    <ConfirmDialog
      :modal-id="deleteModalId"
      :is-loading="isDeleting ? true : false"
      @ok="onDelete"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { APARTMENT_LIST_REQUEST, APARTMENT_REMOVE } from '@/store/actions/apartment';
import PageHeader from '@/components/ui/PageHeader';
import ApartmentTable from '@/components/apartment/ApartmentTable';
import BuildingApartmentAndOwnerUploadModal from '@/components/building/BuildingApartmentAndOwnerUploadModal';
import dataListMixin from '@/mixins/dataListMixin';

export default {
  mixins: [dataListMixin],

  components: {
    PageHeader,
    ApartmentTable,
    BuildingApartmentAndOwnerUploadModal,
  },

  data() {
    return {
      storeActionListRequest: APARTMENT_LIST_REQUEST,
      storeActionRemove: APARTMENT_REMOVE,
      uploadModalId: 'upload-modal-apartments',
      uploadRefName: 'uploadModalApartments',
      dropdownId: 'dropdown-apartment',
      columns: [
        {
          label: this.$t('table.code'),
          field: 'code',
          sortable: true,
          filterable: true,
          disabled: this.$route.name === 'Parkings',
        },
        {
          label:
            this.$route.name === 'Parkings'
              ? this.$t('table.parkingNumber')
              : this.$t('table.doorNumber'),
          field: 'number',
          sortable: true,
          filterable: true,
        },
        {
          label: this.$t('table.squareSize'),
          field: 'square',
          sortable: true,
          filterable: true,
          disabled: this.$route.name === 'Parkings',
        },
        {
          label: this.$t('table.garageFee'),
          field: 'garageFee',
          disabled: this.$route.name === 'Apartments',
        },
        {
          label: this.$t('table.holders'),
          field: 'firstName',
          filterable: true,
        },
        {
          label: this.$t('table.action'),
          field: 'action',
        },
      ],
    };
  },

  computed: {
    ...mapGetters(['apartmentList', 'apartmentMeta', 'apartmentListStatus']),
    isLoading() {
      return this.apartmentListStatus === 'loading';
    },
  },

  created() {
    this.fetchData();
  },

  watch: {
    $route(value) {
      this.onPageChange(1);
      if (value.name === 'Parkings') {
        this.columns = [
          {
            label: this.$t('table.parkingNumber'),
            field: 'number',
            sortable: true,
            filterable: true,
          },
          {
            label: this.$t('table.garageFee'),
            field: 'garageFee',
          },
          {
            label: this.$t('table.holders'),
            filterable: true,
            field: 'firstName',
          },
          {
            label: this.$t('table.action'),
            field: 'action',
          },
        ];
      } else {
        this.columns = [
          {
            label: this.$t('table.code'),
            field: 'code',
            sortable: true,
            filterable: true,
          },
          {
            label: this.$t('table.doorNumber'),
            field: 'number',
            sortable: true,
            filterable: true,
          },
          {
            label: this.$t('table.squareSize'),
            field: 'square',
            sortable: true,
            filterable: true,
          },
          {
            label: this.$t('table.garageFee'),
            field: 'garageFee',
            disabled: true,
          },
          {
            label: this.$t('table.holders'),
            field: 'firstName',
          },
          {
            label: this.$t('table.action'),
            field: 'action',
          },
        ];
      }
    },
  },

  methods: {
    setFilterValues(data) {
      data.filters['filter[buildingId]'] = this.$route.params.buildingId;
      data.filters['filter[isParking]'] = 0;
    },

    setPaginationValues() {
      this.totalRecords = this.apartmentMeta ? this.apartmentMeta.total : 1;
      this.page = this.apartmentMeta.currentPage;
      this.totalPage = this.apartmentMeta.lastPage;
    },
  },
};
</script>
