import { findIndex } from 'lodash';
import {
  EXPENSE_LIST_REQUEST,
  EXPENSE_LIST_REQUEST_ERROR,
  EXPENSE_LIST_REQUEST_SUCCESS,
  EXPENSE_SEARCH,
  EXPENSE_SEARCH_ERROR,
  EXPENSE_SEARCH_SUCCESS,
  EXPENSE_SEARCH_LIST_CLEAR,
  EXPENSE_ADD,
  EXPENSE_ADD_ERROR,
  EXPENSE_ADD_SUCCESS,
  EXPENSE_REQUEST,
  EXPENSE_REQUEST_SUCCESS,
  EXPENSE_REQUEST_ERROR,
  EXPENSE_REMOVE,
  EXPENSE_REMOVE_ERROR,
  EXPENSE_REMOVE_SUCCESS,
  EXPENSE_UPDATE,
  EXPENSE_UPDATE_ERROR,
  EXPENSE_UPDATE_SUCCESS,
  EXPENSE_LIST_EXPORT,
  EXPENSE_LIST_EXPORT_SUCCESS,
  EXPENSE_LIST_EXPORT_ERROR,
} from '@/store/actions/expense';
import { handleRequestError } from '@/utils/error';

import Repository, { EXPENSES } from '@/repositories/RepositoryFactory';

const ExpensesRepository = Repository.get(EXPENSES);

const state = {
  expense: '',
  expenseList: [],
  expenseLastCreated: '',
  expenseMeta: '',
  expenseStatus: '',
  expenseExportStatus: '',
  expenseExportLink: '',
  expenseSearchList: '',
  expenseSearchStatus: '',
};

const getters = {
  expense: state => state.expense,
  expenseList: state => state.expenseList,
  expenseLastCreated: state => state.expenseLastCreated,
  expenseMeta: state => state.expenseMeta,
  expenseStatus: state => state.expenseStatus,
  expenseExportStatus: state => state.expenseExportStatus,
  expenseExportLink: state => state.expenseExportLink,
  expenseSearchList: state => state.expenseSearchList,
  expenseSearchStatus: state => state.expenseSearchStatus,
};

const actions = {
  [EXPENSE_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(EXPENSE_LIST_REQUEST);
    try {
      const response = await ExpensesRepository.get(payload.businessId, payload.data);
      commit(EXPENSE_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(EXPENSE_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },

  [EXPENSE_SEARCH]: async ({ commit }, payload) => {
    commit(EXPENSE_SEARCH);
    try {
      let items = [];
      if (payload.query) {
        const response = await ExpensesRepository.get(payload.businessId, payload.data);
        items = response.data.data;
      }

      commit(EXPENSE_SEARCH_SUCCESS, items);
    } catch (error) {
      commit(EXPENSE_SEARCH_ERROR);
      handleRequestError(error);
    }
  },

  [EXPENSE_SEARCH_LIST_CLEAR]: async ({ commit }) => {
    commit(EXPENSE_SEARCH_LIST_CLEAR);
  },

  [EXPENSE_REQUEST]: async ({ commit }, payload) => {
    commit(EXPENSE_REQUEST);
    try {
      const response = await ExpensesRepository.getSelf(payload.businessId, payload.id);
      const { data } = response;
      commit(EXPENSE_REQUEST_SUCCESS, data);
    } catch (error) {
      commit(EXPENSE_REQUEST_ERROR);
      handleRequestError(error);
    }
  },

  [EXPENSE_ADD]: async ({ commit }, payload) => {
    commit(EXPENSE_ADD);
    try {
      const response = await ExpensesRepository.create(payload.businessId, payload.data);
      commit(EXPENSE_ADD_SUCCESS, response.data.data);
    } catch (error) {
      commit(EXPENSE_ADD_ERROR);
      handleRequestError(error);
    }
  },

  [EXPENSE_UPDATE]: async ({ commit }, payload) => {
    commit(EXPENSE_UPDATE);
    try {
      const response = await ExpensesRepository.update(
        payload.businessId,
        payload.id,
        payload.data
      );
      commit(EXPENSE_UPDATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(EXPENSE_UPDATE_ERROR);
    }
  },

  [EXPENSE_REMOVE]: async ({ commit }, payload) => {
    commit(EXPENSE_REMOVE);
    try {
      await ExpensesRepository.delete(payload.businessId, payload.id);
      commit(EXPENSE_REMOVE_SUCCESS, payload.id);
    } catch (error) {
      commit(EXPENSE_REMOVE_ERROR);
      handleRequestError(error);
    }
  },

  [EXPENSE_LIST_EXPORT]: async ({ commit }, payload) => {
    commit(EXPENSE_LIST_EXPORT);
    try {
      const response = await ExpensesRepository.export(payload.businessId, payload.data);
      commit(EXPENSE_LIST_EXPORT_SUCCESS, response.data.data);
    } catch (error) {
      commit(EXPENSE_LIST_EXPORT_ERROR);
      handleRequestError(error);
    }
  },
};

const mutations = {
  [EXPENSE_LIST_REQUEST]: state => {
    state.expenseStatus = 'loading';
  },
  [EXPENSE_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.expenseStatus = 'success';
    state.expenseList = payload.data;
    state.expenseMeta = payload.meta;
  },
  [EXPENSE_LIST_REQUEST_ERROR]: state => {
    state.expenseStatus = 'error';
  },

  [EXPENSE_SEARCH]: state => {
    state.expenseSearchStatus = 'loading';
  },
  [EXPENSE_SEARCH_SUCCESS]: (state, payload) => {
    state.expenseSearchStatus = 'success';
    state.expenseSearchList = payload;
  },
  [EXPENSE_SEARCH_ERROR]: state => {
    state.expenseSearchStatus = 'error';
  },

  [EXPENSE_SEARCH_LIST_CLEAR]: state => {
    state.expenseSearchList = [];
  },

  [EXPENSE_ADD]: state => {
    state.expenseStatus = 'loading';
    state.expenseLastCreated = '';
  },
  [EXPENSE_ADD_SUCCESS]: (state, payload) => {
    state.expenseStatus = 'success';
    state.expenseLastCreated = payload;
    state.expenseList.push(payload);
  },
  [EXPENSE_ADD_ERROR]: state => {
    state.expenseStatus = 'error';
  },

  [EXPENSE_LIST_EXPORT]: state => {
    state.expenseExportStatus = 'loading';
    state.expenseExportLink = '';
  },
  [EXPENSE_LIST_EXPORT_SUCCESS]: (state, payload) => {
    state.expenseExportStatus = 'success';
    state.expenseExportLink = payload;
  },
  [EXPENSE_LIST_EXPORT_ERROR]: state => {
    state.expenseExportStatus = 'error';
  },

  [EXPENSE_REQUEST]: state => {
    state.expenseStatus = 'loading';
  },
  [EXPENSE_REQUEST_SUCCESS]: (state, payload) => {
    state.expenseStatus = 'success';
    state.expense = payload.data;
  },
  [EXPENSE_REQUEST_ERROR]: state => {
    state.expenseStatus = 'error';
  },

  [EXPENSE_UPDATE]: state => {
    state.expenseStatus = 'loading';
  },
  [EXPENSE_UPDATE_SUCCESS]: (state, payload) => {
    state.expenseStatus = 'success';
    if (payload) {
      const index = findIndex(state.expenseList, { id: payload.id });
      state.expenseList[index] = payload;
    }
  },
  [EXPENSE_UPDATE_ERROR]: state => {
    state.expenseStatus = 'error';
  },

  [EXPENSE_REMOVE]: state => {
    state.expenseStatus = 'deleting';
  },

  [EXPENSE_REMOVE_SUCCESS]: (state, payload) => {
    const index = findIndex(state.expenseList, { id: payload });
    state.expenseList.splice(index, 1);
  },

  [EXPENSE_REMOVE_ERROR]: state => {
    state.expenseStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
