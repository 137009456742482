<template>
  <div class="content">
    <TopBar />
    <router-view :business="me.business"></router-view>
  </div>
</template>

<script>
import TopBar from '@/views/layouts/TopBar';
import meMixin from '@/mixins/meMixin';

export default {
  name: 'Content',
  components: { TopBar },
  mixins: [meMixin],
};
</script>
