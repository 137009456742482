<template>
  <div>
    <p class="text-xl font-medium text-gray-600 mb-4">{{ title }}</p>
    <form @submit.prevent="onSubmit">
      <div class="intro-x">
        <InputString
          type="password"
          :placeholder="$t('auth.password')"
          v-model.trim="$v.formData.password.$model"
          :vuelidate="$v.formData.password"
          input-class="input-register-password login__input input--lg"
        />

        <InputString
          type="password"
          :placeholder="$t('auth.passwordConfirmation')"
          v-model.trim="$v.formData.passwordConfirmation.$model"
          :vuelidate="$v.formData.passwordConfirmation"
          input-class="input-register-password-confirmation login__input input--lg"
        />
      </div>
      <div class="intro-x mt-5 xl:mt-8">
        <Button type="submit" class="button--lg w-full text-white bg-theme-1" :loading="isLoading">
          <span>{{ $t('auth.continue') }}</span>
        </Button>
      </div>
      <div class="intro-x mt-3 xl:mt-5">
        <Button
          :to="{ name: 'UserLogin' }"
          class="button--lg w-full xl:w-32 text-gray-700 border border-gray-300 dark:border-dark-5 dark:text-gray-300"
        >
          <span>{{ $t('auth.login') }}</span>
        </Button>
      </div>
    </form>
  </div>
</template>

<script>
import InputString from '@/components/ui/input/InputString';
import Button from '@/components/ui/button/Button';
import { validationMixin } from 'vuelidate';
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
import stepMixin from '@/mixins/stepMixin';

export default {
  components: { Button, InputString },

  mixins: [validationMixin, stepMixin],

  validations: {
    formData: {
      password: {
        required,
        minLength: minLength(6),
      },
      passwordConfirmation: {
        required,
        minLength: minLength(6),
        sameAs: sameAs('password'),
      },
    },
  },
};
</script>
