export const SERVICE_PRODUCT_LIST_REQUEST = 'SERVICE_PRODUCT_LIST_REQUEST';
export const SERVICE_PRODUCT_LIST_REQUEST_SUCCESS = 'SERVICE_PRODUCT_LIST_REQUEST_SUCCESS';
export const SERVICE_PRODUCT_LIST_REQUEST_ERROR = 'SERVICE_PRODUCT_LIST_REQUEST_ERROR';

export const SERVICE_PRODUCT_SEARCH = 'SERVICE_PRODUCT_SEARCH';
export const SERVICE_PRODUCT_SEARCH_SUCCESS = 'SERVICE_PRODUCT_SEARCH_SUCCESS';
export const SERVICE_PRODUCT_SEARCH_ERROR = 'SERVICE_PRODUCT_SEARCH_ERROR';
export const SERVICE_PRODUCT_SEARCH_LIST_CLEAR = 'SERVICE_PRODUCT_SEARCH_LIST_CLEAR';

export const SERVICE_PRODUCT_ADD = 'SERVICE_PRODUCT_ADD';
export const SERVICE_PRODUCT_ADD_SUCCESS = 'SERVICE_PRODUCT_ADD_SUCCESS';
export const SERVICE_PRODUCT_ADD_ERROR = 'SERVICE_PRODUCT_ADD_ERROR';

export const SERVICE_PRODUCT_UPDATE = 'SERVICE_PRODUCT_UPDATE';
export const SERVICE_PRODUCT_UPDATE_SUCCESS = 'SERVICE_PRODUCT_UPDATE_SUCCESS';
export const SERVICE_PRODUCT_UPDATE_ERROR = 'SERVICE_PRODUCT_UPDATE_ERROR';

export const SERVICE_PRODUCT_REMOVE = 'SERVICE_PRODUCT_REMOVE';
export const SERVICE_PRODUCT_REMOVE_SUCCESS = 'SERVICE_PRODUCT_REMOVE_SUCCESS';
export const SERVICE_PRODUCT_REMOVE_ERROR = 'SERVICE_PRODUCT_REMOVE_ERROR';

export const SERVICE_PRODUCT_REQUEST = 'SERVICE_PRODUCT_REQUEST';
export const SERVICE_PRODUCT_REQUEST_SUCCESS = 'SERVICE_PRODUCT_REQUEST_SUCCESS';
export const SERVICE_PRODUCT_REQUEST_ERROR = 'SERVICE_PRODUCT_REQUEST_ERROR';

export const SERVICE_PRODUCT_LIST_IMPORT = 'SERVICE_PRODUCT_LIST_IMPORT';
export const SERVICE_PRODUCT_LIST_IMPORT_SUCCESS = 'SERVICE_PRODUCT_LIST_IMPORT_SUCCESS';
export const SERVICE_PRODUCT_LIST_IMPORT_ERROR = 'SERVICE_PRODUCT_LIST_IMPORT_ERROR';

export const SERVICE_PRODUCT_LIST_EXPORT = 'SERVICE_PRODUCT_LIST_EXPORT';
export const SERVICE_PRODUCT_LIST_EXPORT_SUCCESS = 'SERVICE_PRODUCT_LIST_EXPORT_SUCCESS';
export const SERVICE_PRODUCT_LIST_EXPORT_ERROR = 'SERVICE_PRODUCT_LIST_EXPORT_ERROR';

export const STATE_BANK_SERVICE_LIST_REQUEST = 'STATE_BANK_SERVICE_LIST_REQUEST';
export const STATE_BANK_SERVICE_LIST_REQUEST_SUCCESS = 'STATE_BANK_SERVICE_LIST_REQUEST_SUCCESS';
export const STATE_BANK_SERVICE_LIST_REQUEST_ERROR = 'STATE_BANK_SERVICE_LIST_REQUEST_ERROR';
