import Index from '@/views/ActivityReports/Index';
import ActivityReports from '@/views/ActivityReports/ActivityReports';
import ActivityReportForm from '@/views/ActivityReports/ActivityReportForm';

export default [
  {
    path: '/activity-reports',
    component: Index,
    meta: { group: 'activity_reports' },
    children: [
      {
        path: '',
        name: 'ActivityReports',
        component: ActivityReports,
      },
      {
        path: 'create',
        name: 'ActivityReportCreate',
        component: ActivityReportForm,
      },
      {
        path: 'update/:id',
        name: 'ActivityReportUpdate',
        component: ActivityReportForm,
      },
    ],
  },
];
