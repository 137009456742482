export const BUSINESS_UPDATE = 'BUSINESS_UPDATE';
export const BUSINESS_UPDATE_SUCCESS = 'BUSINESS_UPDATE_SUCCESS';
export const BUSINESS_UPDATE_ERROR = 'BUSINESS_UPDATE_ERROR';

export const BUSINESS_LIST_REQUEST = 'BUSINESS_LIST_REQUEST';
export const BUSINESS_LIST_REQUEST_SUCCESS = 'BUSINESS_LIST_REQUEST_SUCCESS';
export const BUSINESS_LIST_REQUEST_ERROR = 'BUSINESS_LIST_REQUEST_ERROR';
export const BUSINESS_BACK_ACCOUNT = 'BUSINESS_BACK_ACCOUNT';

export const BUSINESS_BANK_LIST = 'BUSINESS_BANK_LIST';
export const BUSINESS_BANK_LIST_SUCCESS = 'BUSINESS_BANK_LIST_SUCCESS';
export const BUSINESS_BANK_LIST_ERROR = 'BUSINESS_BANK_LIST_ERROR';

export const BUSINESS_BANK_CREATE = 'BUSINESS_BANK_CREATE';
export const BUSINESS_BANK_CREATE_SUCCESS = 'BUSINESS_BANK_CREATE_SUCCESS';
export const BUSINESS_BANK_CREATE_ERROR = 'BUSINESS_BANK_CREATE_ERROR';

export const BUSINESS_BANK_UPDATE = 'BUSINESS_BANK_UPDATE';
export const BUSINESS_BANK_UPDATE_SUCCESS = 'BUSINESS_BANK_UPDATE_SUCCESS';
export const BUSINESS_BANK_UPDATE_ERROR = 'BUSINESS_BANK_UPDATE_ERROR';

export const BUSINESS_EXCEL_IMPORT = 'BUSINESS_EXCEL_IMPORT';
export const BUSINESS_EXCEL_IMPORT_SUCCESS = 'BUSINESS_EXCEL_IMPORT_SUCCESS';
export const BUSINESS_EXCEL_IMPORT_ERROR = 'BUSINESS_EXCEL_IMPORT_ERROR';

export const BUSINESS_SEND_MAIL_TO_STATE_BANK = 'BUSINESS_SEND_MAIL_TO_STATE_BANK';
export const BUSINESS_SEND_MAIL_TO_STATE_BANK_SUCCESS = 'BUSINESS_SEND_MAIL_TO_STATE_BANK_SUCCESS';
export const BUSINESS_SEND_MAIL_TO_STATE_BANK_ERROR = 'BUSINESS_SEND_MAIL_TO_STATE_BANK_ERROR';

export const BUSINESS_SEND_INVOICE_TO_STATE_BANK = 'BUSINESS_SEND_INVOICE_TO_STATE_BANK';
export const BUSINESS_SEND_INVOICE_TO_STATE_BANK_SUCCESS =
  'BUSINESS_SEND_INVOICE_TO_STATE_BANK_SUCCESS';
export const BUSINESS_SEND_INVOICE_TO_STATE_BANK_ERROR =
  'BUSINESS_SEND_INVOICE_TO_STATE_BANK_ERROR';
