<template>
  <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
    <div class="report-box zoom-in">
      <div class="box p-5">
        <div class="flex">
          <component :is="icon" :class="string" />
          <div v-if="filter" class="flex-1 text-2xl font-bold leading-8 text-right w-full">
            {{ data | currency }}
          </div>
          <div v-else class="flex-2 text-2xl font-bold leading-8 text-right w-full">
            {{ data }}
          </div>
        </div>
        <div class="text-base text-gray-600 mt-1 text-right">
          {{ label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {},
    label: String,
    icon: String,
    filter: Boolean,
    string: String,
  },
};
</script>
