<template>
  <TableRegular
    :loading="loading"
    :downloading="downloading"
    :rows="rows"
    :columns="columns"
    :sort="sort"
    :page="page"
    :per-page="perPage"
    :page-size="pageSize"
    :total-page="totalPage"
    :line-numbers="lineNumbers"
    :downloadable="false"
    @onFilter="$emit('onFilter', $event)"
    @onPageChange="$emit('onPageChange', $event)"
    @onPerPageChange="$emit('onPerPageChange', $event)"
    @onSortChange="$emit('onSortChange', $event)"
    @onDownload="$emit('onDownload')"
    @onChangeCustomFilter="$emit('onChangeCustomFilter', $event)"
  >
    <template slot="table-row" slot-scope="props">
      <p v-if="props.column.field === 'action'" class="flex justify-end items-center">
        <a
          href="javascript:"
          @click="$emit('changePassword', props.row.id)"
          class="flex items-center"
        >
          <KeyIcon class="w-4 h-4 mr-1" />
          <span class="whitespace-nowrap">{{ $t('table.password') }}</span>
        </a>
        <!-- <a
          href="javascript:"
          @click="$emit('delete', props.row.id)"
          class="flex items-center text-theme-6"
        >
          <Trash2Icon class="w-4 h-4 mr-1" />
          {{ $t('table.delete') }}
        </a> -->
      </p>
    </template>
  </TableRegular>
</template>

<script>
import TableRegular from '@/components/ui/table/TableRegular';
import tableMixin from '@/mixins/tableMixin';
import moment from 'moment';

export default {
  mixins: [tableMixin],

  components: {
    TableRegular,
  },

  methods: {
    createdAt(value) {
      return moment(value).format('YY/MM/DD hh:mm');
    },
  },
};
</script>
