<template>
  <div class="mt-3">
    <div class="intro-y flex flex-col sm:flex-row items-center">
      <PageHeader class="text-lg font-medium mr-auto" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <router-link
          v-if="checkPermission('tickets.tickets.create')"
          :to="{ name: 'TicketCreate' }"
          class="button text-white bg-theme-1 shadow-md"
        >
          {{ $t('text.create') }}
        </router-link>
      </div>
    </div>
    <div class="intro-y mt-5 overflow-auto lg:overflow-visible">
      <TicketTable
        :loading="isLoading"
        :rows="ticketList"
        :columns="columns"
        :line-numbers="true"
        :sort="sort"
        :page="page"
        :per-page="perPage"
        :page-size="pageSize"
        :total-page="totalPage"
        :users="userList"
        :custom-filters="customFilters"
        @onChangeStatus="onChangeStatus"
        @delete="confirmRemoval"
        @onFilter="onFilter"
        @onPageChange="onPageChange"
        @onPerPageChange="onPerPageChange"
        @onSortChange="onSortChange"
        @onResetFilter="onResetFilter"
        @onChangeCustomFilter="onChangeCustomFilter"
      />
    </div>

    <ConfirmDialog
      :modal-id="deleteModalId"
      :is-loading="isDeleting ? true : false"
      @ok="onDelete"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  TICKET_LIST_REQUEST,
  TICKET_REMOVE,
  TICKET_STATUS_CHANGE,
  TICKET_CATEGORIES_REQUEST,
  TICKET_TYPES_REQUEST,
} from '@/store/actions/ticket';
import PageHeader from '@/components/ui/PageHeader';
import TicketTable from '@/components/ticket/TicketTable';
import dataListMixin from '@/mixins/dataListMixin';
import { USER_LIST_REQUEST } from '@/store/actions/user';

export default {
  mixins: [dataListMixin],

  components: { PageHeader, TicketTable },

  data() {
    return {
      storeActionListRequest: TICKET_LIST_REQUEST,
      storeActionRemove: TICKET_REMOVE,
      statusValue: 'PENDING',
      customFilters: [
        {
          key: 'ticketTypeId',
          value: null,
          label: this.$t('text.type'),
          type: 'select',
          options: [],
        },
        {
          key: 'ticketCategoryId',
          value: null,
          label: this.$t('text.category'),
          type: 'select',
          options: [],
        },
      ],
    };
  },

  computed: {
    ...mapGetters([
      'ticketList',
      'ticketListStatus',
      'ticketMeta',
      'userList',
      'userListStatus',
      'ticketChangingStatus',
      'ticketCategories',
      'ticketCategoriesStatus',
      'ticketTypes',
      'ticketTypesStatus',
    ]),
    isLoading() {
      return this.ticketListStatus === 'loading';
    },
    userListLoading() {
      return this.userListStatus === 'loading';
    },
    columns() {
      const defaultColumns = [
        { label: 'ID', field: 'id' },
        { label: this.$t('table.type'), field: 'ticketType' },
        { label: this.$t('table.category'), field: 'ticketCategory' },
        { label: this.$t('table.priority'), field: 'priority' },
        { label: this.$t('table.holders'), field: 'owner' },
        { label: this.$t('table.content'), field: 'description' },
        { label: this.$t('table.date'), field: 'ticketDate' },
        { label: this.$t('table.channel'), field: 'channel' },
        { label: this.$t('table.status'), field: 'status' },
      ];
      if (this.$route.name !== 'Tickets') {
        return [
          ...defaultColumns,
          { label: this.$t('table.assignedUser'), field: 'assignedUser' },
          { label: this.$t('table.action'), field: 'statusAction' },
        ];
      }
      return [
        ...defaultColumns,
        { label: this.$t('table.assign'), field: 'assign' },
        { label: this.$t('table.action'), field: 'action' },
      ];
    },
  },

  watch: {
    $route: {
      async handler() {
        await this.updateStatusValue();
        this.fetchAllData();
      },
    },
  },

  async created() {
    this.getTicketCategoriesAndTypes();
    await this.updateStatusValue();
    this.fetchAllData();
  },

  methods: {
    async getTicketCategoriesAndTypes() {
      await this.$store.dispatch(TICKET_TYPES_REQUEST);
      if (this.ticketTypesStatus === 'success') {
        this.customFilters[0].options = this.ticketTypes.map(item => item);
      }
      await this.$store.dispatch(TICKET_CATEGORIES_REQUEST);
      if (this.ticketCategoriesStatus === 'success') {
        this.customFilters[1].options = this.ticketCategories.map(item => item);
      }
    },
    updateStatusValue() {
      this.page = 1;
      this.customFilters[0].value = null;
      this.customFilters[1].value = null;
      if (this.$route.name === 'TicketSent') {
        this.statusValue = 'ASSIGNED';
      } else if (this.$route.name === 'TicketSeen') {
        this.statusValue = 'CONFIRMED';
      } else if (this.$route.name === 'TicketSolved') {
        this.statusValue = 'FINISHED';
      } else {
        this.statusValue = 'PENDING';
      }
    },
    async fetchAllData() {
      await this.fetchData();
      if (this.$route.name === 'Tickets') {
        this.fetchUserList();
      }
    },
    setPaginationValues() {
      this.totalRecords = this.ticketMeta ? this.ticketMeta.total : 1;
      this.page = this.ticketMeta.currentPage;
      this.totalPage = this.ticketMeta.lastPage;
    },
    onResetFilter() {
      this.filter = { field: '', value: '' };
      this.onFilter();
    },
    async fetchUserList() {
      const data = { page: 1, perPage: 100 };
      const payload = { businessId: this.business.id, data };
      await this.$store.dispatch(USER_LIST_REQUEST, payload);
    },
    onChangeCustomFilter(object) {
      const index = this.customFilters.findIndex(item => item.key === object.key);
      if (index > -1) {
        const { value } = object;
        this.customFilters[index].value = value || null;
        this.page = 1;
        this.fetchData();
      }
    },
    setFilterValues(data) {
      data.filters['filter[status]'] = this.statusValue;
      this.customFilters.forEach(item => {
        data.filters[`filter[${item.key}]`] = item.value;
      });
    },
    async onChangeStatus({ userId, status, ticketId }) {
      const data = {
        status,
        assignedTo: userId,
      };
      const payload = {
        businessId: this.business.id,
        id: ticketId,
        data,
      };
      await this.$store.dispatch(TICKET_STATUS_CHANGE, payload);
      if (this.ticketChangingStatus === 'success') {
        if (status === 'PENDING') {
          this.$router.push({ name: 'Tickets' });
        } else if (status === 'ASSIGNED') {
          this.$router.push({ name: 'TicketSent' });
        } else if (status === 'CONFIRMED') {
          this.$router.push({ name: 'TicketSeen' });
        } else if (status === 'FINISHED') {
          this.$router.push({ name: 'TicketSolved' });
        } else {
          this.fetchAllData();
        }
      }
    },
  },
};
</script>
