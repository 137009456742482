import { DARK_SWITCH_REQUEST, DARK_SWITCH_REQUEST_SUCCESS } from '@/store/actions/darkSwitcher';

const state = {
  darkMode: false,
};

const getters = {
  darkMode: state => state.darkMode,
};

const actions = {
  [DARK_SWITCH_REQUEST]: async ({ commit }, payload) => {
    commit(DARK_SWITCH_REQUEST_SUCCESS, payload);
  },
};

const mutations = {
  [DARK_SWITCH_REQUEST_SUCCESS]: (state, payload) => {
    state.darkMode = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
