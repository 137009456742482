import Index from '@/views/Polls/Index';
import Polls from '@/views/Polls/Polls';
import PollForm from '@/views/Polls/PollForm';

export default [
  {
    path: '/polls',
    component: Index,
    meta: { group: 'polls' },
    children: [
      {
        path: '',
        component: Polls,
        name: 'Polls',
      },
      {
        path: 'create',
        name: 'PollCreate',
        component: PollForm,
      },
      {
        path: 'update/:id',
        name: 'PollUpdate',
        component: PollForm,
      },
    ],
  },
];
