import store from '@/store';
import { USER_REQUEST } from '@/store/actions/user';
import { CITY_LIST_REQUEST } from '@/store/actions/localUnits';
import AuthTokenStorageService from '@/services/AuthTokenStorageService';
import { FIREBASE_MESSAGING_SUBSCRIBE } from '@/store/actions/firebase';

export const ifNotAuthenticated = async (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next('/');
};

// export const ifAuthenticated = async (to, from, next) => {
//   if (store.getters.isAuthenticated) {
//     if (!store.getters.isProfileLoaded) {
//       await store.dispatch(USER_REQUEST);
//     }
//     next();
//     return;
//   }

//   next({ name: 'UserLogin' });
//   // next();
// };

export const ifAuthenticated = async (to, from, next) => {
  if (store.getters.isProfileLoaded) {
    next();
  } else if (AuthTokenStorageService.getAccessToken()) {
    await store.dispatch(USER_REQUEST);
    await store.dispatch(CITY_LIST_REQUEST);
    if (process.env.NODE_ENV === 'production') {
      store.dispatch(FIREBASE_MESSAGING_SUBSCRIBE);
    }

    const parentRoute = to.matched[1];
    if (parentRoute.meta?.group && !(parentRoute.meta.group in store.state.user.userPermissions)) {
      next({ name: 'Dashboard' });
      return;
    }
    next();
  } else {
    next(`/login?redirect=${to.path}`);
  }
};
