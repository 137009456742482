import { findIndex } from 'lodash';
import {
  SERVICE_PRODUCT_LIST_REQUEST,
  SERVICE_PRODUCT_LIST_REQUEST_ERROR,
  SERVICE_PRODUCT_LIST_REQUEST_SUCCESS,
  SERVICE_PRODUCT_SEARCH,
  SERVICE_PRODUCT_SEARCH_ERROR,
  SERVICE_PRODUCT_SEARCH_SUCCESS,
  SERVICE_PRODUCT_SEARCH_LIST_CLEAR,
  SERVICE_PRODUCT_ADD,
  SERVICE_PRODUCT_ADD_ERROR,
  SERVICE_PRODUCT_ADD_SUCCESS,
  SERVICE_PRODUCT_REQUEST,
  SERVICE_PRODUCT_REQUEST_SUCCESS,
  SERVICE_PRODUCT_REQUEST_ERROR,
  SERVICE_PRODUCT_REMOVE,
  SERVICE_PRODUCT_REMOVE_ERROR,
  SERVICE_PRODUCT_REMOVE_SUCCESS,
  SERVICE_PRODUCT_UPDATE,
  SERVICE_PRODUCT_UPDATE_ERROR,
  SERVICE_PRODUCT_UPDATE_SUCCESS,
  SERVICE_PRODUCT_LIST_EXPORT,
  SERVICE_PRODUCT_LIST_EXPORT_SUCCESS,
  SERVICE_PRODUCT_LIST_EXPORT_ERROR,
  STATE_BANK_SERVICE_LIST_REQUEST,
  STATE_BANK_SERVICE_LIST_REQUEST_SUCCESS,
  STATE_BANK_SERVICE_LIST_REQUEST_ERROR,
} from '@/store/actions/serviceProduct';
import { handleRequestError } from '@/utils/error';

import Repository, { SERVICE_PRODUCTS } from '@/repositories/RepositoryFactory';

const ServiceProductsRepository = Repository.get(SERVICE_PRODUCTS);

const state = {
  serviceProduct: '',
  serviceProductList: [],
  serviceProductLastCreated: '',
  serviceProductMeta: '',
  serviceProductStatus: '',
  serviceProductExportStatus: '',
  serviceProductExportLink: '',
  serviceProductSearchList: '',
  serviceProductSearchStatus: '',
  stateBankServiceList: [],
  stateBankServiceListStatus: '',
};

const getters = {
  serviceProduct: state => state.serviceProduct,
  serviceProductList: state => state.serviceProductList,
  serviceProductLastCreated: state => state.serviceProductLastCreated,
  serviceProductMeta: state => state.serviceProductMeta,
  serviceProductStatus: state => state.serviceProductStatus,
  serviceProductExportStatus: state => state.serviceProductExportStatus,
  serviceProductExportLink: state => state.serviceProductExportLink,
  serviceProductSearchList: state => state.serviceProductSearchList,
  serviceProductSearchStatus: state => state.serviceProductSearchStatus,
  stateBankServiceList: state => state.stateBankServiceList,
  stateBankServiceListStatus: state => state.stateBankServiceListStatus,
};

const actions = {
  [SERVICE_PRODUCT_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(SERVICE_PRODUCT_LIST_REQUEST);
    try {
      const response = await ServiceProductsRepository.get(payload.businessId, payload.data);
      commit(SERVICE_PRODUCT_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(SERVICE_PRODUCT_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },

  [SERVICE_PRODUCT_SEARCH]: async ({ commit }, payload) => {
    commit(SERVICE_PRODUCT_SEARCH);
    try {
      let items = [];
      if (payload.query) {
        const response = await ServiceProductsRepository.get(payload.businessId, payload.data);
        items = response.data.data;
      }

      commit(SERVICE_PRODUCT_SEARCH_SUCCESS, items);
    } catch (error) {
      commit(SERVICE_PRODUCT_SEARCH_ERROR);
      handleRequestError(error);
    }
  },

  [SERVICE_PRODUCT_SEARCH_LIST_CLEAR]: async ({ commit }) => {
    commit(SERVICE_PRODUCT_SEARCH_LIST_CLEAR);
  },

  [SERVICE_PRODUCT_REQUEST]: async ({ commit }, payload) => {
    commit(SERVICE_PRODUCT_REQUEST);
    try {
      const response = await ServiceProductsRepository.getSelf(payload.businessId, payload.id);
      const { data } = response;
      commit(SERVICE_PRODUCT_REQUEST_SUCCESS, data);
    } catch (error) {
      commit(SERVICE_PRODUCT_REQUEST_ERROR);
      handleRequestError(error);
    }
  },

  [SERVICE_PRODUCT_ADD]: async ({ commit }, payload) => {
    commit(SERVICE_PRODUCT_ADD);
    try {
      const response = await ServiceProductsRepository.create(payload.businessId, payload.data);
      commit(SERVICE_PRODUCT_ADD_SUCCESS, response.data.data);
    } catch (error) {
      commit(SERVICE_PRODUCT_ADD_ERROR);
      handleRequestError(error);
    }
  },

  [SERVICE_PRODUCT_UPDATE]: async ({ commit }, payload) => {
    commit(SERVICE_PRODUCT_UPDATE);
    try {
      const response = await ServiceProductsRepository.update(
        payload.businessId,
        payload.id,
        payload.data
      );
      commit(SERVICE_PRODUCT_UPDATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(SERVICE_PRODUCT_UPDATE_ERROR);
      handleRequestError(error);
    }
  },

  [SERVICE_PRODUCT_REMOVE]: async ({ commit }, payload) => {
    commit(SERVICE_PRODUCT_REMOVE);
    try {
      await ServiceProductsRepository.delete(payload.businessId, payload.id);
      commit(SERVICE_PRODUCT_REMOVE_SUCCESS, payload.id);
    } catch (error) {
      commit(SERVICE_PRODUCT_REMOVE_ERROR);
      handleRequestError(error);
    }
  },

  [SERVICE_PRODUCT_LIST_EXPORT]: async ({ commit }, payload) => {
    commit(SERVICE_PRODUCT_LIST_EXPORT);
    try {
      const response = await ServiceProductsRepository.export(payload.businessId, payload.data);
      commit(SERVICE_PRODUCT_LIST_EXPORT_SUCCESS, response.data.data);
    } catch (error) {
      commit(SERVICE_PRODUCT_LIST_EXPORT_ERROR);
      handleRequestError(error);
    }
  },

  [STATE_BANK_SERVICE_LIST_REQUEST]: async ({ commit }) => {
    commit(STATE_BANK_SERVICE_LIST_REQUEST);
    try {
      const response = await ServiceProductsRepository.getStateBankServices();
      commit(STATE_BANK_SERVICE_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(STATE_BANK_SERVICE_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
};

const mutations = {
  [SERVICE_PRODUCT_LIST_REQUEST]: state => {
    state.serviceProductStatus = 'loading';
  },
  [SERVICE_PRODUCT_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.serviceProductStatus = 'success';
    state.serviceProductList = payload.data;
    state.serviceProductMeta = payload.meta;
  },
  [SERVICE_PRODUCT_LIST_REQUEST_ERROR]: state => {
    state.serviceProductStatus = 'error';
  },

  [SERVICE_PRODUCT_SEARCH]: state => {
    state.serviceProductSearchStatus = 'loading';
  },
  [SERVICE_PRODUCT_SEARCH_SUCCESS]: (state, payload) => {
    state.serviceProductSearchStatus = 'success';
    state.serviceProductSearchList = payload;
  },
  [SERVICE_PRODUCT_SEARCH_ERROR]: state => {
    state.serviceProductSearchStatus = 'error';
  },

  [SERVICE_PRODUCT_SEARCH_LIST_CLEAR]: state => {
    state.serviceProductSearchList = [];
  },

  [SERVICE_PRODUCT_ADD]: state => {
    state.serviceProductStatus = 'loading';
    state.serviceProductLastCreated = '';
  },
  [SERVICE_PRODUCT_ADD_SUCCESS]: (state, payload) => {
    state.serviceProductStatus = 'success';
    state.serviceProductLastCreated = payload;
    state.serviceProductList.push(payload);
  },
  [SERVICE_PRODUCT_ADD_ERROR]: state => {
    state.serviceProductStatus = 'error';
  },

  [SERVICE_PRODUCT_LIST_EXPORT]: state => {
    state.serviceProductExportStatus = 'loading';
    state.serviceProductExportLink = '';
  },
  [SERVICE_PRODUCT_LIST_EXPORT_SUCCESS]: (state, payload) => {
    state.serviceProductExportStatus = 'success';
    state.serviceProductExportLink = payload;
  },
  [SERVICE_PRODUCT_LIST_EXPORT_ERROR]: state => {
    state.serviceProductExportStatus = 'error';
  },

  [SERVICE_PRODUCT_REQUEST]: state => {
    state.serviceProductStatus = 'loading';
  },
  [SERVICE_PRODUCT_REQUEST_SUCCESS]: (state, payload) => {
    state.serviceProductStatus = 'success';
    state.serviceProduct = payload.data;
  },
  [SERVICE_PRODUCT_REQUEST_ERROR]: state => {
    state.serviceProductStatus = 'error';
  },

  [SERVICE_PRODUCT_UPDATE]: state => {
    state.serviceProductStatus = 'loading';
  },
  [SERVICE_PRODUCT_UPDATE_SUCCESS]: (state, payload) => {
    state.serviceProductStatus = 'success';
    const index = findIndex(state.serviceProductList, { id: payload.id });
    state.serviceProductList[index] = payload;
  },
  [SERVICE_PRODUCT_UPDATE_ERROR]: state => {
    state.serviceProductStatus = 'error';
  },

  [SERVICE_PRODUCT_REMOVE]: state => {
    state.serviceProductStatus = 'deleting';
  },

  [SERVICE_PRODUCT_REMOVE_SUCCESS]: (state, payload) => {
    const index = findIndex(state.serviceProductList, { id: payload });
    state.serviceProductList.splice(index, 1);
  },

  [SERVICE_PRODUCT_REMOVE_ERROR]: state => {
    state.serviceProductStatus = 'error';
  },

  [STATE_BANK_SERVICE_LIST_REQUEST]: state => {
    state.stateBankServiceListStatus = 'loading';
  },
  [STATE_BANK_SERVICE_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.stateBankServiceListStatus = 'success';
    state.stateBankServiceList = payload;
  },
  [STATE_BANK_SERVICE_LIST_REQUEST_ERROR]: state => {
    state.stateBankServiceListStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
