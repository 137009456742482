<template>
  <div :id="modalId" class="modal">
    <div class="modal__content">
      <div class="p-5 text-center">
        <xCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
        <div class="text-3xl mt-5">{{ $t('transactionDeleteConfirmDialog.title') }}</div>
        <div class="text-gray-600 mt-2">
          {{ $t('transactionDeleteConfirmDialog.description') }}
        </div>
      </div>
      <div class="px-5 pb-8 text-center">
        <Button
          type="button"
          @click="onCancel"
          class="w-24 border text-gray-700 dark:border-dark-5 dark:text-gray-300 mr-1"
        >
          {{ $t('text.cancel') }}
        </Button>
        <Button type="button" @click="onSubmit" class="w-30 bg-theme-6 text-white">
          {{ $t('text.delete') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/button/Button';

export default {
  name: 'SmsDialog',

  components: { Button },

  props: {
    modalId: {
      type: String,
      default: 'transaction-delete-modal',
    },
  },
  methods: {
    onCancel() {
      cash(`#${this.modalId}`).modal('hide');
    },

    onSubmit() {
      cash(`#${this.modalId}`).modal('hide');
      this.$emit('ok');
    },
  },
};
</script>
