<template>
  <ModalEdit :id="modalId" :title="title" :loading="isLoading" @click="onSubmit">
    <div v-if="invoice.apartment" class="p-5">
      <BuildingFullname v-if="invoice.apartment" :item="invoice.apartment.building" />
      <p v-if="invoice.apartment" class="mt-3">
        {{ $t('text.doorNumber') }}: {{ invoice.apartment.number }}
      </p>
      <p v-if="invoice.status != 'PAID'" class="mt-3">
        {{ $t('text.paymentAmount') }}: {{ (invoice.total - invoice.paidAmount) | currency }}
      </p>
      <p v-else class="mt-3">{{ $t('text.paymentAmount') }}: {{ invoice.total | currency }}</p>
    </div>
    <div class="p-5">
      <InputMoney
        :label="$t('text.amount')"
        v-model.trim="$v.form.amount.$model"
        :vuelidate="$v.form.amount"
        class="input-money-invoice-amount"
      />
    </div>
    <div class="mb-5 px-5">
      <div
        class="intro-y border border-1 dark:border-dark-4 w-full rounded dark:bg-dark-2 px-5 py-3"
      >
        <strong :class="amountColor">{{ differenceOfAmount | currency }}</strong>
      </div>
    </div>
  </ModalEdit>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, minValue } from 'vuelidate/lib/validators';
import ModalEdit from '@/components/ui/ModalEdit';
import baseModalMixin from '@/mixins/baseModalMixin';
import InputMoney from '@/components/ui/input/InputMoney';
import BuildingFullname from '@/components/building/BuildingFullname';
import { INVOICE_MAKE_PAY } from '@/store/actions/invoice';

export default {
  mixins: [baseModalMixin, validationMixin],

  components: { ModalEdit, BuildingFullname, InputMoney },

  props: {
    invoice: Object,
    title: String,
  },

  data() {
    return {
      form: {
        amount: 0,
        status: 'PAID',
      },
    };
  },

  validations() {
    return {
      form: {
        amount: {
          required,
          minValueAmount: minValue(this.form.amount),
        },
      },
    };
  },

  computed: {
    ...mapGetters(['invoiceMakePayStatus']),
    isLoading() {
      return this.invoiceMakePayStatus === 'loading';
    },
    minValue() {
      return this.invoice ? this.invoice.total : 0;
    },
    differenceOfAmount() {
      let { total } = this.invoice;
      if (this.invoice.paidAmount) {
        total -= this.invoice.paidAmount;
      }
      return total - this.form.amount;
    },
    amountColor() {
      return this.differenceOfAmount > 0 ? 'text-theme-9' : 'text-theme-6';
    },
  },

  methods: {
    resetForm() {
      this.form = {
        amount: 0,
        status: 'PAID',
      };
      this.$v.$reset();
    },
    async onSubmit() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          this.$toasted.show(this.$t('toast.failedForm'), { type: 'error' });
          return;
        }
      }

      try {
        if (this.form.amount === 0) {
          this.$toasted.show(this.$t('toast.errorAmount'), { type: 'error' });
        } else {
          const payload = {
            businessId: this.invoice.businessId,
            id: this.invoice.id,
            data: this.form,
          };

          await this.$store.dispatch(INVOICE_MAKE_PAY, payload);

          if (this.invoiceMakePayStatus === 'success') {
            this.$toasted.show(this.$t('toast.success'), { type: 'success' });
            this.hideEditModal();
            this.resetForm();
            this.$emit('onFinish');
          }
        }
      } catch (error) {
        this.$toasted.show(error.message, { type: 'error' });
      }
    },
  },
};
</script>
