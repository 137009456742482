import UsersRepository from '@/repositories/UsersRepository';
import BuildingsRepository from '@/repositories/BuildingsRepository';
import OwnersRepository from '@/repositories/OwnersRepository';
import ApartmentsRepository from '@/repositories/ApartmentsRepository';
import ServiceProductsRepository from '@/repositories/ServiceProductsRepository';
import InvoicesRepository from '@/repositories/InvoicesRepository';
import ExpensesRepository from '@/repositories/ExpensesRepository';
import ReportsRepository from '@/repositories/ReportsRepository';
import TicketsRepository from '@/repositories/TicketsRepository';
import BusinessesRepository from '@/repositories/BusinessesRepository';
import DashboardRepository from '@/repositories/DashboardRepository';
import ServicesRepository from '@/repositories/ServicesRepository';
import ImportersRepository from '@/repositories/ImportersRepository';
import BanksRepository from '@/repositories/BanksRepository';
import OrderRepository from '@/repositories/OrderRepository';
import ForumsRepository from '@/repositories/ForumsRepository';
import AnnouncementsRepository from '@/repositories/AnnouncementsRepository';
import PollsRepository from '@/repositories/PollsRepository';
import SmsPackagesRepository from '@/repositories/SmsPackagesRepository';
import ParkingsRepository from '@/repositories/ParkingsRepository';
import AdminRepository from '@/repositories/AdminRepository';
import TicketCategoryRepository from '@/repositories/TicketCategoryRepository';
import ExpenseTypeRepository from '@/repositories/ExpenseTypeRepository';
import TicketTypeRepository from '@/repositories/TicketTypeRepository';
import MeetingRepository from '@/repositories/MeetingRepository';
import BannersRepository from '@/repositories/BannersRepository';
import SmsTemplatesRepository from '@/repositories/SmsTemplatesRepository';
import SmsBalancesRepository from '@/repositories/SmsBalancesRepository';
import ActivityReportsRepository from '@/repositories/ActivityReportsRepository';
import AppVersionsRepository from '@/repositories/AppVersionsRepository';
import TransactionsRepository from '@/repositories/TransactionsRepository';

export const USERS = 'users';
export const BUILDINGS = 'buildings';
export const OWNERS = 'owners';
export const APARTMENTS = 'apartments';
export const SERVICE_PRODUCTS = 'serviceProducts';
export const INVOICES = 'invoices';
export const EXPENSES = 'expenses';
export const REPORTS = 'reports';
export const TICKETS = 'tickets';
export const BUSINESSES = 'businesses';
export const DASHBOARD = 'dashboard';
export const SERVICES = 'services';
export const IMPORTERS = 'Importers';
export const BANKS = 'Banks';
export const ORDER = 'order';
export const FORUMS = 'forums';
export const ANNOUNCEMENTS = 'announcements';
export const POLLS = 'polls';
export const SMS_PACKAGES = 'smsPackages';
export const PARKING = 'parkings';
export const ADMIN = 'Admin';
export const TICKET_CATEGORY_REPOSITORY = 'TicketCategoryRepository';
export const EXPENSE_TYPES = 'ExpenseTypeRepository';
export const TICKET_TYPES = 'TicketTypeRepository';
export const MEETING = 'meeting';
export const BANNERS = 'banners';
export const SMS_TEMPLATES = 'smsTemplates';
export const SMS_BALANCES = 'smsBalances';
export const ACTIVITY_REPORTS = 'activityReports';
export const APP_VERSIONS = 'appVersions';
export const TRANSACTIONS = 'transactions';

const repositories = {
  [USERS]: UsersRepository,
  [BUILDINGS]: BuildingsRepository,
  [OWNERS]: OwnersRepository,
  [APARTMENTS]: ApartmentsRepository,
  [SERVICE_PRODUCTS]: ServiceProductsRepository,
  [INVOICES]: InvoicesRepository,
  [EXPENSES]: ExpensesRepository,
  [REPORTS]: ReportsRepository,
  [TICKETS]: TicketsRepository,
  [BUSINESSES]: BusinessesRepository,
  [DASHBOARD]: DashboardRepository,
  [SERVICES]: ServicesRepository,
  [IMPORTERS]: ImportersRepository,
  [BANKS]: BanksRepository,
  [ORDER]: OrderRepository,
  [FORUMS]: ForumsRepository,
  [ANNOUNCEMENTS]: AnnouncementsRepository,
  [POLLS]: PollsRepository,
  [SMS_PACKAGES]: SmsPackagesRepository,
  [PARKING]: ParkingsRepository,
  [ADMIN]: AdminRepository,
  [TICKET_CATEGORY_REPOSITORY]: TicketCategoryRepository,
  [EXPENSE_TYPES]: ExpenseTypeRepository,
  [TICKET_TYPES]: TicketTypeRepository,
  [MEETING]: MeetingRepository,
  [BANNERS]: BannersRepository,
  [SMS_TEMPLATES]: SmsTemplatesRepository,
  [SMS_BALANCES]: SmsBalancesRepository,
  [ACTIVITY_REPORTS]: ActivityReportsRepository,
  [APP_VERSIONS]: AppVersionsRepository,
  [TRANSACTIONS]: TransactionsRepository,
};

export default {
  get: name => repositories[name],
};
