<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center">
      <PageHeader class="text-lg font-medium mr-auto" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <router-link
          v-if="checkPermission('finance.expense.create')"
          :to="{ name: 'ExpenseCreate' }"
          class="button text-white bg-theme-1 shadow-md mr-2"
        >
          {{ $t('text.create') }}
        </router-link>
        <DropDownButtonList
          v-if="checkPermission('finance.expense.upload')"
          :id="dropdownId"
          class="ml-auto sm:ml-0"
          toggler-class="button px-2 box text-gray-700 dark:text-gray-300"
          box-class="w-40"
          content-class="dark:bg-dark-1"
        >
          <a @click="onUpload"><UploadIcon /> {{ $t('file.excel') }}</a>
        </DropDownButtonList>
      </div>
    </div>
    <div class="intro-y mt-5 overflow-auto lg:overflow-visible">
      <ExpenseTable
        :loading="isLoading"
        :downloading="isDownloading"
        :rows="expenseList"
        :columns="columns"
        :custom-filters="customFilters"
        :line-numbers="true"
        :sort="sort"
        :page="page"
        :per-page="perPage"
        :page-size="pageSize"
        :total-page="totalPage"
        @delete="confirmRemoval"
        @onFilter="onFilter"
        @onPageChange="onPageChange"
        @onPerPageChange="onPerPageChange"
        @onSortChange="onSortChange"
        @onDownload="onDownload"
        @onChangeCustomFilter="onChangeCustomFilter"
      />
    </div>

    <ExpenseModalUpload
      :ref="uploadRefName"
      :title="$t('nav.expenseUpload')"
      :business="business"
      :modal-id="uploadModalId"
      @onImportSuccess="fetchData"
    />

    <ConfirmDialog
      :modal-id="deleteModalId"
      :is-loading="isDeleting ? true : false"
      @ok="onDelete"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { EXPENSE_LIST_REQUEST, EXPENSE_REMOVE, EXPENSE_LIST_EXPORT } from '@/store/actions/expense';
import PageHeader from '@/components/ui/PageHeader';
import ExpenseTable from '@/components/expense/ExpenseTable';
import ExpenseModalUpload from '@/components/expense/ExpenseModalUpload';
import dataListMixin from '@/mixins/dataListMixin';

export default {
  mixins: [dataListMixin],

  components: {
    PageHeader,
    ExpenseTable,
    ExpenseModalUpload,
  },

  data() {
    return {
      storeActionListRequest: EXPENSE_LIST_REQUEST,
      storeActionRemove: EXPENSE_REMOVE,
      storeActionDownload: EXPENSE_LIST_EXPORT,
      uploadModalId: 'expense-upload-modal',
      uploadRefName: 'expenseModalUpload',
      dropdownId: 'expense-dropdown',
      columns: [
        {
          label: this.$t('table.buildings'),
          field: 'buildings',
        },
        {
          label: this.$t('table.type'),
          field: 'expenseType',
        },
        {
          label: this.$t('table.meaning'),
          field: 'description',
        },
        {
          label: this.$t('table.date'),
          field: 'expenditureDate',
          sortable: true,
        },
        {
          label: this.$t('table.amount'),
          field: 'amount',
          sortable: true,
          filterable: true,
        },
        {
          label: this.$t('table.respondent'),
          field: 'users',
        },
        {
          label: this.$t('table.action'),
          field: 'action',
        },
      ],
      customFilters: [
        {
          key: 'expenditureDate',
          value: null,
          label: this.$t('text.selectMonth'),
          type: 'month',
        },
      ],
    };
  },

  computed: {
    ...mapGetters([
      'expenseList',
      'expenseMeta',
      'expenseStatus',
      'expenseExportStatus',
      'expenseExportLink',
    ]),
    isLoading() {
      return this.expenseStatus === 'loading';
    },
    isDownloading() {
      return this.expenseExportStatus === 'loading';
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    setPaginationValues() {
      this.totalRecords = this.expenseMeta ? this.expenseMeta.total : 1;
      this.page = this.expenseMeta.currentPage;
      this.totalPage = this.expenseMeta.lastPage;
    },

    setDownloads() {
      if (this.expenseExportStatus === 'success') {
        window.open(`${this.expenseExportLink}`, '_blank');
      }
    },

    setFilterValues(data) {
      this.customFilters.forEach(item => {
        data.filters[`filter[${item.key}]`] = item.value;
      });
    },

    onChangeCustomFilter(object) {
      const index = this.customFilters.findIndex(item => item.key === object.key);
      if (index > -1) {
        const { value } = object;
        this.customFilters[index].value = value || null;
        this.fetchData();
      }
    },
  },
};
</script>
