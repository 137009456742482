<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <PageHeader class="text-lg font-medium mr-auto" :title="title" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <Button
          type="button"
          @click="onSubmit"
          :loading="isLoading"
          class="w-30 bg-theme-1 text-white"
        >
          {{ $t('text.sms') }}
        </Button>
      </div>
    </div>
    <div class="intro-y mt-5">
      <form @submit.prevent="onSubmit">
        <div class="grid grid-cols-12 gap-6">
          <div class="intro-y col-span-12 sm:col-span-12 lg:col-span-5 xl:col-span-5">
            <div class="intro-y box">
              <div class="p-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class="font-medium text-base">{{ $t('text.smsInfo') }}</h2>
              </div>
              <div class="py-4 px-5 relative">
                <div class="grid grid-cols-12">
                  <div class="intro-y col-span-12 sm:col-span-12">
                    <InputString
                      :label="$t('text.smsSendInfo')"
                      v-model.trim="$v.form.message.$model"
                      :vuelidate="$v.form.message"
                      input-class="input-send-sms-message"
                      is-textarea
                      :maxLength="max"
                    />
                    <p v-text="max - form.message.length"></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="box mt-5">
              <div class="intro-y p-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class="font-medium text-base">{{ $t('text.apartmentsTitle') }}</h2>
              </div>
              <div class="intro-y py-4 px-5">
                <InputSelect
                  v-model.number="$v.form.cityId.$model"
                  :options="cityList"
                  :label="$t('auth.city')"
                  value-key="id"
                  :vuelidate="$v.form.cityId"
                  input-class="input-send-sms-city"
                  @input="onChangeCity"
                  :disabled="isLoadingCities"
                />

                <InputSelect
                  v-model.number="$v.form.districtId.$model"
                  :options="districts"
                  :label="$t('auth.district')"
                  value-key="id"
                  :vuelidate="$v.form.districtId"
                  input-class="input-send-sms-district"
                  :disabled="districts.length < 1"
                  @input="onChangeDistrict"
                />

                <InputSelect
                  v-model.number="$v.form.horooId.$model"
                  :options="horoos"
                  :label="$t('auth.khoroo')"
                  value-key="id"
                  :vuelidate="$v.form.horooId"
                  input-class="input-send-sms-khoroo"
                  :disabled="horoos.length < 1"
                  @input="onChangeHoroo"
                />

                <InputSelect
                  v-model.number="$v.form.businessId.$model"
                  :options="businessList"
                  :label="$t('text.selectBusiness')"
                  value-key="id"
                  :vuelidate="$v.form.businessId"
                  input-class="input-send-sms-business"
                  :disabled="isLoadingBusiness"
                  @input="onChangeBusiness()"
                />
              </div>
            </div>
          </div>
          <div
            v-if="form.businessId"
            class="intro-y col-span-12 sm:col-span-12 lg:col-span-4 xl:col-span-4"
          >
            <div class="intro-y box">
              <div class="p-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class="font-medium text-base">{{ $t('text.buildings') }}</h2>
              </div>
              <Loading v-if="isLoadingBuildings" />
              <template v-else>
                <label
                  class="py-4 px-5 border-b border-gray-200 dark:border-dark-5 flex items-center cursor-pointer hover:bg-gray-100 dark:hover:bg-dark-2"
                >
                  <div class="mr-3">
                    <input
                      class="input-send-sms-select-all-building input border border-gray-500"
                      @change="toggleSelectAllBuilding($event.target.checked)"
                      type="checkbox"
                    />
                  </div>
                  {{ $t('text.selectAll') }}
                </label>
                <template v-for="(building, index) in buildingList">
                  <label
                    :key="index"
                    class="py-4 px-5 border-b border-gray-200 dark:border-dark-5 flex items-center cursor-pointer hover:bg-gray-100 dark:hover:bg-dark-2"
                  >
                    <div class="mr-3">
                      <input
                        class="input-send-sms-toggler-building input border border-gray-500"
                        :checked="checkItemInArray(building, selectedBuildings)"
                        @change="checkBuilding(building, selectedBuildings)"
                        type="checkbox"
                      />
                    </div>
                    <BuildingFullname
                      :item="building"
                      class="lg:ml-3 mt-3 lg:mt-0 overflow-hidden"
                    />
                  </label>
                </template>
              </template>
            </div>
          </div>
          <div
            v-if="selectedBuildings.length === 1"
            class="intro-y col-span-12 sm:col-span-12 lg:col-span-3 xl:col-span-3"
          >
            <div class="intro-y box overflow-y-scroll max-h-screen">
              <div class="p-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class="font-medium text-base">
                  {{ $t('text.apartmentsTitle') }}
                </h2>
              </div>
              <Loading v-if="isLoadingApartments" />
              <template v-else>
                <div class="py-4 px-5 border-b border-gray-200 dark:border-dark-5">
                  <div class="w-full relative">
                    <SearchIcon
                      class="w-4 h-4 absolute my-auto inset-y-0 ml-3 left-0 z-10 text-gray-600 dark:text-gray-300"
                    />
                    <input
                      type="text"
                      v-model="search"
                      :placeholder="$t('table.searchPlaceholder')"
                      class="input-send-sms-search-apartment pl-10 pr-24 input input-lg w-full border"
                    />
                  </div>
                </div>
                <label
                  v-if="!search"
                  class="py-4 px-5 border-b border-gray-200 dark:border-dark-5 flex items-center cursor-pointer hover:bg-gray-100 dark:hover:bg-dark-2"
                >
                  <input
                    class="input-send-sms-select-all-apartment input border border-gray-500"
                    type="checkbox"
                    @change="toggleSelectAll($event.target.checked)"
                  />
                  <div class="ml-3 font-bold">
                    {{ $t('text.selectAll') }}
                  </div>
                </label>
                <template v-for="(apartment, index) in filteredApartmentList">
                  <label
                    :key="index"
                    class="py-2 px-5 border-b border-gray-200 dark:border-dark-5 flex items-center cursor-pointer hover:bg-gray-100 dark:hover:bg-dark-2"
                  >
                    <div class="mr-3">
                      <input
                        class="input-send-sms-toggler-apartment input border border-gray-500"
                        :checked="checkItemInArray(apartment, selectedApartments)"
                        type="checkbox"
                        @change="toggleItem(apartment, selectedApartments)"
                      />
                    </div>
                    <ApartmentAndPhone :key="index" :item="apartment" />
                  </label>
                </template>
              </template>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SEND_SMS } from '@/store/actions/announcements';
import { APARTMENT_LIST_REQUEST } from '@/store/actions/apartment';
import { BUILDING_LIST_REQUEST } from '@/store/actions/building';
import { BUSINESS_LIST_REQUEST } from '@/store/actions/business';
import { ALL_USER_LIST_REQUEST } from '@/store/actions/user';
import { KEY_FILTER_HOROO_ID } from '@/utils/filter';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import Button from '@/components/ui/button/Button';
import PageHeader from '@/components/ui/PageHeader';
import InputString from '@/components/ui/input/InputString';
import InputSelect from '@/components/ui/input/InputSelect';
import ApartmentAndPhone from '@/components/apartment/ApartmentAndPhone';
import { ANNOUNCEMENT_TYPES } from '@/utils/constants';
import BuildingFullname from '@/components/building/BuildingFullname';

export default {
  mixins: [validationMixin],

  components: {
    Button,
    PageHeader,
    InputString,
    InputSelect,
    ApartmentAndPhone,
    BuildingFullname,
  },

  props: {
    business: Object,
  },

  validations: {
    form: {
      cityId: {
        required,
      },
      districtId: {
        required,
      },
      horooId: {
        required,
      },
      message: {
        required,
      },
      businessId: {
        required,
      },
    },
    selectedBuildings: {
      required,
    },
  },

  data() {
    return {
      form: {
        cityId: null,
        districtId: null,
        horooId: null,
        message: '',
        businessId: null,
        buildingId: null,
        apartmentId: null,
        buildings: [],
        apartments: [],
      },
      districts: [],
      horoos: [],
      announcementsTypes: ANNOUNCEMENT_TYPES,
      selectedApartments: [],
      selectedBuildings: [],
      defaultTableData: {
        sort: {
          field: 'id',
          type: 'desc',
        },
        page: 1,
        perPage: 100,
        filters: {},
      },
      max: 160,
      userInfo: {},
      search: '',
    };
  },

  computed: {
    ...mapGetters([
      'me',
      'smsStatus',
      'buildingStatus',
      'buildingList',
      'businessStatus',
      'businessList',
      'apartmentList',
      'apartmentListStatus',
      'userList',
      'userListStatus',
      'cityStatus',
      'cityList',
    ]),
    isLoading() {
      return this.smsStatus === 'loading';
    },
    isLoadingCities() {
      return this.cityStatus === 'loading';
    },
    isLoadingBuildings() {
      return this.buildingStatus === 'loading';
    },
    isLoadingBusiness() {
      return this.businessStatus === 'loading';
    },
    isLoadingApartments() {
      return this.apartmentListStatus === 'loading';
    },
    userListLoading() {
      return this.userListStatus === 'loading';
    },
    title() {
      return this.$t(`nav.sms`);
    },
    filteredApartmentList() {
      return this.apartmentList.filter(apartment => apartment.number.includes(this.search));
    },
  },

  created() {},

  methods: {
    onChangeCity() {
      this.form.districtId = null;
      this.form.horooId = null;
      const index = this.cityList.findIndex(item => item.id === this.form.cityId);
      if (index > -1) {
        this.districts = this.cityList[index].districts;
      } else {
        this.districts = [];
      }
    },
    onChangeDistrict() {
      this.form.horooId = null;
      const index = this.districts.findIndex(item => item.id === this.form.districtId);
      if (index > -1) {
        this.horoos = this.districts[index].horoos;
      } else {
        this.horoos = [];
      }
    },
    onChangeHoroo() {
      this.form.businessId = null;
      this.fetchBusinessList();
    },
    async fetchBuildingList() {
      const payload = {
        businessId: this.form.businessId,
        data: this.defaultTableData,
      };
      await this.$store.dispatch(BUILDING_LIST_REQUEST, payload);
    },
    async fetchBusinessList() {
      const data = this.defaultTableData;
      data.filters[`${KEY_FILTER_HOROO_ID}`] = this.form.horooId;
      const payload = {
        data,
      };
      await this.$store.dispatch(BUSINESS_LIST_REQUEST, payload);
    },
    async getApartmentList(building) {
      const data = this.defaultTableData;
      data.filters['filter[buildingId]'] = building.id;
      data.filters['filter[isParking]'] = 0;

      const payload = {
        businessId: this.form.businessId,
        data,
      };

      await this.$store.dispatch(APARTMENT_LIST_REQUEST, payload);
    },
    async fetchUserList() {
      const data = { page: 1, perPage: 100 };
      await this.$store.dispatch(ALL_USER_LIST_REQUEST, data);
    },
    async onChangeBuilding(building) {
      this.form.apartmentId = null;
      await this.getApartmentList(building);
    },
    async onChangeBusiness() {
      this.form.buildingId = null;
      await this.fetchBuildingList();
    },
    checkBuilding(building, selectedBuildings) {
      this.toggleItem(building, selectedBuildings);
      this.onChangeBuilding(building);
    },
    toggleItem(object, array) {
      const index = array.findIndex(item => item.id === object.id);
      if (index > -1) {
        array.splice(index, 1);
      } else {
        array.push(object);
      }
    },
    checkItemInArray(object, array) {
      const index = array.findIndex(item => item.id === object.id);
      if (index > -1) {
        return true;
      }
      return false;
    },
    toggleSelectAll(checked) {
      if (checked) {
        this.selectedApartments = [...this.apartmentList];
      } else {
        this.selectedApartments = [];
      }
    },
    toggleSelectAllBuilding(checked) {
      if (checked) {
        this.selectedBuildings = [...this.buildingList];
      } else {
        this.selectedBuildings = [];
      }
    },
    async onSubmit() {
      if (this.$v) {
        this.$v.selectedBuildings.$touch();
        this.$v.form.$touch();
        if (this.$v.form.$anyError || this.$v.selectedBuildings.$anyError) {
          if (this.$v.selectedBuildings.$anyError) {
            this.$toasted.show(this.$t('toast.requiredBuilding'), { type: 'error' });
            return;
          }
          this.$toasted.show(this.$t('toast.failedForm'), { type: 'error' });
          return;
        }
      }

      try {
        this.form.buildingId = this.selectedBuildings.map(item => item.id);
        this.form.apartmentId = this.selectedApartments.map(item => item.id);
        if (this.form.buildingId.length !== 1) {
          this.form.buildingId = 'ALL';
        } else {
          this.form.buildingId = this.form.buildingId;
        }
        this.form.apartments = this.form.apartmentId;
        const payload = {
          data: {
            ...this.form,
          },
        };
        await this.$store.dispatch(SEND_SMS, payload);
        if (this.smsStatus === 'success') {
          this.$toasted.show(this.$t('toast.success'), { type: 'success' });
          window.location.reload();
        }
      } catch (error) {
        this.$toasted.show(error.message, { type: 'error' });
      }
    },
  },
};
</script>
