<template>
  <div :id="modalId" class="modal">
    <div class="modal__content">
      <div class="flex items-center px-5 py-5 sm:py-3 border-b border-gray-200 dark:border-dark-5">
        <h2 class="font-medium text-base mr-auto">{{ $t('transactionDialog.title') }}</h2>
      </div>
      <div
        v-if="transactionData.transactionDesc"
        class="pl-5 pt-2 pb-2 bg-gray-200 dark:bg-dark-2 border-t border-b border-gray-400 dark:bg-transparent"
      >
        <div>
          {{ $t('transactionDialog.transactionDesctiption') }}
        </div>
        <div class="font-bold pt-2 pb-2">
          {{ transactionData.transactionDesc }}
        </div>
      </div>
      <div class="px-5 pt-5">
        <InputSelect
          v-model="buildingId"
          :label="$t('transactionDialog.building')"
          :options="buildingList"
          value-key="id"
          input-class="input-select-transaction-building"
          @input="getApartmentList"
        />
      </div>
      <div class="px-5 pt-4 mb-5">
        <InputSelect
          v-model="apartmentId"
          :label="$t('transactionDialog.apartment')"
          :options="apartmentList"
          value-key="id"
          input-class="input-select-transaction-apartment"
          :disabled="disabledApartmentInput"
          @input="checkInvoice"
        />
      </div>
      <div
        v-if="invoiceCheckList.length > 0 && !disabledApartmentInput && apartmentId"
        class="mb-5 p-5 border-t border-b border-gray-400 dark:border-dark-2"
      >
        <div class="grid grid-cols-12 gap-6">
          <div
            v-for="(invoice, index) in invoiceCheckList"
            :key="`invoice-${index}`"
            class="intro-y col-span-12 sm:col-span-12 lg:col-span-6"
          >
            <div
              @click="selectInvoice(invoice)"
              :class="[
                'py-2 px-3 rounded border cursor-pointer',
                isActiveInvoice(invoice.id)
                  ? 'bg-theme-1 border-theme-1 dark:border-theme-1 text-white'
                  : 'dark:bg-dark-2 border-gray-300 dark:border-dark-4',
              ]"
            >
              <div>{{ invoice.startDate }}</div>
              <div>{{ invoice.total | currency }}</div>
              <div>
                {{
                  `${$t('table.invoicePaidAmount')}:
                  ${$options.filters.currency(invoice.paidAmount)}`
                }}
              </div>
              <div>
                {{ $t('table.balance') }}
                <strong>{{ $options.filters.currency(invoice.total - invoice.paidAmount) }}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-5 px-5">
        <div
          v-if="buildingId && apartmentId"
          class="intro-y border border-1 dark:border-dark-4 w-full rounded dark:bg-dark-2 px-5 py-2"
        >
          <div class="text-xs">
            {{ $t('transactionDialog.totalAmountOfSelectedInvoices') }}
          </div>
          <div class="text-2xl font-bold">
            {{ totalAmountOfSelectedInvoices | currency }}
          </div>
        </div>
        <div
          class="intro-y border border-1 border-yellow-400 w-full rounded bg-yellow-100 dark:bg-theme-11 px-5 py-2 dark:text-white mt-5"
        >
          <div class="text-xs">
            {{ $t('transactionDialog.transactionAmount') }}
          </div>
          <div class="text-2xl font-bold">
            {{ transactionData.transactionAmount | currencyNormal }}
          </div>
        </div>
      </div>
      <div class="px-5 py-3 flex items-center justify-between">
        <Button
          type="button"
          @click="onCancel"
          class="w-20 border text-gray-700 dark:border-dark-5 dark:text-gray-300 mr-1"
        >
          {{ $t('text.cancel') }}
        </Button>
        <Button
          @click="onSubmit"
          type="submit"
          :disabled="invoices.length === 0"
          class="w-32 bg-theme-1 text-white"
        >
          {{ $t('text.save') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Button from '@/components/ui/button/Button';
import InputSelect from '@/components/ui/input/InputSelect';
import { INVOICE_CHECK_REQUEST, INVOICE_PAY_FROM_STATEMENT } from '@/store/actions/invoice';
import { APARTMENT_LIST_REQUEST } from '@/store/actions/apartment';

export default {
  name: 'TransactionDialog',
  components: {
    Button,
    InputSelect,
  },
  data() {
    return {
      buildingId: null,
      apartmentId: null,
      invoices: [],
      defaultTableData: {
        filters: {},
        sort: {
          field: 'number',
          type: 'asc',
        },
        page: 1,
        perPage: 1000,
      },
      invoiceTableData: {
        filters: {},
        sort: {
          field: 'id',
          type: 'asc',
        },
        page: 1,
        perPage: 1000,
      },
      disabledApartmentInput: true,
    };
  },

  props: {
    modalId: {
      type: String,
      default: 'transaction-dialog',
    },
    buildingList: Array,
    businessId: Number,
    transactionData: Object,
  },
  computed: {
    ...mapGetters([
      'apartmentList',
      'invoiceList',
      'invoiceCheckList',
      'invoiceCheckStatus',
      'invoicePayFromBankStatus',
    ]),
    totalAmountOfSelectedInvoices() {
      return this.invoices.reduce((total, item) => total + (item.total - item.paidAmount), 0);
    },
    totalAmountIsExceeded() {
      return this.totalAmountOfSelectedInvoices >= this.transactionData.transactionAmount * 100;
    },
    differenceOfTotalAmount() {
      return this.totalAmountOfSelectedInvoices - this.transactionData.transactionAmount * 100;
    },
  },
  created() {
    this.resetForm();
  },
  watch: {
    transactionData() {
      this.resetForm();
    },
  },
  methods: {
    resetForm() {
      this.buildingId = null;
      this.apartmentId = null;
      this.invoices = [];
      this.disabledApartmentInput = true;
    },
    onCancel() {
      this.resetForm();
      cash(`#${this.modalId}`).modal('hide');
    },
    async onSubmit() {
      if (this.$v) {
        this.$v.$touch();
        if (this.$v.$anyError) {
          this.$toasted.show(this.$t('toast.failedForm'), { type: 'error' });
        }
        return;
      }

      const invoices = this.invoices.map((invoice, index) => {
        let amount = invoice.total - invoice.paidAmount;
        if (this.differenceOfTotalAmount > 0 && index === this.invoices.length - 1) {
          amount -= this.differenceOfTotalAmount;
          // console.log('diff-tei', amount);
        }

        // console.log('amount', amount);
        return {
          id: invoice.id,
          amount,
        };
      });

      try {
        const data = {
          invoices,
          unknownTransactionId: this.transactionData.transactionId,
          amount: this.transactionData.transactionAmount,
          paymentType: this.transactionData.transactionPaymentType,
        };
        const payload = {
          businessId: this.businessId,
          data,
        };
        await this.$store.dispatch(INVOICE_PAY_FROM_STATEMENT, payload);
        if (this.invoicePayFromBankStatus === 'success') {
          this.$toasted.show(this.$t('toast.success'), { type: 'success' });
          this.onCancel();
          this.$emit('onImportSuccess');
        }
      } catch (error) {
        this.$toasted.show(error.message, { type: 'error' });
      }
    },
    async getApartmentList(building) {
      this.disabledApartmentInput = true;
      this.apartmentId = null;
      const data = this.defaultTableData;
      data.filters['filter[buildingId]'] = building;
      data.filters['filter[isParking]'] = 0;

      const payload = {
        businessId: this.businessId,
        data,
      };
      await this.$store.dispatch(APARTMENT_LIST_REQUEST, payload);
      this.disabledApartmentInput = false;
    },
    async checkInvoice() {
      const payload = {
        businessId: this.businessId,
        data: {
          apartmentId: this.apartmentId,
        },
      };
      await this.$store.dispatch(INVOICE_CHECK_REQUEST, payload);
      this.invoices = [];
    },
    selectInvoice(invoice) {
      const index = this.invoices.findIndex(item => item.id === invoice.id);
      if (index > -1) {
        this.invoices.splice(index, 1);
        return;
      }
      if (this.totalAmountIsExceeded) {
        this.$toasted.show(this.$t('toast.totalAmountIsExceeded'), { type: 'error' });
      } else {
        this.invoices.push(invoice);
      }
    },
    isActiveInvoice(id) {
      const index = this.invoices.findIndex(item => item.id === id);
      return index > -1;
    },
  },
};
</script>
