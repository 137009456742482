import {
  DASHBOARD_GRAPHIC_REQUEST,
  DASHBOARD_GRAPHIC_STATUS_SUCCESS,
  DASHBOARD_GRAPHIC_STATUS_ERROR,
  DASHBOARD_GENERAL_REQUEST,
  DASHBOARD_GENERAL_STATUS_ERROR,
  DASHBOARD_GENERAL_STATUS_SUCCESS,
} from '@/store/actions/dashboard';
import Repository, { DASHBOARD } from '@/repositories/RepositoryFactory';
import { handleRequestError } from '@/utils/error';

const DashboardRepository = Repository.get(DASHBOARD);

const state = {
  dashboardGeneral: {},
  dashboardGraphic: {},
  dashboardGeneralStatus: '',
  dashboardGraphicStatus: '',
};

const getters = {
  dashboardGraphic: state => state.dashboardGraphic,
  dashboardGeneral: state => state.dashboardGeneral,
  dashboardGraphicStatus: state => state.dashboardGraphicStatus,
  dashboardGeneralStatus: state => state.dashboardGeneralStatus,
};

const actions = {
  [DASHBOARD_GRAPHIC_REQUEST]: async ({ commit }, payload) => {
    commit(DASHBOARD_GRAPHIC_REQUEST);
    try {
      const response = await DashboardRepository.getDashboardGraphic(
        payload.businessId,
        payload.data
      );
      commit(DASHBOARD_GRAPHIC_STATUS_SUCCESS, response.data);
    } catch (error) {
      commit(DASHBOARD_GRAPHIC_STATUS_ERROR);
      handleRequestError(error);
    }
  },
  [DASHBOARD_GENERAL_REQUEST]: async ({ commit }, payload) => {
    commit(DASHBOARD_GENERAL_REQUEST);
    try {
      const response = await DashboardRepository.getDashboardGeneral(
        payload.businessId,
        payload.data
      );
      commit(DASHBOARD_GENERAL_STATUS_SUCCESS, response.data);
    } catch (error) {
      commit(DASHBOARD_GENERAL_STATUS_ERROR);
      handleRequestError(error);
    }
  },
};

const mutations = {
  [DASHBOARD_GRAPHIC_REQUEST]: state => {
    state.dashboardGraphicStatus = 'loading';
  },
  [DASHBOARD_GRAPHIC_STATUS_SUCCESS]: (state, payload) => {
    state.dashboardGraphicStatus = 'success';
    state.dashboardGraphic = payload;
  },
  [DASHBOARD_GRAPHIC_STATUS_ERROR]: state => {
    state.dashboardGraphicStatus = 'error';
  },
  [DASHBOARD_GENERAL_REQUEST]: state => {
    state.dashboardGeneralStatus = 'loading';
  },
  [DASHBOARD_GENERAL_STATUS_SUCCESS]: (state, payload) => {
    state.dashboardGeneralStatus = 'success';
    state.dashboardGeneral = payload;
  },
  [DASHBOARD_GENERAL_STATUS_ERROR]: state => {
    state.dashboardGeneralStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
