<template>
  <div class="box p-5">
    <TableFilters
      @onFilter="onFilter"
      @onResetFilter="onResetFilter"
      @onDownload="onDownload"
      @onChangeCustomFilter="$emit('onChangeCustomFilter', $event)"
      :filterable-columns="filterableColumns"
      :filter="filter"
      :loading="loading"
      :downloadable="downloadable"
      :downloading="downloading"
      :custom-filters="customFilters"
    />
    <div class="table-regular mt-5">
      <component :is="componentName" :ops="ops" class="table-regular-holder">
        <table class="table">
          <thead>
            <tr>
              <th v-if="selectable">
                <div class="table-regular-col-title">
                  <input
                    @input="$emit('checkAll')"
                    :checked="selectedRows.length === rows.length"
                    type="checkbox"
                    class="input-table-regular-checkall"
                  />
                </div>
              </th>
              <th v-if="lineNumbers">
                <div class="table-regular-col-title">
                  {{ $t('table.number') }}
                </div>
              </th>
              <template v-for="(column, columnIndex) in columns">
                <th v-if="!column.disabled" :key="`column-${columnIndex}`">
                  <div
                    v-if="column.sortable"
                    class="table-regular-col-title table-regular-col-title-sortable"
                    :aria-sort="
                      sort.field !== column.field ? 'none' : sort.type === 'desc' ? 'desc' : 'asc'
                    "
                    @click="onSortChange(column)"
                  >
                    {{ column.label }}
                  </div>
                  <div v-else class="table-regular-col-title">
                    {{ column.label }}
                  </div>
                </th>
              </template>
            </tr>
          </thead>
          <tbody>
            <template v-if="loading">
              <tr>
                <td :colspan="lineNumbers ? columns.length + 1 : columns.length">
                  <div class="flex items-center justify-center">
                    <LoadingIcon icon="oval" class="w-5 h-5" />
                    <p class="text-gray-500 ml-3">{{ $t('table.loading') }}</p>
                  </div>
                </td>
              </tr>
            </template>
            <template v-else-if="rows.length < 1">
              <tr>
                <td :colspan="lineNumbers ? columns.length + 1 : columns.length">
                  <div class="flex items-center justify-center">
                    <InboxIcon class="w-5 h-5 text-gray-500" />
                    <p class="text-gray-500 ml-3">{{ $t('table.noItems') }}</p>
                  </div>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr v-for="(row, rowIndex) in rows" :key="rowIndex">
                <td v-if="selectable">
                  <input
                    @input="$emit('check', row.id)"
                    :checked="selectedRows.includes(row.id)"
                    type="checkbox"
                    class="input-table-regular-check-row"
                  />
                </td>
                <td v-if="lineNumbers">
                  {{ (page - 1) * perPage + rowIndex + 1 }}
                </td>
                <template v-for="(column, columnIndex) in columns">
                  <td
                    v-if="!column.disabled"
                    :key="columnIndex"
                    :class="{ 'table-report__action w-56': column.field === 'action' }"
                  >
                    <slot name="table-row" :column="column" :row="row">{{
                      row[column.field]
                    }}</slot>
                  </td>
                </template>
              </tr>
            </template>
          </tbody>

          <tfoot>
            <slot name="footer"></slot>
          </tfoot>
        </table>
      </component>

      <TablePagination
        @onPerPageChange="onPerPageChange($event)"
        @onPageChange="onPageChange"
        :page="page"
        :per-page="perPage"
        :page-size="pageSize"
        :total-page="totalPage"
      />
    </div>
  </div>
</template>

<script>
import TableFilters from '@/components/ui/table/TableFilters';
import TablePagination from '@/components/ui/table/TablePagination';
import tableMixin from '@/mixins/tableMixin';
import vuescroll from 'vuescroll';

export default {
  mixins: [tableMixin],

  data() {
    return {
      filter: {
        field: '',
        value: '',
      },
      ops: {
        scrollPanel: {
          scrollingX: true,
          scrollingY: false,
        },
        vuescroll: {
          detectResize: false,
          sizeStrategy: 'number',
        },
        rail: {
          background: '#e2e8f0',
          size: '10px',
          opacity: 1,
        },
        bar: {
          background: 'rgba(0, 0, 0, .3)',
          onlyShowBarOnScroll: false,
          keepShow: true,
          size: '10px',
        },
      },
    };
  },

  computed: {
    filterableColumns() {
      return this.columns.filter(item => item.filterable);
    },
    componentName() {
      return this.loading ? 'div' : 'vuescroll';
    },
  },

  methods: {
    onFilter() {
      this.$emit('onFilter', this.filter);
      this.$emit('onSuccessFilter', this.filter);
    },
    onResetFilter() {
      this.filter = {
        field: '',
        value: '',
      };
      this.onFilter();
    },
    onPageChange(page) {
      this.$emit('onPageChange', page);
    },
    onPerPageChange($event) {
      this.$emit('onPerPageChange', parseInt($event.target.value, 10));
    },
    onSortChange(column) {
      if (this.loading !== true) {
        const type = this.sort.field === column.field && this.sort.type === 'desc' ? 'asc' : 'desc';
        const payload = {
          field: column.field,
          type,
        };
        this.$emit('onSortChange', payload);
      }
    },
    onDownload() {
      this.$emit('onDownload');
    },
  },

  components: {
    TableFilters,
    TablePagination,
    vuescroll,
  },

  // methods: {}
};
</script>
