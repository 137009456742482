import Client from '@/clients/apiClient';
import FileClient from '@/clients/fileClient';
import humps from 'humps';
import { stringify } from 'query-string';
import AuthTokenStorageService from '@/services/AuthTokenStorageService';

const resource = 'services';

export default {
  get(payload) {
    return Client.get(
      `${resource}?page=${payload.page}&limit=${payload.perPage}&sort[field]=${humps.decamelize(
        payload.sort.field
      )}&sort[type]=${payload.sort.type}&${stringify(humps.decamelizeKeys(payload.filters))}`
    );
  },
  create(payload) {
    const accessToken = AuthTokenStorageService.getAccessToken();
    const formData = new FormData();
    if (formData.type === 'SERVICE') {
      formData.append('schedule', payload.schedule);
    } else {
      formData.append('photo_0', payload.photo_0, payload.photo_0.name);
    }
    formData.append('name', payload.name);
    formData.append('type', payload.type);
    formData.append('price', payload.price);
    formData.append('sale', payload.sale);
    formData.append('delivery_cost', payload.deliveryCost);
    formData.append('description', payload.description);
    formData.append('importer_id', payload.importerId);

    return FileClient.post(`${resource}`, formData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
  show(id) {
    return Client.get(`${resource}/${id}`);
  },
};
