<template>
  <div class="intro-x">
    <div class="box px-5 py-3 mb-3 flex items-center zoom-in">
      <div class="ml-4 mr-auto">
        <div class="font-medium">{{ item.name }}</div>
        <div class="text-gray-600 text-xs mt-0.5">
          {{ item.price | currency }}
        </div>
      </div>
      <div class="text-theme-9">
        <a href="javascript:" @click="$emit('subscribe', item.id)" class="flex items-center mr-3">
          {{ $t('text.subscribe') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
  },
};
</script>
