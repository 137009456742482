<template>
  <json-excel
    class="button inline-flex items-center justify-center"
    :data="list"
    :worksheet="sheetName"
    :name="`${fileName}.xls`"
  >
    {{ label }}
  </json-excel>
</template>

<script>
import JsonExcel from 'vue-json-excel';

export default {
  props: {
    list: [],
    label: {
      type: String,
      default: 'Download Excel',
    },
    fileName: {
      type: String,
      default: 'filename',
    },
    sheetName: {
      type: String,
      default: 'Worksheet',
    },
  },

  computed: {},

  data() {
    return {};
  },

  components: {
    JsonExcel,
  },
};
</script>
