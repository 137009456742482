import { findIndex } from 'lodash';
import {
  PARKING_LIST_REQUEST,
  PARKING_LIST_REQUEST_ERROR,
  PARKING_LIST_REQUEST_SUCCESS,
  PARKING_ADD,
  PARKING_ADD_ERROR,
  PARKING_ADD_SUCCESS,
  PARKING_REQUEST,
  PARKING_REQUEST_SUCCESS,
  PARKING_REQUEST_ERROR,
  PARKING_REMOVE,
  PARKING_REMOVE_ERROR,
  PARKING_REMOVE_SUCCESS,
  PARKING_UPDATE,
  PARKING_UPDATE_ERROR,
  PARKING_UPDATE_SUCCESS,
  PARKING_CHILD_LIST_IMPORT,
  PARKING_CHILD_LIST_IMPORT_SUCCESS,
  PARKING_CHILD_LIST_IMPORT_ERROR,
} from '@/store/actions/parkings';
import { handleRequestError } from '@/utils/error';

import Repository, { PARKING, OWNERS, APARTMENTS } from '@/repositories/RepositoryFactory';

const ParkingsRepository = Repository.get(PARKING);
const OwnersRepository = Repository.get(OWNERS);
const ApartmentsRepository = Repository.get(APARTMENTS);

const state = {
  parking: '',
  parkingList: [],
  parkingMeta: '',
  parkingStatus: '',
  parkingChildImportStatus: '',
};

const getters = {
  parking: state => state.parking,
  parkingList: state => state.parkingList,
  parkingMeta: state => state.parkingMeta,
  parkingStatus: state => state.parkingStatus,
  parkingChildImportStatus: state => state.parkingChildImportStatus,
};

const actions = {
  [PARKING_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(PARKING_LIST_REQUEST);
    try {
      const response = await ParkingsRepository.get(payload.businessId, payload.data);
      commit(PARKING_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(PARKING_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },

  [PARKING_REQUEST]: async ({ commit }, payload) => {
    commit(PARKING_REQUEST);
    try {
      const response = await ParkingsRepository.show(payload.businessId, payload.id);
      const { data } = response;
      commit(PARKING_REQUEST_SUCCESS, data);
    } catch (error) {
      commit(PARKING_REQUEST_ERROR);
      handleRequestError(error);
    }
  },

  [PARKING_ADD]: async ({ commit }, payload) => {
    commit(PARKING_ADD);
    try {
      const response = await ParkingsRepository.create(payload.businessId, payload.data);
      commit(PARKING_ADD_SUCCESS, response.data.data);
    } catch (error) {
      commit(PARKING_ADD_ERROR);
      handleRequestError(error);
    }
  },

  [PARKING_UPDATE]: async ({ commit }, payload) => {
    commit(PARKING_UPDATE);
    try {
      const response = await ParkingsRepository.update(
        payload.businessId,
        payload.id,
        payload.data
      );
      commit(PARKING_UPDATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(PARKING_UPDATE_ERROR);
      handleRequestError(error);
    }
  },

  [PARKING_REMOVE]: async ({ commit }, payload) => {
    commit(PARKING_REMOVE);
    try {
      await ParkingsRepository.delete(payload.businessId, payload.id);
      commit(PARKING_REMOVE_SUCCESS, payload.id);
    } catch (error) {
      commit(PARKING_REMOVE_ERROR);
      handleRequestError(error);
    }
  },

  [PARKING_CHILD_LIST_IMPORT]: async ({ commit }, payload) => {
    commit(PARKING_CHILD_LIST_IMPORT);
    try {
      let response = '';
      if (payload.type === 'owners') {
        response = await OwnersRepository.import(payload.businessId, payload.data);
      } else {
        response = await ApartmentsRepository.import(payload.businessId, payload.data);
      }
      commit(PARKING_CHILD_LIST_IMPORT_SUCCESS, response.data.data);
    } catch (error) {
      commit(PARKING_CHILD_LIST_IMPORT_ERROR);
      handleRequestError(error);
    }
  },
};

const mutations = {
  [PARKING_LIST_REQUEST]: state => {
    state.parkingStatus = 'loading';
  },
  [PARKING_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.parkingStatus = 'success';
    state.parkingList = payload.data;
    state.parkingMeta = payload.meta;
  },
  [PARKING_LIST_REQUEST_ERROR]: state => {
    state.parkingStatus = 'error';
  },

  [PARKING_ADD]: state => {
    state.parkingStatus = 'loading';
  },
  [PARKING_ADD_SUCCESS]: (state, payload) => {
    state.parkingStatus = 'success';
    state.parkingList.push(payload);
  },
  [PARKING_ADD_ERROR]: state => {
    state.parkingStatus = 'error';
  },

  [PARKING_REQUEST]: state => {
    state.parkingStatus = 'loading';
  },
  [PARKING_REQUEST_SUCCESS]: (state, payload) => {
    state.parkingStatus = 'success';
    state.parking = payload.data;
  },
  [PARKING_REQUEST_ERROR]: state => {
    state.parkingStatus = 'error';
  },

  [PARKING_UPDATE]: state => {
    state.parkingStatus = 'loading';
  },
  [PARKING_UPDATE_SUCCESS]: (state, payload) => {
    state.parkingStatus = 'success';
    const index = findIndex(state.parkingList, { id: payload.id });
    state.parkingList[index] = payload;
  },
  [PARKING_UPDATE_ERROR]: state => {
    state.parkingStatus = 'error';
  },

  [PARKING_REMOVE]: state => {
    state.parkingStatus = 'deleting';
  },

  [PARKING_REMOVE_SUCCESS]: (state, payload) => {
    const index = findIndex(state.parkingList, { id: payload });
    state.parkingList.splice(index, 1);
  },

  [PARKING_REMOVE_ERROR]: state => {
    state.parkingStatus = 'error';
  },

  [PARKING_CHILD_LIST_IMPORT]: state => {
    state.parkingChildImportStatus = 'loading';
  },
  [PARKING_CHILD_LIST_IMPORT_SUCCESS]: state => {
    state.parkingChildImportStatus = 'success';
  },
  [PARKING_CHILD_LIST_IMPORT_ERROR]: state => {
    state.parkingChildImportStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
