<template>
  <div class="cursor-pointer box">
    <div class="px-4 py-2 bg-default rounded-t-lg">
      <h3 class="text-base font-medium text-white">Хуралд оролцогчид</h3>
    </div>
    <div class="border-t border-gray-200 dark:border-dark-5 flex items-center justify-between">
      <div class="w-full flex-grow h-96 overflow-y-auto">
        <MeetingAccordion :items="buildings" />
      </div>
    </div>
  </div>
</template>

<script>
import MeetingAccordion from '@/components/meeting/video/MeetingAccordion';

export default {
  props: {
    meeting: Object,
    users: {
      type: Array,
      default: () => [],
    },
  },
  components: { MeetingAccordion },
  computed: {
    getHours() {
      return this.meeting.duration / 60;
    },
    buildings() {
      return this.meeting.buildings.map(building => {
        return {
          users: (this.users ?? []).filter(user => {
            return (
              (user.apartments ?? []).filter(apartment => apartment.building_id === building.id)
                .length > 0
            );
          }),
          ...building,
        };
      });
    },
  },
};
</script>
<style>
.bg-default {
  background: #1c3faa;
}
.text-xs-new {
  font-size: 0.65rem;
}
.meeting-approve {
  margin: 15px 0;
}
</style>
