<template>
  <div>
    <div class="intro-y w-full relative">
      <SearchIcon
        class="w-4 h-4 absolute my-auto inset-y-0 ml-3 left-0 z-10 text-gray-600 dark:text-gray-300"
      />
      <input
        type="text"
        v-model="search"
        @input="fetchResults"
        :placeholder="isParking ? $t('text.parkingSearch') : $t('text.apartmentSearch')"
        class=" pl-10 pr-24 input input-lg w-full border"
        input-class="input-apartment-search"
      />
      <Button
        v-if="rows.length < 1 && search"
        @click="onApartmentModalShow"
        class="w-20 absolute my-1 mr-1 inset-y-0 right-0 z-10 bg-gray-300 dark:bg-dark-5 dark:text-gray-300 text-gray-600"
        >{{ $t('text.add') }}</Button
      >
    </div>

    <div
      v-if="isLoading"
      class="border border-t-0 dark:border-dark-4 rounded-b-md -mt-1 py-4 pt-5 px-5 flex items-center justify-center"
    >
      <LoadingIcon icon="oval" class="w-5 h-5" />
    </div>
    <template v-else>
      <div
        v-if="rows.length < 1 && search"
        class="border border-t-0 dark:border-dark-4 rounded-b-md -mt-1 py-4 pt-5 px-5 text-gray-500"
      >
        {{ $t('text.noResult') }}
      </div>
      <div
        v-if="rows.length > 0 && search"
        class="border border-t-0 dark:border-dark-4 rounded-b-md -mt-1"
      >
        <template v-for="(item, index) in rows">
          <div
            @click="onApartmentSelect(item)"
            :key="index"
            class="px-4 py-3 border-gray-300 dark:border-dark-4 cursor-pointer hover:bg-gray-200 dark:hover:bg-dark-2"
            :class="[{ 'border-t': index > 0 }, { 'pt-4': index < 1 }]"
          >
            <p class="font-medium whitespace-nowrap">{{ $t('text.code') }}: {{ item.code }}</p>
            <div class="text-gray-600 text-xs whitespace-nowrap">
              {{ item.isParking ? $t('text.parkingNumber') : $t('text.doorNumber') }}:
              {{ item.number }}
              <span v-if="!item.isParking" class="ml-3">
                {{ $t('text.squareSize') }}: {{ item.square | square }}
              </span>
            </div>
          </div>
        </template>
      </div>
    </template>

    <ApartmentModalForm
      :modal-id="modalId"
      :business="business"
      :building-id="parseInt($route.params.buildingId, 10)"
      :is-parking="apartmentModalTypeIsParking"
      @onApartmentAdd="onApartmentAdd"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Button from '@/components/ui/button/Button';
import ApartmentModalForm from '@/components/apartment/ApartmentModalForm';
import { APARTMENT_SEARCH, APARTMENT_SEARCH_LIST_CLEAR } from '@/store/actions/apartment';

export default {
  components: { ApartmentModalForm, Button },

  props: {
    business: Object,
    buildingId: Number,
    isParking: Boolean,
    modalId: String,
  },

  data() {
    return {
      apartmentModalTypeIsParking: false,
      search: '',
      timedOut: null,
      debounceMilliseconds: 250,
      rows: [],
    };
  },

  computed: {
    ...mapGetters(['apartmentSearchStatus', 'apartmentSearchList', 'apartmentSearchFieldId']),
    isLoading() {
      return (
        this.apartmentSearchStatus === 'loading' && this.apartmentSearchFieldId === this.modalId
      );
    },
  },

  methods: {
    onApartmentModalShow() {
      this.apartmentModalTypeIsParking = this.isParking;
      cash(`#${this.modalId}`).modal('show');
    },

    onApartmentAdd(apartment) {
      const data = { ...apartment, new: true };
      this.$emit('onApartmentAdd', data);
      this.resetSearch();
    },

    onApartmentSelect(apartment) {
      this.$emit('onApartmentAdd', apartment);
      this.resetSearch();
    },

    async resetSearch() {
      await this.$store.dispatch(APARTMENT_SEARCH_LIST_CLEAR);
      this.search = '';
    },

    fetchResults() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.fetchData();
      }, this.debounceMilliseconds);
    },

    async fetchData() {
      const data = {
        filters: {},
        sort: {
          field: 'id',
          type: 'desc',
        },
        page: 1,
        perPage: 5,
      };

      data.filters['filter[buildingId]'] = this.$route.params.buildingId;
      data.filters['filter[isParking]'] = this.isParking ? 1 : 0;
      data.filters['filter[code]'] = this.search;

      const payload = {
        businessId: this.business.id,
        query: this.search,
        searchingFieldId: this.modalId,
        data,
      };

      await this.$store.dispatch(APARTMENT_SEARCH, payload);
      this.rows = this.apartmentSearchList;
    },
  },
};
</script>
