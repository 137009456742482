import { findIndex } from 'lodash';
import {
  ANNOUNCEMENTS_LIST_REQUEST,
  ANNOUNCEMENTS_LIST_REQUEST_ERROR,
  ANNOUNCEMENTS_LIST_REQUEST_SUCCESS,
  ANNOUNCEMENTS_ADD,
  ANNOUNCEMENTS_ADD_ERROR,
  ANNOUNCEMENTS_ADD_SUCCESS,
  ANNOUNCEMENTS_CATEGORIES_REQUEST,
  ANNOUNCEMENTS_CATEGORIES_REQUEST_ERROR,
  ANNOUNCEMENTS_CATEGORIES_REQUEST_SUCCESS,
  ANNOUNCEMENTS_SHOW,
  ANNOUNCEMENTS_SHOW_SUCCESS,
  ANNOUNCEMENTS_SHOW_ERROR,
  ANNOUNCEMENTS_UPDATE,
  ANNOUNCEMENTS_UPDATE_SUCCESS,
  ANNOUNCEMENTS_UPDATE_ERROR,
  ANNOUNCEMENTS_DELETE,
  ANNOUNCEMENTS_DELETE_SUCCESS,
  ANNOUNCEMENTS_DELETE_ERROR,
  SEND_SMS,
  SEND_SMS_SUCCESS,
  SEND_SMS_ERROR,
} from '@/store/actions/announcements';
import { handleRequestError } from '@/utils/error';

import Repository, { ANNOUNCEMENTS } from '@/repositories/RepositoryFactory';

const AnnouncementsRepository = Repository.get(ANNOUNCEMENTS);

const state = {
  announcement: {},
  announcementList: [],
  announcementsMeta: '',
  announcementStatus: '',
  announcementLastCreated: '',
  announcementCategories: [],
  smsStatus: '',
};

const getters = {
  announcement: state => state.announcement,
  announcementList: state => state.announcementList,
  announcementsMeta: state => state.announcementsMeta,
  announcementStatus: state => state.announcementStatus,
  announcementLastCreated: state => state.announcementLastCreated,
  announcementCategories: state => state.announcementCategories,
  smsStatus: state => state.smsStatus,
};

const actions = {
  [ANNOUNCEMENTS_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(ANNOUNCEMENTS_LIST_REQUEST);
    try {
      const response = await AnnouncementsRepository.get(payload.businessId, payload.data);
      commit(ANNOUNCEMENTS_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(ANNOUNCEMENTS_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [ANNOUNCEMENTS_ADD]: async ({ commit }, payload) => {
    commit(ANNOUNCEMENTS_ADD);
    try {
      const response = await AnnouncementsRepository.create(payload.businessId, payload.data);
      commit(ANNOUNCEMENTS_ADD_SUCCESS, response.data.data);
    } catch (error) {
      commit(ANNOUNCEMENTS_ADD_ERROR);
      handleRequestError(error);
    }
  },
  [ANNOUNCEMENTS_CATEGORIES_REQUEST]: async ({ commit }, payload) => {
    commit(ANNOUNCEMENTS_CATEGORIES_REQUEST);
    try {
      const response = await AnnouncementsRepository.categories(payload.businessId);
      commit(ANNOUNCEMENTS_CATEGORIES_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(ANNOUNCEMENTS_CATEGORIES_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [ANNOUNCEMENTS_SHOW]: async ({ commit }, payload) => {
    commit(ANNOUNCEMENTS_SHOW);
    try {
      const response = await AnnouncementsRepository.show(payload.businessId, payload.id);
      commit(ANNOUNCEMENTS_SHOW_SUCCESS, response.data);
    } catch (error) {
      commit(ANNOUNCEMENTS_SHOW_ERROR);
      handleRequestError(error);
    }
  },
  [ANNOUNCEMENTS_UPDATE]: async ({ commit }, payload) => {
    commit(ANNOUNCEMENTS_UPDATE);
    try {
      const response = await AnnouncementsRepository.update(
        payload.businessId,
        payload.id,
        payload.data
      );
      commit(ANNOUNCEMENTS_UPDATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(ANNOUNCEMENTS_UPDATE_ERROR);
      handleRequestError(error);
    }
  },
  [ANNOUNCEMENTS_DELETE]: async ({ commit }, payload) => {
    commit(ANNOUNCEMENTS_DELETE);
    try {
      await AnnouncementsRepository.delete(payload.businessId, payload.id);
      commit(ANNOUNCEMENTS_DELETE_SUCCESS, payload.id);
    } catch (error) {
      commit(ANNOUNCEMENTS_DELETE_ERROR);
      handleRequestError(error);
    }
  },
  [SEND_SMS]: async ({ commit }, payload) => {
    commit(SEND_SMS);
    try {
      const response = await AnnouncementsRepository.sendSms(payload.data);
      commit(SEND_SMS_SUCCESS, response.data.data);
    } catch (error) {
      commit(SEND_SMS_ERROR);
      handleRequestError(error);
    }
  },
};

const mutations = {
  [ANNOUNCEMENTS_LIST_REQUEST]: state => {
    state.announcementStatus = 'loading';
  },
  [ANNOUNCEMENTS_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.announcementStatus = 'success';
    state.announcementList = payload.data;
    state.announcementsMeta = payload.meta;
  },
  [ANNOUNCEMENTS_LIST_REQUEST_ERROR]: state => {
    state.announcementStatus = 'error';
  },
  [ANNOUNCEMENTS_ADD]: state => {
    state.announcementStatus = 'loading';
    state.announcementLastCreated = '';
  },
  [ANNOUNCEMENTS_ADD_SUCCESS]: (state, payload) => {
    state.announcementStatus = 'success';
    state.announcementLastCreated = payload;
    state.announcementList.push(payload);
  },
  [ANNOUNCEMENTS_ADD_ERROR]: state => {
    state.announcementStatus = 'error';
  },
  [ANNOUNCEMENTS_CATEGORIES_REQUEST]: state => {
    state.announcementStatus = 'loading';
  },
  [ANNOUNCEMENTS_CATEGORIES_REQUEST_SUCCESS]: (state, payload) => {
    state.announcementStatus = 'success';
    state.announcementCategories = payload.data;
  },
  [ANNOUNCEMENTS_CATEGORIES_REQUEST_ERROR]: state => {
    state.announcementStatus = 'error';
  },
  [ANNOUNCEMENTS_SHOW]: state => {
    state.announcementStatus = 'loading';
  },
  [ANNOUNCEMENTS_SHOW_SUCCESS]: (state, payload) => {
    state.announcementStatus = 'success';
    state.announcement = payload.data;
  },
  [ANNOUNCEMENTS_SHOW_ERROR]: state => {
    state.announcementStatus = 'error';
  },
  [ANNOUNCEMENTS_UPDATE]: state => {
    state.announcementStatus = 'loading';
  },
  [ANNOUNCEMENTS_UPDATE_SUCCESS]: (state, payload) => {
    state.announcementStatus = 'success';
    state.announcementList = payload;
  },
  [ANNOUNCEMENTS_UPDATE_ERROR]: state => {
    state.announcementStatus = 'error';
  },
  [ANNOUNCEMENTS_DELETE]: state => {
    state.announcementStatus = 'deleting';
  },
  [ANNOUNCEMENTS_DELETE_SUCCESS]: (state, payload) => {
    const index = findIndex(state.announcementList, { id: payload });
    state.announcementList.splice(index, 1);
  },
  [ANNOUNCEMENTS_DELETE_ERROR]: state => {
    state.announcementStatus = 'error';
  },

  [SEND_SMS]: state => {
    state.smsStatus = 'loading';
  },
  [SEND_SMS_SUCCESS]: state => {
    state.smsStatus = 'success';
  },
  [SEND_SMS_ERROR]: state => {
    state.smsStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
