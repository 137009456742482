<template>
  <div class="intro-y box mt-5">
    <div class="relative flex items-center p-5">
      <Avatar :src="business.logo" :title="business.name" />
      <div class="ml-4 mr-auto">
        <div class="font-medium text-lg">{{ business.name }}</div>
      </div>
      <Button @click="onEdit" class="w-24 bg-theme-14 text-theme-10">
        {{ $t('text.update') }}
      </Button>
    </div>
    <div class="p-5 border-t border-gray-200 dark:border-dark-5">
      <p>
        {{ `${$t('text.registrationNumber')}: ${business.registrationNumber}` }}
      </p>
      <p class="mt-4">
        {{ `${$t('text.businessPhoneNumber')}: ${business.phone ? business.phone : ''}` }}
      </p>
      <p class="mt-4">
        {{ `${$t('text.address')}: ${business.address}` }}
      </p>
      <p class="mt-4">
        {{ `${$t('text.stateBankEmail')}: ${business.stateBankEmail || ''}` }}
      </p>
    </div>

    <BusinessDetailsModal :modal-id="modalId" :business="business" />
  </div>
</template>

<script>
import { BUSINESS_UPDATE_SUCCESS } from '@/store/actions/business';
import Avatar from '@/components/ui/Avatar';
import Button from '@/components/ui/button/Button';
import BusinessDetailsModal from '@/components/business/details/BusinessDetailsModal';

export default {
  props: {
    business: Object,
  },

  components: {
    Avatar,
    Button,
    BusinessDetailsModal,
  },

  data() {
    return {
      modalId: 'business-details-modal',
    };
  },

  computed: {
    fullAddress() {
      let address = '';
      if (this.business.city) {
        address = `${address} ${this.business.city.name}`;
      }
      if (this.business.district) {
        address = `${address} ${this.business.district.name}`;
      }
      if (this.business.horoo) {
        address = `${address} ${this.business.horoo.name}`;
      }
      if (this.business.address) {
        address = `${address} ${this.business.address}`;
      }
      return address;
    },
  },
  methods: {
    onEdit() {
      cash(`#${this.modalId}`).modal('show');
    },
    async listOfBusiness() {
      await this.$store.dispatch(BUSINESS_UPDATE_SUCCESS);
    },
  },
};
</script>
