export const APARTMENT_LIST_REQUEST = 'APARTMENT_LIST_REQUEST';
export const APARTMENT_LIST_REQUEST_SUCCESS = 'APARTMENT_LIST_REQUEST_SUCCESS';
export const APARTMENT_LIST_REQUEST_ERROR = 'APARTMENT_LIST_REQUEST_ERROR';

export const APARTMENT_SEARCH = 'APARTMENT_SEARCH';
export const APARTMENT_SEARCH_SUCCESS = 'APARTMENT_SEARCH_SUCCESS';
export const APARTMENT_SEARCH_ERROR = 'APARTMENT_SEARCH_ERROR';
export const APARTMENT_SEARCH_LIST_CLEAR = 'APARTMENT_SEARCH_LIST_CLEAR';

export const APARTMENT_ADD = 'APARTMENT_ADD';
export const APARTMENT_ADD_SUCCESS = 'APARTMENT_ADD_SUCCESS';
export const APARTMENT_ADD_ERROR = 'APARTMENT_ADD_ERROR';

export const APARTMENT_UPDATE = 'APARTMENT_UPDATE';
export const APARTMENT_UPDATE_SUCCESS = 'APARTMENT_UPDATE_SUCCESS';
export const APARTMENT_UPDATE_ERROR = 'APARTMENT_UPDATE_ERROR';

export const APARTMENT_REMOVE = 'APARTMENT_REMOVE';
export const APARTMENT_REMOVE_SUCCESS = 'APARTMENT_REMOVE_SUCCESS';
export const APARTMENT_REMOVE_ERROR = 'APARTMENT_REMOVE_ERROR';

export const APARTMENT_REQUEST = 'APARTMENT_REQUEST';
export const APARTMENT_REQUEST_SUCCESS = 'APARTMENT_REQUEST_SUCCESS';
export const APARTMENT_REQUEST_ERROR = 'APARTMENT_REQUEST_ERROR';

export const APARTMENT_LIST_IMPORT = 'APARTMENT_LIST_IMPORT';
export const APARTMENT_LIST_IMPORT_SUCCESS = 'APARTMENT_LIST_IMPORT_SUCCESS';
export const APARTMENT_LIST_IMPORT_ERROR = 'APARTMENT_LIST_IMPORT_ERROR';
