import Index from '@/views/SmsTemplates/Index';
import SmsTemplates from '@/views/SmsTemplates/SmsTemplates';
import SmsTemplateForm from '@/views/SmsTemplates/SmsTemplateForm';

export default [
  {
    path: '/sms-templates',
    component: Index,
    meta: {
      group: 'sms_template',
    },
    children: [
      {
        path: '',
        component: SmsTemplates,
        name: 'SmsTemplates',
      },
      {
        path: 'update/:id',
        name: 'SmsTemplateUpdate',
        component: SmsTemplateForm,
      },
    ],
  },
];
