import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['me', 'isProfileLoaded']),
  },

  methods: {
    getName(user) {
      return `${user.firstName} ${user.lastName}`;
    },
    getId(user) {
      return user.id;
    },
  },
};
