<template>
  <TableRegular
    :loading="loading"
    :downloading="downloading"
    :rows="rows"
    :columns="columns"
    :custom-filters="customFilters"
    :sort="sort"
    :page="page"
    :per-page="perPage"
    :page-size="pageSize"
    :total-page="totalPage"
    :line-numbers="lineNumbers"
    :downloadable="downloadable && checkPermission('service.services.download')"
    @onFilter="$emit('onFilter', $event)"
    @onPageChange="$emit('onPageChange', $event)"
    @onPerPageChange="$emit('onPerPageChange', $event)"
    @onSortChange="$emit('onSortChange', $event)"
    @onDownload="$emit('onDownload')"
    @onChangeCustomFilter="$emit('onChangeCustomFilter', $event)"
  >
    <template slot="table-row" slot-scope="props">
      <p v-if="props.column.field === 'name'">
        {{ props.row.name }}
      </p>
      <p v-else-if="props.column.field === 'type'">
        {{ $t(`text.${props.row.type.toLowerCase()}`) }}
      </p>
      <p v-else-if="props.column.field === 'price'" class="whitespace-nowrap">
        {{ props.row.price | currency }}
      </p>
      <p v-else-if="props.column.field === 'importerId'" class="whitespace-nowrap">
        {{ props.row.importerId }}
      </p>
      <p v-else-if="props.column.field === 'action'" class="flex justify-center items-center">
        <!-- <a
          v-if="checkPermission('services.services.show')"
          href="javascript:"
          class="flex items-center mr-3"
        >
          <EyeIcon class="w-4 h-4 mr-1" />
          {{ $t('table.read') }}
        </a> -->
        <!-- <router-link
          v-if="checkPermission('services.services.edit')"
          :to="{ name: 'ServiceUpdate', params: { serviceId: props.row.id } }"
          class="flex items-center mr-3"
        >
          <CheckSquareIcon class="w-4 h-4 mr-1" />
          {{ $t('table.edit') }}
        </router-link> -->
        <a
          v-if="checkPermission('services.services.delete')"
          href="javascript:"
          @click="$emit('delete', props.row.id)"
          class="flex items-center text-theme-6"
        >
          <Trash2Icon class="w-4 h-4 mr-1" />
          {{ $t('table.delete') }}
        </a>
      </p>
    </template>
  </TableRegular>
</template>

<script>
import TableRegular from '@/components/ui/table/TableRegular';
import tableMixin from '@/mixins/tableMixin';

export default {
  mixins: [tableMixin],
  components: { TableRegular },
};
</script>
