import { findIndex } from 'lodash';
import {
  IMPORTERS_LIST_REQUEST,
  IMPORTERS_LIST_REQUEST_ERROR,
  IMPORTERS_LIST_REQUEST_SUCCESS,
  IMPORTERS_SHOW,
  IMPORTERS_SHOW_SUCCESS,
  IMPORTERS_SHOW_ERROR,
  IMPORTERS_ADD,
  IMPORTERS_ADD_ERROR,
  IMPORTERS_ADD_SUCCESS,
  IMPORTERS_UPDATE,
  IMPORTERS_UPDATE_SUCCESS,
  IMPORTERS_UPDATE_ERROR,
  IMPORTERS_DELETE,
  IMPORTERS_DELETE_SUCCESS,
  IMPORTERS_DELETE_ERROR,
} from '@/store/actions/importer';
import { handleRequestError } from '@/utils/error';

import Repository, { IMPORTERS } from '@/repositories/RepositoryFactory';

const ImportersRepository = Repository.get(IMPORTERS);

const state = {
  importer: {},
  importerStatus: '',
  importerList: [],
  importerListMeta: '',
  importerListStatus: '',
};

const getters = {
  importer: state => state.importer,
  importerStatus: state => state.importerStatus,
  importerList: state => state.importerList,
  importerListMeta: state => state.importerListMeta,
  importerListStatus: state => state.importerListStatus,
};

const actions = {
  [IMPORTERS_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(IMPORTERS_LIST_REQUEST);
    try {
      const response = await ImportersRepository.get(payload.data);
      commit(IMPORTERS_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(IMPORTERS_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [IMPORTERS_SHOW]: async ({ commit }, payload) => {
    commit(IMPORTERS_SHOW);
    try {
      const response = await ImportersRepository.show(payload.id);
      const { data } = response;
      commit(IMPORTERS_SHOW_SUCCESS, data);
    } catch (error) {
      commit(IMPORTERS_SHOW_ERROR);
      handleRequestError(error);
    }
  },
  [IMPORTERS_ADD]: async ({ commit }, payload) => {
    commit(IMPORTERS_ADD);
    try {
      const response = await ImportersRepository.create(payload.data);
      commit(IMPORTERS_ADD_SUCCESS, response.data.data);
    } catch (error) {
      commit(IMPORTERS_ADD_ERROR);
      handleRequestError(error);
    }
  },
  [IMPORTERS_UPDATE]: async ({ commit }, payload) => {
    commit(IMPORTERS_UPDATE);
    try {
      const response = await ImportersRepository.update(payload.id, payload.data);
      commit(IMPORTERS_UPDATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(IMPORTERS_UPDATE_ERROR);
      handleRequestError(error);
    }
  },
  [IMPORTERS_DELETE]: async ({ commit }, payload) => {
    commit(IMPORTERS_DELETE);
    try {
      await ImportersRepository.delete(payload.id);
      commit(IMPORTERS_DELETE_SUCCESS, payload.id);
    } catch (error) {
      commit(IMPORTERS_DELETE_ERROR);
      handleRequestError(error);
    }
  },
};

const mutations = {
  [IMPORTERS_LIST_REQUEST]: state => {
    state.importerListStatus = 'loading';
  },
  [IMPORTERS_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.importerListStatus = 'success';
    state.importerList = payload.data;
    state.importerListMeta = payload.meta;
  },
  [IMPORTERS_LIST_REQUEST_ERROR]: state => {
    state.importerListStatus = 'error';
  },
  [IMPORTERS_SHOW]: state => {
    state.importerStatus = 'loading';
  },
  [IMPORTERS_SHOW_SUCCESS]: (state, payload) => {
    state.importerStatus = 'success';
    state.importer = payload.data;
  },
  [IMPORTERS_SHOW_ERROR]: state => {
    state.importerStatus = 'error';
  },
  [IMPORTERS_ADD]: state => {
    state.importerStatus = 'loading';
  },
  [IMPORTERS_ADD_SUCCESS]: (state, payload) => {
    state.importerStatus = 'success';
    state.importerList.push(payload);
  },
  [IMPORTERS_ADD_ERROR]: state => {
    state.importerStatus = 'error';
  },
  [IMPORTERS_UPDATE]: state => {
    state.importerStatus = 'loading';
  },
  [IMPORTERS_UPDATE_SUCCESS]: (state, payload) => {
    state.importerStatus = 'success';
    state.importerList = state.importerList.map(item => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },
  [IMPORTERS_UPDATE_ERROR]: state => {
    state.importerStatus = 'error';
  },
  [IMPORTERS_DELETE]: state => {
    state.bannerStatus = 'deleting';
  },
  [IMPORTERS_DELETE_SUCCESS]: (state, payload) => {
    state.bannerStatus = 'deleted';
    const index = findIndex(state.importerList, { id: payload });
    if (index > -1) {
      state.importerList.splice(index, 1);
    }
  },
  [IMPORTERS_DELETE_ERROR]: state => {
    state.bannerStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
