<template>
  <div class="w-6/12">
    <div class="intro-y flex flex-col sm:flex-row items-center">
      <PageHeader class="text-lg font-medium mr-auto mt-3" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <router-link
          :to="{ name: 'expenseTypeCreate' }"
          class="button text-white bg-theme-1 shadow-md mr-2"
        >
          {{ $t('text.create') }}
        </router-link>
      </div>
    </div>
    <div class="intro-y mt-3 overflow-auto lg:overflow-visible">
      <ExpenseTypeTable
        :loading="isLoading"
        :rows="expenseTypeList"
        :columns="columns"
        :line-numbers="true"
        :page="page"
        :per-page="perPage"
        :page-size="pageSize"
        :total-page="totalPage"
        @delete="confirmRemoval"
        @onPageChange="onPageChange"
        @onPerPageChange="onPerPageChange"
      />
      <ConfirmDialog
        :modal-id="deleteModalId"
        :is-loading="isDeleting ? true : false"
        @ok="onDelete"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PageHeader from '@/components/ui/PageHeader';
import ExpenseTypeTable from '@/components/expenseType/ExpenseTypeTable';
import dataListMixin from '@/mixins/dataListMixin';
import permissionMixin from '@/mixins/permissionMixin';
import { EXPENSE_TYPE_LIST_REQUEST, EXPENSE_TYPE_REMOVE } from '@/store/actions/expenseType';

export default {
  mixins: [dataListMixin, permissionMixin],

  components: {
    PageHeader,
    ExpenseTypeTable,
  },

  data() {
    return {
      storeActionListRequest: EXPENSE_TYPE_LIST_REQUEST,
      storeActionRemove: EXPENSE_TYPE_REMOVE,
      sort: false,
      columns: [
        {
          label: this.$t('table.name'),
          field: 'name',
        },
        {
          label: this.$t('table.action'),
          field: 'action',
        },
      ],
    };
  },

  computed: {
    ...mapGetters(['expenseTypeList', 'expenseTypeListStatus']),
    isLoading() {
      return this.expenseTypeListStatus === 'loading';
    },
  },

  async created() {
    await this.fetchData();
  },
};
</script>
