<template>
  <ModalEdit :id="modalId" :title="title" :save-button="false">
    <div class="p-5">
      <div class="border-b border-gray-200 pb-3 mb-3">
        <div class="font-bold text-xs mb-2">{{ $t('text.name') }}</div>
        <div>{{ report.name }}</div>
      </div>
      <div class="border-b border-gray-200 pb-3 mb-3">
        <div class="font-bold text-xs mb-2">{{ $t('text.description') }}</div>
        <div>{{ report.description }}</div>
      </div>
      <div class="border-b border-gray-200 pb-3 mb-3">
        <div class="font-bold text-xs mb-2">{{ $t('text.startDate') }}</div>
        <div>{{ report.startDate }}</div>
      </div>
      <div>
        <div class="font-bold text-xs mb-2">{{ $t('text.endDate') }}</div>
        <div>{{ report.endDate }}</div>
      </div>

      <!-- <InvoiceBill :item="invoice" /> -->
    </div>
  </ModalEdit>
</template>

<script>
import ModalEdit from '@/components/ui/ModalEdit';
import baseModalMixin from '@/mixins/baseModalMixin';

export default {
  mixins: [baseModalMixin],

  components: {
    ModalEdit,
  },

  props: {
    report: Object,
    title: String,
  },

  computed: {},
  methods: {
    close() {
      this.hideEditModal();
    },
  },
};
</script>
