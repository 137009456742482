<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center">
      <PageHeader class="text-lg font-medium mr-auto" />
    </div>
    <div class="intro-y mt-5 overflow-auto lg:overflow-visible">
      <ReportTable
        :loading="isLoading"
        downloadable
        :downloading="isDownloading"
        :rows="invoiceList"
        :columns="columns"
        :line-numbers="true"
        :sort="sort"
        :page="page"
        :per-page="perPage"
        :page-size="pageSize"
        :total-page="totalPage"
        :footerItems="footerItems"
        :custom-filters="customFilters"
        @onFilter="onFilter"
        @onPageChange="onPageChange"
        @onPerPageChange="onPerPageChange"
        @onSortChange="onSortChange"
        @onDownload="onDownload"
        @onChangeCustomFilter="onChangeCustomFilter"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { INVOICE_LIST_REQUEST, INVOICE_LIST_EXPORT } from '@/store/actions/invoice';
import { BUILDING_LIST_REQUEST } from '@/store/actions/building';
import dataListMixin from '@/mixins/dataListMixin';
import PageHeader from '@/components/ui/PageHeader';
import ReportTable from '@/components/reports/ReportTable';

export default {
  mixins: [dataListMixin],
  components: {
    PageHeader,
    ReportTable,
  },
  data() {
    return {
      storeActionListRequest: INVOICE_LIST_REQUEST,
      storeActionDownload: INVOICE_LIST_EXPORT,
      columns: [
        { label: this.$t('table.buildingName'), field: 'building' },
        { label: this.$t('table.doorNumber'), field: 'number', filterable: 'true', sortable: true },
        { label: this.$t('table.holders'), field: 'owners' },
        { label: this.$t('table.phone'), field: 'phone', filterable: true, disabled: true },
        { label: this.$t('table.date'), field: 'startDate', sortable: true },
        { label: this.$t('table.invoicedAmount'), field: 'amount', sortable: true },
        { label: this.$t('table.invoicePaidAmount'), field: 'paidAmount', sortable: true },
        { label: this.$t('table.balance'), field: 'balance' },
        // { label: this.$t('table.paidDate'), field: 'paidDate', sortable: true },
        // { label: this.$t('table.status'), field: 'status' },
      ],
      customFilters: [
        {
          key: 'buildingId',
          value: null,
          label: this.$t('text.selectBuilding'),
          type: 'select',
          options: [],
        },
        // {
        //   key: 'status',
        //   value: null,
        //   label: this.$t('text.status'),
        //   type: 'select',
        //   options: [
        //     {
        //       id: 'PENDING',
        //       name: this.$t('status.pending'),
        //     },
        //     {
        //       id: 'PAID',
        //       name: this.$t('status.paid'),
        //     },
        //     {
        //       id: 'OVERDUE',
        //       name: this.$t('status.overdue'),
        //     },
        //     {
        //       id: 'UNDER_PAID',
        //       name: this.$t('status.under_paid'),
        //     },
        //   ],
        // },
        {
          key: 'startDate',
          value: null,
          label: this.$t('text.selectMonth'),
          type: 'month',
        },
        {
          key: 'number',
          value: null,
          label: this.$t('text.selectMonth'),
          type: 'number',
        },
      ],
      filter: {
        field: 'type',
        value: 'report',
      },
      sort: {
        field: 'startDate',
        type: 'desc',
      },
    };
  },
  computed: {
    ...mapGetters([
      'invoice',
      'invoiceStatus',
      'invoiceList',
      'invoiceListStatus',
      'invoiceMeta',
      'invoiceExportStatus',
      'invoiceExportLink',
      'buildingStatus',
      'buildingList',
    ]),
    isLoading() {
      return this.invoiceListStatus === 'loading';
    },
    isDownloading() {
      return this.invoiceExportStatus === 'loading';
    },
    buildingListLoading() {
      return this.buildingStatus === 'loading';
    },
    footerItems() {
      return this.invoiceMeta
        ? {
            totalAmount: this.invoiceMeta.totalAmount,
            totalPaidAmount: this.invoiceMeta.totalPaidAmount,
            totalBalance: this.invoiceMeta.totalBalance,
            total: this.invoiceMeta.total,
          }
        : {};
    },
  },
  async created() {
    await this.fetchBuildingList();
    this.fetchData();
  },
  methods: {
    async fetchBuildingList() {
      const payload = {
        businessId: this.business.id,
        data: {
          filters: {},
          sort: {
            field: 'id',
            type: 'desc',
          },
          page: 1,
          perPage: 100,
        },
      };
      await this.$store.dispatch(BUILDING_LIST_REQUEST, payload);
      if (this.buildingStatus === 'success') {
        this.customFilters[0].options = this.buildingList.map(item => item);
      }
    },
    onChangeCustomFilter(object) {
      const index = this.customFilters.findIndex(item => item.key === object.key);
      if (index > -1) {
        const { value } = object;
        this.customFilters[index].value = value || null;
        this.fetchData();
      }
    },
    setFilterValues(data) {
      this.customFilters.forEach(item => {
        data.filters[`filter[${item.key}]`] = item.value;
      });
      data.filters['filter[type]'] = 'report';
    },
    setPaginationValues() {
      this.totalRecords = this.invoiceMeta ? this.invoiceMeta.total : 1;
      this.page = this.invoiceMeta.currentPage;
      this.totalPage = this.invoiceMeta.lastPage;
    },
    setDownloads() {
      if (this.invoiceExportStatus === 'success') {
        window.open(`${this.invoiceExportLink}`, '_blank');
      }
    },
  },
};
</script>
