<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center">
      <PageHeader class="text-lg font-medium mr-auto" />
      <button
        v-if="selectedRows.length > 0"
        @click="confirmRemovalSelectedItems"
        class="button text-white bg-theme-6 shadow-sm mr-2"
      >
        {{ $t('text.delete') }}
      </button>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <a
          v-if="checkPermission('finance.income.edit')"
          @click="sendSMS"
          class="button text-white bg-theme-1 shadow-md mr-2"
        >
          {{ $t('text.sendSms') }}
        </a>
      </div>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <router-link
          v-if="checkPermission('finance.income.create')"
          :to="{ name: 'InvoiceCreate' }"
          class="button text-white bg-theme-1 shadow-md mr-2"
        >
          {{ $t('text.create') }}
        </router-link>
        <DropDownButtonList
          v-if="checkPermission('finance.income.upload')"
          :id="dropdownId"
          class="ml-auto sm:ml-0"
          toggler-class="button px-2 box text-gray-700 dark:text-gray-300"
          box-class="w-60"
          content-class="dark:bg-dark-1"
        >
          <a @click="onUpload(true)"><UploadIcon /> {{ $t('file.invoice') }}</a>
          <a @click="onUpload"><UploadIcon /> {{ $t('file.statementOfStateBank') }}</a>
          <a @click="onCopy"><CopyIcon /> {{ $t('file.copyInvoice') }}</a>
          <a @click="onSendMailToStateBank">
            {{ $t('text.sendMailToStateBank') }}
          </a>
          <a @click="onSendInvoiceToStateBank">
            {{ $t('text.sendInvoiceToStateBank') }}
          </a>
        </DropDownButtonList>
      </div>
    </div>
    <div class="intro-y mt-5 overflow-auto lg:overflow-visible">
      <InvoiceTable
        :loading="isLoading"
        downloadable
        :downloading="isDownloading"
        :rows="invoiceList"
        :columns="columns"
        :line-numbers="true"
        :sort="sort"
        :page="page"
        :per-page="perPage"
        :page-size="pageSize"
        :total-page="totalPage"
        :footerItems="footerItems"
        :custom-filters="customFilters"
        selectable
        :selected-rows="selectedRows"
        @delete="confirmRemoval"
        @show="onShowModal($event)"
        @pay="onShowModal($event, 'pay')"
        @onFilter="onFilter"
        @onPageChange="onPageChange"
        @onPerPageChange="onPerPageChange"
        @onSortChange="onSortChange"
        @onDownload="onDownload"
        @onChangeCustomFilter="onChangeCustomFilter"
        @onChangePaidStatus="onChangePaidStatus($event)"
        @onSuccessFilter="onSuccessFilter($event)"
        @check="check"
        @checkAll="checkAll"
      />
    </div>

    <InvoiceModalUpload
      :ref="uploadRefName"
      :title="uploadModalTitle"
      :business="business"
      :is-invoice="uploadModalTypeIsInvoice"
      :modal-id="uploadModalId"
      @onImportSuccess="fetchData"
    />

    <InvoiceModalCopy
      :ref="copyRefName"
      :title="$t('nav.invoiceUpload')"
      :business="business"
      :modal-id="copyModalId"
      @onCopySuccess="fetchData"
    />

    <InvoiceModalShow
      ref="invoiceModalShow"
      :title="$t('nav.invoiceShow')"
      :modal-id="showModalId"
      :invoice="invoice"
    />

    <InvoiceModalPay
      ref="invoiceModalPay"
      :title="$t('nav.invoicePay')"
      :modal-id="payModalId"
      :invoice="invoice"
      @onFinish="fetchData"
    />

    <ConfirmDialog
      :modal-id="deleteModalId"
      :is-loading="isDeleting ? true : false"
      @ok="onDelete"
    />
    <ConfirmDialog
      :modal-id="deleteSelectedItemsModalId"
      :is-loading="isDeleting ? true : false"
      @ok="onDeleteSelectedItems"
    />
    <SmsDialog
      ref="invoiceModalShow"
      :title="$t('nav.invoiceShow')"
      :modal-id="smsModalId"
      :filters="customFilters"
      :filterable="filterAbleVal"
      :businessId="business.id"
      :smsCount="invoicesSms.smsCount"
      :totalSmsCount="invoicesSms.totalSmsCount"
      :oldSmsCount="business.smsCount"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  INVOICE_LIST_REQUEST,
  INVOICE_LIST_EXPORT,
  INVOICE_REMOVE,
  INVOICE_REQUEST,
  INVOICE_MAKE_PAY,
  INVOICE_CHECK_SMS,
  INVOICE_SELECTED_REMOVE,
} from '@/store/actions/invoice';
import { BUILDING_LIST_REQUEST } from '@/store/actions/building';
import {
  BUSINESS_SEND_MAIL_TO_STATE_BANK,
  BUSINESS_SEND_INVOICE_TO_STATE_BANK,
} from '@/store/actions/business';
import { PAYMENT_TYPES } from '@/utils/constants';
import dataListMixin from '@/mixins/dataListMixin';
import PageHeader from '@/components/ui/PageHeader';
import InvoiceTable from '@/components/invoice/InvoiceTable';
import InvoiceModalUpload from '@/components/invoice/InvoiceModalUpload';
import InvoiceModalCopy from '@/components/invoice/InvoiceModalCopy';
import InvoiceModalShow from '@/components/invoice/InvoiceModalShow';
import InvoiceModalPay from '@/components/invoice/InvoiceModalPay';
import SmsDialog from '@/components/receivable/SmsDialog';

export default {
  mixins: [dataListMixin],
  components: {
    PageHeader,
    InvoiceTable,
    InvoiceModalUpload,
    InvoiceModalCopy,
    InvoiceModalShow,
    InvoiceModalPay,
    SmsDialog,
  },
  data() {
    return {
      storeActionListRequest: INVOICE_LIST_REQUEST,
      storeActionDownload: INVOICE_LIST_EXPORT,
      storeActionRemove: INVOICE_REMOVE,
      uploadModalId: 'invoice-upload-modal',
      uploadExcelModalId: 'invoice-excel-upload-modal',
      copyModalId: 'invoice-copy-modal',
      uploadRefName: 'invoiceModalUpload',
      copyRefName: 'invoiceModalCopy',
      showModalId: 'invoice-show-modal',
      payModalId: 'invoice-pay-modal',
      dropdownId: 'invoice-dropdown',
      uploadModalTypeIsInvoice: false,
      smsModalId: 'sms-show-modal',
      filterAbleVal: '',
      columns: [
        { label: this.$t('table.buildingName'), field: 'building' },
        { label: this.$t('table.doorNumber'), field: 'number', filterable: 'true', sortable: true },
        { label: this.$t('table.holders'), field: 'owners' },
        { label: this.$t('table.phone'), field: 'phone', filterable: true, disabled: true },
        { label: this.$t('table.invoiceAmount'), field: 'amount', sortable: true },
        { label: this.$t('table.invoicePaidAmount'), field: 'paidAmount', sortable: true },
        { label: this.$t('table.date'), field: 'startDate', sortable: true },
        { label: this.$t('table.paidDate'), field: 'paidDate', sortable: true },
        // { label: this.$t('table.morePayment'), field: 'morePayment' },
        { label: this.$t('table.paymentType'), field: 'payments' },
        { label: this.$t('table.status'), field: 'status' },
        // { label: this.$t('table.doPay'), field: 'paid' },
        { label: this.$t('table.action'), field: 'action' },
      ],
      customFilters: [
        {
          key: 'buildingId',
          value: null,
          label: this.$t('text.selectBuilding'),
          type: 'select',
          options: [],
        },
        {
          key: 'status',
          value: null,
          label: this.$t('text.status'),
          type: 'select',
          options: [
            {
              id: 'PENDING',
              name: this.$t('status.pending'),
            },
            {
              id: 'PAID',
              name: this.$t('status.paid'),
            },
            {
              id: 'OVERDUE',
              name: this.$t('status.overdue'),
            },
            {
              id: 'UNDER_PAID',
              name: this.$t('status.under_paid'),
            },
          ],
        },
        {
          key: 'paymentType',
          value: null,
          label: this.$t('table.paymentType'),
          type: 'select',
          options: PAYMENT_TYPES,
        },
        {
          key: 'startDate',
          value: null,
          label: this.$t('text.selectMonth'),
          type: 'month',
        },
        {
          key: 'number',
          value: null,
          label: this.$t('text.selectMonth'),
          type: 'number',
        },
      ],
      sms: {
        buildingId: '',
        startDate: '',
        status: '',
        number: '',
      },
      isSms: false,
      selectedRows: [],
    };
  },
  computed: {
    ...mapGetters([
      'invoice',
      'invoiceStatus',
      'invoiceList',
      'invoiceListStatus',
      'invoiceMeta',
      'invoiceExportStatus',
      'invoiceExportLink',
      'buildingStatus',
      'buildingList',
      'invoicesSms',
      'invoicesSmsStatus',
      'businessSendMailToStateBankStatus',
      'businessSendInvoiceToStateBankStatus',
      'invoiceSelectedRemoveStatus',
    ]),
    isLoading() {
      return this.invoiceListStatus === 'loading';
    },
    isDownloading() {
      return this.invoiceExportStatus === 'loading';
    },
    buildingListLoading() {
      return this.buildingStatus === 'loading';
    },
    footerItems() {
      return this.invoiceMeta
        ? {
            totalAmount: this.invoiceMeta.totalAmount,
            totalPaidAmount: this.invoiceMeta.totalPaidAmount,
            totalBalance: this.invoiceMeta.totalBalance,
            total: this.invoiceMeta.total,
          }
        : {};
    },
    uploadModalTitle() {
      return this.uploadModalTypeIsInvoice
        ? this.$t('nav.invoiceUpload')
        : this.$t('nav.statementOfStateBankBillUpload');
    },
    isLoadingSendMailToStateBank() {
      return this.businessSendMailToStateBankStatus === 'loading';
    },
  },
  async created() {
    await this.fetchBuildingList();
    this.fetchData();
  },
  methods: {
    check(value) {
      const index = this.selectedRows.findIndex(item => item === value);
      if (index > -1) {
        this.selectedRows.splice(index, 1);
      } else {
        this.selectedRows.push(value);
      }
    },
    checkAll() {
      if (this.selectedRows.length === this.invoiceList.length) {
        this.selectedRows = [];
        return;
      }

      this.selectedRows = this.invoiceList.map(item => item.id);
    },
    confirmRemovalSelectedItems() {
      cash(`#${this.deleteSelectedItemsModalId}`).modal('show');
    },
    async onDeleteSelectedItems() {
      try {
        this.isDeleting = true;
        const payload = {
          businessId: this.business ? this.business.id : 0,
          data: {
            invoiceIds: this.selectedRows,
          },
        };
        await this.$store.dispatch(INVOICE_SELECTED_REMOVE, payload);
        if (this.invoiceSelectedRemoveStatus === 'success') {
          this.$toasted.show(this.$t('toast.success'), { type: 'success' });
          this.fetchData();
        }
      } catch (error) {
        this.$toasted.show(error.message, { type: 'error' });
      } finally {
        this.selectedRows = [];
        cash(`#${this.deleteSelectedItemsModalId}`).modal('hide');
        this.isDeleting = false;
      }
    },
    async fetchBuildingList() {
      const payload = {
        businessId: this.business.id,
        data: {
          filters: {},
          sort: {
            field: 'id',
            type: 'desc',
          },
          page: 1,
          perPage: 100,
        },
      };
      await this.$store.dispatch(BUILDING_LIST_REQUEST, payload);
      if (this.buildingStatus === 'success') {
        this.customFilters[0].options = this.buildingList.map(item => item);
      }
    },
    onChangeCustomFilter(object) {
      const index = this.customFilters.findIndex(item => item.key === object.key);
      if (object.key === 'buildingId') {
        this.sms.buildingId = object.value;
      } else if (object.key === 'startDate') {
        this.sms.startDate = object.value;
      } else if (object.key === 'status') {
        this.sms.status = object.value;
      }
      if (index > -1) {
        const { value } = object;
        this.customFilters[index].value = value || null;
        this.page = 1;
        this.fetchData();
      }
    },
    async onShowModal(id, type) {
      const payload = {
        businessId: this.business.id,
        id,
      };
      await this.$store.dispatch(INVOICE_REQUEST, payload);
      if (this.invoiceStatus === 'success') {
        if (type === 'pay') {
          await cash(`#${this.payModalId}`).modal('show');
          return;
        }
        await cash(`#${this.showModalId}`).modal('show');
      }
    },
    setFilterValues(data) {
      this.customFilters.forEach(item => {
        data.filters[`filter[${item.key}]`] = item.value;
      });
    },
    async checkSms() {
      try {
        const payload = {
          businessId: this.business.id,
          type: 'SEND',
          data: this.sms,
        };
        await this.$store.dispatch(INVOICE_CHECK_SMS, payload);
        return true;
      } catch (error) {
        return false;
      }
    },
    setPaginationValues() {
      this.totalRecords = this.invoiceMeta ? this.invoiceMeta.total : 1;
      this.page = this.invoiceMeta.currentPage;
      this.totalPage = this.invoiceMeta.lastPage;
    },
    setDownloads() {
      if (this.invoiceExportStatus === 'success') {
        window.open(`${this.invoiceExportLink}`, '_blank');
      }
    },
    async onUpload(isInvoice) {
      this.uploadModalTypeIsInvoice = !!isInvoice;
      await this.$refs[this.uploadRefName].resetForm();
      await cash(`#${this.uploadModalId}`).modal('show');
      cash(`#${this.dropdownId}`).dropdown('hide');
    },
    async onCopy() {
      await cash(`#${this.copyModalId}`).modal('show');
      cash(`#${this.dropdownId}`).dropdown('hide');
    },
    async sendSMS() {
      const response = await this.checkSms();
      if (response) {
        await cash(`#${this.smsModalId}`).modal('show');
      }
    },
    async onChangePaidStatus(isInvoice) {
      const payload = {
        businessId: this.business.id,
        id: isInvoice.id,
        data: isInvoice,
      };
      await this.$store.dispatch(INVOICE_MAKE_PAY, payload);
      if (this.invoiceMakePayStatus === 'success') {
        this.$toasted.show(this.$t('toast.success'), { type: 'success' });
        this.fetchData();
      }
    },
    async onSendMailToStateBank() {
      const startDate = this.customFilters.find(filter => filter.key === 'startDate');
      const payload = {
        businessId: this.business.id,
        data: {
          startDate: startDate ? startDate.value : null,
        },
      };
      await this.$store.dispatch(BUSINESS_SEND_MAIL_TO_STATE_BANK, payload);
      if (this.businessSendMailToStateBankStatus === 'success') {
        this.$toasted.show(this.$t('toast.success'), { type: 'success' });
        this.fetchData();
      }
    },
    async onSendInvoiceToStateBank() {
      const startDate = this.customFilters.find(filter => filter.key === 'startDate');
      const payload = {
        businessId: this.business.id,
        data: {
          startDate: startDate ? startDate.value : null,
        },
      };
      await this.$store.dispatch(BUSINESS_SEND_INVOICE_TO_STATE_BANK, payload);
      if (this.businessSendInvoiceToStateBankStatus === 'success') {
        this.$toasted.show(this.$t('toast.success'), { type: 'success' });
        this.fetchData();
      }
    },
    onSuccessFilter(data) {
      this.filterAbleVal = data.value;
      this.sms.number = data.value;
    },
  },
};
</script>
