<template>
  <div class="field">
    <label v-if="label" class="flex flex-col sm:flex-row">
      {{ label }}
      <span v-if="helpText" class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600">
        {{ helpText }}
      </span>
    </label>

    <!-- <input
      :type="type"
      :class="[
        'input w-full border block',
        inputClass,
        { 'border-theme-6': hasError },
        { 'mt-2': label },
      ]"
      v-bind:value="value"
      :placeholder="placeholder"
      @input="onChange"
      @focusout="onFocusout"
      @keydown.enter="onKeydownEnter"
      :disabled="disabled"
      :maxlength="maxLength"
    /> -->

    <div :class="['relative', { 'mt-2': label }]">
      <money
        :value="getValue"
        @input="onChange"
        v-bind="money"
        :placeholder="placeholder"
        :class="['input w-full border block pr-12', inputClass, { 'border-theme-6': hasError }]"
      ></money>
      <div
        class="absolute top-0 right-0 rounded-r w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600 font-medium"
        :class="{ 'border-theme-6': hasError }"
      >
        ₮
      </div>
    </div>

    <HasError v-if="hasError" :vuelidate="vuelidate" :invalidFeedback="invalidFeedback" />
  </div>
</template>

<script>
import baseInputMixin from '@/mixins/baseInputMixin';
import HasError from '@/components/ui/input/HasError';
import { Money } from 'v-money';

export default {
  mixins: [baseInputMixin],

  components: { HasError, Money },

  data() {
    return {
      money: {
        decimal: '.',
        thousands: ',',
        // prefix: `${this.currency}`,
        // precision: 2,
        masked: false,
      },
    };
  },

  computed: {
    getValue() {
      return this.value / 100;
    },
  },

  methods: {
    onChange($event) {
      this.$emit('input', $event * 100);
    },
  },
};
</script>
