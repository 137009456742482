<template>
  <TableRegular
    :loading="loading"
    :downloading="downloading"
    :rows="rows"
    :columns="columns"
    :sort="sort"
    :page="page"
    :per-page="perPage"
    :page-size="pageSize"
    :total-page="totalPage"
    :line-numbers="lineNumbers"
    @onFilter="$emit('onFilter', $event)"
    @onPageChange="$emit('onPageChange', $event)"
    @onPerPageChange="$emit('onPerPageChange', $event)"
    @onSortChange="$emit('onSortChange', $event)"
    @onDownload="$emit('onDownload')"
  >
    <template slot="table-row" slot-scope="props">
      <p v-if="props.column.field === 'name'" class="font-medium whitespace-nowrap">
        {{ props.row.business.name }}
      </p>
      <p v-if="props.column.field === 'firstName'" class="font-medium whitespace-nowrap">
        {{ props.row.firstName | textSubstr(10, '...') }}
      </p>
      <p v-else-if="props.column.field === 'registrationNumber'" class="whitespace-nowrap">
        {{ props.row.business.registrationNumber }}
      </p>
      <p v-else-if="props.column.field === 'district'" class="whitespace-nowrap">
        {{ props.row.business.district.name }}
      </p>
      <p v-else-if="props.column.field === 'horoo'" class="whitespace-nowrap">
        {{ props.row.business.horoo.name }}
      </p>
      <p
        v-else-if="props.column.field === 'address'"
        class="whitespace-nowrap"
        v-tooltip.top-center="props.row.business.address"
      >
        {{ props.row.business.address | textSubstr(13, '...') }}
      </p>
      <p v-else-if="props.column.field === 'createdAt'" class="whitespace-nowrap">
        {{ props.row.createdAt | momentDate('YYYY-MM-DD HH:mm') }}
      </p>
      <p v-else-if="props.column.field === 'phone'" class="font-medium whitespace-nowrap">
        {{ props.row.phone }}
      </p>
      <p v-else-if="props.column.field === 'action'" class="flex justify-center items-center">
        <a
          href="javascript:"
          @click="$emit('approve', props.row.id, props.row.business.name)"
          class="flex items-center mr-3 text-theme-6"
        >
          <CheckSquareIcon class="w-4 h-4 mr-1" />
          <span class="whitespace-nowrap">{{ $t('table.approveRequest') }}</span>
        </a>
        <a
          href="javascript:"
          @click="$emit('delete', props.row.id)"
          class="flex items-center text-theme-6"
        >
          <Trash2Icon class="w-4 h-4 mr-1" />
          {{ $t('table.delete') }}
        </a>
      </p>
    </template>
  </TableRegular>
</template>

<script>
import TableRegular from '@/components/ui/table/TableRegular';
import tableMixin from '@/mixins/tableMixin';

export default {
  mixins: [tableMixin],
  components: { TableRegular },
};
</script>
