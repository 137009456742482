<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-5">
      <PageHeader class="text-lg font-medium mr-auto" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <router-link
          :to="{ name: 'SmsBalanceCreate' }"
          class="button text-white bg-theme-1 shadow-md mr-2"
        >
          {{ $t('text.create') }}
        </router-link>
      </div>
    </div>
    <div class="intro-y mt-5 overflow-auto lg:overflow-visible">
      <SmsBalanceTable
        :loading="isLoading"
        :rows="smsBalanceList"
        :columns="columns"
        :line-numbers="true"
        :sort="sort"
        :page="page"
        :per-page="perPage"
        :page-size="pageSize"
        :total-page="totalPage"
        @onFilter="onFilter"
        @onPageChange="onPageChange"
        @onPerPageChange="onPerPageChange"
        @onSortChange="onSortChange"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SMS_BALANCE_LIST_REQUEST } from '@/store/actions/smsBalance';
import dataListMixin from '@/mixins/dataListMixin';
import PageHeader from '@/components/ui/PageHeader';
import SmsBalanceTable from '@/components/smsBalances/SmsBalanceTable';

export default {
  mixins: [dataListMixin],

  data() {
    return {
      storeActionListRequest: SMS_BALANCE_LIST_REQUEST,
      columns: [
        { label: this.$t('table.business'), field: 'business' },
        { label: this.$t('table.date'), field: 'date' },
        { label: this.$t('table.size'), field: 'in' },
        { label: this.$t('table.creator'), field: 'creator' },
      ],
    };
  },

  computed: {
    ...mapGetters(['smsBalanceList', 'smsBalanceListMeta', 'smsBalanceListStatus']),
    isLoading() {
      return this.smsBalanceListStatus === 'loading';
    },
  },

  async created() {
    await this.fetchData();
  },

  methods: {
    setPaginationValues() {
      this.totalRecords = this.smsBalanceListMeta ? this.smsBalanceListMeta.total : 1;
      this.page = this.smsBalanceListMeta.currentPage;
      this.totalPage = this.smsBalanceListMeta.lastPage;
    },
  },

  components: {
    PageHeader,
    SmsBalanceTable,
  },
};
</script>
