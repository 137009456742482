<template>
  <div class="table-regular">
    <div class="table-regular-holder">
      <table class="table mt-5">
        <thead>
          <tr>
            <th v-if="lineNumbers">
              <div class="table-regular-col-title">
                {{ $t('table.number') }}
              </div>
            </th>
            <template v-for="(column, columnIndex) in columns">
              <th v-if="!column.disabled" :key="`column-${columnIndex}`">
                <div
                  v-if="column.sortable"
                  class="table-regular-col-title table-regular-col-title-sortable"
                  :aria-sort="
                    sort.field !== column.field ? 'none' : sort.type === 'desc' ? 'desc' : 'asc'
                  "
                  @click="onSortChange(column)"
                >
                  {{ column.label }}
                </div>
                <div v-else class="table-regular-col-title">
                  {{ column.label }}
                </div>
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <template v-if="loading">
            <tr>
              <td :colspan="lineNumbers ? columns.length + 1 : columns.length">
                <div class="flex items-center justify-center">
                  <LoadingIcon icon="oval" class="w-5 h-5" />
                  <p class="text-gray-500 ml-3">{{ $t('table.loading') }}</p>
                </div>
              </td>
            </tr>
          </template>
          <template v-else-if="rows.length < 1">
            <tr>
              <td :colspan="lineNumbers ? columns.length + 1 : columns.length">
                <div class="flex items-center justify-center">
                  <InboxIcon class="w-5 h-5 text-gray-500" />
                  <p class="text-gray-500 ml-3">{{ $t('table.noItems') }}</p>
                </div>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr v-for="(row, rowIndex) in rows" :key="rowIndex">
              <td v-if="lineNumbers">{{ (page - 1) * perPage + rowIndex + 1 }}</td>
              <template v-for="(column, columnIndex) in columns">
                <td
                  v-if="!column.disabled"
                  :key="columnIndex"
                  :class="{ 'table-report__action w-56': column.field === 'action' }"
                >
                  <slot name="table-row" :column="column" :row="row">{{ row[column.field] }}</slot>
                </td>
              </template>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import tableMixin from '@/mixins/tableMixin';

export default {
  mixins: [tableMixin],

  data() {
    return {
      filter: {
        field: '',
        value: '',
      },
    };
  },

  computed: {
    filterableColumns() {
      return this.columns.filter(item => item.filterable);
    },
  },

  methods: {
    onFilter() {
      this.$emit('onFilter', this.filter);
      this.$emit('onSuccessFilter', this.filter);
    },
    onResetFilter() {
      this.filter = {
        field: '',
        value: '',
      };
      this.onFilter();
    },

    onPageChange(page) {
      this.$emit('onPageChange', page);
    },

    onPerPageChange($event) {
      this.$emit('onPerPageChange', parseInt($event.target.value, 10));
    },

    onSortChange(column) {
      if (this.loading !== true) {
        const type = this.sort.field === column.field && this.sort.type === 'desc' ? 'asc' : 'desc';
        const payload = {
          field: column.field,
          type,
        };
        this.$emit('onSortChange', payload);
      }
    },

    onDownload() {
      this.$emit('onDownload');
    },
  },
};
</script>
