import { findIndex } from 'lodash';
import {
  BANNER_LIST_REQUEST,
  BANNER_LIST_REQUEST_ERROR,
  BANNER_LIST_REQUEST_SUCCESS,
  BANNER_SHOW,
  BANNER_SHOW_SUCCESS,
  BANNER_SHOW_ERROR,
  BANNER_CREATE,
  BANNER_CREATE_ERROR,
  BANNER_CREATE_SUCCESS,
  BANNER_UPDATE,
  BANNER_UPDATE_SUCCESS,
  BANNER_UPDATE_ERROR,
  BANNER_DELETE,
  BANNER_DELETE_SUCCESS,
  BANNER_DELETE_ERROR,
} from '@/store/actions/banner';
import { handleRequestError } from '@/utils/error';

import Repository, { BANNERS } from '@/repositories/RepositoryFactory';

const BannersRepository = Repository.get(BANNERS);

const state = {
  banner: {},
  bannerList: [],
  bannerListMeta: '',
  bannerStatus: '',
};

const getters = {
  banner: state => state.banner,
  bannerList: state => state.bannerList,
  bannerListMeta: state => state.bannerListMeta,
  bannerStatus: state => state.bannerStatus,
};

const actions = {
  [BANNER_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(BANNER_LIST_REQUEST);
    try {
      const response = await BannersRepository.get(payload.data);
      commit(BANNER_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(BANNER_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [BANNER_SHOW]: async ({ commit }, payload) => {
    commit(BANNER_SHOW);
    try {
      const response = await BannersRepository.show(payload.id);
      const { data } = response;
      commit(BANNER_SHOW_SUCCESS, data);
    } catch (error) {
      commit(BANNER_SHOW_ERROR);
      handleRequestError(error);
    }
  },
  [BANNER_CREATE]: async ({ commit }, payload) => {
    commit(BANNER_CREATE);
    try {
      const response = await BannersRepository.create(payload.data);
      commit(BANNER_CREATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(BANNER_CREATE_ERROR);
      handleRequestError(error);
    }
  },
  [BANNER_UPDATE]: async ({ commit }, payload) => {
    commit(BANNER_UPDATE);
    try {
      const response = await BannersRepository.update(payload.id, payload.data);
      commit(BANNER_UPDATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(BANNER_UPDATE_ERROR);
      handleRequestError(error);
    }
  },
  [BANNER_DELETE]: async ({ commit }, payload) => {
    commit(BANNER_DELETE);
    try {
      await BannersRepository.delete(payload.id);
      commit(BANNER_DELETE_SUCCESS, payload.id);
    } catch (error) {
      commit(BANNER_DELETE_ERROR);
      handleRequestError(error);
    }
  },
};

const mutations = {
  [BANNER_LIST_REQUEST]: state => {
    state.bannerStatus = 'loading';
  },
  [BANNER_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.bannerStatus = 'success';
    state.bannerList = payload.data;
    if (payload.meta) {
      state.bannerListMeta = payload.meta;
    }
  },
  [BANNER_LIST_REQUEST_ERROR]: state => {
    state.bannerStatus = 'error';
  },
  [BANNER_SHOW]: state => {
    state.bannerStatus = 'loading';
  },
  [BANNER_SHOW_SUCCESS]: (state, payload) => {
    state.bannerStatus = 'success';
    state.banner = payload.data;
  },
  [BANNER_SHOW_ERROR]: state => {
    state.bannerStatus = 'error';
  },
  [BANNER_CREATE]: state => {
    state.bannerStatus = 'loading';
  },
  [BANNER_CREATE_SUCCESS]: (state, payload) => {
    state.bannerStatus = 'success';
    state.bannerList.unshift(payload);
  },
  [BANNER_CREATE_ERROR]: state => {
    state.bannerStatus = 'error';
  },
  [BANNER_UPDATE]: state => {
    state.bannerStatus = 'loading';
  },
  [BANNER_UPDATE_SUCCESS]: (state, payload) => {
    state.bannerStatus = 'success';
    state.bannerList = state.bannerList.map(item => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },
  [BANNER_UPDATE_ERROR]: state => {
    state.bannerStatus = 'error';
  },
  [BANNER_DELETE]: state => {
    state.bannerStatus = 'deleting';
  },
  [BANNER_DELETE_SUCCESS]: (state, payload) => {
    state.bannerStatus = 'deleted';
    const index = findIndex(state.bannerList, { id: payload });
    state.bannerList.splice(index, 1);
  },
  [BANNER_DELETE_ERROR]: state => {
    state.bannerStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
