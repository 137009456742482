<template>
  <div class="flex">
    <div class="flex-initial">
      <form v-if="filterableColumns.length > 0" class="md:flex" @submit.prevent="$emit('onFilter')">
        <div class="sm:flex items-center sm:mr-2">
          <select
            v-model="filterObject"
            class="input w-full xxl:w-full sm:w-auto border"
            @change="onChangeFilter"
          >
            <option :value="null">{{ $t('table.filterField') }}</option>
            <option v-for="(item, index) in filterableColumns" :key="index" :value="item">
              {{ item.label }}
            </option>
          </select>
        </div>
        <div class="sm:flex items-center sm:mr-2 mt-2 md:mt-0">
          <input
            type="text"
            v-model="filter.value"
            class="input-table-filter input w-full sm:w-40 xxl:w-full border"
            :placeholder="filterPlaceholder"
            :disabled="filter && filter.field ? false : true"
          />
        </div>
        <div class="mt-2 md:mt-0 flex">
          <button
            type="button"
            @click="$emit('onFilter')"
            :disabled="loading"
            class="button w-full sm:w-20 bg-theme-1 text-white"
          >
            {{ $t('table.filterGo') }}
          </button>
          <button
            type="button"
            @click="onReset"
            :disabled="loading"
            class="button w-full sm:w-20 ml-2 bg-gray-200 text-gray-600 dark:bg-dark-5 dark:text-gray-300"
          >
            {{ $t('table.filterReset') }}
          </button>
        </div>
      </form>
    </div>
    <div v-if="customFilters.length > 0" class="flex-initial">
      <div class="sm:flex" :class="{ 'ml-5': filterableColumns.length > 0 }">
        <template v-for="(item, index) in customFilters">
          <div :key="index" class="sm:mr-2" :class="{ 'mt-2 sm:mt-0': index > 0 }">
            <InputSelect
              v-if="item.type === 'select'"
              :value="item.value"
              :options="item.options"
              :firstItemLabel="item.label"
              valueKey="id"
              input-class="input-select-table-options"
              @input="onChange($event, item.key)"
            />
            <InputYear
              v-else-if="item.type === 'year'"
              :value="item.value"
              :placeholder="item.label"
              input-class="input-year-table-year"
              @input="onChange($event, item.key)"
            />
            <InputMonth
              v-else-if="item.type === 'month'"
              :value="item.value"
              :placeholder="item.label"
              class="input-month-table-month"
              @input="onChange($event, item.key)"
            />
            <InputDay
              v-else-if="item.type === 'day'"
              :value="item.value"
              :placeholder="item.label"
              class="input-day-table-day"
              @input="onChange($event, item.key)"
            />
          </div>
        </template>
      </div>
    </div>
    <div class="flex-initial ml-5 xl:ml-auto">
      <div class="flex">
        <Button
          v-if="downloadable"
          @click="$emit('onDownload')"
          :loading="downloading"
          class="w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
        >
          <DownloadIcon class="w-4 h-4 mr-2" />
          {{ $t('table.downloadRows') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/button/Button';
import InputSelect from '@/components/ui/input/InputSelect';
import InputYear from '@/components/ui/input/InputYear';
import InputMonth from '@/components/ui/input/InputMonth';
import InputDay from '@/components/ui/input/InputDay';

export default {
  components: { Button, InputSelect, InputYear, InputMonth, InputDay },

  props: {
    filterableColumns: Array,
    filter: Object,
    loading: Boolean,
    downloadable: Boolean,
    downloading: Boolean,
    customFilters: Array,
  },

  data() {
    return {
      filterLabel: '',
      filterObject: null,
    };
  },

  computed: {
    filterPlaceholder() {
      return this.filterLabel ? this.$t('placeholderByFilter', { text: this.filterLabel }) : '';
    },
  },

  methods: {
    onChange(value, key) {
      this.$emit('onChangeCustomFilter', { key, value });
    },
    onChangeFilter() {
      this.filter.field = this.filterObject.field;
      this.filterLabel = this.filterObject.label;
    },
    onReset() {
      this.filterLabel = '';
      this.filterObject = null;
      this.$emit('onResetFilter');
    },
  },
};
</script>
