<template>
  <div class="apartment-and-parking">
    <p class="font-medium whitespace-nowrap">
      {{ item.isParking ? $t('text.parkingNumber') : $t('text.doorNumber') }}: {{ item.number }}
    </p>
    <p class="text-gray-600 text-xs whitespace-nowrap">{{ $t('text.code') }}: {{ item.code }}</p>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.apartment-and-parking {
  & + .apartment-and-parking {
    @apply mt-2;
  }
}
</style>
