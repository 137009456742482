<template>
  <div class="w-6/12">
    <div class="intro-y flex flex-col sm:flex-row items-center">
      <PageHeader class="text-lg font-medium mr-auto mt-3" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <router-link
          :to="{ name: 'TicketTypeCreate' }"
          class="button text-white bg-theme-1 shadow-md mr-2"
        >
          {{ $t('text.create') }}
        </router-link>
      </div>
    </div>
    <div class="intro-y mt-3 overflow-auto lg:overflow-visible">
      <TicketTypeTable
        :loading="isLoading"
        :rows="ticketTypeList"
        :columns="columns"
        :line-numbers="true"
        :page="page"
        :per-page="perPage"
        :page-size="pageSize"
        :total-page="totalPage"
        @delete="confirmRemoval"
        @onFilter="onFilter"
        @onPageChange="onPageChange"
        @onPerPageChange="onPerPageChange"
      />
      <ConfirmDialog
        :modal-id="deleteModalId"
        :is-loading="isDeleting ? true : false"
        @ok="onDelete"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { TICKET_TYPE_LIST_REQUEST, TICKET_TYPE_REMOVE } from '@/store/actions/ticketType';
import PageHeader from '@/components/ui/PageHeader';
import TicketTypeTable from '@/components/TicketType/TicketTypeTable';
import dataListMixin from '@/mixins/dataListMixin';
import permissionMixin from '@/mixins/permissionMixin';

export default {
  mixins: [dataListMixin, permissionMixin],

  components: {
    PageHeader,
    TicketTypeTable,
  },

  data() {
    return {
      storeActionListRequest: TICKET_TYPE_LIST_REQUEST,
      storeActionRemove: TICKET_TYPE_REMOVE,
      sort: false,
      columns: [
        {
          label: this.$t('table.name'),
          field: 'name',
        },
        {
          label: this.$t('table.action'),
          field: 'action',
        },
      ],
    };
  },

  computed: {
    ...mapGetters(['ticketTypeList', 'ticketTypeListStatus', 'ticketTypeListMeta']),
    isLoading() {
      return this.ticketTypeListStatus === 'loading';
    },
  },

  async created() {
    await this.fetchData();
  },
};
</script>
