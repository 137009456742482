import api from '@/utils/api';
import {
  ACCOUNT_STATEMENTS_REQUEST,
  ACCOUNT_STATEMENTS_REQUEST_ERROR,
  ACCOUNT_STATEMENTS_REQUEST_SUCCESS,
} from '@/store/actions/account';
import humps from 'humps';

const state = {
  accountStatementsList: [],
  accountStatementsMeta: '',
  status: '',
};

const getters = {
  accountStatementsList: state => state.accountStatementsList,
  accountStatementsMeta: state => state.accountStatementsMeta,
  accountStatus: state => state.status,
};

const actions = {
  [ACCOUNT_STATEMENTS_REQUEST]: async ({ commit }, payload) => {
    commit(ACCOUNT_STATEMENTS_REQUEST);
    try {
      const response = await api.get(
        `account-statements?page=${payload.page}&per_page=${
          payload.perPage
        }&sort[field]=${humps.decamelize(payload.sort.field)}&sort[type]=${payload.sort.type}`
      );

      commit(ACCOUNT_STATEMENTS_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(ACCOUNT_STATEMENTS_REQUEST_ERROR);
    }
  },
};

const mutations = {
  [ACCOUNT_STATEMENTS_REQUEST]: state => {
    state.status = 'loading';
  },
  [ACCOUNT_STATEMENTS_REQUEST_SUCCESS]: (state, payload) => {
    state.status = 'success';
    state.accountStatementsList = payload.data;
    state.accountStatementsMeta = payload.meta;
  },
  [ACCOUNT_STATEMENTS_REQUEST_ERROR]: state => {
    state.status = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
