<template>
  <div class="mt-5">
    <div class="box px-5 intro-y mb-5">
      <div class="nav-tabs flex flex-col sm:flex-row justify-center lg:justify-start">
        <router-link
          :to="{ name: 'Services' }"
          class="py-4 sm:mr-8"
          :class="{ active: $route.name === 'Services' }"
          >{{ $t('nav.services') }}</router-link
        >
        <router-link
          :to="{ name: 'Importers' }"
          class="py-4 sm:mr-8"
          :class="{ active: $route.name === 'Importers' }"
          >{{ $t('nav.importers') }}</router-link
        >
      </div>
    </div>
    <div class="intro-y">
      <router-view :business="business"></router-view>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    business: Object,
  },
};
</script>
