import Client from '@/clients/apiClient';

const resource = 'app-versions';

export default {
  get(payload) {
    return Client.get(`${resource}?page=${payload.page}&limit=${payload.perPage}`);
  },
  show(id) {
    return Client.get(`${resource}/${id}`);
  },
  create(payload) {
    return Client.post(`${resource}`, payload);
  },
  update(id, payload) {
    return Client.put(`${resource}/${id}`, payload);
  },
  delete(id) {
    return Client.delete(`${resource}/${id}`);
  },
};
