<template>
  <div class="owner-short-info">
    <p class="font-medium whitespace-nowrap">{{ owner | fullName }}</p>
    <div class="text-gray-600 text-xs whitespace-nowrap">
      <span class="mr-3">{{ owner.phone }}</span>
      <span v-if="owner.familySize">
        {{ $t('text.familySizeWithTotal', { total: owner.familySize }) }}
      </span>
    </div>
    <div v-if="owner.cars" class="text-gray-600 text-xs whitespace-nowrap">
      <template v-for="(car, index) in owner.cars">
        <span class="mr-3" :key="index">{{
          `${car.carMake.name} ${car.carModel.name} ${car.number}`
        }}</span>
      </template>
    </div>
    <div v-if="owner.pets" class="text-gray-600 text-xs whitespace-nowrap">
      <template v-for="(pet, index) in owner.pets">
        <span class="mr-3" :key="index">{{ `${pet.petType.name}` }}</span>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    owner: Object,
  },
};
</script>
