export const ANNOUNCEMENTS_LIST_REQUEST = 'ANNOUNCEMENTS_LIST_REQUEST';
export const ANNOUNCEMENTS_LIST_REQUEST_SUCCESS = 'ANNOUNCEMENTS_LIST_REQUEST_SUCCESS';
export const ANNOUNCEMENTS_LIST_REQUEST_ERROR = 'ANNOUNCEMENTS_LIST_REQUEST_ERROR';

export const ANNOUNCEMENTS_ADD = 'ANNOUNCEMENTS_ADD';
export const ANNOUNCEMENTS_ADD_SUCCESS = 'ANNOUNCEMENTS_ADD_SUCCESS';
export const ANNOUNCEMENTS_ADD_ERROR = 'ANNOUNCEMENTS_ADD_ERROR';

export const ANNOUNCEMENTS_CATEGORIES_REQUEST = 'ANNOUNCEMENTS_CATEGORIES_REQUEST';
export const ANNOUNCEMENTS_CATEGORIES_REQUEST_SUCCESS = 'ANNOUNCEMENTS_CATEGORIES_REQUEST_SUCCESS';
export const ANNOUNCEMENTS_CATEGORIES_REQUEST_ERROR = 'ANNOUNCEMENTS_CATEGORIES_REQUEST_ERROR';

export const ANNOUNCEMENTS_SHOW = 'ANNOUNCEMENTS_SHOW';
export const ANNOUNCEMENTS_SHOW_SUCCESS = 'ANNOUNCEMENTS_SHOW_SUCCESS';
export const ANNOUNCEMENTS_SHOW_ERROR = 'ANNOUNCEMENTS_SHOW_ERROR';

export const ANNOUNCEMENTS_UPDATE = 'ANNOUNCEMENTS_UPDATE';
export const ANNOUNCEMENTS_UPDATE_SUCCESS = 'ANNOUNCEMENTS_UPDATE_SUCCESS';
export const ANNOUNCEMENTS_UPDATE_ERROR = 'ANNOUNCEMENTS_UPDATE_ERROR';

export const ANNOUNCEMENTS_DELETE = 'ANNOUNCEMENTS_DELETE';
export const ANNOUNCEMENTS_DELETE_SUCCESS = 'ANNOUNCEMENTS_DELETE_SUCCESS';
export const ANNOUNCEMENTS_DELETE_ERROR = 'ANNOUNCEMENTS_DELETE_ERROR';

export const SEND_SMS = 'SEND_SMS';
export const SEND_SMS_SUCCESS = 'SEND_SMS_SUCCESS';
export const SEND_SMS_ERROR = 'SEND_SMS_ERROR';
