<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-5">
      <PageHeader class="text-lg font-medium mr-auto" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <router-link
          :to="{ name: 'userCreate' }"
          class="button text-white bg-theme-1 shadow-md mr-2"
        >
          {{ $t('text.create') }}
        </router-link>
      </div>
    </div>
    <div class="intro-y mt-5 overflow-auto lg:overflow-visible">
      <UserTable
        :loading="isLoading"
        :rows="userList"
        :columns="columns"
        :line-numbers="true"
        :page="page"
        :per-page="perPage"
        :page-size="pageSize"
        :total-page="totalPage"
        @approve="confirmApprove"
        @onPageChange="onPageChange"
        @onPerPageChange="onPerPageChange"
        @onDownload="onDownload"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { USER_LIST_REQUEST } from '@/store/actions/user';
import PageHeader from '@/components/ui/PageHeader';
import UserTable from '@/components/user/UserTable';
import dataListMixin from '@/mixins/dataListMixin';

export default {
  mixins: [dataListMixin],

  components: {
    PageHeader,
    UserTable,
  },

  data() {
    return {
      storeActionListRequest: USER_LIST_REQUEST,
      sort: false,
      columns: [
        {
          label: this.$t('table.lastName'),
          field: 'lastName',
        },
        {
          label: this.$t('table.firstName'),
          field: 'firstName',
        },
        {
          label: this.$t('table.phone'),
          field: 'phone',
        },
        {
          label: this.$t('table.email'),
          field: 'email',
        },
        {
          label: this.$t('table.action'),
          field: 'action',
        },
      ],
    };
  },

  computed: {
    ...mapGetters(['userList', 'userListMeta', 'userListStatus']),
    isLoading() {
      return this.userListStatus === 'loading';
    },
  },

  async created() {
    await this.fetchData();
  },

  methods: {
    setPaginationValues() {
      this.totalRecords = this.userListMeta ? this.userListMeta.total : 1;
      this.page = this.userListMeta.currentPage;
      this.totalPage = this.userListMeta.lastPage;
    },
  },
};
</script>
