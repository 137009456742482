<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <PageHeader class="text-lg font-medium mr-auto" :title="title" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <Button
          type="button"
          :to="{ name: 'Owners' }"
          class="box text-gray-700 dark:text-gray-300 mr-2 flex"
        >
          {{ $t('text.back') }}
        </Button>
        <Button
          type="button"
          @click="onSubmit"
          :loading="isLoading"
          class="w-24 bg-theme-1 text-white"
        >
          {{ $t('text.save') }}
        </Button>
      </div>
    </div>
    <div class="intro-y mt-5">
      <form>
        <div class="grid grid-cols-12 gap-6">
          <div class="intro-y col-span-12 sm:col-span-12 lg:col-span-4 xl:col-span-4">
            <div class="intro-y box">
              <div class="p-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class="font-medium text-base">{{ $t('nav.ownerShow') }}</h2>
              </div>
              <FormOwner
                :form="$v.form"
                :selected-pets="form.pets"
                :cars="form.cars"
                @onPetsChange="onPetsChange"
                @onCarAdd="onCarAdd"
                @onCarDelete="onCarDelete"
              />
            </div>
          </div>
          <div
            v-for="(item, itemIndex) in apartmentTypeList"
            :key="itemIndex"
            class="intro-y col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-4"
          >
            <div class="intro-y box">
              <div class="p-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class="font-medium text-base">{{ item.title }}</h2>
              </div>
              <template v-for="(apartment, index) in apartmentListOfThisOwner">
                <div
                  v-if="apartment.isParking === item.isParking"
                  :key="index"
                  class="py-4 px-5 border-b border-gray-200 dark:border-dark-5 relative"
                >
                  <p class="font-medium whitespace-nowrap">
                    {{ $t('text.code') }}: {{ apartment.code }}
                  </p>
                  <div class="text-gray-600 text-xs whitespace-nowrap">
                    {{ apartment.isParking ? $t('text.parkingNumber') : $t('text.doorNumber') }}:
                    {{ apartment.number }}
                    <span v-if="!apartment.isParking" class="ml-3">
                      {{ $t('text.squareSize') }}: {{ apartment.square | square }}
                    </span>
                  </div>
                  <div
                    @click="confirmRemoval(apartment)"
                    class="w-5 h-5 cursor-pointer flex items-center justify-center absolute rounded-full text-white bg-theme-6 right-0 top-0 -mr-2 mt-2"
                  >
                    <xIcon class="w-4 h-4" />
                  </div>
                </div>
              </template>
              <ApartmentSearch
                class="p-5"
                :business="business"
                :building-id="parseInt($route.params.buildingId, 10)"
                :isParking="item.isParking"
                :modalId="`${item.isParking ? 'parking' : 'apartment'}-form-modal`"
                @onApartmentAdd="onItemAdd"
              />
            </div>
          </div>
        </div>
      </form>
    </div>

    <ConfirmDialog :modal-id="deleteModalId" @ok="onDelete" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { OWNER_ADD, OWNER_UPDATE, OWNER_REQUEST } from '@/store/actions/owner';
import { APARTMENT_REMOVE } from '@/store/actions/apartment';
import { validationMixin } from 'vuelidate';
import { required, minLength, email, helpers, minValue } from 'vuelidate/lib/validators';
import Button from '@/components/ui/button/Button';
import PageHeader from '@/components/ui/PageHeader';
import ConfirmDialog from '@/components/ui/ConfirmDialog';
import ApartmentSearch from '@/components/apartment/ApartmentSearch';
import FormOwner from '@/components/form/FormOwner';
import phone from '@/utils/phone';
import registrationNumber from '@/utils/registrationNumber';

export default {
  mixins: [validationMixin],

  components: { Button, FormOwner, PageHeader, ConfirmDialog, ApartmentSearch },

  props: {
    business: Object,
  },

  validations: {
    form: {
      lastName: {
        minLength: minLength(2),
      },
      firstName: {
        required,
        minLength: minLength(2),
      },
      phone: {
        required,
        phoneNumber: helpers.regex('phoneNumber', phone),
      },
      email: {
        email,
      },
      registrationNumber: {
        registrationNumber: helpers.regex('registrationNumber', registrationNumber),
      },
      familySize: {
        minValue: minValue(0),
      },
    },
    apartmentListOfThisOwner: {
      required,
    },
  },

  data() {
    return {
      form: {
        lastName: '',
        firstName: '',
        phone: '',
        email: '',
        registrationNumber: '',
        familySize: 1,
        avatar: '',
        buildingId: this.$route.params.buildingId,
        apartmentList: [],
        cars: [],
        pets: [],
      },
      apartmentToRemove: null,
      apartmentListOfThisOwner: [],
      apartmentTypeList: [
        {
          isParking: false,
          title: this.$t('nav.apartmentShow'),
        },
        {
          isParking: true,
          title: this.$t('nav.parkingShow'),
        },
      ],
      deleteModalId: 'delete-confirm-dialog',
    };
  },

  computed: {
    ...mapGetters(['ownerStatus', 'owner']),
    isLoading() {
      return this.ownerStatus === 'loading';
    },
    title() {
      return this.$route.name === 'OwnerCreate'
        ? this.$t('nav.ownerCreate')
        : this.$t('nav.ownerUpdate');
    },
  },

  async created() {
    await this.fetchData();
  },

  methods: {
    async fetchData() {
      if (this.$route.params.ownerId) {
        const payload = { businessId: this.business.id, id: this.$route.params.ownerId };
        await this.$store.dispatch(OWNER_REQUEST, payload);
        const { owner } = this;
        if (owner) {
          this.form = {
            lastName: owner.lastName,
            firstName: owner.firstName,
            phone: owner.phone,
            email: owner.email,
            registrationNumber: owner.registrationNumber,
            familySize: owner.familySize,
            avatar: owner.avatar,
            cars: owner.cars.map(item => item),
            pets: owner.pets.map(item => item.petTypeId),
          };
          this.apartmentListOfThisOwner = owner.apartments.map(item => item);
        }
      }
    },

    onItemAdd(apartment) {
      this.apartmentListOfThisOwner.push(apartment);
    },

    confirmRemoval(apartment) {
      this.apartmentToRemove = apartment;
      cash(`#${this.deleteModalId}`).modal('show');
    },

    async onDelete() {
      cash(`#${this.deleteModalId}`).modal('hide');
      try {
        if (this.apartmentToRemove.new) {
          const payload = {
            businessId: this.business.id,
            id: this.apartmentToRemove.id,
          };
          await this.$store.dispatch(APARTMENT_REMOVE, payload);
        }
        const index = this.apartmentListOfThisOwner.findIndex(
          item => item.id === this.apartmentToRemove.id
        );
        this.apartmentListOfThisOwner.splice(index, 1);
      } catch (error) {
        this.$toasted.show(error.message, { type: 'error' });
      }
      this.apartmentToRemove = null;
    },

    onPetsChange(value) {
      this.form.pets = value;
    },

    onCarAdd(car) {
      this.form.cars.push(car);
    },

    onCarDelete(car) {
      const index = this.form.cars.findIndex(
        item => item.number === car.number && item.carMakeId === car.carMakeId
      );
      if (index > -1) {
        this.form.cars.splice(index, 1);
      }
    },

    async onSubmit() {
      if (this.$v) {
        this.$v.apartmentListOfThisOwner.$touch();
        this.$v.form.$touch();
        if (this.$v.form.$anyError || this.$v.apartmentListOfThisOwner.$anyError) {
          if (this.$v.form.$anyError) {
            this.$toasted.show(this.$t('toast.failedForm'), { type: 'error' });
            return;
          }

          this.$toasted.show(this.$t('toast.requiredApartmentOrParking'), { type: 'error' });
          return;
        }
      }

      try {
        this.form.apartmentList = this.apartmentListOfThisOwner.map(item => {
          return item.id;
        });

        this.form.pets = this.form.pets.map(item => {
          return { petTypeId: item };
        });

        if (this.$route.params.ownerId) {
          const payload = {
            businessId: this.business.id,
            id: this.owner.id,
            data: this.form,
          };
          await this.$store.dispatch(OWNER_UPDATE, payload);
        } else {
          const payload = {
            businessId: this.business.id,
            data: this.form,
          };
          await this.$store.dispatch(OWNER_ADD, payload);
        }

        if (this.ownerStatus === 'success') {
          this.$router.push({ name: 'Owners' });
          this.$toasted.show(this.$t('toast.success'), { type: 'success' });
        }
      } catch (error) {
        this.$toasted.show(error.message, { type: 'error' });
      }
    },
  },
};
</script>
