<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-5">
      <PageHeader class="text-lg font-medium mr-auto" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <router-link
          v-if="checkPermission('activity_reports.activity_reports.create')"
          :to="{ name: 'ActivityReportCreate' }"
          class="button text-white bg-theme-1 shadow-md mr-2"
        >
          {{ $t('text.create') }}
        </router-link>
      </div>
    </div>
    <div class="intro-y mt-5 overflow-auto lg:overflow-visible">
      <ActivityReportTable
        :loading="isLoading"
        :rows="activityReportList"
        :columns="columns"
        :line-numbers="true"
        :sort="sort"
        :page="page"
        :per-page="perPage"
        :page-size="pageSize"
        :total-page="totalPage"
        :custom-filters="customFilters"
        @delete="confirmRemoval"
        @show="onShowModal($event)"
        @onFilter="onFilter"
        @onPageChange="onPageChange"
        @onPerPageChange="onPerPageChange"
        @onSortChange="onSortChange"
        @onChangeCustomFilter="onChangeCustomFilter"
      />
    </div>

    <ActivityReportModalShow
      ref="activityReportModalShow"
      :title="$t('nav.activityReport')"
      :modal-id="showModalId"
      :report="activityReport"
    />

    <ConfirmDialog
      :modal-id="deleteModalId"
      :is-loading="isDeleting ? true : false"
      @ok="onDelete"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  ACTIVITY_REPORT_LIST_REQUEST,
  ACTIVITY_REPORT_DELETE,
  ACTIVITY_REPORT_SHOW,
} from '@/store/actions/activityReport';
import dataListMixin from '@/mixins/dataListMixin';
import permissionMixin from '@/mixins/permissionMixin';
import PageHeader from '@/components/ui/PageHeader';
import ActivityReportTable from '@/components/activityReports/ActivityReportTable';
import ActivityReportModalShow from '@/components/activityReports/ActivityReportModalShow';

export default {
  mixins: [dataListMixin, permissionMixin],

  data() {
    return {
      storeActionListRequest: ACTIVITY_REPORT_LIST_REQUEST,
      storeActionRemove: ACTIVITY_REPORT_DELETE,
      showModalId: 'activity-report-show-modal',
      columns: [
        { label: this.$t('table.name'), field: 'name', filterable: true },
        { label: this.$t('table.description'), field: 'description', filterable: true },
        { label: this.$t('table.startDate'), field: 'startDate' },
        { label: this.$t('table.endDate'), field: 'endDate' },
        { label: this.$t('table.action'), field: 'action' },
      ],
      customFilters: [
        {
          key: 'startDate',
          value: null,
          label: this.$t('text.selectStartDate'),
          type: 'day',
        },
        {
          key: 'endDate',
          value: null,
          label: this.$t('text.selectEndDate'),
          type: 'day',
        },
      ],
    };
  },

  computed: {
    ...mapGetters([
      'activityReport',
      'activityReportStatus',
      'activityReportList',
      'activityReportListMeta',
      'activityReportListStatus',
    ]),
    isLoading() {
      return this.activityReportListStatus === 'loading';
    },
  },

  async created() {
    await this.fetchData();
  },

  methods: {
    async onShowModal(id) {
      const payload = { id, businessId: this.business ? this.business.id : null };
      await this.$store.dispatch(ACTIVITY_REPORT_SHOW, payload);
      if (this.activityReportStatus === 'success') {
        await cash(`#${this.showModalId}`).modal('show');
      }
    },
    onChangeCustomFilter(object) {
      const index = this.customFilters.findIndex(item => item.key === object.key);
      if (index > -1) {
        const { value } = object;
        this.customFilters[index].value = value || null;
        this.fetchData();
      }
    },
    setFilterValues(data) {
      this.customFilters.forEach(item => {
        data.filters[`filter[${item.key}]`] = item.value;
      });
    },
    setPaginationValues() {
      this.totalRecords = this.activityReportListMeta ? this.activityReportListMeta.total : 1;
      this.page = this.activityReportListMeta.currentPage;
      this.totalPage = this.activityReportListMeta.lastPage;
    },
  },

  components: {
    PageHeader,
    ActivityReportTable,
    ActivityReportModalShow,
  },
};
</script>
