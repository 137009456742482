export const POLLS_LIST_REQUEST = 'POLLS_LIST_REQUEST';
export const POLLS_LIST_REQUEST_SUCCESS = 'POLLS_LIST_REQUEST_SUCCESS';
export const POLLS_LIST_REQUEST_ERROR = 'POLLS_LIST_REQUEST_ERROR';

export const POLLS_ADD = 'POLLS_ADD';
export const POLLS_ADD_SUCCESS = 'POLLS_ADD_SUCCESS';
export const POLLS_ADD_ERROR = 'POLLS_ADD_ERROR';

export const POLLS_SHOW = 'POLLS_SHOW';
export const POLLS_SHOW_SUCCESS = 'POLLS_SHOW_SUCCESS';
export const POLLS_SHOW_ERROR = 'POLLS_SHOW_ERROR';

export const POLLS_UPDATE = 'POLLS_UPDATE';
export const POLLS_UPDATE_SUCCESS = 'POLLS_UPDATE_SUCCESS';
export const POLLS_UPDATE_ERROR = 'POLLS_UPDATE_ERROR';

export const POLLS_DELETE = 'POLLS_DELETE';
export const POLLS_DELETE_SUCCESS = 'POLLS_DELETE_SUCCESS';
export const POLLS_DELETE_ERROR = 'POLLS_DELETE_ERROR';

export const POLLS_ADD_RESPONSES = 'POLLS_ADD_RESPONSES';
export const POLLS_ADD_RESPONSES_SUCCESS = 'POLLS_ADD_RESPONSES_SUCCESS';
export const POLLS_ADD_RESPONSES_ERROR = 'POLLS_ADD_RESPONSES_ERROR';
