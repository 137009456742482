export default {
  props: {
    type: {
      type: String,
      default: 'button',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    to: Object,
  },

  data() {
    return {};
  },

  methods: {
    onClick($event) {
      this.$emit('click', $event);
    },
  },
};
