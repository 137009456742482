<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center">
      <PageHeader class="text-lg font-medium mr-auto" />
    </div>
    <div class="intro-y mt-5 overflow-auto lg:overflow-visible">
      <ReceivableTable
        :loading="isLoading"
        downloadable
        :downloading="isDownloading"
        :rows="computedReceivableList"
        :columns="columns"
        :line-numbers="true"
        :page="page"
        :per-page="perPage"
        :page-size="pageSize"
        :total-page="totalPage"
        :custom-filters="customFilters"
        :footerItems="receivableListFooter"
        @onFilter="onFilter"
        @onPageChange="onPageChange"
        @onPerPageChange="onPerPageChange"
        @onDownload="onDownload"
        @onChangeCustomFilter="onChangeCustomFilter"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { RECEIVABLE_LIST_REQUEST, RECEIVABLE_LIST_EXPORT } from '@/store/actions/report';
import { BUILDING_LIST_REQUEST } from '@/store/actions/building';
import PageHeader from '@/components/ui/PageHeader';
import ReceivableTable from '@/components/receivable/ReceivableTable';
import moment from 'moment';
import { isNaN } from 'lodash';
import dataListMixin from '@/mixins/dataListMixin';

export default {
  mixins: [dataListMixin],

  components: {
    PageHeader,
    ReceivableTable,
  },

  data() {
    return {
      storeActionListRequest: RECEIVABLE_LIST_REQUEST,
      storeActionDownload: RECEIVABLE_LIST_EXPORT,
      sort: false,
      customFilters: [
        {
          key: 'buildingId',
          value: this.business.id,
          label: this.$t('text.selectBuilding'),
          type: 'select',
          options: [],
        },
        {
          key: 'year',
          value: moment().format('YYYY'),
          label: this.$t('text.selectYear'),
          type: 'year',
        },
      ],
    };
  },

  computed: {
    ...mapGetters([
      'receivableList',
      'receivableListStatus',
      'receivableListFooter',
      'receivableMeta',
      'receivableExportStatus',
      'receivableExportLink',
      'buildingStatus',
      'buildingList',
    ]),
    isLoading() {
      return this.receivableListStatus === 'loading';
    },
    isDownloading() {
      return this.receivableExportStatus === 'loading';
    },
    buildingListLoading() {
      return this.buildingStatus === 'loading';
    },
    columns() {
      let array = [];

      const columns = [];

      for (let index = 1; index < 13; index += 1) {
        columns.push({
          label: this.$t('text.monthWithNumber', { number: index }),
          field: index,
        });
      }

      array = [
        {
          label: this.$t('table.doorNumber'),
          field: 'number',
        },
        { label: this.$t('table.holders'), field: 'owners' },
        {
          label: this.$t('table.balance'),
          field: 'balance',
        },
        ...columns,
        {
          label: this.$t('table.total'),
          field: 'total',
        },
      ];
      return array;
    },
    computedReceivableList() {
      const list = this.receivableList.map(receivable => {
        const valueByMonths = [];
        for (let index = 0; index < 12; index += 1) {
          const foundedInvoice = receivable.invoices.find(
            invoice => moment(invoice.startDate).month() === index
          );
          const b = foundedInvoice
            ? this.$options.filters.currency(foundedInvoice.total - foundedInvoice.paidAmount)
            : '0';
          valueByMonths.push(b);
        }
        const objectWithValueByMonths = valueByMonths.reduce((obj, item, index) => {
          return {
            ...obj,
            [index + 1]: item,
          };
        }, {});
        return { ...receivable, ...objectWithValueByMonths };
      });
      return list;
    },
  },

  async created() {
    await this.fetchBuildingList();
    this.fetchData();
  },

  methods: {
    async fetchBuildingList() {
      const payload = {
        businessId: this.business.id,
        data: {
          filters: {},
          sort: {
            field: 'id',
            type: 'desc',
          },
          page: 1,
          perPage: 100,
        },
      };

      await this.$store.dispatch(BUILDING_LIST_REQUEST, payload);
      if (this.buildingStatus === 'success') {
        this.customFilters[0].options = this.buildingList.map(item => item);
      }
    },

    onChangeCustomFilter(object) {
      const index = this.customFilters.findIndex(item => item.key === object.key);
      if (index > -1) {
        this.customFilters[index].value = isNaN(object.value) ? null : object.value;
        this.fetchData();
      }
    },

    setFilterValues(data) {
      this.customFilters.forEach(item => {
        data.filters[`filter[${item.key}]`] = item.value;
      });
    },

    setPaginationValues() {
      this.totalRecords = this.receivableMeta ? this.receivableMeta.total : 1;
      this.page = this.receivableMeta.currentPage;
      this.totalPage = this.receivableMeta.lastPage;
    },

    setDownloads() {
      if (this.receivableExportStatus === 'success') {
        window.open(`${this.receivableExportLink}`, '_blank');
      }
    },
  },
};
</script>
