export const FORUMS_LIST_REQUEST = 'FORUMS_LIST_REQUEST';
export const FORUMS_LIST_REQUEST_SUCCESS = 'FORUMS_LIST_REQUEST_SUCCESS';
export const FORUMS_LIST_REQUEST_ERROR = 'FORUMS_LIST_REQUEST_ERROR';

export const FORUMS_ADD = 'FORUMS_ADD';
export const FORUMS_ADD_SUCCESS = 'FORUMS_ADD_SUCCESS';
export const FORUMS_ADD_ERROR = 'FORUMS_ADD_ERROR';

export const FORUMS_SHOW = 'FORUMS_SHOW';
export const FORUMS_SHOW_SUCCESS = 'FORUMS_SHOW_SUCCESS';
export const FORUMS_SHOW_ERROR = 'FORUMS_SHOW_ERROR';

export const FORUMS_UPDATE = 'FORUMS_UPDATE';
export const FORUMS_UPDATE_SUCCESS = 'FORUMS_UPDATE_SUCCESS';
export const FORUMS_UPDATE_ERROR = 'FORUMS_UPDATE_ERROR';

export const FORUMS_DELETE = 'FORUMS_DELETE';
export const FORUMS_DELETE_SUCCESS = 'FORUMS_DELETE_SUCCESS';
export const FORUMS_DELETE_ERROR = 'FORUMS_DELETE_ERROR';
