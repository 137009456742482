<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-5">
      <PageHeader class="text-lg font-medium mr-auto" />
    </div>
    <div class="intro-y mt-5 overflow-auto lg:overflow-visible">
      <SmsTemplatesTable
        :loading="isLoading"
        :rows="smsTemplateList"
        :columns="columns"
        :line-numbers="true"
        :sort="sort"
        :page="page"
        :per-page="perPage"
        :page-size="pageSize"
        :total-page="totalPage"
        :custom-filters="customFilters"
        @show="onShowModal($event)"
        @pay="onShowModal($event, 'pay')"
        @onFilter="onFilter"
        @onPageChange="onPageChange"
        @onPerPageChange="onPerPageChange"
        @onSortChange="onSortChange"
        @onDownload="onDownload"
        @onChangeCustomFilter="onChangeCustomFilter"
      />
    </div>
    <ConfirmDialog
      :modal-id="deleteModalId"
      :is-loading="isDeleting ? true : false"
      @ok="onDelete"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SMS_TEMPLATE_LIST_REQUEST } from '@/store/actions/smsTemplate';
import { BUSINESS_LIST_REQUEST } from '@/store/actions/business';
import dataListMixin from '@/mixins/dataListMixin';
import PageHeader from '@/components/ui/PageHeader';
import SmsTemplatesTable from '@/components/smsTemplates/SmsTemplatesTable';

export default {
  mixins: [dataListMixin],
  components: { PageHeader, SmsTemplatesTable },
  data() {
    return {
      storeActionListRequest: SMS_TEMPLATE_LIST_REQUEST,
      columns: [
        { label: 'id', field: 'id' },
        { label: this.$t('nav.business'), field: 'businessName', filterable: true },
        { label: 'key', field: 'key' },
        { label: this.$t('table.text'), field: 'text' },
        { label: this.$t('table.status'), field: 'isActive' },
        { label: this.$t('table.createdDate'), field: 'createdAt' },
        { label: this.$t('table.action'), field: 'action' },
      ],
      customFilters: [
        {
          key: 'businessId',
          value: null,
          label: this.$t('text.selectBusiness'),
          type: 'select',
          options: [],
        },
        {
          key: 'hasQpay',
          value: null,
          label: this.$t('text.hasQPayOrNot'),
          type: 'select',
          options: [
            {
              id: 1,
              name: this.$t('text.yes'),
            },
            {
              id: 0,
              name: this.$t('text.no'),
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'smsTemplateList',
      'smsTemplateListStatus',
      'smsTemplateListMeta',
      'businessStatus',
      'businessList',
    ]),
    isLoading() {
      return this.smsTemplateListStatus === 'loading';
    },
  },
  async created() {
    this.fetchBusinessList();
    await this.fetchData();
  },
  methods: {
    async fetchBusinessList() {
      const payload = {
        data: {
          sort: {
            field: 'id',
            type: 'desc',
          },
          page: 1,
          perPage: 200,
          filters: {},
        },
      };
      await this.$store.dispatch(BUSINESS_LIST_REQUEST, payload);
      if (this.businessStatus === 'success') {
        this.customFilters[0].options = this.businessList.map(item => item);
      }
    },
    onChangeCustomFilter(object) {
      const index = this.customFilters.findIndex(item => item.key === object.key);
      if (index > -1) {
        const { value } = object;
        this.customFilters[index].value = value || null;
        this.fetchData();
      }
    },
    setFilterValues(data) {
      this.customFilters.forEach(item => {
        if (item.value) {
          data.filters[`filter[${item.key}]`] = item.value;
        }
      });
    },
    setPaginationValues() {
      this.totalRecords = this.smsTemplateListMeta ? this.smsTemplateListMeta.total : 1;
      this.page = this.smsTemplateListMeta.currentPage;
      this.totalPage = this.smsTemplateListMeta.lastPage;
    },
  },
};
</script>
