<template>
  <div class="cursor-pointer">
    <div class="flex items-start px-5 pt-5 mb-5">
      <div class="w-full flex flex-col lg:flex-row items-center">
        <div class="flex-1">
          <h3 class="text-base font-medium text-blue-700">{{ meeting.name }}</h3>
          <span class="text-sm">хурлын сэдэв</span>
        </div>
        <div class="flex-3 w-18 h-10 hidden">
          <div class="flex">
            <div class="flex-1 pt-1.5 pb-1.5 border rounded-full border-gray-400 mr-1">
              <CheckSquareIcon class="w-4 h-4 mr-1 hidden sm:block ml-2" />
            </div>
            <div class="flex-1 pt-1.5 pb-1.5 border rounded-full border-gray-400">
              <Trash2Icon class="w-4 h-4 mr-1 hidden sm:block ml-2" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row px-5 pb-5">
      <div class="flex-1">
        <h3 class="text-base font-medium text-blue-700">{{ meeting.scheduledAt }}</h3>
        <span class="text-sm">болох огноо</span>
      </div>
      <div class="flex-1 text-right">
        <h3 class="text-base font-medium text-blue-700">{{ getHours }} цаг</h3>
        <span class="text-sm">үргэлжлэх хугацаа</span>
      </div>
    </div>
    <div class="border-t border-gray-200 dark:border-dark-5 flex items-center justify-between">
      <div class="flex flex-grow">
        <div class="w-1/4 w-25 mt-3 mb-3 ml-5 mr-1 text-xs">
          Хамрах хүрээ:
        </div>
        <div class="w-3/4">
          <div class="" v-for="(building, index) in meeting.buildings" :key="index">
            <span
              class="w-auto float-left mt-3 mb-3 border rounded-md border-gray-400 px-2 mr-2 text-xs text-blue-700"
            >
              {{ building.name }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import permissionMixin from '@/mixins/permissionMixin';

export default {
  mixins: [permissionMixin],

  props: {
    meeting: Object,
  },

  computed: {
    getHours() {
      return this.meeting.duration / 60;
    },
  },
};
</script>
