import Vue from 'vue';
import Vuex from 'vuex';
import user from '@/store/modules/user';
import auth from '@/store/modules/auth';
import sideMenu from '@/store/modules/sideMenu';
import account from '@/store/modules/account';
import darkSwitcher from '@/store/modules/darkSwitcher';
import localUnits from '@/store/modules/localUnits';
import building from '@/store/modules/building';
import owner from '@/store/modules/owner';
import apartment from '@/store/modules/apartment';
import serviceProduct from '@/store/modules/serviceProduct';
import invoice from '@/store/modules/invoice';
import expense from '@/store/modules/expense';
import report from '@/store/modules/report';
import ticket from '@/store/modules/ticket';
import file from '@/store/modules/file';
import business from '@/store/modules/business';
import dashboard from '@/store/modules/dashboard';
import service from '@/store/modules/service';
import importer from '@/store/modules/importer';
import banks from '@/store/modules/banks';
import order from '@/store/modules/order';
import forums from '@/store/modules/forums';
import announcements from '@/store/modules/announcements';
import polls from '@/store/modules/polls';
import smsPackages from '@/store/modules/smsPackages';
import parkings from '@/store/modules/parkings';
import firebase from '@/store/modules/firebase';
import admin from '@/store/modules/admin';
import ticketCategory from '@/store/modules/ticketCategory';
import expenseType from '@/store/modules/expenseType';
import ticketType from '@/store/modules/ticketType';
import meeting from '@/store/modules/meeting';
import banner from '@/store/modules/banner';
import smsTemplate from '@/store/modules/smsTemplate';
import activityReport from '@/store/modules/activityReport';
import appVersion from '@/store/modules/appVersion';
import transaction from '@/store/modules/transaction';
import smsBalance from '@/store/modules/smsBalance';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    user,
    auth,
    sideMenu,
    account,
    darkSwitcher,
    localUnits,
    building,
    owner,
    apartment,
    serviceProduct,
    invoice,
    expense,
    report,
    ticket,
    file,
    business,
    dashboard,
    service,
    importer,
    banks,
    order,
    forums,
    announcements,
    polls,
    smsPackages,
    parkings,
    firebase,
    admin,
    ticketCategory,
    expenseType,
    ticketType,
    meeting,
    banner,
    smsTemplate,
    activityReport,
    appVersion,
    transaction,
    smsBalance,
  },
  strict: debug,
});
