<template>
  <component
    :is="tag"
    @click="onClick"
    :type="tag === 'button' && type"
    :to="to"
    class="button inline-flex items-center justify-center"
    :class="{ 'is-loading': loading }"
    :disabled="loading || disabled"
  >
    <slot />
    <LoadingIcon v-if="loading" icon="oval" color="white" class="button-loader" />
  </component>
</template>

<script>
import baseButtonMixin from '@/mixins/baseButtonMixin';

export default {
  mixins: [baseButtonMixin],
  computed: {
    tag() {
      if (this.to) {
        return 'router-link';
      }
      return 'button';
    },
  },
};
</script>
