import Client from '@/clients/apiClient';
import humps from 'humps';
import { stringify } from 'query-string';

const resourceBase = 'business';
const resource = 'announcements';
const resourceSms = 'send-sms';

export default {
  get(businessId, payload) {
    return Client.get(
      `${resourceBase}/${businessId}/${resource}?page=${payload.page}&limit=${payload.perPage}
        &sort[field]=${humps.decamelize(payload.sort.field)}&sort[type]=${payload.sort.type}
        &${stringify(humps.decamelizeKeys(payload.filters))}`
    );
  },
  create(businessId, payload) {
    return Client.post(`${resourceBase}/${businessId}/${resource}`, payload);
  },
  categories(businessId) {
    return Client.get(`${resourceBase}/${businessId}/announcement/category`);
  },
  show(businessId, id) {
    return Client.get(`${resourceBase}/${businessId}/${resource}/${id}`);
  },
  update(businessId, id, payload) {
    return Client.put(`${resourceBase}/${businessId}/${resource}/${id}`, payload);
  },
  delete(businessId, id) {
    return Client.delete(`${resourceBase}/${businessId}/${resource}/${id}`);
  },
  sendSms(payload) {
    return Client.post(`${resourceSms}`, payload);
  },
};
