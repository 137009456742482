import FinanceIndex from '@/views/Finance/FinanceIndex';
import Finance from '@/views/Finance/Finance';
import Invoices from '@/views/Finance/Invoices';
import InvoiceForm from '@/views/Finance/InvoiceForm';
import ExpenseList from '@/views/Finance/ExpenseList';
import ExpenseForm from '@/views/Finance/ExpenseForm';
import ServiceProducts from '@/views/Finance/ServiceProducts';
import ServiceProductForm from '@/views/Finance/ServiceProductForm';
import Receivables from '@/views/Finance/Receivables';
import ReceivableForm from '@/views/Finance/ReceivableForm';
import Statement from '@/views/Finance/Statement';
import Transactions from '@/views/Finance/Transactions';

export default [
  {
    path: '/finance',
    component: FinanceIndex,
    meta: { group: 'finance' },
    children: [
      {
        path: '',
        component: Finance,
        children: [
          { path: '', name: 'InvoiceIncome', component: Invoices },
          { path: 'receivables', name: 'Receivables', component: Receivables },
          { path: 'expenses', name: 'Expenses', component: ExpenseList },
          { path: 'service-products', name: 'ServiceProducts', component: ServiceProducts },
          { path: 'transactions', name: 'Transactions', component: Transactions },
          { path: 'statement', name: 'Statement', component: Statement },
        ],
      },
      { path: 'create-invoice', name: 'InvoiceCreate', component: InvoiceForm },
      { path: 'update-invoice/:invoiceId', name: 'InvoiceUpdate', component: InvoiceForm },
      { path: 'receivables/create', name: 'ReceivableCreate', component: Receivables },
      {
        path: 'receivables/update/:receivableId',
        name: 'ReceivableUpdate',
        component: ReceivableForm,
      },
      {
        path: 'create-expense',
        name: 'ExpenseCreate',
        component: ExpenseForm,
      },
      {
        path: 'update-expense/:expenseId',
        name: 'ExpenseUpdate',
        component: ExpenseForm,
      },
      {
        path: 'service-products/create',
        name: 'ServiceProductCreate',
        component: ServiceProductForm,
      },
      {
        path: 'service-products/update/:serviceProductId',
        name: 'ServiceProductUpdate',
        component: ServiceProductForm,
      },
    ],
  },
];
