<template>
  <div class="top-bar">
    <div class="-intro-x breadcrumb mr-auto hidden sm:flex">
      <a>{{ group }}</a>
      <ChevronRightIcon class="breadcrumb__icon" />
      <a class="breadcrumb--active">{{ title }}</a>
    </div>

    <DropDown
      class="intro-x w-8 h-8 relative"
      toggler-class="w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in"
      box-class="w-56"
      content-class="bg-theme-38 text-white p-0"
      toggler-tag="div"
    >
      <template #toggler>
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyJyjgXzQ3EUUR0l8ZPcGdn-xvkArS4lnOWA&usqp=CAU"
        />
      </template>

      <div class="p-4 border-b border-theme-40 dark:border-dark-3">
        <div class="font-medium">
          <template v-if="isProfileLoaded">{{ me | fullName }}</template>
        </div>
      </div>
      <div class="p-2">
        <a
          href="#"
          class="flex items-center p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
          @click="userProfile"
        >
          <UserIcon class="w-4 h-4 mr-2" />{{ $t('topBar.profile') }}
        </a>
        <a
          href="#"
          class="flex items-center p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
          @click="isAuthResetPassword"
        >
          <LockIcon class="w-4 h-4 mr-2" />{{ $t('topBar.resetPassword') }}
        </a>
      </div>
      <div class="p-2 border-t border-theme-40 dark:border-dark-3">
        <a
          href
          @click="logout"
          class="flex items-center p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
        >
          <ToggleRightIcon class="w-4 h-4 mr-2" />
          {{ $t('auth.logout') }}
        </a>
      </div>
    </DropDown>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { AUTH_LOGOUT } from '@/store/actions/auth';
import humps from 'humps';
import DropDown from '@/components/ui/DropDown';

export default {
  name: 'TopBar',

  components: { DropDown },

  computed: {
    ...mapGetters(['me', 'isProfileLoaded']),
    title() {
      let group = '';
      if (this.$route.matched.length > 1 && this.$route.matched[1].meta?.group) {
        group = this.$route.matched[1].meta?.group;
      } else {
        group = humps.camelize(this.$route.name);
      }
      return this.$t(`nav.${group}`);
    },
    group() {
      return this.$t('nav.main');
    },
  },
  methods: {
    async logout() {
      await this.$store.dispatch(AUTH_LOGOUT);
    },
    isAuthResetPassword() {
      this.$router.push({ name: 'userResetPassword', params: { userId: this.me.id } });
    },
    userProfile() {
      this.$router.push({ name: 'userUpdate', params: { userId: this.me.id } });
    },
  },
};
</script>
