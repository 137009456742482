<template>
  <div class="p-5">
    <InputCarNumber
      :label="$t('text.carNumber')"
      v-model.trim="form.vehicleNumber.$model"
      :vuelidate="form.vehicleNumber"
      input-class="input-parking-vehicle-number"
    />
    <InputString
      :label="$t('text.parkingNumber')"
      v-model.trim="form.number.$model"
      :vuelidate="form.number"
      input-class="input-parking-number"
    />
    <InputMoney
      :label="$t('text.garageFee')"
      v-model.trim="form.fee.$model"
      :vuelidate="form.fee"
      input-class="input-parking-fee"
    />
  </div>
</template>

<script>
import InputCarNumber from '@/components/ui/input/InputCarNumber';
import InputString from '@/components/ui/input/InputString';
import InputMoney from '@/components/ui/input/InputMoney';

export default {
  components: {
    InputCarNumber,
    InputString,
    InputMoney,
  },

  props: {
    form: Object,
  },
};
</script>
