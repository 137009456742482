<template>
  <div class="p-5">
    <InputSelect
      v-model.number="form.carMakeId.$model"
      :options="carMakes"
      :label="$t('text.carMake')"
      value-key="id"
      :vuelidate="form.carMakeId"
      :disabled="carMakes.length < 1"
      input-class="input-select-car-make"
      @input="onChangeCarMake()"
    />

    <InputSelect
      v-model.number="form.carModelId.$model"
      :options="models"
      :label="$t('text.carModel')"
      value-key="id"
      :vuelidate="form.carModelId"
      input-class="input-select-cart-model"
    />

    <InputCarNumber
      :label="$t('text.carNumber')"
      v-model.trim="form.number.$model"
      :vuelidate="form.number"
      input-class="input-car-number"
    />
  </div>
</template>

<script>
import InputSelect from '@/components/ui/input/InputSelect';
import InputCarNumber from '@/components/ui/input/InputCarNumber';

export default {
  components: { InputSelect, InputCarNumber },

  props: {
    form: Object,
    carMakes: Array,
  },

  data() {
    return {
      models: [],
    };
  },

  methods: {
    onChangeCarMake() {
      this.form.carModelId.$model = null;
      const index = this.carMakes.findIndex(item => item.id === this.form.carMakeId.$model);
      if (index > -1) {
        this.models = this.carMakes[index].models;
      } else {
        this.models = [];
      }
    },
  },
};
</script>
