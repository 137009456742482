<template>
  <div>
    <div class="intro-y flex items-center mt-5">
      <PageHeader class="text-lg font-medium mr-auto" />
    </div>
    <div class="intro-y grid grid-cols-12 gap-6">
      <div class="col-span-6 lg:col-span-6 xxl:col-span-4">
        <BusinessDetails :business="business" class="h-full" />
      </div>
      <div class="col-span-6 lg:col-span-6 xxl:col-span-4">
        <BusinessBankAccount
          :bank-accounts="business.bankAccounts"
          :businessId="business.id"
          class="h-full"
        />
      </div>
      <div class="col-span-6 lg:col-span-6 xxl:col-span-4">
        <BusinessSmsInfo :business="business" class="h-full" />
      </div>
    </div>
    <div class="intro-y mt-10">
      <BusinessSmsPackageList :business="business" />
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/ui/PageHeader';
import BusinessDetails from '@/components/business/details/BusinessDetails';
import BusinessBankAccount from '@/components/business/account/BusinessBankAccount';
import BusinessSmsInfo from '@/components/business/sms/BusinessSmsInfo';
import BusinessSmsPackageList from '@/components/business/sms/BusinessSmsPackageList';
// import BusinessMyProfile from '@/components/business/BusinessMyProfile';

export default {
  props: {
    business: Object,
  },

  components: {
    PageHeader,
    BusinessDetails,
    BusinessBankAccount,
    BusinessSmsInfo,
    BusinessSmsPackageList,
  },

  created() {
    if (this.business.bankAccounts.length === 0) {
      this.business.bankAccounts = [
        {
          accountName: this.$t('text.bankAccountName'),
          accountNumber: this.$t('text.bankAccountNumber'),
          bank: {
            name: 'bank-ss oruulah',
          },
        },
      ];
    }
  },
};
</script>
