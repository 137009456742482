<template>
  <div class="field">
    <div class="flex flex-col sm:flex-row">
      <div class="flex items-center text-gray-700 dark:text-gray-500 mr-2 mt-2">
        <input
          type="checkbox"
          id="checkbox"
          :checked="isChecked"
          @change="onChangeChecked"
          class="input border mr-2"
        />
        <label for="checkbox" class="cursor-pointer select-none">
          {{ label }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import baseInputMixin from '@/mixins/baseInputMixin';

export default {
  mixins: [baseInputMixin],
  props: {
    checked: Boolean,
    isBoolean: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isChecked() {
      return this.value;
    },
  },

  methods: {
    onChangeChecked($event) {
      if (this.isBoolean) {
        const value = !!$event.target.checked;
        this.$emit('input', value);
        return;
      }
      const value = $event.target.checked ? 1 : 0;
      this.$emit('input', value);
    },
  },
};
</script>
