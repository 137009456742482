<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-5">
      <PageHeader class="text-lg font-medium mr-auto" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <router-link
          :to="{ name: 'AppVersionCreate' }"
          class="button text-white bg-theme-1 shadow-md mr-2"
        >
          {{ $t('text.create') }}
        </router-link>
      </div>
    </div>
    <div class="intro-y mt-5 overflow-auto lg:overflow-visible">
      <AppVersionsTable
        :loading="isLoading"
        :rows="appVersionList"
        :columns="columns"
        :line-numbers="true"
        :sort="sort"
        :page="page"
        :per-page="perPage"
        :page-size="pageSize"
        :total-page="totalPage"
        @delete="confirmRemoval"
        @onFilter="onFilter"
        @onPageChange="onPageChange"
        @onPerPageChange="onPerPageChange"
        @onSortChange="onSortChange"
      />
    </div>
    <ConfirmDialog
      :modal-id="deleteModalId"
      :is-loading="isDeleting ? true : false"
      @ok="onDelete"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { APP_VERSION_LIST_REQUEST, APP_VERSION_DELETE } from '@/store/actions/appVersion';
import dataListMixin from '@/mixins/dataListMixin';
import PageHeader from '@/components/ui/PageHeader';
import AppVersionsTable from '@/components/appVersions/AppVersionsTable';

export default {
  mixins: [dataListMixin],

  data() {
    return {
      storeActionListRequest: APP_VERSION_LIST_REQUEST,
      storeActionRemove: APP_VERSION_DELETE,
      columns: [
        { label: this.$t('table.os'), field: 'os' },
        { label: this.$t('table.type'), field: 'type' },
        { label: this.$t('table.version'), field: 'latestVersion' },
        { label: this.$t('table.forceUpgrade'), field: 'forceUpgrade' },
        { label: this.$t('table.text'), field: 'message' },
        { label: this.$t('table.action'), field: 'action' },
      ],
    };
  },

  computed: {
    ...mapGetters(['appVersionList', 'appVersionListMeta', 'appVersionListStatus']),
    isLoading() {
      return this.appVersionListStatus === 'loading';
    },
  },

  async created() {
    await this.fetchData();
  },

  methods: {
    setPaginationValues() {
      this.totalRecords = this.appVersionListMeta ? this.appVersionListMeta.total : 1;
      this.page = this.appVersionListMeta.currentPage;
      this.totalPage = this.appVersionListMeta.lastPage;
    },
  },

  components: {
    PageHeader,
    AppVersionsTable,
  },
};
</script>
