<template>
  <ModalEdit :id="modalId" title="Шинэ санал асуулга" :loading="loading" @click="onSubmit">
    <div class="p-5">
      <div class="intro-y col-span-12 sm:col-span-12">
        <InputString
          :label="$t('text.title')"
          v-model.trim="form.title"
          :vuelidate="validations.form.title"
          input-class="input-poll-title"
        />
        <InputString
          :label="$t('text.description')"
          v-model.trim="form.description"
          :vuelidate="validations.form.description"
          input-class="input-poll-description"
          is-textarea
        />
      </div>

      <label class="flex flex-col sm:flex-row mt-4 justify-between items-center">
        <span>Асуултууд</span>
        <button @click="addQuestion">
          <PlusIcon />
        </button>
      </label>
      <div
        v-for="(question, idx) in form.questions"
        :key="`question-${idx}`"
        class="flex items-start bg-gray-200 border border-gray-200 overflow-hidden rounded my-4"
      >
        <div class="p-2">
          <button @click="removeQuestion(question)" class="text-red-600">
            <Trash2Icon />
          </button>
        </div>
        <div class="w-full flex flex-col gap-3 bg-white px-4 py-2">
          <InputString
            v-model.trim="question.title"
            label="Асуултын гарчиг"
            class="w-full"
            input-class="input-poll-question-title"
          />
          <div>
            <span>Хариулт</span>
          </div>
          <div
            :key="`question-${idx}-option-${optionIdx}`"
            v-for="(option, optionIdx) in question.answers"
            class="flex items-center gap-2 bg-gray-200 rounded-lg overflow-clip"
          >
            <button @click="removeOption(question, option)" class="ml-2 text-red-600">
              <Trash2Icon />
            </button>
            <InputString
              label=""
              v-model.trim="option.answer"
              class="w-full rounded-r-lg rounded-l-none"
              input-class="input-poll-answer"
            />
          </div>
          <button
            @click="addOption(question)"
            class="flex items-center justify-center gap-2 bg-gray-200 rounded-lg px-4 py-2 text-center text-gray-800"
          >
            <PlusCircleIcon size="18" />
            Хариулт нэмэх
          </button>
        </div>
      </div>
    </div>
  </ModalEdit>
</template>

<script>
import ModalEdit from '@/components/ui/ModalEdit';
import InputString from '@/components/ui/input/InputString';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin],
  name: 'PollCreateModal',
  components: {
    ModalEdit,
    InputString,
  },
  props: ['formData', 'modalId'],
  watch: {
    $props: {
      handler() {
        this.form = {
          title: this._.get(this.formData, 'title', ''),
          description: this._.get(this.formData, 'description', ''),
          questions: this._.get(this.formData, 'questions', []),
        };
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      loading: false,
      form: {
        name: this._.get(this.formData, 'name', ''),
        description: this._.get(this.formData, 'description', ''),
        questions: this._.get(this.formData, 'questions', []),
      },
      validations: {
        form: {
          title: {
            required,
          },
          description: {
            required,
          },
          closingDate: {
            required,
          },
          buildingId: {
            required,
          },
          questions: {
            required,
          },
        },
        selectedApartments: {
          required,
        },
      },
    };
  },
  methods: {
    addQuestion() {
      this.form.questions.push({
        title: '',
        answers: [],
      });
    },
    removeQuestion(option) {
      const idx = this.form.questions.indexOf(option);
      this.form.questions.splice(idx, 1);
    },
    addOption(question) {
      question.answers.push({
        answer: '',
      });
    },
    removeOption(question, option) {
      const idx = question.answers.indexOf(option);
      question.answers.splice(idx, 1);
    },
    reset() {
      this.form = {
        name: '',
        description: '',
        questions: [],
      };
    },
    onSubmit() {
      // console.log(this.form);
      // this.$emit('submit', this.form);
      // if (this.formData === null) this.reset();
    },
  },
};
</script>
