<template>
  <PieChart :width="width" :height="height" :chart-data="data" />
</template>

<script>
import PieChart from '@/components/ui/chart/PieChart';

export default {
  components: { PieChart },
  props: {
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
    statistics: Array,
    headers: Array,
  },
  computed: {
    data() {
      const colors = this.headers.map(item => this.getColor(item));
      const headers = this.headers.map(item => this.getLabel(item));
      return {
        labels: headers,
        datasets: [
          {
            data: this.statistics,
            backgroundColor: colors,
            hoverBackgroundColor: colors,
            borderWidth: 1,
            borderColor: '#fff',
          },
        ],
      };
    },
  },
  methods: {
    getColor(status) {
      let color = '';
      switch (status) {
        case 'PENDING':
          color = '#FFA500';
          break;
        case 'RETURNED':
          color = '#C6D4FD';
          break;
        case 'CONFIRMED':
          color = '#07ab43';
          break;
        case 'ASSIGNED':
          color = '#3160D8';
          break;
        case 'FINISHED':
          color = '#4ab3f4';
          break;
        case 'APPROVED':
          color = '#91C714';
          break;
        case 'DECLINED':
          color = '#D32929';
          break;
        case 'OTHER':
          color = '#718096';
          break;

        default:
          break;
      }

      return color;
    },

    getLabel(status) {
      return this.$t(`statusTicketLabels.${status.toLowerCase()}`);
    },
  },
};
</script>
