<template>
  <div>
    <p class="text-xl font-medium text-gray-600 mb-4">{{ title }}</p>
    <form @submit.prevent="onSubmit">
      <div class="intro-x">
        <InputString
          :placeholder="$t('auth.lastName')"
          v-model.trim="$v.formData.lastName.$model"
          :vuelidate="$v.formData.lastName"
          input-class="input-register-last-name login__input input--lg"
        />

        <InputString
          :placeholder="$t('auth.firstName')"
          v-model.trim="$v.formData.firstName.$model"
          :vuelidate="$v.formData.firstName"
          input-class="input-register-first-name login__input input--lg"
        />

        <InputString
          :placeholder="$t('auth.phone')"
          :value="$v.formData.phone.$model"
          :vuelidate="$v.formData.phone"
          input-class="input-register-phone login__input input--lg"
          :invalidFeedback="phoneInvalidFeedback"
          :max-length="8"
          @focusout="setPhone($event.target.value)"
        />

        <InputString
          :placeholder="$t('auth.email')"
          :value="$v.formData.email.$model"
          :vuelidate="$v.formData.email"
          :invalidFeedback="emailInvalidFeedback"
          input-class="input-register-email login__input input--lg"
          @focusout="setEmail($event.target.value)"
        />
      </div>
      <div class="intro-x mt-5 xl:mt-8">
        <Button type="submit" class="button--lg w-full text-white bg-theme-1" :loading="isLoading">
          <span>{{ $t('auth.continue') }}</span>
        </Button>
      </div>
      <div class="intro-x mt-3 xl:mt-5">
        <Button
          :to="{ name: 'UserLogin' }"
          class="button--lg w-full xl:w-32 text-gray-700 border border-gray-300 dark:border-dark-5 dark:text-gray-300"
        >
          <span>{{ $t('auth.login') }}</span>
        </Button>
      </div>
    </form>
  </div>
</template>

<script>
import InputString from '@/components/ui/input/InputString';
import Button from '@/components/ui/button/Button';
import { validationMixin } from 'vuelidate';
import { required, minLength, helpers, email } from 'vuelidate/lib/validators';
import { USER_VALIDATE } from '@/store/actions/user';
import stepMixin from '@/mixins/stepMixin';
import phone from '@/utils/phone';

export default {
  components: { Button, InputString },

  mixins: [validationMixin, stepMixin],

  validations: {
    formData: {
      lastName: {
        required,
        minLength: minLength(2),
      },
      firstName: {
        required,
        minLength: minLength(2),
      },
      phone: {
        required,
        phoneNumber: helpers.regex('phoneNumber', phone),
        async isUnique(value) {
          if (value === '') return true;
          try {
            const data = { phone: this.formData.phone };
            await this.$store.dispatch(USER_VALIDATE, data);
            return true;
          } catch (e) {
            if (e.response.status === 422) {
              const firstError = { index: 0 };
              if (e.response.data.errors.phone) {
                [firstError.index] = e.response.data.errors.phone;
                this.phoneInvalidFeedback = firstError.index;
              } else {
                return true;
              }
            }
            return false;
          }
        },
      },
      email: {
        required,
        email,
        async isUnique(value) {
          if (value === '') return true;
          try {
            const data = { email: this.formData.email };
            await this.$store.dispatch(USER_VALIDATE, data);
            return true;
          } catch (e) {
            if (e.response.status === 422) {
              const firstError = { index: 0 };
              if (e.response.data.errors.email) {
                [firstError.index] = e.response.data.errors.email;
                this.emailInvalidFeedback = firstError.index;
              } else {
                return true;
              }
            }
            return false;
          }
        },
      },
    },
  },

  data() {
    return {
      phoneInvalidFeedback: '',
      emailInvalidFeedback: '',
    };
  },

  methods: {
    setPhone(value) {
      this.$v.formData.phone.$model = value;
      this.$v.formData.phone.$touch();
    },
    setEmail(value) {
      this.$v.formData.email.$model = value;
      this.$v.formData.email.$touch();
    },
  },
};
</script>
