<template>
  <ModalEdit :id="modalId" :title="title" :loading="isLoading" @click="onSubmit">
    <div class="p-5">
      <InputFile
        ref="inputFile"
        :label="$t('text.chooseFile')"
        :value="$v.form.file.$model"
        accept-files="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv"
        :vuelidate="$v.form.file"
        class="input-file-expense-upload"
        @upload="setFile"
      />
      <div v-if="serverErrors" class="text-theme-6 mt-2">
        <p v-for="(error, index) in serverErrors" :key="index">{{ error[0] }}</p>
      </div>
    </div>
  </ModalEdit>
</template>

<script>
import { mapGetters } from 'vuex';
import { BUILDING_CHILD_LIST_IMPORT } from '@/store/actions/building';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import ModalEdit from '@/components/ui/ModalEdit';
import baseModalMixin from '@/mixins/baseModalMixin';
import InputFile from '@/components/ui/input/InputFile';
import { VALIDATION_ERROR_CODE } from '@/utils/error';

export default {
  mixins: [validationMixin, baseModalMixin],

  components: { ModalEdit, InputFile },

  props: {
    business: Object,
    title: String,
  },

  validations: {
    form: {
      file: {
        required,
      },
    },
  },

  data() {
    return {
      form: {
        file: '',
        buildingId: this.$route.params.buildingId,
      },
      serverErrors: [],
    };
  },

  computed: {
    ...mapGetters(['buildingChildImportStatus']),
    isLoading() {
      return this.buildingChildImportStatus === 'loading';
    },
  },

  methods: {
    resetForm() {
      this.form = {
        file: '',
        buildingId: this.$route.params.buildingId,
      };
      this.$v.$reset();
      this.serverErrors = [];
      this.$refs.inputFile.resetField();
    },

    setFile(value) {
      this.$v.form.file.$model = value;
      this.form.file = value;
      this.$v.form.file.$touch();
    },

    async onSubmit() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          this.$toasted.show(this.$t('toast.failedForm'), { type: 'error' });
          return;
        }
      }

      try {
        const formData = new FormData();
        formData.append('file', this.form.file);
        formData.append('building_id', this.form.buildingId);
        if (this.$route.name !== 'Owners') {
          formData.append('is_parking', this.$route.name !== 'Apartments' ? 1 : 0);
        }
        const payload = {
          businessId: this.business.id,
          type: this.$route.name === 'Owners' ? 'owners' : 'apartments',
          data: formData,
        };
        await this.$store.dispatch(BUILDING_CHILD_LIST_IMPORT, payload);

        if (this.buildingChildImportStatus === 'success') {
          this.$toasted.show(this.$t('toast.success'), { type: 'success' });
          this.hideEditModal();
          this.$emit('onImportSuccess');
          this.resetForm();
        }
      } catch (error) {
        if (error.response.status === VALIDATION_ERROR_CODE) {
          this.serverErrors = error.response.data.errors;
        }
        this.$toasted.show(error.message, { type: 'error' });
      }
    },
  },
};
</script>
