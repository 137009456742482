<template>
  <div>
    <BusinessSmsPackageTable
      :loading="isLoading"
      :rows="smsPackageInvoiceList"
      :columns="columns"
      :line-numbers="true"
      :sort="sort"
      :page="page"
      :per-page="perPage"
      :page-size="pageSize"
      :total-page="totalPage"
      @delete="confirmRemoval"
      @onFilter="onFilter"
      @onPageChange="onPageChange"
      @onPerPageChange="onPerPageChange"
      @onSortChange="onSortChange"
      @show="onShowModal"
      @pay="onShowModal($event, 'pay')"
    />
    <BusinessSmsLogsModal ref="smsLogsModal" :modal-id="smsLogsModalId" :business="business" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SMS_PACKAGE_INVOICE_LIST_REQUEST } from '@/store/actions/smsPackage';
import dataListMixin from '@/mixins/dataListMixin';
import BusinessSmsPackageTable from '@/components/business/sms/BusinessSmsPackageTable';
import BusinessSmsLogsModal from '@/components/business/sms/BusinessSmsLogsModal';

export default {
  mixins: [dataListMixin],

  props: {
    business: Object,
  },

  data() {
    return {
      storeActionListRequest: SMS_PACKAGE_INVOICE_LIST_REQUEST,
      perPage: 10,
      smsPackageModalId: 'subscribe-sms-list-dialog',
      packageId: 0,
      columns: [
        { label: this.$t('table.smsPackageAdded'), field: 'in' },
        {
          label: this.$t('table.smsPackageUsed'),
          field: 'out',
          filterable: 'true',
          sortable: true,
        },
        { label: this.$t('table.smsPackageBalance'), field: 'balance' },
        { label: this.$t('table.date'), field: 'date' },
        { label: this.$t('table.action'), field: 'action' },
      ],
      smsLogsModalId: 'sms-logs-modal',
    };
  },

  computed: {
    ...mapGetters(['smsPackageInvoiceList', 'smsPackageInvoiceListMeta', 'smsPackageStatus']),
    isLoading() {
      return this.smsPackageStatus === 'loading';
    },
    title() {
      return 'Жагсаалт';
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    setPaginationValues() {
      this.totalRecords = this.smsPackageInvoiceListMeta ? this.smsPackageInvoiceListMeta.total : 1;
      this.page = this.smsPackageInvoiceListMeta.currentPage;
      this.totalPage = this.smsPackageInvoiceListMeta.lastPage;
    },
    onShowModal(id) {
      this.$refs.smsLogsModal.showModal(id);
    },
  },

  components: {
    BusinessSmsPackageTable,
    BusinessSmsLogsModal,
  },
};
</script>
