export const SMS_TEMPLATE_LIST_REQUEST = 'SMS_TEMPLATE_LIST_REQUEST';
export const SMS_TEMPLATE_LIST_REQUEST_SUCCESS = 'SMS_TEMPLATE_LIST_REQUEST_SUCCESS';
export const SMS_TEMPLATE_LIST_REQUEST_ERROR = 'SMS_TEMPLATE_LIST_REQUEST_ERROR';

export const SMS_TEMPLATE_REQUEST = 'SMS_TEMPLATE_REQUEST';
export const SMS_TEMPLATE_REQUEST_SUCCESS = 'SMS_TEMPLATE_REQUEST_SUCCESS';
export const SMS_TEMPLATE_REQUEST_ERROR = 'SMS_TEMPLATE_REQUEST_ERROR';

export const SMS_TEMPLATE_UPDATE_REQUEST = 'SMS_TEMPLATE_UPDATE_REQUEST';
export const SMS_TEMPLATE_UPDATE_REQUEST_SUCCESS = 'SMS_TEMPLATE_UPDATE_REQUEST_SUCCESS';
export const SMS_TEMPLATE_UPDATE_REQUEST_ERROR = 'SMS_TEMPLATE_UPDATE_REQUEST_ERROR';
