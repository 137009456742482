<template>
  <div class="dropdown">
    <component :is="togglerTag" :class="['dropdown-toggle', togglerClass]">
      <slot name="toggler"></slot>
    </component>
    <div :class="['dropdown-box', boxClass]">
      <div :class="['dropdown-box__content box p-2 dark:bg-dark-6', contentClass]">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import dropDownMixin from '@/mixins/dropDownMixin';

export default {
  mixins: [dropDownMixin],
};
</script>
