import Client from '@/clients/apiClient';

const resource = 'ticket-categories';

export default {
  get() {
    return Client.get(`/${resource}`);
  },
  create(payload) {
    return Client.post(`/${resource}`, payload);
  },
  update(id, payload) {
    return Client.put(`/${resource}/${id}`, payload);
  },
  show(id) {
    return Client.get(`/${resource}/${id}`);
  },
  delete(id) {
    return Client.delete(`/${resource}/${id}`);
  },
};
