<template>
  <div
    class="w-16 h-16 flex items-center justify-center image-fit rounded-full overflow-hidden bg-gray-300 dark:bg-dark-5"
  >
    <img v-if="src" :src="src" :alt="title" />
    <span v-else-if="title" class="uppercase text-lg">{{ title.charAt(0) }}</span>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: 'A',
    },
  },
};
</script>
