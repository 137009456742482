<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center">
      <PageHeader class="text-lg font-medium mr-auto" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <router-link
          :to="{ name: 'ImporterCreate' }"
          class="button text-white bg-theme-1 shadow-md mr-2"
        >
          {{ $t('text.create') }}
        </router-link>
      </div>
    </div>
    <div class="intro-y mt-5 overflow-auto lg:overflow-visible">
      <ImportersTable
        :loading="isLoading"
        :rows="importerList"
        :columns="columns"
        :line-numbers="true"
        :sort="sort"
        :page="page"
        :per-page="perPage"
        :page-size="pageSize"
        :total-page="totalPage"
        :custom-filters="customFilters"
        @delete="confirmRemoval"
        @show="onShowModal($event)"
        @onFilter="onFilter"
        @onPageChange="onPageChange"
        @onPerPageChange="onPerPageChange"
        @onSortChange="onSortChange"
        @onChangeCustomFilter="onChangeCustomFilter"
      />
    </div>
    <ConfirmDialog
      :modal-id="deleteModalId"
      :is-loading="isDeleting ? true : false"
      @ok="onDelete"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { IMPORTERS_LIST_REQUEST, IMPORTERS_DELETE } from '@/store/actions/importer';
import dataListMixin from '@/mixins/dataListMixin';
import PageHeader from '@/components/ui/PageHeader';
import ImportersTable from '@/components/importers/ImportersTable';

export default {
  mixins: [dataListMixin],

  data() {
    return {
      storeActionListRequest: IMPORTERS_LIST_REQUEST,
      storeActionRemove: IMPORTERS_DELETE,
      columns: [
        { label: this.$t('table.name'), field: 'name', filterable: 'true' },
        { label: this.$t('table.type'), field: 'type' },
        { label: this.$t('table.registrationNumber'), field: 'register', filterable: 'true' },
        { label: this.$t('table.phone'), field: 'phone', filterable: 'true' },
        { label: this.$t('table.email'), field: 'email', filterable: 'true' },
        { label: this.$t('table.action'), field: 'action' },
      ],
      customFilters: [
        {
          key: 'type',
          value: null,
          label: this.$t('text.type'),
          type: 'select',
          options: [
            {
              id: 'INDIVIDUAL',
              name: this.$t('text.individual'),
            },
            {
              id: 'ORGANIZATION',
              name: this.$t('text.organization'),
            },
          ],
        },
      ],
    };
  },

  computed: {
    ...mapGetters(['importerList', 'importerListMeta', 'importerListStatus']),
    isLoading() {
      return this.importerListStatus === 'loading';
    },
  },

  async created() {
    await this.fetchData();
  },

  methods: {
    onChangeCustomFilter(object) {
      const index = this.customFilters.findIndex(item => item.key === object.key);
      if (index > -1) {
        const { value } = object;
        this.customFilters[index].value = value || null;
        this.fetchData();
      }
    },
    setFilterValues(data) {
      this.customFilters.forEach(item => {
        data.filters[`filter[${item.key}]`] = item.value;
      });
    },
    setPaginationValues() {
      this.totalRecords = this.importerListMeta ? this.importerListMeta.total : 1;
      this.page = this.importerListMeta.currentPage;
      this.totalPage = this.importerListMeta.lastPage;
    },
  },

  components: {
    PageHeader,
    ImportersTable,
  },
};
</script>
