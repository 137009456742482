<template>
  <div class="field">
    <label v-if="label" class="flex flex-col sm:flex-row">
      {{ label }}
      <span v-if="helpText" class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600">
        {{ helpText }}
      </span>
    </label>

    <flat-pickr
      :value="value"
      :config="config"
      :placeholder="placeholder"
      :disabled="disabled"
      @on-change="onChange"
      @on-close="onClose"
      @on-open="onOpen"
      :class="[
        'input w-full border block',
        inputClass,
        { 'border-theme-6': hasError },
        { 'mt-2': label },
      ]"
    ></flat-pickr>

    <HasError v-if="hasError" :vuelidate="vuelidate" :invalidFeedback="invalidFeedback" />
  </div>
</template>

<script>
import baseInputMixin from '@/mixins/baseInputMixin';
import HasError from '@/components/ui/input/HasError';
import flatPickr from 'vue-flatpickr-component';
import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate';
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/plugins/confirmDate/confirmDate.css';

export default {
  mixins: [baseInputMixin],

  props: {
    min: [String, Date],
    max: [String, Date],
    enableTime: {
      type: Boolean,
      default: false,
    },
  },

  components: { HasError, flatPickr },

  computed: {
    config() {
      const config = {
        mode: 'single', // 'single', 'multiple', 'range'
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: this.enableTime ? 'Y-m-d H:i' : 'Y-m-d',
        altInput: false,
        dateFormat: this.enableTime ? 'Y-m-d H:i' : 'Y-m-d',
        enableTime: this.enableTime,
        time_24hr: true,
        maxDate: this.max,
        minDate: this.min,
        plugins: [],
      };
      if (this.enableTime) {
        config.plugins.push(new ConfirmDatePlugin({}));
      }
      return config;
    },
  },

  methods: {
    onChange(selectedDates, dateStr) {
      this.$emit('input', dateStr);
    },
    onOpen() {
      this.$emit('open');
    },
    onClose() {
      this.$emit('close');
    },
  },
};
</script>
