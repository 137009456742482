<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-5">
      <PageHeader class="text-lg font-medium mr-auto" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <router-link
          :to="{ name: 'PollCreate' }"
          class="button text-white bg-theme-1 shadow-md mr-2"
        >
          {{ $t('text.create') }}
        </router-link>
      </div>
    </div>
    <div class="intro-y mt-5 overflow-auto lg:overflow-visible">
      <PollsTable
        :loading="isLoading"
        :rows="pollList"
        :columns="columns"
        :line-numbers="true"
        :sort="sort"
        :page="page"
        :per-page="perPage"
        :page-size="pageSize"
        :total-page="totalPage"
        :footerItems="footerItems"
        :custom-filters="customFilters"
        @delete="confirmRemoval"
        @show="onShowModal($event)"
        @onFilter="onFilter"
        @onPageChange="onPageChange"
        @onPerPageChange="onPerPageChange"
        @onSortChange="onSortChange"
        @onDownload="onDownload"
        @onChangeCustomFilter="onChangeCustomFilter"
      />
    </div>
    <ConfirmDialog
      :modal-id="deleteModalId"
      :is-loading="isDeleting ? true : false"
      @ok="onDelete"
    />
    <ChartDialog :modal-id="showModalId" :poll="poll.questions" :title="poll.title" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { POLLS_LIST_REQUEST, POLLS_DELETE, POLLS_SHOW } from '@/store/actions/polls';
import dataListMixin from '@/mixins/dataListMixin';
import PageHeader from '@/components/ui/PageHeader';
import PollsTable from '@/components/polls/PollsTable';
import ChartDialog from '@/components/ui/ChartDialog';

export default {
  mixins: [dataListMixin],
  components: { PageHeader, PollsTable, ChartDialog },
  data() {
    return {
      storeActionListRequest: POLLS_LIST_REQUEST,
      storeActionRemove: POLLS_DELETE,
      showModalId: 'chart-show-modal',
      columns: [
        { label: this.$t('table.name'), field: 'title', filterable: 'true' },
        { label: this.$t('table.content'), field: 'description', filterable: 'true' },
        { label: this.$t('table.respondent'), field: 'assignedUser' },
        { label: this.$t('table.submitted'), field: 'totalNumber' },
        { label: this.$t('table.voted'), field: 'totalSubmit' },
        { label: this.$t('table.status'), field: 'status' },
        { label: this.$t('table.createdDate'), field: 'createdAt' },
        { label: this.$t('table.closingDate'), field: 'closingDate' },
        {
          label: this.$t('table.respondent'),
          field: 'assignedName',
          filterable: 'true',
          disabled: true,
        },
        { label: this.$t('table.action'), field: 'action' },
      ],
      customFilters: [
        {
          key: 'startDate',
          value: null,
          label: this.$t('text.selectStartDate'),
          type: 'day',
        },
        {
          key: 'endDate',
          value: null,
          label: this.$t('text.selectEndDate'),
          type: 'day',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['poll', 'pollList', 'pollMeta', 'pollStatus']),
    isLoading() {
      return this.pollStatus === 'loading';
    },
    footerItems() {
      return this.pollMeta
        ? {
            totalAmount: this.pollMeta.totalAmount,
            total: this.pollMeta.total,
          }
        : {};
    },
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    onChangeCustomFilter(object) {
      const index = this.customFilters.findIndex(item => item.key === object.key);
      if (index > -1) {
        const { value } = object;
        this.customFilters[index].value = value || null;
        this.fetchData();
      }
    },
    setFilterValues(data) {
      this.customFilters.forEach(item => {
        data.filters[`filter[${item.key}]`] = item.value;
      });
    },
    async onShowModal(pollId) {
      const payload = { id: pollId };
      await this.$store.dispatch(POLLS_SHOW, payload);
      if (this.pollStatus === 'success') {
        await cash(`#${this.showModalId}`).modal('show');
      }
    },
  },
};
</script>
