export const EXPENSE_TYPE = 'EXPENSE_TYPE';
export const EXPENSE_TYPE_SUCCESS = 'EXPENSE_TYPE_SUCCESS';
export const EXPENSE_TYPE_ERROR = 'EXPENSE_TYPE_ERROR';

export const EXPENSE_TYPE_ADD = 'EXPENSE_TYPE_ADD';
export const EXPENSE_TYPE_ADD_SUCCESS = 'EXPENSE_TYPE_ADD_SUCCESS';
export const EXPENSE_TYPE_ADD_ERROR = 'EXPENSE_TYPE_ADD_ERROR';

export const EXPENSE_TYPE_UPDATE = 'EXPENSE_TYPE_UPDATE';
export const EXPENSE_TYPE_UPDATE_SUCCESS = 'EXPENSE_TYPE_UPDATE_SUCCESS';
export const EXPENSE_TYPE_UPDATE_ERROR = 'EXPENSE_TYPE_UPDATE_ERROR';

export const EXPENSE_TYPE_REMOVE = 'EXPENSE_TYPE_REMOVE';
export const EXPENSE_TYPE_REMOVE_SUCCESS = 'EXPENSE_TYPE_REMOVE_SUCCESS';
export const EXPENSE_TYPE_REMOVE_ERROR = 'EXPENSE_TYPE_REMOVE_ERROR';

export const EXPENSE_TYPE_LIST_REQUEST = 'EXPENSE_TYPE_LIST_REQUEST';
export const EXPENSE_TYPE_LIST_REQUEST_SUCCESS = 'EXPENSE_TYPE_LIST_REQUEST_SUCCESS';
export const EXPENSE_TYPE_LIST_REQUEST_ERROR = 'EXPENSE_TYPE_LIST_REQUEST_ERR';
