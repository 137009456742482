<template>
  <div class="intro-x">
    <div class="flex justify-between items-center">
      <div class="text-sm font-bold">
        {{ item.question }}
      </div>
      <div class="text-center">
        <Button
          @click="$emit('modifyPoll', item)"
          type="button"
          class="button--sm text-gray-700 dark:border-dark-5 dark:text-gray-300 mr-1"
        >
          <EditIcon class="w-4 h-4" />
        </Button>
        <Button
          @click="deletePoll(item, form.questions)"
          type="button"
          class="button--sm text-gray-700 dark:border-dark-5 dark:text-gray-300"
        >
          <Trash2Icon class="w-4 h-4" />
        </Button>
      </div>
    </div>
    <div class="mt-2 ml-5">
      <ul class="list-disc">
        <li v-for="(answer, index) in item.answers" :key="index">
          {{ answer.answer }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
  },
  methods: {
    deletePoll(object, array) {
      const index = array.findIndex(item => item === object);
      if (index > -1) {
        array.splice(index, 1);
      } else {
        array.push(object);
      }
    },
  },
};
</script>
