<template>
  <ModalEdit :id="modalId" :title="title" :loading="isLoading" @click="onSubmit">
    <FormCar :form="$v.form" :car-makes="carMakes" />
  </ModalEdit>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, helpers } from 'vuelidate/lib/validators';
import ModalEdit from '@/components/ui/ModalEdit';
import baseModalMixin from '@/mixins/baseModalMixin';
import FormCar from '@/components/form/FormCar';
import carNumber from '@/utils/carNumber';

export default {
  mixins: [validationMixin, baseModalMixin],

  components: { ModalEdit, FormCar },

  props: {
    carMakes: Array,
  },

  validations: {
    form: {
      carMakeId: {
        required,
      },
      carModelId: {
        required,
      },
      number: {
        required,
        carNumber: helpers.regex('carNumber', carNumber),
      },
    },
  },

  data() {
    return {
      form: {
        carMakeId: null,
        carModelId: null,
        number: '',
      },
      isLoading: false,
    };
  },

  computed: {
    title() {
      return this.$t('nav.carCreate');
    },
  },

  methods: {
    resetForm() {
      this.form = {
        carMakeId: null,
        carModelId: null,
        number: '',
      };
      this.$v.$reset();
    },
    async onSubmit() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          this.$toasted.show(this.$t('toast.failedForm'), { type: 'error' });
          return;
        }
      }

      this.$emit('onCarAdd', this.form);
      this.hideEditModal();
      this.resetForm();
    },
  },
};
</script>
