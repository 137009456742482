<template>
  <TableRegular
    :loading="loading"
    :rows="rows"
    :columns="columns"
    :sort="sort"
    :page="page"
    :per-page="perPage"
    :page-size="pageSize"
    :total-page="totalPage"
    :line-numbers="lineNumbers"
    @onFilter="$emit('onFilter', $event)"
    @onPageChange="$emit('onPageChange', $event)"
    @onPerPageChange="$emit('onPerPageChange', $event)"
    @onSortChange="$emit('onSortChange', $event)"
  >
    <template slot="table-row" slot-scope="props">
      <div v-if="props.column.field === 'fee'">
        {{ props.row.fee | currency }}
      </div>
      <div v-else-if="props.column.field === 'firstName'">
        <PeopleFullname v-for="(owner, index) in props.row.owners" :key="index" :item="owner" />
      </div>
      <div v-else-if="props.column.field === 'action'" class="flex justify-center items-center">
        <router-link
          v-if="checkPermission('registration.apartment.edit')"
          :to="{
            name: 'ParkingUpdate',
            params: {
              parkingId: props.row.id,
            },
          }"
          class="flex items-center mr-3"
        >
          <CheckSquareIcon class="w-4 h-4 mr-1" />
          {{ $t('table.edit') }}
        </router-link>
        <div
          v-if="checkPermission('registration.apartment.delete')"
          @click="$emit('delete', props.row.id)"
          class="flex items-center text-theme-6 cursor-pointer"
        >
          <Trash2Icon class="w-4 h-4 mr-1" />
          {{ $t('table.delete') }}
        </div>
      </div>
    </template>
  </TableRegular>
</template>

<script>
import TableRegular from '@/components/ui/table/TableRegular';
import PeopleFullname from '@/components/people/PeopleFullname';
import tableMixin from '@/mixins/tableMixin';

export default {
  mixins: [tableMixin],
  components: { TableRegular, PeopleFullname },
};
</script>
