<template>
  <TableRegular
    :loading="loading"
    :rows="rows"
    :columns="columns"
    @onFilter="$emit('onFilter', $event)"
    @onPageChange="$emit('onPageChange', $event)"
    @onPerPageChange="$emit('onPerPageChange', $event)"
    @onSortChange="$emit('onSortChange', $event)"
  >
    <template slot="table-row" slot-scope="props">
      <p v-if="props.column.field === 'firstBalance'" class="font-medium whitespace-nowrap">
        {{ props.row.firstBalance | currency }}
      </p>
      <p v-else-if="props.column.field === 'invoicedAmount'" class="whitespace-nowrap">
        {{ props.row.invoicedAmount | currency }}
      </p>
      <p v-else-if="props.column.field === 'paidAmount'" class="whitespace-nowrap">
        {{ props.row.paidAmount | currency }}
      </p>
      <p v-else-if="props.column.field === 'lastBalance'" class="font-medium whitespace-nowrap">
        {{ props.row.lastBalance | currency }}
      </p>
      <p v-else-if="props.column.field === 'startDate'" class="whitespace-nowrap">
        {{ props.row.startDate.slice(0, 7) }} {{ $t('table.month') }}
      </p>
    </template>
  </TableRegular>
</template>

<script>
import TableRegular from '@/components/ui/table/TableRegular';
import tableMixin from '@/mixins/tableMixin';

export default {
  mixins: [tableMixin],

  components: {
    TableRegular,
  },
};
</script>
