<template>
  <div>
    <div class="box border-t-0 px-6 py-4">
      <div
        class="text-lg font-medium border-b border-gray-300 pb-2 flex justify-between items-center"
      >
        <span>Санал асуулга</span>
        <button
          @click="showModal"
          class="hidden bg-blue-600 hover:bg-blue-700 text-sm text-white px-4 py-2 rounded-md flex gap-1 items-center justify-center"
        >
          <PlusIcon size="16" />
          Шинэ санал асуулга нэмэх
        </button>
      </div>
      <div v-if="hasPolls" class="flex border-b border-gray-300">
        <div
          class="w-1/4 text-center flex flex-col gap-2 justify-center items-center py-4 border-r border-gray-300"
        >
          <div class="text-4xl">{{ totalCount }}</div>
          <div>Нийт санал өгсөн</div>
        </div>
        <div class="w-3/4 p-4">
          <div class="flex justify-between items-center">
            <div class="flex items-center gap-4">
              <span class="text-lg">{{ polls[currentPollIdx].title }}</span>
            </div>
            <div class="flex gap-4">
              <button
                @click="prevPoll"
                class="bg-gray-50 hover:bg-gray-200 hover:border-gray-500 text-sm px-4 py-2 rounded-md flex gap-1 items-center justify-center"
              >
                <ChevronLeftIcon size="12" />
                Өмнөх
              </button>
              <button
                @click="nextPoll"
                class="bg-gray-50 hover:bg-gray-200 hover:border-gray-500 text-sm px-4 py-2 rounded-md flex gap-1 items-center justify-center"
              >
                Дараах
                <ChevronRightIcon size="12" />
              </button>
            </div>
          </div>
          <div
            v-for="(question, idx) in polls[currentPollIdx].questions"
            :key="`poll-option-${idx}`"
            class="my-4"
          >
            <div class="flex gap-2 mb-2">
              <span>{{ idx + 1 }}. {{ question.question }}</span>
              <span class="text-gray-500">({{ question.answered || 0 }})</span>
            </div>
            <li
              :key="`question-answer-${answerIdx}`"
              class="ml-8"
              v-for="(answer, answerIdx) in question.answers"
            >
              {{ answer.answer }} <span class="text-gray-500">({{ answer.responseCount }})</span>
            </li>
          </div>
        </div>
      </div>
      <div v-if="hasPolls" class="hidden flex justify-end mt-4 gap-4">
        <button
          @click="showDeleteModal"
          class="bg-red-600 hover:bg-red-700 text-sm text-white px-4 py-2 rounded-md flex gap-1 items-center justify-center"
        >
          <TrashIcon size="16" />
          Устгах
        </button>
        <button
          @click="showEditModal"
          class="bg-indigo-600 hover:bg-indigo-700 text-sm text-white px-4 py-2 rounded-md flex gap-1 items-center justify-center"
        >
          <EditIcon size="16" />
          Засах
        </button>
        <button
          :disabled="savingResult"
          @click="saveResult"
          :class="{
            'bg-indigo-600 hover:bg-indigo-700': !savingResult,
            'bg-indigo-300': savingResult,
          }"
          class=" text-sm text-white px-4 py-2 rounded-md flex gap-1 items-center justify-center"
        >
          <SaveIcon size="16" />
          Хадгалах
        </button>
      </div>
      <div v-if="!hasPolls" class="flex justify-center items-center px-6 py-4 text-gray-600">
        Санал асуулга оруулаагүй байна
      </div>
    </div>

    <PollCreateModal :modal-id="modalId" @submit="onPollSubmit" />
    <PollCreateModal :modal-id="modalEditId" @submit="onPollEdit" :form-data="editFormData" />
    <ConfirmDialog :modal-id="modalDeleteId" @ok="deletePoll" />
  </div>
</template>

<script>
import PollCreateModal from '@/components/meeting/PollCreateModal';
import ConfirmDialog from '@/components/ui/ConfirmDialog';
import { POLLS_ADD, POLLS_ADD_RESPONSES, POLLS_DELETE, POLLS_UPDATE } from '@/store/actions/polls';
import { mapGetters } from 'vuex';
import { MEETING_UPDATE } from '@/store/actions/meeting';

export default {
  name: 'MeetingPolls',
  components: { ConfirmDialog, PollCreateModal },
  props: ['client', 'meeting'],
  data() {
    return {
      modalId: 'create-meeting-poll-modal',
      modalEditId: 'edit-meeting-poll-modal',
      modalDeleteId: 'delete-meeting-poll-modal',

      currentPollIdx: 0,
      editFormData: null,

      savingResult: false,
    };
  },
  computed: {
    ...mapGetters(['me', 'pollStatus', 'poll', 'pollLastCreated']),
    totalCount() {
      return this.polls[this.currentPollIdx].totalNumber;
    },
    hasPolls() {
      return this.meeting.polls.length > 0;
    },
    polls() {
      return this.meeting.polls;
    },
  },
  mounted() {
    this.getPolls();
    this.configureEvents();
  },
  methods: {
    showModal() {
      cash(`#${this.modalId}`).modal('show');
    },
    showEditModal() {
      this.editFormData = this.polls[this.currentPollIdx];
      cash(`#${this.modalEditId}`).modal('show');
    },
    showDeleteModal() {
      cash(`#${this.modalDeleteId}`).modal('show');
    },
    onPollSubmit(e) {
      cash(`#${this.modalId}`).modal('hide');

      const payload = {
        businessId: this.meeting.businessId,
        data: {
          title: e.name,
          description: e.name,
          closing_date: '2021-04-21',
          building_id: null,
          apartments: [],
          questions: [
            {
              question: e.name,
              answers: e.options.map(paramE => ({ answer: paramE.name })),
            },
          ],
        },
      };

      this.$store.dispatch(POLLS_ADD, payload).then(() => {
        if (this.pollStatus !== 'success') return;

        e.data = this.pollLastCreated;
        const options = e.data.questions[0].answers.map(paramItem => ({
          id: paramItem.id,
          name: paramItem.answer,
        }));
        e.options = options;

        this.client.socket
          .request('add-poll', {
            room_id: this.client.room_id,
            poll: e,
          })
          .then(param => {
            this.polls = param.polls;
            this.currentPollIdx = this.polls.length - 1;
            this.updateMeeting();
          });
      });
    },
    onPollEdit(e) {
      cash(`#${this.modalEditId}`).modal('hide');
      const poll = this.polls[this.currentPollIdx].data;
      this.$store
        .dispatch(POLLS_UPDATE, {
          id: poll.id,
          businessId: poll.businessId,
          data: {
            title: e.name,
            description: e.name,
            closing_date: '2021-04-21',
            building_id: null,
            apartments: [],
            questions: [
              {
                question: e.name,
                answers: e.options.map(paramE => ({ answer: paramE.name })),
              },
            ],
          },
        })
        .then(() => {
          this.client.socket.request('set-poll', {
            room_id: this.client.room_id,
            poll: e,
            index: this.currentPollIdx,
          });
        })
        .finally(() => {});
    },
    getPolls() {
      // this.client.socket
      //   .request('get-polls', {
      //     room_id: this.client.room_id,
      //   })
      //   .then(e => {
      //     this.polls = e.polls;
      //   });
    },
    deletePoll() {
      const poll = this.polls[this.currentPollIdx].data;
      this.$store
        .dispatch(POLLS_DELETE, { id: poll.id, businessId: poll.businessId })
        .then(() => {
          this.client.socket
            .request('remove-poll', {
              room_id: this.client.room_id,
              index: this.currentPollIdx,
            })
            .then(param => {
              this.currentPollIdx = 0;
              this.polls = param.polls;
            });
        })
        .finally(() => {
          cash(`#${this.modalDeleteId}`).modal('hide');
        });
    },
    updateMeeting() {
      this.$store
        .dispatch(MEETING_UPDATE, {
          id: this.meeting.id,
          businessId: this.meeting.businessId,
          data: {
            duration: this.meeting.duration,
            scheduled_at: this.meeting.scheduledAt,
            name: this.meeting.name,
            topics: this.meeting.topics,
            buildings: this.meeting.buildings.map(e => e.id),
            polls: this.polls.map(e => e.data.id),
          },
        })
        .then(() => {})
        .finally(() => {});
    },
    saveResult() {
      if (this.savingResult) return;
      this.savingResult = true;

      this.client.socket
        .request('save-poll', {
          room_id: this.client.room_id,
          index: this.currentPollIdx,
        })
        .then(async param => {
          if (param.status !== 200) {
            return;
          }

          const requestBody = {
            question: {
              id: param.data.data.questions[0].id,
              answers: param.data.options.map(paramItem => ({
                id: paramItem.id,
                users: (paramItem.users ?? []).map(paramUser => ({
                  id: paramUser,
                })),
              })),
            },
          };

          await this.$store
            .dispatch(POLLS_ADD_RESPONSES, {
              id: param.data.data.id,
              businessId: this.meeting.businessId,
              data: requestBody,
            })
            .then(() => {})
            .finally(() => {});
        })
        .finally(() => {
          this.savingResult = false;
        });
    },
    prevPoll() {
      if (this.currentPollIdx > 0) {
        this.currentPollIdx -= 1;
      } else {
        this.currentPollIdx = this.polls.length - 1;
      }
    },
    nextPoll() {
      if (this.currentPollIdx < this.polls.length - 1) {
        this.currentPollIdx += 1;
      } else {
        this.currentPollIdx = 0;
      }
    },
    configureEvents() {
      this.client.on('pollsUpdated', param => {
        this.polls = param;
      });
    },
  },
};
</script>
