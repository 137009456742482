import Vue from 'vue';
import { assign } from 'lodash';
import {
  USER_REQUEST,
  USER_ERROR,
  USER_SUCCESS,
  USER_PROFILE_UPDATE,
  USER_PROFILE_UPDATE_SUCCESS,
  USER_PROFILE_UPDATE_ERROR,
  USER_REGISTER,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_ERROR,
  USER_VALIDATE,
  USER_VALIDATE_SUCCESS,
  USER_VALIDATE_ERROR,
  USER_LIST_REQUEST,
  USER_LIST_REQUEST_SUCCESS,
  USER_LIST_REQUEST_ERROR,
  ALL_USER_LIST_REQUEST,
  ALL_USER_LIST_REQUEST_SUCCESS,
  ALL_USER_LIST_REQUEST_ERROR,
  USER_PERMISSION_REQUEST,
  USER_PERMISSION_REQUEST_SUCCESS,
  USER_PERMISSION_REQUEST_ERROR,
  USER_PERMISSION_UPDATE,
  USER_PERMISSION_UPDATE_ERROR,
  USER_PERMISSION_UPDATE_SUCCESS,
  USER_CREATE_REQUEST,
  USER_CREATE_REQUEST_ERROR,
  USER_CREATE_REQUEST_SUCCESS,
  USER_UPDATE_REQUEST,
  USER_UPDATE_REQUEST_SUCCESS,
  USER_UPDATE_REQUEST_ERROR,
  USER_GET_REQUEST,
  USER_GET_REQUEST_ERROR,
  USER_GET_REQUEST_SUCCESS,
  USER_RESET_PASSWORD,
  USER_RESET_PASSWORD_SUCCESS,
  USER_RESET_PASSWORD_ERROR,
} from '@/store/actions/user';
import { AUTH_LOGOUT } from '@/store/actions/auth';
import { handleRequestError } from '@/utils/error';
import Repository, { USERS } from '@/repositories/RepositoryFactory';

const UserRepository = Repository.get(USERS);

const state = {
  status: '',
  error: '',
  profile: {},
  isUserRegistrationSuccessful: null,
  validateStatus: '',
  user: '',
  usersStatus: '',
  userList: [],
  userListMeta: '',
  userListStatus: '',
  permissionList: [],
  permissionStatus: '',
  permissionUpdateStatus: '',
  userPermissions: {},
  resetPasswordStatus: '',
};

const getters = {
  isUserRegistrationSuccessful: state => state.isUserRegistrationSuccessful,
  userStatus: state => state.status,
  isProfileLoaded: state => !!state.profile.email || !!state.profile.phone,
  me: state => state.profile,
  userValidateStatus: state => state.validateStatus,
  user: state => state.user,
  usersStatus: state => state.usersStatus,
  userList: state => state.userList,
  userListMeta: state => state.userListMeta,
  userListStatus: state => state.userListStatus,
  permissionList: state => state.permissionList,
  permissionStatus: state => state.permissionStatus,
  permissionUpdateStatus: state => state.permissionUpdateStatus,
  userPermissions: state => state.userPermissions,
  resetPasswordStatus: state => state.resetPasswordStatus,
};

const actions = {
  [USER_REQUEST]: async ({ commit }) => {
    commit(USER_REQUEST);
    try {
      commit(USER_SUCCESS, await UserRepository.getSelf());
    } catch (error) {
      commit(USER_ERROR);
      handleRequestError(error);
    }
  },

  [USER_REGISTER]: async ({ commit }, data) => {
    commit(USER_REGISTER);
    try {
      await UserRepository.register(data);
      commit(USER_REGISTER_SUCCESS);
    } catch (error) {
      commit(USER_REGISTER_ERROR);
      handleRequestError(error);
    }
  },

  [USER_PROFILE_UPDATE]: async ({ commit }, payload) => {
    commit(USER_PROFILE_UPDATE);
    try {
      const data = assign({}, state.profile, payload.data);

      const response = await UserRepository.update(payload.id, data);
      const updatedData = response.data.data;
      commit(USER_PROFILE_UPDATE_SUCCESS, updatedData);
    } catch (error) {
      commit(USER_PROFILE_UPDATE_ERROR);
      handleRequestError(error);
    }
  },

  [USER_RESET_PASSWORD]: async ({ commit }, payload) => {
    commit(USER_RESET_PASSWORD);
    try {
      commit(USER_RESET_PASSWORD_SUCCESS, await UserRepository.resetPassword(payload));
    } catch (error) {
      commit(USER_RESET_PASSWORD_ERROR, error);
      handleRequestError(error);
    }
  },
  [USER_VALIDATE]: async ({ commit }, payload) => {
    commit(USER_VALIDATE);
    try {
      await UserRepository.validateIsUnique(payload);
      commit(USER_VALIDATE_SUCCESS);
    } catch (error) {
      commit(USER_VALIDATE_ERROR);
      handleRequestError(error);
    }
  },

  [USER_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(USER_LIST_REQUEST);
    try {
      const response = await UserRepository.fetchUsers(payload);
      commit(USER_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(USER_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },

  [ALL_USER_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(ALL_USER_LIST_REQUEST);
    try {
      const response = await UserRepository.getAll(payload);
      commit(ALL_USER_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(ALL_USER_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },

  [USER_PERMISSION_REQUEST]: async ({ commit }, payload) => {
    commit(USER_PERMISSION_REQUEST);
    try {
      const response = await UserRepository.getUserPermissionList(payload.id);
      commit(USER_PERMISSION_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(USER_PERMISSION_REQUEST_ERROR);
      handleRequestError(error);
    }
  },

  [USER_PERMISSION_UPDATE]: async ({ commit }, payload) => {
    commit(USER_PERMISSION_UPDATE);
    try {
      await UserRepository.updateUserPermission(payload);
      commit(USER_PERMISSION_UPDATE_SUCCESS);
    } catch (error) {
      commit(USER_PERMISSION_UPDATE_ERROR);
      handleRequestError(error);
    }
  },

  [USER_CREATE_REQUEST]: async ({ commit }, payload) => {
    commit(USER_CREATE_REQUEST);
    try {
      const response = await UserRepository.createUser(payload);
      commit(USER_CREATE_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(USER_CREATE_REQUEST_ERROR);
      handleRequestError(error);
    }
  },

  [USER_UPDATE_REQUEST]: async ({ commit }, payload) => {
    commit(USER_UPDATE_REQUEST);
    try {
      const response = await UserRepository.updateUser(payload);
      commit(USER_UPDATE_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(USER_UPDATE_REQUEST_ERROR);
      handleRequestError(error);
    }
  },

  [USER_GET_REQUEST]: async ({ commit }, payload) => {
    commit(USER_GET_REQUEST);
    try {
      const response = await UserRepository.fetchSingleUser(payload);
      const { data } = response.data;
      commit(USER_GET_REQUEST_SUCCESS, data);
    } catch (error) {
      commit(USER_GET_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
};

const mutations = {
  [USER_REQUEST]: state => {
    state.status = 'loading';
  },
  [USER_SUCCESS]: (state, response) => {
    const { data: user } = response.data;
    state.status = 'success';
    state.profile = user;

    const initialValue = {};
    state.userPermissions = user.permissions.reduce((object, permission) => {
      const secondInitialValue = {};
      const child = permission.item.reduce((childObject, childPermission) => {
        return {
          ...childObject,
          [childPermission.name]: childPermission.permission,
        };
      }, secondInitialValue);

      return {
        ...object,
        [permission.menu]: { ...child },
      };
    }, initialValue);
  },
  [USER_ERROR]: state => {
    state.status = 'error';
  },
  [USER_REGISTER]: state => {
    state.status = 'loading';
    state.isUserRegistrationSuccessful = null;
  },
  [USER_REGISTER_SUCCESS]: state => {
    state.status = 'success';
    state.isUserRegistrationSuccessful = true;
  },
  [USER_REGISTER_ERROR]: state => {
    state.status = 'error';
    state.isUserRegistrationSuccessful = false;
  },
  [AUTH_LOGOUT]: state => {
    state.profile = {};
    state.featureList = {};
  },
  [USER_PROFILE_UPDATE]: state => {
    state.status = 'loading';
  },
  [USER_PROFILE_UPDATE_SUCCESS]: (state, data) => {
    state.status = 'success';
    Vue.set(state, 'profile', data);
  },
  [USER_PROFILE_UPDATE_ERROR]: state => {
    state.status = 'error';
  },
  [USER_RESET_PASSWORD]: state => {
    state.resetPasswordStatus = 'loading';
  },
  [USER_RESET_PASSWORD_SUCCESS]: state => {
    state.resetPasswordStatus = 'success';
  },
  [USER_RESET_PASSWORD_ERROR]: state => {
    state.resetPasswordStatus = 'error';
  },
  [USER_VALIDATE]: state => {
    state.validateStatus = 'loading';
  },
  [USER_VALIDATE_SUCCESS]: state => {
    state.validateStatus = 'success';
  },
  [USER_VALIDATE_ERROR]: state => {
    state.validateStatus = 'error';
  },

  [USER_LIST_REQUEST]: state => {
    state.userListStatus = 'loading';
  },
  [USER_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.userListStatus = 'success';
    state.userList = payload.data;
    state.userListMeta = payload.meta;
  },
  [USER_LIST_REQUEST_ERROR]: state => {
    state.userListStatus = 'error';
  },
  [ALL_USER_LIST_REQUEST]: state => {
    state.userListStatus = 'loading';
  },
  [ALL_USER_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.userListStatus = 'success';
    state.userList = payload.data;
    state.userListMeta = payload.meta;
  },
  [ALL_USER_LIST_REQUEST_ERROR]: state => {
    state.userListStatus = 'error';
  },
  [USER_PERMISSION_REQUEST]: state => {
    state.permissionStatus = 'loading';
  },
  [USER_PERMISSION_REQUEST_SUCCESS]: (state, payload) => {
    state.permissionStatus = 'success';
    state.permissionList = payload;
  },
  [USER_PERMISSION_REQUEST_ERROR]: state => {
    state.permissionStatus = 'error';
  },
  [USER_PERMISSION_UPDATE]: state => {
    state.permissionUpdateStatus = 'loading';
  },
  [USER_PERMISSION_UPDATE_SUCCESS]: state => {
    state.permissionUpdateStatus = 'success';
  },
  [USER_PERMISSION_UPDATE_ERROR]: state => {
    state.permissionUpdateStatus = 'error';
  },
  [USER_CREATE_REQUEST]: state => {
    state.usersStatus = 'loading';
  },
  [USER_CREATE_REQUEST_SUCCESS]: (state, payload) => {
    state.user = payload;
    state.usersStatus = 'success';
  },
  [USER_CREATE_REQUEST_ERROR]: state => {
    state.usersStatus = 'error';
  },
  [USER_UPDATE_REQUEST]: state => {
    state.usersStatus = 'loading';
  },
  [USER_UPDATE_REQUEST_SUCCESS]: (state, payload) => {
    state.user = payload;
    state.usersStatus = 'success';
  },
  [USER_GET_REQUEST]: state => {
    state.usersStatus = 'loading';
  },
  [USER_GET_REQUEST_SUCCESS]: (state, payload) => {
    state.user = payload;
    state.usersStatus = 'success';
  },
  [USER_GET_REQUEST_ERROR]: state => {
    state.usersStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
