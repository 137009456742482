<template>
  <div class="mt-5">
    <div class="box px-5 intro-y mb-5">
      <div class="nav-tabs flex flex-col sm:flex-row justify-center lg:justify-start">
        <router-link
          :to="{ name: 'InvoiceIncome' }"
          class="py-4 sm:mr-8"
          :class="{ active: $route.name === 'InvoiceIncome' }"
          >{{ $t('nav.invoiceIncome') }}</router-link
        >
        <router-link
          :to="{ name: 'Receivables' }"
          class="py-4 sm:mr-8"
          :class="{ active: $route.name === 'Receivables' }"
          >{{ $t('nav.receivables') }}</router-link
        >
        <router-link
          :to="{ name: 'Expenses' }"
          class="py-4 sm:mr-8"
          :class="{ active: $route.name === 'Expenses' }"
        >
          {{ $t('nav.expenses') }}
        </router-link>
        <router-link
          :to="{ name: 'ServiceProducts' }"
          class="py-4 sm:mr-8"
          :class="{ active: $route.name === 'ServiceProducts' }"
        >
          {{ $t('nav.serviceProducts') }}
        </router-link>
        <router-link
          :to="{ name: 'Statement' }"
          class="py-4 sm:mr-8"
          :class="{ active: $route.name === 'Statement' }"
        >
          {{ $t('nav.statement') }}
        </router-link>
        <router-link
          :to="{ name: 'Transactions' }"
          class="py-4 sm:mr-8"
          :class="{ active: $route.name === 'Transactions' }"
        >
          {{ $t('nav.transactions') }}
        </router-link>
      </div>
    </div>
    <div class="intro-y">
      <router-view :business="business"></router-view>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    business: Object,
  },
};
</script>
