<template>
  <div class="field">
    <label v-if="label" class="flex flex-col sm:flex-row">
      {{ label }}
      <span v-if="helpText" class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600">
        {{ helpText }}
      </span>
    </label>

    <div class="relative">
      <label
        :class="['input-image', { 'border-theme-6': $_hasError }, { 'mt-2': label }]"
        :style="`background-image: url(${photo && photo.path ? photo.path : photo});`"
      >
        <input
          ref="fileupload"
          class="hidden"
          type="file"
          name="image"
          accept="image/jpeg, image/png, image/gif"
          @change="onChange"
        />
      </label>
      <div
        v-if="photo"
        @click="$emit('remove')"
        class="w-5 h-5 cursor-pointer flex items-center justify-center absolute rounded-full text-white bg-theme-6 right-0 bottom-0 transform translate-x-1/2 translate-y-1/2"
      >
        <xIcon class="w-4 h-4" />
      </div>
    </div>
  </div>
</template>

<script>
import { isNil } from 'lodash';

export default {
  props: {
    label: String,
    helpText: String,
    photo: [Object, String],
    isLoading: {
      type: Boolean,
      default: false,
    },
    vuelidate: Object,
    hasError: Boolean,
  },

  computed: {
    $_hasError() {
      return isNil(this.vuelidate) ? this.hasError : this.vuelidate.$error;
    },
  },

  methods: {
    onChange(value) {
      this.$emit('change', value);
      const input = this.$refs.fileupload;
      input.type = 'text';
      input.type = 'file';
    },
  },
};
</script>
