import Client from '@/clients/apiClient';
import ClientFile from '@/clients/fileClient';
import humps from 'humps';
import { stringify } from 'query-string';

const resourceBase = 'business';
const resource = 'parkings';
const apartments = 'apartments';
export default {
  get(businessId, payload) {
    return Client.get(
      `${resourceBase}/${businessId}/${resource}?page=${payload.page}&limit=${
        payload.perPage
      }&sort[field]=${humps.decamelize(payload.sort.field)}&sort[type]=${
        payload.sort.type
      }&${stringify(humps.decamelizeKeys(payload.filters))}`
    );
  },
  create(businessId, payload) {
    return Client.post(`${resourceBase}/${businessId}/${resource}`, payload);
  },
  show(businessId, parkingId) {
    return Client.get(`${resourceBase}/${businessId}/${resource}/${parkingId}`);
  },
  update(businessId, id, payload) {
    return Client.put(`${resourceBase}/${businessId}/${resource}/${id}`, payload);
  },
  delete(businessId, id) {
    return Client.delete(`${resourceBase}/${businessId}/${resource}/${id}`);
  },
  import(businessId, payload) {
    return ClientFile.post(`${resourceBase}/${businessId}/${apartments}/import`, payload);
  },
};
