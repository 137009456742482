<template>
  <div v-if="loading" class="flex items-center justify-center flex-col py-3">
    <LoadingIcon icon="oval" class="w-10 h-10" />
    <p class="text-gray-500 mt-3">{{ $t('table.loading') }}</p>
  </div>
  <div v-else class="grid grid-cols-12 gap-6">
    <div
      v-for="(building, index) in buildings"
      :key="index"
      class="intro-y col-span-12 md:col-span-6  lg:col-span-4"
    >
      <BuildingListItem :item="building" @delete="onDelete" />
    </div>
  </div>
</template>

<script>
import BuildingListItem from '@/components/building/BuildingListItem';

export default {
  props: {
    buildings: Array,
    loading: Boolean,
  },

  components: { BuildingListItem },

  methods: {
    onDelete(value) {
      this.$emit('delete', value);
    },
  },
};
</script>
