<template>
  <div>
    <apexchart
      type="pie"
      width="380"
      heigth="400"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
export default {
  name: 'ApexChart',
  props: {
    chart: Array,
  },
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: [],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.chart.forEach(value => {
      this.chartOptions.labels.push(value.answer);
      this.series.push(value.responseCount);
    });
  },
};
</script>
