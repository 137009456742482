<template>
  <div class="field">
    <label v-if="label" class="flex flex-col sm:flex-row">
      {{ label }}
      <span v-if="helpText" class="sm:ml-auto mt-1 sm:mt-0 text-xs text-gray-600">
        {{ helpText }}
      </span>
    </label>

    <input
      ref="inputFile"
      :class="[
        'input w-full border block',
        inputClass,
        { 'border-theme-6': hasError },
        { 'mt-2': label },
      ]"
      type="file"
      name="file"
      :accept="acceptFiles"
      @change="onFileChange($event.target.name, $event.target.files)"
      :disabled="disabled"
    />

    <HasError v-if="hasError" :vuelidate="vuelidate" :invalidFeedback="invalidFeedback" />
  </div>
</template>

<script>
import baseInputMixin from '@/mixins/baseInputMixin';
import HasError from '@/components/ui/input/HasError';

export default {
  mixins: [baseInputMixin],

  props: {
    value: [Object, String, Number, Array, Boolean, File],
    acceptFiles: String,
  },

  components: {
    HasError,
  },

  methods: {
    resetField() {
      this.$refs.inputFile.value = null;
    },
    onFileChange(fieldName, fileList) {
      const formData = new FormData();

      Array.from(Array(fileList.length).keys()).map(x =>
        formData.append(fieldName, fileList[x], fileList[x].name)
      );

      const data = formData.get(fieldName);
      if (data.size > 1024 * 1024 * 5) {
        this.$toasted.show(this.$t('toast.fileIsTooLarge'), { type: 'error' });
        return;
      }
      this.$emit('upload', data);
    },
  },
};
</script>
