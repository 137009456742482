<template>
  <TableRegular
    :loading="loading"
    :rows="rows"
    :columns="columns"
    :sort="sort"
    :page="page"
    :per-page="perPage"
    :page-size="pageSize"
    :total-page="totalPage"
    :line-numbers="lineNumbers"
    @onFilter="$emit('onFilter', $event)"
    @onPageChange="$emit('onPageChange', $event)"
    @onPerPageChange="$emit('onPerPageChange', $event)"
    @onSortChange="$emit('onSortChange', $event)"
    @onChangeCustomFilter="$emit('onChangeCustomFilter', $event)"
  >
    <template slot="table-row" slot-scope="props">
      <p v-if="props.column.field === 'business'">
        {{ props.row.business ? props.row.business.name : '' }}
      </p>
      <p v-else-if="props.column.field === 'date'" class="whitespace-nowrap">
        {{ props.row.date | momentDate('YYYY-MM-DD HH:mm') }}
      </p>
      <div v-else-if="props.column.field === 'creator'" class="text-left">
        <template v-if="props.row.creator">
          <p class="whitespace-nowrap">{{ props.row.creator | fullName }}</p>
          <p class="whitespace-nowrap" v-if="props.row.creator.email">
            {{ props.row.creator.email }}
          </p>
        </template>
      </div>
    </template>
  </TableRegular>
</template>

<script>
import TableRegular from '@/components/ui/table/TableRegular';
import tableMixin from '@/mixins/tableMixin';

export default {
  mixins: [tableMixin],

  components: {
    TableRegular,
  },
};
</script>
