import { isEmpty } from 'lodash';
import Client from '@/clients/apiClient';
import ClientFile from '@/clients/fileClient';
import AuthTokenStorageService from '@/services/AuthTokenStorageService';

function handleNewTokens(accessToken) {
  AuthTokenStorageService.store(accessToken);

  Client.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  ClientFile.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
}

const AuthService = {
  async authenticate(payload) {
    const data = {
      email: payload.email,
      password: payload.password,
    };

    const response = await Client.post(`${process.env.VUE_APP_API_URL}/login`, data);
    const { token } = response.data.data;
    handleNewTokens(token);
    return token;
  },

  async forgotRequest(payload) {
    const data = {
      email: payload.email,
    };
    const response = await Client.post(
      `${process.env.VUE_APP_API_URL}/forgot-password-by-email`,
      data
    );

    return response;
  },

  async resetPassword(payload) {
    const data = {
      email: payload.email,
      password: payload.password,
    };
    const headers = {};
    headers.Authorization = `Bearer ${payload.token}`;
    const response = await Client.post(
      `${process.env.VUE_APP_API_URL}/reset-password-by-email`,
      data,
      { headers }
    );

    return response;
  },

  logout() {
    return new Promise(resolve => {
      if (Client.defaults.headers.common) {
        const headersCommon = Client.defaults.headers.common;
        delete headersCommon.Authorization;
        Client.defaults.headers.common = headersCommon;
      }
      AuthTokenStorageService.clear();
      resolve();
    });
  },

  isAuthenticated() {
    return !isEmpty(AuthTokenStorageService.getAccessToken());
  },
};

Client.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    if (
      error.response.status === 401 &&
      originalRequest.url === `${process.env.VUE_APP_API_URL}/login`
    ) {
      AuthTokenStorageService.clear();
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest.shouldRetry) {
      AuthTokenStorageService.clear();
    }
    return Promise.reject(error);
  }
);

export default AuthService;
