<template>
  <div class="p-5">
    <InputString
      :label="$t('text.code')"
      v-model.trim="vForm.code.$model"
      :vuelidate="vForm.code"
      input-class="input-apartment-code"
    />

    <InputString
      :label="isParking ? $t('text.parkingNumber') : $t('text.doorNumber')"
      v-model.trim="vForm.number.$model"
      :vuelidate="vForm.number"
      input-class="input-apartment-number"
    />

    <template v-if="!isParking">
      <InputString
        :label="$t('text.squareSize')"
        v-model.trim="vForm.square.$model"
        :vuelidate="vForm.square"
        input-class="input-apartment-square"
      />
      <InputString
        :label="$t('text.stateBankApartmentName')"
        v-model="form.stateBankApartmentName"
        input-class="input-apartment-state-bank-name"
      />
      <InputString
        :label="$t('text.stateBankCorps')"
        v-model="form.corps"
        input-class="input-apartment-state-bank-corps"
      />
    </template>

    <InputMoney
      v-if="isParking"
      :label="$t('text.garageFee')"
      v-model.trim="vForm.garageFee.$model"
      :vuelidate="vForm.garageFee"
      input-class="input-garage-fee"
    />
  </div>
</template>

<script>
import InputString from '@/components/ui/input/InputString';
import InputMoney from '@/components/ui/input/InputMoney';

export default {
  components: {
    InputString,
    InputMoney,
  },

  props: {
    vForm: Object,
    form: Object,
    isParking: Boolean,
  },
};
</script>
