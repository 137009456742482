import Owner from '@/views/Owner/OwnerRequest';

export default [
  {
    path: '/owner-requests',
    name: 'ownerRequests',
    component: Owner,
    meta: { group: 'userRequest' },
    children: [],
  },
];
