export const CITY_LIST_REQUEST = 'CITY_LIST_REQUEST';
export const CITY_LIST_REQUEST_SUCCESS = 'CITY_LIST_REQUEST_SUCCESS';
export const CITY_LIST_REQUEST_ERROR = 'CITY_LIST_REQUEST_ERROR';

export const TOWN_LIST_REQUEST = 'TOWN_LIST_REQUEST';
export const TOWN_LIST_REQUEST_SUCCESS = 'TOWN_LIST_REQUEST_SUCCESS';
export const TOWN_LIST_REQUEST_ERROR = 'TOWN_LIST_REQUEST_ERROR';

export const PET_TYPES_REQUEST = 'PET_TYPES_REQUEST';
export const PET_TYPES_REQUEST_SUCCESS = 'PET_TYPES_REQUEST_SUCCESS';
export const PET_TYPES_REQUEST_ERROR = 'PET_TYPES_REQUEST_ERROR';

export const CAR_MAKES_REQUEST = 'CAR_MAKES_REQUEST';
export const CAR_MAKES_REQUEST_SUCCESS = 'CAR_MAKES_REQUEST_SUCCESS';
export const CAR_MAKES_REQUEST_ERROR = 'CAR_MAKES_REQUEST_ERROR';

export const EXPENSE_TYPES_REQUEST = 'EXPENSE_TYPES_REQUEST';
export const EXPENSE_TYPES_REQUEST_SUCCESS = 'EXPENSE_TYPES_REQUEST_SUCCESS';
export const EXPENSE_TYPES_REQUEST_ERROR = 'EXPENSE_TYPES_REQUEST_ERROR';
