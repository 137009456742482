<template>
  <LineChart :width="width" :height="height" :chart-data="data" :options="options" />
</template>

<script>
import LineChart from '@/components/ui/chart/LineChart';

export default {
  components: { LineChart },
  props: {
    width: {
      type: Number,
      default: 150,
    },
    height: {
      type: Number,
      default: 150,
    },
    statistics: Array,
  },
  computed: {
    realData() {
      return this.statistics
        .filter(item => item.createdDate)
        .map(item => {
          const year = item.createdDate.toString().substring(0, 4);
          const month = item.createdDate.toString().substring(6, 4);
          return {
            total: item.total,
            date: `${year}-${month}`,
          };
        });
    },
    data() {
      return {
        labels: this.realData.map(item => item.date),
        datasets: [
          {
            label: this.$t('dashboard.appDownloads'),
            data: this.realData.map(item => item.total),
            borderWidth: 2,
            borderColor: '#3160D8',
            backgroundColor: 'transparent',
            pointBorderColor: '#3160D8',
            pointBackgroundColor: '#ffffff',
          },
        ],
      };
    },
    options() {
      return {
        scales: {
          xAxes: [
            {
              ticks: {
                fontSize: '12',
                fontColor: '#777777',
              },
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                fontSize: '12',
                fontColor: '#777777',
              },
              gridLines: {
                color: '#D8D8D8',
                zeroLineColor: '#D8D8D8',
                borderDash: [2, 2],
                zeroLineBorderDash: [2, 2],
                drawBorder: false,
              },
            },
          ],
        },
      };
    },
  },
};
</script>
