export const ACTIVITY_REPORT_LIST_REQUEST = 'ACTIVITY_REPORT_LIST_REQUEST';
export const ACTIVITY_REPORT_LIST_REQUEST_SUCCESS = 'ACTIVITY_REPORT_LIST_REQUEST_SUCCESS';
export const ACTIVITY_REPORT_LIST_REQUEST_ERROR = 'ACTIVITY_REPORT_LIST_REQUEST_ERROR';

export const ACTIVITY_REPORT_CREATE = 'ACTIVITY_REPORT_CREATE';
export const ACTIVITY_REPORT_CREATE_SUCCESS = 'ACTIVITY_REPORT_CREATE_SUCCESS';
export const ACTIVITY_REPORT_CREATE_ERROR = 'ACTIVITY_REPORT_CREATE_ERROR';

export const ACTIVITY_REPORT_SHOW = 'ACTIVITY_REPORT_SHOW';
export const ACTIVITY_REPORT_SHOW_SUCCESS = 'ACTIVITY_REPORT_SHOW_SUCCESS';
export const ACTIVITY_REPORT_SHOW_ERROR = 'ACTIVITY_REPORT_SHOW_ERROR';

export const ACTIVITY_REPORT_UPDATE = 'ACTIVITY_REPORT_UPDATE';
export const ACTIVITY_REPORT_UPDATE_SUCCESS = 'ACTIVITY_REPORT_UPDATE_SUCCESS';
export const ACTIVITY_REPORT_UPDATE_ERROR = 'ACTIVITY_REPORT_UPDATE_ERROR';

export const ACTIVITY_REPORT_DELETE = 'ACTIVITY_REPORT_DELETE';
export const ACTIVITY_REPORT_DELETE_SUCCESS = 'ACTIVITY_REPORT_DELETE_SUCCESS';
export const ACTIVITY_REPORT_DELETE_ERROR = 'ACTIVITY_REPORT_DELETE_ERROR';
