import {
  TRANSACTION_LIST_REQUEST,
  TRANSACTION_LIST_REQUEST_ERROR,
  TRANSACTION_LIST_REQUEST_SUCCESS,
} from '@/store/actions/transaction';
import { handleRequestError } from '@/utils/error';

import Repository, { TRANSACTIONS } from '@/repositories/RepositoryFactory';

const TransactionsRepository = Repository.get(TRANSACTIONS);

const state = {
  transactionList: [],
  transactionListStatus: '',
};

const getters = {
  transactionList: state => state.transactionList,
  transactionListStatus: state => state.transactionListStatus,
};

const actions = {
  [TRANSACTION_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(TRANSACTION_LIST_REQUEST);
    try {
      const response = await TransactionsRepository.get(payload.businessId, payload.data);
      commit(TRANSACTION_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(TRANSACTION_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
};

const mutations = {
  [TRANSACTION_LIST_REQUEST]: state => {
    state.transactionListStatus = 'loading';
  },
  [TRANSACTION_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.transactionListStatus = 'success';
    state.transactionList = payload;
  },
  [TRANSACTION_LIST_REQUEST_ERROR]: state => {
    state.transactionListStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
