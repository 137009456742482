<template>
  <ModalEdit :id="modalId" :title="title" :loading="isLoading" @click="onSubmit">
    <FormApartment :v-form="$v.form" :is-parking="isParking" />
  </ModalEdit>
</template>

<script>
import { mapGetters } from 'vuex';
import { APARTMENT_ADD } from '@/store/actions/apartment';
import { validationMixin } from 'vuelidate';
import { required, decimal, minValue } from 'vuelidate/lib/validators';
import ModalEdit from '@/components/ui/ModalEdit';
import baseModalMixin from '@/mixins/baseModalMixin';
import FormApartment from '@/components/form/FormApartment';

export default {
  mixins: [validationMixin, baseModalMixin],

  components: { ModalEdit, FormApartment },

  props: {
    business: Object,
    apartment: Object,
    isParking: Boolean,
    buildingId: Number,
  },

  validations() {
    if (this.isParking) {
      return {
        form: {
          code: {
            required,
          },
          number: {
            required,
          },
          garageFee: {
            required,
            minValueAmount: minValue(10000),
          },
        },
      };
    }
    return {
      form: {
        code: {
          required,
        },
        number: {
          required,
        },
        square: {
          required,
          decimal,
        },
      },
    };
  },

  data() {
    return {
      form: {
        code: '',
        number: '',
        square: '',
        garageFee: 0,
        isParking: false,
        buildingId: this.buildingId,
        stateBankApartmentName: '',
        corps: '',
      },
    };
  },

  computed: {
    ...mapGetters(['apartmentStatus', 'apartmentLastCreated']),
    isLoading() {
      return this.apartmentStatus === 'loading';
    },
    title() {
      return this.isParking ? this.$t('nav.parkingCreate') : this.$t('nav.apartmentCreate');
    },
  },

  methods: {
    resetForm() {
      this.form = {
        code: '',
        number: '',
        square: '',
        garageFee: 0,
        isParking: false,
        buildingId: this.buildingId,
        stateBankApartmentName: '',
        corps: '',
      };
      this.$v.$reset();
    },
    async onSubmit() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          this.$toasted.show(this.$t('toast.failedForm'), { type: 'error' });
          return;
        }
      }

      try {
        this.form.isParking = this.isParking;
        if (this.isParking) {
          this.form.square = 0;
        }
        const payload = {
          businessId: this.business.id,
          data: this.form,
        };
        await this.$store.dispatch(APARTMENT_ADD, payload);

        if (this.apartmentStatus === 'success') {
          this.$toasted.show(this.$t('toast.success'), { type: 'success' });
          this.$emit('onApartmentAdd', this.apartmentLastCreated);
          this.hideEditModal();
          this.resetForm();
        }
      } catch (error) {
        this.$toasted.show(error.message, { type: 'error' });
      }
    },
  },
};
</script>
