<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">{{ title }}</h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 sm:col-span-6 lg:col-span-4">
        <div class="intro-y box p-5">
          <form @submit.prevent="onSubmit">
            <InputString
              :label="$t('text.TypeName')"
              v-model.trim="$v.form.name.$model"
              :vuelidate="$v.form.name"
              input-class="input-expense-type-name"
            />

            <div class="text-right mt-5">
              <Button
                type="button"
                :to="{ name: 'expenseType' }"
                class="w-24 border dark:border-dark-5 text-gray-700 dark:text-gray-300 mr-1"
              >
                {{ $t('text.cancel') }}
              </Button>
              <Button type="submit" :loading="isLoading" class="w-24 bg-theme-1 text-white">
                {{ $t('text.save') }}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { EXPENSE_TYPE_ADD, EXPENSE_TYPE_UPDATE, EXPENSE_TYPE } from '@/store/actions/expenseType';
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import InputString from '@/components/ui/input/InputString';
import Button from '@/components/ui/button/Button';

export default {
  mixins: [validationMixin],

  components: { InputString, Button },

  validations: {
    form: {
      name: {
        required,
        minLength: minLength(2),
      },
    },
  },

  data() {
    return {
      form: {
        name: '',
      },
    };
  },

  computed: {
    ...mapGetters([
      'expenseTypeAddStatus, expenseType, expenseTypeStatus, expenseTypeUpdateStatus',
    ]),
    isLoading() {
      return this.expenseTypeAddStatus === 'loading';
    },
    title() {
      return this.$route.name === 'expenseTypeCreate'
        ? this.$t('nav.expenseTypeCreate')
        : this.$t('nav.expenseTypeUpdate');
    },
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      if (this.$route.params.expenseTypeId) {
        const payload = { id: this.$route.params.expenseTypeId };
        await this.$store.dispatch(EXPENSE_TYPE, payload);
        if (this.$store.getters.expenseTypeStatus === 'success') {
          this.form.name = this.$store.getters.expenseType;
        }
      }
    },

    async apptest(expenseTypeId) {
      if (expenseTypeId) {
        const payload = {
          id: this.$route.params.expenseTypeId,
          data: this.form,
        };
        await this.$store.dispatch(EXPENSE_TYPE_UPDATE, payload);
        if (this.$store.getters.expenseTypeUpdateStatus === 'success') {
          this.$router.push({ name: 'expenseType' });
          this.$toasted.show(this.$t('toast.success'), { type: 'success' });
        }
      } else {
        const payload = {
          data: this.form,
        };
        await this.$store.dispatch(EXPENSE_TYPE_ADD, payload);
        if (this.$store.getters.expenseTypeAddStatus === 'success') {
          this.$router.push({ name: 'expenseType' });
          this.$toasted.show(this.$t('toast.success'), { type: 'success' });
        }
      }
    },

    async onSubmit() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          this.$toasted.show(this.$t('toast.failedForm'), { type: 'error' });
          return;
        }
      }

      try {
        if (this.$route.params.expenseTypeId) {
          await this.apptest(this.$route.params.expenseTypeId);
        } else {
          await this.apptest();
        }
        if (this.ticketTypeUpdateStatus === 'success') {
          this.$router.push({ name: 'expenseType' });
          this.$toasted.show(this.$t('toast.success'), { type: 'success' });
        }
      } catch (error) {
        this.$toasted.show(error.message, { type: 'error' });
      }
    },
  },
};
</script>
