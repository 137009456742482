import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['userPermissions']),
  },

  methods: {
    checkPermission(permission) {
      return !!permission.split('.').reduce((o, i) => {
        return o[i] ? o[i] : false;
      }, this.userPermissions);
    },
  },
};
