import { findIndex } from 'lodash';
import {
  SMS_TEMPLATE_LIST_REQUEST,
  SMS_TEMPLATE_LIST_REQUEST_SUCCESS,
  SMS_TEMPLATE_LIST_REQUEST_ERROR,
  SMS_TEMPLATE_REQUEST,
  SMS_TEMPLATE_REQUEST_SUCCESS,
  SMS_TEMPLATE_REQUEST_ERROR,
  SMS_TEMPLATE_UPDATE_REQUEST,
  SMS_TEMPLATE_UPDATE_REQUEST_SUCCESS,
  SMS_TEMPLATE_UPDATE_REQUEST_ERROR,
} from '@/store/actions/smsTemplate';

import Repository, { SMS_TEMPLATES } from '@/repositories/RepositoryFactory';
import { handleRequestError } from '@/utils/error';

const SmsTemplatesRepository = Repository.get(SMS_TEMPLATES);

const state = {
  smsTemplate: {},
  smsTemplateStatus: '',
  smsTemplateList: [],
  smsTemplateListStatus: '',
  smsTemplateListMeta: '',
};

const getters = {
  smsTemplate: state => state.smsTemplate,
  smsTemplateStatus: state => state.smsTemplateStatus,
  smsTemplateList: state => state.smsTemplateList,
  smsTemplateListStatus: state => state.smsTemplateListStatus,
  smsTemplateListMeta: state => state.smsTemplateListMeta,
};

const actions = {
  [SMS_TEMPLATE_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(SMS_TEMPLATE_LIST_REQUEST);
    try {
      const response = await SmsTemplatesRepository.get(payload.data);
      commit(SMS_TEMPLATE_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(SMS_TEMPLATE_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [SMS_TEMPLATE_REQUEST]: async ({ commit }, payload) => {
    commit(SMS_TEMPLATE_REQUEST);
    try {
      const response = await SmsTemplatesRepository.show(payload.id);
      const { data } = response;
      commit(SMS_TEMPLATE_REQUEST_SUCCESS, data);
    } catch (error) {
      commit(SMS_TEMPLATE_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [SMS_TEMPLATE_UPDATE_REQUEST]: async ({ commit }, payload) => {
    commit(SMS_TEMPLATE_UPDATE_REQUEST);
    try {
      const response = await SmsTemplatesRepository.update(payload.id, payload.data);
      commit(SMS_TEMPLATE_UPDATE_REQUEST_SUCCESS, response.data.data);
    } catch (error) {
      commit(SMS_TEMPLATE_UPDATE_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
};

const mutations = {
  [SMS_TEMPLATE_LIST_REQUEST]: state => {
    state.smsTemplateListStatus = 'loading';
  },
  [SMS_TEMPLATE_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.smsTemplateListStatus = 'success';
    state.smsTemplateList = payload.data;
    state.smsTemplateListMeta = payload.meta;
  },
  [SMS_TEMPLATE_LIST_REQUEST_ERROR]: state => {
    state.smsTemplateListStatus = 'error';
  },
  [SMS_TEMPLATE_REQUEST]: state => {
    state.smsTemplateStatus = 'loading';
  },
  [SMS_TEMPLATE_REQUEST_SUCCESS]: (state, payload) => {
    state.smsTemplateStatus = 'success';
    state.smsTemplate = payload.data;
  },
  [SMS_TEMPLATE_REQUEST_ERROR]: state => {
    state.smsTemplateStatus = 'error';
  },
  [SMS_TEMPLATE_UPDATE_REQUEST]: state => {
    state.smsTemplateStatus = 'loading';
  },
  [SMS_TEMPLATE_UPDATE_REQUEST_SUCCESS]: (state, payload) => {
    state.smsTemplateStatus = 'success';
    const index = findIndex(state.smsTemplateList, { id: payload.id });
    state.smsTemplateList[index] = payload;
  },
  [SMS_TEMPLATE_UPDATE_REQUEST_ERROR]: state => {
    state.smsTemplateStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
