import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  AUTH_FORGOT_REQUEST,
  AUTH_RESET_PASSWORD,
} from '@/store/actions/auth';
import { handleRequestError, VALIDATION_ERROR_CODE } from '@/utils/error';
import AuthService from '@/services/auth';
import AuthTokenStorageService from '@/services/AuthTokenStorageService';

const state = {
  token: AuthTokenStorageService.getAccessToken() || '',
  status: '',
};

const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status,
  authToken: state => state.token,
  authForgotStatus: state => state.status,
};

const actions = {
  [AUTH_REQUEST]: async ({ commit }, payload) => {
    commit(AUTH_REQUEST);
    try {
      commit(AUTH_SUCCESS, await AuthService.authenticate(payload));
    } catch (error) {
      commit(AUTH_ERROR, error);
      if (error.response?.status === VALIDATION_ERROR_CODE) {
        throw error;
      }
      handleRequestError(error);
    }
  },

  [AUTH_FORGOT_REQUEST]: async ({ commit }, payload) => {
    commit(AUTH_FORGOT_REQUEST, await AuthService.forgotRequest(payload));
  },

  [AUTH_RESET_PASSWORD]: async ({ commit }, payload) => {
    commit(AUTH_RESET_PASSWORD);
    try {
      commit(AUTH_RESET_PASSWORD, await AuthService.resetPassword(payload));
    } catch (error) {
      commit(AUTH_ERROR, error);
      if (error.response?.status === VALIDATION_ERROR_CODE) {
        throw error;
      }
      handleRequestError(error);
    }
  },

  [AUTH_LOGOUT]: ({ commit }) => {
    commit(AUTH_LOGOUT);
    return AuthService.logout();
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  [AUTH_REQUEST]: state => {
    state.status = 'loading';
  },
  [AUTH_FORGOT_REQUEST]: state => {
    state.status = 'loading';
  },
  [AUTH_RESET_PASSWORD]: state => {
    state.status = 'reset';
  },
  [AUTH_SUCCESS]: (state, token) => {
    state.status = 'success';
    state.token = token;
  },
  [AUTH_ERROR]: state => {
    state.status = 'error';
  },
  [AUTH_LOGOUT]: state => {
    state.token = '';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
