<template>
  <TableRegular
    :loading="loading"
    :downloading="downloading"
    :rows="rows"
    :columns="columns"
    :custom-filters="customFilters"
    :sort="sort"
    :page="page"
    :per-page="perPage"
    :page-size="pageSize"
    :total-page="totalPage"
    :downloadable="downloadable && checkPermission('service.services.download')"
    @onFilter="$emit('onFilter', $event)"
    @onPageChange="$emit('onPageChange', $event)"
    @onPerPageChange="$emit('onPerPageChange', $event)"
    @onSortChange="$emit('onSortChange', $event)"
    @onDownload="$emit('onDownload')"
    @onChangeCustomFilter="$emit('onChangeCustomFilter', $event)"
  >
    <template slot="table-row" slot-scope="props">
      <p v-if="props.column.field === 'createdAt'">
        {{ props.row.createdAt | momentDate('YYYY-MM-DD HH:mm') }}
      </p>
      <p v-else-if="props.column.field === 'serviceType'">
        {{ $t(`text.${props.row.serviceType.toLowerCase()}`) }}
      </p>
      <p v-else-if="props.column.field === 'total'" class="whitespace-nowrap">
        {{ props.row.total | currency }}
      </p>
      <p v-else-if="props.column.field === 'importerId'" class="whitespace-nowrap">
        <PeopleFullname v-if="props.row.importer" :item="props.row.importer" />
      </p>
      <p v-else-if="props.column.field === 'userId'" class="whitespace-nowrap">
        <PeopleFullname v-if="props.row.user" :item="props.row.user" />
      </p>
      <p v-else-if="props.column.field === 'apartmentId'" class="font-medium whitespace-nowrap">
        <template v-if="props.row.apartment && props.row.apartment.building">
          <BuildingFullname :item="props.row.apartment.building" />
        </template>
      </p>
      <p v-else-if="props.column.field === 'doorNumber'" class="whitespace-nowrap">
        {{ props.row.apartment.number }}
      </p>
      <p
        v-else-if="props.column.field === 'status'"
        :class="['status status-order', `is-${props.row.status.toLowerCase()}`]"
      >
        {{ $t(`statusOrder.${props.row.status.toLowerCase()}`) }}
      </p>
      <p v-else-if="props.column.field === 'action'" class="flex justify-center items-center">
        <a href="javascript:" class="flex items-center mr-3">
          <EyeIcon class="w-4 h-4 mr-1" />
          {{ $t('table.read') }}
        </a>
        <router-link
          v-if="checkPermission('service.services.edit')"
          :to="{ name: 'ServiceUpdate', params: { expenseId: props.row.id } }"
          class="flex items-center mr-3"
        >
          <CheckSquareIcon class="w-4 h-4 mr-1" />
          {{ $t('table.edit') }}
        </router-link>
        <a
          v-if="checkPermission('service.services.delete')"
          href="javascript:"
          @click="$emit('delete', props.row.id)"
          class="flex items-center text-theme-6"
        >
          <Trash2Icon class="w-4 h-4 mr-1" />
          {{ $t('table.delete') }}
        </a>
      </p>
    </template>
  </TableRegular>
</template>

<script>
import TableRegular from '@/components/ui/table/TableRegular';
import tableMixin from '@/mixins/tableMixin';
import PeopleFullname from '@/components/people/PeopleFullname';
import BuildingFullname from '@/components/building/BuildingFullname';

export default {
  mixins: [tableMixin],
  components: { TableRegular, PeopleFullname, BuildingFullname },
};
</script>
