import User from '@/views/User/User';
import Index from '@/views/User/Index';
import Permission from '@/views/User/Permission';
import UserForm from '@/views/User/UserForm';
import userResetPassword from '@/views/User/ResetPassword';

export default [
  {
    path: '/user',
    component: Index,
    meta: { group: 'users' },
    children: [
      {
        path: '',
        name: 'user',
        component: User,
      },
      {
        path: ':userId/permission',
        name: 'permission',
        component: Permission,
      },
      {
        path: ':userId/update',
        name: 'userUpdate',
        component: UserForm,
      },
      {
        path: 'create',
        name: 'userCreate',
        component: UserForm,
      },
      {
        path: ':userId/reset-password',
        name: 'userResetPassword',
        component: userResetPassword,
      },
    ],
  },
];
