import Businesses from '@/views/Businesses';

export default [
  {
    path: '/businesses',
    name: 'AllBusiness',
    component: Businesses,
    meta: { group: 'all_business' },
  },
];
