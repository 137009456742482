<template>
  <ModalEdit :id="modalId" :title="$t('nav.businessUpdate')" :loading="isLoading" @click="onSubmit">
    <div class="p-5">
      <InputString
        type="password"
        :placeholder="$t('text.password')"
        v-model.trim="$v.form.password.$model"
        :vuelidate="$v.form.password"
        input-class="input-change-password"
      />
      <InputString
        type="password"
        :placeholder="$t('text.passwordConfirmation')"
        v-model.trim="$v.form.passwordConfirmation.$model"
        :vuelidate="$v.form.passwordConfirmation"
        input-class="input-change-password-confirmation"
      />
    </div>
  </ModalEdit>
</template>

<script>
import { mapGetters } from 'vuex';
import { BUSINESS_UPDATE } from '@/store/actions/business';
import { validationMixin } from 'vuelidate';
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
import ModalEdit from '@/components/ui/ModalEdit';
import InputString from '@/components/ui/input/InputString';
import baseModalMixin from '@/mixins/baseModalMixin';

export default {
  mixins: [validationMixin, baseModalMixin],

  components: { ModalEdit, InputString },

  props: {
    businessId: Number,
  },

  data() {
    return {
      form: {
        password: '',
        passwordConfirmation: '',
      },
    };
  },

  validations: {
    form: {
      password: {
        required,
        minLength: minLength(6),
      },
      passwordConfirmation: {
        required,
        minLength: minLength(6),
        sameAs: sameAs('password'),
      },
    },
  },

  computed: {
    ...mapGetters(['businessStatus', 'businessList']),
    isLoading() {
      return this.businessStatus === 'loading';
    },
    title() {
      return this.$t('nav.changePassword');
    },
  },

  methods: {
    async onSubmit() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          this.$toasted.show(this.$t('toast.failedForm'), { type: 'error' });
          return;
        }
      }

      try {
        const payload = {
          id: this.businessId,
          data: {
            password: this.form.password,
          },
        };
        await this.$store.dispatch(BUSINESS_UPDATE, payload);
        if (this.businessStatus === 'success') {
          this.$toasted.show(this.$t('toast.success'), { type: 'success' });
          this.hideEditModal();
        }
      } catch (error) {
        this.$toasted.show(error.message, { type: 'error' });
      }
    },
    hideEditModal() {
      cash(`#${this.modalId}`).modal('hide');
    },
  },
};
</script>
