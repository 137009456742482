import Client from '@/clients/apiClient';
import humps from 'humps';
import { stringify } from 'query-string';

const resource = 'admin';

export default {
  get(payload) {
    return Client.get(
      `${resource}?page=${payload.page}&limit=${payload.perPage}&sort[field]=${humps.decamelize(
        payload.sort.field
      )}&sort[type]=${payload.sort.type}&${stringify(humps.decamelizeKeys(payload.filters))}`
    );
  },
  getBusinessAdmins(payload) {
    return Client.get(
      `/${resource}/get-business-admin-user?page=${payload.page}&limit=${
        payload.perPage
      }&sort[field]=${humps.decamelize(payload.sort.field)}&sort[type]=${
        payload.sort.type
      }&${stringify(humps.decamelizeKeys(payload.filters))}`
    );
  },
  approveBusinessAdmin(id) {
    return Client.put(`/${resource}/set-business-admin-user/${id}`);
  },
  deleteBusinessAdmin(id) {
    return Client.delete(`/${resource}/delete-business-admin-user/${id}`);
  },
};
