<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center">
      <PageHeader class="text-lg font-medium mr-auto" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <router-link
          v-if="checkPermission('finance.service.create')"
          :to="{ name: 'ServiceProductCreate' }"
          class="button text-white bg-theme-1 shadow-md"
        >
          {{ $t('text.create') }}
        </router-link>
      </div>
    </div>
    <div class="intro-y mt-5 overflow-auto lg:overflow-visible">
      <ServiceProductTable
        :loading="isLoading"
        :rows="serviceProductList"
        :columns="columns"
        :line-numbers="true"
        :sort="sort"
        :page="page"
        :per-page="perPage"
        :page-size="pageSize"
        :total-page="totalPage"
        @delete="confirmRemoval"
        @onFilter="onFilter"
        @onPageChange="onPageChange"
        @onPerPageChange="onPerPageChange"
        @onSortChange="onSortChange"
      />
    </div>

    <ConfirmDialog
      :modal-id="deleteModalId"
      :is-loading="isDeleting ? true : false"
      @ok="onDelete"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  SERVICE_PRODUCT_LIST_REQUEST,
  SERVICE_PRODUCT_REMOVE,
} from '@/store/actions/serviceProduct';
import PageHeader from '@/components/ui/PageHeader';
import ServiceProductTable from '@/components/service-product/ServiceProductTable';
import dataListMixin from '@/mixins/dataListMixin';

export default {
  mixins: [dataListMixin],

  components: {
    PageHeader,
    ServiceProductTable,
  },

  data() {
    return {
      storeActionListRequest: SERVICE_PRODUCT_LIST_REQUEST,
      storeActionRemove: SERVICE_PRODUCT_REMOVE,
      columns: [
        {
          label: this.$t('table.name'),
          field: 'name',
          sortable: true,
          filterable: true,
        },
        {
          label: this.$t('table.price'),
          field: 'price',
          sortable: true,
          filterable: true,
        },
        {
          label: this.$t('table.type'),
          field: 'priceType',
          sortable: true,
          filterable: true,
        },
        {
          label: this.$t('table.action'),
          field: 'action',
        },
      ],
    };
  },

  computed: {
    ...mapGetters(['serviceProductList', 'serviceProductMeta', 'serviceProductStatus']),
    isLoading() {
      return this.serviceProductStatus === 'loading';
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    setPaginationValues() {
      this.totalRecords = this.serviceProductMeta ? this.serviceProductMeta.total : 1;
      this.page = this.serviceProductMeta.currentPage;
      this.totalPage = this.serviceProductMeta.lastPage;
    },
  },
};
</script>
