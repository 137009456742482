<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <PageHeader class="text-lg font-medium mr-auto" :title="title" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <Button
          type="button"
          :to="{ name: 'user' }"
          class="box text-gray-700 dark:text-gray-300 mr-2 flex"
        >
          {{ $t('text.back') }}
        </Button>
        <Button
          type="button"
          @click="onSubmit"
          :loading="isLoading"
          class="w-24 bg-theme-1 text-white"
        >
          {{ $t('text.save') }}
        </Button>
      </div>
    </div>
    <div class="intro-y mt-5">
      <form>
        <div class="grid grid-cols-12 gap-6">
          <div class="intro-y col-span-12 sm:col-span-12 lg:col-span-4 xl:col-span-4">
            <div class="intro-y box">
              <div class="p-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class="font-medium text-base">{{ $t('nav.userShow') }}</h2>
              </div>
              <FormUser :form="$v.form" :edit="edit" />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/button/Button';
import PageHeader from '@/components/ui/PageHeader';
import FormUser from '@/components/user/FormUser';
import { validationMixin } from 'vuelidate';
import { mapGetters } from 'vuex';
import { USER_GET_REQUEST, USER_CREATE_REQUEST, USER_UPDATE_REQUEST } from '@/store/actions/user';
import { email, helpers, minLength, required, sameAs } from 'vuelidate/lib/validators';
import phone from '@/utils/phone';

export default {
  mixins: [validationMixin],

  components: { Button, PageHeader, FormUser },

  props: {
    business: Object,
  },

  data() {
    return {
      edit: true,
      form: {
        lastName: '',
        firstName: '',
        phone: '',
        email: '',
        password: '',
        passwordConfirmation: '',
      },
    };
  },

  validations() {
    return this.$route.name === 'userCreate'
      ? {
          form: {
            lastName: {
              required,
              minLength: minLength(2),
            },
            firstName: {
              required,
              minLength: minLength(2),
            },
            phone: {
              required,
              phoneNumber: helpers.regex('phoneNumber', phone),
            },
            email: {
              required,
              email,
            },
            password: {
              required,
              minLength: minLength(6),
            },
            passwordConfirmation: {
              required,
              minLength: minLength(6),
              sameAs: sameAs('password'),
            },
          },
        }
      : {
          form: {
            lastName: {
              required,
              minLength: minLength(2),
            },
            firstName: {
              required,
              minLength: minLength(2),
            },
            phone: {
              required,
              phoneNumber: helpers.regex('phoneNumber', phone),
            },
            email: {
              required,
              email,
            },
            password: {},
            passwordConfirmation: {},
          },
        };
  },

  computed: {
    ...mapGetters(['user', 'usersStatus']),
    isLoading() {
      return this.usersStatus === 'loading';
    },
    title() {
      return this.$route.name === 'userCreate'
        ? this.$t('nav.userCreate')
        : this.$t('nav.userUpdate');
    },
  },

  async created() {
    await this.fetchData();
  },

  methods: {
    async fetchData() {
      if (this.$route.params.userId) {
        const payload = { businessId: this.business.id, id: this.$route.params.userId };
        await this.$store.dispatch(USER_GET_REQUEST, payload);
        const { user } = this;
        if (user) {
          this.form = {
            lastName: user.lastName,
            firstName: user.firstName,
            phone: user.phone,
            email: user.email,
          };
        }
        this.edit = true;
      }
    },

    async onSubmit() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          this.$toasted.show(this.$t('toast.failedForm'), { type: 'error' });
          return;
        }
      }

      try {
        if (this.$route.params.userId) {
          const payload = {
            businessId: this.business.id,
            id: this.user.id,
            data: this.form,
          };
          await this.$store.dispatch(USER_UPDATE_REQUEST, payload);
        } else {
          const payload = {
            businessId: this.business.id,
            data: this.form,
          };
          await this.$store.dispatch(USER_CREATE_REQUEST, payload);
        }

        if (this.usersStatus === 'success') {
          await this.$router.push({ name: 'user' });
          this.$toasted.show(this.$t('toast.success'), { type: 'success' });
        }
      } catch (error) {
        this.$toasted.show(error.message, { type: 'error' });
      }
    },
  },
};
</script>
