<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <PageHeader class="text-lg font-medium mr-auto" :title="title" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <Button
          type="button"
          :to="{ name: 'Forums' }"
          class="box text-gray-700 dark:text-gray-300 mr-2 flex"
        >
          {{ $t('text.back') }}
        </Button>
        <Button
          type="button"
          @click="onSubmit"
          :loading="isLoading"
          class="w-24 bg-theme-1 text-white"
        >
          {{ $t('text.save') }}
        </Button>
      </div>
    </div>
    <div class="intro-y mt-5">
      <form @submit.prevent="onSubmit">
        <div class="grid grid-cols-12 gap-6">
          <div class="intro-y col-span-12 sm:col-span-12 lg:col-span-7 xl:col-span-7">
            <div class="intro-y box">
              <div class="p-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class="font-medium text-base">{{ $t('nav.forumShow') }}</h2>
              </div>
              <div class="py-4 px-5 relative">
                <div class="grid grid-cols-12">
                  <div class="intro-y col-span-12 sm:col-span-12">
                    <InputString
                      :label="$t('text.title')"
                      v-model.trim="$v.form.title.$model"
                      :vuelidate="$v.form.title"
                      input-class="input-forum-title"
                    />
                    <InputString
                      :label="$t('text.description')"
                      v-model.trim="$v.form.description.$model"
                      :vuelidate="$v.form.description"
                      input-class="input-forum-description"
                      is-textarea
                    />
                    <InputSelect
                      :label="$t('text.status')"
                      v-model.trim="$v.form.status.$model"
                      :options="forumsTypes"
                      value-key="id"
                      :vuelidate="$v.form.status"
                      input-class="input-forum-status"
                    />
                    <InputString
                      :label="$t('text.respondent')"
                      :value="me.firstName"
                      disabled="disabled"
                      input-class="input-forum-first-name"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="box mt-5">
              <div class="intro-y p-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class="font-medium text-base">{{ $t('nav.buildingShow') }}</h2>
              </div>
              <div class="intro-y py-4 px-5">
                <InputSelect
                  v-model.number="$v.form.buildingId.$model"
                  :options="buildingList"
                  :label="$t('text.selectBuilding')"
                  value-key="id"
                  :vuelidate="$v.form.buildingId"
                  input-class="input-forum-building"
                  :disabled="isLoadingBuildings"
                  @input="onChangeBuilding()"
                />
              </div>
            </div>
          </div>
          <div
            v-if="form.buildingId"
            class="intro-y col-span-12 sm:col-span-12 lg:col-span-5 xl:col-span-5"
          >
            <div class="intro-y box overflow-y-scroll max-h-96">
              <div class="p-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class="font-medium text-base">
                  {{ $t('text.apartmentsTitle') }}
                </h2>
              </div>
              <Loading v-if="isLoadingApartments" />
              <template v-else>
                <div class="py-4 px-5 border-b border-gray-200 dark:border-dark-5">
                  <div class="w-full relative">
                    <SearchIcon
                      class="w-4 h-4 absolute my-auto inset-y-0 ml-3 left-0 z-10 text-gray-600 dark:text-gray-300"
                    />
                    <input
                      type="text"
                      v-model="search"
                      :placeholder="$t('table.searchPlaceholder')"
                      class="input-forum-apartment-search pl-10 pr-24 input input-lg w-full border"
                    />
                  </div>
                </div>
                <label
                  v-if="!search"
                  class="py-4 px-5 border-b border-gray-200 dark:border-dark-5 flex items-center cursor-pointer hover:bg-gray-100 dark:hover:bg-dark-2"
                >
                  <input
                    class="input-forum-select-all input border border-gray-500"
                    type="checkbox"
                    @change="toggleSelectAll($event.target.checked)"
                  />
                  <div class="ml-3 font-bold">
                    {{ $t('text.selectAll') }}
                  </div>
                </label>
                <template v-for="(apartment, index) in filteredApartmentList">
                  <label
                    :key="index"
                    class="apartment-toggler-container py-2 px-5 border-b border-gray-200 dark:border-dark-5 flex items-center cursor-pointer hover:bg-gray-100 dark:hover:bg-dark-2"
                  >
                    <div class="mr-3">
                      <input
                        class="input border border-gray-500"
                        :checked="checkItemInArray(apartment, selectedApartments)"
                        @change="toggleItem(apartment, selectedApartments)"
                        type="checkbox"
                      />
                    </div>
                    <ApartmentAndCode :key="index" :item="apartment" />
                  </label>
                </template>
              </template>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { assign } from 'lodash';
import { mapGetters } from 'vuex';
import { FORUMS_SHOW, FORUMS_ADD, FORUMS_UPDATE } from '@/store/actions/forums';
import { APARTMENT_LIST_REQUEST } from '@/store/actions/apartment';
import { BUILDING_LIST_REQUEST } from '@/store/actions/building';
import { USER_LIST_REQUEST } from '@/store/actions/user';
import { FORUM_STATUS_TYPES } from '@/utils/constants';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import Button from '@/components/ui/button/Button';
import PageHeader from '@/components/ui/PageHeader';
import InputString from '@/components/ui/input/InputString';
import InputSelect from '@/components/ui/input/InputSelect';
import ApartmentAndCode from '@/components/apartment/ApartmentAndCode';

export default {
  mixins: [validationMixin],

  components: {
    Button,
    PageHeader,
    InputString,
    InputSelect,
    ApartmentAndCode,
  },

  props: {
    business: Object,
  },

  validations: {
    form: {
      title: {
        required,
      },
      description: {
        required,
      },
      status: {
        required,
      },
      buildingId: {
        required,
      },
    },
    selectedApartments: {
      required,
    },
  },

  data() {
    return {
      form: {
        title: '',
        status: '',
        description: '',
        assignedTo: null,
        buildingId: '',
        apartmentId: [],
        apartments: [],
      },
      selectedApartments: [],
      forumsTypes: FORUM_STATUS_TYPES,
      defaultTableData: {
        filters: {},
        sort: {
          field: 'id',
          type: 'desc',
        },
        page: 1,
        perPage: 100,
      },
      search: '',
    };
  },

  computed: {
    ...mapGetters([
      'me',
      'forumsStatus',
      'forum',
      'buildingStatus',
      'buildingList',
      'apartmentList',
      'apartmentListStatus',
      'userList',
      'userListStatus',
    ]),
    isLoading() {
      return this.forumsStatus === 'loading';
    },
    isLoadingBuildings() {
      return this.buildingStatus === 'loading';
    },
    isLoadingApartments() {
      return this.apartmentListStatus === 'loading';
    },
    userListLoading() {
      return this.userListStatus === 'loading';
    },
    title() {
      return this.$route.name === 'ForumCreate'
        ? this.$t(`nav.forumCreate`)
        : this.$t(`nav.forumUpdate`);
    },
    filteredApartmentList() {
      return this.apartmentList.filter(apartment => apartment.number.includes(this.search));
    },
  },

  async created() {
    await this.fetchData();
    this.form.assignedTo = this.me.id;
  },

  methods: {
    async fetchData() {
      if (this.$route.params.id) {
        const payload = { id: this.$route.params.id };
        await this.$store.dispatch(FORUMS_SHOW, payload);
        if (this.forum) {
          const {
            title,
            status,
            description,
            assignedTo,
            buildingId,
            apartmentId,
            apartments,
          } = this.forum;

          this.form = {
            title,
            status,
            description,
            assignedTo,
            buildingId,
            apartmentId,
            apartments,
          };
          this.selectedApartments = apartments;
        }
      }
      await this.fetchUserList();
      this.fetchBuildingList();
      this.onChangeBuilding();
    },

    async fetchBuildingList() {
      const payload = {
        businessId: this.business.id,
        data: this.defaultTableData,
      };

      await this.$store.dispatch(BUILDING_LIST_REQUEST, payload);
    },

    async getApartmentList() {
      const data = this.defaultTableData;
      data.filters['filter[buildingId]'] = this.form.buildingId;
      data.filters['filter[isParking]'] = 0;

      const payload = {
        businessId: this.business.id,
        data,
      };

      await this.$store.dispatch(APARTMENT_LIST_REQUEST, payload);
    },

    async fetchUserList() {
      const data = { page: 1, perPage: 100 };
      const payload = { businessId: this.business.id, data };
      await this.$store.dispatch(USER_LIST_REQUEST, payload);
    },

    async onChangeBuilding() {
      this.form.apartmentId = [];
      await this.getApartmentList();
    },

    toggleItem(object, array) {
      const index = array.findIndex(item => item.id === object.id);
      if (index > -1) {
        array.splice(index, 1);
      } else {
        array.push(object);
      }
    },

    checkItemInArray(object, array) {
      const index = array.findIndex(item => item.id === object.id);
      if (index > -1) {
        return true;
      }
      return false;
    },

    toggleSelectAll(checked) {
      if (checked) {
        this.selectedApartments = [...this.apartmentList];
      } else {
        this.selectedApartments = [];
      }
    },
    async onSubmit() {
      if (this.$v) {
        this.$v.selectedApartments.$touch();
        this.$v.form.$touch();
        if (this.$v.form.$anyError || this.$v.selectedApartments.$anyError) {
          if (this.$v.selectedApartments.$anyError) {
            this.$toasted.show(this.$t('toast.requiredApartment'), { type: 'error' });
            return;
          }
          this.$toasted.show(this.$t('toast.failedForm'), { type: 'error' });
          return;
        }
      }

      try {
        this.form.apartmentId = this.selectedApartments.map(item => item.id);
        this.form.apartments = this.form.apartmentId;
        const payload = {
          businessId: this.business.id,
          data: this.form,
        };

        if (this.$route.params.id) {
          assign(payload, { id: this.forum.id });
          await this.$store.dispatch(FORUMS_UPDATE, payload);
        } else {
          await this.$store.dispatch(FORUMS_ADD, payload);
        }
        if (this.forumsStatus === 'success') {
          this.$router.push({ name: 'Forums' });
          this.$toasted.show(this.$t('toast.success'), { type: 'success' });
        }
      } catch (error) {
        this.$toasted.show(error.message, { type: 'error' });
      }
    },
  },
};
</script>
<style>
.max-h-96 {
  max-height: 36rem !important;
}
</style>
