<template>
  <div>
    <DarkModeSwitcher />
    <div class="container sm:px-10 mx-auto">
      <div class="block xl:grid grid-cols-2 gap-4">
        <div class="hidden xl:flex flex-col min-h-screen">
          <a href class="-intro-x flex items-center pt-5">
            <img
              v-if="isUB"
              :alt="$t('project.name')"
              src="@/assets/images/aillogoshine.png"
              class="w-12"
            />
            <img
              v-else
              :alt="$t('project.name')"
              src="@/assets/images/logo_darkhan.png"
              class="w-12"
            />
            <span class="text-white text-lg ml-3">
              <span class="font-medium">{{ $t('project.name') }}</span>
            </span>
          </a>
          <div class="my-auto">
            <img
              v-if="isUB"
              :alt="$t('project.name')"
              src="@/assets/images/photo1.png"
              class="-intro-x w-3/5 mx-24"
            />
            <img
              v-else
              :alt="$t('project.name')"
              src="@/assets/images/photo_3.png"
              class="-intro-x w-3/5 mx-24"
            />
            <div class="-intro-x text-white font-thin animate-spin text-4xl leading-tight mx-20">
              {{ $t('project.slogan') }}
            </div>
          </div>
        </div>
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div
            class="my-auto mx-auto xl:ml-20 bg-white xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
          >
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DarkModeSwitcher from '@/components/ui/DarkModeSwitcher';

export default {
  components: { DarkModeSwitcher },

  data() {
    return {
      isUB: process.env.VUE_APP_LOCATION !== 'da',
    };
  },

  mounted() {
    document.querySelector('body').classList.add('login');
  },
};
</script>
