<template>
  <div class="mobile-menu md:hidden">
    <div class="mobile-menu-bar">
      <a class="flex mr-auto">
        <img
          v-if="isUB"
          :alt="$t('project.name')"
          src="@/assets/images/aillogoshine.png"
          class="w-12"
        />
        <img v-else :alt="$t('project.name')" src="@/assets/images/logo_darkhan.png" class="w-12" />
      </a>
      <BarChart2Icon class="w-8 h-8 text-white transform -rotate-90" @click="toggleMobileMenu" />
    </div>
    <transition @enter="enter" @leave="leave">
      <ul v-if="activeMobileMenu" class="border-t border-theme-24 py-5">
        <template v-for="(menu, menuKey) in formattedMenu">
          <li v-if="menu === 'divider'" :key="menuKey" class="menu__divider my-6"></li>
          <li v-else :key="menuKey">
            <a
              href="javascript:;"
              class="menu"
              :class="{
                'menu--active': menu.active,
                'menu--open': menu.activeDropdown,
              }"
              @click="linkTo(menu)"
            >
              <div class="menu__icon">
                <component :is="menu.icon" />
              </div>
              <div class="menu__title">
                {{ menu.title }}
                <ChevronDownIcon
                  v-if="issei(menu.subMenu)"
                  class="menu__sub-icon"
                  :class="{ 'transform rotate-180': menu.activeDropdown }"
                />
              </div>
            </a>
            <transition @enter="enter" @leave="leave">
              <ul v-if="issei(menu.subMenu) && menu.activeDropdown">
                <li v-for="(subMenu, subMenuKey) in menu.subMenu" :key="subMenuKey">
                  <a
                    href="javascript:;"
                    class="menu"
                    :class="{ 'menu--active': subMenu.active }"
                    @click="linkTo(subMenu)"
                  >
                    <div class="menu__icon">
                      <component :is="subMenu.icon" />
                    </div>
                    <div class="menu__title">
                      {{ subMenu.title }}
                      <ChevronDownIcon
                        v-if="issei(subMenu.subMenu)"
                        class="menu__sub-icon"
                        :class="{
                          'transform rotate-180': subMenu.activeDropdown,
                        }"
                      />
                    </div>
                  </a>
                  <transition @enter="enter" @leave="leave">
                    <ul v-if="issei(subMenu.subMenu) && subMenu.activeDropdown">
                      <li
                        v-for="(lastSubMenu, lastSubMenuKey) in subMenu.subMenu"
                        :key="lastSubMenuKey"
                      >
                        <a
                          href="javascript:;"
                          class="menu"
                          :class="{ 'menu--active': lastSubMenu.active }"
                          @click="linkTo(lastSubMenu)"
                        >
                          <div class="menu__icon">
                            <component :is="lastSubMenu.icon" />
                          </div>
                          <div class="menu__title">
                            {{ lastSubMenu.title }}
                          </div>
                        </a>
                      </li>
                    </ul>
                  </transition>
                </li>
              </ul>
            </transition>
          </li>
        </template>
      </ul>
    </transition>
  </div>
</template>

<script>
import navigationMixin from '@/mixins/navigationMixin';

export default {
  mixins: [navigationMixin],

  data() {
    return {
      activeMobileMenu: false,
      isUB: process.env.VUE_APP_LOCATION !== 'da',
    };
  },

  methods: {
    toggleMobileMenu() {
      this.activeMobileMenu = !this.activeMobileMenu;
    },
  },
};
</script>
