<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">{{ title }}</h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 sm:col-span-6 lg:col-span-4">
        <div class="intro-y box p-5">
          <form @submit.prevent="onSubmit">
            <InputString
              :label="$t('text.name')"
              v-model.trim="$v.form.name.$model"
              :vuelidate="$v.form.name"
              input-class="input-service-product-name"
            />

            <InputMoney
              :label="$t('text.price')"
              v-model.trim="$v.form.price.$model"
              :vuelidate="$v.form.price"
              input-class="input-service-product-price"
            />

            <InputSelect
              v-model.number="$v.form.priceType.$model"
              :options="priceTypes"
              :label="$t('text.priceType')"
              :vuelidate="$v.form.priceType"
              input-class="input-service-product-price-type"
              value-key="type"
            />

            <InputSelect
              :label="$t('text.stateBankServices')"
              v-model.number="form.stateBankServiceId"
              :options="stateBankServiceList"
              value-key="id"
              :disabled="isLoadingStateBankServices"
              input-class="input-service-product-state-bank-service"
            />

            <div class="text-right mt-5">
              <Button
                type="button"
                :to="{ name: 'ServiceProducts' }"
                class="w-24 border dark:border-dark-5 text-gray-700 dark:text-gray-300 mr-1"
              >
                {{ $t('text.cancel') }}
              </Button>
              <Button type="submit" :loading="isLoading" class="w-24 bg-theme-1 text-white">
                {{ $t('text.save') }}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  SERVICE_PRODUCT_ADD,
  SERVICE_PRODUCT_UPDATE,
  SERVICE_PRODUCT_REQUEST,
  STATE_BANK_SERVICE_LIST_REQUEST,
} from '@/store/actions/serviceProduct';
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import InputString from '@/components/ui/input/InputString';
import InputMoney from '@/components/ui/input/InputMoney';
import InputSelect from '@/components/ui/input/InputSelect';
import Button from '@/components/ui/button/Button';

export default {
  mixins: [validationMixin],

  components: { InputString, InputMoney, InputSelect, Button },

  props: {
    business: Object,
  },

  validations: {
    form: {
      name: {
        required,
        minLength: minLength(2),
      },
      price: {
        required,
      },
      priceType: {
        required,
      },
    },
  },

  data() {
    return {
      form: {
        name: '',
        price: 0,
        priceType: '',
        stateBankServiceId: null,
      },
      priceTypes: [
        {
          type: 'SQUARE_PRICE',
          name: this.$t('text.perSquareMeter'),
        },
        {
          type: 'WHOLE_PRICE',
          name: this.$t('text.wholePrice'),
        },
      ],
    };
  },

  computed: {
    ...mapGetters([
      'serviceProductStatus',
      'serviceProduct',
      'stateBankServiceList',
      'stateBankServiceListStatus',
    ]),
    isLoading() {
      return this.serviceProductStatus === 'loading';
    },
    isLoadingStateBankServices() {
      return this.stateBankServiceListStatus === 'loading';
    },
    title() {
      return this.$route.name === 'ServiceProductCreate'
        ? this.$t('nav.serviceProductCreate')
        : this.$t('nav.serviceProductUpdate');
    },
  },

  async created() {
    this.$store.dispatch(STATE_BANK_SERVICE_LIST_REQUEST);
    await this.fetchData();
  },

  methods: {
    async fetchData() {
      if (this.$route.params.serviceProductId) {
        const payload = { businessId: this.business.id, id: this.$route.params.serviceProductId };
        await this.$store.dispatch(SERVICE_PRODUCT_REQUEST, payload);
        if (this.serviceProduct) {
          this.form = {
            name: this.serviceProduct.name,
            price: this.serviceProduct.price,
            priceType: this.serviceProduct.priceType,
            stateBankServiceId: this.serviceProduct.stateBankServiceId,
          };
        }
      }
    },

    async onSubmit() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          this.$toasted.show(this.$t('toast.failedForm'), { type: 'error' });
          return;
        }
      }

      try {
        if (this.$route.params.serviceProductId) {
          const payload = {
            businessId: this.business.id,
            id: this.serviceProduct.id,
            data: this.form,
          };
          await this.$store.dispatch(SERVICE_PRODUCT_UPDATE, payload);
        } else {
          const payload = {
            businessId: this.business.id,
            data: this.form,
          };
          await this.$store.dispatch(SERVICE_PRODUCT_ADD, payload);
        }

        if (this.serviceProductStatus === 'success') {
          this.$router.push({ name: 'ServiceProducts' });
          this.$toasted.show(this.$t('toast.success'), { type: 'success' });
        }
      } catch (error) {
        this.$toasted.show(error.message, { type: 'error' });
      }
    },
  },
};
</script>
