export default {
  props: {
    modalId: {
      type: String,
      default: 'base-modal',
    },
  },
  methods: {
    hideEditModal() {
      cash(`#${this.modalId}`).modal('hide');
    },
  },
};
