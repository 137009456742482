export const PARKING_LIST_REQUEST = 'PARKING_LIST_REQUEST';
export const PARKING_LIST_REQUEST_SUCCESS = 'PARKING_LIST_REQUEST_SUCCESS';
export const PARKING_LIST_REQUEST_ERROR = 'PARKING_LIST_REQUEST_ERROR';

export const PARKING_ADD = 'PARKING_ADD';
export const PARKING_ADD_SUCCESS = 'PARKING_ADD_SUCCESS';
export const PARKING_ADD_ERROR = 'PARKING_ADD_ERROR';

export const PARKING_UPDATE = 'PARKING_UPDATE';
export const PARKING_UPDATE_SUCCESS = 'PARKING_UPDATE_SUCCESS';
export const PARKING_UPDATE_ERROR = 'PARKING_UPDATE_ERROR';

export const PARKING_REMOVE = 'PARKING_REMOVE';
export const PARKING_REMOVE_SUCCESS = 'PARKING_REMOVE_SUCCESS';
export const PARKING_REMOVE_ERROR = 'PARKING_REMOVE_ERROR';

export const PARKING_REQUEST = 'PARKING_REQUEST';
export const PARKING_REQUEST_SUCCESS = 'PARKING_REQUEST_SUCCESS';
export const PARKING_REQUEST_ERROR = 'PARKING_REQUEST_ERROR';

export const PARKING_CHILD_LIST_IMPORT = 'PARKING_CHILD_LIST_IMPORT';
export const PARKING_CHILD_LIST_IMPORT_SUCCESS = 'PARKING_CHILD_LIST_IMPORT_SUCCESS';
export const PARKING_CHILD_LIST_IMPORT_ERROR = 'PARKING_CHILD_LIST_IMPORT_ERROR';
