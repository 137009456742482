export const APP_VERSION_LIST_REQUEST = 'APP_VERSION_LIST_REQUEST';
export const APP_VERSION_LIST_REQUEST_SUCCESS = 'APP_VERSION_LIST_REQUEST_SUCCESS';
export const APP_VERSION_LIST_REQUEST_ERROR = 'APP_VERSION_LIST_REQUEST_ERROR';

export const APP_VERSION_SHOW = 'APP_VERSION_SHOW';
export const APP_VERSION_SHOW_SUCCESS = 'APP_VERSION_SHOW_SUCCESS';
export const APP_VERSION_SHOW_ERROR = 'APP_VERSION_SHOW_ERROR';

export const APP_VERSION_CREATE = 'APP_VERSION_CREATE';
export const APP_VERSION_CREATE_SUCCESS = 'APP_VERSION_CREATE_SUCCESS';
export const APP_VERSION_CREATE_ERROR = 'APP_VERSION_CREATE_ERROR';

export const APP_VERSION_UPDATE = 'APP_VERSION_UPDATE';
export const APP_VERSION_UPDATE_SUCCESS = 'APP_VERSION_UPDATE_SUCCESS';
export const APP_VERSION_UPDATE_ERROR = 'APP_VERSION_UPDATE_ERROR';

export const APP_VERSION_DELETE = 'APP_VERSION_DELETE';
export const APP_VERSION_DELETE_SUCCESS = 'APP_VERSION_DELETE_SUCCESS';
export const APP_VERSION_DELETE_ERROR = 'APP_VERSION_DELETE_ERROR';
