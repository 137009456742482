import Index from '@/views/Service/Index';
import Service from '@/views/Service/Service';
import Services from '@/views/Service/Services';
import ServiceForm from '@/views/Service/ServiceForm';
import Importers from '@/views/Service/Importers';
import ImporterForm from '@/views/Service/ImporterForm';

export default [
  {
    path: '/services',
    component: Index,
    meta: { group: 'services' },
    children: [
      {
        path: '',
        component: Service,
        children: [
          { path: '', name: 'Services', component: Services },
          { path: 'importers', name: 'Importers', component: Importers },
        ],
      },
      { path: 'create-service', name: 'ServiceCreate', component: ServiceForm },
      { path: 'update-service/:serviceId', name: 'ServiceUpdate', component: ServiceForm },
      { path: 'importer/create', name: 'ImporterCreate', component: ImporterForm },
      { path: 'importer/:importerId', name: 'ImporterUpdate', component: ImporterForm },
    ],
  },
];
