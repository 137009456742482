<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-5">
      <PageHeader class="text-lg font-medium mr-auto" />
      <div
        v-if="checkPermission('registration.building.create')"
        class="w-full sm:w-auto flex mt-4 sm:mt-0"
      >
        <router-link
          :to="{ name: 'BuildingCreate' }"
          class="button text-white bg-theme-1 shadow-md"
        >
          {{ $t('text.create') }}
        </router-link>
      </div>
    </div>
    <div class="intro-y mt-5">
      <BuildingList :buildings="buildingList" :loading="isLoading" @delete="confirmRemoval" />
    </div>

    <ConfirmDialog
      :modal-id="deleteModalId"
      :is-loading="isDeleting ? true : false"
      @ok="onDelete"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BUILDING_LIST_REQUEST, BUILDING_REMOVE } from '@/store/actions/building';
import PageHeader from '@/components/ui/PageHeader';
import BuildingList from '@/components/building/BuildingList';
import dataListMixin from '@/mixins/dataListMixin';
import permissionMixin from '@/mixins/permissionMixin';

export default {
  mixins: [dataListMixin, permissionMixin],

  components: {
    PageHeader,
    BuildingList,
  },

  data() {
    return {
      perPage: 100,
      storeActionListRequest: BUILDING_LIST_REQUEST,
      storeActionRemove: BUILDING_REMOVE,
    };
  },

  computed: {
    ...mapGetters(['buildingList', 'buildingMeta', 'buildingStatus']),
    isLoading() {
      return this.buildingStatus === 'loading';
    },
  },

  created() {
    this.fetchData();
  },
};
</script>
