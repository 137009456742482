<template>
  <ModalEdit :id="modalId" :title="title" :loading="isLoading" @click="onSubmit">
    <div class="p-5">
      <InputFile
        ref="inputFile"
        :label="$t('text.chooseFile')"
        :value="$v.form.file.$model"
        accept-files=".xls, .xlsx, .csv"
        :vuelidate="$v.form.file"
        class="input-file-statement-file"
        @upload="setFile"
      />
      <div v-if="serverErrors" class="text-theme-6 mt-2">
        <p v-for="(error, index) in serverErrors" :key="index">{{ error[0] }}</p>
      </div>
    </div>
  </ModalEdit>
</template>

<script>
import { mapGetters } from 'vuex';
import { INVOICE_LIST_IMPORT } from '@/store/actions/invoice';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import ModalEdit from '@/components/ui/ModalEdit';
import baseModalMixin from '@/mixins/baseModalMixin';
import InputFile from '@/components/ui/input/InputFile';
import { VALIDATION_ERROR_CODE } from '@/utils/error';

export default {
  mixins: [validationMixin, baseModalMixin],

  components: { ModalEdit, InputFile },

  props: {
    business: Object,
    title: String,
    bank: String,
    isInvoice: {
      type: Boolean,
      default: false,
    },
  },

  validations() {
    if (this.isInvoice) {
      return {
        form: {
          file: {
            required,
          },
          invoiceDate: {
            required,
          },
        },
      };
    }

    return {
      form: {
        file: {
          required,
        },
      },
    };
  },

  data() {
    return {
      form: {
        file: '',
        invoiceDate: '',
      },
      serverErrors: [],
    };
  },

  computed: {
    ...mapGetters(['invoiceImportStatus']),
    isLoading() {
      return this.invoiceImportStatus === 'loading';
    },
  },

  methods: {
    resetForm() {
      this.form = {
        file: '',
        invoiceDate: '',
      };
      this.$v.$reset();
      this.serverErrors = [];
      this.$refs.inputFile.resetField();
    },

    setFile(value) {
      this.$v.form.file.$model = value;
      this.form.file = value;
      this.$v.form.file.$touch();
    },

    async onSubmit() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          this.$toasted.show(this.$t('toast.failedForm'), { type: 'error' });
          return;
        }
      }

      try {
        const formData = new FormData();
        formData.append('file', this.form.file);
        formData.append('bank', this.bank);
        if (this.isInvoice) {
          formData.append('invoice_date', this.form.invoiceDate);
        }
        const payload = {
          businessId: this.business.id,
          isInvoice: this.isInvoice,
          bank: this.bank,
          data: formData,
        };
        await this.$store.dispatch(INVOICE_LIST_IMPORT, payload);

        if (this.invoiceImportStatus === 'success') {
          this.$toasted.show(this.$t('toast.success'), { type: 'success' });
          this.hideEditModal();
          this.resetForm();
          this.$emit('onImportSuccess');
        }
      } catch (error) {
        if (error.response.status === VALIDATION_ERROR_CODE) {
          this.serverErrors = error.response.data.errors;
        }
        this.$toasted.show(error.message, { type: 'error' });
      }
    },
  },
};
</script>
