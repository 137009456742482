import axios from 'axios';
import humps from 'humps';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  transformRequest: [data => humps.decamelizeKeys(data), ...axios.defaults.transformRequest],

  transformResponse: [...axios.defaults.transformResponse, data => humps.camelizeKeys(data)],
});

export default api;
