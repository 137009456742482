import {
  SMS_BALANCE_LIST_REQUEST,
  SMS_BALANCE_LIST_REQUEST_SUCCESS,
  SMS_BALANCE_LIST_REQUEST_ERROR,
  SMS_BALANCE_REQUEST,
  SMS_BALANCE_REQUEST_SUCCESS,
  SMS_BALANCE_REQUEST_ERROR,
} from '@/store/actions/smsBalance';
import { handleRequestError } from '@/utils/error';

import Repository, { SMS_BALANCES } from '@/repositories/RepositoryFactory';

const SmsBalancesRepository = Repository.get(SMS_BALANCES);

const state = {
  smsBalance: {},
  smsBalanceStatus: '',
  smsBalanceList: [],
  smsBalanceListMeta: '',
  smsBalanceListStatus: '',
};

const getters = {
  smsBalance: state => state.smsBalance,
  smsBalanceStatus: state => state.smsBalanceStatus,
  smsBalanceList: state => state.smsBalanceList,
  smsBalanceListMeta: state => state.smsBalanceListMeta,
  smsBalanceListStatus: state => state.smsBalanceListStatus,
};

const actions = {
  [SMS_BALANCE_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(SMS_BALANCE_LIST_REQUEST);
    try {
      const response = await SmsBalancesRepository.get(payload.data);
      commit(SMS_BALANCE_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(SMS_BALANCE_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [SMS_BALANCE_REQUEST]: async ({ commit }, payload) => {
    commit(SMS_BALANCE_REQUEST);
    try {
      const response = await SmsBalancesRepository.create(payload);
      commit(SMS_BALANCE_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(SMS_BALANCE_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
};

const mutations = {
  [SMS_BALANCE_LIST_REQUEST]: state => {
    state.smsBalanceListStatus = 'loading';
  },
  [SMS_BALANCE_LIST_REQUEST_SUCCESS]: (state, payload) => {
    console.log(payload);
    state.smsBalanceListStatus = 'success';
    state.smsBalanceList = payload.data;
    if (payload.meta) {
      state.smsBalanceListMeta = payload.meta;
    }
  },
  [SMS_BALANCE_LIST_REQUEST_ERROR]: state => {
    state.smsBalanceListStatus = 'error';
  },
  [SMS_BALANCE_REQUEST]: state => {
    state.smsBalanceStatus = 'loading';
  },
  [SMS_BALANCE_REQUEST_SUCCESS]: (state, payload) => {
    state.smsBalanceStatus = 'success';
    state.smsBalance = payload.data;
  },
  [SMS_BALANCE_REQUEST_ERROR]: state => {
    state.smsBalanceStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
