import Vue from 'vue';
import VueRouter from 'vue-router';

import { USER_TOKEN_KEY } from '@/config';
import api from '@/utils/api';

import { ifAuthenticated, ifNotAuthenticated } from '@/router/helpers';
import Main from '@/views/Main';
import AuthLogin from '@/views/auth/AuthLogin';
import AuthRegister from '@/views/auth/AuthRegister';
import AuthForgotPassword from '@/views/auth/AuthForgotPassword';
import AuthResetPassword from '@/views/auth/ResetPassword';
import MeetingStart from '@/views/Meeting/MeetingStart';
import BusinessSetup from '@/views/BusinessSetup';
import Dashboard from '@/views/Dashboard';
import buildingRoutes from '@/router/building';
import financeRoutes from '@/router/finance';
import reportRoutes from '@/router/report';
import ticketRoutes from '@/router/ticket';
import businessRoutes from '@/router/business';
import ownerRoutes from '@/router/ownerRequest';
import user from '@/router/user';
import service from '@/router/service';
import order from '@/router/order';
import forums from '@/router/forums';
import businesses from '@/router/businesses';
import banners from '@/router/banners';
import activityReports from '@/router/activityReports';
import announcements from '@/router/announcements';
import polls from '@/router/polls';
import businessapprove from '@/router/businessapprove';
import reference from '@/router/reference';
import sms from '@/router/sms';
import smsTemplates from '@/router/smsTemplates';
import smsBalances from '@/router/smsBalances';
import meeting from '@/router/meeting';
import appVersions from '@/router/appVersions';

Vue.use(VueRouter);
// api.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     const { status } = error.response;
//     if (status === 401) {
//       store.dispatch(AUTH_REFRESH_TOKEN).then(() => {
//         window.location.reload();
//       });
//     }
//     return Promise.reject(error);
//   }
// );

const userToken = window.$cookies.get(USER_TOKEN_KEY);
if (userToken) {
  api.defaults.headers.common.Authorization = `Bearer ${userToken}`;
} else {
  delete api.defaults.headers.common.Authorization;
}

const routes = [
  { path: '/login', name: 'UserLogin', component: AuthLogin, beforeEnter: ifNotAuthenticated },
  {
    path: '/register',
    name: 'UserRegister',
    component: AuthRegister,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/forgot-password',
    name: 'UserForgotPassword',
    component: AuthForgotPassword,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/reset-password',
    name: 'UserResetPassword',
    component: AuthResetPassword,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/',
    component: Main,
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
        meta: { group: 'statistic' },
      },
      ...buildingRoutes,
      ...financeRoutes,
      ...reportRoutes,
      ...ticketRoutes,
      ...businessRoutes,
      ...ownerRoutes,
      ...user,
      ...service,
      ...order,
      ...forums,
      ...businesses,
      ...banners,
      ...activityReports,
      ...announcements,
      ...polls,
      ...businessapprove,
      ...reference,
      ...sms,
      ...smsTemplates,
      ...smsBalances,
      ...meeting,
      ...appVersions,
      {
        path: '/business-setup',
        name: 'BusinessSetup',
        component: BusinessSetup,
      },
    ],
  },
  {
    path: '/meeting/:businessId/start/:id',
    name: 'MeetingStart',
    component: MeetingStart,
    meta: { group: 'meeting' },
    // beforeEnter: ifAuthenticated,
  },
];

const router = new VueRouter({ mode: 'history', base: process.env.BASE_URL, routes });

export default router;
