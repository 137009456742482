export const OWNER_LIST_REQUEST = 'OWNER_LIST_REQUEST';
export const OWNER_LIST_REQUEST_SUCCESS = 'OWNER_LIST_REQUEST_SUCCESS';
export const OWNER_LIST_REQUEST_ERROR = 'OWNER_LIST_REQUEST_ERROR';

export const OWNER_SEARCH = 'OWNER_SEARCH';
export const OWNER_SEARCH_SUCCESS = 'OWNER_SEARCH_SUCCESS';
export const OWNER_SEARCH_ERROR = 'OWNER_SEARCH_ERROR';
export const OWNER_SEARCH_LIST_CLEAR = 'OWNER_SEARCH_LIST_CLEAR';

export const OWNER_ADD = 'OWNER_ADD';
export const OWNER_ADD_SUCCESS = 'OWNER_ADD_SUCCESS';
export const OWNER_ADD_ERROR = 'OWNER_ADD_ERROR';

export const OWNER_UPDATE = 'OWNER_UPDATE';
export const OWNER_UPDATE_SUCCESS = 'OWNER_UPDATE_SUCCESS';
export const OWNER_UPDATE_ERROR = 'OWNER_UPDATE_ERROR';

export const OWNER_REMOVE = 'OWNER_REMOVE';
export const OWNER_REMOVE_SUCCESS = 'OWNER_REMOVE_SUCCESS';
export const OWNER_REMOVE_ERROR = 'OWNER_REMOVE_ERROR';

export const OWNER_REQUEST = 'OWNER_REQUEST';
export const OWNER_REQUEST_SUCCESS = 'OWNER_REQUEST_SUCCESS';
export const OWNER_REQUEST_ERROR = 'OWNER_REQUEST_ERROR';

export const OWNER_LIST_IMPORT = 'OWNER_LIST_IMPORT';
export const OWNER_LIST_IMPORT_SUCCESS = 'OWNER_LIST_IMPORT_SUCCESS';
export const OWNER_LIST_IMPORT_ERROR = 'OWNER_LIST_IMPORT_ERROR';

export const OWNER_LIST_EXPORT = 'OWNER_LIST_EXPORT';
export const OWNER_LIST_EXPORT_SUCCESS = 'OWNER_LIST_EXPORT_SUCCESS';
export const OWNER_LIST_EXPORT_ERROR = 'OWNER_LIST_EXPORT_ERROR';

export const OWNER_REQUEST_LIST = 'OWNER_REQUEST_LIST';
export const OWNER_REQUEST_LIST_SUCCESS = 'OWNER_REQUEST_LIST_SUCCESS';
export const OWNER_REQUEST_LIST_ERROR = 'OWNER_REQUEST_LIST_ERROR';
export const OWNER_APPROVE_CONFIRM = 'OWNER_APPROVE_CONFIRM';
export const OWNER_APPROVE_CONFIRM_SUCCESS = 'OWNER_APPROVE_CONFIRM_SUCCESS';
export const OWNER_APPROVE_CONFIRM_ERROR = 'OWNER_APPROVE_CONFIRM_ERROR';

export const OWNER_REQUEST_REMOVE = 'OWNER_REQUEST_REMOVE';
export const OWNER_REQUEST_REMOVE_SUCCESS = 'OWNER_REQUEST_REMOVE_SUCCESS';
export const OWNER_REQUEST_REMOVE_ERROR = 'OWNER_REQUEST_REMOVE_ERROR';
