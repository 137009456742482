export const RECEIVABLE_LIST_REQUEST = 'RECEIVABLE_LIST_REQUEST';
export const RECEIVABLE_LIST_REQUEST_SUCCESS = 'RECEIVABLE_LIST_REQUEST_SUCCESS';
export const RECEIVABLE_LIST_REQUEST_ERROR = 'RECEIVABLE_LIST_REQUEST_ERROR';

export const RECEIVABLE_LIST_EXPORT = 'RECEIVABLE_LIST_EXPORT';
export const RECEIVABLE_LIST_EXPORT_SUCCESS = 'RECEIVABLE_LIST_EXPORT_SUCCESS';
export const RECEIVABLE_LIST_EXPORT_ERROR = 'RECEIVABLE_LIST_EXPORT_ERROR';

export const RECEIVABLES_SMS_SEND = 'RECEIVABLES_SMS_SEND';
export const RECEIVABLES_SMS_SEND_SUCCESS = 'RECEIVABLES_SMS_SEND_SUCCESS';
export const RECEIVABLES_SMS_SEND_ERROR = 'RECEIVABLES_SMS_SEND_ERROR';
