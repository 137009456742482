export const SMS_PACKAGE_LIST_REQUEST = 'SMS_PACKAGE_LIST_REQUEST';
export const SMS_PACKAGE_LIST_REQUEST_SUCCESS = 'SMS_PACKAGE_LIST_REQUEST_SUCCESS';
export const SMS_PACKAGE_LIST_REQUEST_ERROR = 'SMS_PACKAGE_LIST_REQUEST_ERROR';

export const SMS_PACKAGE_SUBSCRIBE = 'SMS_PACKAGE_SUBSCRIBE';
export const SMS_PACKAGE_SUBSCRIBE_SUCCESS = 'SMS_PACKAGE_SUBSCRIBE_SUCCESS';
export const SMS_PACKAGE_SUBSCRIBE_ERROR = 'SMS_PACKAGE_SUBSCRIBE_ERROR';

export const SMS_PACKAGE_INVOICE_LIST_REQUEST = 'SMS_PACKAGE_INVOICE_LIST_REQUEST';
export const SMS_PACKAGE_INVOICE_LIST_REQUEST_SUCCESS = 'SMS_PACKAGE_INVOICE_LIST_REQUEST_SUCCESS';
export const SMS_PACKAGE_INVOICE_LIST_REQUEST_ERROR = 'SMS_PACKAGE_INVOICE_LIST_REQUEST_ERROR';

export const SMS_LOGS_REQUEST = 'SMS_LOGS_REQUEST';
export const SMS_LOGS_REQUEST_SUCCESS = 'SMS_LOGS_REQUEST_SUCCESS';
export const SMS_LOGS_REQUEST_ERROR = 'SMS_LOGS_REQUEST_ERROR';
