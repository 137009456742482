<template>
  <div class="box">
    <div class="px-6 py-3 bg-default rounded-t-lg">
      <h3 class="text-base font-medium text-white">Хурлын илтгэгчид ({{ users.length }})</h3>
    </div>

    <div :class="connectedToRoom ? 'hidden' : 'font-medium italic text-center py-4 px-4'">
      Холбогдож байна...
    </div>

    <div
      :class="
        connectedToRoom
          ? 'grid grid-flow-row xs:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6 auto-rows-max gap-4 p-5'
          : 'hidden'
      "
    >
      <div class="aspect-w-1 aspect-h-1 bg-gray-200 rounded-lg">
        <div class="w-full h-full">
          <div
            id="localMedia"
            class="aspect-w-1 aspect-h-1 bg-gray-600 rounded-lg transition ease-in duration-200"
            :class="_.get(currentSpeaker, 'devices.video', false) ? 'bg-gray-800' : 'bg-gray-600'"
          />
          <div
            v-if="currentSpeaker !== null"
            class="absolute top-0 right-0 z-10 flex items-center gap-2 top-2 right-2 text-red-600"
          >
            <svg
              v-if="currentSpeaker.devices.audio"
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M7 4a3 3 0 016 0v4a3 3 0 11-6 0V4zm4 10.93A7.001 7.001 0 0017 8a1 1 0 10-2 0A5 5 0 015 8a1 1 0 00-2 0 7.001 7.001 0 006 6.93V17H6a1 1 0 100 2h8a1 1 0 100-2h-3v-2.07z"
                clip-rule="evenodd"
              />
            </svg>
            <svg
              v-if="currentSpeaker.devices.video"
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div
            class="absolute left-0 bottom-0 right-0 text-white bg-gradient-to-b from-transparent to-black/50 flex justify-around items-center gap-1 rounded-b-lg rounded-t-none m-0 py-2 px-4"
          >
            <div class="cursor-not-allowed hidden">
              <MonitorIcon />
            </div>

            <div class="cursor-pointer">
              <MicIcon v-if="isAudioStarted" @click="disableAudio" />
              <MicOffIcon v-else @click="enableAudio" />
            </div>

            <div class="cursor-pointer">
              <VideoIcon v-if="isVideoStarted" @click="disableVideo" />
              <VideoOffIcon v-else @click="enableVideo" />
            </div>

            <div class="">
              <MeetingDropDownList
                :id="dropdownId"
                class="p-0 m-0"
                toggler-class="text-gray-700 dark:text-gray-300"
                box-class="w-60"
                content-class="dark:bg-dark-1"
              >
                <div class="flex items-center gap-2">
                  <MicIcon size="20" class="mt-1" />
                  <select
                    v-model="selectedAudioDevice"
                    id="audio_devices"
                    name="location"
                    class="input w-full border block mt-2"
                  >
                    <option :key="idx" v-for="(item, idx) in audioDevices" :value="item.deviceId">
                      {{ item.label || 'Default audio input' }}
                    </option>
                  </select>
                </div>

                <div class="flex items-center gap-2">
                  <CameraIcon size="20" class="mt-1" />
                  <select
                    v-model="selectedVideoDevice"
                    id="video_devices"
                    name="location"
                    class="input w-full border block mt-2"
                  >
                    <option :key="idx" v-for="(item, idx) in videoDevices" :value="item.deviceId">
                      {{ item.label || 'Default video input' }}
                    </option>
                  </select>
                </div>
              </MeetingDropDownList>
            </div>
          </div>
        </div>
      </div>

      <div
        v-for="(speaker, idx) in speakers"
        :key="idx"
        :id="`speaker-${speaker.profile.id}`"
        :data-socket-id="speaker.profile._socket.id"
        class="aspect-w-1 aspect-h-1 rounded-lg transition ease-in duration-200"
        :class="speaker.devices.video ? 'bg-gray-800' : 'bg-gray-600'"
      >
        <div class="w-full h-full">
          <div
            v-if="speaker.devices.audio || speaker.devices.video"
            class="z-10 absolute flex items-center gap-2 top-2 right-2 text-red-600"
          >
            <svg
              v-if="speaker.devices.audio"
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M7 4a3 3 0 016 0v4a3 3 0 11-6 0V4zm4 10.93A7.001 7.001 0 0017 8a1 1 0 10-2 0A5 5 0 015 8a1 1 0 00-2 0 7.001 7.001 0 006 6.93V17H6a1 1 0 100 2h8a1 1 0 100-2h-3v-2.07z"
                clip-rule="evenodd"
              />
            </svg>
            <svg
              v-if="speaker.devices.video"
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div
            v-if="!speaker.devices.video"
            class="absolute flex flex-col items-center gap-2 top-12 left-0 right-0 text-white"
          >
            <div
              class="flex items-center justify-center font-light text-center bg-gray-800 text-white text-4xl rounded-full w-14 h-14"
            >
              {{ _.get(speaker, 'profile_new.apartments[0].number', '0') }}
            </div>
            <div class="text-xl">
              {{ _.get(speaker, 'profile.first_name', '-') }}
            </div>
          </div>
          <div
            class="z-10 px-2 py-2 transition ease-in duration-200 text-white absolute left-0 right-0 bottom-0 flex justify-between rounded-b-lg"
            :class="speaker.devices.video ? 'bg-gradient-to-b from-transparent to-black/50' : ''"
          >
            <div class="flex flex-row gap-1 items-center text-sm">
              <MapPinIcon size="18" />
              {{ _.get(speaker, 'profile_new.apartments[0].building.name', '-') }}
            </div>

            <div class="flex flex-row gap-1 items-center text-sm">
              <HomeIcon size="18" />
              {{ _.get(speaker, 'profile_new.apartments[0].number', '-') }}
            </div>

            <div class="flex flex-row gap-1 items-center text-sm">
              <PhoneIcon size="18" />
              {{ _.get(speaker, 'profile_new.phone', '-') }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <MeetingPolls v-if="connectedToRoom" :client="roomClient" :meeting="meeting" />
  </div>
</template>
<script>
import MeetingDropDownList from '@/components/meeting/video/MeetingDropDownList';
import { mapGetters } from 'vuex';
import { USER_REQUEST } from '@/store/actions/user';
import AuthTokenStorageService from '@/services/AuthTokenStorageService';
import MeetingPolls from '@/components/meeting/MeetingPolls';
import RoomClient, { mediaType } from '../../../lib/room_client';

export default {
  components: { MeetingPolls, MeetingDropDownList },
  props: {
    meeting: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    this.readyToConnect = false;
    Promise.all([
      this.fetchMe(),
      this.injectScript(`${process.env.VUE_APP_MEETING_HOST}/socket.io/socket.io.js`),
      this.injectScript(`${process.env.VUE_APP_MEETING_HOST}/modules/mediasoupclient.min.js`),
    ]).then(() => {
      this.readyToConnect = true;
      this.startRoom();
    });
  },
  destroyed() {
    [
      `${process.env.VUE_APP_MEETING_HOST}/socket.io/socket.io.js`,
      `${process.env.VUE_APP_MEETING_HOST}/modules/mediasoupclient.min.js`,
    ].forEach(e => document.getElementById(e).remove());
  },
  emits: ['users'],
  data() {
    return {
      readyToConnect: false,

      roomName: '',
      roomClient: null,
      connectingToRoom: false,
      connectedToRoom: false,
      io: null,
      producer: null,
      audioDevices: [],
      videoDevices: [],

      selectedAudioDevice: null,
      selectedVideoDevice: null,

      isVideoStarted: false,
      isAudioStarted: false,
      isScreenStarted: false,

      users: [],
      usersData: [],
      dropdownId: 'invoice-dropdown',
      videoText: false,
    };
  },
  computed: {
    ...mapGetters(['user', 'me']),
    speakers() {
      return this.users.filter(e => {
        // return e.is_speaker_new || false;
        return (e.is_speaker || false) && e.profile.id !== this.me.id;
      });
    },
    currentSpeaker() {
      const arr = this.users.filter(e => e.profile.id === this.me.id);
      if (arr.length <= 0) return null;
      return arr[0];
    },
  },
  methods: {
    async fetchMe() {
      return this.$store.dispatch(USER_REQUEST);
    },

    startRoom() {
      this.roomName = this.meeting.id;
      if (this.roomName.length <= 0) return;

      const localMedia = document.getElementById('localMedia');
      const remoteVideos = document.getElementById('remoteVideos');
      const remoteAudios = document.getElementById('remoteAudios');

      this.joinRoom(this.roomName, localMedia, remoteVideos, remoteAudios);
    },
    initClient() {
      if (this.io == null) {
        this.io = window.io(process.env.VUE_APP_MEETING_HOST, {
          withCredentials: true,
          query: {
            token: AuthTokenStorageService.getAccessToken(),
          },
        });
      }

      if (!this.io.request) {
        this.io.request = (type, data = {}) => {
          return new Promise((resolve, reject) => {
            this.io.emit(type, data, e => {
              if (e.error) reject(e.error);
              else resolve(e);
            });
          });
        };
      }

      navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(() => {
        navigator.mediaDevices.enumerateDevices().then(devices => {
          this.audioDevices = devices.filter(e => e.kind === 'audioinput');
          this.videoDevices = devices.filter(e => e.kind === 'videoinput');

          this.selectedAudioDevice = this.audioDevices[0].deviceId ?? null;
          this.selectedVideoDevice = this.videoDevices[0].deviceId ?? null;
        });
      });
    },
    joinRoom(roomId, localMedia, remoteVideos, remoteAudios) {
      // eslint-disable-next-line no-throw-literal
      if (!roomId) throw 'room id is expected';

      this.initClient();
      const rc = this.roomClient;
      if (rc && rc.isOpen()) {
        // eslint-disable-next-line no-throw-literal
        throw 'already connected to a room';
      }

      this.connectingToRoom = true;
      this.connectedToRoom = false;
      this.roomClient = new RoomClient(
        localMedia,
        remoteVideos,
        remoteAudios,
        window.mediasoupClient,
        this.io,
        roomId,
        'user-name'
      );

      this.$emit('client', this.roomClient);

      this.configureEvents();

      this.roomClient
        .createRoom(roomId)
        .then(() => this.roomClient.join('user-name', roomId))
        .then(() => {
          this.roomClient.initSockets();

          this.connectingToRoom = false;
          this.connectedToRoom = true;
        });
    },
    configureEvents() {
      const rc = this.roomClient;
      rc.on(RoomClient.EVENTS.startScreen, () => {
        this.isScreenStarted = true;
      });
      rc.on(RoomClient.EVENTS.stopScreen, () => {
        this.isScreenStarted = false;
      });
      rc.on(RoomClient.EVENTS.startAudio, () => {
        this.isAudioStarted = true;
      });
      rc.on(RoomClient.EVENTS.stopAudio, () => {
        this.isAudioStarted = false;
      });
      rc.on(RoomClient.EVENTS.startVideo, () => {
        this.isVideoStarted = true;
      });
      rc.on(RoomClient.EVENTS.stopVideo, () => {
        this.isVideoStarted = false;
      });
      rc.on(RoomClient.EVENTS.exitRoom, () => {
        // hide(control)
        // reveal(login)
        // hide(videoMedia)
      });
      rc.on(RoomClient.EVENTS.users, data => {
        this.users = data;
        this.$emit('users-data', data);
      });
      rc.on(RoomClient.EVENTS.usersData, data => {
        this.usersData = data;
        this.$emit('users', data);
      });
    },
    injectScript(path) {
      return new Promise(resolve => {
        const plugin = document.createElement('script');
        plugin.setAttribute('src', path);
        plugin.id = path;
        plugin.onload = () => resolve();
        plugin.async = true;
        document.head.appendChild(plugin);
      });
    },
    enableAudio() {
      this.roomClient.produce(mediaType.audio, this.selectedAudioDevice);
    },
    enableVideo() {
      this.videoText = true;
      this.roomClient.produce(mediaType.video, this.selectedVideoDevice);
    },
    disableAudio() {
      this.roomClient.closeProducer(mediaType.audio);
    },
    disableVideo() {
      this.roomClient.closeProducer(mediaType.video);
    },
  },
};
</script>

<style>
.bg-default {
  background: #1c3faa;
}

.text-xs-new {
  font-size: 0.65rem;
}

#localMedia {
  position: relative;
}

/*.owner-info {*/
/*  position: absolute;*/
/*  bottom: 10px;*/
/*  left: 10px;*/
/*  background: #ffffffab;*/
/*  padding: 5px 15px;*/
/*  font-weight: 800;*/
/*  border-radius: 10px;*/
/*}*/
.meeting-video-options {
  margin-top: 1.5rem;
  background: #1c3faa;
  color: #fff;
  padding: 0.5rem 0.5rem;
  border-radius: 10px;
}

.meeting-video-audio {
  width: 100%;
  height: 100%;
  padding: 8px 0;
}

.meeting-video-audio svg {
  margin: auto;
}

.meeting-video-video {
  width: 100%;
  height: 100%;
  padding: 8px 0;
}

.meeting-video-video svg {
  margin: auto;
}

.meeting-video-more {
  width: 100%;
  height: 100%;
}

.meeting-video-more svg {
  margin: auto;
}

.to-black\/50 {
  --tw-gradient-to: rgba(0, 0, 0, 0.5);
}
</style>
