import Vue from 'vue';
import { FILE_UPLOAD, FILE_UPLOAD_ERROR, FILE_UPLOAD_SUCCESS } from '@/store/actions/file';
import { handleRequestError } from '@/utils/error';
import ClientFile from '@/clients/fileClient';

const state = {
  fileUpload: {},
};

const getters = {
  getFileUpload: state => state.fileUpload,
};

const actions = {
  [FILE_UPLOAD]: async ({ commit }, file) => {
    commit(FILE_UPLOAD);
    try {
      const formData = new FormData();
      formData.append('file', file);
      const response = await ClientFile.post('galleries', formData);
      commit(FILE_UPLOAD_SUCCESS, response.data.data);
    } catch (error) {
      commit(FILE_UPLOAD_ERROR);
      handleRequestError(error);
    }
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  [FILE_UPLOAD]: state => {
    Vue.set(state, 'fileUpload', {
      status: 'loading',
    });
  },
  [FILE_UPLOAD_SUCCESS]: (state, data) => {
    Vue.set(state, 'fileUpload', {
      status: 'success',
      item: data,
    });
  },
  [FILE_UPLOAD_ERROR]: state => {
    Vue.set(state, 'fileUpload', {
      status: 'error',
      item: null,
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
