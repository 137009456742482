import { findIndex } from 'lodash';
import {
  MEETING_LIST_REQUEST,
  MEETING_LIST_REQUEST_ERROR,
  MEETING_LIST_REQUEST_SUCCESS,
  MEETING_ADD,
  MEETING_ADD_ERROR,
  MEETING_ADD_SUCCESS,
  MEETING_REQUEST,
  MEETING_REQUEST_SUCCESS,
  MEETING_REQUEST_ERROR,
  MEETING_REMOVE,
  MEETING_REMOVE_ERROR,
  MEETING_REMOVE_SUCCESS,
  MEETING_UPDATE,
  MEETING_UPDATE_ERROR,
  MEETING_UPDATE_SUCCESS,
} from '@/store/actions/meeting';
import { handleRequestError } from '@/utils/error';

import Repository, { MEETING } from '@/repositories/RepositoryFactory';

const MeetingRepository = Repository.get(MEETING);

const state = {
  meeting: '',
  meetingList: [],
  meetingLastCreated: '',
  meetingMeta: '',
  meetingStatus: '',
};

const getters = {
  meeting: state => state.meeting,
  meetingList: state => state.meetingList,
  meetingLastCreated: state => state.meetingLastCreated,
  meetingMeta: state => state.meetingMeta,
  meetingStatus: state => state.meetingStatus,
};

const actions = {
  [MEETING_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(MEETING_LIST_REQUEST);
    try {
      const response = await MeetingRepository.get(payload.businessId, payload.data);
      commit(MEETING_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(MEETING_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },

  [MEETING_REQUEST]: async ({ commit }, payload) => {
    commit(MEETING_REQUEST);
    try {
      const response = await MeetingRepository.show(payload.businessId, payload.id);
      const { data } = response;
      commit(MEETING_REQUEST_SUCCESS, data);
    } catch (error) {
      commit(MEETING_REQUEST_ERROR);
      handleRequestError(error);
    }
  },

  [MEETING_ADD]: async ({ commit }, payload) => {
    commit(MEETING_ADD);
    try {
      const response = await MeetingRepository.create(payload.businessId, payload.data);
      commit(MEETING_ADD_SUCCESS, response.data.data);
    } catch (error) {
      commit(MEETING_ADD_ERROR);
      handleRequestError(error);
    }
  },

  [MEETING_UPDATE]: async ({ commit }, payload) => {
    commit(MEETING_UPDATE);
    try {
      const response = await MeetingRepository.update(payload.businessId, payload.id, payload.data);
      commit(MEETING_UPDATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(MEETING_UPDATE_ERROR);
      handleRequestError(error);
    }
  },

  [MEETING_REMOVE]: async ({ commit }, payload) => {
    commit(MEETING_REMOVE);
    try {
      await MeetingRepository.delete(payload.businessId, payload.id);
      commit(MEETING_REMOVE_SUCCESS, payload.id);
    } catch (error) {
      commit(MEETING_REMOVE_ERROR);
      handleRequestError(error);
    }
  },
};

const mutations = {
  [MEETING_LIST_REQUEST]: state => {
    state.meetingStatus = 'loading';
  },
  [MEETING_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.meetingStatus = 'success';
    state.meetingList = payload.data;
    state.meetingMeta = payload.meta;
  },
  [MEETING_LIST_REQUEST_ERROR]: state => {
    state.meetingStatus = 'error';
  },

  [MEETING_ADD]: state => {
    state.meetingStatus = 'loading';
    state.meetingLastCreated = '';
  },
  [MEETING_ADD_SUCCESS]: (state, payload) => {
    state.meetingStatus = 'success';
    state.meetingLastCreated = payload;
    state.meetingList.push(payload);
  },
  [MEETING_ADD_ERROR]: state => {
    state.meetingStatus = 'error';
  },

  [MEETING_REQUEST]: state => {
    state.meetingStatus = 'loading';
  },
  [MEETING_REQUEST_SUCCESS]: (state, payload) => {
    state.meetingStatus = 'success';
    state.meeting = payload.data;
  },
  [MEETING_REQUEST_ERROR]: state => {
    state.meetingStatus = 'error';
  },

  [MEETING_UPDATE]: state => {
    state.meetingStatus = 'loading';
  },
  [MEETING_UPDATE_SUCCESS]: (state, payload) => {
    state.meetingStatus = 'success';
    const index = findIndex(state.meetingList, { id: payload.id });
    state.meetingList[index] = payload;
  },
  [MEETING_UPDATE_ERROR]: state => {
    state.meetingStatus = 'error';
  },

  [MEETING_REMOVE]: state => {
    state.meetingStatus = 'deleting';
  },

  [MEETING_REMOVE_SUCCESS]: (state, payload) => {
    const index = findIndex(state.meetingList, { id: payload });
    if (index > -1) {
      state.meetingList.splice(index, 1);
    }
  },

  [MEETING_REMOVE_ERROR]: state => {
    state.meetingStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
