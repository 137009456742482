import { formatMoney } from 'accounting-js';
import moment from 'moment';

export default function initializeFilters(Vue) {
  Vue.filter('currency', money => formatMoney(money / 100, { symbol: '₮', format: '%v%s' }, 2));

  Vue.filter('currencyNormal', money => formatMoney(money, { symbol: '₮', format: '%v%s' }, 2));

  Vue.filter('textSubstr', (text, length, suffix) => {
    let b = '';
    if (text.length > length) {
      b = text.substring(0, length) + suffix;
    } else {
      b = text;
    }
    return b;
  });

  Vue.filter('duration', service => {
    let duration = service.durationHours ? `${service.durationHours}h ` : '';
    duration += `${service.durationMinutes}min`;
    return duration;
  });

  Vue.filter('momentDate', (date, format) => moment(date).format(format));

  Vue.filter('dateAndTimeToLocal', (date, format) =>
    moment(date)
      .add(8, 'hours')
      .format(format)
  );

  Vue.filter('fullName', user => {
    return user
      ? `${user.lastName ? `${user.lastName.charAt(0).toUpperCase()}.` : ''}${
          user.firstName
            ? user.firstName.charAt(0).toUpperCase() + user.firstName.slice(1)
            : user.name
        }`
      : '';
  });

  Vue.filter('lastAndFirstName', user => {
    return user
      ? `${user.lastName ? user.lastName.charAt(0).toUpperCase() + user.lastName.slice(1) : ''} ${
          user.firstName ? user.firstName.toUpperCase() : 'no name'
        }`
      : '';
  });

  Vue.filter('square', square => {
    return `${square}м.кв`;
  });

  Vue.filter('minuteToDuration', minutes => {
    return Vue.moment
      .utc()
      .startOf('day')
      .add({ minutes })
      .format('H[h] mm[m]');
  });
}
