<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <PageHeader class="text-lg font-medium mr-auto" :title="title" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <Button
          type="button"
          :to="{ name: 'Meeting' }"
          class="box text-gray-700 dark:text-gray-300 mr-2 flex"
        >
          {{ $t('text.back') }}
        </Button>
        <Button
          type="button"
          @click="onSubmit"
          :loading="isLoading"
          class="w-24 bg-theme-1 text-white"
        >
          {{ $t('text.save') }}
        </Button>
      </div>
    </div>
    <div class="intro-y mt-5">
      <form @submit.prevent="onSubmit">
        <div class="grid grid-cols-12 gap-6">
          <div class="intro-y col-span-12 sm:col-span-12 lg:col-span-5 xl:col-span-5">
            <div class="intro-y box">
              <div class="p-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class="font-medium text-base">{{ $t('meeting.meetingInfo') }}</h2>
              </div>
              <div class="py-4 px-5 relative">
                <div class="grid grid-cols-12">
                  <div class="intro-y col-span-12 sm:col-span-12">
                    <InputString
                      :label="$t('meeting.meetingName')"
                      v-model.trim="$v.form.name.$model"
                      :vuelidate="$v.form.name"
                      input-class="input-meeting-name"
                    />
                    <InputString
                      type="date"
                      :label="$t('meeting.meetingDate')"
                      v-model.trim="$v.form.scheduledAt.$model"
                      :vuelidate="$v.form.scheduledAt"
                      input-class="input-meeting-date"
                    />
                    <InputString
                      type="time"
                      :label="$t('meeting.meetingTime')"
                      v-model.trim="$v.form.scheduledAtTime.$model"
                      :vuelidate="$v.form.scheduledAtTime"
                      input-class="input-meeting-time"
                    />
                    <!-- <InputString
                      type="number"
                      :label="$t('meeting.meetingDuration')"
                      v-model="selectedDuration"
                    /> -->
                    <InputSelect
                      :label="$t('meeting.meetingDuration')"
                      v-model="selectedDuration"
                      :options="meetTime"
                      value-key="id"
                      input-class="input-meeting-duration"
                    />
                    <InputString
                      :label="$t('text.respondent')"
                      :value="me.firstName"
                      disabled="disabled"
                      input-class="input-meeting-respondent"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="box mt-5">
              <div class="intro-y p-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class="font-medium text-base">{{ $t('text.buildings') }}</h2>
              </div>
              <div class="intro-y py-4 px-5">
                <Loading v-if="isLoadingBuildings" />
                <template v-else>
                  <label
                    class="py-4 px-5 border-b border-gray-200 dark:border-dark-5 flex items-center cursor-pointer hover:bg-gray-100 dark:hover:bg-dark-2"
                  >
                    <div class="mr-3">
                      <input
                        class="input-meeting-select-all-building input border border-gray-500"
                        @change="toggleSelectAllBuilding($event.target.checked)"
                        type="checkbox"
                      />
                    </div>
                    {{ $t('text.selectAll') }}
                  </label>
                  <template v-for="(building, index) in buildingList">
                    <label
                      :key="index"
                      class="py-4 px-5 border-b border-gray-200 dark:border-dark-5 flex items-center cursor-pointer hover:bg-gray-100 dark:hover:bg-dark-2"
                    >
                      <div class="mr-3">
                        <input
                          class="input-meeting-toggler-building input border border-gray-500"
                          :checked="checkItemInArray(building, selectedBuildings)"
                          @change="toggleItem(building, selectedBuildings)"
                          type="checkbox"
                        />
                      </div>
                      <BuildingFullname
                        :item="building"
                        class="lg:ml-3 mt-3 lg:mt-0 overflow-hidden"
                      />
                    </label>
                  </template>
                </template>
              </div>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-12 lg:col-span-5 xl:col-span-5">
            <div class="intro-y box">
              <div class="p-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class="font-medium text-base">{{ $t('meeting.meetingPollsTitle') }}</h2>
              </div>
              <Loading v-if="isLoadingPolls" />
              <template v-else>
                <label
                  class="py-4 px-5 border-b border-gray-200 dark:border-dark-5 flex items-center cursor-pointer hover:bg-gray-100 dark:hover:bg-dark-2"
                >
                  <div class="mr-3">
                    <input
                      class="input-meeting-select-all-poll input border border-gray-500"
                      @change="toggleSelectAllPoll($event.target.checked)"
                      type="checkbox"
                    />
                  </div>
                  <b>{{ $t('text.selectAll') }}</b>
                </label>
                <template v-for="(poll, index) in pollList">
                  <label
                    :key="index"
                    class="py-4 px-5 border-b border-gray-200 dark:border-dark-5 flex items-center cursor-pointer hover:bg-gray-100 dark:hover:bg-dark-2"
                  >
                    <div class="mr-3">
                      <input
                        class="input-meeting-toggler-poll input border border-gray-500"
                        :checked="checkItemInArray(poll, selectedPolls)"
                        @change="toggleItemPoll(poll, selectedPolls)"
                        type="checkbox"
                      />
                    </div>
                    <p>{{ poll.title }}</p>
                  </label>
                </template>
              </template>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { assign } from 'lodash';
import { mapGetters } from 'vuex';
import { MEETING_REQUEST, MEETING_ADD, MEETING_UPDATE } from '@/store/actions/meeting';
import { APARTMENT_LIST_REQUEST } from '@/store/actions/apartment';
import { BUILDING_LIST_REQUEST } from '@/store/actions/building';
import { POLLS_LIST_REQUEST } from '@/store/actions/polls';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import Button from '@/components/ui/button/Button';
import PageHeader from '@/components/ui/PageHeader';
import InputString from '@/components/ui/input/InputString';
import InputSelect from '@/components/ui/input/InputSelect';
import BuildingFullname from '@/components/building/BuildingFullname';
import moment from 'moment';

export default {
  mixins: [validationMixin],

  components: {
    Button,
    PageHeader,
    InputString,
    InputSelect,
    BuildingFullname,
  },

  props: {
    business: Object,
  },

  validations: {
    form: {
      name: {
        required,
      },
      scheduledAt: {
        required,
      },
      scheduledAtTime: {
        required,
      },
    },
    selectedBuildings: {
      required,
    },
    selectedPolls: {
      required,
    },
  },

  data() {
    return {
      form: {
        name: '',
        scheduledAt: '',
        scheduledAtTime: '',
        duration: '',
        buildings: [],
        pollId: '',
        polls: [],
      },
      selectedDuration: '',
      selectedBuildings: [],
      selectedPolls: [],
      defaultTableData: {
        filters: {},
        sort: {
          field: 'id',
          type: 'desc',
        },
        page: 1,
        perPage: 200,
      },
      meetTime: [
        {
          id: 30,
          name: '30 минут',
        },
        {
          id: 60,
          name: '1 цаг',
        },
        {
          id: 90,
          name: '1 цаг 30 минут',
        },
        {
          id: 120,
          name: '2 цаг',
        },
        {
          id: 150,
          name: '2 цаг 30 минут',
        },
        {
          id: 180,
          name: '3 цаг',
        },
        {
          id: 210,
          name: '3 цаг 30 минут',
        },
      ],
    };
  },

  computed: {
    ...mapGetters([
      'me',
      'buildingStatus',
      'buildingList',
      'businessStatus',
      'businessList',
      'pollList',
      'pollStatus',
      'meeting',
      'meetingStatus',
    ]),
    isLoading() {
      return this.meetingStatus === 'loading';
    },
    isLoadingBuildings() {
      return this.buildingStatus === 'loading';
    },
    isLoadingPolls() {
      return this.pollStatus === 'loading';
    },
    title() {
      return this.$route.name === 'MeetingCreate'
        ? this.$t(`meeting.create`)
        : this.$t(`meeting.update`);
    },
  },

  async created() {
    this.fetchData();
    this.form.assignedTo = this.me.id;
  },
  methods: {
    async fetchData() {
      if (this.$route.params.id) {
        const payload = { id: this.$route.params.id, businessId: this.business.id };
        await this.$store.dispatch(MEETING_REQUEST, payload);
        if (this.meeting) {
          const { name, scheduledAt, duration, buildings, polls } = this.meeting;
          const scheduledAtDate = moment(scheduledAt);

          this.form = {
            name,
            scheduledAt: scheduledAtDate.format('YYYY-MM-DD'),
            scheduledAtTime: scheduledAtDate.format('HH:mm:ss'),
            duration,
            polls,
            buildings,
          };
          this.selectedDuration = duration;
          this.selectedBuildings = [...buildings];
          this.selectedPolls = [...polls];
        }
      }

      await this.fetchBuildingList();
      await this.fetchPollList();
    },

    async fetchBuildingList() {
      const payload = {
        businessId: this.business.id,
        data: this.defaultTableData,
      };

      await this.$store.dispatch(BUILDING_LIST_REQUEST, payload);
    },

    async fetchPollList() {
      const payload = {
        businessId: this.business.id,
        data: this.defaultTableData,
      };
      await this.$store.dispatch(POLLS_LIST_REQUEST, payload);
    },

    async getApartmentList(building) {
      const data = this.defaultTableData;
      data.filters['filter[buildingId]'] = building.id;
      data.filters['filter[isParking]'] = 0;

      const payload = {
        businessId: this.form.businessId,
        data,
      };

      await this.$store.dispatch(APARTMENT_LIST_REQUEST, payload);
    },

    async onChangeBuilding(building) {
      this.form.apartmentId = null;
      await this.getApartmentList(building);
    },
    checkBuilding(building, selectedBuildings) {
      this.toggleItem(building, selectedBuildings);
    },
    toggleItem(object, array) {
      const index = array.findIndex(e => e.id === object.id);
      if (index > -1) {
        array.splice(index, 1);
      } else {
        array.push(object);
      }
    },

    toggleItemPoll(object, array) {
      const index = array.findIndex(item => item.id === object.id);
      if (index > -1) {
        array.splice(index, 1);
      } else {
        array.push(object);
      }
    },

    checkItemInArray(object, array) {
      const index = array.findIndex(item => item.id === object.id);
      if (index > -1) {
        return true;
      }
      return false;
    },
    toggleSelectAllBuilding(checked) {
      if (checked) {
        this.selectedBuildings = [...this.buildingList];
      } else {
        this.selectedBuildings = [];
      }
    },
    toggleSelectAllPoll(checked) {
      if (checked) {
        this.selectedPolls = [...this.pollList];
      } else {
        this.selectedPolls = [];
      }
    },
    async onSubmit() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          this.$toasted.show(this.$t('toast.failedForm'), { type: 'error' });
          return;
        }
      }

      try {
        this.form.buildingId = this.selectedBuildings.map(item => item.id);
        this.form.pollId = this.selectedPolls.map(item => item.id);
        this.form.buildings = this.form.buildingId;
        this.form.duration = this.selectedDuration;
        this.form.polls = this.form.pollId;
        this.form.scheduledAt = `${this.form.scheduledAt} ${this.form.scheduledAtTime}`;
        const payload = {
          businessId: this.business.id,
          data: this.form,
        };

        if (this.$route.params.id) {
          assign(payload, { id: this.meeting.id });
          await this.$store.dispatch(MEETING_UPDATE, payload);
        } else {
          await this.$store.dispatch(MEETING_ADD, payload);
        }

        if (this.meetingStatus === 'success') {
          this.$router.push({ name: 'Meeting' });
          this.$toasted.show(this.$t('toast.success'), { type: 'success' });
        }
      } catch (error) {
        this.$toasted.show(error.message, { type: 'error' });
      }
    },
  },
};
</script>
