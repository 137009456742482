<template>
  <TableRegular
    :loading="loading"
    :downloading="downloading"
    :rows="rows"
    :columns="columns"
    :sort="sort"
    :page="page"
    :per-page="perPage"
    :page-size="pageSize"
    :total-page="totalPage"
    :line-numbers="lineNumbers"
    @onFilter="$emit('onFilter', $event)"
    @onPageChange="$emit('onPageChange', $event)"
    @onPerPageChange="$emit('onPerPageChange', $event)"
    @onSortChange="$emit('onSortChange', $event)"
    @onDownload="$emit('onDownload')"
  >
    <template slot="table-row" slot-scope="props">
      <p v-if="props.column.field === 'name'" class="font-medium whitespace-nowrap">
        {{ props.row.name }}
      </p>
      <p v-else-if="props.column.field === 'action'" class="flex justify-center items-center">
        <router-link
          v-if="checkPermission('tickettype.tickettype.edit')"
          :to="{ name: 'expenseTypeUpdate', params: { expenseTypeId: props.row.id } }"
          class="flex items-center mr-3"
        >
          <CheckSquareIcon class="w-4 h-4 mr-1" />
          {{ $t('table.edit') }}
        </router-link>
        <a
          href="javascript:"
          class="flex items-center mr-3 text-theme-6"
          v-if="checkPermission('expensetype.expensetype.delete')"
          @click="$emit('delete', props.row.id)"
        >
          <Trash2Icon class="w-4 h-4 mr-1" />
          {{ $t('table.delete') }}
        </a>
      </p>
    </template>
  </TableRegular>
</template>

<script>
import TableRegular from '@/components/ui/table/TableRegular';
import tableMixin from '@/mixins/tableMixin';

export default {
  mixins: [tableMixin],
  components: { TableRegular },
};
</script>
