import permissionMixin from '@/mixins/permissionMixin';

export default {
  mixins: [permissionMixin],

  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
    lineNumbers: {
      type: Boolean,
      default: false,
    },
    loading: Boolean,
    downloading: Boolean,
    sort: Object,
    page: Number,
    perPage: Number,
    pageSize: Array,
    totalPage: Number,
    selectable: Boolean,
    selectedRows: {
      type: Array,
      default: () => [],
    },
    downloadable: {
      type: Boolean,
      default: false,
    },
    customFilters: {
      type: Array,
      default: () => [],
    },
  },
};
