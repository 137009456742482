<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-5">
      <PageHeader class="text-lg font-medium mr-auto" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <router-link
          :to="{ name: 'MeetingCreate' }"
          class="button text-white bg-theme-1 shadow-md mr-2"
        >
          {{ $t('text.create') }}
        </router-link>
        <DropDownButtonList
          v-if="checkPermission('finance.income.upload')"
          :id="dropdownId"
          class="ml-auto sm:ml-0"
          toggler-class="button px-2 box text-gray-700 dark:text-gray-300"
          box-class="w-60"
          content-class="dark:bg-dark-1"
        >
          <a @click="onUpload(true)"><UploadIcon /> {{ $t('file.invoice') }}</a>
          <a @click="onUpload()"><UploadIcon /> {{ $t('file.statementOfStateBank') }}</a>
          <a @click="onCopy()"><CopyIcon /> {{ $t('file.copyInvoice') }}</a>
        </DropDownButtonList>
      </div>
    </div>
    <div class="intro-y mt-5 overflow-auto lg:overflow-visible">
      <MeetingList :meetings="meetingList" :loading="isLoading" :businessId="business.id" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { MEETING_LIST_REQUEST } from '@/store/actions/meeting';
import dataListMixin from '@/mixins/dataListMixin';
import PageHeader from '@/components/ui/PageHeader';
import MeetingList from '@/components/meeting/MeetingList';

export default {
  mixins: [dataListMixin],
  props: {
    business: Object,
  },
  components: {
    PageHeader,
    MeetingList,
  },
  data() {
    return {
      storeActionListRequest: MEETING_LIST_REQUEST,
    };
  },
  computed: {
    ...mapGetters(['meeting', 'meetingList', 'meetingStatus']),
    isLoading() {
      return this.meetingStatus === 'loading';
    },
  },
  async created() {
    this.fetchData();
  },
  methods: {},
};
</script>
