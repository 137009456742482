<template>
  <ModalEdit :id="modalId" :title="$t('nav.businessUpdate')" :loading="isLoading" @click="onSubmit">
    <div class="p-5">
      <InputSelect
        :label="$t('text.businessBankName')"
        v-model.number="form.bankId"
        :options="bankList"
        value-key="id"
        input-class="input-select-bank"
      />
      <InputString
        :label="$t('text.bankAccountName')"
        v-model.trim="form.bank_account_name"
        input-class="input-account-name"
      />
      <InputString
        :label="$t('text.bankAccountNumber')"
        :type="'number'"
        v-model.trim="form.bank_account_number"
        input-class="input-account-number"
      />
    </div>
  </ModalEdit>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  BUSINESS_BANK_CREATE,
  BUSINESS_BANK_UPDATE,
  BUSINESS_BANK_LIST,
} from '@/store/actions/business';
import { validationMixin } from 'vuelidate';
import ModalEdit from '@/components/ui/ModalEdit';
import InputString from '@/components/ui/input/InputString';
import InputSelect from '@/components/ui/input/InputSelect';
import baseModalMixin from '@/mixins/baseModalMixin';

export default {
  mixins: [validationMixin, baseModalMixin],

  components: { ModalEdit, InputString, InputSelect },
  props: {
    bankAccount: Object,
    businessId: Number,
  },
  data() {
    return {
      form: {
        bank_account_name: this.bankAccount.accountName,
        bank_account_number: this.bankAccount.accountNumber,
        bankId: this.bankAccount.bankId,
      },
    };
  },

  computed: {
    ...mapGetters(['bankList', 'businessStatus', 'businessList']),
    isLoading() {
      return this.businessStatus === 'loading';
    },
    title() {
      return this.$t('nav.ownerCreate');
    },
  },
  created() {
    this.getBankList();
  },
  methods: {
    async getBankList() {
      await this.$store.dispatch(BUSINESS_BANK_LIST);
    },
    async onSubmit() {
      try {
        if (this.bankAccount.id == null) {
          const payload = {
            businessId: this.businessId,
            data: this.form,
          };
          await this.$store.dispatch(BUSINESS_BANK_CREATE, payload);
        } else {
          const payload = {
            businessId: this.businessId,
            id: this.bankAccount.id,
            data: this.form,
          };
          await this.$store.dispatch(BUSINESS_BANK_UPDATE, payload);
        }
        if (this.businessStatus === 'success') {
          this.$toasted.show(this.$t('toast.success'), { type: 'success' });
          this.$emit('onBankAdd', this.businessList);
          window.location.reload();
        }
      } catch (error) {
        this.$toasted.show(error.message, { type: 'error' });
      }
    },
    hideEditModal() {
      cash(`#${this.modalId}`).modal('hide');
    },
  },
};
</script>
