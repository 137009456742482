<template>
  <div>
    <div v-if="item.id">
      <table class="wrapper" width="100%" cellpadding="0" cellspacing="0">
        <tr>
          <td style="text-align: center; padding: 10px;">
            {{ $t('invoice.invoiceNumber') }} {{ item.id }}
          </td>
        </tr>
        <tr>
          <td>
            <table width="100%" cellpadding="0" cellspacing="0">
              <tr>
                <td>{{ $t('invoices.userName') }}</td>
                <td style="text-align: right;">
                  {{
                    item.apartment.owners.length > 0
                      ? $options.filters.lastAndFirstName(item.apartment.owners[0])
                      : ''
                  }}
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t('invoice.doorNumber') }}:
                  {{ `${item.apartment.building.name}-${item.apartment.number}` }}
                </td>
                <td style="text-align: right;">
                  {{ $t('invoice.square') }}: {{ item.apartment.square | square }}
                </td>
              </tr>
              <tr>
                <td>{{ $t('invoice.eligibilityPeriod') }}</td>
                <td style="text-align: right;">{{ `${item.startDate} ${item.endDate}` }}</td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td style="padding: 10px;"></td>
        </tr>
        <tr>
          <td style="text-align: center; padding: 10px;">
            {{ $t('invoice.detailsOfUse') }}
          </td>
        </tr>
        <tr>
          <td>
            <table width="100%" cellpadding="0" cellspacing="0">
              <thead>
                <tr>
                  <th style="text-align: left;"></th>
                  <th style="text-align: center;">{{ $t('invoice.size') }}</th>
                  <th style="text-align: right;">{{ $t('invoice.amount') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(service, index) in item.serviceProducts" :key="index">
                  <td style="text-align: left;">
                    {{ service.name }}
                  </td>
                  <td style="text-align: center;">{{ service.quantity }}</td>
                  <td style="text-align: right;">{{ service.total | currency }}</td>
                </tr>
                <tr>
                  <th style="text-align: left; padding: 10px 0;">
                    {{ $t('invoice.totalAmount') }}
                  </th>
                  <th style="text-align: center; padding: 10px 0;"></th>
                  <th style="text-align: right; padding: 10px 0;">
                    {{ totalAmountOfServiceProducts | currency }}
                  </th>
                </tr>
                <tr>
                  <td style="text-align: left;">{{ $t('invoice.discount') }}</td>
                  <td style="text-align: center;"></td>
                  <td style="text-align: right;">-</td>
                </tr>
                <tr>
                  <td style="text-align: left;">{{ $t('invoice.paymentOfThisMonth') }}</td>
                  <td style="text-align: center;"></td>
                  <td style="text-align: right;">{{ item.total | currency }}</td>
                </tr>
                <tr>
                  <td style="text-align: left;">{{ $t('invoice.balance') }}</td>
                  <td style="text-align: center;"></td>
                  <td style="text-align: right;">{{ item.balance | currency }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th style="text-align: left; padding: 10px 0;">
                    {{ $t('invoice.totalPaymentAmount') }}
                  </th>
                  <th style="text-align: center; padding: 10px 0;"></th>
                  <th
                    style="text-align: right; padding: 10px 0;"
                    v-if="item.balance == null ? 0 : item.balance"
                  >
                    {{
                      (parseInt(item.total) - parseInt(item.paidAmount) + parseInt(item.balance))
                        | currency
                    }}
                  </th>
                </tr>
              </tfoot>
            </table>
          </td>
        </tr>
        <tr>
          <td style="padding: 10px;"></td>
        </tr>
        <tr>
          <td style="text-align: center; padding: 10px;">
            {{ $t('invoice.paymentChannels') }}
          </td>
        </tr>
        <tr>
          <td>
            <table width="100%" cellpadding="0" cellspacing="0">
              <tr>
                <td style="text-align: left;">{{ $t('invoice.ailApp') }}</td>
                <td style="text-align: right;">{{ $t('invoice.socialPay') }}</td>
              </tr>
              <tr>
                <td style="text-align: left;">{{ $t('invoice.qPay') }}</td>
                <td style="text-align: right;">{{ $t('invoice.mostMoney') }}</td>
              </tr>
            </table>

            <br /><br />

            <table width="100%" cellpadding="0" cellspacing="0">
              <template v-if="item.business.bankAccounts.length > 0">
                <tr v-for="(account, index) in item.business.bankAccounts" :key="index">
                  <td style="text-align: left;">{{ account.bank.name }}</td>
                  <td style="text-align: right;">{{ account.accountNumber }}</td>
                </tr>
              </template>
              <tr>
                <td style="text-align: left;">
                  {{ $t('invoice.receiver') }}: ..... {{ $t('invoice.SUH') }}
                </td>
                <td style="text-align: right;">{{ $t('invoice.attention') }}</td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
  },

  computed: {
    totalAmountOfServiceProducts() {
      return this.item.serviceProducts.reduce((total, item) => total + item.total, 0);
    },
  },
};
</script>
