<template>
  <AuthLayout>
    <form v-if="isForgotBody" @submit.prevent="onSubmit">
      <h2 class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
        {{ $t('auth.forgotPassword') }}
      </h2>
      <div class="intro-x mt-8">
        <InputString
          type="email"
          :placeholder="$t('auth.email')"
          v-model.trim="$v.form.email.$model"
          :vuelidate="$v.form.email"
          input-class="input-forgot-password-email login__input input--lg"
        />
      </div>
      <div class="intro-x mt-3 xl:mt-5">
        <Button type="submit" class="button--lg w-full text-white bg-theme-1" :loading="isLoading">
          <span>{{ $t('auth.forgotPasswordBtn') }}</span>
        </Button>
      </div>
      <div class="intro-x mt-3 xl:mt-5">
        <Button
          :to="{ name: 'UserLogin' }"
          class="button--lg w-full xl:w-32 text-gray-700 border border-gray-300 dark:border-dark-5 dark:text-gray-300"
        >
          <span>{{ $t('auth.login') }}</span>
        </Button>
      </div>
    </form>
    <h2
      v-if="isForgotSuccess"
      class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
    >
      {{ $t('auth.forgotPasswordSuccessText') }}
    </h2>
  </AuthLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import InputString from '@/components/ui/input/InputString';
import Button from '@/components/ui/button/Button';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import { AUTH_FORGOT_REQUEST } from '@/store/actions/auth';
import AuthLayout from '@/views/auth/AuthLayout';

export default {
  components: { Button, InputString, AuthLayout },

  mixins: [validationMixin],

  validations: {
    form: {
      email: {
        required,
        email,
      },
    },
  },

  data() {
    return {
      loading: false,
      isForgotSuccess: false,
      isForgotBody: true,
      form: {
        email: '',
      },
      error: '',
    };
  },

  computed: {
    ...mapGetters(['authForgotStatus']),
    isLoading() {
      return this.authForgotStatus === 'loading';
    },
  },

  mounted() {},

  methods: {
    async onSubmit() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          this.$toasted.show(this.$t('toast.failedForm'), { type: 'error' });
          return;
        }
      }
      try {
        await this.$store.dispatch(AUTH_FORGOT_REQUEST, this.form);
        this.isForgotBody = false;
        this.isForgotSuccess = true;
        this.$toasted.show(this.$t('toast.successForgorRequest'), { type: 'success' });
      } catch (error) {
        if (error.response.status === 422) {
          this.form = { email: '' };
          this.$v.$reset();
          this.$toasted.show(this.$t('toast.incorrectCredentials'), { type: 'error' });
        }
      }
    },
  },
};
</script>
