import { findIndex } from 'lodash';
import {
  INVOICE_LIST_REQUEST,
  INVOICE_LIST_REQUEST_ERROR,
  INVOICE_LIST_REQUEST_SUCCESS,
  INVOICE_SEARCH,
  INVOICE_SEARCH_ERROR,
  INVOICE_SEARCH_SUCCESS,
  INVOICE_SEARCH_LIST_CLEAR,
  INVOICE_ADD,
  INVOICE_ADD_ERROR,
  INVOICE_ADD_SUCCESS,
  INVOICE_REQUEST,
  INVOICE_REQUEST_SUCCESS,
  INVOICE_REQUEST_ERROR,
  INVOICE_REMOVE,
  INVOICE_REMOVE_ERROR,
  INVOICE_REMOVE_SUCCESS,
  INVOICE_SELECTED_REMOVE,
  INVOICE_SELECTED_REMOVE_ERROR,
  INVOICE_SELECTED_REMOVE_SUCCESS,
  INVOICE_UPDATE,
  INVOICE_UPDATE_ERROR,
  INVOICE_UPDATE_SUCCESS,
  INVOICE_LIST_EXPORT,
  INVOICE_LIST_EXPORT_SUCCESS,
  INVOICE_LIST_EXPORT_ERROR,
  INVOICE_MAKE_PAY,
  INVOICE_MAKE_PAY_ERROR,
  INVOICE_MAKE_PAY_SUCCESS,
  INVOICE_IMPORT,
  INVOICE_LIST_IMPORT,
  INVOICE_LIST_IMPORT_SUCCESS,
  INVOICE_LIST_IMPORT_ERROR,
  JOB_STATEMENT_REQUEST,
  JOB_STATEMENT_REQUEST_SUCCESS,
  JOB_STATEMENT_REQUEST_ERROR,
  JOB_REQUEST,
  JOB_REQUEST_SUCCESS,
  JOB_REQUEST_ERROR,
  INVOICE_CHECK_REQUEST,
  INVOICE_CHECK_REQUEST_SUCCESS,
  INVOICE_CHECK_REQUEST_ERROR,
  INVOICE_PAY_FROM_STATEMENT,
  INVOICE_PAY_FROM_STATEMENT_SUCCESS,
  INVOICE_PAY_FROM_STATEMENT_ERROR,
  INVOICE_COPY,
  INVOICE_COPY_SUCCESS,
  INVOICE_COPY_ERROR,
  INVOICE_CHECK_SMS,
  INVOICE_CHECK_SMS_SUCCESS,
  INVOICE_CHECK_SMS_ERROR,
  UNUSED_TRANSACTION_DELETE,
  UNUSED_TRANSACTION_DELETE_SUCCESS,
  UNUSED_TRANSACTION_DELETE_ERROR,
  UNKNOWN_TRANSACTION_DELETE,
  UNKNOWN_TRANSACTION_DELETE_SUCCESS,
  UNKNOWN_TRANSACTION_DELETE_ERROR,
} from '@/store/actions/invoice';
import { handleRequestError } from '@/utils/error';

import Repository, { INVOICES } from '@/repositories/RepositoryFactory';

const InvoicesRepository = Repository.get(INVOICES);

const state = {
  invoice: {},
  invoiceStatus: '',
  invoiceList: [],
  invoiceListStatus: '',
  invoiceLastCreated: '',
  invoiceMeta: '',
  invoiceExportStatus: '',
  invoiceExportLink: '',
  invoiceSearchList: '',
  invoiceSearchStatus: '',
  invoiceMakePayStatus: '',
  invoiceImportStatus: '',
  invoiceCheckList: [],
  invoiceCheckStatus: '',
  statementList: [],
  statementListStatus: '',
  jobStatementList: [],
  jobStatementListStatus: '',
  jobStatementMeta: '',
  jobRequestStatus: '',
  jobRequest: [],
  invoicePayFromBankStatus: '',
  invoiceCopyStatus: '',
  invoicesSms: '',
  invoicesSmsStatus: '',
  transactionDeleteStatus: '',
  unknownTransactionDeleteStatus: '',
  invoiceSelectedRemoveStatus: '',
};

const getters = {
  invoice: state => state.invoice,
  invoiceStatus: state => state.invoiceStatus,
  invoiceList: state => state.invoiceList,
  invoiceListStatus: state => state.invoiceListStatus,
  invoiceLastCreated: state => state.invoiceLastCreated,
  invoiceListFooter: state => state.invoiceListFooter,
  invoiceMeta: state => state.invoiceMeta,
  invoiceExportStatus: state => state.invoiceExportStatus,
  invoiceExportLink: state => state.invoiceExportLink,
  invoiceSearchList: state => state.invoiceSearchList,
  invoiceSearchStatus: state => state.invoiceSearchStatus,
  invoiceMakePayStatus: state => state.invoiceMakePayStatus,
  invoiceImportStatus: state => state.invoiceImportStatus,
  statementList: state => state.statementList,
  statementListStatus: state => state.statementListStatus,
  jobStatementList: state => state.jobStatementList,
  jobStatementListStatus: state => state.jobStatementListStatus,
  jobStatementMeta: state => state.jobStatementMeta,
  jobRequestStatus: state => state.jobRequestStatus,
  jobRequest: state => state.jobRequest,
  invoiceCheckList: state => state.invoiceCheckList,
  invoiceCheckStatus: state => state.invoiceCheckStatus,
  invoicePayFromBankStatus: state => state.invoicePayFromBankStatus,
  invoiceCopyStatus: state => state.invoiceCopyStatus,
  invoicesSms: state => state.invoicesSms,
  invoicesSmsStatus: state => state.invoicesSmsStatus,
  transactionDeleteStatus: state => state.transactionDeleteStatus,
  unknownTransactionDeleteStatus: state => state.unknownTransactionDeleteStatus,
  invoiceSelectedRemoveStatus: state => state.invoiceSelectedRemoveStatus,
};

const actions = {
  [INVOICE_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(INVOICE_LIST_REQUEST);
    try {
      const response = await InvoicesRepository.get(payload.businessId, payload.data);
      commit(INVOICE_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(INVOICE_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },

  [INVOICE_SEARCH]: async ({ commit }, payload) => {
    commit(INVOICE_SEARCH);
    try {
      let items = [];
      if (payload.query) {
        const response = await InvoicesRepository.get(payload.businessId, payload.data);
        items = response.data.data;
      }

      commit(INVOICE_SEARCH_SUCCESS, items);
    } catch (error) {
      commit(INVOICE_SEARCH_ERROR);
      handleRequestError(error);
    }
  },

  [INVOICE_SEARCH_LIST_CLEAR]: async ({ commit }) => {
    commit(INVOICE_SEARCH_LIST_CLEAR);
  },

  [INVOICE_REQUEST]: async ({ commit }, payload) => {
    commit(INVOICE_REQUEST);
    try {
      const response = await InvoicesRepository.getSelf(payload.businessId, payload.id);
      const { data } = response;
      commit(INVOICE_REQUEST_SUCCESS, data);
    } catch (error) {
      commit(INVOICE_REQUEST_ERROR);
      handleRequestError(error);
    }
  },

  [INVOICE_ADD]: async ({ commit }, payload) => {
    commit(INVOICE_ADD);
    try {
      const response = await InvoicesRepository.create(payload.businessId, payload.data);
      commit(INVOICE_ADD_SUCCESS, response.data.data);
    } catch (error) {
      commit(INVOICE_ADD_ERROR);
      handleRequestError(error);
    }
  },

  [INVOICE_UPDATE]: async ({ commit }, payload) => {
    commit(INVOICE_UPDATE);
    try {
      const response = await InvoicesRepository.update(
        payload.businessId,
        payload.id,
        payload.data
      );
      commit(INVOICE_UPDATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(INVOICE_UPDATE_ERROR);
      handleRequestError(error);
    }
  },

  [INVOICE_REMOVE]: async ({ commit }, payload) => {
    commit(INVOICE_REMOVE);
    try {
      await InvoicesRepository.delete(payload.businessId, payload.id);
      commit(INVOICE_REMOVE_SUCCESS, payload.id);
    } catch (error) {
      commit(INVOICE_REMOVE_ERROR);
      handleRequestError(error);
    }
  },

  [INVOICE_SELECTED_REMOVE]: async ({ commit }, payload) => {
    commit(INVOICE_SELECTED_REMOVE);
    try {
      await InvoicesRepository.deleteChecked(payload.businessId, payload.data);
      commit(INVOICE_SELECTED_REMOVE_SUCCESS);
    } catch (error) {
      commit(INVOICE_SELECTED_REMOVE_ERROR);
      handleRequestError(error);
    }
  },

  [INVOICE_LIST_EXPORT]: async ({ commit }, payload) => {
    commit(INVOICE_LIST_EXPORT);
    try {
      const response = await InvoicesRepository.export(payload.businessId, payload.data);
      commit(INVOICE_LIST_EXPORT_SUCCESS, response.data.data);
    } catch (error) {
      commit(INVOICE_LIST_EXPORT_ERROR);
      handleRequestError(error);
    }
  },

  [INVOICE_MAKE_PAY]: async ({ commit }, payload) => {
    commit(INVOICE_MAKE_PAY);
    try {
      const response = await InvoicesRepository.makePay(
        payload.businessId,
        payload.id,
        payload.data
      );
      commit(INVOICE_MAKE_PAY_SUCCESS, response.data.data);
    } catch (error) {
      commit(INVOICE_MAKE_PAY_ERROR);
      handleRequestError(error);
    }
  },

  [INVOICE_IMPORT]: async ({ commit }, payload) => {
    commit(INVOICE_LIST_IMPORT);
    try {
      await InvoicesRepository.importInvoice(payload.businessId, payload.data);
      commit(INVOICE_LIST_IMPORT_SUCCESS);
    } catch (error) {
      commit(INVOICE_LIST_IMPORT_ERROR);
      handleRequestError(error);
    }
  },

  [INVOICE_COPY]: async ({ commit }, payload) => {
    commit(INVOICE_COPY);
    try {
      await InvoicesRepository.copyInvoice(payload.businessId, payload.data);
      commit(INVOICE_COPY_SUCCESS);
    } catch (error) {
      commit(INVOICE_COPY_ERROR);
      handleRequestError(error);
    }
  },

  [INVOICE_LIST_IMPORT]: async ({ commit }, payload) => {
    commit(INVOICE_LIST_IMPORT);
    try {
      if (payload.isInvoice) {
        await InvoicesRepository.importInvoice(payload.businessId, payload.data);
        commit(INVOICE_LIST_IMPORT_SUCCESS);
      } else {
        const response = await InvoicesRepository.importStatement(payload.businessId, payload.data);
        commit(INVOICE_LIST_IMPORT_SUCCESS, response.data);
      }
    } catch (error) {
      commit(INVOICE_LIST_IMPORT_ERROR);
      handleRequestError(error);
    }
  },

  [JOB_STATEMENT_REQUEST]: async ({ commit }, payload) => {
    commit(JOB_STATEMENT_REQUEST);
    try {
      const response = await InvoicesRepository.getStatementJob(payload.businessId, payload.data);
      commit(JOB_STATEMENT_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(JOB_STATEMENT_REQUEST_ERROR);
      handleRequestError(error);
    }
  },

  [JOB_REQUEST]: async ({ commit }, payload) => {
    commit(JOB_REQUEST);
    try {
      const response = await InvoicesRepository.getJob(payload.businessId, payload);
      commit(JOB_REQUEST_SUCCESS, response);
    } catch (error) {
      commit(JOB_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [INVOICE_CHECK_REQUEST]: async ({ commit }, payload) => {
    commit(INVOICE_CHECK_REQUEST);
    try {
      const response = await InvoicesRepository.checkStatus(payload.businessId, payload.data);
      commit(INVOICE_CHECK_REQUEST_SUCCESS, response);
    } catch (error) {
      commit(INVOICE_CHECK_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [INVOICE_PAY_FROM_STATEMENT]: async ({ commit }, payload) => {
    commit(INVOICE_PAY_FROM_STATEMENT);
    try {
      const response = await InvoicesRepository.payFromStatement(payload.businessId, payload.data);
      commit(INVOICE_PAY_FROM_STATEMENT_SUCCESS, response);
    } catch (error) {
      commit(INVOICE_PAY_FROM_STATEMENT_ERROR);
    }
  },
  [INVOICE_CHECK_SMS]: async ({ commit }, payload) => {
    commit(INVOICE_CHECK_SMS);
    try {
      const response = await InvoicesRepository.checkSms(
        payload.businessId,
        payload.type,
        payload.data
      );
      commit(INVOICE_CHECK_SMS_SUCCESS, response.data);
    } catch (error) {
      commit(INVOICE_CHECK_SMS_ERROR);
      handleRequestError(error);
    }
  },
  [UNUSED_TRANSACTION_DELETE]: async ({ commit }, payload) => {
    commit(UNUSED_TRANSACTION_DELETE);
    try {
      await InvoicesRepository.deleteUnusedStatement(payload.businessId);
      commit(UNUSED_TRANSACTION_DELETE_SUCCESS);
    } catch (error) {
      commit(UNUSED_TRANSACTION_DELETE_ERROR);
      handleRequestError(error);
    }
  },
  [UNKNOWN_TRANSACTION_DELETE]: async ({ commit }, payload) => {
    commit(UNKNOWN_TRANSACTION_DELETE);
    try {
      await InvoicesRepository.deleteUnknownTransaction(payload.businessId, payload.id);
      commit(UNKNOWN_TRANSACTION_DELETE_SUCCESS, payload.id);
    } catch (error) {
      commit(UNKNOWN_TRANSACTION_DELETE_ERROR);
      handleRequestError(error);
    }
  },
};

const mutations = {
  [INVOICE_LIST_REQUEST]: state => {
    state.invoiceListStatus = 'loading';
  },
  [INVOICE_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.invoiceListStatus = 'success';
    state.invoiceList = payload.data;
    state.invoiceMeta = payload.meta;
  },
  [INVOICE_LIST_REQUEST_ERROR]: state => {
    state.invoiceListStatus = 'error';
  },

  [INVOICE_SEARCH]: state => {
    state.invoiceSearchStatus = 'loading';
  },
  [INVOICE_SEARCH_SUCCESS]: (state, payload) => {
    state.invoiceSearchStatus = 'success';
    state.invoiceSearchList = payload;
  },
  [INVOICE_SEARCH_ERROR]: state => {
    state.invoiceSearchStatus = 'error';
  },

  [INVOICE_SEARCH_LIST_CLEAR]: state => {
    state.invoiceSearchList = [];
  },

  [INVOICE_ADD]: state => {
    state.invoiceStatus = 'loading';
    state.invoiceLastCreated = '';
  },
  [INVOICE_ADD_SUCCESS]: (state, payload) => {
    state.invoiceStatus = 'success';
    state.invoiceLastCreated = payload;
    state.invoiceList.push(payload);
  },
  [INVOICE_ADD_ERROR]: state => {
    state.invoiceStatus = 'error';
  },

  [INVOICE_LIST_EXPORT]: state => {
    state.invoiceExportStatus = 'loading';
    state.invoiceExportLink = '';
  },
  [INVOICE_LIST_EXPORT_SUCCESS]: (state, payload) => {
    state.invoiceExportStatus = 'success';
    state.invoiceExportLink = payload;
  },
  [INVOICE_LIST_EXPORT_ERROR]: state => {
    state.invoiceExportStatus = 'error';
  },

  [INVOICE_REQUEST]: state => {
    state.invoiceStatus = 'loading';
  },
  [INVOICE_REQUEST_SUCCESS]: (state, payload) => {
    state.invoiceStatus = 'success';
    state.invoice = payload.data;
  },
  [INVOICE_REQUEST_ERROR]: state => {
    state.invoiceStatus = 'error';
  },

  [INVOICE_UPDATE]: state => {
    state.invoiceStatus = 'loading';
  },
  [INVOICE_UPDATE_SUCCESS]: (state, payload) => {
    state.invoiceStatus = 'success';
    const index = findIndex(state.invoiceList, { id: payload.id });
    state.invoiceList[index] = payload;
  },
  [INVOICE_UPDATE_ERROR]: state => {
    state.invoiceStatus = 'error';
  },

  [INVOICE_REMOVE]: state => {
    state.invoiceStatus = 'deleting';
  },
  [INVOICE_REMOVE_SUCCESS]: (state, payload) => {
    const index = findIndex(state.invoiceList, { id: payload });
    state.invoiceList.splice(index, 1);
  },
  [INVOICE_REMOVE_ERROR]: state => {
    state.invoiceStatus = 'error';
  },

  [INVOICE_SELECTED_REMOVE]: state => {
    state.invoiceSelectedRemoveStatus = 'loading';
  },
  [INVOICE_SELECTED_REMOVE_SUCCESS]: state => {
    state.invoiceSelectedRemoveStatus = 'success';
  },
  [INVOICE_SELECTED_REMOVE_ERROR]: state => {
    state.invoiceSelectedRemoveStatus = 'error';
  },

  [INVOICE_MAKE_PAY]: state => {
    state.invoiceMakePayStatus = 'loading';
  },
  [INVOICE_MAKE_PAY_SUCCESS]: (state, payload) => {
    state.invoiceMakePayStatus = 'success';
    state.invoiceList = state.invoiceList.map(item => {
      if (item.id === payload.id) {
        return {
          ...item,
          status: payload.status,
          paidAmount: payload.paidAmount,
          paidDate: payload.paidDate,
        };
      }
      return item;
    });
  },
  [INVOICE_MAKE_PAY_ERROR]: state => {
    state.invoiceMakePayStatus = 'error';
  },

  [INVOICE_LIST_IMPORT]: state => {
    state.invoiceImportStatus = 'loading';
  },
  [INVOICE_LIST_IMPORT_SUCCESS]: (state, payload) => {
    state.invoiceImportStatus = 'success';
    state.statementListStatus = 'success';
    state.statementList = payload.warning_data;
  },
  [INVOICE_LIST_IMPORT_ERROR]: state => {
    state.invoiceImportStatus = 'error';
  },
  [JOB_REQUEST]: state => {
    state.jobRequestStatus = 'loading';
  },
  [JOB_REQUEST_SUCCESS]: (state, payload) => {
    state.jobRequestStatus = 'success';
    state.jobRequest = payload.data;
  },
  [JOB_REQUEST_ERROR]: state => {
    state.jobRequestStatus = 'error';
  },
  [JOB_STATEMENT_REQUEST]: state => {
    state.jobStatementListStatus = 'loading';
  },
  [JOB_STATEMENT_REQUEST_SUCCESS]: (state, payload) => {
    state.jobStatementListStatus = 'success';
    state.jobStatementList = payload.data;
    if (payload.meta) {
      state.jobStatementMeta = payload.meta;
    }
  },
  [JOB_STATEMENT_REQUEST_ERROR]: state => {
    state.jobStatementListStatus = 'error';
  },
  [INVOICE_CHECK_REQUEST]: state => {
    state.invoiceCheckStatus = 'loading';
  },
  [INVOICE_CHECK_REQUEST_SUCCESS]: (state, payload) => {
    state.invoiceCheckStatus = 'success';
    state.invoiceCheckList = payload.data.data;
  },
  [INVOICE_CHECK_REQUEST_ERROR]: state => {
    state.invoiceCheckStatus = 'error';
  },
  [INVOICE_PAY_FROM_STATEMENT]: state => {
    state.invoicePayFromBankStatus = 'loading';
  },
  [INVOICE_PAY_FROM_STATEMENT_SUCCESS]: state => {
    state.invoicePayFromBankStatus = 'success';
  },
  [INVOICE_PAY_FROM_STATEMENT_ERROR]: state => {
    state.invoicePayFromBankStatus = 'error';
  },
  [INVOICE_COPY]: state => {
    state.invoiceCopyStatus = 'loading';
  },
  [INVOICE_COPY_SUCCESS]: state => {
    state.invoiceCopyStatus = 'success';
  },
  [INVOICE_COPY_ERROR]: state => {
    state.invoiceCopyStatus = 'error';
  },
  // sms check
  [INVOICE_CHECK_SMS]: state => {
    state.invoicesSmsStatus = 'loading';
  },
  [INVOICE_CHECK_SMS_SUCCESS]: (state, payload) => {
    state.invoicesSmsStatus = 'success';
    state.invoicesSms = payload;
  },
  [INVOICE_CHECK_SMS_ERROR]: state => {
    state.invoicesSmsStatus = 'error';
  },
  [UNUSED_TRANSACTION_DELETE]: state => {
    state.transactionDeleteStatus = 'loading';
  },
  [UNUSED_TRANSACTION_DELETE_SUCCESS]: state => {
    state.transactionDeleteStatus = 'success';
  },
  [UNUSED_TRANSACTION_DELETE_ERROR]: state => {
    state.transactionDeleteStatus = 'error';
  },
  [UNKNOWN_TRANSACTION_DELETE]: state => {
    state.unknownTransactionDeleteStatus = 'deleting';
  },
  [UNKNOWN_TRANSACTION_DELETE_SUCCESS]: (state, payload) => {
    const index = findIndex(state.jobStatementList, { id: payload });
    state.jobStatementList.splice(index, 1);
    state.unknownTransactionDeleteStatus = 'deleted';
  },
  [UNKNOWN_TRANSACTION_DELETE_ERROR]: state => {
    state.unknownTransactionDeleteStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
