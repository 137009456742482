<template>
  <div class="field">
    <div class="flex flex-col sm:flex-row">
      <div class="flex items-center text-gray-700 dark:text-gray-500 mr-2 mt-2">
        <input
          type="checkbox"
          id="switch"
          :checked="isChecked"
          @change="onChangeChecked"
          class="input input--switch border mr-2"
        />
        <label for="switch" class="cursor-pointer select-none">
          {{ isChecked ? $t('text.active') : $t('text.inactive') }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import baseInputMixin from '@/mixins/baseInputMixin';

export default {
  mixins: [baseInputMixin],
  props: {
    checked: Boolean,
  },

  computed: {
    isChecked() {
      return this.value;
    },
  },

  methods: {
    onChangeChecked($event) {
      const value = !!$event.target.checked;
      this.$emit('input', value);
    },
  },
};
</script>
