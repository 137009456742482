import Index from '@/views/Order/Index';
import Order from '@/views/Order/Order';

export default [
  {
    path: '/order',
    component: Index,
    meta: { group: 'order' },
    children: [
      {
        path: '',
        component: Order,
        name: 'Order',
      },
    ],
  },
];
