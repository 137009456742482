<template>
  <div class="apartment-and-parking">
    <p class="font-medium whitespace-nowrap">{{ $t('text.doorNumber') }}: {{ item.number }}</p>
    <p class="text-gray-600 text-xs whitespace-nowrap">
      {{ $t('text.phone') }}:
      <span v-for="(owner, idx) in item.owners" :key="idx">
        <br />
        {{ owner.firstName }} - {{ owner.phone }}
      </span>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
