<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-5">
      <PageHeader class="text-lg font-medium mr-auto" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <router-link
          :to="{ name: 'BannerCreate' }"
          class="button text-white bg-theme-1 shadow-md mr-2"
        >
          {{ $t('text.create') }}
        </router-link>
      </div>
    </div>
    <div class="intro-y mt-5 overflow-auto lg:overflow-visible">
      <BannerTable
        :loading="isLoading"
        :rows="bannerList"
        :columns="columns"
        :line-numbers="true"
        :sort="sort"
        :page="page"
        :per-page="perPage"
        :page-size="pageSize"
        :total-page="totalPage"
        @delete="confirmRemoval"
        @onFilter="onFilter"
        @onPageChange="onPageChange"
        @onPerPageChange="onPerPageChange"
        @onSortChange="onSortChange"
      />
    </div>
    <ConfirmDialog
      :modal-id="deleteModalId"
      :is-loading="isDeleting ? true : false"
      @ok="onDelete"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BANNER_LIST_REQUEST, BANNER_DELETE } from '@/store/actions/banner';
import dataListMixin from '@/mixins/dataListMixin';
import PageHeader from '@/components/ui/PageHeader';
import BannerTable from '@/components/banners/BannerTable';

export default {
  mixins: [dataListMixin],

  data() {
    return {
      storeActionListRequest: BANNER_LIST_REQUEST,
      storeActionRemove: BANNER_DELETE,
      columns: [
        { label: this.$t('table.name'), field: 'title' },
        { label: this.$t('table.content'), field: 'description' },
        { label: this.$t('table.status'), field: 'status' },
        // { label: this.$t('table.createdDate'), field: 'createdAt' },
        { label: this.$t('table.action'), field: 'action' },
      ],
    };
  },

  computed: {
    ...mapGetters(['bannerList', 'bannerListMeta', 'bannerStatus']),
    isLoading() {
      return this.bannerStatus === 'loading';
    },
  },

  async created() {
    await this.fetchData();
  },

  methods: {
    setPaginationValues() {
      this.totalRecords = this.bannerListMeta ? this.bannerListMeta.total : 1;
      this.page = this.bannerListMeta.currentPage;
      this.totalPage = this.bannerListMeta.lastPage;
    },
  },

  components: {
    PageHeader,
    BannerTable,
  },
};
</script>
