export const USER_REQUEST = 'USER_REQUEST';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_ERROR = 'USER_ERROR';
export const USER_REGISTER = 'USER_REGISTER';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_ERROR = 'USER_REGISTER_ERROR';

export const USER_VALIDATE = 'USER_VALIDATE';
export const USER_VALIDATE_SUCCESS = 'USER_VALIDATE_SUCCESS';
export const USER_VALIDATE_ERROR = 'USER_VALIDATE_ERROR';

export const USER_PROFILE_UPDATE = 'USER_PROFILE_UPDATE';
export const USER_PROFILE_UPDATE_SUCCESS = 'USER_PROFILE_UPDATE_SUCCESS';
export const USER_PROFILE_UPDATE_ERROR = 'USER_PROFILE_UPDATE_ERROR';

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST';
export const USER_LIST_REQUEST_SUCCESS = 'USER_LIST_REQUEST_SUCCESS';
export const USER_LIST_REQUEST_ERROR = 'USER_LIST_REQUEST_ERROR';

export const ALL_USER_LIST_REQUEST = 'ALL_USER_LIST_REQUEST';
export const ALL_USER_LIST_REQUEST_SUCCESS = 'ALL_USER_LIST_REQUEST_SUCCESS';
export const ALL_USER_LIST_REQUEST_ERROR = 'ALL_USER_LIST_REQUEST_ERROR';

export const USER_PERMISSION_REQUEST = 'USER_PERMISSION_REQUEST';
export const USER_PERMISSION_REQUEST_SUCCESS = 'USER_PERMISSION_REQUEST_SUCCESS';
export const USER_PERMISSION_REQUEST_ERROR = 'USER_PERMISSION_REQUEST_ERROR';

export const USER_PERMISSION_UPDATE = 'USER_PERMISSION_UPDATE';
export const USER_PERMISSION_UPDATE_SUCCESS = 'USER_PERMISSION_UPDATE_SUCCESS';
export const USER_PERMISSION_UPDATE_ERROR = 'USER_PERMISSION_UPDATE_ERROR';

export const USER_CREATE_REQUEST = 'USER_CREATE_REQUEST';
export const USER_CREATE_REQUEST_SUCCESS = 'USER_CREATE_REQUEST_SUCCESS';
export const USER_CREATE_REQUEST_ERROR = 'USER_CREATE_REQUEST_ERROR';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_REQUEST_SUCCESS = 'USER_UPDATE_REQUEST_SUCCESS';
export const USER_UPDATE_REQUEST_ERROR = 'USER_UPDATE_REQUEST_ERROR';

export const USER_GET_REQUEST = 'USER_GET_REQUEST';
export const USER_GET_REQUEST_SUCCESS = 'USER_GET_REQUEST_SUCCESS';
export const USER_GET_REQUEST_ERROR = 'USER_GET_REQUEST_ERROR';
export const USER_RESET_PASSWORD = 'USER_RESET_PASSWORD';
export const USER_RESET_PASSWORD_SUCCESS = 'USER_RESET_PASSWORD_SUCCESS';
export const USER_RESET_PASSWORD_ERROR = 'USER_RESET_PASSWORD_ERROR';
