<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <PageHeader class="text-lg font-medium mr-auto" :title="title" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <Button
          type="button"
          :to="{ name: 'Expenses' }"
          class="box text-gray-700 dark:text-gray-300 mr-2 flex"
        >
          {{ $t('text.back') }}
        </Button>
        <Button
          type="button"
          @click="onSubmit"
          :loading="isLoading"
          class="w-24 bg-theme-1 text-white"
        >
          {{ $t('text.save') }}
        </Button>
      </div>
    </div>
    <div class="intro-y mt-5">
      <form @submit.prevent="onSubmit">
        <div class="grid grid-cols-12 gap-6">
          <div class="intro-y col-span-12 sm:col-span-12 lg:col-span-4 xl:col-span-4">
            <div class="intro-y box">
              <div class="p-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class="font-medium text-base">
                  {{ $t('nav.expenseShow') }}
                </h2>
              </div>
              <div class="p-5">
                <InputString
                  :label="$t('text.type')"
                  v-model.trim="$v.form.name.$model"
                  :vuelidate="$v.form.name"
                  input-class="input-expense-name"
                />

                <InputString
                  :label="$t('text.meaning')"
                  v-model.trim="$v.form.description.$model"
                  :vuelidate="$v.form.description"
                  input-class="input-expense-description"
                />

                <InputDate
                  v-model="$v.form.expenditureDate.$model"
                  :vuelidate="$v.form.expenditureDate"
                  input-class="input-expense-expenditure-date"
                  :label="$t('text.date')"
                />

                <InputMoney
                  :label="$t('text.amount')"
                  v-model.trim="$v.form.amount.$model"
                  :vuelidate="$v.form.amount"
                  input-class="input-expense-amount"
                />
              </div>
            </div>
          </div>

          <div class="intro-y col-span-12 sm:col-span-12 lg:col-span-4 xl:col-span-4">
            <div class="intro-y box">
              <div class="p-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class="font-medium text-base">{{ $t('text.buildings') }}</h2>
              </div>
              <Loading v-if="buildingListLoading" />
              <div class="input-expense-toggle-items" v-else>
                <template v-for="(building, index) in buildingList">
                  <label
                    :key="index"
                    class="py-4 px-5 border-b border-gray-200 dark:border-dark-5 flex items-center cursor-pointer hover:bg-gray-100 dark:hover:bg-dark-2"
                  >
                    <div class="mr-3">
                      <input
                        class="input border border-gray-500"
                        :checked="checkItemInArray(building, selectedBuildings)"
                        @change="toggleItem(building, selectedBuildings)"
                        type="checkbox"
                      />
                    </div>
                    <BuildingFullname
                      :item="building"
                      class="lg:ml-3 mt-3 lg:mt-0 overflow-hidden"
                    />
                  </label>
                </template>
              </div>
            </div>
          </div>

          <div class="intro-y col-span-12 sm:col-span-12 lg:col-span-4 xl:col-span-4">
            <div class="intro-y box">
              <div class="p-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class="font-medium text-base">{{ $t('text.respondent') }}</h2>
              </div>
              <Loading v-if="userListLoading" />
              <div class="expense-toggle-user-list" v-else>
                <template v-for="(user, index) in userList">
                  <label
                    :key="index"
                    class="py-4 px-5 border-b border-gray-200 dark:border-dark-5 flex items-center cursor-pointer hover:bg-gray-100 dark:hover:bg-dark-2"
                  >
                    <div class="mr-3">
                      <input
                        class="input border border-gray-500"
                        :checked="checkItemInArray(user, selectedUsers)"
                        @change="toggleItem(user, selectedUsers)"
                        type="checkbox"
                      />
                    </div>
                    <PeopleFullname :item="user" />
                  </label>
                </template>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { EXPENSE_ADD, EXPENSE_UPDATE, EXPENSE_REQUEST } from '@/store/actions/expense';
import { BUILDING_LIST_REQUEST } from '@/store/actions/building';
import { USER_LIST_REQUEST } from '@/store/actions/user';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import Button from '@/components/ui/button/Button';
import PageHeader from '@/components/ui/PageHeader';
import InputString from '@/components/ui/input/InputString';
import InputDate from '@/components/ui/input/InputDate';
import InputMoney from '@/components/ui/input/InputMoney';
import BuildingFullname from '@/components/building/BuildingFullname';
import PeopleFullname from '@/components/people/PeopleFullname';

export default {
  mixins: [validationMixin],

  components: {
    Button,
    PageHeader,
    InputString,
    InputDate,
    InputMoney,
    BuildingFullname,
    PeopleFullname,
  },

  props: {
    business: Object,
  },

  validations: {
    form: {
      name: { required },
      description: { required },
      expenditureDate: { required },
      amount: { required },
    },
    selectedUsers: {
      required,
    },
    selectedBuildings: {
      required,
    },
  },

  data() {
    return {
      form: {
        name: '',
        description: '',
        expenditureDate: '',
        amount: 0,
        users: [],
        buildings: [],
      },
      selectedUsers: [],
      selectedBuildings: [],
      defaultTableData: {
        filters: {},
        sort: {
          field: 'id',
          type: 'desc',
        },
        page: 1,
        perPage: 100,
      },
    };
  },

  computed: {
    ...mapGetters([
      'expenseStatus',
      'expense',
      'buildingStatus',
      'buildingList',
      'userList',
      'userListStatus',
    ]),
    isLoading() {
      return this.expenseStatus === 'loading';
    },
    buildingListLoading() {
      return this.buildingStatus === 'loading';
    },
    userListLoading() {
      return this.userListStatus === 'loading';
    },
    title() {
      return this.$route.name === 'ExpenseCreate'
        ? this.$t(`nav.expenseCreate`)
        : this.$t(`nav.expenseUpdate`);
    },
  },

  async created() {
    await this.fetchData();
  },

  methods: {
    async fetchData() {
      if (this.$route.params.expenseId) {
        const payload = { businessId: this.business.id, id: this.$route.params.expenseId };
        await this.$store.dispatch(EXPENSE_REQUEST, payload);
        const { expense } = this;
        if (expense) {
          this.form = {
            name: expense.name,
            description: expense.description,
            expenditureDate: expense.expenditureDate,
            amount: expense.amount,
            users: [],
            buildings: [],
          };

          this.selectedUsers = expense.users;
        }
      }

      this.fetchBuildingList();
      this.fetchUserList();
    },

    async fetchBuildingList() {
      const payload = {
        businessId: this.business.id,
        data: this.defaultTableData,
      };

      await this.$store.dispatch(BUILDING_LIST_REQUEST, payload);
    },

    async fetchUserList() {
      const data = { page: 1, perPage: 100 };
      const payload = { businessId: this.business.id, data };
      await this.$store.dispatch(USER_LIST_REQUEST, payload);
    },

    toggleItem(object, array) {
      const index = array.findIndex(item => item.id === object.id);
      if (index > -1) {
        array.splice(index, 1);
      } else {
        array.push(object);
      }
    },

    checkItemInArray(object, array) {
      const index = array.findIndex(item => item.id === object.id);
      if (index > -1) {
        return true;
      }
      return false;
    },

    async onSubmit() {
      if (this.$v) {
        this.$v.selectedUsers.$touch();
        this.$v.selectedBuildings.$touch();
        this.$v.form.$touch();
        if (
          this.$v.form.$anyError ||
          this.$v.selectedUsers.$anyError ||
          this.$v.selectedBuildings.$anyError
        ) {
          if (this.$v.form.$anyError) {
            this.$toasted.show(this.$t('toast.failedForm'), { type: 'error' });
            return;
          }
          if (this.$v.selectedBuildings.$anyError) {
            this.$toasted.show(this.$t('toast.requiredBuilding'), { type: 'error' });
            return;
          }
          this.$toasted.show(this.$t('toast.requiredRespondent'), { type: 'error' });
          return;
        }
      }

      try {
        this.form.buildings = this.selectedBuildings.map(item => item.id);
        this.form.users = this.selectedUsers.map(item => item.id);
        if (this.$route.params.expenseId) {
          const payload = {
            businessId: this.business.id,
            id: this.$route.params.expenseId,
            data: this.form,
          };
          await this.$store.dispatch(EXPENSE_UPDATE, payload);
        } else {
          const payload = {
            businessId: this.business.id,
            data: this.form,
          };
          await this.$store.dispatch(EXPENSE_ADD, payload);
        }
        if (this.expenseStatus === 'success') {
          this.$router.push({ name: 'Expenses' });
          this.$toasted.show(this.$t('toast.success'), { type: 'success' });
        }
      } catch (error) {
        this.$toasted.show(error.message, { type: 'error' });
      }
    },
  },
};
</script>
