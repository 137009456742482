<template>
  <TableRegular
    :loading="loading"
    :downloading="downloading"
    :rows="rows"
    :columns="columns"
    :custom-filters="customFilters"
    :sort="sort"
    :page="page"
    :per-page="perPage"
    :page-size="pageSize"
    :total-page="totalPage"
    :downloadable="downloadable && checkPermission('service.services.download')"
    @onFilter="$emit('onFilter', $event)"
    @onPageChange="$emit('onPageChange', $event)"
    @onPerPageChange="$emit('onPerPageChange', $event)"
    @onSortChange="$emit('onSortChange', $event)"
    @onDownload="$emit('onDownload')"
    @onChangeCustomFilter="$emit('onChangeCustomFilter', $event)"
  >
    <template slot="table-row" slot-scope="props">
      <p v-if="props.column.field === 'assignedUser'">
        <PeopleFullname v-if="props.row.assignedUser" :item="props.row.assignedUser" />
      </p>
      <p v-else-if="props.column.field === 'createdAt'" class="font-medium whitespace-nowrap">
        {{ props.row.createdAt | momentDate('YYYY-MM-DD HH:mm') }}
      </p>
      <p v-else-if="props.column.field === 'business'">
        <template v-if="props.row.business">{{ props.row.business.name }}</template>
      </p>
      <div
        v-else-if="props.column.field === 'description'"
        class="w-40 whitespace-nowrap overflow-ellipsis overflow-hidden"
        v-tooltip.top-center="props.row.description"
      >
        <template v-if="props.row.description">{{ props.row.description }}</template>
      </div>
      <div v-else-if="props.column.field === 'buildings'" class="w-52">
        <template v-if="props.row.buildings && props.row.buildings.length > 0">
          <template v-for="(item, index) in props.row.buildings">
            <span v-if="index < 5" :key="index" class="mr-2">
              {{ item.name }}<span v-if="index < 4">,</span>
            </span>
          </template>
          <a
            v-if="props.row.buildings.length > 5"
            @click="openModalBuilding(props.row.id)"
            class="underline cursor-pointer"
            >{{ $t('text.seeMore') }}</a
          >
          <ModalEdit
            :id="`modal-see-more-buildings-${props.row.id}`"
            :title="$t('text.buildings')"
            :save-button="false"
          >
            <div class="p-6">
              <template v-for="(item, index) in props.row.buildings">
                <span :key="index" class="mr-2">
                  {{ item.name }}<span v-if="index < props.row.buildings.length - 1">,</span>
                </span>
              </template>
            </div>
          </ModalEdit>
        </template>
      </div>
      <p v-else-if="props.column.field === 'apartments'" class="w w-36">
        <template v-if="props.row.apartments && props.row.apartments.length > 0">
          <template v-for="(item, index) in props.row.apartments">
            <span v-if="index < 10" :key="index" class="mr-2">
              {{ item.number }}<span v-if="index < 9">,</span>
            </span>
          </template>
          <a
            v-if="props.row.apartments.length > 10"
            @click="openModal(props.row.id)"
            class="underline cursor-pointer"
            >{{ $t('text.seeMore') }}</a
          >
          <ModalEdit
            :id="`modal-see-more-apartments-${props.row.id}`"
            :title="$t('text.apartments')"
            :save-button="false"
          >
            <div class="p-6">
              <template v-for="(item, index) in props.row.apartments">
                <span :key="index" class="mr-2">
                  {{ item.number }}<span v-if="index < props.row.apartments.length - 1">,</span>
                </span>
              </template>
            </div>
          </ModalEdit>
        </template>
      </p>
      <p v-else-if="props.column.field === 'action'" class="flex justify-center items-center">
        <router-link
          v-if="checkPermission('announcements.announcements.edit')"
          :to="{ name: 'AnnouncementUpdate', params: { id: props.row.id } }"
          class="flex items-center mr-3"
        >
          <CheckSquareIcon class="w-4 h-4 mr-1" />
          {{ $t('table.edit') }}
        </router-link>
        <a
          v-if="checkPermission('announcements.announcements.delete')"
          href="javascript:"
          @click="$emit('delete', props.row.id)"
          class="flex items-center text-theme-6"
        >
          <Trash2Icon class="w-4 h-4 mr-1" />
          {{ $t('table.delete') }}
        </a>
      </p>
    </template>
  </TableRegular>
</template>

<script>
import TableRegular from '@/components/ui/table/TableRegular';
import tableMixin from '@/mixins/tableMixin';
import PeopleFullname from '@/components/people/PeopleFullname';
import ModalEdit from '@/components/ui/ModalEdit';

export default {
  mixins: [tableMixin],
  components: { TableRegular, PeopleFullname, ModalEdit },
  methods: {
    openModal(index) {
      cash(`#modal-see-more-apartments-${index}`).modal('show');
    },
    openModalBuilding(index) {
      cash(`#modal-see-more-buildings-${index}`).modal('show');
    },
  },
};
</script>
