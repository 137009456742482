export const SERVICE_LIST_REQUEST = 'SERVICE_LIST_REQUEST';
export const SERVICE_LIST_REQUEST_SUCCESS = 'SERVICE_LIST_REQUEST_SUCCESS';
export const SERVICE_LIST_REQUEST_ERROR = 'SERVICE_LIST_REQUEST_ERROR';

export const SERVICE_ADD = 'SERVICE_ADD';
export const SERVICE_ADD_SUCCESS = 'SERVICE_ADD_SUCCESS';
export const SERVICE_ADD_ERROR = 'SERVICE_ADD_ERROR';

export const SERVICE_UPDATE = 'SERVICE_UPDATE';
export const SERVICE_UPDATE_SUCCESS = 'SERVICE_UPDATE_SUCCESS';
export const SERVICE_UPDATE_ERROR = 'SERVICE_UPDATE_ERROR';

export const SERVICE_REQUEST = 'SERVICE_REQUEST';
export const SERVICE_REQUEST_SUCCESS = 'SERVICE_REQUEST_SUCCESS';
export const SERVICE_REQUEST_ERROR = 'SERVICE_REQUEST_ERROR';
