<template>
  <TableRegular
    :loading="loading"
    :downloading="downloading"
    :rows="rows"
    :columns="columns"
    :sort="sort"
    :page="page"
    :per-page="perPage"
    :page-size="pageSize"
    :total-page="totalPage"
    :line-numbers="lineNumbers"
    :custom-filters="customFilters"
    @onPageChange="$emit('onPageChange', $event)"
    @onPerPageChange="$emit('onPerPageChange', $event)"
    @onSortChange="$emit('onSortChange', $event)"
    @onDownload="$emit('onDownload')"
    @onChangeCustomFilter="$emit('onChangeCustomFilter', $event)"
  >
    <template slot="table-row" slot-scope="props">
      <p v-if="props.column.field === 'action'" class="flex justify-center items-center">
        <router-link
          :to="{ name: 'permission', params: { userId: props.row.id } }"
          class="flex items-center mr-3"
        >
          <CheckSquareIcon class="w-4 h-4 mr-1" />
          {{ $t('nav.permission') }}
        </router-link>
        <router-link
          :to="{ name: 'userUpdate', params: { userId: props.row.id } }"
          class="flex items-center mr-3"
        >
          <CheckSquareIcon class="w-4 h-4 mr-1" />
          {{ $t('table.edit') }}
        </router-link>
        <!-- <a
          href="javascript:;"
          @click="$emit('delete', props.row.id)"
          class="flex items-center text-theme-6"
        >
          <CheckSquareIcon class="w-4 h-4 mr-1" />
          {{ $t('table.delete') }}
        </a> -->
      </p>
    </template>
  </TableRegular>
</template>

<script>
import TableRegular from '@/components/ui/table/TableRegular';
import tableMixin from '@/mixins/tableMixin';

export default {
  mixins: [tableMixin],
  components: { TableRegular },
};
</script>
