<template>
  <div class="dropdown">
    <component
      :is="togglerTag"
      class="dropdown-toggle button px-2 box text-gray-700 dark:text-gray-300"
    >
      <span class="w-5 h-5 flex items-center justify-center">
        <PlusIcon class="w-4 h-4" />
      </span>
    </component>
    <div :class="['dropdown-box', boxClass]">
      <div
        :class="[
          'dropdown-box__content dropdown-box__content-list box p-2 dark:bg-dark-6',
          contentClass,
        ]"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import dropDownMixin from '@/mixins/dropDownMixin';

export default {
  mixins: [dropDownMixin],
};
</script>
