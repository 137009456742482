export const BANNER_LIST_REQUEST = 'BANNER_LIST_REQUEST';
export const BANNER_LIST_REQUEST_SUCCESS = 'BANNER_LIST_REQUEST_SUCCESS';
export const BANNER_LIST_REQUEST_ERROR = 'BANNER_LIST_REQUEST_ERROR';

export const BANNER_SHOW = 'BANNER_SHOW';
export const BANNER_SHOW_SUCCESS = 'BANNER_SHOW_SUCCESS';
export const BANNER_SHOW_ERROR = 'BANNER_SHOW_ERROR';

export const BANNER_CREATE = 'BANNER_CREATE';
export const BANNER_CREATE_SUCCESS = 'BANNER_CREATE_SUCCESS';
export const BANNER_CREATE_ERROR = 'BANNER_CREATE_ERROR';

export const BANNER_UPDATE = 'BANNER_UPDATE';
export const BANNER_UPDATE_SUCCESS = 'BANNER_UPDATE_SUCCESS';
export const BANNER_UPDATE_ERROR = 'BANNER_UPDATE_ERROR';

export const BANNER_DELETE = 'BANNER_DELETE';
export const BANNER_DELETE_SUCCESS = 'BANNER_DELETE_SUCCESS';
export const BANNER_DELETE_ERROR = 'BANNER_DELETE_ERROR';
