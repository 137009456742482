<template>
  <h2>{{ title ? title : routeTitle }}</h2>
</template>

<script>
import humps from 'humps';

export default {
  name: 'PageHeader',

  props: {
    title: String,
  },

  computed: {
    routeTitle() {
      return this.$t(`nav.${humps.camelize(this.$route.name)}`);
    },
  },
};
</script>
