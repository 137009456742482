<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <PageHeader class="text-lg font-medium mr-auto" :title="title" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <Button
          type="button"
          :to="{ name: 'Banners' }"
          class="box text-gray-700 dark:text-gray-300 mr-2 flex"
        >
          {{ $t('text.back') }}
        </Button>
        <Button
          type="button"
          @click="onSubmit"
          :loading="isLoading"
          class="w-24 bg-theme-1 text-white"
        >
          {{ $t('text.save') }}
        </Button>
      </div>
    </div>
    <div class="intro-y mt-5">
      <form @submit.prevent="onSubmit">
        <div class="intro-y box">
          <div class="p-5 border-b border-gray-200 dark:border-dark-5">
            <h2 class="font-medium text-base">{{ $t('nav.bannerShow') }}</h2>
          </div>
          <div class="py-4 px-5 relative">
            <div class="grid grid-cols-12 gap-6">
              <div class="intro-y col-span-12 sm:col-span-12 lg:col-span-6">
                <InputString
                  :label="$t('text.title')"
                  v-model.trim="$v.form.title.$model"
                  :vuelidate="$v.form.title"
                  input-class="input-banner-title"
                />
                <InputString
                  :label="$t('text.description')"
                  v-model.trim="$v.form.description.$model"
                  :vuelidate="$v.form.description"
                  input-class="input-banner-description"
                  is-textarea
                />
                <InputSelect
                  :label="$t('text.status')"
                  v-model.trim="$v.form.status.$model"
                  :options="statusOptions"
                  value-key="id"
                  :vuelidate="$v.form.status"
                  input-class="input-banner-status"
                />
              </div>
              <div class="intro-y col-span-12 sm:col-span-12 lg:col-span-6">
                <InputPhoto
                  :label="$t('text.attachImages')"
                  :photo="form.gallery"
                  :vuelidate="$v.form.gallery"
                  input-class="input-banner-image"
                  @change="onChangeBanner"
                  @remove="onDeleteBanner"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { assign } from 'lodash';
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { STATUS_OPTIONS } from '@/utils/constants';
import { BANNER_SHOW, BANNER_CREATE, BANNER_UPDATE } from '@/store/actions/banner';
import { FILE_UPLOAD } from '@/store/actions/file';
import Button from '@/components/ui/button/Button';
import PageHeader from '@/components/ui/PageHeader';
import InputString from '@/components/ui/input/InputString';
import InputSelect from '@/components/ui/input/InputSelect';
import InputPhoto from '@/components/ui/input/InputPhoto';

export default {
  mixins: [validationMixin],

  validations() {
    return {
      form: {
        title: {
          required,
        },
        description: {
          required,
        },
        status: {
          required,
        },
        gallery: {
          required,
        },
      },
    };
  },

  data() {
    return {
      form: {
        title: '',
        description: '',
        status: 'ACTIVE',
        gallery: null,
      },
      image: '',
      statusOptions: STATUS_OPTIONS,
    };
  },

  computed: {
    ...mapGetters(['bannerStatus', 'banner', 'getFileUpload']),
    isLoading() {
      return this.bannerStatus === 'loading';
    },
    title() {
      return this.$route.name === 'BannerCreate'
        ? this.$t(`nav.bannerCreate`)
        : this.$t(`nav.bannerUpdate`);
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      if (this.$route.params.id) {
        await this.$store.dispatch(BANNER_SHOW, { id: this.$route.params.id });
        if (this.banner) {
          const { title, status, description, gallery } = this.banner;
          this.form = {
            title,
            description,
            status,
            gallery,
          };
        }
      }
    },
    async onChangeBanner(e) {
      const file = e.target.files[0];
      await this.$store.dispatch(FILE_UPLOAD, file);

      if (this.getFileUpload.status === 'success') {
        this.form.gallery = this.getFileUpload.item;
      }
    },
    onDeleteBanner() {
      this.form.gallery = null;
    },
    async onSubmit() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          this.$toasted.show(this.$t('toast.failedForm'), { type: 'error' });
          return;
        }
      }

      try {
        const payload = {
          data: { ...this.form },
        };

        if (this.$route.params.id) {
          assign(payload, { id: this.banner.id });
          await this.$store.dispatch(BANNER_UPDATE, payload);
        } else {
          await this.$store.dispatch(BANNER_CREATE, payload);
        }
        if (this.bannerStatus === 'success') {
          this.$router.push({ name: 'Banners' });
          this.$toasted.show(this.$t('toast.success'), { type: 'success' });
        }
      } catch (error) {
        this.$toasted.show(error.message, { type: 'error' });
      }
    },
  },

  components: {
    Button,
    PageHeader,
    InputString,
    InputSelect,
    InputPhoto,
  },
};
</script>
