<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <PageHeader class="text-lg font-medium mr-auto" :title="title" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <Button
          type="button"
          :to="{ name: 'Polls' }"
          class="box text-gray-700 dark:text-gray-300 mr-2 flex"
        >
          {{ $t('text.back') }}
        </Button>
        <Button
          type="button"
          @click="onSubmit"
          :loading="isLoading"
          class="w-24 bg-theme-1 text-white"
        >
          {{ $t('text.save') }}
        </Button>
      </div>
    </div>
    <div class="intro-y mt-5">
      <form @submit.prevent="onSubmit">
        <div class="grid grid-cols-12 gap-6">
          <div class="intro-y col-span-12 sm:col-span-12 lg:col-span-4 xl:col-span-4">
            <div class="intro-y box">
              <div class="p-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class="font-medium text-base">{{ $t('nav.pollShow') }}</h2>
              </div>
              <div class="py-4 px-5 relative">
                <div class="grid grid-cols-12">
                  <div class="intro-y col-span-12 sm:col-span-12">
                    <InputString
                      :label="$t('text.title')"
                      v-model.trim="$v.form.title.$model"
                      :vuelidate="$v.form.title"
                      input-class="input-poll-title"
                    />
                    <InputString
                      :label="$t('text.description')"
                      v-model.trim="$v.form.description.$model"
                      :vuelidate="$v.form.description"
                      input-class="input-poll-description"
                      is-textarea
                    />
                    <InputString
                      :label="$t('text.respondent')"
                      :value="me.firstName"
                      disabled="disabled"
                      input-class="input-poll-respondent"
                    />
                    <InputDate
                      v-model="$v.form.closingDate.$model"
                      :vuelidate="$v.form.closingDate"
                      input-class="input-poll-date"
                      :label="$t('table.closingDate')"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="box mt-5" style="z-index: 1;">
              <div class="intro-y p-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class="font-medium text-base">{{ $t('nav.buildingShow') }}</h2>
              </div>
              <div class="intro-y py-4 px-5">
                <InputSelect
                  v-model.number="$v.form.buildingId.$model"
                  :options="buildingList"
                  :label="$t('text.selectBuilding')"
                  value-key="id"
                  :vuelidate="$v.form.buildingId"
                  input-class="input-poll-building"
                  :disabled="isLoadingBuildings"
                  @input="onChangeBuilding()"
                />
              </div>
            </div>
          </div>
          <div
            v-if="form.buildingId"
            class="intro-y col-span-12 sm:col-span-12 lg:col-span-4 xl:col-span-4"
          >
            <div class="intro-y box overflow-y-scroll max-h-96">
              <div class="p-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class="font-medium text-base">
                  {{ $t('text.apartmentsTitle') }}
                </h2>
              </div>
              <Loading v-if="isLoadingApartments" />
              <template v-else>
                <div class="py-4 px-5 border-b border-gray-200 dark:border-dark-5">
                  <div class="w-full relative">
                    <SearchIcon
                      class="w-4 h-4 absolute my-auto inset-y-0 ml-3 left-0 z-10 text-gray-600 dark:text-gray-300"
                    />
                    <input
                      type="text"
                      v-model="search"
                      :placeholder="$t('table.searchPlaceholder')"
                      class="input-poll-search-apartment pl-10 pr-24 input input-lg w-full border"
                    />
                  </div>
                </div>
                <label
                  v-if="!search"
                  class="py-4 px-5 border-b border-gray-200 dark:border-dark-5 flex items-center cursor-pointer hover:bg-gray-100 dark:hover:bg-dark-2"
                >
                  <input
                    class="input-poll-select-all-apartment input border border-gray-500"
                    type="checkbox"
                    @change="toggleSelectAll($event.target.checked)"
                  />
                  <div class="ml-3 font-bold">
                    {{ $t('text.selectAll') }}
                  </div>
                </label>
                <template v-for="(apartment, index) in filteredApartmentList">
                  <label
                    :key="index"
                    class="py-2 px-5 border-b border-gray-200 dark:border-dark-5 flex items-center cursor-pointer hover:bg-gray-100 dark:hover:bg-dark-2"
                  >
                    <div class="mr-3">
                      <input
                        class="input-poll-toggler-apartment input border border-gray-500"
                        :checked="checkItemInArray(apartment, selectedApartments)"
                        @change="toggleItem(apartment, selectedApartments)"
                        type="checkbox"
                      />
                    </div>
                    <ApartmentAndCode :key="index" :item="apartment" />
                  </label>
                </template>
              </template>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-12 lg:col-span-4 xl:col-span-4">
            <div class="intro-y box">
              <div class="py-4 px-5 relative">
                <div class="grid grid-cols-12">
                  <div class="intro-y col-span-12 sm:col-span-12">
                    <InputString
                      :placeholder="$t('text.question')"
                      v-model.trim="pollField.question"
                      is-textarea
                      input-class="input-poll-question"
                    />
                    <div class="ml-10">
                      <ol class="list-decimal">
                        <li
                          v-for="(item, index) in pollField.answers"
                          :key="index"
                          class="intro-x mt-2"
                        >
                          <InputString
                            v-model.trim="item.answer"
                            @keydownenter="addPollField(index)"
                            :ref="'answer' + index"
                            :class="[`input-poll-answer-${index}`, { 'pr-10': index > 0 }]"
                          ></InputString>
                          <div
                            v-if="index != 0"
                            @click="removePollField(item)"
                            class="cursor-pointer absolute top-0 right-0 rounded-r w-10 h-full flex items-center justify-center text-gray-600 font-medium"
                          >
                            <xIcon class="w-4 h-5 text-gray-600 font-bold" />
                          </div>
                        </li>
                      </ol>
                    </div>
                    <div class="ml-10 mt-2">
                      <Button
                        @click="addPollField(pollField.answers.length - 1)"
                        class="w-full bg-theme-14 text-theme-10"
                      >
                        {{ $t('text.pollAnswerAdd') }}
                      </Button>
                    </div>
                    <div class="mt-5">
                      <Button
                        v-if="selectedPollId === null"
                        @click="addPollQuestion()"
                        type="button"
                        class="w-full bg-theme-1 text-white"
                      >
                        {{ $t('text.add') }}
                      </Button>
                      <Button
                        v-if="selectedPollId !== null"
                        @click="modifyPollQuestion()"
                        type="button"
                        class="w-full bg-theme-1 text-white"
                      >
                        {{ $t('table.edit') }}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="form.questions.length > 0" class="intro-y box mt-4">
              <div class="py-4 px-5 relative">
                <PollQuestion
                  v-for="(item, index) in form.questions"
                  :key="index"
                  :form="form"
                  @modifyPoll="modifyPoll"
                  :item="item"
                  :class="['text-center lg:text-left overflow-hidden', { 'mt-4': index > 0 }]"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { assign } from 'lodash';
import { POLLS_SHOW, POLLS_ADD, POLLS_UPDATE } from '@/store/actions/polls';
import { APARTMENT_LIST_REQUEST } from '@/store/actions/apartment';
import { BUILDING_LIST_REQUEST } from '@/store/actions/building';
import { USER_LIST_REQUEST } from '@/store/actions/user';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import Button from '@/components/ui/button/Button';
import PageHeader from '@/components/ui/PageHeader';
import InputString from '@/components/ui/input/InputString';
import InputSelect from '@/components/ui/input/InputSelect';
import InputDate from '@/components/ui/input/InputDate';
import ApartmentAndCode from '@/components/apartment/ApartmentAndCode';
import { ANNOUNCEMENT_TYPES } from '@/utils/constants';
import PollQuestion from '@/components/polls/PollQuestion';

export default {
  mixins: [validationMixin],

  components: {
    Button,
    PageHeader,
    InputString,
    InputSelect,
    ApartmentAndCode,
    InputDate,
    PollQuestion,
  },

  props: {
    business: Object,
  },

  validations: {
    form: {
      title: {
        required,
      },
      description: {
        required,
      },
      closingDate: {
        required,
      },
      buildingId: {
        required,
      },
      questions: {
        required,
      },
    },
    selectedApartments: {
      required,
    },
  },

  data() {
    return {
      form: {
        title: '',
        closingDate: '',
        description: '',
        assignedTo: null,
        buildingId: '',
        apartmentId: null,
        questions: [],
        apartments: [],
      },
      pollField: {
        question: '',
        answers: [
          {
            answer: '',
          },
        ],
      },
      selectedApartments: [],
      pollsTypes: ANNOUNCEMENT_TYPES,
      selectedPollId: null,
      defaultTableData: {
        filters: {},
        sort: {
          field: 'id',
          type: 'desc',
        },
        page: 1,
        perPage: 100,
      },
      search: '',
    };
  },

  computed: {
    ...mapGetters([
      'me',
      'pollStatus',
      'poll',
      'buildingStatus',
      'buildingList',
      'apartmentList',
      'apartmentListStatus',
      'userList',
      'userListStatus',
    ]),
    isLoading() {
      return this.pollStatus === 'loading';
    },
    isLoadingBuildings() {
      return this.buildingStatus === 'loading';
    },
    isLoadingApartments() {
      return this.apartmentListStatus === 'loading';
    },
    userListLoading() {
      return this.userListStatus === 'loading';
    },
    title() {
      return this.$route.name === 'PollCreate'
        ? this.$t(`nav.pollCreate`)
        : this.$t(`nav.pollUpdate`);
    },
    filteredApartmentList() {
      return this.apartmentList.filter(apartment => apartment.number.includes(this.search));
    },
  },

  async created() {
    await this.fetchData();
    this.form.assignedTo = this.me.id;
  },

  methods: {
    async fetchData() {
      if (this.$route.params.id) {
        const payload = { id: this.$route.params.id };
        await this.$store.dispatch(POLLS_SHOW, payload);
        if (this.poll) {
          const {
            title,
            closingDate,
            description,
            assignedTo,
            buildingId,
            questions,
            apartments,
            assignedUser,
          } = this.poll;
          this.form = {
            title,
            closingDate,
            description,
            assignedTo,
            buildingId,
            apartmentId: [],
            questions: questions.map(item => {
              return {
                ...item,
                answers: item.answers.map(answer => answer),
              };
            }),
            assignedUser,
            apartments: apartments.map(item => item),
          };
          this.selectedApartments = apartments.map(item => item);

          this.getApartmentList();
        }
      }
      await this.fetchUserList();
      this.fetchBuildingList();
    },
    async fetchBuildingList() {
      const payload = {
        businessId: this.business.id,
        data: this.defaultTableData,
      };

      await this.$store.dispatch(BUILDING_LIST_REQUEST, payload);
    },
    async getApartmentList() {
      const data = this.defaultTableData;
      data.filters['filter[buildingId]'] = this.form.buildingId;
      data.filters['filter[isParking]'] = 0;

      const payload = {
        businessId: this.business.id,
        data,
      };

      await this.$store.dispatch(APARTMENT_LIST_REQUEST, payload);
    },
    async fetchUserList() {
      const data = { page: 1, perPage: 100 };
      const payload = { businessId: this.business.id, data };
      await this.$store.dispatch(USER_LIST_REQUEST, payload);
    },
    async onChangeBuilding() {
      this.selectedApartments = [];
      await this.getApartmentList();
    },
    toggleItem(object, array) {
      const index = array.findIndex(item => item.id === object.id);
      if (index > -1) {
        array.splice(index, 1);
      } else {
        array.push(object);
      }
    },
    resetPollField() {
      this.pollField = {
        question: '',
        answers: [{ answer: '' }],
      };
    },
    removePollField(object) {
      const index = this.pollField.answers.findIndex(item => item === object);
      if (index > -1) {
        this.pollField.answers.splice(index, 1);
      }
    },
    addPollQuestion() {
      this.form.questions.push(this.pollField);
      this.resetPollField();
    },
    addPollField(index) {
      const object = { answer: '' };
      this.pollField.answers.push(object);
      this.$nextTick(() => {
        this.$refs[`answer${index + 1}`][0].focus();
      });
    },
    modifyPoll(object) {
      this.selectedPollId = this.form.questions.findIndex(item => item === object);
      this.pollField = object;
    },
    modifyPollQuestion() {
      this.form.questions[this.selectedPollId] = this.pollField;
      this.selectedPollId = null;
      this.resetPollField();
    },
    checkItemInArray(object, array) {
      const index = array.findIndex(item => item.id === object.id);
      if (index > -1) {
        return true;
      }
      return false;
    },
    toggleSelectAll(checked) {
      if (checked) {
        this.selectedApartments = [...this.apartmentList];
      } else {
        this.selectedApartments = [];
      }
    },
    async onSubmit() {
      if (this.$v) {
        this.$v.selectedApartments.$touch();
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          this.$toasted.show(this.$t('toast.failedForm'), { type: 'error' });
          return;
        }
      }
      try {
        this.form.apartmentId = this.selectedApartments.map(item => item.id);
        // this.form.apartmentId = '5323';
        // this.form.buildingId = '16';
        this.form.apartments = this.form.apartmentId;
        const payload = {
          businessId: this.business.id,
          data: this.form,
        };
        if (this.$route.params.id) {
          assign(payload, { id: this.poll.id });
          await this.$store.dispatch(POLLS_UPDATE, payload);
        } else {
          await this.$store.dispatch(POLLS_ADD, payload);
        }
        if (this.pollStatus === 'success') {
          this.$router.push({ name: 'Polls' });
          this.$toasted.show(this.$t('toast.success'), { type: 'success' });
        }
      } catch (error) {
        this.$toasted.show(error.message, { type: 'error' });
      }
    },
  },
};
</script>
<style>
.max-h-96 {
  max-height: 36rem !important;
}
</style>
