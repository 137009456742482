<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <PageHeader class="text-lg font-medium mr-auto" :title="title" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <Button
          type="button"
          :to="{ name: 'InvoiceIncome' }"
          class="box text-gray-700 dark:text-gray-300 mr-2 flex"
        >
          {{ $t('text.back') }}
        </Button>
        <Button
          type="button"
          @click="onSubmit"
          :loading="isLoading"
          class="w-24 bg-theme-1 text-white"
        >
          {{ $t('text.save') }}
        </Button>
      </div>
    </div>
    <div class="intro-y mt-5">
      <form @submit.prevent="onSubmit">
        <div class="grid grid-cols-12 gap-6">
          <div class="intro-y col-span-12 sm:col-span-12 lg:col-span-6 xl:col-span-8">
            <div class="grid grid-cols-12 gap-6">
              <div class="intro-y col-span-12 sm:col-span-12 lg:col-span-12 xl:col-span-6">
                <div class="intro-y box">
                  <div class="p-5 border-b border-gray-200 dark:border-dark-5">
                    <h2 class="font-medium text-base">
                      {{ $t('nav.main') }}
                    </h2>
                  </div>
                  <div class="p-5">
                    <InputSelect
                      v-model.number="$v.form.buildingId.$model"
                      :firstItemLabel="$t('text.all')"
                      firstItemValue="ALL"
                      :options="buildings"
                      :label="$t('text.buildingNameLong')"
                      value-key="id"
                      :vuelidate="$v.form.buildingId"
                      :disabled="buildingListLoading || $route.params.invoiceId"
                      input-class="input-invoice-building"
                      @input="onChangeBuilding()"
                    />

                    <InputSelect
                      v-if="$route.params.invoiceId"
                      v-model.number="form.apartmentId"
                      :firstItemLabel="$t('text.all')"
                      firstItemValue="ALL"
                      :options="apartments"
                      :label="$t('text.doorNumber')"
                      value-key="id"
                      label-key="number"
                      :disabled="true"
                      input-class="input-invoice-apartment"
                    />

                    <InputMonth
                      v-model="$v.form.startDate.$model"
                      :vuelidate="$v.form.startDate"
                      :label="$t('text.date')"
                      input-class="input-invoice-month"
                    />

                    <InputCheckbox
                      v-model="form.sendSms"
                      :label="$t('text.sendSms')"
                      input-class="input-invoice-send-sms"
                      @input="input"
                    />
                    <div class="pt-5" v-if="isSms">
                      <p>
                        {{ $t('text.smsBalance') }}: <b>{{ business.smsCount }}</b>
                      </p>
                      <p>
                        {{ $t('text.nowTotalSendingSMS') }}: <b>{{ smsCount }}</b>
                      </p>
                      <p class="pt-5 red" v-if="isAlert">
                        <b>{{ $t('smsConfirmDialog.errorAlert') }}</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="intro-y col-span-12 sm:col-span-12 lg:col-span-12 xl:col-span-6">
                <div class="intro-y box">
                  <div class="p-5 border-b border-gray-200 dark:border-dark-5">
                    <h2 class="font-medium text-base">{{ $t('nav.serviceProducts') }}</h2>
                  </div>
                  <Loading v-if="productListLoading" />
                  <div v-else class="service-product-list">
                    <template v-for="(product, index) in serviceProductList">
                      <label
                        :key="index"
                        class="py-4 px-5 border-b border-gray-200 dark:border-dark-5 flex items-center cursor-pointer hover:bg-gray-100 dark:hover:bg-dark-2"
                      >
                        <div class="mr-3">
                          <input
                            class="input border border-gray-500"
                            :checked="checkProductInArray(product)"
                            @change="toggleProduct(product)"
                            type="checkbox"
                          />
                        </div>
                        <div>
                          <p
                            class="whitespace-nowrap font-medium"
                            :class="{ 'text-gray-600': !checkProductInArray(product) }"
                          >
                            {{ product.name }}
                          </p>
                          <div class="text-gray-600 text-xs whitespace-nowrap">
                            {{ product.price | currency }}
                            <span class="ml-3">
                              {{
                                product.priceType === 'SQUARE_PRICE'
                                  ? $t('text.perSquareMeter')
                                  : $t('text.wholePrice')
                              }}
                            </span>
                          </div>
                        </div>
                      </label>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="form.buildingId !== 'ALL' && $route.name === 'InvoiceCreate'"
            class="intro-y col-span-12 sm:col-span-12 lg:col-span-6 xl:col-span-4"
          >
            <div class="intro-y col-span-12 sm:col-span-12 lg:col-span-6 xl:col-span-4">
              <div class="intro-y box overflow-y-scroll max-h-96">
                <div class="p-5 border-b border-gray-200 dark:border-dark-5">
                  <h2 class="font-medium text-base">
                    {{ $t('nav.apartmentShow') }}
                  </h2>
                </div>
                <Loading v-if="apartmentListLoading" />
                <template v-else>
                  <div class="py-4 px-5 border-b border-gray-200 dark:border-dark-5">
                    <div class="w-full relative">
                      <SearchIcon
                        class="w-4 h-4 absolute my-auto inset-y-0 ml-3 left-0 z-10 text-gray-600 dark:text-gray-300"
                      />
                      <input
                        type="text"
                        v-model="search"
                        :placeholder="$t('table.searchPlaceholder')"
                        class="input-invoice-apartment-search pl-10 pr-24 input input-lg w-full border"
                      />
                    </div>
                  </div>
                  <label
                    v-if="!search"
                    class="py-4 px-5 border-b border-gray-200 dark:border-dark-5 flex items-center cursor-pointer hover:bg-gray-100 dark:hover:bg-dark-2"
                  >
                    <input
                      class="input-invoice-toggle-apartment input border border-gray-500"
                      type="checkbox"
                      :checked="selectedApartments.length === apartments.length"
                      @change="toggleAllApartmentSelect"
                    />
                    <div class="ml-3 font-bold">
                      {{ $t('text.selectAll') }}
                    </div>
                  </label>
                  <div class="apartment-list">
                    <template v-for="(apartment, index) in filteredApartmentList">
                      <label
                        :key="index"
                        class="py-2 px-5 border-b border-gray-200 dark:border-dark-5 flex items-center cursor-pointer hover:bg-gray-100 dark:hover:bg-dark-2"
                      >
                        <div class="mr-3">
                          <input
                            class="input border border-gray-500"
                            :checked="checkApartmentInArray(apartment)"
                            @change="toggleApartment(apartment)"
                            type="checkbox"
                          />
                        </div>
                        <ApartmentAndParking :key="index" :item="apartment" />
                      </label>
                    </template>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  INVOICE_ADD,
  INVOICE_UPDATE,
  INVOICE_REQUEST,
  INVOICE_CHECK_SMS,
} from '@/store/actions/invoice';
import { BUILDING_LIST_REQUEST } from '@/store/actions/building';
import { APARTMENT_LIST_REQUEST } from '@/store/actions/apartment';
import { SERVICE_PRODUCT_LIST_REQUEST } from '@/store/actions/serviceProduct';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import Button from '@/components/ui/button/Button';
import PageHeader from '@/components/ui/PageHeader';
import InputSelect from '@/components/ui/input/InputSelect';
import InputMonth from '@/components/ui/input/InputMonth';
import InputCheckbox from '@/components/ui/input/InputCheckbox';
import ApartmentAndParking from '@/components/apartment/ApartmentAndParking';
import { assign } from 'lodash';

const ALL_KEY = 'ALL';

export default {
  mixins: [validationMixin],

  components: { Button, PageHeader, InputSelect, InputMonth, InputCheckbox, ApartmentAndParking },

  props: {
    business: Object,
  },

  validations: {
    form: {
      buildingId: {
        required,
      },
      startDate: {
        required,
      },
    },
    selectedProducts: {
      required,
    },
    selectedApartments: {
      required,
    },
  },

  data() {
    return {
      form: {
        buildingId: ALL_KEY,
        apartmentId: ALL_KEY,
        startDate: '',
        serviceProducts: [],
        sendSms: 0,
      },
      selectedProducts: [],
      selectedApartments: [],
      defaultTableData: {
        filters: {},
        sort: {
          field: 'id',
          type: 'desc',
        },
        page: 1,
        perPage: 1000,
      },
      buildings: [],
      apartments: [],
      productListLoading: true,
      smsCount: '',
      isSms: false,
      isAlert: false,
      search: '',
    };
  },

  computed: {
    ...mapGetters([
      'invoiceStatus',
      'invoice',
      'buildingStatus',
      'buildingList',
      'apartmentList',
      'apartmentListStatus',
      'serviceProductList',
      'serviceProductStatus',
      'invoicesSmsStatus',
      'invoicesSms',
    ]),
    isLoading() {
      return this.invoiceStatus === 'loading';
    },
    buildingListLoading() {
      return this.buildingStatus === 'loading';
    },
    apartmentListLoading() {
      return this.apartmentListStatus === 'loading';
    },
    title() {
      return this.$route.name === 'InvoiceCreate'
        ? this.$t(`nav.invoiceCreate`)
        : this.$t(`nav.invoiceUpdate`);
    },
    filteredApartmentList() {
      return this.apartments.filter(apartment => apartment.number.includes(this.search));
    },
  },

  watch: {
    $route() {
      this.productListLoading = true;
      this.fetchData();
    },
  },

  async created() {
    await this.fetchData();
    await this.fetchBuildingList();
  },

  methods: {
    async fetchData() {
      let oldServiceProducts = [];
      if (this.$route.params.invoiceId) {
        const payload = { businessId: this.business.id, id: this.$route.params.invoiceId };
        await this.$store.dispatch(INVOICE_REQUEST, payload);
        const { invoice } = this;
        if (invoice) {
          this.form = {
            buildingId: invoice.buildingId,
            apartmentId: invoice.apartmentId,
            startDate: invoice.startDate,
            serviceProducts: [],
          };
          oldServiceProducts = invoice.serviceProducts.map(item => item.serviceProductId);
        }
      }
      if (this.$route.params.invoiceId) {
        await this.fetchApartmentList();
      }
      await this.fetchProductList();

      if (this.$route.params.invoiceId) {
        this.serviceProductList.forEach(item => {
          if (oldServiceProducts.indexOf(item.id) > -1) {
            this.selectedProducts.push(item);
          }
        });
      }
    },

    async fetchBuildingList() {
      const payload = {
        businessId: this.business.id,
        data: this.defaultTableData,
      };

      await this.$store.dispatch(BUILDING_LIST_REQUEST, payload);
      if (this.buildingStatus === 'success') {
        this.buildings = this.buildingList.map(item => item);
      }
    },

    async fetchApartmentList() {
      const data = this.defaultTableData;

      data.filters['filter[buildingId]'] = this.form.buildingId;
      data.filters['filter[isParking]'] = 0;

      const payload = {
        businessId: this.business.id,
        data,
      };

      await this.$store.dispatch(APARTMENT_LIST_REQUEST, payload);
      if (this.apartmentListStatus === 'success') {
        this.apartments = this.apartmentList.map(item => item);
      }
    },

    async fetchProductList() {
      const payload = {
        businessId: this.business.id,
        data: this.defaultTableData,
      };

      await this.$store.dispatch(SERVICE_PRODUCT_LIST_REQUEST, payload);
      if (this.serviceProductStatus === 'success') {
        if (this.$route.name === 'InvoiceCreate') {
          this.selectedProducts = this.serviceProductList.map(item => item);
        }
        this.productListLoading = false;
      }
    },

    async onChangeBuilding() {
      this.selectedApartments = [];
      await this.fetchApartmentList();
    },

    toggleProduct(product) {
      const index = this.selectedProducts.findIndex(item => item.id === product.id);
      if (index > -1) {
        this.selectedProducts.splice(index, 1);
      } else {
        this.selectedProducts.push(product);
      }
    },

    checkProductInArray(product) {
      const index = this.selectedProducts.findIndex(item => item.id === product.id);
      if (index > -1) {
        return true;
      }
      return false;
    },

    toggleApartment(apartment) {
      const index = this.selectedApartments.findIndex(item => item.id === apartment.id);
      if (index > -1) {
        this.selectedApartments.splice(index, 1);
      } else {
        this.selectedApartments.push(apartment);
      }
    },

    toggleAllApartmentSelect() {
      this.selectedApartments =
        this.selectedApartments.length === this.apartments.length
          ? []
          : this.apartments.map(item => item);
    },

    checkApartmentInArray(apartment) {
      const index = this.selectedApartments.findIndex(item => item.id === apartment.id);
      if (index > -1) {
        return true;
      }
      return false;
    },
    async input(val) {
      if (val !== 0) {
        this.isSms = true;
        if (this.form.buildingId !== '' && this.form.startDate !== '') {
          const payload = {
            businessId: this.business.id,
            type: 'INVOICE',
            data: {
              buildingId: this.form.buildingId,
              startDate: this.form.startDate,
              apartments: this.selectedApartments,
            },
          };
          await this.$store.dispatch(INVOICE_CHECK_SMS, payload);
          if (this.invoicesSmsStatus === 'success') {
            this.smsCount = this.invoicesSms.totalSmsCount;
            if (this.business.smsCount < this.smsCount) {
              this.isAlert = true;
            }
          }
        }
      } else {
        this.isSms = false;
        this.isAlert = false;
      }
    },
    async onSubmit() {
      if (this.$v) {
        this.$v.selectedProducts.$touch();
        this.$v.form.$touch();
        if (this.$v.form.$anyError || this.$v.selectedProducts.$anyError) {
          if (this.$v.form.$anyError) {
            this.$toasted.show(this.$t('toast.failedForm'), { type: 'error' });
            return;
          }
          this.$toasted.show(this.$t('toast.requiredProducts'), { type: 'error' });
          return;
        }
      }
      try {
        this.form.serviceProducts = this.selectedProducts.map(item => {
          return { id: item.id, price: item.price };
        });
        if (this.$route.params.invoiceId) {
          const payload = {
            businessId: this.business.id,
            id: this.$route.params.invoiceId,
            data: this.form,
          };
          await this.$store.dispatch(INVOICE_UPDATE, payload);
        } else {
          assign(this.form, { apartments: this.selectedApartments });
          const payload = {
            businessId: this.business.id,
            data: this.form,
          };
          await this.$store.dispatch(INVOICE_ADD, payload);
        }
        if (this.invoiceStatus === 'success') {
          this.$router.push({ name: 'InvoiceIncome' });
          this.$toasted.show(this.$t('toast.success'), { type: 'success' });
        }
      } catch (error) {
        this.$toasted.show(error.message, { type: 'error' });
      }
    },
  },
};
</script>
