import Client from '@/clients/apiClient';
import scriptMixin from '@/mixins/scriptMixin';
import {
  FIREBASE_INIT_APP,
  FIREBASE_INIT_MESSAGING,
  FIREBASE_MESSAGING_SUBSCRIBE,
  FIREBASE_MESSAGING_TOKEN,
  FIREBASE_MESSAGING_UNSUBSCRIBE,
  FIREBASE_SUBSCRIBED,
  FIREBASE_SUBSCRIBING,
  FIREBASE_UNSUBSCRIBING,
} from '@/store/actions/firebase';

const state = {
  firebaseConfig: {
    apiKey: process.env.VUE_APP_FIREBAE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBAE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBAE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBAE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBAE_MESSAGING_SENDERID,
    appId: process.env.VUE_APP_FIREBAE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBAE_MEASUREMENT_ID,
  },
  firebaseMessagingSubscribing: false,
  firebaseMessagingUnsubscribing: false,
  firebaseMessagingIsSubscribed: false,
  firebaseMessagingToken: null,
};

const getters = {};

const actions = {
  [FIREBASE_INIT_APP]: async ({ state }) => {
    return scriptMixin.methods
      .$importScript('https://www.gstatic.com/firebasejs/8.6.8/firebase-app.js')
      .then(() => {
        const { firebase } = window;
        firebase.initializeApp(state.firebaseConfig);
      });
  },
  [FIREBASE_INIT_MESSAGING]: async () => {
    return scriptMixin.methods.$importScript(
      'https://www.gstatic.com/firebasejs/8.6.8/firebase-messaging.js'
    );
  },
  [FIREBASE_MESSAGING_SUBSCRIBE]: async ({ commit, state }) => {
    if (state.firebaseMessagingIsSubscribed) return Promise.resolve();

    commit(FIREBASE_SUBSCRIBING, true);
    const messaging = window.firebase.messaging();

    return messaging
      .requestPermission()
      .then(() => messaging.getToken())
      .then(token => {
        commit(FIREBASE_MESSAGING_TOKEN, token);
        return Client.post('users/user-token', { os: 'web', token });
      })
      .then(() => {
        commit(FIREBASE_SUBSCRIBED, true);
      })
      .finally(() => commit(FIREBASE_SUBSCRIBING, false));
    // .catch(err => console.fi('test firebase =>', err));
  },
  [FIREBASE_MESSAGING_UNSUBSCRIBE]: async ({ commit }) => {
    commit(FIREBASE_UNSUBSCRIBING, true);
    const messaging = window.firebase.messaging();
    return messaging
      .getToken()
      .then(token => Client.delete('users/user-token', { data: { token } }))
      .finally(() => commit(FIREBASE_UNSUBSCRIBING, false));
    // .catch(err => console.fi('test firebase =>', err));
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  [FIREBASE_SUBSCRIBING]: (state, val) => {
    state.firebaseMessagingSubscribing = val;
  },
  [FIREBASE_UNSUBSCRIBING]: (state, val) => {
    state.firebaseMessagingSubscribing = val;
  },
  [FIREBASE_MESSAGING_TOKEN]: (state, val) => {
    state.firebaseMessagingToken = val;
  },
  [FIREBASE_SUBSCRIBED]: (state, val) => {
    state.firebaseMessagingIsSubscribed = val;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
