<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <PageHeader class="text-lg font-medium mr-auto" :title="title" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <Button
          type="button"
          :to="{ name: 'Announcements' }"
          class="box text-gray-700 dark:text-gray-300 mr-2 flex"
        >
          {{ $t('text.back') }}
        </Button>
        <Button
          type="button"
          @click="onSubmit"
          :loading="isLoading"
          class="w-24 bg-theme-1 text-white"
        >
          {{ $t('text.save') }}
        </Button>
      </div>
    </div>
    <div class="intro-y mt-5">
      <form @submit.prevent="onSubmit">
        <div class="grid grid-cols-12 gap-6">
          <div class="intro-y col-span-12 sm:col-span-12 lg:col-span-5 xl:col-span-5">
            <div class="intro-y box">
              <div class="p-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class="font-medium text-base">{{ $t('nav.announcementShow') }}</h2>
              </div>
              <div class="py-4 px-5 relative">
                <div class="grid grid-cols-12">
                  <div class="intro-y col-span-12 sm:col-span-12">
                    <InputString
                      :label="$t('text.title')"
                      v-model.trim="$v.form.title.$model"
                      :vuelidate="$v.form.title"
                      input-class="input-announce-title"
                    />
                    <InputSelect
                      :label="$t('text.category')"
                      v-model.number="$v.form.announcementCategoryId.$model"
                      :options="announcementCategories"
                      value-key="id"
                      :vuelidate="$v.form.announcementCategoryId"
                      input-class="input-announce-category"
                    />
                    <InputString
                      :label="$t('text.description')"
                      v-model.trim="$v.form.description.$model"
                      :vuelidate="$v.form.description"
                      input-class="input-announce-description"
                      is-textarea
                    />
                    <InputString
                      :label="$t('text.weblink')"
                      input-class="input-announce-weblink"
                      v-model="form.weblink"
                    />
                    <InputString
                      :label="$t('text.businessPhoneNumber')"
                      v-model="$v.form.contactNumber.$model"
                      :vuelidate="$v.form.contactNumber"
                      input-class="input-announce-phone"
                      type="number"
                    />
                    <InputSelect
                      :label="$t('text.status')"
                      v-model.trim="$v.form.status.$model"
                      :options="announcementsTypes"
                      value-key="id"
                      :vuelidate="$v.form.status"
                      input-class="input-announce-status"
                    />
                    <InputString
                      :label="$t('text.respondent')"
                      :value="me.firstName"
                      disabled="disabled"
                      input-class="input-announce-first-name"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="box mt-5">
              <div class="intro-y p-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class="font-medium text-base">{{ $t('text.apartmentsTitle') }}</h2>
              </div>
              <div class="intro-y py-4 px-5">
                <InputSelect
                  v-model.number="$v.form.businessId.$model"
                  :options="businessList"
                  :label="$t('text.selectBusiness')"
                  value-key="id"
                  :vuelidate="$v.form.businessId"
                  :disabled="isLoadingBusiness"
                  input-class="input-select-announce-business"
                  @input="onChangeBusiness()"
                />
              </div>
            </div>
          </div>
          <div
            v-if="form.businessId"
            class="intro-y col-span-12 sm:col-span-12 lg:col-span-4 xl:col-span-4"
          >
            <div class="intro-y box">
              <div class="p-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class="font-medium text-base">{{ $t('text.buildings') }}</h2>
              </div>
              <Loading v-if="isLoadingBuildings" />
              <template v-else>
                <label
                  class="py-4 px-5 border-b border-gray-200 dark:border-dark-5 flex items-center cursor-pointer hover:bg-gray-100 dark:hover:bg-dark-2"
                >
                  <div class="mr-3">
                    <input
                      class="input-announce-select-all input border border-gray-500"
                      @change="toggleSelectAllBuilding($event.target.checked)"
                      type="checkbox"
                    />
                  </div>
                  {{ $t('text.selectAll') }}
                </label>
                <template v-for="(building, index) in buildingList">
                  <label
                    :key="index"
                    class="py-4 px-5 border-b border-gray-200 dark:border-dark-5 flex items-center cursor-pointer hover:bg-gray-100 dark:hover:bg-dark-2"
                  >
                    <div class="mr-3">
                      <input
                        class="input-announce-check-building input border border-gray-500"
                        :checked="checkItemInArray(building, selectedBuildings)"
                        @change="checkBuilding(building, selectedBuildings)"
                        type="checkbox"
                      />
                    </div>
                    <BuildingFullname
                      :item="building"
                      class="lg:ml-3 mt-3 lg:mt-0 overflow-hidden"
                    />
                  </label>
                </template>
              </template>
            </div>
          </div>
          <div
            v-if="selectedBuildings.length === 1"
            class="intro-y col-span-12 sm:col-span-12 lg:col-span-3 xl:col-span-3"
          >
            <div class="intro-y box overflow-y-scroll max-h-screen">
              <div class="p-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class="font-medium text-base">
                  {{ $t('text.apartmentsTitle') }}
                </h2>
              </div>
              <Loading v-if="isLoadingApartments" />
              <template v-else>
                <div class="py-4 px-5 border-b border-gray-200 dark:border-dark-5">
                  <div class="w-full relative">
                    <SearchIcon
                      class="w-4 h-4 absolute my-auto inset-y-0 ml-3 left-0 z-10 text-gray-600 dark:text-gray-300"
                    />
                    <input
                      type="text"
                      v-model="search"
                      :placeholder="$t('table.searchPlaceholder')"
                      class="input-announce-search-apartments pl-10 pr-24 input input-lg w-full border"
                    />
                  </div>
                </div>
                <label
                  v-if="!search"
                  class="py-4 px-5 border-b border-gray-200 dark:border-dark-5 flex items-center cursor-pointer hover:bg-gray-100 dark:hover:bg-dark-2"
                >
                  <input
                    class="input-announce-toggle-select-all input border border-gray-500"
                    type="checkbox"
                    @change="toggleSelectAll($event.target.checked)"
                  />
                  <div class="ml-3 font-bold">
                    {{ $t('text.selectAll') }}
                  </div>
                </label>
                <template v-for="(apartment, index) in filteredApartmentList">
                  <label
                    :key="index"
                    class="py-2 px-5 border-b border-gray-200 dark:border-dark-5 flex items-center cursor-pointer hover:bg-gray-100 dark:hover:bg-dark-2"
                  >
                    <div class="mr-3">
                      <input
                        class="input-announce-toggle-apartment input border border-gray-500"
                        :checked="checkItemInArray(apartment, selectedApartments)"
                        @change="toggleItem(apartment, selectedApartments)"
                        type="checkbox"
                      />
                    </div>
                    <ApartmentAndCode :key="index" :item="apartment" />
                  </label>
                </template>
              </template>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { assign } from 'lodash';
import { mapGetters } from 'vuex';
import {
  ANNOUNCEMENTS_SHOW,
  ANNOUNCEMENTS_ADD,
  ANNOUNCEMENTS_CATEGORIES_REQUEST,
  ANNOUNCEMENTS_UPDATE,
} from '@/store/actions/announcements';
import { APARTMENT_LIST_REQUEST } from '@/store/actions/apartment';
import { BUILDING_LIST_REQUEST } from '@/store/actions/building';
import { BUSINESS_LIST_REQUEST } from '@/store/actions/business';
import { ALL_USER_LIST_REQUEST } from '@/store/actions/user';
import { validationMixin } from 'vuelidate';
import { required, maxLength } from 'vuelidate/lib/validators';
import Button from '@/components/ui/button/Button';
import PageHeader from '@/components/ui/PageHeader';
import InputString from '@/components/ui/input/InputString';
import InputSelect from '@/components/ui/input/InputSelect';
import ApartmentAndCode from '@/components/apartment/ApartmentAndCode';
import { ANNOUNCEMENT_TYPES } from '@/utils/constants';
import BuildingFullname from '@/components/building/BuildingFullname';

export default {
  mixins: [validationMixin],

  components: {
    Button,
    PageHeader,
    InputString,
    InputSelect,
    ApartmentAndCode,
    BuildingFullname,
  },

  props: {
    business: Object,
  },

  data() {
    return {
      form: {
        title: '',
        description: '',
        status: '',
        assignedTo: null,
        announcementCategoryId: null,
        weblink: '',
        contactNumber: '',
        businessId: null,
        buildingId: null,
        apartmentId: null,
        buildings: [],
        apartments: [],
      },
      announcementsTypes: ANNOUNCEMENT_TYPES,
      selectedApartments: [],
      selectedBuildings: [],
      defaultTableData: {
        filters: {},
        sort: {
          field: 'id',
          type: 'desc',
        },
        page: 1,
        perPage: 100,
      },
      userInfo: {},
      search: '',
    };
  },

  validations: {
    form: {
      title: {
        required,
      },
      description: {
        required,
      },
      status: {
        required,
      },
      announcementCategoryId: {
        required,
      },
      businessId: {
        required,
      },
      contactNumber: {
        required,
        maxLength: maxLength(8),
      },
    },
    selectedBuildings: {
      required,
    },
  },

  computed: {
    ...mapGetters([
      'me',
      'announcementStatus',
      'announcement',
      'announcementCategories',
      'buildingStatus',
      'buildingList',
      'businessStatus',
      'businessList',
      'apartmentList',
      'apartmentListStatus',
      'userList',
      'userListStatus',
    ]),
    isLoading() {
      return this.announcementStatus === 'loading';
    },
    isLoadingBuildings() {
      return this.buildingStatus === 'loading';
    },
    isLoadingBusiness() {
      return this.businessStatus === 'loading';
    },
    isLoadingApartments() {
      return this.apartmentListStatus === 'loading';
    },
    userListLoading() {
      return this.userListStatus === 'loading';
    },
    title() {
      return this.$route.name === 'AnnouncementCreate'
        ? this.$t(`nav.announcementCreate`)
        : this.$t(`nav.announcementUpdate`);
    },
    filteredApartmentList() {
      return this.apartmentList.filter(apartment => apartment.number.includes(this.search));
    },
  },

  watch: {
    'form.businessId': {
      handler(businessId) {
        if (businessId) {
          this.onChangeBusiness();
        }
      },
    },
  },

  async created() {
    await this.getCategories();
    this.fetchData();
    this.form.assignedTo = this.me.id;
  },

  methods: {
    async fetchData() {
      if (this.$route.params.id) {
        const payload = { id: this.$route.params.id };
        await this.$store.dispatch(ANNOUNCEMENTS_SHOW, payload);
        if (this.announcement) {
          const {
            title,
            status,
            description,
            assignedTo,
            announcementCategoryId,
            weblink,
            contactNumber,
            businessId,
            buildingId,
            apartmentId,
            buildings,
            apartments,
          } = this.announcement;

          this.form = {
            title,
            status,
            description,
            assignedTo,
            announcementCategoryId,
            weblink,
            contactNumber,
            businessId,
            buildingId,
            apartmentId,
            buildings,
            apartments,
          };
          this.selectedBuildings = buildings;
          this.selectedApartments = apartments;
        }
      }
      await this.fetchUserList();
      await this.fetchBusinessList();

      if (
        this.$route.name === 'AnnouncementCreate' &&
        this.businessList &&
        this.businessList.length > 0
      ) {
        this.form.businessId = this.businessList[0].id;
      }
    },

    getCategories() {
      let payload = {};
      if (this.business) {
        payload = { businessId: this.business.id };
      }
      this.$store.dispatch(ANNOUNCEMENTS_CATEGORIES_REQUEST, payload);
    },

    async fetchBuildingList() {
      const payload = {
        businessId: this.form.businessId,
        data: this.defaultTableData,
      };

      await this.$store.dispatch(BUILDING_LIST_REQUEST, payload);
    },

    async fetchBusinessList() {
      const payload = {
        data: this.defaultTableData,
      };

      await this.$store.dispatch(BUSINESS_LIST_REQUEST, payload);
    },

    async getApartmentList(building) {
      const data = this.defaultTableData;
      data.filters['filter[buildingId]'] = building.id;
      data.filters['filter[isParking]'] = 0;

      const payload = {
        businessId: this.form.businessId,
        data,
      };

      await this.$store.dispatch(APARTMENT_LIST_REQUEST, payload);
    },

    async fetchUserList() {
      const data = { page: 1, perPage: 100 };
      await this.$store.dispatch(ALL_USER_LIST_REQUEST, data);
    },
    async onChangeBuilding(building) {
      this.form.apartmentId = null;
      await this.getApartmentList(building);
    },

    async onChangeBusiness() {
      this.form.buildingId = null;
      await this.fetchBuildingList();
    },
    checkBuilding(building, selectedBuildings) {
      this.toggleItem(building, selectedBuildings);
      this.onChangeBuilding(building);
    },
    toggleItem(object, array) {
      const index = array.findIndex(item => item.id === object.id);
      if (index > -1) {
        array.splice(index, 1);
      } else {
        array.push(object);
      }
    },

    checkItemInArray(object, array) {
      const index = array.findIndex(item => item.id === object.id);
      if (index > -1) {
        return true;
      }
      return false;
    },

    toggleSelectAll(checked) {
      if (checked) {
        this.selectedApartments = [...this.apartmentList];
      } else {
        this.selectedApartments = [];
      }
    },
    toggleSelectAllBuilding(checked) {
      if (checked) {
        this.selectedBuildings = [...this.buildingList];
      } else {
        this.selectedBuildings = [];
      }
    },
    async onSubmit() {
      if (this.$v) {
        this.$v.selectedBuildings.$touch();
        this.$v.form.$touch();
        if (this.$v.form.$anyError || this.$v.selectedBuildings.$anyError) {
          if (this.$v.selectedBuildings.$anyError) {
            this.$toasted.show(this.$t('toast.requiredBuilding'), { type: 'error' });
            return;
          }
          this.$toasted.show(this.$t('toast.failedForm'), { type: 'error' });
          return;
        }
      }

      try {
        this.form.buildingId = this.selectedBuildings.map(item => item.id);
        this.form.apartmentId = this.selectedApartments.map(item => item.id);
        this.form.buildings = this.form.buildingId;
        this.form.apartments = this.form.apartmentId;
        const payload = {
          businessId: this.business ? this.business.id : null,
          data: this.form,
        };
        if (this.$route.params.id) {
          assign(payload, { id: this.announcement.id });
          await this.$store.dispatch(ANNOUNCEMENTS_UPDATE, payload);
        } else {
          await this.$store.dispatch(ANNOUNCEMENTS_ADD, payload);
        }
        if (this.announcementStatus === 'success') {
          this.$router.push({ name: 'Announcements' });
          this.$toasted.show(this.$t('toast.success'), { type: 'success' });
        }
      } catch (error) {
        this.$toasted.show(error.message, { type: 'error' });
      }
    },
  },
};
</script>
