<template>
  <AuthLayout>
    <form @submit.prevent="onSubmit">
      <h2 class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
        {{ $t('auth.resetPassword') }}
      </h2>
      <div class="intro-x mt-8">
        <InputString
          type="email"
          :placeholder="$t('auth.email')"
          v-model.trim="$v.form.email.$model"
          :vuelidate="$v.form.email"
          input-class="input-reset-email login__input input--lg"
        />
        <InputString
          type="password"
          :placeholder="$t('auth.password')"
          v-model.trim="$v.form.password.$model"
          :vuelidate="$v.form.password"
          input-class="input-reset-password login__input input--lg"
        />
        <InputString
          type="password"
          :placeholder="$t('auth.passwordConfirmation')"
          v-model.trim="$v.form.passwordConfirmation.$model"
          :vuelidate="$v.form.passwordConfirmation"
          input-class="input-reset-password-confirmation login__input input--lg"
        />
      </div>

      <div class="intro-x mt-3 xl:mt-5">
        <Button type="submit" class="button--lg w-full text-white bg-theme-1" :loading="isLoading">
          <span>{{ $t('auth.resetPasswordBtn') }}</span>
        </Button>
      </div>
      <div class="intro-x mt-3 xl:mt-5">
        <Button
          :to="{ name: 'UserLogin' }"
          class="button--lg w-full xl:w-32 text-gray-700 border border-gray-300 dark:border-dark-5 dark:text-gray-300"
        >
          <span>{{ $t('auth.login') }}</span>
        </Button>
      </div>
    </form>
  </AuthLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import InputString from '@/components/ui/input/InputString';
import Button from '@/components/ui/button/Button';
import { validationMixin } from 'vuelidate';
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators';
import { AUTH_RESET_PASSWORD } from '@/store/actions/auth';
import AuthLayout from '@/views/auth/AuthLayout';

export default {
  components: { Button, InputString, AuthLayout },

  mixins: [validationMixin],

  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(6),
      },
      passwordConfirmation: {
        required,
        minLength: minLength(6),
        sameAs: sameAs('password'),
      },
    },
  },

  data() {
    return {
      loading: false,
      form: {
        email: '',
        password: '',
        passwordConfirmation: '',
        token: this.$route.query.token,
      },
      error: '',
    };
  },

  computed: {
    ...mapGetters(['authForgotStatus']),
    isLoading() {
      return this.authForgotStatus === 'loading';
    },
  },

  mounted() {},

  methods: {
    async onSubmit() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          this.$toasted.show(this.$t('toast.failedForm'), { type: 'error' });
          return;
        }
      }
      try {
        await this.$store.dispatch(AUTH_RESET_PASSWORD, this.form);
        this.$toasted.show(this.$t('toast.resetPasswordSuccess'), { type: 'success' });
        this.$router.push({ name: 'UserLogin' });
      } catch (error) {
        if (error.response.status === 422) {
          this.form = { email: '', password: '' };
          this.$v.$reset();
          this.$toasted.show(this.$t('toast.incorrectCredentials'), { type: 'error' });
        } else {
          this.$toasted.show(error.message, { type: 'error' });
        }
      }
    },
  },
};
</script>
