import i18n from '@/i18n';

const state = () => {
  return {
    menu: [
      {
        icon: 'BarChartIcon',
        pageName: 'Dashboard',
        title: i18n.t('nav.dashboard'),
        group: 'statistic',
      },
      {
        icon: 'ImageIcon',
        pageName: 'AllBusiness',
        title: i18n.t('nav.allBusiness'),
        group: 'all_business',
      },
      {
        icon: 'ImageIcon',
        pageName: 'Banners',
        title: i18n.t('nav.banner'),
        group: 'banner',
      },
      {
        icon: 'LayersIcon',
        pageName: 'AppVersions',
        title: i18n.t('nav.appVersions'),
        group: 'app_versions',
      },
      {
        icon: 'HomeIcon',
        pageName: 'Buildings',
        title: i18n.t('nav.registration'),
        group: 'registration',
      },
      {
        icon: 'FileIcon',
        pageName: 'ticketType',
        title: i18n.t('nav.reference'),
        group: 'reference',
      },
      {
        icon: 'UsersIcon',
        pageName: 'businessapprove',
        title: i18n.t('nav.businessapprove'),
        group: 'businessapprove',
      },
      {
        icon: 'UsersIcon',
        pageName: 'ownerRequests',
        title: i18n.t('nav.ownerRequests'),
        group: 'userRequest',
      },
      {
        icon: 'UserIcon',
        pageName: 'user',
        title: i18n.t('nav.users'),
        group: 'users',
      },
      {
        icon: 'SettingsIcon',
        pageName: 'Business',
        title: i18n.t('nav.business'),
        group: 'business',
      },
      {
        icon: 'FileIcon',
        pageName: 'InvoiceIncome',
        title: i18n.t('nav.finance'),
        group: 'finance',
        // subMenu: [
        //   {
        //     icon: 'FileIcon',
        //     pageName: 'InvoiceIncome',
        //     title: i18n.t('nav.invoiceIncome'),
        //   },
        //   {
        //     icon: 'FileIcon',
        //     pageName: 'InvoiceExpenses',
        //     title: i18n.t('nav.invoiceExpenses'),
        //   },
        // ],
      },
      {
        icon: 'MessageSquareIcon',
        pageName: 'Tickets',
        title: i18n.t('nav.tickets'),
        group: 'tickets',
      },
      {
        icon: 'ShoppingBagIcon',
        pageName: 'Services',
        title: i18n.t('nav.service'),
        group: 'services',
        // subMenu: [
        //   {
        //     icon: 'FileIcon',
        //     pageName: 'InvoiceIncome',
        //     title: i18n.t('nav.invoiceIncome'),
        //   },
        //   {
        //     icon: 'FileIcon',
        //     pageName: 'InvoiceExpenses',
        //     title: i18n.t('nav.invoiceExpenses'),
        //   },
        // ],
      },
      {
        icon: 'PackageIcon',
        pageName: 'Order',
        title: i18n.t('nav.order'),
        group: 'order',
      },
      {
        icon: 'MessageCircleIcon',
        pageName: 'Forums',
        title: i18n.t('nav.forums'),
        group: 'forums',
      },
      {
        icon: 'Volume2Icon',
        pageName: 'Announcements',
        title: i18n.t('nav.announcements'),
        group: 'announcements',
      },
      {
        icon: 'FilePlusIcon',
        pageName: 'Polls',
        title: i18n.t('nav.polls'),
        group: 'polls',
      },
      {
        icon: 'SendIcon',
        pageName: 'SendSms',
        title: i18n.t('nav.sms'),
        group: 'sms',
      },
      {
        icon: 'FileTextIcon',
        pageName: 'SmsTemplates',
        title: i18n.t('nav.smsTemplates'),
        group: 'sms_template',
      },
      {
        icon: 'UsersIcon',
        pageName: 'Meeting',
        title: i18n.t('nav.meeting'),
        group: 'meeting',
      },
      {
        icon: 'FolderPlusIcon',
        pageName: 'BusinessSetup',
        title: i18n.t('nav.businessSetup'),
        group: 'business_setup',
      },
      {
        icon: 'FileIcon',
        pageName: 'Reports',
        title: i18n.t('nav.reports'),
        group: 'reports',
      },
      {
        icon: 'ImageIcon',
        pageName: 'ActivityReports',
        title: i18n.t('nav.activityReports'),
        group: 'activity_reports',
      },
      {
        icon: 'SettingsIcon',
        pageName: 'SmsBalances',
        title: i18n.t('nav.smsBalances'),
        group: 'sms_balances',
      },
    ],
  };
};

const getters = {
  menu: state => state.menu,
};

const actions = {};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
