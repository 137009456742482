import { mapGetters } from 'vuex';
import { USER_VALIDATE } from '@/store/actions/user';

export default {
  props: {
    formData: Object,
    cities: Array,
    title: String,
  },

  computed: {
    ...mapGetters(['userStatus']),
    isLoading() {
      return this.userStatus === 'loading';
    },
  },

  methods: {
    onSubmit() {
      if (this.$v) {
        this.$v.formData.$touch();
        if (this.$v.formData.$anyError) {
          this.$toasted.show(this.$t('toast.failedForm'), { type: 'error' });
          return;
        }
      }

      this.$emit('step-done');
    },
    onUserValidate(payload) {
      this.$store.dispatch(USER_VALIDATE, payload);
    },
  },
};
