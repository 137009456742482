<template>
  <ModalEdit
    :id="modalId"
    :title="title"
    :loading="isLoading"
    :saveButton="false"
    @onClickCancel="onClickCancel"
  >
    <div
      v-for="(smsPackage, index) in smsPackageList"
      :key="index"
      class="intro-y col-span-12 md:col-span-6  lg:col-span-4"
    >
      <SmsPackageListItem v-if="isSms" :item="smsPackage" @subscribe="subscribeSmsPackage" />
    </div>
    <div class="qrcode">
      <vue-qrcode v-if="isCode" :value="qrCode" :width="250" />
      <p v-if="isCode">{{ $t('text.pleasePayWithReadTheCode') }}</p>
    </div>
    <SubscribeSmsConfirmDialog :modal-id="smsPackageModalId" @onSubmit="onSubmit" />
  </ModalEdit>
</template>

<script>
import { mapGetters } from 'vuex';
import { SMS_PACKAGE_LIST_REQUEST, SMS_PACKAGE_SUBSCRIBE } from '@/store/actions/smsPackage';
import { validationMixin } from 'vuelidate';
import ModalEdit from '@/components/ui/ModalEdit';
import baseModalMixin from '@/mixins/baseModalMixin';
import SmsPackageListItem from '@/components/business/sms/SmsPackageListItem';
import SubscribeSmsConfirmDialog from '@/components/business/sms/SubscribeSmsConfirmDialog';
import VueQrcode from 'vue-qrcode';

export default {
  mixins: [validationMixin, baseModalMixin],

  components: {
    ModalEdit,
    SmsPackageListItem,
    SubscribeSmsConfirmDialog,
    VueQrcode,
  },

  data() {
    return {
      smsPackageModalId: 'subscribe-sms-confirm-dialog',
      packageId: 0,
      isCode: false,
      isSms: true,
      qrCode: '',
      size: 300,
    };
  },
  props: {
    modalId: {
      type: String,
      default: 'sms-package-modal',
    },
    businessId: Number,
  },

  computed: {
    ...mapGetters(['smsPackageList', 'smsPackage', 'smsPackageStatus']),
    isLoading() {
      return this.businessStatus === 'loading';
    },
    title() {
      return this.$t('nav.businessSmsPackage');
    },
  },
  created() {
    this.getSmsPackageList();
  },
  methods: {
    async getSmsPackageList() {
      const payload = {
        data: {
          filters: {},
          sort: {
            field: 'id',
            type: 'desc',
          },
          page: 1,
          perPage: 100,
        },
      };
      await this.$store.dispatch(SMS_PACKAGE_LIST_REQUEST, payload);
    },
    async subscribeSmsPackage(packageId) {
      this.packageId = packageId;
      await cash(`#${this.smsPackageModalId}`).modal('show');
    },
    async onSubmit() {
      try {
        const payload = {
          data: {
            businessId: this.businessId,
            packageId: this.packageId,
          },
        };
        await this.$store.dispatch(SMS_PACKAGE_SUBSCRIBE, payload);

        if (this.smsPackageStatus === 'success') {
          this.$toasted.show(this.$t('toast.success'), { type: 'success' });
          this.isCode = true;
          this.isSms = false;
          this.qrCode = this.smsPackage.qpayData.qrText;
        }
      } catch (error) {
        this.$toasted.show(error.message, { type: 'error' });
      }
    },
    onClickCancel() {
      this.isSms = true;
      this.qrCode = '';
      this.isCode = false;
    },
  },
};
</script>

<style>
.qrcode img {
  margin: 0 20%;
}
.qrcode p {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 700;
}
</style>
