import { findIndex } from 'lodash';
import {
  OWNER_LIST_REQUEST,
  OWNER_LIST_REQUEST_ERROR,
  OWNER_LIST_REQUEST_SUCCESS,
  OWNER_SEARCH,
  OWNER_SEARCH_ERROR,
  OWNER_SEARCH_SUCCESS,
  OWNER_SEARCH_LIST_CLEAR,
  OWNER_ADD,
  OWNER_ADD_ERROR,
  OWNER_ADD_SUCCESS,
  OWNER_REQUEST,
  OWNER_REQUEST_SUCCESS,
  OWNER_REQUEST_ERROR,
  OWNER_REMOVE,
  OWNER_REMOVE_ERROR,
  OWNER_REMOVE_SUCCESS,
  OWNER_UPDATE,
  OWNER_UPDATE_ERROR,
  OWNER_UPDATE_SUCCESS,
  OWNER_LIST_EXPORT,
  OWNER_LIST_EXPORT_SUCCESS,
  OWNER_LIST_EXPORT_ERROR,
  OWNER_REQUEST_LIST,
  OWNER_REQUEST_LIST_SUCCESS,
  OWNER_REQUEST_LIST_ERROR,
  OWNER_APPROVE_CONFIRM,
  OWNER_APPROVE_CONFIRM_SUCCESS,
  OWNER_APPROVE_CONFIRM_ERROR,
  OWNER_REQUEST_REMOVE,
  OWNER_REQUEST_REMOVE_ERROR,
  OWNER_REQUEST_REMOVE_SUCCESS,
} from '@/store/actions/owner';
import { handleRequestError } from '@/utils/error';

import Repository, { OWNERS } from '@/repositories/RepositoryFactory';

const OwnersRepository = Repository.get(OWNERS);

const state = {
  owner: '',
  ownerList: [],
  ownerLastCreated: '',
  ownerMeta: '',
  ownerStatus: '',
  ownerExportStatus: '',
  ownerExportLink: '',
  ownerSearchList: '',
  ownerSearchStatus: '',
  ownerRequestListStatus: '',
  ownerRequestList: [],
  ownerRequestMeta: '',
};

const getters = {
  owner: state => state.owner,
  ownerList: state => state.ownerList,
  ownerLastCreated: state => state.ownerLastCreated,
  ownerMeta: state => state.ownerMeta,
  ownerStatus: state => state.ownerStatus,
  ownerExportStatus: state => state.ownerExportStatus,
  ownerExportLink: state => state.ownerExportLink,
  ownerSearchList: state => state.ownerSearchList,
  ownerSearchStatus: state => state.ownerSearchStatus,
  ownerRequestListStatus: state => state.ownerRequestListStatus,
  ownerRequestList: state => state.ownerRequestList,
  ownerRequestMeta: state => state.ownerRequestMeta,
};

const actions = {
  [OWNER_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(OWNER_LIST_REQUEST);
    try {
      const response = await OwnersRepository.get(payload.businessId, payload.data);
      commit(OWNER_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(OWNER_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },

  [OWNER_SEARCH]: async ({ commit }, payload) => {
    commit(OWNER_SEARCH);
    try {
      let items = [];
      if (payload.query) {
        const response = await OwnersRepository.get(payload.businessId, payload.data);
        items = response.data.data;
      }

      commit(OWNER_SEARCH_SUCCESS, items);
    } catch (error) {
      commit(OWNER_SEARCH_ERROR);
      handleRequestError(error);
    }
  },

  [OWNER_SEARCH_LIST_CLEAR]: async ({ commit }) => {
    commit(OWNER_SEARCH_LIST_CLEAR);
  },

  [OWNER_REQUEST]: async ({ commit }, payload) => {
    commit(OWNER_REQUEST);
    try {
      const response = await OwnersRepository.getSelf(payload.businessId, payload.id);
      const { data } = response;
      commit(OWNER_REQUEST_SUCCESS, data);
    } catch (error) {
      commit(OWNER_REQUEST_ERROR);
      handleRequestError(error);
    }
  },

  [OWNER_ADD]: async ({ commit }, payload) => {
    commit(OWNER_ADD);
    try {
      const response = await OwnersRepository.create(payload.businessId, payload.data);
      commit(OWNER_ADD_SUCCESS, response.data.data);
    } catch (error) {
      commit(OWNER_ADD_ERROR);
      handleRequestError(error);
    }
  },

  [OWNER_UPDATE]: async ({ commit }, payload) => {
    commit(OWNER_UPDATE);
    try {
      const response = await OwnersRepository.update(payload.businessId, payload.id, payload.data);
      commit(OWNER_UPDATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(OWNER_UPDATE_ERROR);
      handleRequestError(error);
    }
  },

  [OWNER_REMOVE]: async ({ commit }, payload) => {
    commit(OWNER_REMOVE);
    try {
      await OwnersRepository.delete(payload.businessId, payload.id);
      commit(OWNER_REMOVE_SUCCESS, payload.id);
    } catch (error) {
      commit(OWNER_REMOVE_ERROR);
      handleRequestError(error);
    }
  },

  [OWNER_LIST_EXPORT]: async ({ commit }, payload) => {
    commit(OWNER_LIST_EXPORT);
    try {
      const response = await OwnersRepository.export(payload.businessId, payload.data);
      commit(OWNER_LIST_EXPORT_SUCCESS, response.data.data);
    } catch (error) {
      commit(OWNER_LIST_EXPORT_ERROR);
      handleRequestError(error);
    }
  },

  [OWNER_REQUEST_LIST]: async ({ commit }, payload) => {
    commit(OWNER_REQUEST_LIST);
    try {
      const response = await OwnersRepository.getOwnerRequest(payload.businessId, payload.data);
      commit(OWNER_REQUEST_LIST_SUCCESS, response.data);
    } catch (error) {
      commit(OWNER_REQUEST_LIST_ERROR);
      handleRequestError(error);
    }
  },

  [OWNER_APPROVE_CONFIRM]: async ({ commit }, payload) => {
    commit(OWNER_APPROVE_CONFIRM);
    try {
      const response = await OwnersRepository.approveOwnerRequest(payload.businessId, payload);
      // const response = await OwnersRepository.getOwnerRequest(payload.businessId, payload);
      commit(OWNER_REQUEST_LIST_SUCCESS, response.data);
    } catch (error) {
      commit(OWNER_APPROVE_CONFIRM_ERROR);
      handleRequestError(error);
    }
  },

  [OWNER_REQUEST_REMOVE]: async ({ commit }, payload) => {
    commit(OWNER_REQUEST_REMOVE);
    try {
      await OwnersRepository.deleteOnwerRequest(payload.businessId, payload);
      commit(OWNER_REQUEST_REMOVE_SUCCESS, payload.user_apartment_id);
    } catch (error) {
      commit(OWNER_REQUEST_REMOVE_ERROR);
      handleRequestError(error);
    }
  },
};

const mutations = {
  [OWNER_LIST_REQUEST]: state => {
    state.ownerStatus = 'loading';
  },
  [OWNER_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.ownerStatus = 'success';
    state.ownerList = payload.data;
    state.ownerMeta = payload.meta;
  },
  [OWNER_LIST_REQUEST_ERROR]: state => {
    state.ownerStatus = 'error';
  },

  [OWNER_SEARCH]: state => {
    state.ownerSearchStatus = 'loading';
  },
  [OWNER_SEARCH_SUCCESS]: (state, payload) => {
    state.ownerSearchStatus = 'success';
    state.ownerSearchList = payload;
  },
  [OWNER_SEARCH_ERROR]: state => {
    state.ownerSearchStatus = 'error';
  },

  [OWNER_SEARCH_LIST_CLEAR]: state => {
    state.ownerSearchList = [];
  },

  [OWNER_ADD]: state => {
    state.ownerStatus = 'loading';
    state.ownerLastCreated = '';
  },
  [OWNER_ADD_SUCCESS]: (state, payload) => {
    state.ownerStatus = 'success';
    state.ownerLastCreated = payload;
    state.ownerList.push(payload);
  },
  [OWNER_ADD_ERROR]: state => {
    state.ownerStatus = 'error';
  },

  [OWNER_LIST_EXPORT]: state => {
    state.ownerExportStatus = 'loading';
    state.ownerExportLink = '';
  },
  [OWNER_LIST_EXPORT_SUCCESS]: (state, payload) => {
    state.ownerExportStatus = 'success';
    state.ownerExportLink = payload;
  },
  [OWNER_LIST_EXPORT_ERROR]: state => {
    state.ownerExportStatus = 'error';
  },

  [OWNER_REQUEST]: state => {
    state.ownerStatus = 'loading';
  },
  [OWNER_REQUEST_SUCCESS]: (state, payload) => {
    state.ownerStatus = 'success';
    state.owner = payload.data;
  },
  [OWNER_REQUEST_ERROR]: state => {
    state.ownerStatus = 'error';
  },

  [OWNER_UPDATE]: state => {
    state.ownerStatus = 'loading';
  },
  [OWNER_UPDATE_SUCCESS]: (state, payload) => {
    state.ownerStatus = 'success';
    const index = findIndex(state.ownerList, { id: payload.id });
    state.ownerList[index] = payload;
  },
  [OWNER_UPDATE_ERROR]: state => {
    state.ownerStatus = 'error';
  },

  [OWNER_REMOVE]: state => {
    state.ownerStatus = 'deleting';
  },

  [OWNER_REMOVE_SUCCESS]: (state, payload) => {
    const index = findIndex(state.ownerList, { id: payload });
    state.ownerList.splice(index, 1);
  },

  [OWNER_REMOVE_ERROR]: state => {
    state.ownerStatus = 'error';
  },

  [OWNER_REQUEST_LIST]: state => {
    state.ownerRequestListStatus = 'loading';
  },

  [OWNER_REQUEST_LIST_ERROR]: state => {
    state.ownerRequestListStatus = 'error';
  },

  [OWNER_REQUEST_LIST_SUCCESS]: (state, payload) => {
    state.ownerRequestListStatus = 'success';
    state.ownerRequestList = payload.data;
    state.ownerRequestMeta = payload.meta;
  },

  [OWNER_APPROVE_CONFIRM_SUCCESS]: (state, payload) => {
    state.invoiceList = payload.data;
  },

  [OWNER_APPROVE_CONFIRM_ERROR]: state => {
    state.ownerRequestListStatus = 'error';
  },
  [OWNER_REQUEST_REMOVE]: state => {
    state.ownerStatus = 'deleting';
  },

  [OWNER_REQUEST_REMOVE_SUCCESS]: (state, payload) => {
    const index = findIndex(state.ownerRequestList, { id: payload });
    state.ownerRequestList.splice(index, 1);
  },

  [OWNER_REQUEST_REMOVE_ERROR]: state => {
    state.ownerStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
