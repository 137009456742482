<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-5">
      <PageHeader class="text-lg font-medium mr-auto" />
    </div>
    <div class="intro-y mt-5 overflow-auto lg:overflow-visible">
      <BusinessTable
        :loading="isLoading"
        :rows="businessList"
        :columns="columns"
        :line-numbers="true"
        :sort="sort"
        :page="page"
        :per-page="perPage"
        :page-size="pageSize"
        :total-page="totalPage"
        @changePassword="changePassword"
        @delete="confirmRemoval"
        @onFilter="onFilter"
        @onPageChange="onPageChange"
        @onPerPageChange="onPerPageChange"
        @onSortChange="onSortChange"
      />
    </div>
    <BusinessChangePasswordModal
      :modal-id="changePasswordModalId"
      :businessId="changePasswordItemId || null"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BUSINESS_LIST_REQUEST } from '@/store/actions/business';
import dataListMixin from '@/mixins/dataListMixin';
import PageHeader from '@/components/ui/PageHeader';
import BusinessTable from '@/components/business/BusinessTable';
import BusinessChangePasswordModal from '@/components/business/BusinessChangePasswordModal';

export default {
  mixins: [dataListMixin],

  data() {
    return {
      storeActionListRequest: BUSINESS_LIST_REQUEST,
      changePasswordModalId: 'change-password-modal',
      changePasswordItemId: null,
      columns: [
        { label: this.$t('table.name'), field: 'name', filterable: true },
        { label: this.$t('table.phone'), field: 'phone', filterable: true },
        { label: this.$t('table.address'), field: 'address' },
        {
          label: this.$t('table.registrationNumber'),
          field: 'registrationNumber',
          filterable: true,
        },
        { label: this.$t('table.smsCount'), field: 'smsCount' },
        { label: this.$t('table.stateBankEmail'), field: 'stateBankEmail' },
        { label: this.$t('table.action'), field: 'action' },
      ],
    };
  },

  computed: {
    ...mapGetters(['businessList', 'businessMeta', 'businessStatus']),
    isLoading() {
      return this.businessStatus === 'loading';
    },
  },

  async created() {
    await this.fetchData();
  },

  methods: {
    setPaginationValues() {
      this.totalRecords = this.businessMeta ? this.businessMeta.total : 1;
      this.page = this.businessMeta.currentPage;
      this.totalPage = this.businessMeta.lastPage;
    },
    changePassword(id) {
      this.changePasswordItemId = id;
      cash(`#${this.changePasswordModalId}`).modal('show');
    },
  },

  components: {
    PageHeader,
    BusinessTable,
    BusinessChangePasswordModal,
  },
};
</script>
