<template>
  <div :id="id" class="modal">
    <div :class="className">
      <div class="flex items-center px-5 py-5 sm:py-3 border-b border-gray-200 dark:border-dark-5">
        <h2 class="font-medium text-base mr-auto">{{ title }}</h2>
      </div>
      <div>
        <slot></slot>
      </div>
      <div
        class="px-5 py-3 flex items-center justify-between border-t border-gray-200 dark:border-dark-5"
      >
        <Button
          type="button"
          @click="onCancel"
          class="w-20 border text-gray-700 dark:border-dark-5 dark:text-gray-300 mr-1"
        >
          {{ $t('text.cancel') }}
        </Button>
        <div v-if="saveButton">
          <Button
            type="button"
            @click="onSubmit"
            :loading="loading"
            class="w-32 bg-theme-1 text-white"
          >
            {{ $t('text.save') }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/button/Button';

export default {
  name: 'ModalEdit',

  components: { Button },

  props: {
    id: {
      type: String,
      default: 'edit-modal',
    },
    title: String,
    loading: Boolean,
    saveButton: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'sm',
    },
  },

  computed: {
    className() {
      return `modal__content modal__content--${this.size}`;
    },
  },

  methods: {
    onCancel() {
      cash(`#${this.id}`).modal('hide');
      this.$emit('onClickCancel');
    },

    onSubmit() {
      this.$emit('click');
    },
  },
};
</script>
