<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <PageHeader class="text-lg font-medium mr-auto" :title="title" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <Button
          type="button"
          :to="{ name: 'user' }"
          class="box text-gray-700 dark:text-gray-300 mr-2 flex"
        >
          {{ $t('text.back') }}
        </Button>
        <Button type="button" @click="onSubmit" class="w-24 bg-theme-1 text-white">
          {{ $t('text.save') }}
        </Button>
      </div>
    </div>
    <div class="intro-y mt-5">
      <form>
        <div class="grid grid-cols-12 gap-6">
          <div class="intro-y col-span-12 sm:col-span-12 lg:col-span-4 xl:col-span-4">
            <div class="intro-y box">
              <div class="p-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class="font-medium text-base">{{ $t('nav.userShow') }}</h2>
              </div>
              <div class="p-5">
                <InputString
                  :label="$t('text.email')"
                  v-model.trim="$v.form.email.$model"
                  :vuelidate="$v.form.email"
                  disabled
                  input-class="input-reset-password-email"
                />
                <InputString
                  :label="$t('text.passwordNew')"
                  v-model="$v.form.password.$model"
                  :vuelidate="$v.form.password"
                  type="password"
                  input-class="input-reset-password"
                />
                <InputString
                  :label="$t('text.passwordConfirmation')"
                  v-model.trim="$v.form.passwordConfirmation.$model"
                  :vuelidate="$v.form.passwordConfirmation"
                  input-class="input-reset-password-confirmation"
                  type="password"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/button/Button';
import PageHeader from '@/components/ui/PageHeader';
import InputString from '@/components/ui/input/InputString';
import { validationMixin } from 'vuelidate';
import { mapGetters } from 'vuex';
import { USER_GET_REQUEST, USER_RESET_PASSWORD } from '@/store/actions/user';
import { email, minLength, required, sameAs } from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin],

  props: {
    business: Object,
  },

  data() {
    return {
      userId: parseInt(this.$route.params.userId, 10),
      form: {
        email: '',
        password: '',
        passwordConfirmation: '',
      },
    };
  },

  validations() {
    return {
      form: {
        email: {
          required,
          email,
        },
        password: {
          required,
          minLength: minLength(6),
        },
        passwordConfirmation: {
          required,
          sameAs: sameAs('password'),
        },
      },
    };
  },

  computed: {
    ...mapGetters(['user', 'resetPasswordStatus']),
    title() {
      return this.$t('topBar.resetPassword');
    },
  },

  async created() {
    await this.fetchData();
  },

  methods: {
    async fetchData() {
      if (this.userId) {
        const payload = { businessId: this.business.id, id: this.userId };
        await this.$store.dispatch(USER_GET_REQUEST, payload);
        if (this.user) {
          this.form.email = this.user.email;
        }
      }
    },
    async onSubmit() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          this.$toasted.show(this.$t('toast.failedForm'), { type: 'error' });
          return;
        }
      }
      if (this.userId) {
        try {
          const payload = {
            email: this.form.email,
            password: this.form.password,
          };
          await this.$store.dispatch(USER_RESET_PASSWORD, payload);
          if (this.resetPasswordStatus === 'success') {
            this.$toasted.show(this.$t('toast.resetPasswordSuccess'), { type: 'success' });
            this.$router.push({ name: 'Dashboard' });
          }
        } catch (error) {
          if (error.response.status === 422) {
            this.form = {
              email: this.user.email,
              password: '',
              passwordConfirmation: '',
            };
            this.$v.$reset();
            this.$toasted.show(this.$t('toast.incorrectCredentials'), { type: 'error' });
          } else {
            this.$toasted.show(error.message, { type: 'error' });
          }
        }
      }
    },
  },

  components: {
    Button,
    PageHeader,
    InputString,
  },
};
</script>
