<template>
  <TableRegular
    :loading="loading"
    :downloading="downloading"
    :rows="rows"
    :columns="columns"
    :sort="sort"
    :page="page"
    :per-page="perPage"
    :page-size="pageSize"
    :total-page="totalPage"
    :line-numbers="lineNumbers"
    :downloadable="downloadable"
    @onFilter="$emit('onFilter', $event)"
    @onPageChange="$emit('onPageChange', $event)"
    @onPerPageChange="$emit('onPerPageChange', $event)"
    @onSortChange="$emit('onSortChange', $event)"
  >
    <template slot="table-row" slot-scope="props">
      <p v-if="props.column.field === 'name'" class="font-medium whitespace-nowrap">
        {{ props.row.name }}
      </p>
      <p v-else-if="props.column.field === 'price'" class="whitespace-nowrap">
        {{ props.row.price | currency }}
      </p>
      <p v-else-if="props.column.field === 'priceType'" class="whitespace-nowrap">
        <template v-if="props.row.priceType === 'SQUARE_PRICE'">{{
          $t('text.perSquareMeter')
        }}</template>
        <template v-if="props.row.priceType === 'WHOLE_PRICE'">{{
          $t('text.wholePrice')
        }}</template>
      </p>
      <p v-else-if="props.column.field === 'action'" class="flex justify-center items-center">
        <router-link
          v-if="checkPermission('finance.service.edit')"
          :to="{ name: 'ServiceProductUpdate', params: { serviceProductId: props.row.id } }"
          class="flex items-center mr-3"
        >
          <CheckSquareIcon class="w-4 h-4 mr-1" />
          {{ $t('table.edit') }}
        </router-link>
        <a
          v-if="checkPermission('finance.service.delete')"
          href="javascript:;"
          @click="$emit('delete', props.row.id)"
          class="flex items-center text-theme-6"
        >
          <Trash2Icon class="w-4 h-4 mr-1" />
          {{ $t('table.delete') }}
        </a>
      </p>
    </template>
  </TableRegular>
</template>

<script>
import TableRegular from '@/components/ui/table/TableRegular';
import tableMixin from '@/mixins/tableMixin';

export default {
  mixins: [tableMixin],
  components: { TableRegular },
};
</script>
