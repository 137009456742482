<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <PageHeader class="text-lg font-medium mr-auto" :title="title" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <Button
          type="button"
          :to="{ name: 'Apartments' }"
          class="box text-gray-700 dark:text-gray-300 mr-2 flex"
        >
          {{ $t('text.back') }}
        </Button>
        <Button
          type="button"
          @click="onSubmit"
          :loading="isLoading"
          class="w-24 bg-theme-1 text-white"
        >
          {{ $t('text.save') }}
        </Button>
      </div>
    </div>
    <div class="intro-y mt-5">
      <form @submit.prevent="onSubmit">
        <div class="grid grid-cols-12 gap-6">
          <div class="intro-y col-span-12 sm:col-span-12 lg:col-span-6 xl:col-span-4">
            <div class="intro-y box">
              <div class="p-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class="font-medium text-base">
                  {{ $t('nav.apartmentShow') }}
                </h2>
              </div>
              <FormApartment :v-form="$v.form" :form="form" :is-parking="false" />
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-12 lg:col-span-6 xl:col-span-4">
            <div class="intro-y box">
              <div class="p-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class="font-medium text-base">{{ $t('nav.ownerShow') }}</h2>
              </div>
              <template v-for="(owner, index) in ownersOfThisApartment">
                <div
                  :key="index"
                  class="py-4 px-5 border-b border-gray-200 dark:border-dark-5 relative"
                >
                  <OwnerInfo :owner="owner" />
                  <div
                    @click="confirmRemoval(owner)"
                    class="w-5 h-5 cursor-pointer flex items-center justify-center absolute rounded-full text-white bg-theme-6 right-0 top-0 -mr-2 mt-2"
                  >
                    <xIcon class="w-4 h-4" />
                  </div>
                </div>
              </template>
              <OwnerSearch
                class="p-5"
                :business="business"
                :building-id="parseInt($route.params.buildingId, 10)"
                modal-id="apartment-owner-form-modal"
                @onOwnerAdd="onItemAdd"
              />
            </div>
          </div>
        </div>
      </form>
    </div>

    <ConfirmDialog :modal-id="deleteModalId" @ok="onDelete" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { APARTMENT_ADD, APARTMENT_UPDATE, APARTMENT_REQUEST } from '@/store/actions/apartment';
import { OWNER_REMOVE } from '@/store/actions/owner';
import { validationMixin } from 'vuelidate';
import { required, decimal, minValue } from 'vuelidate/lib/validators';
import Button from '@/components/ui/button/Button';
import PageHeader from '@/components/ui/PageHeader';
import ConfirmDialog from '@/components/ui/ConfirmDialog';
import FormApartment from '@/components/form/FormApartment';
import OwnerSearch from '@/components/owner/OwnerSearch';
import OwnerInfo from '@/components/owner/OwnerInfo';

export default {
  mixins: [validationMixin],

  components: { Button, FormApartment, PageHeader, ConfirmDialog, OwnerSearch, OwnerInfo },

  props: {
    business: Object,
  },

  validations() {
    return {
      form: {
        code: {
          required,
        },
        number: {
          required,
        },
        square: {
          required,
          decimal,
        },
      },
    };
  },

  data() {
    return {
      form: {
        code: '',
        number: '',
        square: '',
        garageFee: 0,
        isParking: false,
        buildingId: this.$route.params.buildingId,
        owners: [],
        stateBankApartmentName: '',
        corps: '',
      },
      ownerToRemove: null,
      ownersOfThisApartment: [],
      deleteModalId: 'delete-confirm-dialog',
    };
  },

  computed: {
    ...mapGetters(['apartmentStatus', 'apartment']),
    isLoading() {
      return this.apartmentStatus === 'loading';
    },
    title() {
      return this.$route.name === 'ApartmentCreate'
        ? this.$t(`nav.apartmentCreate`)
        : this.$t(`nav.apartmentUpdate`);
    },
  },

  async created() {
    await this.fetchData();
  },

  methods: {
    async fetchData() {
      if (this.$route.params.apartmentId) {
        const payload = { businessId: this.business.id, id: this.$route.params.apartmentId };
        await this.$store.dispatch(APARTMENT_REQUEST, payload);
        const { apartment } = this;
        if (apartment) {
          this.form = {
            code: apartment.code,
            number: apartment.number,
            square: apartment.square,
            garageFee: apartment.garageFee ? apartment.garageFee : 0,
            isParking: apartment.isParking,
            buildingId: this.$route.params.buildingId,
            owners: [],
            stateBankApartmentName: apartment.stateBankApartmentName,
            corps: apartment.corps,
          };
          this.ownersOfThisApartment = this.apartment.owners.map(item => item);
        }
      }
    },

    onItemAdd(owner) {
      this.ownersOfThisApartment.push(owner);
    },

    confirmRemoval(owner) {
      this.ownerToRemove = owner;
      cash(`#${this.deleteModalId}`).modal('show');
    },

    async onDelete() {
      cash(`#${this.deleteModalId}`).modal('hide');
      try {
        if (this.ownerToRemove.new) {
          const payload = {
            businessId: this.business.id,
            id: this.ownerToRemove.id,
          };
          await this.$store.dispatch(OWNER_REMOVE, payload);
        }
        const index = this.ownersOfThisApartment.findIndex(
          item => item.id === this.ownerToRemove.id
        );
        this.ownersOfThisApartment.splice(index, 1);
      } catch (error) {
        this.$toasted.show(error.message, { type: 'error' });
      }
      this.ownerToRemove = null;
    },

    async onSubmit() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          this.$toasted.show(this.$t('toast.failedForm'), { type: 'error' });
          return;
        }
      }
      try {
        this.form.owners = this.ownersOfThisApartment.map(item => {
          return item.id;
        });
        if (this.$route.params.apartmentId) {
          const payload = {
            businessId: this.business.id,
            id: this.apartment.id,
            data: this.form,
          };
          await this.$store.dispatch(APARTMENT_UPDATE, payload);
        } else {
          const payload = {
            businessId: this.business.id,
            data: this.form,
          };
          await this.$store.dispatch(APARTMENT_ADD, payload);
        }
        if (this.apartmentStatus === 'success') {
          this.$router.push({ name: 'Apartments' });
          this.$toasted.show(this.$t('toast.success'), { type: 'success' });
        }
      } catch (error) {
        this.$toasted.show(error.message, { type: 'error' });
      }
    },
  },
};
</script>
