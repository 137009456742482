export const INVOICE_LIST_REQUEST = 'INVOICE_LIST_REQUEST';
export const INVOICE_LIST_REQUEST_SUCCESS = 'INVOICE_LIST_REQUEST_SUCCESS';
export const INVOICE_LIST_REQUEST_ERROR = 'INVOICE_LIST_REQUEST_ERROR';

export const INVOICE_SEARCH = 'INVOICE_SEARCH';
export const INVOICE_SEARCH_SUCCESS = 'INVOICE_SEARCH_SUCCESS';
export const INVOICE_SEARCH_ERROR = 'INVOICE_SEARCH_ERROR';
export const INVOICE_SEARCH_LIST_CLEAR = 'INVOICE_SEARCH_LIST_CLEAR';

export const INVOICE_ADD = 'INVOICE_ADD';
export const INVOICE_ADD_SUCCESS = 'INVOICE_ADD_SUCCESS';
export const INVOICE_ADD_ERROR = 'INVOICE_ADD_ERROR';

export const INVOICE_UPDATE = 'INVOICE_UPDATE';
export const INVOICE_UPDATE_SUCCESS = 'INVOICE_UPDATE_SUCCESS';
export const INVOICE_UPDATE_ERROR = 'INVOICE_UPDATE_ERROR';

export const INVOICE_REMOVE = 'INVOICE_REMOVE';
export const INVOICE_REMOVE_SUCCESS = 'INVOICE_REMOVE_SUCCESS';
export const INVOICE_REMOVE_ERROR = 'INVOICE_REMOVE_ERROR';

export const INVOICE_SELECTED_REMOVE = 'INVOICE_SELECTED_REMOVE';
export const INVOICE_SELECTED_REMOVE_SUCCESS = 'INVOICE_SELECTED_REMOVE_SUCCESS';
export const INVOICE_SELECTED_REMOVE_ERROR = 'INVOICE_SELECTED_REMOVE_ERROR';

export const INVOICE_REQUEST = 'INVOICE_REQUEST';
export const INVOICE_REQUEST_SUCCESS = 'INVOICE_REQUEST_SUCCESS';
export const INVOICE_REQUEST_ERROR = 'INVOICE_REQUEST_ERROR';

export const INVOICE_IMPORT = 'INVOICE_IMPORT';
export const INVOICE_LIST_IMPORT = 'INVOICE_LIST_IMPORT';
export const INVOICE_LIST_IMPORT_SUCCESS = 'INVOICE_LIST_IMPORT_SUCCESS';
export const INVOICE_LIST_IMPORT_ERROR = 'INVOICE_LIST_IMPORT_ERROR';

export const INVOICE_COPY = 'INVOICE_COPY';
export const INVOICE_COPY_SUCCESS = 'INVOICE_COPY_SUCCESS';
export const INVOICE_COPY_ERROR = 'INVOICE_COPY_ERROR';

export const INVOICE_LIST_EXPORT = 'INVOICE_LIST_EXPORT';
export const INVOICE_LIST_EXPORT_SUCCESS = 'INVOICE_LIST_EXPORT_SUCCESS';
export const INVOICE_LIST_EXPORT_ERROR = 'INVOICE_LIST_EXPORT_ERROR';

export const INVOICE_MAKE_PAY = 'INVOICE_MAKE_PAY';
export const INVOICE_MAKE_PAY_SUCCESS = 'INVOICE_MAKE_PAY_SUCCESS';
export const INVOICE_MAKE_PAY_ERROR = 'INVOICE_MAKE_PAY_ERROR';

export const JOB_STATEMENT_REQUEST = 'JOB_STATEMENT_REQUEST';
export const JOB_STATEMENT_REQUEST_SUCCESS = 'JOB_STATEMENT_REQUEST_SUCCESS';
export const JOB_STATEMENT_REQUEST_ERROR = 'JOB_STATEMENT_REQUEST_ERROR';

export const JOB_REQUEST = 'JOB_REQUEST';
export const JOB_REQUEST_SUCCESS = 'JOB_REQUEST_SUCCESS';
export const JOB_REQUEST_ERROR = 'JOB_REQUEST_ERROR';

export const INVOICE_CHECK_REQUEST = 'INVOICE_CHECK_REQUEST';
export const INVOICE_CHECK_REQUEST_SUCCESS = 'INVOICE_CHECK_REQUEST_SUCCESS';
export const INVOICE_CHECK_REQUEST_ERROR = 'INVOICE_CHECK_REQUEST_ERROR';

export const INVOICE_PAY_FROM_STATEMENT = 'INVOICE_PAY_FROM_STATEMENT';
export const INVOICE_PAY_FROM_STATEMENT_SUCCESS = 'INVOICE_PAY_FROM_STATEMENT_SUCCESS';
export const INVOICE_PAY_FROM_STATEMENT_ERROR = 'INVOICE_PAY_FROM_STATEMENT_ERROR';
export const INVOICE_CHECK_SMS = 'INVOICE_CHECK_SMS';
export const INVOICE_CHECK_SMS_SUCCESS = 'INVOICE_CHECK_SMS_SUCCESS';
export const INVOICE_CHECK_SMS_ERROR = 'INVOICE_CHECK_SMS_ERROR';

export const UNUSED_TRANSACTION_DELETE = 'UNUSED_TRANSACTION_DELETE';
export const UNUSED_TRANSACTION_DELETE_SUCCESS = 'UNUSED_TRANSACTION_DELETE_SUCCESS';
export const UNUSED_TRANSACTION_DELETE_ERROR = 'UNUSED_TRANSACTION_DELETE_ERROR';

export const UNKNOWN_TRANSACTION_DELETE = 'UNKNOWN_TRANSACTION_DELETE';
export const UNKNOWN_TRANSACTION_DELETE_SUCCESS = 'UNKNOWN_TRANSACTION_DELETE_SUCCESS';
export const UNKNOWN_TRANSACTION_DELETE_ERROR = 'UNKNOWN_TRANSACTION_DELETE_ERROR';
