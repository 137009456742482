import api from '@/clients/apiClient';
import {
  CITY_LIST_REQUEST,
  CITY_LIST_REQUEST_ERROR,
  CITY_LIST_REQUEST_SUCCESS,
  TOWN_LIST_REQUEST,
  TOWN_LIST_REQUEST_ERROR,
  TOWN_LIST_REQUEST_SUCCESS,
  PET_TYPES_REQUEST,
  PET_TYPES_REQUEST_ERROR,
  PET_TYPES_REQUEST_SUCCESS,
  CAR_MAKES_REQUEST,
  CAR_MAKES_REQUEST_ERROR,
  CAR_MAKES_REQUEST_SUCCESS,
  EXPENSE_TYPES_REQUEST,
  EXPENSE_TYPES_REQUEST_ERROR,
  EXPENSE_TYPES_REQUEST_SUCCESS,
} from '@/store/actions/localUnits';
import { handleRequestError } from '@/utils/error';

const state = {
  cityList: [],
  cityStatus: '',
  townList: [],
  townStatus: '',
  petTypes: [],
  petTypesStatus: '',
  carMakes: [],
  carMakesStatus: '',
  expenseTypes: [],
  expenseTypesStatus: '',
};

const getters = {
  cityList: state => state.cityList,
  cityStatus: state => state.cityStatus,
  townList: state => state.townList,
  townStatus: state => state.townStatus,
  petTypes: state => state.petTypes,
  petTypesStatus: state => state.petTypesStatus,
  carMakes: state => state.carMakes,
  carMakesStatus: state => state.carMakesStatus,
  expenseTypes: state => state.expenseTypes,
  expenseTypesStatus: state => state.expenseTypesStatus,
};

const actions = {
  [CITY_LIST_REQUEST]: async ({ commit }) => {
    commit(CITY_LIST_REQUEST);
    try {
      const response = await api.get('cities');
      commit(CITY_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(CITY_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },

  [TOWN_LIST_REQUEST]: async ({ commit }) => {
    commit(TOWN_LIST_REQUEST);
    try {
      const response = await api.get('towns');
      commit(TOWN_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(TOWN_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },

  [PET_TYPES_REQUEST]: async ({ commit }) => {
    commit(PET_TYPES_REQUEST);
    try {
      const response = await api.get('pet-types');
      commit(PET_TYPES_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(PET_TYPES_REQUEST_ERROR);
      handleRequestError(error);
    }
  },

  [CAR_MAKES_REQUEST]: async ({ commit }) => {
    commit(CAR_MAKES_REQUEST);
    try {
      const response = await api.get('car-makes');
      commit(CAR_MAKES_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(CAR_MAKES_REQUEST_ERROR);
      handleRequestError(error);
    }
  },

  [EXPENSE_TYPES_REQUEST]: async ({ commit }) => {
    commit(EXPENSE_TYPES_REQUEST);
    try {
      const response = await api.get('expense-types');
      commit(EXPENSE_TYPES_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(EXPENSE_TYPES_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
};

const mutations = {
  [CITY_LIST_REQUEST]: state => {
    state.cityStatus = 'loading';
  },
  [CITY_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.cityStatus = 'success';
    state.cityList = payload.data;
  },
  [CITY_LIST_REQUEST_ERROR]: state => {
    state.cityStatus = 'error';
  },

  [TOWN_LIST_REQUEST]: state => {
    state.townStatus = 'loading';
  },
  [TOWN_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.townStatus = 'success';
    state.townList = payload.data;
  },
  [TOWN_LIST_REQUEST_ERROR]: state => {
    state.townStatus = 'error';
  },

  [PET_TYPES_REQUEST]: state => {
    state.petTypesStatus = 'loading';
  },
  [PET_TYPES_REQUEST_SUCCESS]: (state, payload) => {
    state.petTypesStatus = 'success';
    state.petTypes = payload.data;
  },
  [PET_TYPES_REQUEST_ERROR]: state => {
    state.petTypesStatus = 'error';
  },

  [CAR_MAKES_REQUEST]: state => {
    state.carMakesStatus = 'loading';
  },
  [CAR_MAKES_REQUEST_SUCCESS]: (state, payload) => {
    state.carMakesStatus = 'success';
    state.carMakes = payload.data;
  },
  [CAR_MAKES_REQUEST_ERROR]: state => {
    state.carMakesStatus = 'error';
  },

  [EXPENSE_TYPES_REQUEST]: state => {
    state.expenseTypesStatus = 'loading';
  },
  [EXPENSE_TYPES_REQUEST_SUCCESS]: (state, payload) => {
    state.expenseTypesStatus = 'success';
    state.expenseTypes = payload.data;
  },
  [EXPENSE_TYPES_REQUEST_ERROR]: state => {
    state.expenseTypesStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
