import ReportsIndex from '@/views/Reports/ReportsIndex';
import Reports from '@/views/Reports/Reports';

export default [
  {
    path: '/reports',
    component: ReportsIndex,
    meta: { group: 'reports' },
    children: [
      {
        path: '',
        name: 'Reports',
        component: Reports,
      },
    ],
  },
];
