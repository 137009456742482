import axios from 'axios';
import AuthTokenStorageService from '@/services/AuthTokenStorageService';

const headers = {};

const accessToken = AuthTokenStorageService.getAccessToken();
if (accessToken) {
  headers.Authorization = `Bearer ${accessToken}`;
}

const fileClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers,
});

export default fileClient;
