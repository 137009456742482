import Vue from 'vue';
import VueI18n from 'vue-i18n';
import mn from './lang/mn';

Vue.use(VueI18n);

export default new VueI18n({
  locale: 'mn', // set locale
  messages: { mn }, // set locale messages
});
