<template>
  <ModalPrint :id="modalId" :title="title">
    <div class="p-5">
      <InvoiceBill :item="invoice" />
    </div>
  </ModalPrint>
</template>

<script>
import ModalPrint from '@/components/ui/ModalPrint';
import baseModalMixin from '@/mixins/baseModalMixin';
import InvoiceBill from '@/components/invoice/InvoiceBill';

export default {
  mixins: [baseModalMixin],

  components: { ModalPrint, InvoiceBill },

  props: {
    invoice: Object,
    title: String,
  },

  computed: {},
  methods: {
    close() {
      this.hideEditModal();
    },
  },
};
</script>
