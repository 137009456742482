export default {
  props: {
    togglerClass: String,
    togglerTag: {
      type: String,
      default: 'button',
    },
    boxClass: String,
    contentClass: String,
  },
};
