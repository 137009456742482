<template>
  <section class="section" v-if="!isLoading">
    <div v-if="checkPermission('statistic.statistic')" class="grid grid-cols-12 gap-6">
      <div class="col-span-12 xxl:col-span-12 grid grid-cols-12 gap-6">
        <div class="col-span-12">
          <div class="grid grid-cols-12 gap-6 mt-5">
            <IconCard
              :data="generalData.totalIncomeAmount"
              :label="$t('dashboard.data.totalIncomeAmount')"
              :filter="true"
              icon="ShoppingCartIcon"
              string="report-box__icon text-theme-9"
            ></IconCard>
            <IconCard
              :data="generalData.totalInvoiceAmount"
              :label="$t('dashboard.data.totalInvoiceAmount')"
              :filter="true"
              icon="EditIcon"
              string="report-box__icon text-theme-12"
            ></IconCard>
            <IconCard
              :data="generalData.totalReceivableAmount"
              :label="$t('dashboard.data.totalReceivableAmount')"
              :filter="true"
              icon="CreditCardIcon"
              string="report-box__icon text-theme-10"
            ></IconCard>
            <IconCard
              :data="generalData.totalIncomePercent"
              :label="$t('dashboard.data.totalIncomePercent')"
              :filter="false"
              icon="PercentIcon"
              string="report-box__icon text-theme-9"
            ></IconCard>
          </div>
          <div class="grid grid-cols-12 gap-6 mt-5">
            <IconCard
              :data="generalData.totalIncomeCount"
              :label="$t('dashboard.data.totalIncomeCount')"
              :filter="false"
              icon="ShoppingCartIcon"
              string="report-box__icon text-theme-9"
            ></IconCard>
            <IconCard
              :data="generalData.totalInvoiceCount"
              :label="$t('dashboard.data.totalInvoiceCount')"
              :filter="false"
              icon="EditIcon"
              string="report-box__icon text-theme-12"
            ></IconCard>
            <IconCard
              :data="generalData.totalReceivableCount"
              :label="$t('dashboard.data.totalReceivableCount')"
              :filter="false"
              icon="CreditCardIcon"
              string="report-box__icon text-theme-10"
            ></IconCard>
            <IconCard
              :data="generalData.totalReceivablePercent"
              :label="$t('dashboard.data.totalReceivablePercent')"
              :filter="false"
              icon="PercentIcon"
              string="report-box__icon text-theme-10"
            ></IconCard>
          </div>
        </div>
        <div class="col-span-12 lg:col-span-6">
          <div class="intro-y box h-full">
            <div
              class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
            >
              <h2 class="font-medium text-base mr-auto">{{ $t('dashboard.lineChart') }}</h2>
              <div class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0">
                <InputYear
                  :placeholder="$t('text.date')"
                  v-model="graphicLine"
                  input-class="input-year-dashboard-linechart"
                />
              </div>
            </div>
            <div class="p-5">
              <LineChart
                :statistics="statistics"
                :headers="headers"
                :width="526"
                :height="273"
              ></LineChart>
            </div>
          </div>
        </div>
        <div class="col-span-12 lg:col-span-6">
          <div class="intro-y box h-full">
            <div
              class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
            >
              <h2 class="font-medium text-base mr-auto">{{ $t('dashboard.pieChart') }}</h2>
              <div class="w-full sm:w-auto flex items-center sm:ml-auto">
                <InputYear
                  v-model="graphicPie"
                  :placeholder="$t('text.date')"
                  input-class="input-year-dashboard-pie"
                />
              </div>
              <div class="w-full mt-1 sm:w-auto flex items-center sm:ml-1 sm:mt-0">
                <InputSelect
                  v-model="graphicPieMonth"
                  :options="graphicPieOptions"
                  :firstItemLabel="graphicPieLabel"
                  valueKey="id"
                  input-class="input-select-dashboard-graphic-pie"
                />
              </div>
            </div>
            <div class="p-5">
              <PieChart
                :statistics="statisticsPie"
                :headers="headersPie"
                :width="526"
                :height="273"
              ></PieChart>
            </div>
          </div>
        </div>
        <div class="col-span-12 lg:col-span-6">
          <div class="intro-y box h-full">
            <div
              class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
            >
              <h2 class="font-medium text-base mr-auto">{{ $t('dashboard.appDownloads') }}</h2>
            </div>
            <div class="p-5">
              <DownloadsLine :statistics="downloads" :width="526" :height="273"></DownloadsLine>
            </div>
          </div>
        </div>
        <div class="col-span-12 lg:col-span-6">
          <div class="intro-y box h-full">
            <div
              class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
            >
              <h2 class="font-medium text-base mr-auto">{{ $t('dashboard.feedback') }}</h2>
              <div class="w-full sm:w-auto flex items-center sm:ml-auto">
                <InputYear
                  v-model="graphicPie"
                  :placeholder="$t('text.date')"
                  input-class="input-year-dashboard-feedback"
                />
              </div>
              <div class="w-full mt-1 sm:w-auto flex items-center sm:ml-1 sm:mt-0">
                <InputSelect
                  v-model="graphicPieMonth"
                  :options="graphicPieOptions"
                  :firstItemLabel="graphicPieLabel"
                  valueKey="id"
                  input-class="input-select-dashboard-graphic-pie-month"
                />
              </div>
            </div>
            <div class="p-5">
              <TicketsPie
                :statistics="ticketsPie"
                :headers="ticketsHeader"
                :width="526"
                :height="273"
              ></TicketsPie>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else></div>
  </section>
</template>

<script>
import { isNil } from 'lodash';
import { mapGetters } from 'vuex';
import { DASHBOARD_GRAPHIC_REQUEST, DASHBOARD_GENERAL_REQUEST } from '@/store/actions/dashboard';
import LineChart from '@/components/dashboard/LineChart';
import PieChart from '@/components/dashboard/PieChart';
import TicketsPie from '@/components/dashboard/TicketsPie';
import DownloadsLine from '@/components/dashboard/DownloadsLine';
import InputYear from '@/components/ui/input/InputYear';
import InputSelect from '@/components/ui/input/InputSelect';
import IconCard from '@/components/dashboard/IconCard';
import moment from 'moment';
import permissionMixin from '@/mixins/permissionMixin';

export default {
  mixins: [permissionMixin],

  components: { LineChart, PieChart, InputYear, InputSelect, IconCard, TicketsPie, DownloadsLine },

  props: {
    business: Object,
  },

  data() {
    return {
      generalData: {},
      graphicData: {},
      statistics: { invoice: [], income: [], receivable: [] },
      headers: { invoice: [], income: [], receivable: [] },
      statisticsPie: [],
      headersPie: [],
      ticketsPie: [],
      ticketsHeader: [],
      downloads: [],
      graphicPie: moment().format('YYYY-MM-DD'),
      graphicLine: moment().format('YYYY-MM-DD'),
      graphicPieMonth: null,
      graphicPieLabel: 'Сонгоно уу',
      graphicPieOptions: [
        {
          id: '01',
          name: 1 + this.$t('dashboard.data.month'),
        },
        {
          id: '02',
          name: 2 + this.$t('dashboard.data.month'),
        },
        {
          id: '03',
          name: 3 + this.$t('dashboard.data.month'),
        },
        {
          id: '04',
          name: 4 + this.$t('dashboard.data.month'),
        },
        {
          id: '05',
          name: 5 + this.$t('dashboard.data.month'),
        },
        {
          id: '06',
          name: 6 + this.$t('dashboard.data.month'),
        },
        {
          id: '07',
          name: 7 + this.$t('dashboard.data.month'),
        },
        {
          id: '08',
          name: 8 + this.$t('dashboard.data.month'),
        },
        {
          id: '09',
          name: 9 + this.$t('dashboard.data.month'),
        },
        {
          id: '10',
          name: 10 + this.$t('dashboard.data.month'),
        },
        {
          id: '11',
          name: 11 + this.$t('dashboard.data.month'),
        },
        {
          id: '12',
          name: 12 + this.$t('dashboard.data.month'),
        },
      ],
    };
  },

  computed: {
    ...mapGetters([
      'dashboardGeneral',
      'dashboardGraphic',
      'dashboardGraphicStatus',
      'dashboardGeneralStatus',
    ]),
    isLoading() {
      return this.dashboardGraphicStatus === 'loading' || this.dashboardGeneralStatus === 'loading';
    },
  },

  created() {
    if (isNil(this.business)) {
      this.$router.push({ name: 'AllBusiness' });
      return;
    }
    this.fetchData();
  },

  methods: {
    async fetchData() {
      await this.fetchGeneralData();
      await this.fetchGraphicData(this.graphicLine);
    },
    async fetchGraphicData(date) {
      this.statistics.income = [];
      this.statistics.invoice = [];
      this.statistics.receivable = [];
      this.headers = {};
      const payload = {
        businessId: this.business.id,
        data: {
          invoice_date: moment(date).format('YYYY-MM-DD'),
        },
      };
      await this.$store.dispatch(DASHBOARD_GRAPHIC_REQUEST, payload);
      if (this.dashboardGraphicStatus === 'success') {
        this.graphicData = this.dashboardGraphic;
      }
      if (this.graphicData) {
        for (let i = 0; i < this.graphicData.totalIncomes.length; i += 1) {
          this.statistics.income.push(this.graphicData.totalIncomes[i].amount);
          this.statistics.invoice.push(this.graphicData.totalInvoices[i].amount);
          this.statistics.receivable.push(this.graphicData.totalReceivables[i].amount);
        }
        this.headers.income = this.$t('dashboard.data.income');
        this.headers.invoice = this.$t('dashboard.data.invoice');
        this.headers.receivable = this.$t('dashboard.data.receivable');
        this.downloads = this.graphicData.users;
      }
    },
    async fetchGeneralData() {
      this.headersPie = [];
      this.statisticsPie = [];
      let dataType = 'current_year';
      let date = '';
      if (
        this.graphicPieMonth === null ||
        this.graphicPieMonth === 0 ||
        this.graphicPieMonth === ''
      ) {
        dataType = 'current_year';
        date = moment(this.graphicPie).format('YYYY-MM-DD');
      } else {
        dataType = 'current_month';
        const yearConvert = moment(this.graphicPie, 'YYYY/MM/DD');
        const year = yearConvert.format('YYYY');
        date = `${year}-${this.graphicPieMonth}-01`;
      }
      const payload = {
        businessId: this.business.id,
        data: { invoice_date: date, type: dataType },
      };
      await this.$store.dispatch(DASHBOARD_GENERAL_REQUEST, payload);
      if (this.dashboardGeneral) {
        this.generalData = this.dashboardGeneral;
      }
      if (this.generalData) {
        this.headersPie.push(this.$t('dashboard.data.income'));
        this.headersPie.push(this.$t('dashboard.data.receivable'));
        this.statisticsPie.push(this.generalData.totalIncomePercent);
        this.statisticsPie.push(this.generalData.totalReceivablePercent);
        this.ticketsHeader = this.generalData.tickets.map(item => item.status);
        this.ticketsPie = this.generalData.tickets.map(item => item.statusCount);
      }
    },
  },
  watch: {
    graphicPie: {
      async handler() {
        await this.fetchGeneralData();
      },
      deep: true,
    },
    graphicLine: {
      async handler(value) {
        await this.fetchGraphicData(moment(value).format('YYYY-MM-DD'));
      },
      deep: true,
    },
    graphicPieMonth: {
      async handler() {
        await this.fetchGeneralData();
      },
      deep: true,
    },
  },
};
</script>
<style>
.vdp-datepicker__calendar {
  width: 186px !important;
}
</style>
