<template>
  <div>
    <div class="p-5">
      <InputString
        :label="$t('text.lastName')"
        v-model.trim="form.lastName.$model"
        :vuelidate="form.lastName"
        input-class="input-user-last-name"
      />

      <InputString
        :label="$t('text.firstName')"
        v-model.trim="form.firstName.$model"
        :vuelidate="form.firstName"
        input-class="input-user-first-name"
      />

      <InputString
        :label="$t('text.phone')"
        v-model.trim="form.phone.$model"
        :vuelidate="form.phone"
        input-class="input-user-phone"
        :max-length="8"
      />

      <InputString
        :label="$t('text.email')"
        v-model.trim="form.email.$model"
        :vuelidate="form.email"
        input-class="input-user-email"
      />

      <InputString
        v-if="edit"
        type="password"
        :label="$t('text.password')"
        v-model.trim="form.password.$model"
        :vuelidate="form.password"
        input-class="input-user-password"
      />

      <InputString
        v-if="edit"
        type="password"
        :label="$t('text.passwordConfirmation')"
        v-model.trim="form.passwordConfirmation.$model"
        :vuelidate="form.passwordConfirmation"
        input-class="input-user-password-confirmation"
      />
    </div>
  </div>
</template>

<script>
import InputString from '@/components/ui/input/InputString';

export default {
  components: {
    InputString,
  },

  props: {
    form: Object,
    edit: Boolean,
  },
};
</script>
