export const FIREBASE_INIT_APP = 'FIREBASE_INIT_APP';
export const FIREBASE_INIT_MESSAGING = 'FIREBASE_INIT_MESSAGING';

export const FIREBASE_MESSAGING_SUBSCRIBE = 'FIREBASE_MESSAGING_SUBSCRIBE';
export const FIREBASE_MESSAGING_UNSUBSCRIBE = 'FIREBASE_MESSAGING_UNSUBSCRIBE';

export const FIREBASE_SUBSCRIBING = 'FIREBASE_SUBSCRIBING';
export const FIREBASE_UNSUBSCRIBING = 'FIREBASE_UNSUBSCRIBING';
export const FIREBASE_SUBSCRIBED = 'FIREBASE_SUBSCRIBED';
export const FIREBASE_MESSAGING_TOKEN = 'FIREBASE_MESSAGING_TOKEN';
