<template>
  <div class="intro-y box mt-5">
    <div class="relative flex items-center p-5">
      <div class="mr-auto">
        <div class="font-medium text-base">{{ $t('text.bankAccountSettings') }}</div>
      </div>
      <Button @click="onEdit" class="w-24 bg-theme-14 text-theme-10">
        {{ $t('text.update') }}
      </Button>
    </div>
    <div class="p-5 border-t border-gray-200 dark:border-dark-5">
      <p class="mb-4 font-bold" v-if="bankAccounts.length != 0">
        {{ `${$t('text.businessBankName')}: ${bankAccounts[0].bank.name}` }}
      </p>
      <p class="font-bold">
        {{ `${$t('text.bankAccountName')}: ${bankAccounts[0].accountName}` }}
      </p>
      <p class="mt-4 font-bold">
        {{
          `${$t('text.bankAccountNumber')}: ${
            bankAccounts[0].accountNumber ? bankAccounts[0].accountNumber : ''
          }`
        }}
      </p>
    </div>
    <BusinessBankModal
      :modal-id="modalId"
      :bankAccount="{ ...bankAccounts[0] }"
      :businessId="businessId"
      @onBankAdd="onBankAdd"
    />
  </div>
</template>

<script>
import Button from '@/components/ui/button/Button';
import BusinessBankModal from '@/components/business/account/BusinessBankModal';

export default {
  props: {
    bankAccounts: Array,
    businessId: Number,
  },

  components: {
    Button,
    BusinessBankModal,
  },
  data() {
    return {
      modalId: 'business-bank-modal',
    };
  },
  methods: {
    onEdit() {
      cash(`#${this.modalId}`).modal('show');
    },
    onBankAdd(change) {
      this.$emit('onBankAdd', change);
      this.bankAccounts[0].accountName = change.data.accountName;
      this.bankAccounts[0].accountNumber = change.data.accountNumber;
      this.bankAccounts[0].bank.name = change.data.bank.name;
    },
  },
};
</script>
