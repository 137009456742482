export const BUSINESS_ADMIN_LIST_REQUEST = 'BUSINESS_ADMIN_LIST_REQUEST';
export const BUSINESS_ADMIN_LIST_REQUEST_SUCCESS = 'BUSINESS_ADMIN_LIST_REQUEST_SUCCESS';
export const BUSINESS_ADMIN_LIST_REQUEST_ERROR = 'BUSINESS_ADMIN_LIST_REQUEST_ERROR';

export const BUSINESS_ADMIN_APPROVE_REQUEST = 'BUSINESS_ADMIN_APPROVE_REQUEST';
export const BUSINESS_ADMIN_APPROVE_REQUEST_SUCCESS = 'BUSINESS_ADMIN_APPROVE_REQUEST_SUCCESS';
export const BUSINESS_ADMIN_APPROVE_REQUEST_ERROR = 'BUSINESS_ADMIN_APPROVE_REQUEST_ERROR';

export const BUSINESS_ADMIN_DELETE_REQUEST = 'BUSINESS_ADMIN_DELETE_REQUEST';
export const BUSINESS_ADMIN_DELETE_REQUEST_SUCCESS = 'BUSINESS_ADMIN_DELETE_REQUEST_SUCCESS';
export const BUSINESS_ADMIN_DELETE_REQUEST_ERROR = 'BUSINESS_ADMIN_DELETE_REQUEST_ERROR';

export const TICKET_TYPE_LIST_REQUEST = 'TICKET_TYPE_LIST_REQUEST';
export const TICKET_TYPE_LIST_REQUEST_SUCCESS = 'TICKET_TYPE_LIST_REQUEST_SUCCESS';
export const TICKET_TYPE_LIST_REQUEST_ERROR = 'TICKET_TYPE_LIST_REQUEST_ERROR';
