import { findIndex } from 'lodash';
import {
  FORUMS_LIST_REQUEST,
  FORUMS_LIST_REQUEST_ERROR,
  FORUMS_LIST_REQUEST_SUCCESS,
  FORUMS_ADD,
  FORUMS_ADD_ERROR,
  FORUMS_ADD_SUCCESS,
  FORUMS_SHOW,
  FORUMS_SHOW_SUCCESS,
  FORUMS_SHOW_ERROR,
  FORUMS_UPDATE,
  FORUMS_UPDATE_SUCCESS,
  FORUMS_UPDATE_ERROR,
  FORUMS_DELETE,
  FORUMS_DELETE_SUCCESS,
  FORUMS_DELETE_ERROR,
} from '@/store/actions/forums';
import { handleRequestError } from '@/utils/error';

import Repository, { FORUMS } from '@/repositories/RepositoryFactory';

const ForumsRepository = Repository.get(FORUMS);

const state = {
  forum: {},
  forumList: [],
  forumsMeta: '',
  forumsStatus: '',
  forumsLastCreated: '',
};

const getters = {
  forum: state => state.forum,
  forumList: state => state.forumList,
  forumsMeta: state => state.forumsMeta,
  forumsStatus: state => state.forumsStatus,
  forumsLastCreated: state => state.forumsLastCreated,
};

const actions = {
  [FORUMS_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(FORUMS_LIST_REQUEST);
    try {
      const response = await ForumsRepository.get(payload.businessId, payload.data);
      commit(FORUMS_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(FORUMS_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [FORUMS_ADD]: async ({ commit }, payload) => {
    commit(FORUMS_ADD);
    try {
      const response = await ForumsRepository.create(payload.businessId, payload.data);
      commit(FORUMS_ADD_SUCCESS, response.data.data);
    } catch (error) {
      commit(FORUMS_ADD_ERROR);
      handleRequestError(error);
    }
  },
  [FORUMS_SHOW]: async ({ commit }, payload) => {
    commit(FORUMS_SHOW);
    try {
      const response = await ForumsRepository.show(payload.businessId, payload.id);
      const { data } = response;
      commit(FORUMS_SHOW_SUCCESS, data);
    } catch (error) {
      commit(FORUMS_SHOW_ERROR);
      handleRequestError(error);
    }
  },
  [FORUMS_UPDATE]: async ({ commit }, payload) => {
    commit(FORUMS_UPDATE);
    try {
      const response = await ForumsRepository.update(payload.businessId, payload.id, payload.data);
      commit(FORUMS_UPDATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(FORUMS_UPDATE_ERROR);
      handleRequestError(error);
    }
  },
  [FORUMS_DELETE]: async ({ commit }, payload) => {
    commit(FORUMS_DELETE);
    try {
      await ForumsRepository.delete(payload.businessId, payload.id);
      commit(FORUMS_DELETE_SUCCESS, payload.id);
    } catch (error) {
      commit(FORUMS_DELETE_ERROR);
      handleRequestError(error);
    }
  },
};

const mutations = {
  [FORUMS_LIST_REQUEST]: state => {
    state.forumsStatus = 'loading';
  },
  [FORUMS_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.forumsStatus = 'success';
    state.forumList = payload.data;
    state.forumsMeta = payload.meta;
  },
  [FORUMS_LIST_REQUEST_ERROR]: state => {
    state.forumsStatus = 'error';
  },
  [FORUMS_ADD]: state => {
    state.forumsStatus = 'loading';
    state.forumsLastCreated = '';
  },
  [FORUMS_ADD_SUCCESS]: (state, payload) => {
    state.forumsStatus = 'success';
    state.forumsLastCreated = payload;
    state.forumList.push(payload);
  },
  [FORUMS_ADD_ERROR]: state => {
    state.forumsStatus = 'error';
  },
  [FORUMS_SHOW]: state => {
    state.forumsStatus = 'loading';
  },
  [FORUMS_SHOW_SUCCESS]: (state, payload) => {
    state.forumsStatus = 'success';
    state.forum = payload.data;
  },
  [FORUMS_SHOW_ERROR]: state => {
    state.forumsStatus = 'error';
  },
  [FORUMS_UPDATE]: state => {
    state.forumsStatus = 'loading';
  },
  [FORUMS_UPDATE_SUCCESS]: (state, payload) => {
    state.forumsStatus = 'success';
    state.forumList = payload;
  },
  [FORUMS_UPDATE_ERROR]: state => {
    state.forumsStatus = 'error';
  },
  [FORUMS_DELETE]: state => {
    state.forumsStatus = 'deleting';
  },
  [FORUMS_DELETE_SUCCESS]: (state, payload) => {
    const index = findIndex(state.forumList, { id: payload });
    state.forumList.splice(index, 1);
  },
  [FORUMS_DELETE_ERROR]: state => {
    state.forumsStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
