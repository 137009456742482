<template>
  <ModalEdit
    :id="modalId"
    :title="title"
    :loading="isLoading"
    @click="onSubmit"
    :saveButton="isSave"
  >
    <div class="p-5">
      <InputMonth
        v-model="$v.form.invoiceDate.$model"
        :vuelidate="$v.form.invoiceDate"
        :label="$t('text.date')"
        input-class="input-month-invoice-date"
      />
      <InputSelect
        v-model.number="$v.form.buildingId.$model"
        first-item-label=""
        first-item-disabled
        :options="buildings"
        :label="$t('text.buildingNameLong')"
        value-key="id"
        :vuelidate="$v.form.buildingId"
        :disabled="buildingListLoading"
        input-class="input-select-invoice-building"
      />
      <InputCheckbox
        v-model="form.sendSms"
        :label="$t('text.sendSms')"
        input-class="input-checkbox-send-sms"
        @input="input"
      />
      <div class="pt-5" v-if="isSms">
        <p>
          {{ $t('text.smsBalance') }}: <b>{{ business.smsCount }}</b>
        </p>
        <p>
          {{ $t('text.nowTotalSendingSMS') }}: <b>{{ smsCount }}</b>
        </p>
      </div>
      <div v-if="serverErrors" class="text-theme-6 mt-2">
        <p v-for="(error, index) in serverErrors" :key="index">{{ error[0] }}</p>
      </div>
    </div>
  </ModalEdit>
</template>

<script>
import { mapGetters } from 'vuex';
import { INVOICE_COPY, INVOICE_CHECK_SMS } from '@/store/actions/invoice';
import { BUILDING_LIST_REQUEST } from '@/store/actions/building';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import ModalEdit from '@/components/ui/ModalEdit';
import baseModalMixin from '@/mixins/baseModalMixin';
import InputSelect from '@/components/ui/input/InputSelect';
import InputMonth from '@/components/ui/input/InputMonth';
import InputCheckbox from '@/components/ui/input/InputCheckbox';
import { VALIDATION_ERROR_CODE } from '@/utils/error';

export default {
  mixins: [validationMixin, baseModalMixin],

  components: { ModalEdit, InputMonth, InputSelect, InputCheckbox },

  props: {
    business: Object,
    title: String,
  },

  validations() {
    return {
      form: {
        invoiceDate: {
          required,
        },
        buildingId: {
          required,
        },
        sendSms: {
          required,
        },
      },
    };
  },

  data() {
    return {
      form: {
        invoiceDate: '',
        buildingId: 0,
        sendSms: 0,
      },
      smsCount: '',
      isSms: false,
      isSave: true,
      serverErrors: [],
      defaultTableData: {
        filters: {},
        sort: {
          field: 'id',
          type: 'desc',
        },
        page: 1,
        perPage: 1000,
      },
      buildings: [],
    };
  },

  computed: {
    ...mapGetters([
      'invoiceCopyStatus',
      'buildingList',
      'buildingStatus',
      'invoicesSmsStatus',
      'invoicesSms',
    ]),
    isLoading() {
      return this.invoiceCopyStatus === 'loading';
    },
    buildingListLoading() {
      return this.buildingStatus === 'loading';
    },
  },

  async created() {
    await this.fetchBuildingList();
  },

  methods: {
    resetForm() {
      this.form = {
        invoiceDate: '',
        buildingId: 0,
        sendSms: 0,
      };
      this.$v.$reset();
      this.serverErrors = [];
    },

    async fetchBuildingList() {
      const payload = {
        businessId: this.business.id,
        data: this.defaultTableData,
      };

      await this.$store.dispatch(BUILDING_LIST_REQUEST, payload);
      if (this.buildingStatus === 'success') {
        this.buildings = this.buildingList.map(item => item);
      }
    },
    async input(val) {
      if (val !== 0) {
        this.isSms = true;
        if (this.form.invoiceDate !== '') {
          const payload = {
            businessId: this.business.id,
            type: 'COPY',
            data: {
              buildingId: this.form.buildingId,
              startDate: this.form.invoiceDate,
            },
          };
          await this.$store.dispatch(INVOICE_CHECK_SMS, payload);
          if (this.invoicesSmsStatus === 'success') {
            this.smsCount = this.invoicesSms.smsCount;
            if (this.business.smsCount < this.smsCount) {
              this.isSave = false;
            }
          }
        }
      } else {
        this.isSms = false;
        this.isSave = true;
      }
    },
    async onSubmit() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          this.$toasted.show(this.$t('toast.failedForm'), { type: 'error' });
          return;
        }
      }

      try {
        const payload = {
          businessId: this.business.id,
          data: {
            ...this.form,
          },
        };
        await this.$store.dispatch(INVOICE_COPY, payload);

        if (this.invoiceCopyStatus === 'success') {
          this.$toasted.show(this.$t('toast.success'), { type: 'success' });
          this.hideEditModal();
          this.$emit('onCopySuccess');
          this.resetForm();
        }
      } catch (error) {
        if (error.response.status === VALIDATION_ERROR_CODE) {
          this.serverErrors = error.response.data.errors;
        }
        this.$toasted.show(error.message, { type: 'error' });
      }
    },
  },
};
</script>
