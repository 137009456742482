import { findIndex } from 'lodash';
import {
  SERVICE_LIST_REQUEST,
  SERVICE_LIST_REQUEST_ERROR,
  SERVICE_LIST_REQUEST_SUCCESS,
  SERVICE_ADD,
  SERVICE_ADD_ERROR,
  SERVICE_ADD_SUCCESS,
  SERVICE_REQUEST,
  SERVICE_REQUEST_ERROR,
  SERVICE_REQUEST_SUCCESS,
  SERVICE_UPDATE,
  SERVICE_UPDATE_ERROR,
  SERVICE_UPDATE_SUCCESS,
} from '@/store/actions/service';
import { handleRequestError } from '@/utils/error';

import Repository, { SERVICES } from '@/repositories/RepositoryFactory';

const ServicesRepository = Repository.get(SERVICES);

const state = {
  serviceList: [],
  serviceListMeta: '',
  serviceListStatus: '',
  serviceLastCreated: '',
  service: {},
  serviceStatus: '',
};

const getters = {
  serviceList: state => state.serviceList,
  serviceListMeta: state => state.serviceListMeta,
  serviceListStatus: state => state.serviceListStatus,
  serviceLastCreated: state => state.serviceLastCreated,
  service: state => state.service,
  serviceStatus: state => state.serviceStatus,
};

const actions = {
  [SERVICE_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(SERVICE_LIST_REQUEST);
    try {
      const response = await ServicesRepository.get(payload.data);
      commit(SERVICE_LIST_REQUEST_SUCCESS, response.data);
    } catch (error) {
      commit(SERVICE_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [SERVICE_ADD]: async ({ commit }, payload) => {
    commit(SERVICE_ADD);
    try {
      const response = await ServicesRepository.create(payload.data);
      commit(SERVICE_ADD_SUCCESS, response.data.data);
    } catch (error) {
      commit(SERVICE_ADD_ERROR);
      handleRequestError(error);
    }
  },
  [SERVICE_REQUEST]: async ({ commit }, payload) => {
    commit(SERVICE_REQUEST);
    try {
      const response = await ServicesRepository.show(payload.id);
      const { data } = response;
      commit(SERVICE_REQUEST_SUCCESS, data);
    } catch (error) {
      commit(SERVICE_REQUEST_ERROR);
      handleRequestError(error);
    }
  },
  [SERVICE_UPDATE]: async ({ commit }, payload) => {
    commit(SERVICE_UPDATE);
    try {
      const response = await ServicesRepository.update(payload.id, payload.data);
      commit(SERVICE_UPDATE_SUCCESS, response.data.data);
    } catch (error) {
      commit(SERVICE_UPDATE_ERROR);
      handleRequestError(error);
    }
  },
};

const mutations = {
  [SERVICE_LIST_REQUEST]: state => {
    state.serviceListStatus = 'loading';
  },
  [SERVICE_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.serviceListStatus = 'success';
    state.serviceList = payload.data;
    state.serviceListMeta = payload.meta;
  },
  [SERVICE_LIST_REQUEST_ERROR]: state => {
    state.serviceListStatus = 'error';
  },
  [SERVICE_ADD]: state => {
    state.serviceStatus = 'loading';
    state.serviceLastCreated = '';
  },
  [SERVICE_ADD_SUCCESS]: (state, payload) => {
    state.serviceStatus = 'success';
    state.serviceLastCreated = payload;
    state.serviceList.push(payload);
  },
  [SERVICE_ADD_ERROR]: state => {
    state.serviceStatus = 'error';
  },
  [SERVICE_REQUEST]: state => {
    state.serviceStatus = 'loading';
  },
  [SERVICE_REQUEST_SUCCESS]: (state, payload) => {
    state.serviceStatus = 'success';
    state.service = payload.data;
  },
  [SERVICE_REQUEST_ERROR]: state => {
    state.serviceStatus = 'error';
  },
  [SERVICE_UPDATE]: state => {
    state.serviceStatus = 'loading';
  },
  [SERVICE_UPDATE_SUCCESS]: (state, payload) => {
    state.serviceStatus = 'success';
    const index = findIndex(state.serviceList, { id: payload.id });
    state.serviceList[index] = payload;
  },
  [SERVICE_UPDATE_ERROR]: state => {
    state.serviceStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
