import {
  RECEIVABLE_LIST_REQUEST,
  RECEIVABLE_LIST_REQUEST_ERROR,
  RECEIVABLE_LIST_REQUEST_SUCCESS,
  RECEIVABLE_LIST_EXPORT,
  RECEIVABLE_LIST_EXPORT_SUCCESS,
  RECEIVABLE_LIST_EXPORT_ERROR,
  RECEIVABLES_SMS_SEND,
  RECEIVABLES_SMS_SEND_SUCCESS,
  RECEIVABLES_SMS_SEND_ERROR,
} from '@/store/actions/report';
import { handleRequestError } from '@/utils/error';

import Repository, { REPORTS } from '@/repositories/RepositoryFactory';

const ReportsRepository = Repository.get(REPORTS);

const state = {
  receivableList: [],
  receivableListStatus: '',
  receivableListFooter: {},
  receivableMeta: '',
  receivableExportStatus: '',
  receivableExportLink: '',
  receivablesSendSms: '',
};

const getters = {
  receivableList: state => state.receivableList,
  receivableListStatus: state => state.receivableListStatus,
  receivableListFooter: state => state.receivableListFooter,
  receivableMeta: state => state.receivableMeta,
  receivableExportStatus: state => state.receivableExportStatus,
  receivableExportLink: state => state.receivableExportLink,
  receivablesSendSms: state => state.receivablesSendSms,
};

const actions = {
  [RECEIVABLE_LIST_REQUEST]: async ({ commit }, payload) => {
    commit(RECEIVABLE_LIST_REQUEST);
    try {
      const response = await ReportsRepository.getReceivables(payload.businessId, payload.data);
      commit(RECEIVABLE_LIST_REQUEST_SUCCESS, response);
    } catch (error) {
      commit(RECEIVABLE_LIST_REQUEST_ERROR);
      handleRequestError(error);
    }
  },

  [RECEIVABLE_LIST_EXPORT]: async ({ commit }, payload) => {
    commit(RECEIVABLE_LIST_EXPORT);
    try {
      const response = await ReportsRepository.exportReceivables(payload.businessId, payload.data);
      commit(RECEIVABLE_LIST_EXPORT_SUCCESS, response.data.data);
    } catch (error) {
      commit(RECEIVABLE_LIST_EXPORT_ERROR);
      handleRequestError(error);
    }
  },
  [RECEIVABLES_SMS_SEND]: async ({ commit }, payload) => {
    commit(RECEIVABLES_SMS_SEND);
    try {
      await ReportsRepository.sendReceivablesSms(payload.businessId, payload.data);
      commit(RECEIVABLES_SMS_SEND_SUCCESS);
    } catch (error) {
      commit(RECEIVABLES_SMS_SEND_ERROR);
      handleRequestError(error);
    }
  },
};

const mutations = {
  [RECEIVABLE_LIST_REQUEST]: state => {
    state.receivableListStatus = 'loading';
  },
  [RECEIVABLE_LIST_REQUEST_SUCCESS]: (state, payload) => {
    state.receivableListStatus = 'success';
    state.receivableList = Object.values(payload.data.data);
    state.receivableListFooter = payload.data.meta.footer;
    state.receivableMeta = payload.data.meta;
  },
  [RECEIVABLE_LIST_REQUEST_ERROR]: state => {
    state.receivableListStatus = 'error';
  },

  [RECEIVABLE_LIST_EXPORT]: state => {
    state.receivableExportStatus = 'loading';
    state.receivableExportLink = '';
  },
  [RECEIVABLE_LIST_EXPORT_SUCCESS]: (state, payload) => {
    state.receivableExportStatus = 'success';
    state.receivableExportLink = payload;
  },
  [RECEIVABLE_LIST_EXPORT_ERROR]: state => {
    state.receivableExportStatus = 'error';
  },

  [RECEIVABLES_SMS_SEND]: state => {
    state.receivablesSendSms = 'loading';
  },
  [RECEIVABLES_SMS_SEND_SUCCESS]: state => {
    state.receivablesSendSms = 'success';
  },
  [RECEIVABLES_SMS_SEND_ERROR]: state => {
    state.receivablesSendSms = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
