<template>
  <div class="mt-5">
    <div class="box px-5 intro-y mb-5">
      <div class="nav-tabs flex flex-col sm:flex-row justify-center lg:justify-start">
        <router-link
          :to="{ name: 'Tickets' }"
          class="py-4 sm:mr-8"
          :class="{ active: $route.name === 'Tickets' }"
          >{{ $t('nav.ticketReceived') }}</router-link
        >
        <router-link
          :to="{ name: 'TicketSent' }"
          class="py-4 sm:mr-8"
          :class="{ active: $route.name === 'TicketSent' }"
          >{{ $t('nav.ticketSent') }}</router-link
        >
        <router-link
          :to="{ name: 'TicketSeen' }"
          class="py-4 sm:mr-8"
          :class="{ active: $route.name === 'TicketSeen' }"
        >
          {{ $t('nav.ticketSeen') }}
        </router-link>
        <router-link
          :to="{ name: 'TicketSolved' }"
          class="py-4 sm:mr-8"
          :class="{ active: $route.name === 'TicketSolved' }"
        >
          {{ $t('nav.ticketSolved') }}
        </router-link>
      </div>
    </div>
    <div class="intro-y">
      <router-view :business="business"></router-view>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    business: Object,
  },
};
</script>
