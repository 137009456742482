import Client from '@/clients/apiClient';

const resourceBase = 'business';
const resource = 'dashboard';

export default {
  getDashboardGraphic(businessId, payload) {
    return Client.post(`${resourceBase}/${businessId}/${resource}/invoice-graphic-data`, payload);
  },
  getDashboardGeneral(businessId, payload) {
    return Client.post(`${resourceBase}/${businessId}/${resource}/invoice-general-data`, payload);
  },
};
