import Client from '@/clients/apiClient';
import humps from 'humps';
import { stringify } from 'query-string';

const business = 'business';
const resource = 'sms-packages';
const businessInvoice = 'business-invoice';
const balance = 'sms-balance';
export default {
  get(payload) {
    return Client.get(
      `${resource}?page=${payload.page}&limit=${payload.perPage}&sort[field]=${humps.decamelize(
        payload.sort.field
      )}&sort[type]=${payload.sort.type}&${stringify(humps.decamelizeKeys(payload.filters))}`
    );
  },
  subscribe(businessId, payload) {
    return Client.post(`${business}/${businessId}/${businessInvoice}`, payload);
  },
  getBusinessInvoice(businessId, payload) {
    return Client.get(
      `${business}/${businessId}/${balance}?page=${payload.page}&limit=${
        payload.perPage
      }&sort[field]=${humps.decamelize(payload.sort.field)}&sort[type]=${
        payload.sort.type
      }&${stringify(humps.decamelizeKeys(payload.filters))}`
    );
  },
  getSmsLogs(businessId, payload) {
    return Client.get(
      `${business}/${businessId}/sms-logs?page=${payload.page}&limit=${
        payload.perPage
      }&sort[field]=${humps.decamelize(payload.sort.field)}&sort[type]=${
        payload.sort.type
      }&${stringify(humps.decamelizeKeys(payload.filters))}`
    );
  },
};
