<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <PageHeader class="text-lg font-medium mr-auto" :title="title" />
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <Button
          type="button"
          :to="{ name: 'ActivityReports' }"
          class="box text-gray-700 dark:text-gray-300 mr-2 flex"
        >
          {{ $t('text.back') }}
        </Button>
        <Button
          type="button"
          @click="onSubmit"
          :loading="isLoading"
          class="w-24 bg-theme-1 text-white"
        >
          {{ $t('text.save') }}
        </Button>
      </div>
    </div>
    <div class="intro-y mt-5">
      <form @submit.prevent="onSubmit">
        <div class="intro-y box">
          <div class="p-5 border-b border-gray-200 dark:border-dark-5">
            <h2 class="font-medium text-base">{{ $t('nav.activityReportShow') }}</h2>
          </div>
          <div class="py-4 px-5 relative">
            <div class="grid grid-cols-12 gap-6">
              <div class="intro-y col-span-12 sm:col-span-12 lg:col-span-6">
                <InputString
                  :label="$t('text.name')"
                  v-model.trim="$v.form.name.$model"
                  :vuelidate="$v.form.name"
                  input-class="input-activity-report-name"
                />
                <InputString
                  :label="$t('text.description')"
                  v-model.trim="$v.form.description.$model"
                  :vuelidate="$v.form.description"
                  input-class="input-activity-report-description"
                  is-textarea
                />
                <InputDate
                  v-model="$v.form.startDate.$model"
                  :vuelidate="$v.form.startDate"
                  input-class="input-activity-report-start-date"
                  :label="$t('text.selectStartDate')"
                />
                <InputDate
                  v-model="$v.form.endDate.$model"
                  :vuelidate="$v.form.endDate"
                  input-class="input-activity-report-end-date"
                  :label="$t('text.selectEndDate')"
                />
              </div>
              <div class="intro-y col-span-12 sm:col-span-12 lg:col-span-6">
                <FieldWithLabel :label="$t('text.attachImages')">
                  <div class="grid grid-cols-12 gap-6">
                    <div class="intro-y col-span-6 sm:col-span-6 lg:col-span-6 xl:col-span-6">
                      <InputPhoto
                        :vuelidate="$v.form.images"
                        class="input-photo-activity-report-images"
                        @change="onChangeFile"
                      />
                    </div>
                    <div
                      v-for="(photo, photoIndex) in form.images"
                      :key="`photo-${photoIndex}`"
                      class="input-photo-activity-report-photos intro-y col-span-6 sm:col-span-6 lg:col-span-6 xl:col-span-6"
                    >
                      <InputPhoto :photo="photo" @remove="onDeleteFile(photoIndex)" />
                    </div>
                  </div>
                </FieldWithLabel>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { assign } from 'lodash';
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import {
  ACTIVITY_REPORT_SHOW,
  ACTIVITY_REPORT_CREATE,
  ACTIVITY_REPORT_UPDATE,
} from '@/store/actions/activityReport';
import { FILE_UPLOAD } from '@/store/actions/file';
import Button from '@/components/ui/button/Button';
import PageHeader from '@/components/ui/PageHeader';
import InputString from '@/components/ui/input/InputString';
import InputPhoto from '@/components/ui/input/InputPhoto';
import InputDate from '@/components/ui/input/InputDate';
import FieldWithLabel from '@/components/ui/input/FieldWithLabel';
import moment from 'moment';

export default {
  mixins: [validationMixin],

  props: {
    business: Object,
  },

  validations() {
    return {
      form: {
        name: {
          required,
        },
        description: {
          required,
        },
        startDate: {
          required,
        },
        endDate: {
          required,
        },
        images: {
          required,
        },
      },
    };
  },

  data() {
    return {
      form: {
        name: '',
        description: '',
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        images: [],
      },
    };
  },

  computed: {
    ...mapGetters(['activityReportStatus', 'activityReport', 'getFileUpload']),
    isLoading() {
      return this.activityReportStatus === 'loading';
    },
    title() {
      return this.$route.name === 'ActivityReportCreate'
        ? this.$t(`nav.activityReportCreate`)
        : this.$t(`nav.activityReportUpdate`);
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      if (this.$route.params.id) {
        const payload = { businessId: this.business.id, id: this.$route.params.id };
        await this.$store.dispatch(ACTIVITY_REPORT_SHOW, payload);
        if (this.activityReport) {
          const { name, description, startDate, endDate, images } = this.activityReport;
          this.form = {
            name,
            description,
            startDate,
            endDate,
            images: images.map(image => image),
          };
        }
      }
    },
    async onChangeFile(e) {
      const file = e.target.files[0];
      await this.$store.dispatch(FILE_UPLOAD, file);
      if (this.getFileUpload.status === 'success') {
        this.form.images.push(this.getFileUpload.item);
      }
    },
    async onDeleteFile(index) {
      this.form.images.splice(index, 1);
    },
    async onSubmit() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          this.$toasted.show(this.$t('toast.failedForm'), { type: 'error' });
          return;
        }
      }

      try {
        const payload = {
          businessId: this.business.id,
          data: { ...this.form },
        };

        if (this.$route.params.id) {
          assign(payload, { id: this.activityReport.id });
          await this.$store.dispatch(ACTIVITY_REPORT_UPDATE, payload);
        } else {
          await this.$store.dispatch(ACTIVITY_REPORT_CREATE, payload);
        }
        if (this.activityReportStatus === 'success') {
          this.$router.push({ name: 'ActivityReports' });
          this.$toasted.show(this.$t('toast.success'), { type: 'success' });
        }
      } catch (error) {
        this.$toasted.show(error.message, { type: 'error' });
      }
    },
  },

  components: {
    Button,
    PageHeader,
    InputString,
    InputPhoto,
    InputDate,
    FieldWithLabel,
  },
};
</script>
